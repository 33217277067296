import { HttpErrorResponse, HttpResponseBase } from '@angular/common/http';
import cloneDeep from 'lodash/cloneDeep';
import isArray from 'lodash/isArray';
import isPlainObject from 'lodash/isPlainObject';
import values from 'lodash/values';

// TODO: Refactor import
import { isSet } from '../../../../shared/utils/common/common';
import { errorToString } from '../../../../shared/utils/errors/errors';

export class ServerRequestError {
  nonFieldErrors: string[] = [];
  fieldErrors: { [key: string]: string } = {};
  nonFieldErrorsKey = 'non_field_errors';
  status: number;
  response: HttpResponseBase;
  exception: any;

  constructor(error?: HttpErrorResponse | Object | string | Error | boolean | any) {
    if (!error) {
      return;
    }

    this.exception = error;

    if (error instanceof ServerRequestError) {
      this.nonFieldErrors = error.nonFieldErrors;
      this.fieldErrors = error.fieldErrors;
      this.status = error.status;
      this.response = error.response;
    } else if (error instanceof HttpErrorResponse) {
      this.status = error.status;
      this.response = error;

      if (isPlainObject(error.error) && error.status >= 400 && error.status < 500) {
        this.parseJetBridgeObjectError(error.error);
      }

      if (this.nonFieldErrors.length || values(this.fieldErrors).length) {
        // return parseJetBridgeObjectError result
      } else if (isPlainObject(error.error) && error.error['detail']) {
        this.nonFieldErrors = [error.error['detail']];
        this.fieldErrors = {};
      } else if (error.status == 401) {
        this.nonFieldErrors = ['Unauthorized'];
        this.fieldErrors = {};
      } else if (error.status == 403) {
        this.nonFieldErrors = ['Permission denied'];
        this.fieldErrors = {};
      } else if (error.status == 404) {
        this.nonFieldErrors = ['Not found'];
        this.fieldErrors = {};
      } else if (error.status == 500) {
        this.nonFieldErrors = ['Server error'];
        this.fieldErrors = {};
      } else if (error.status >= 501 && error.status < 600) {
        this.nonFieldErrors = ['Server is not available'];
        this.fieldErrors = {};
      } else if (error.status === 0) {
        this.nonFieldErrors = ['Query failed, please check your network connection and try again'];
        this.fieldErrors = {};
      } else {
        const generalError = this.parseGeneralErrorResponse(error.error);
        this.nonFieldErrors = [isSet(generalError) ? generalError : errorToString(error)];
        this.fieldErrors = {};
      }
    } else if (error instanceof Error) {
      this.nonFieldErrors = [error.message];
      this.fieldErrors = {};
    } else {
      const generalError = this.parseGeneralErrorResponse(error);
      this.nonFieldErrors = [isSet(generalError) ? generalError : errorToString(error)];
      this.fieldErrors = {};
    }
  }

  parseGeneralErrorResponse(error: any): string {
    if (typeof error == 'string') {
      return error;
    } else if (error && error['message']) {
      return error['message'];
    } else if (error && error['error'] && error['error']['message']) {
      return error['error']['message'];
    } else if (typeof error == 'boolean' || error === null || error === undefined) {
      return 'Unknown error';
    }
  }

  parseJetBridgeObjectError(error: Object) {
    if (error.hasOwnProperty(this.nonFieldErrorsKey)) {
      error = cloneDeep(error);
      this.nonFieldErrors = error[this.nonFieldErrorsKey];
      delete error[this.nonFieldErrorsKey];
    } else {
      this.nonFieldErrors = [];
    }

    if (
      values(error).every(item => {
        if (typeof item == 'string') {
          return true;
        } else if (isArray(item) && item.every(subItem => typeof subItem == 'string')) {
          return true;
        } else {
          return false;
        }
      })
    ) {
      this.fieldErrors = error as any;
    } else {
      this.nonFieldErrors.push(JSON.stringify(error));
      this.fieldErrors = {};
    }
  }

  get errors() {
    return [].concat(this.nonFieldErrors, ...values(this.fieldErrors));
  }

  serialize(): string {
    return JSON.stringify({
      nonFieldErrors: this.nonFieldErrors,
      fieldErrors: this.fieldErrors
    });
  }

  toString() {
    return this.serialize();
  }
}
