import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SelectModule } from '@common/select';
import { TipsModule } from '@common/tips';
import { ParametersComponentsModule } from '@modules/parameters-components';
import { ViewsComponentsModule } from '@modules/views-components';
import { SharedModule } from '@shared';
import { UiModule } from '@ui';

import { CustomElementAutoComponent } from './components/custom-element-auto/custom-element-auto.component';
import { CustomElementHtmlComponent } from './components/custom-element-html/custom-element-html.component';
import { CustomElementJsComponent } from './components/custom-element-js/custom-element-js.component';
import { CustomElementViewComponent } from './components/custom-element-view/custom-element-view.component';
import { CustomViewMapParametersComponent } from './components/custom-view-map-parameters/custom-view-map-parameters.component';
import { CustomElementDirective } from './directives/custom-element/custom-element.directive';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    ViewsComponentsModule,
    UiModule,
    TipsModule,
    SelectModule,
    ParametersComponentsModule
  ],
  declarations: [
    CustomElementHtmlComponent,
    CustomElementViewComponent,
    CustomElementJsComponent,
    CustomElementAutoComponent,
    CustomElementDirective,
    CustomViewMapParametersComponent
  ],
  exports: [CustomElementAutoComponent],
  entryComponents: [CustomViewMapParametersComponent]
})
export class CustomElementsComponentsModule {}
