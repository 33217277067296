var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, ElementRef, EventEmitter, OnChanges, OnDestroy, OnInit, QueryList } from '@angular/core';
import { Power2, TweenMax } from 'gsap';
import isEqual from 'lodash/isEqual';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, of } from 'rxjs';
import { distinctUntilChanged, skip, switchMap, tap } from 'rxjs/operators';
import { CollapseIndicatorPosition, CustomizeService, ElementItem, ElementType, TextStyle, traverseElementItems, ViewContext, ViewContextElement } from '@modules/customize';
import { AutoElementComponent, ElementContainerService } from '@modules/customize-elements';
import { applyParamInput$, Input as FieldInput } from '@modules/fields';
import { RoutingService } from '@modules/routing';
import { addClass, removeClass, toggleClass } from '@shared';
import { ElementGroupsContainerDirective } from '../../directives/element-groups-container/element-groups-container.directive';
import { FormElementComponent } from '../form-element/form-element.component';
import { RootLayoutComponent } from '../root-layout/root-layout.component';
var CollapseSectionComponent = /** @class */ (function () {
    function CollapseSectionComponent(customizeService, elementContainerService, routing, rootLayoutComponent, formElementComponent, cd) {
        var _this = this;
        this.customizeService = customizeService;
        this.elementContainerService = elementContainerService;
        this.routing = routing;
        this.rootLayoutComponent = rootLayoutComponent;
        this.formElementComponent = formElementComponent;
        this.cd = cd;
        this.children = [];
        this.collapseIndicatorPosition = CollapseIndicatorPosition.Right;
        this.opened = false;
        this.visible = true;
        this.first = false;
        this.last = false;
        this.theme = false;
        this.toggleOpened = new EventEmitter();
        this.elementComponents = new QueryList();
        this.titleInput$ = new BehaviorSubject(this.titleInput);
        this.opened$ = new BehaviorSubject(undefined);
        this.collapseIndicatorPositions = CollapseIndicatorPosition;
        this.canEnter = (function () {
            return function (drag, drop) {
                if (_this.rootLayoutComponent && !_this.rootLayoutComponent.active) {
                    return false;
                }
                if (drag.data instanceof ElementItem && drag.data.type == ElementType.FormSubmit) {
                    var isInsideCurrentForm_1 = false;
                    if (_this.formElementComponent) {
                        traverseElementItems(_this.formElementComponent.element, function (item) {
                            if (item === drag.data) {
                                isInsideCurrentForm_1 = true;
                            }
                        });
                    }
                    return isInsideCurrentForm_1;
                }
                else {
                    return true;
                }
            };
        })();
        this.trackElement = (function () {
            return function (i, item) {
                var pageUid = _this.context && _this.context.viewSettings && !_this.context.viewSettings.newlyCreated
                    ? _this.context.viewSettings.uid
                    : undefined;
                return [pageUid, item.uid].join('_');
            };
        })();
    }
    CollapseSectionComponent.prototype.ngOnInit = function () {
        this.opened$.next(this.opened);
        this.initTitle();
        this.initOpenAnimation();
    };
    CollapseSectionComponent.prototype.ngOnDestroy = function () { };
    CollapseSectionComponent.prototype.ngOnChanges = function (changes) {
        if (changes.titleInput) {
            this.titleInput$.next(this.titleInput);
        }
        if (changes.opened && !changes.opened.firstChange) {
            this.opened$.next(this.opened);
        }
    };
    CollapseSectionComponent.prototype.initTitle = function () {
        var _this = this;
        this.titleInput$
            .pipe(distinctUntilChanged(function (lhs, rhs) { return isEqual(lhs, rhs); }), switchMap(function (titleInput) {
            if (!titleInput) {
                return of(undefined);
            }
            return applyParamInput$(titleInput, {
                context: _this.context,
                contextElement: _this.contextElement,
                defaultValue: ''
            });
        }), untilDestroyed(this))
            .subscribe(function (value) {
            _this.title = value;
            _this.cd.markForCheck();
        });
    };
    CollapseSectionComponent.prototype.initOpenAnimation = function () {
        var _this = this;
        this.opened$
            .pipe(skip(1), tap(function (open) {
            var element = _this.contentElement.nativeElement;
            var currentHeight = element.offsetHeight;
            removeClass(element, 'collapse-section__content_hidden');
            var newHeight = open ? element.scrollHeight : 0;
            addClass(element, 'collapse-section__content_animating');
            TweenMax.fromTo(element, 0.4, {
                height: currentHeight
            }, {
                height: newHeight,
                ease: Power2.easeOut,
                clearProps: 'height',
                onComplete: function () {
                    removeClass(element, 'collapse-section__content_animating');
                    toggleClass(element, 'collapse-section__content_hidden', !_this.opened);
                }
            });
        }), untilDestroyed(this))
            .subscribe();
    };
    CollapseSectionComponent.prototype.dragDrop = function (event) {
        var item = event.previousContainer.data[event.previousIndex];
        var barItem = item instanceof ElementItem ? undefined : item;
        if (barItem && barItem.popup) {
            if (this.customizeService.handler && this.customizeService.handler.createPopup) {
                this.customizeService.handler.createPopup(true, __assign({}, (barItem.defaultParams && {
                    width: barItem.defaultParams['width'],
                    style: barItem.defaultParams['style'],
                    position: barItem.defaultParams['position']
                }), { analyticsSource: 'components_library' }));
                this.elementContainerService.sendAddPopupAnalytics();
            }
            return;
        }
        var siblingLeftEntered = event.data ? !!event.data['siblingLeftEntered'] : false;
        var siblingRightEntered = event.data ? !!event.data['siblingRightEntered'] : false;
        var siblingSelf = event.data ? !!event.data['siblingSelf'] : false;
        var siblingAnchor = event.data ? event.data['siblingAnchor'] : undefined;
        var siblingAnchorContainer = event.data ? event.data['siblingAnchorContainer'] : undefined;
        if (siblingLeftEntered || siblingRightEntered) {
            var anchorContainer = siblingSelf ? event.container.data : siblingAnchorContainer.data;
            var anchorIndex = siblingSelf ? event.currentIndex : anchorContainer.indexOf(siblingAnchor);
            this.elementContainerService.dragDropIntoSiblingColumn({
                sourceContainer: event.previousContainer.data,
                sourceIndex: event.previousIndex,
                sourceCloneItem: event.previousContainer.cloneItems,
                anchorContainer: anchorContainer,
                anchorIndex: anchorIndex,
                anchorSelf: siblingSelf,
                left: siblingLeftEntered,
                context: this.context,
                parent: this.element
            });
            // TODO: Implement onAdd
            // this.cd.detectChanges();
            // const component = this.elementComponents.find(i => i.element === elementItem);
            // this.onAdd(elementItem, item, component);
        }
        else {
            if (event.previousContainer === event.container) {
                moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
            }
            else if (event.previousContainer.cloneItems) {
                var elementItem = this.elementContainerService.copyElementItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex, this.context);
                this.customizeService.registerCreatedElement(elementItem, barItem);
            }
            else {
                transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
            }
        }
        this.customizeService.markChanged();
        this.updateElementStatesOnStable();
    };
    CollapseSectionComponent.prototype.duplicateItem = function (index) {
        var _this = this;
        this.elementContainerService
            .duplicateElementItem(this.children, this.context, index)
            .pipe(untilDestroyed(this))
            .subscribe(function (elementItem) {
            _this.cd.detectChanges();
            _this.customizeService.markChanged();
            _this.updateElementStatesOnStable();
            var component = _this.elementComponents.find(function (i) { return i.element === elementItem; });
            if (component) {
                component.customize();
            }
        });
    };
    CollapseSectionComponent.prototype.replaceItem = function (index, elements) {
        this.elementContainerService.replaceElementItem(this.children, index, elements);
        this.cd.markForCheck();
        this.customizeService.markChanged();
        this.updateElementStatesOnStable();
    };
    CollapseSectionComponent.prototype.deleteItem = function (element) {
        var index = this.children.findIndex(function (item) { return item === element; });
        if (index == -1) {
            return;
        }
        this.elementContainerService.deleteElementItem(this.children, index);
        this.cd.markForCheck();
        this.customizeService.markChanged();
        this.updateElementStatesOnStable();
    };
    CollapseSectionComponent.prototype.moveItemTo = function (element, link) {
        var _this = this;
        this.deleteItem(element);
        this.customizeService.stopTrackChanges();
        this.customizeService
            .saveActualChanges()
            .pipe(untilDestroyed(this))
            .subscribe(function () { return _this.routing.navigateApp(link); });
    };
    CollapseSectionComponent.prototype.updateElementStatesOnStable = function () {
        if (this.elementGroupsContainer) {
            this.elementGroupsContainer.updateElementStatesOnStable();
        }
    };
    CollapseSectionComponent.prototype.updateElementStates = function () {
        if (this.elementGroupsContainer) {
            this.elementGroupsContainer.updateElementStates();
        }
    };
    return CollapseSectionComponent;
}());
export { CollapseSectionComponent };
