import { Injectable } from '@angular/core';
import { Option, SelectSource } from 'ng-gxselect';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { fromLegacyModel } from '@modules/models';
import { CurrentEnvironmentStore, CurrentProjectStore, Resource } from '@modules/projects';
import { isSet } from '@shared';

import { ModelDescriptionStore } from './model-description.store';

@Injectable()
export class ModelDescriptionSelectSource extends SelectSource {
  resource: string;
  allowEmpty = false;
  emptyName = '---';
  models: string[];

  private page = 1;
  private totalPages = 1;

  constructor(
    private modelDescriptionStore: ModelDescriptionStore,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore
  ) {
    super();
  }

  get emptyOption() {
    return {
      value: null,
      name: this.emptyName
    };
  }

  resourceName(resource: Resource) {
    if (!resource) {
      return;
    }

    return resource.name;
  }

  resourceImage(resource: Resource) {
    if (!resource) {
      return;
    }

    return resource.icon;
  }

  fetch(searchQuery: string): Observable<Option[]> {
    searchQuery = (searchQuery || '').trim();

    return this.modelDescriptionStore.getFirst().pipe(
      map(result => {
        if (!this.models || !result) {
          return result;
        }

        return result.filter(item => this.models.includes(item.modelId) || this.models.includes(item.model));
      }),
      map(result => {
        if (!this.resource || !result) {
          return result;
        }

        return result.filter(item => item.resource == this.resource);
      }),
      map(result => {
        this.page += 1;

        const results = result
          .filter(item =>
            isSet(searchQuery)
              ? [item.verboseName, item.verboseNamePlural, item.model, item.resource]
                  .filter(str => typeof str == 'string')
                  .some(str => str.toLowerCase().includes(searchQuery.toLowerCase()))
              : true
          )
          .reduce((acc, item) => {
            const resource = this.currentEnvironmentStore.resources.find(i => i.uniqueName == item.resource);

            if (resource && !resource.demo) {
              acc.push({
                value: { model: item.modelId },
                name: item.verboseNamePlural,
                image: this.resourceImage(resource),
                data: {
                  resource: this.resourceName(resource)
                }
              });
            }

            return acc;
          }, []);

        if (!this.allowEmpty) {
          return results;
        }

        return [this.emptyOption].concat(results);
      })
    );
  }

  fetchByValue(value: any): Observable<Option> {
    if (this.allowEmpty && value === null) {
      return of(this.emptyOption);
    }

    if (!value || !value['model']) {
      return of(undefined);
    }

    return this.modelDescriptionStore.getDetailFirst(fromLegacyModel(value['model'])).pipe(
      map(item => {
        if (!item) {
          return;
        }

        const resource = this.currentEnvironmentStore.resources.find(i => i.uniqueName == item.resource);

        if (!resource || resource.demo) {
          return;
        }

        return {
          value: { model: item.modelId },
          name: item.verboseNamePlural,
          image: this.resourceImage(resource),
          data: {
            resource: this.resourceName(resource)
          }
        };
      })
    );
  }

  isFetchAvailable(): boolean {
    return this.page <= this.totalPages;
  }

  resetPagination() {
    this.page = 1;
    this.totalPages = 1;
  }

  setStaticOptions(options: Option[]) {}
}
