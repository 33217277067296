<app-upload-template
  [upload]="isUploadable()"
  [accept]="'audio/*'"
  [name]="name"
  [control]="control"
  [autofocus]="autofocus"
  [disabled]="!storage$.value"
  [background]="background"
  [thin]="true"
  [fit]="fit"
  [cropFormat]="cropFormat"
  [cropFormatCustom]="cropFormatCustom"
  [preview]="uploadedFile?.url"
  [previewInteractive]="true"
  [uploadProgress]="uploadProgress"
  [uploadError]="uploadError"
  [accentColor]="accentColor"
  [theme]="theme"
  (clearValue)="clearCurrentValue()"
  (fileSelected)="onFileSelected($event)"
>
  <ng-container *ngIf="uploadedFile" upload-preview>
    <div class="upload__background upload__background_audio upload__background_interactive">
      <app-audio-preview *ngIf="!compact" [preview]="uploadedFile?.url"></app-audio-preview>

      <app-file-preview
        *ngIf="compact"
        [previewFilename]="uploadedFile?.filename"
        [previewExtension]="uploadedFile?.extension"
        [previewSize]="uploadFileSize"
        [theme]="theme"
      ></app-file-preview>
    </div>
  </ng-container>

  <ng-container upload-actions>
    <a
      href="javascript:void(0)"
      class="upload__button icon-cloud_download"
      (click)="$event.stopPropagation(); download()"
      [appTip]="'Download' | localize"
      [appTipOptions]="{ side: 'top', margin: -4 }"
    >
    </a>
  </ng-container>
</app-upload-template>
