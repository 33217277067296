import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';

import { ColumnsLayoutElementItem, ColumnsStyle, MarginControl } from '@modules/customize';
import { Input } from '@modules/fields';
import { FieldInputControl } from '@modules/parameters';

@Injectable()
export class CustomizeBarColumnsEditForm extends FormGroup {
  controls: {
    title: FormControl;
    style: FormControl;
    visible_input: FieldInputControl;
    margin: MarginControl;
    load_invisible: FormControl;
  };

  element: ColumnsLayoutElementItem;

  styleOptions = [
    {
      value: ColumnsStyle.Wrap,
      name: 'Wrap',
      image: 'columns-style-wrap'
    },
    {
      value: ColumnsStyle.Background,
      name: 'Background',
      image: 'columns-style-background'
    }
  ];

  constructor() {
    super({
      title: new FormControl(''),
      style: new FormControl(ColumnsStyle.Wrap),
      visible_input: new FieldInputControl({ path: ['value'] }),
      margin: new MarginControl(),
      load_invisible: new FormControl(false)
    });
  }

  init(element: ColumnsLayoutElementItem) {
    this.element = element;

    this.controls.title.patchValue(element.name ? element.name : 'Columns');
    this.controls.style.patchValue(element.style || ColumnsStyle.Wrap);
    this.controls.visible_input.patchValue(element.visibleInput ? element.visibleInput.serializeWithoutPath() : {});
    this.controls.margin.patchValue(element.margin);
    this.controls.load_invisible.patchValue(element.loadInvisible);
  }

  submit(): ColumnsLayoutElementItem {
    const instance = cloneDeep(this.element) as ColumnsLayoutElementItem;

    instance.name = this.controls.title.value;
    instance.style = this.controls.style.value;

    if (this.controls.visible_input.value) {
      instance.visibleInput = new Input().deserialize(this.controls.visible_input.value);
    } else {
      instance.visibleInput = undefined;
    }

    instance.loadInvisible = this.controls.load_invisible.value;
    instance.margin = this.controls.margin.value;

    return instance;
  }
}
