/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../shared/pipes/deploy-css-url/deploy-css-url.pipe";
import * as i2 from "./element-wrap-edit.component";
var styles_ElementWrapEditComponent = [];
var RenderType_ElementWrapEditComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ElementWrapEditComponent, data: {} });
export { RenderType_ElementWrapEditComponent as RenderType_ElementWrapEditComponent };
export function View_ElementWrapEditComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i1.DeployCssUrlPipe, []), (_l()(), i0.ɵeld(1, 0, null, null, 14, "div", [["class", "sidebar-image-radio"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 6, "div", [["class", "sidebar-image-radio__item"]], [[2, "sidebar-image-radio__item_active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.control.patchValue(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "div", [["class", "sidebar-image-radio__item-image"]], [[4, "width", "px"], [4, "height", "px"], [4, "background-image", null]], null, null, null, null)), i0.ɵppd(4, 1), (_l()(), i0.ɵeld(5, 0, null, null, 3, "div", [["class", "sidebar-image-radio__item-footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 0, "div", [["class", "sidebar-image-radio__item-radio"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 1, "div", [["class", "sidebar-image-radio__item-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Inside card"])), (_l()(), i0.ɵeld(9, 0, null, null, 6, "div", [["class", "sidebar-image-radio__item"]], [[2, "sidebar-image-radio__item_active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.control.patchValue(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 1, "div", [["class", "sidebar-image-radio__item-image"]], [[4, "width", "px"], [4, "height", "px"], [4, "background-image", null]], null, null, null, null)), i0.ɵppd(11, 1), (_l()(), i0.ɵeld(12, 0, null, null, 3, "div", [["class", "sidebar-image-radio__item-footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 0, "div", [["class", "sidebar-image-radio__item-radio"]], null, null, null, null, null)), (_l()(), i0.ɵeld(14, 0, null, null, 1, "div", [["class", "sidebar-image-radio__item-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Do not wrap"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.control.value; _ck(_v, 2, 0, currVal_0); var currVal_1 = 86; var currVal_2 = 48; var currVal_3 = i0.ɵunv(_v, 3, 2, _ck(_v, 4, 0, i0.ɵnov(_v, 0), "/assets/images/element-wrap/card-wrap.svg")); _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3); var currVal_4 = !_co.control.value; _ck(_v, 9, 0, currVal_4); var currVal_5 = 86; var currVal_6 = 48; var currVal_7 = i0.ɵunv(_v, 10, 2, _ck(_v, 11, 0, i0.ɵnov(_v, 0), "/assets/images/element-wrap/no-wrap.svg")); _ck(_v, 10, 0, currVal_5, currVal_6, currVal_7); }); }
export function View_ElementWrapEditComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-element-wrap-edit", [], null, null, null, View_ElementWrapEditComponent_0, RenderType_ElementWrapEditComponent)), i0.ɵdid(1, 114688, null, 0, i2.ElementWrapEditComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ElementWrapEditComponentNgFactory = i0.ɵccf("app-element-wrap-edit", i2.ElementWrapEditComponent, View_ElementWrapEditComponent_Host_0, { control: "control" }, {}, []);
export { ElementWrapEditComponentNgFactory as ElementWrapEditComponentNgFactory };
