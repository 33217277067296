import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';

import { outputDataToHtml } from '@common/text-editor';
import { MarginControl, TextElementItem, ViewContext, ViewContextElement } from '@modules/customize';
import { Input } from '@modules/fields';
import { FieldInputControl } from '@modules/parameters';

@Injectable()
export class CustomizeBarTextEditForm {
  element: TextElementItem;
  form = new FormGroup({
    title: new FormControl(''),
    html: new FormControl(''),
    visible_input: new FieldInputControl({ path: ['value'] }),
    card_wrap: new FormControl(true),
    margin: new MarginControl()
  });

  constructor() {}

  init(element: TextElementItem, options: { context?: ViewContext; contextElement?: ViewContextElement } = {}) {
    this.element = element;

    const value = {
      title: element.name ? element.name : 'Text',
      visible_input: element.visibleInput ? element.visibleInput.serializeWithoutPath() : {},
      card_wrap: element.cardWrap,
      margin: element.margin
    };

    this.form.patchValue(value, { emitEvent: false });

    outputDataToHtml(element.markup, options).subscribe(html => {
      this.form.controls['html'].patchValue(html);
    });
  }

  submit(): TextElementItem {
    const value = this.form.value;
    const instance = cloneDeep(this.element) as TextElementItem;

    instance.name = value['title'];

    if (value['visible_input']) {
      instance.visibleInput = new Input().deserialize(value['visible_input']);
    } else {
      instance.visibleInput = undefined;
    }

    instance.cardWrap = value['card_wrap'];
    instance.margin = value['margin'];

    return instance;
  }
}
