import { Resource, ResourceName } from '@modules/projects';
import { FirebaseRealTime } from '@modules/resources';

import { DataSyncJob } from '../data/data-sync-job';

export interface DataSyncJobRealtimeState {
  enabled: boolean;
  status?: string;
  description?: string;
}

export function getDataSyncJobRealtimeState(resource: Resource, job: DataSyncJob): DataSyncJobRealtimeState {
  if (!job) {
    return { enabled: false };
  }

  const firebaseRealTime =
    resource.typeItem.name == ResourceName.Firebase
      ? resource.params['firebase_real_time'] || FirebaseRealTime.Snapshots
      : undefined;

  if ([ResourceName.Airtable].includes(resource.typeItem.name)) {
    return { enabled: true };
  } else if (resource.typeItem.name == ResourceName.Firebase && firebaseRealTime != FirebaseRealTime.Disabled) {
    return { enabled: true };
  } else if (resource.typeItem.name == ResourceName.GoogleSheets) {
    return {
      enabled: true,
      status: '0 to 3 min',
      description:
        'The Google Drive Watch API notifies of changes as soon as they are saved, but at most once every 3 minutes'
    };
  } else {
    return { enabled: false };
  }
}
