var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';
import { debounceTime, filter, switchMap } from 'rxjs/operators';
import { DialogService } from '@common/dialogs';
import { NotificationService } from '@common/notifications';
import { UniversalAnalyticsService } from '@modules/analytics';
import { CustomViewType } from '@modules/custom-views';
import { AFTER_ITEM_OUTPUT, BEFORE_ITEM_OUTPUT, COLUMN_FROM_OUTPUT, COLUMN_OUTPUT, COLUMN_TO_OUTPUT, ITEM_OUTPUT, ORDER_OUTPUT, PREV_AFTER_ITEM_OUTPUT, PREV_BEFORE_ITEM_OUTPUT, PREV_COLUMN_OUTPUT, PREV_ORDER_OUTPUT, SELECTED_ITEM_OUTPUT } from '@modules/list';
import { SidebarCollapseContext } from '@modules/sidebar';
import { CustomViewTemplateType } from '@modules/views';
import { isSet } from '@shared';
import { CustomizeBarContext } from '../../../services/customize-bar-context/customize-bar.context';
import { CustomizeBarListLayoutSettingsComponent } from '../customize-bar-list-layout-settings/customize-bar-list-layout-settings.component';
import { CustomizeBarKanbanBoardSettingsDataSourceComponent } from './customize-bar-kanban-board-settings-data-source/customize-bar-kanban-board-settings-data-source.component';
import { CustomizeBarKanbanBoardSettingsForm } from './customize-bar-kanban-board-settings.form';
var CustomizeBarKanbanBoardSettingsComponent = /** @class */ (function (_super) {
    __extends(CustomizeBarKanbanBoardSettingsComponent, _super);
    function CustomizeBarKanbanBoardSettingsComponent(form, notificationService, customizeBarContext, dialogService, analyticsService, cd) {
        var _this = _super.call(this, form, customizeBarContext, dialogService, analyticsService, cd) || this;
        _this.form = form;
        _this.notificationService = notificationService;
        _this.itemContextElementPath = [ITEM_OUTPUT];
        _this.selectedItemContextElementPath = [SELECTED_ITEM_OUTPUT];
        _this.itemColumnChangeContextElementPaths = [[ITEM_OUTPUT], [COLUMN_FROM_OUTPUT], [COLUMN_TO_OUTPUT]];
        _this.itemOrderChangeContextElementPaths = [
            [ITEM_OUTPUT],
            [ORDER_OUTPUT],
            [BEFORE_ITEM_OUTPUT],
            [AFTER_ITEM_OUTPUT],
            [COLUMN_OUTPUT],
            [PREV_ORDER_OUTPUT],
            [PREV_BEFORE_ITEM_OUTPUT],
            [PREV_AFTER_ITEM_OUTPUT],
            [PREV_COLUMN_OUTPUT]
        ];
        _this.dataSourceEditComponent = CustomizeBarKanbanBoardSettingsDataSourceComponent;
        _this.collapseContext = new SidebarCollapseContext();
        _this.actionsRequiredFields = [
            'card_click_action',
            'card_column_change_action',
            'card_order_change_action',
            'actions',
            'model_actions'
        ];
        _this.addMissingLoading = false;
        _this.customViewTypes = CustomViewType;
        _this.customViewTemplateTypes = CustomViewTemplateType;
        return _this;
    }
    CustomizeBarKanbanBoardSettingsComponent.prototype.trackActionOutput = function (i, item) {
        return item.name;
    };
    CustomizeBarKanbanBoardSettingsComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.form.context = this.context;
        this.form.contextElement = this.contextElement;
        this.columnParameters$ = this.form.controls.data_source.controls.columns.getParameters$();
        this.stageTitleTextStyleIsDefault = this.form.controls.element_styles.controls.stage_title_style.isDefaultStyle$();
        this.customViewDefaults = this.form.getCustomViewDefaults();
        combineLatest(this.form.controls.data_source.getModelDescription$(), this.form.controls.stage_field.valueChanges)
            .pipe(filter(function (_a) {
            var modelDescription = _a[0], stage = _a[1];
            return isSet(modelDescription) && isSet(stage);
        }), debounceTime(10), switchMap(function () { return _this.form.getMissing(); }))
            .subscribe(function (result) {
            if (result && result.length) {
                _this.form.controls.stages.addMissingByValues(result);
            }
        });
        this.form
            .getCardCustomView$()
            .pipe(untilDestroyed(this))
            .subscribe(function (customView) {
            _this.cardCustomView = customView;
            _this.cd.markForCheck();
        });
    };
    CustomizeBarKanbanBoardSettingsComponent.prototype.createStage = function () {
        this.createdStage = this.form.controls.stages.appendControl();
        this.cd.markForCheck();
    };
    CustomizeBarKanbanBoardSettingsComponent.prototype.removeStage = function (group, i) {
        // this.dialogService
        //   .warning({
        //     title: 'Delete Stage',
        //     description: `Are you sure want to delete <strong>Stage #${i + 1}</strong>?`
        //   })
        //   .pipe(untilDestroyed(this))
        //   .subscribe(result => {
        //     if (result) {
        // this.form.formArrayRemove('stages', group);
        this.form.controls.stages.removeControl(group);
        this.cd.markForCheck();
        //   }
        // });
    };
    CustomizeBarKanbanBoardSettingsComponent.prototype.addMissing = function () {
        var _this = this;
        this.addMissingLoading = true;
        this.cd.markForCheck();
        this.form
            .getMissing()
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            if (!result) {
                _this.notificationService.error('Failed', 'Query failed');
                return;
            }
            if (result.length == 0) {
                _this.notificationService.warning('No missing values', 'All found values are already added');
            }
            else {
                var maxValues = 10;
                var values = result;
                if (result.length > maxValues) {
                    values = values.slice(0, maxValues);
                    _this.notificationService.success('Missing values added', "\n                Found <strong>" + result.length + "</strong> missing values,\n                added first <strong>" + values.length + "</strong> of them\n              ");
                }
                else {
                    _this.notificationService.success('Missing values added', "\n                <strong>" + values.length + "</strong> values were added\n              ");
                }
                _this.form.controls.stages.addMissingByValues(values);
            }
            _this.addMissingLoading = false;
            _this.cd.markForCheck();
        }, function () {
            _this.addMissingLoading = false;
            _this.cd.markForCheck();
        });
    };
    CustomizeBarKanbanBoardSettingsComponent.prototype.dragDrop = function (event) {
        if (event.previousIndex !== event.currentIndex) {
            moveItemInArray(this.form.controls.stages.controls, event.previousIndex, event.currentIndex);
            this.form.controls.stages.updateValueAndValidity();
        }
    };
    CustomizeBarKanbanBoardSettingsComponent.prototype.onCustomViewSelected = function (customView) {
        this.form.controls.card_view_unique_name.patchValue(customView ? customView.uniqueName : undefined);
    };
    return CustomizeBarKanbanBoardSettingsComponent;
}(CustomizeBarListLayoutSettingsComponent));
export { CustomizeBarKanbanBoardSettingsComponent };
