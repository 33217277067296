<div class="view-editor__data-menu" [class.view-editor__data-menu_visible]="opened" (click)="markClickEvent($event)">
  <div class="view-editor__data-menu-viewport" xsScrollable [xsScrollableOptions]="{ stopPropagationAlways: true }">
    <app-sidebar-section>
      <div class="sidebar__list">
        <app-sidebar-field [label]="'Component State'" [subtitle]="'You can specify test values below'">
          <div class="sidebar-list">
            <div *ngIf="stateSelectedEnabled" class="sidebar-list__item">
              <app-view-editor-parameters-value-item
                [title]="(componentLabel | appCapitalize) + ' is selected'"
                [icon]="'select'"
                [fieldType]="fieldTypes.Boolean"
                [value]="testState[SELECTED_OUTPUT]"
                [collapseContext]="stateCollapseContext"
                (valueUpdated)="onStateValueChange(SELECTED_OUTPUT, $event)"
              ></app-view-editor-parameters-value-item>
            </div>

            <div class="sidebar-list__item">
              <app-view-editor-parameters-value-item
                [title]="'Dark theme'"
                [icon]="'toggle_theme'"
                [fieldType]="fieldTypes.Boolean"
                [value]="testState[DARK_THEME_OUTPUT]"
                [collapseContext]="stateCollapseContext"
                (valueUpdated)="onStateValueChange(DARK_THEME_OUTPUT, $event)"
              ></app-view-editor-parameters-value-item>
            </div>
          </div>
        </app-sidebar-field>
      </div>

      <div class="sidebar__list">
        <app-sidebar-field
          [label]="'Component Parameters'"
          [subtitle]="'You can customize parameters and specify test values below'"
        >
          <app-parameters-edit
            [control]="form.controls.parameters"
            [parametersValueEditable]="true"
            [parametersValue]="form.controls.test_parameters.value"
            [placeholder]="'Test value'"
            [bannerTitle]="'Component parameters'"
            [bannerDescription]="'Use component parameters to pass data from resources or other components.'"
            [configurable]="
              parametersEditable
                ? {
                    name: true,
                    field: true,
                    required: true,
                    add: true,
                    sortable: true
                  }
                : {}
            "
            [emptyBanner]="true"
            [analyticsSource]="analyticsSource"
            (parameterValueUpdated)="form.updateTestParametersValue($event.name, $event.value)"
          >
          </app-parameters-edit>
        </app-sidebar-field>
      </div>
    </app-sidebar-section>
  </div>
</div>
