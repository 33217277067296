import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { combineLatest, Observable } from 'rxjs';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';

import { ProjectsStore } from '@modules/projects';
import { SSOSettings } from '@modules/sso';
import { CurrentUserStore } from '@modules/users';

import { ApiService, TokenOptions } from '../../../api/services/api/api.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private http: HttpClient,
    private projectsStore: ProjectsStore,
    private currentUserStore: CurrentUserStore,
    private apiService: ApiService
  ) {}

  login(
    username: string,
    password: string,
    options: {
      projectName?: string;
      projectToken?: string;
      transferDemoProjects?: string[];
    } = {}
  ): Observable<boolean> {
    const url = this.apiService.methodURL('token/');
    const data = {
      username: username,
      password: password
    };

    if (options.projectName) {
      data['project'] = options.projectName;
    }

    if (options.projectToken) {
      data['project_token'] = options.projectToken;
    }

    if (options.transferDemoProjects) {
      data['transfer_projects'] = options.transferDemoProjects.join(',');
    }

    return this.http.post(url, data).pipe(
      switchMap(result => {
        this.apiService.saveToken({
          token: result['token'],
          accessToken: result['access_token'],
          accessTokenExpires: moment(result['access_token_expires']),
          refreshToken: result['refresh_token'],
          refreshTokenExpires: moment(result['refresh_token_expires']),
          serverTime: moment(result['server_time'])
        });

        return combineLatest([this.currentUserStore.getFirst(true), this.projectsStore.updateIfLoaded()]);
      }),
      map(() => true),
      this.apiService.catchApiError(false),
      publishLast(),
      refCount()
    );
  }

  customLogin(
    sso: SSOSettings,
    username: string,
    password: string,
    options: {
      projectName?: string;
      projectToken?: string;
      transferDemoProjects?: string[];
      language?: string;
    } = {}
  ): Observable<boolean> {
    const url = this.apiService.methodURL(`custom_login/${sso.uid}/`);
    const data = {
      username: username,
      password: password
    };

    if (options.projectName) {
      data['project'] = options.projectName;
    }

    if (options.projectToken) {
      data['project_token'] = options.projectToken;
    }

    if (options.transferDemoProjects) {
      data['transfer_projects'] = options.transferDemoProjects.join(',');
    }

    if (options.language) {
      data['language'] = options.language;
    }

    return this.http.post(url, data).pipe(
      switchMap(result => {
        this.apiService.saveToken({
          token: result['token'],
          accessToken: result['access_token'],
          accessTokenExpires: moment(result['access_token_expires']),
          refreshToken: result['refresh_token'],
          refreshTokenExpires: moment(result['refresh_token_expires']),
          serverTime: moment(result['server_time'])
        });

        return combineLatest([this.currentUserStore.getFirst(true), this.projectsStore.updateIfLoaded()]);
      }),
      map(() => true),
      this.apiService.catchApiError(false),
      publishLast(),
      refCount()
    );
  }

  generateToken(): Observable<TokenOptions> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.methodURL('token/');
        const params = { json: '1' };
        let headers = new HttpHeaders();

        headers = this.apiService.setHeadersToken(headers);

        return this.http.get(url, { headers: headers, params: params });
      }),
      map(result => {
        return {
          token: result['token'],
          accessToken: result['access_token'],
          accessTokenExpires: moment(result['access_token_expires']),
          refreshToken: result['refresh_token'],
          refreshTokenExpires: moment(result['refresh_token_expires']),
          serverTime: moment(result['server_time']),
          incognito: this.apiService.getIncognito()
        };
      }),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  logout() {
    this.apiService.deleteToken();
    this.apiService.deleteProjectToken();
    this.apiService.deleteProjectAccessTokens();
    this.currentUserStore.reset();
    this.projectsStore.updateIfLoaded();
  }

  tokenLogin(tokenOptions: TokenOptions) {
    this.saveToken(tokenOptions);
    return combineLatest([this.currentUserStore.getFirst(true), this.projectsStore.updateIfLoaded()]).pipe(
      map(() => true)
    );
  }

  saveToken(tokenOptions: TokenOptions) {
    this.apiService.saveToken(tokenOptions);
  }

  isAuthorized(): Observable<boolean> {
    return this.currentUserStore.get().pipe(map(user => user !== undefined));
  }

  isUserSelfAuthorized(): Observable<boolean> {
    return this.currentUserStore.get().pipe(map(user => user !== undefined && !user.isAnonymous()));
  }

  isUserAuthorized(): Observable<boolean> {
    return this.isUserSelfAuthorized().pipe(map(authorized => authorized && this.apiService.isUserToken()));
  }

  isProjectAuthorized(): Observable<boolean> {
    return this.isAuthorized().pipe(map(authorized => authorized && this.apiService.isProjectToken()));
  }
}
