import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-element-wrap-edit',
  templateUrl: './element-wrap-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ElementWrapEditComponent implements OnInit {
  @Input() control: FormControl;

  constructor() {}

  ngOnInit() {}
}
