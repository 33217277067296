import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import cloneDeep from 'lodash/cloneDeep';
import toPairs from 'lodash/toPairs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, Observable, of } from 'rxjs';
import { delayWhen, filter, map, switchMap, tap } from 'rxjs/operators';

import { NotificationService } from '@common/notifications';
import { UniversalAnalyticsService } from '@modules/analytics';
import {
  CustomView,
  CustomViewDataView,
  CustomViewService,
  CustomViewSource,
  CustomViewsStore,
  CustomViewType
} from '@modules/custom-views';
import { Input as FieldInput, InputValueType } from '@modules/fields';
import { IDEController, IDEService } from '@modules/ide-components';
import { MenuBlockLayout, MenuBlockLayouts } from '@modules/menu';
import { MenuContext } from '@modules/menu-components';
import { ViewContextTokenProvider } from '@modules/parameters-components';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { CurrentUserStore } from '@modules/users';
import { CustomViewTemplateType, Frame, View } from '@modules/views';
import { CustomViewTemplatesController, ViewEditorController } from '@modules/views-components';
import { controlValue, isSet } from '@shared';

import { AddMenuItemOptions } from '../add-menu-item-menu/add-menu-item-menu.component';
import { CustomizeBarPagesEditCustomControl } from '../customize-bar-pages-edit/custom-control';
import { CustomizeBarPagesEditMenuItemControl } from '../customize-bar-pages-edit/customize-bar-pages-edit.form';

@Component({
  selector: 'app-custom-menu-item-edit',
  templateUrl: './custom-menu-item-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomMenuItemEditComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() control: CustomizeBarPagesEditCustomControl;
  @Input() layout: MenuBlockLayout;
  @Input() createdOptions: AddMenuItemOptions;
  @Output() deleteRequested = new EventEmitter<void>();

  @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;

  loading = false;
  customView: CustomView;
  customViewsShared: CustomView[] = [];
  valueShared = false;
  editPopoverOpened = false;
  analyticsSource = 'custom_menu_item';
  sources = CustomViewSource;

  trackMenuItemFn(i, item: CustomizeBarPagesEditMenuItemControl) {
    return item.menuItem.id;
  }

  constructor(
    public currentUserStore: CurrentUserStore,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private context: MenuContext,
    private contextTokenProvider: ViewContextTokenProvider,
    private customViewService: CustomViewService,
    private customViewsStore: CustomViewsStore,
    private customViewTemplatesController: CustomViewTemplatesController,
    private viewEditorController: ViewEditorController,
    private ideService: IDEService,
    private ideController: IDEController,
    private notificationService: NotificationService,
    private analyticsService: UniversalAnalyticsService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    combineLatest(this.customViewsStore.get(), controlValue<string>(this.control.controls.custom_view))
      .pipe(untilDestroyed(this))
      .subscribe(([customViews, customView]) => {
        this.customView = isSet(customView) ? customViews.find(item => item.uniqueName == customView) : undefined;
        this.customViewsShared = customViews.filter(item => item.shared);
        this.valueShared = isSet(customView)
          ? this.customViewsShared.some(item => item.uniqueName == customView)
          : undefined;
        this.cd.markForCheck();
        this.control.setInputsParameters(this.customView ? this.customView.parameters : []);
      });

    this.control.inputFieldProvider.getFields$().subscribe(() => {
      this.control.controls.inputs.updateValueAndValidity();
    });
  }

  ngOnDestroy(): void {
    this.control.inputFieldProvider.clearProvider();
  }

  ngAfterViewInit(): void {
    if (this.createdOptions && this.createdOptions.customize && this.menuTrigger) {
      this.menuTrigger.openMenu();
    }
  }

  onTitleChanged(value: string) {
    this.control.controls.title.patchValue(value);
    this.cd.markForCheck();
  }

  openEditPopover(value) {
    this.editPopoverOpened = value;
    this.cd.markForCheck();
  }

  createView(): View {
    const result = new View();

    result.generateId();
    result.frame = MenuBlockLayouts.isHorizontal(this.layout)
      ? new Frame({ width: 120, height: 50 })
      : new Frame({ width: 250, height: 50 });

    return result;
  }

  updateOrCreateCustomView(options: {
    source: CustomViewSource;
    beforeSave?: (customView: CustomView, fields: string[]) => void;
  }): Observable<CustomView> {
    const existingInstance = this.customView;
    const uniqueName = this.control.controls.custom_view.value;
    const instance = existingInstance || new CustomView();

    if (isSet(uniqueName)) {
      instance.uniqueName = uniqueName;
    } else {
      instance.uniqueName = CustomView.generateUniqueName();
    }

    instance.viewType = CustomViewType.MenuItem;
    instance.source = options.source;

    if (!existingInstance && options.source == CustomViewSource.CustomElement) {
      instance.filesIde = true;
    }

    const fields = ['unique_name', 'view_type', 'source', 'params'];

    if (options.beforeSave) {
      options.beforeSave(instance, fields);
    }

    const obs$ = existingInstance
      ? this.customViewService.update(
          this.currentProjectStore.instance.uniqueName,
          this.currentEnvironmentStore.instance.uniqueName,
          instance,
          { draft: true, fields: fields }
        )
      : this.customViewService.create(
          this.currentProjectStore.instance.uniqueName,
          this.currentEnvironmentStore.instance.uniqueName,
          instance,
          { draft: true, fields: fields }
        );

    return obs$.pipe(tap(customView => this.customViewsStore.updateOrAddItem(customView)));
  }

  openViewEditor(customView?: CustomView) {
    let view = customView ? customView.view : undefined;
    const create = !view;

    if (!view) {
      view = this.createView();
    }

    const data$ = customView
      ? of<CustomViewDataView>({
          source: CustomViewSource.View,
          view: view,
          name: customView.name,
          parameters: customView.parameters,
          actions: customView.actions,
          testParameters: customView.testParameters
        })
      : this.customViewsStore.generateName().pipe(
          map<string, CustomViewDataView>(name => ({
            source: CustomViewSource.View,
            view: view,
            name: name
          }))
        );

    data$
      .pipe(
        switchMap(data => {
          return this.viewEditorController.open({
            create: create,
            data: data,
            componentLabel: this.control.controls.title.value,
            submitLabel: create ? 'Create menu item' : 'Save changes',
            nameEditingEnabled: true,
            analyticsSource: this.analyticsSource
          });
        }),
        filter(result => !result.cancelled),
        switchMap(result => {
          if (result.useShared) {
            return of(result.useShared);
          } else {
            this.loading = true;
            this.cd.markForCheck();

            return this.updateOrCreateCustomView({
              source: CustomViewSource.View,
              beforeSave: (instance, fields) => {
                instance.name = result.data.name;
                instance.view = result.data.view;
                instance.parameters = result.data.parameters;
                instance.actions = result.data.actions;
                instance.testParameters = result.data.testParameters;

                fields.push('name', 'view');
              }
            });
          }
        }),
        untilDestroyed(this)
      )
      .subscribe(
        result => {
          this.loading = false;
          this.cd.markForCheck();

          this.selectCustomView(result);
        },
        () => {
          this.loading = false;
          this.cd.markForCheck();
        }
      );
  }

  openIDE(customView?: CustomView) {
    if (customView) {
      this.loading = true;
      this.cd.markForCheck();

      return this.ideController
        .open({
          projectName: this.currentProjectStore.instance.uniqueName,
          environmentName: this.currentEnvironmentStore.instance.uniqueName,
          customViewName: customView.uniqueName
        })
        .pipe(untilDestroyed(this))
        .subscribe(
          () => {
            this.loading = false;
            this.cd.markForCheck();
          },
          error => {
            console.error(error);
            this.loading = false;
            this.cd.markForCheck();
          }
        );
    } else {
      this.loading = true;
      this.cd.markForCheck();

      this.updateOrCreateCustomView({
        source: CustomViewSource.CustomElement
      })
        .pipe(
          tap(result => {
            this.selectCustomView(result);
          }),
          switchMap(result => {
            return this.ideController.open({
              projectName: this.currentProjectStore.instance.uniqueName,
              environmentName: this.currentEnvironmentStore.instance.uniqueName,
              customViewName: result.uniqueName
            });
          }),
          untilDestroyed(this)
        )
        .subscribe(
          () => {
            this.loading = false;
            this.cd.markForCheck();
          },
          error => {
            console.error(error);
            this.loading = false;
            this.cd.markForCheck();
          }
        );
    }
  }

  openCustomViewTemplates() {
    this.customViewTemplatesController
      .chooseTemplate({
        initialFilter: {
          type: CustomViewTemplateType.MenuItem
        },
        viewCreateEnabled: true,
        componentLabel: this.control.controls.title.value,
        analyticsSource: this.analyticsSource
      })
      .pipe(
        filter(result => !result.cancelled),
        switchMap(result => {
          if (result.useShared) {
            return of(result.useShared);
          } else {
            this.loading = true;
            this.cd.markForCheck();

            return this.updateOrCreateCustomView({
              source: CustomViewSource.View,
              beforeSave: (instance, fields) => {
                instance.name = result.data.name;
                instance.view = result.data.view;
                instance.parameters = result.data.parameters;
                instance.actions = result.data.actions;
                instance.testParameters = result.data.testParameters;

                fields.push('name', 'view');
              }
            });
          }
        }),
        untilDestroyed(this)
      )
      .subscribe(
        customView => {
          this.loading = false;
          this.cd.markForCheck();
          this.selectCustomView(customView);
        },
        () => {
          this.loading = false;
          this.cd.markForCheck();
        }
      );
  }

  updateCustomViewTemplate(customView: CustomView) {
    const view = customView ? customView.view : undefined;

    if (!view) {
      return;
    }

    this.customViewTemplatesController
      .setTemplateView(view, { componentLabel: this.control.controls.title.value })
      .pipe(
        filter(result => !result.cancelled),
        untilDestroyed(this)
      )
      .subscribe();
  }

  cloneCustomView(customView: CustomView, options: { shared?: boolean }) {
    const instance = new CustomView();

    instance.uniqueName = CustomView.generateUniqueName();
    instance.name = customView.name;
    instance.viewType = CustomViewType.MenuItem;
    instance.source = customView.source;
    instance.parameters = cloneDeep(customView.parameters);
    instance.actions = cloneDeep(customView.actions);
    instance.testParameters = cloneDeep(customView.testParameters);
    instance.shared = options.shared;

    if (customView.source == CustomViewSource.View) {
      instance.view = customView.view;
    } else if (customView.source == CustomViewSource.HTML) {
      instance.html = customView.html;
    } else if (customView.source == CustomViewSource.CustomElement) {
      instance.buildId = customView.buildId;
      instance.tagName = customView.tagName;
      instance.baseTagName = customView.baseTagName;
      instance.filesJs = cloneDeep(customView.filesJs);
      instance.filesCss = cloneDeep(customView.filesCss);
    }

    return this.customViewService
      .create(
        this.currentProjectStore.instance.uniqueName,
        this.currentEnvironmentStore.instance.uniqueName,
        instance,
        { draft: true }
      )
      .pipe(
        delayWhen(result => {
          if (customView.source == CustomViewSource.CustomElement) {
            return this.ideService.clone(
              this.currentProjectStore.instance.uniqueName,
              this.currentEnvironmentStore.instance.uniqueName,
              customView.uniqueName,
              result.uniqueName
            );
          } else {
            return of(undefined);
          }
        }),
        tap(result => this.customViewsStore.addItem(result))
      );
  }

  createCustomViewShared(customView: CustomView) {
    this.loading = true;
    this.cd.markForCheck();

    this.cloneCustomView(customView, { shared: true })
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          this.loading = false;
          this.cd.markForCheck();

          this.selectCustomView(result);
        },
        () => {
          this.loading = false;
          this.cd.markForCheck();
        }
      );
  }

  detachCustomViewShared(customView: CustomView) {
    this.loading = true;
    this.cd.markForCheck();

    this.cloneCustomView(customView, { shared: false })
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          this.loading = false;
          this.cd.markForCheck();

          this.selectCustomView(result);
        },
        () => {
          this.loading = false;
          this.cd.markForCheck();
        }
      );
  }

  getCustomViewTestParameters(customView: CustomView): FieldInput[] {
    return toPairs(customView.testParameters).map(([name, value]) => {
      const result = new FieldInput();

      result.path = [name];
      result.valueType = InputValueType.StaticValue;
      result.staticValue = value;

      return result;
    });
  }

  selectCustomView(customView?: CustomView) {
    if (customView) {
      this.control.patchValue({
        custom_view: customView.uniqueName,
        ...(customView.uniqueName != this.control.controls.custom_view.value && {
          inputs: this.getCustomViewTestParameters(customView)
        })
      });
    } else {
      this.control.patchValue({
        custom_view: undefined,
        inputs: {}
      });
    }
  }
}
