import { select, Selection } from 'd3-selection';

export function getAxisWidth(axis: any): number {
  let result = 0;

  axis.selectAll('text').each(function () {
    if (this.getBBox().width > result) {
      result = this.getBBox().width;
    }
  });

  return result;
}

export function fitXAxisLabelWithWidth(
  axis: Selection<SVGElement, any, any, any>,
  domain: any[],
  maxWidth: number,
  hiddenClass?: string
) {
  axis
    .selectAll<SVGTextElement, any>('.tick text')
    .each((datum, index, selection) => {
      const el = selection[index];
      addTextOverflowEllipsis(select(el), maxWidth, 1, hiddenClass);
    })
    .append('title')
    .text((datum, index) => {
      return domain[index];
    });
}

export function fitXAxisLabelWithVisibility(axis: Selection<SVGElement, any, any, any>, canvas: Element, padding = 8) {
  const canvasBounds = canvas.getBoundingClientRect();
  const nodes = axis.selectAll<SVGTextElement, any>('.tick text').nodes();

  if (!nodes.length) {
    return;
  }

  const nodeFirst = nodes[0];
  const nodeLast = nodes.length > 1 ? nodes[nodes.length - 1] : undefined;
  const rectFirst = nodeFirst.getBoundingClientRect();
  const rectLast = nodeLast ? nodeLast.getBoundingClientRect() : undefined;

  if (rectFirst.left < canvasBounds.left) {
    select(nodeFirst).attr('x', canvasBounds.left - rectFirst.left);
  }

  if (nodeLast && rectLast.right > canvasBounds.right) {
    select(nodeLast).attr('x', 0 - (rectLast.right - canvasBounds.right));
  }

  const labels = nodes.length;

  for (let skip = 0; skip < labels; ++skip) {
    let lastEnd = 0;
    const fit = nodes
      .filter((el, i) => i % (skip + 1) == 0)
      .every((el, i) => {
        const rect = el.getBoundingClientRect();
        const rectCollapses = rect.left <= lastEnd + padding;

        lastEnd = rect.right;

        return !rectCollapses;
      });

    if (fit) {
      axis
        .selectAll<SVGTextElement, any>('.tick')
        .filter((el, i) => i % (skip + 1) != 0)
        .remove();

      break;
    }
  }
}

export function addTextOverflowEllipsis(
  textSel: Selection<SVGTextElement, any, any, any>,
  width: number,
  padding = 0,
  hiddenClass?: string
) {
  if (!textSel.text().length) {
    if (hiddenClass) {
      textSel.node().classList.remove('chart-axis-label_hidden');
    }

    return;
  }

  const initialText = textSel.text();
  let text = initialText;

  while (true) {
    const textLength = textSel.node().getComputedTextLength();

    if (textLength <= width - 2 * padding) {
      if (hiddenClass) {
        textSel.node().classList.remove('chart-axis-label_hidden');
      }

      break;
    }

    text = text.slice(0, -1);
    textSel.text(text + '...');

    if (text.length == 0) {
      if (hiddenClass) {
        textSel.text(initialText);
        textSel.node().classList.add('chart-axis-label_hidden');
      }

      break;
    }
  }
}
