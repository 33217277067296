import { ComponentFactoryResolver, Injectable, Injector } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

import { CLOSE_BY_BACKGROUND_CLICK, ThinDialogPopupComponent } from '@common/dialog-popup';
import { CLOSE_BY_DEACTIVATE, PopupService } from '@common/popups';
import { Resource } from '@modules/projects';
import { Storage } from '@modules/storages';
import { getFilenameWithExtension } from '@shared';

import {
  StorageRemovePopupComponent,
  StorageRemoveState
} from '../../components/storage-remove-popup/storage-remove-popup.component';

@Injectable()
export class StorageRemovePopupController {
  constructor(private popupService: PopupService) {}

  remove(options: {
    paths: string[];
    resource: Resource;
    storage: Storage;
    injector: Injector;
    resolver: ComponentFactoryResolver;
  }): Observable<StorageRemoveState> {
    const obs$ = new ReplaySubject<StorageRemoveState>();
    const getCancelledState = (): StorageRemoveState => {
      return {
        files: options.paths.map(item => {
          return {
            name: getFilenameWithExtension(item),
            path: item
          };
        }),
        processed: 0,
        succeeded: 0,
        failed: 0,
        cancelled: true
      };
    };

    this.popupService.push({
      component: StorageRemovePopupComponent,
      popupComponent: ThinDialogPopupComponent,
      popupComponentOrange: true,
      inputs: {
        paths: options.paths,
        resource: options.resource,
        storage: options.storage
      },
      outputs: {
        finished: [
          result => {
            obs$.next(result);
          }
        ],
        cancelled: [
          result => {
            obs$.next(result);
          }
        ]
      },
      popupClosed: data => {
        if (data == CLOSE_BY_BACKGROUND_CLICK || data == CLOSE_BY_DEACTIVATE) {
          obs$.next(getCancelledState());
        }
      },
      resolver: options.resolver,
      injector: options.injector
    });

    return obs$;
  }
}
