import { moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  QueryList,
  SimpleChanges,
  ViewChildren
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import fromPairs from 'lodash/fromPairs';
import isEqual from 'lodash/isEqual';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, Observable, of, Subscription } from 'rxjs';
import { debounceTime, filter, map } from 'rxjs/operators';

import { AppDrag, AppDragDrop, AppDropList, DropListOrientation } from '@common/drag-drop2';
import { DocumentService } from '@core';
import { TintStyle } from '@modules/actions';
import { UniversalAnalyticsService } from '@modules/analytics';
import { CustomizeBarItem } from '@modules/change-components/data/customize-bar-item';
import {
  CustomizeService,
  ElementItem,
  ElementType,
  registerElementComponent,
  TabsAppearance,
  TabsLayoutElementItem,
  TabsLayoutItem,
  TabsOrientation,
  TabsStyle,
  traverseElementItems
} from '@modules/customize';
import { AutoElementComponent, BaseElementComponent, ElementContainerService } from '@modules/customize-elements';
import { applyBooleanInput$, Input as FieldInput } from '@modules/fields';
import { RoutingService } from '@modules/routing';
import { isSet } from '@shared';

import { ElementGroupsContainerDirective } from '../../directives/element-groups-container/element-groups-container.directive';
import { FormElementComponent } from '../form-element/form-element.component';
import { RootLayoutComponent } from '../root-layout/root-layout.component';

@Component({
  selector: 'app-tabs-layout',
  templateUrl: './tabs-layout.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TabsLayoutComponent extends BaseElementComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() element: TabsLayoutElementItem;

  @ViewChildren(ElementGroupsContainerDirective) elementGroupsContainer = new QueryList<
    ElementGroupsContainerDirective
  >();
  @ViewChildren(AutoElementComponent) elementComponents = new QueryList<AutoElementComponent>();

  activeItemUid: string;
  itemsOpened: Object = {};

  subscription: Subscription;
  customizeEnabled$: Observable<boolean>;
  isMobile$: Observable<boolean>;
  isVisible = false;
  isVisibleSubscription: Subscription;
  tabsVisible$ = new BehaviorSubject<{ tab: TabsLayoutItem; visible: boolean }[]>([]);
  tabsVisibleById$ = new BehaviorSubject<{ [k: string]: boolean }>({});
  dropListOrientation = DropListOrientation;
  tabsOrientations = TabsOrientation;
  tabsStyles = TabsStyle;
  tabsAppearances = TabsAppearance;
  tintStyles = TintStyle;

  canEnter = (() => {
    return (drag: AppDrag, drop: AppDropList): boolean => {
      if (this.rootLayoutComponent && !this.rootLayoutComponent.active) {
        return false;
      }

      if (drag.data instanceof ElementItem && drag.data.type == ElementType.FormSubmit) {
        let isInsideCurrentForm = false;

        if (this.formElementComponent) {
          traverseElementItems(this.formElementComponent.element, item => {
            if (item === drag.data) {
              isInsideCurrentForm = true;
            }
          });
        }

        return isInsideCurrentForm;
      } else {
        return true;
      }
    };
  })();

  trackTab = (() => {
    return (i, item: TabsLayoutItem) => {
      const pageUid = this.context && this.context.viewSettings ? this.context.viewSettings.uid : undefined;
      return [pageUid, item.uid].join('_');
    };
  })();

  trackElement = (() => {
    return (i, item: ElementItem) => {
      const pageUid =
        this.context && this.context.viewSettings && !this.context.viewSettings.newlyCreated
          ? this.context.viewSettings.uid
          : undefined;
      return [pageUid, item.uid].join('_');
    };
  })();

  constructor(
    public customizeService: CustomizeService,
    private elementContainerService: ElementContainerService,
    private routing: RoutingService,
    private analyticsService: UniversalAnalyticsService,
    @Optional() private rootLayoutComponent: RootLayoutComponent,
    @Optional() private formElementComponent: FormElementComponent,
    private documentService: DocumentService,
    private cd: ChangeDetectorRef,
    private routingService: RoutingService,
    private activatedRoute: ActivatedRoute
  ) {
    super();
  }

  ngOnInit() {
    this.initVisibleObserver();

    this.customizeEnabled$ = this.customizeService.enabled$.pipe(map(item => !!item));
    this.isMobile$ = this.documentService.isMobile$();
    this.actionClicked
      .pipe(
        filter(item => item == 'add'),
        untilDestroyed(this)
      )
      .subscribe(() => this.addTab());

    const initialUrl = this.activatedRoute.snapshot.url.map(item => item.path);

    combineLatest(
      this.activatedRoute.url.pipe(map(url => url.map(item => item.path))),
      this.activatedRoute.queryParams.pipe(map(params => params[this.elementParam])),
      this.tabsVisibleById$,
      this.customizeService.enabled$
    )
      .pipe(
        debounceTime(0),
        filter(([url, urlUid, visibleTabsById, customize]) => isEqual(url, initialUrl)),
        map(([url, urlUid, visibleTabsById, customize]) => {
          const tabs = this.getVisibleTabs(!!customize);
          const activeUrlTab = urlUid ? tabs.find(item => item.uid == urlUid) : undefined;
          return activeUrlTab || tabs[0];
        }),
        untilDestroyed(this)
      )
      .subscribe(tab => {
        this.setActiveItemUid(tab ? tab.uid : undefined);
      });
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['element'] && !changes['element'].firstChange) {
      this.initVisibleObserver();
      setTimeout(() => this.updateAllElementStatesOnStable(), 0);
    }
  }

  ngAfterViewInit(): void {
    this.updateAllElementStates();
  }

  get activeItem(): TabsLayoutItem {
    if (!isSet(this.activeItemUid)) {
      return;
    }
    return this.element.items.find(item => item.uid == this.activeItemUid);
  }

  getVisibleTabs(customize: boolean): TabsLayoutItem[] {
    return this.tabsVisible$.value
      .filter(item => {
        if (customize) {
          return true;
        }

        return item.visible;
      })
      .map(item => item.tab);
  }

  initVisibleObserver() {
    if (this.isVisibleSubscription) {
      this.isVisibleSubscription.unsubscribe();
    }

    const obs$ = this.element.items.map(tab => {
      if (!tab.visibleInput) {
        return of({ tab: tab, visible: true });
      } else {
        return applyBooleanInput$(tab.visibleInput, { context: this.context }).pipe(
          map(visible => {
            return { tab: tab, visible: visible };
          })
        );
      }
    });

    if (!obs$.length) {
      this.tabsVisible$.next([]);
      this.tabsVisibleById$.next({});
      return;
    }

    combineLatest(obs$)
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        this.tabsVisible$.next(value);
        this.tabsVisibleById$.next(fromPairs(value.map(item => [item.tab.uid, item.visible])));
        this.cd.markForCheck();
      });
  }

  // tabDragDrop(event: AppDragDrop<TabsLayoutItem[]>) {
  //   const prevActiveItem = this.activeItem;
  //
  //   moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
  //
  //   const activeItemIndex = this.element.items.indexOf(prevActiveItem);
  //
  //   if (activeItemIndex != -1) {
  //     this.setActiveItemIndex(activeItemIndex, false);
  //   }
  //
  //   this.customizeService.markChanged();
  // }

  dragDrop(tabIndex: number, event: AppDragDrop<ElementItem[] | CustomizeBarItem[]>) {
    const item = event.previousContainer.data[event.previousIndex];
    const barItem = item instanceof ElementItem ? undefined : (item as CustomizeBarItem);

    if (barItem && barItem.popup) {
      if (this.customizeService.handler && this.customizeService.handler.createPopup) {
        this.customizeService.handler.createPopup(true, {
          ...(barItem.defaultParams && {
            width: barItem.defaultParams['width'],
            style: barItem.defaultParams['style'],
            position: barItem.defaultParams['position']
          }),
          analyticsSource: 'components_library'
        });
        this.elementContainerService.sendAddPopupAnalytics();
      }

      return;
    }

    const siblingLeftEntered = event.data ? !!event.data['siblingLeftEntered'] : false;
    const siblingRightEntered = event.data ? !!event.data['siblingRightEntered'] : false;
    const siblingSelf = event.data ? !!event.data['siblingSelf'] : false;
    const siblingAnchor: ElementItem = event.data ? event.data['siblingAnchor'] : undefined;
    const siblingAnchorContainer: AppDropList = event.data ? event.data['siblingAnchorContainer'] : undefined;

    if (siblingLeftEntered || siblingRightEntered) {
      const anchorContainer: ElementItem[] = siblingSelf ? event.container.data : siblingAnchorContainer.data;
      const anchorIndex = siblingSelf ? event.currentIndex : anchorContainer.indexOf(siblingAnchor);

      this.elementContainerService.dragDropIntoSiblingColumn({
        sourceContainer: event.previousContainer.data as (ElementItem | CustomizeBarItem)[],
        sourceIndex: event.previousIndex,
        sourceCloneItem: event.previousContainer.cloneItems,
        anchorContainer: anchorContainer,
        anchorIndex: anchorIndex,
        anchorSelf: siblingSelf,
        left: siblingLeftEntered,
        context: this.context,
        parent: this.element
      });

      // TODO: Implement onAdd
      // this.cd.detectChanges();
      // const component = this.elementComponents.find(i => i.element === elementItem);
      // this.onAdd(elementItem, item, component);
    } else {
      if (event.previousContainer === event.container) {
        moveItemInArray(event.container.data as ElementItem[], event.previousIndex, event.currentIndex);
      } else if (event.previousContainer.cloneItems) {
        const elementItem = this.elementContainerService.copyElementItem(
          event.previousContainer.data as CustomizeBarItem[],
          event.container.data as ElementItem[],
          event.previousIndex,
          event.currentIndex,
          this.context
        );
        this.customizeService.registerCreatedElement(elementItem, barItem);
      } else {
        transferArrayItem(
          event.previousContainer.data as ElementItem[],
          event.container.data as ElementItem[],
          event.previousIndex,
          event.currentIndex
        );
      }
    }

    this.customizeService.markChanged();
    this.updateElementStatesOnStable(tabIndex);
  }

  replaceItem(tabIndex: number, index: number, elements: ElementItem[]) {
    this.elementContainerService.replaceElementItem(this.element.items[tabIndex].children, index, elements);
    this.cd.markForCheck();
    this.customizeService.markChanged();
    this.updateElementStatesOnStable(tabIndex);
  }

  duplicateItem(tabIndex: number, index: number) {
    this.elementContainerService
      .duplicateElementItem(this.element.items[tabIndex].children, this.context, index)
      .pipe(untilDestroyed(this))
      .subscribe(elementItem => {
        this.cd.detectChanges();
        this.customizeService.markChanged();
        this.updateElementStatesOnStable(tabIndex);

        const component = this.elementComponents.find(i => i.element === elementItem);

        if (component) {
          component.customize();
        }
      });
  }

  deleteItem(element: ElementItem) {
    this.element.items.forEach((tab, tabIndex) => {
      const index = tab.children.findIndex(item => item === element);

      if (index == -1) {
        return;
      }

      this.elementContainerService.deleteElementItem(this.element.items[tabIndex].children, index);
      this.cd.markForCheck();
      this.customizeService.markChanged();
      this.updateElementStatesOnStable(tabIndex);
    });
  }

  moveItemTo(element: ElementItem, link: any[]) {
    this.deleteItem(element);

    this.customizeService.stopTrackChanges();
    this.customizeService
      .saveActualChanges()
      .pipe(untilDestroyed(this))
      .subscribe(() => this.routing.navigateApp(link));
  }

  updateAllElementStatesOnStable() {
    this.element.items.forEach((_, i) => this.updateElementStatesOnStable(i));
  }

  updateAllElementStates() {
    this.element.items.forEach((_, i) => this.updateElementStates(i));
  }

  updateElementStatesOnStable(tabIndex: number) {
    const container = this.elementGroupsContainer.toArray()[tabIndex];
    if (container) {
      container.updateElementStatesOnStable();
    }
  }

  updateElementStates(tabIndex: number) {
    const container = this.elementGroupsContainer.toArray()[tabIndex];
    if (container) {
      container.updateElementStates();
    }
  }

  get elementParam() {
    return `_tab_${this.element.uid.substring(0, 4)}`;
  }

  setActiveItemUid(uid: string, navigate = true) {
    if (this.activeItemUid === uid) {
      return;
    }

    this.activeItemUid = uid;

    if (this.activeItem && !this.itemsOpened[this.activeItem.uid]) {
      this.itemsOpened[this.activeItem.uid] = true;
    }

    this.cd.markForCheck();

    if (navigate) {
      this.routingService.navigateCurrent({
        queryParams: { [this.elementParam]: uid },
        queryParamsHandling: 'merge'
      });
    }
  }

  deleteTab(tabIndex: number) {
    this.element.items.splice(tabIndex, 1);

    const newActiveItem = this.getVisibleTabs(!!this.customizeService.enabled).splice(0, tabIndex).reverse()[0];

    this.setActiveItemUid(newActiveItem ? newActiveItem.uid : undefined);
    this.customizeService.markChanged();
  }

  addTab() {
    const tab = new TabsLayoutItem();

    tab.titleInput = new FieldInput().deserializeFromStatic('value', 'New Tab');
    tab.generateUid();

    this.element.items.push(tab);
    this.setActiveItemUid(tab.uid);
    this.cd.markForCheck();
    this.customizeService.markChanged();
    this.initVisibleObserver();
  }
}

registerElementComponent({
  type: ElementType.Tabs,
  component: TabsLayoutComponent,
  label: 'Tabs',
  alwaysActive: false,
  actions: [{ name: 'add', label: 'Add Tab' }]
});
