<div *ngIf="!loading" class="sidebar-list-item-wrapper">
  <div
    class="sidebar-list-item sidebar-list-item_clickable sidebar-list-item_purple"
    [class.sidebar-list-item_shadow]="true"
    [class.sidebar-list-item_disabled]="dropdown_trigger.menuOpen"
    [matMenuTriggerFor]="dropdown"
    #dropdown_trigger="matMenuTrigger"
    cdkOverlayOrigin
    #dropdown_origin="cdkOverlayOrigin"
    (mousedown)="$event.stopPropagation()"
  >
    <div class="sidebar-list-item__left">
      <span
        class="button button_icon button_purple-gradient button_small"
        [class.button_disabled]="!customView"
        style="margin: 0;"
      >
        <span
          class="button__icon"
          [class.icon-canvas]="!customView?.source || customView?.source == sources.View"
          [class.icon-console]="customView?.source == sources.CustomElement"
          [style.font-size.px]="!customView?.source || customView?.source == sources.View ? 18 : null"
        ></span>
      </span>
    </div>

    <div class="sidebar-list-item__main">
      <div class="sidebar-list-item__title">
        <ng-container *ngIf="customView">
          {{ (customView.name | appIsSet) ? customView.name : 'No name' }}
        </ng-container>

        <ng-container *ngIf="!customView">
          Not specified
        </ng-container>
      </div>
      <div class="sidebar-list-item__description">
        <span *ngIf="valueShared" class="sidebar-list-item__tag">SAVED</span> {{ subtitle }}
      </div>
    </div>

    <div class="sidebar-list-item__right">
      <a
        *ngIf="!customView"
        href="javascript:void(0)"
        class="sidebar-list-item__action sidebar-list-item__action_button icon-plus"
        style="margin-left: 8px;"
      >
      </a>

      <a
        *ngIf="customView"
        href="javascript:void(0)"
        class="sidebar-list-item__action sidebar-list-item__action_button icon-arrow_down_2"
        style="margin-left: 8px;"
      >
      </a>
    </div>
  </div>

  <div class="sidebar-list-item-wrapper__footer">
    <div class="sidebar-list-item-wrapper__footer-row">
      <div
        class="sidebar-list-item-subbutton sidebar-list-item-subbutton_interactive"
        [class.sidebar-list-item-subbutton_disabled]="dropdown_trigger.menuOpen"
        (click)="openCustomViewTemplates()"
      >
        <div class="sidebar-list-item-subbutton__icon icon-blocks"></div>
        <div class="sidebar-list-item-subbutton__label">
          <ng-container *ngIf="templatesApprox | appIsSet">Explore {{ templatesApprox }}+ components</ng-container>
          <ng-container *ngIf="!(templatesApprox | appIsSet)">Explore components library</ng-container>
        </div>
      </div>
    </div>
  </div>

  <mat-menu #dropdown="matMenu">
    <ng-template matMenuContent>
      <button
        *ngIf="!customView || customView?.source == sources.View"
        mat-menu-item
        class="mat-menu-item-odd"
        [disableRipple]="true"
        (click)="openViewEditor(customView)"
      >
        <span class="choose-items-item__dropdown-icon icon-canvas"></span>
        <ng-container *ngIf="customView">Customize with Designer</ng-container>
        <ng-container *ngIf="!customView">Create with Designer</ng-container>
      </button>

      <button
        *ngIf="!customView || customView?.source == sources.CustomElement"
        mat-menu-item
        class="mat-menu-item-odd"
        [disableRipple]="true"
        (click)="openIDE(customView)"
      >
        <span class="choose-items-item__dropdown-icon icon-console"></span>
        <ng-container *ngIf="customView">Customize with JS Editor</ng-container>
        <ng-container *ngIf="!customView">Create with JS Editor</ng-container>
        <span class="mat-menu-item-tag mat-menu-item-tag_color_purple">AI</span>
      </button>

      <button mat-menu-item class="mat-menu-item-odd" [disableRipple]="true" (click)="openCustomViewTemplates()">
        <span class="choose-items-item__dropdown-icon icon-blocks"></span>
        Use Marketplace component
      </button>

      <button
        mat-menu-item
        class="mat-menu-item-odd"
        [disabled]="customViewsShared.length == 0"
        [disableRipple]="true"
        (click)="custom_view_dropdown.open()"
      >
        <span class="choose-items-item__dropdown-icon icon-open_folder"></span>
        Use saved components ({{ customViewsShared.length }})
      </button>

      <button
        *ngIf="mappingsControl && customView"
        mat-menu-item
        class="mat-menu-item-odd"
        [disableRipple]="true"
        (click)="changeMapping(customView)"
      >
        <span class="choose-items-item__dropdown-icon icon-plug"></span>
        Change data binding
      </button>

      <button
        *ngIf="(currentUserStore.instance$ | async)?.isStaff"
        mat-menu-item
        class="mat-menu-item-odd staff-block staff-block_background"
        [disabled]="!customView"
        [disableRipple]="true"
        (click)="updateCustomViewTemplate(customView)"
      >
        <span class="choose-items-item__dropdown-icon icon-redo"></span>
        Save to marketplace
      </button>

      <button
        *ngIf="!valueShared && customView"
        mat-menu-item
        class="mat-menu-item-odd mat-menu-item-orange"
        [disableRipple]="true"
        (click)="createCustomViewShared(customView)"
      >
        <span class="choose-items-item__dropdown-icon choose-items-item__dropdown-icon_orange icon-save"></span>
        Save to reuse
      </button>

      <button
        *ngIf="valueShared && customView"
        mat-menu-item
        class="mat-menu-item-odd mat-menu-item-orange"
        [disableRipple]="true"
        (click)="detachCustomViewShared(customView)"
      >
        <span class="choose-items-item__dropdown-icon choose-items-item__dropdown-icon_orange icon-copy"></span>
        Detach from saved
      </button>

      <button
        *ngIf="deleteEnabled && customView"
        mat-menu-item
        class="mat-menu-item-odd mat-menu-item-red"
        [disableRipple]="true"
        (click)="resetCustomView()"
      >
        <span class="choose-items-item__dropdown-icon choose-items-item__dropdown-icon_red icon-bin"></span>
        Remove custom component
      </button>
    </ng-template>
  </mat-menu>

  <app-custom-view-dropdown
    [value]="customView?.uniqueName"
    [origin]="dropdown_origin"
    (selectItem)="onSelectCustomView($event)"
    #custom_view_dropdown
  ></app-custom-view-dropdown>
</div>

<div *ngIf="loading" class="sidebar-list-item-wrapper">
  <div class="sidebar-list-item sidebar-list-item_purple" [class.sidebar-list-item_shadow]="true">
    <div class="sidebar-list-item__left">
      <span
        class="button button_icon button_purple-gradient button_small"
        [class.button_disabled]="true"
        style="margin: 0;"
      >
        <span
          class="button__icon"
          [class.icon-canvas]="!customView?.source || customView?.source == sources.View"
          [class.icon-console]="customView?.source == sources.CustomElement"
          [style.font-size.px]="!customView?.source || customView?.source == sources.View ? 18 : null"
        ></span>
      </span>
    </div>

    <div class="sidebar-list-item__main">
      <div class="sidebar-list-item__title">
        <span [class.loading-animation]="loading"><span class="stub-text">name</span></span>
      </div>
      <div class="sidebar-list-item__description">
        <span [class.loading-animation]="loading"><span class="stub-text">custom</span></span>
      </div>
    </div>
  </div>

  <div class="sidebar-list-item-wrapper__footer">
    <div class="sidebar-list-item-wrapper__footer-row">
      <div class="sidebar-list-item-subbutton">
        <div class="sidebar-list-item-subbutton__label">
          <span [class.loading-animation]="loading"><span class="stub-text">action</span></span>
        </div>
      </div>
    </div>
  </div>
</div>
