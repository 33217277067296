var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { FormControl, FormGroup } from '@angular/forms';
import { map } from 'rxjs/operators';
import { AggregateDisplayField, ComputedDisplayField, CustomViewDisplayField, DisplayField, DisplayFieldType, FieldType, getFieldDescriptionByType, Input, LookupDisplayField } from '@modules/fields';
import { FieldInputControl } from '@modules/parameters';
import { controlValue } from '@shared';
var DisplayFieldControl = /** @class */ (function (_super) {
    __extends(DisplayFieldControl, _super);
    function DisplayFieldControl() {
        var _this = _super.call(this, {
            name: new FormControl(''),
            verboseName: new FormControl(''),
            description: new FormControl(''),
            field: new FormControl(FieldType.Text),
            params: new FormControl({}),
            visible: new FormControl(true),
            valueInput: new FieldInputControl({ path: ['value'] }),
            lookupPath: new FormControl(null),
            aggregatePath: new FormControl(null),
            aggregateFunc: new FormControl(null),
            aggregateColumn: new FormControl(null),
            customViewUniqueName: new FormControl(null),
            customViewMappings: new FormControl([])
        }) || this;
        _this.type = DisplayFieldType.Base;
        return _this;
    }
    DisplayFieldControl.prototype.deserialize = function (item) {
        this.instance = item;
        this.type = item ? item.type : DisplayFieldType.Base;
        this.patchValue(__assign({ name: item.name, verboseName: item.verboseName, description: item.description, field: item.field, params: item.params, visible: item.visible }, (item instanceof ComputedDisplayField
            ? {
                valueInput: item.valueInput ? item.valueInput.serializeWithoutPath() : {}
            }
            : undefined), (item instanceof LookupDisplayField
            ? {
                lookupPath: item.path
            }
            : undefined), (item instanceof AggregateDisplayField
            ? {
                aggregatePath: item.path,
                aggregateFunc: item.func,
                aggregateColumn: item.column
            }
            : undefined), (item instanceof CustomViewDisplayField
            ? {
                customViewUniqueName: item.customView,
                customViewMappings: item.customViewMappings
            }
            : undefined)));
        this.markAsPristine();
    };
    DisplayFieldControl.prototype.createInstance = function () {
        if (this.type == DisplayFieldType.Computed) {
            return new ComputedDisplayField();
        }
        else if (this.type == DisplayFieldType.CustomView) {
            return new CustomViewDisplayField();
        }
        else if (this.type == DisplayFieldType.Lookup) {
            return new LookupDisplayField();
        }
        else if (this.type == DisplayFieldType.Aggregate) {
            return new AggregateDisplayField();
        }
        else {
            return new DisplayField();
        }
    };
    DisplayFieldControl.prototype.serialize = function (reuseInstance) {
        if (reuseInstance === void 0) { reuseInstance = true; }
        var result = (reuseInstance ? this.instance : undefined) || this.createInstance();
        result.name = this.controls.name.value;
        result.verboseName = this.controls.verboseName.value;
        result.description = this.controls.description.value;
        result.field = this.controls.field.value;
        result.params = this.controls.params.value;
        result.visible = this.controls.visible.value;
        result.updateFieldDescription();
        if (result instanceof ComputedDisplayField) {
            result.valueInput = this.controls.valueInput.value
                ? new Input().deserialize(this.controls.valueInput.value)
                : undefined;
        }
        else if (result instanceof LookupDisplayField) {
            result.path = this.controls.lookupPath.value;
        }
        else if (result instanceof AggregateDisplayField) {
            result.path = this.controls.aggregatePath.value;
            result.func = this.controls.aggregateFunc.value;
            result.column = this.controls.aggregateColumn.value;
        }
        else if (result instanceof CustomViewDisplayField) {
            result.customView = this.controls.customViewUniqueName.value;
            result.customViewMappings = this.controls.customViewMappings.value;
        }
        return result;
    };
    DisplayFieldControl.prototype.toggleVisible = function () {
        this.controls.visible.patchValue(!this.controls.visible.value);
    };
    DisplayFieldControl.prototype.getFieldDescription = function () {
        return getFieldDescriptionByType(this.controls.field.value);
    };
    DisplayFieldControl.prototype.getIcon = function () {
        return this.getFieldDescription().icon;
    };
    DisplayFieldControl.prototype.getIcon$ = function () {
        var _this = this;
        return controlValue(this.controls.field).pipe(map(function (field) {
            if (_this.instance instanceof ComputedDisplayField) {
                return 'function';
            }
            else if (_this.instance instanceof LookupDisplayField) {
                return 'lookup';
            }
            else if (_this.instance instanceof AggregateDisplayField) {
                return 'spiral';
            }
            else {
                return getFieldDescriptionByType(field).icon;
            }
        }));
    };
    return DisplayFieldControl;
}(FormGroup));
export { DisplayFieldControl };
