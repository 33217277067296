var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { NotificationService } from '@common/notifications';
import { PopupRef } from '@common/popups';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ViewContext, ViewContextElement } from '@modules/customize';
import { BooleanFieldStyle } from '@modules/field-components';
import { createFormFieldFactory, getFieldComponentsByName, getFieldDescriptionByType } from '@modules/fields';
import { CurrentEnvironmentStore, getProjectPropertyTypeLabel, ProjectProperty, ProjectPropertyType } from '@modules/projects';
import { capitalize, controlValue, errorToString, Singleton } from '@shared';
import { ProjectPropertyEditPopupForm } from './project-property-edit-popup.form';
var ProjectPropertyEditPopupComponent = /** @class */ (function () {
    function ProjectPropertyEditPopupComponent(popupRef, form, notificationService, currentEnvironmentStore, analyticsService, cd) {
        var _this = this;
        this.popupRef = popupRef;
        this.form = form;
        this.notificationService = notificationService;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.analyticsService = analyticsService;
        this.cd = cd;
        this.defaultValueEnabled = false;
        this.requiredEnabled = false;
        this.placeholderEnabled = false;
        this.created = new EventEmitter();
        this.updated = new EventEmitter();
        this.createField = createFormFieldFactory();
        this.defaultValueParams = {};
        this.contextNew = new Singleton(function () { return new ViewContext(_this.currentEnvironmentStore); });
        this.submitLoading = false;
        this.booleanFieldStyle = BooleanFieldStyle;
    }
    ProjectPropertyEditPopupComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.typeLabel = this.getTypeLabel();
        this.form.init(this.type, this.property, {
            defaultName: this.defaultName,
            defaultValueEnabled: this.defaultValueEnabled,
            requiredEnabled: this.requiredEnabled,
            required: this.required,
            placeholderEnabled: this.placeholderEnabled,
            placeholder: this.placeholder,
            pageUid: this.pageUid,
            saveLocal: this.saveLocal,
            context: this.context
        });
        controlValue(this.form.controls.field)
            .pipe(untilDestroyed(this))
            .subscribe(function (field) { return _this.updateParamsComponentData(field); });
        controlValue(this.form.controls.params)
            .pipe(untilDestroyed(this))
            .subscribe(function (params) { return _this.updateDefaultValueParams(params); });
        if (this.property) {
            this.analyticsService.sendSimpleEvent(AnalyticsEvent.Property.UpdateStarted, {
                Type: this.property.type,
                Source: this.analyticsSource
            });
        }
        else {
            this.analyticsService.sendSimpleEvent(AnalyticsEvent.Property.AddStarted, {
                Type: this.type,
                Source: this.analyticsSource
            });
        }
    };
    ProjectPropertyEditPopupComponent.prototype.ngOnDestroy = function () { };
    ProjectPropertyEditPopupComponent.prototype.getTypeLabel = function () {
        var type = this.property ? this.property.type : this.type;
        return getProjectPropertyTypeLabel(type);
    };
    ProjectPropertyEditPopupComponent.prototype.getContextProviders = function () {
        var _this = this;
        return [
            {
                provide: ViewContext,
                useFactory: function () {
                    if (_this.context) {
                        return _this.context;
                    }
                    else {
                        return _this.contextNew.get();
                    }
                },
                deps: []
            }
        ];
    };
    ProjectPropertyEditPopupComponent.prototype.updateParamsComponentData = function (field) {
        var item = getFieldDescriptionByType(field);
        var components = getFieldComponentsByName(item.name);
        if (!components || !components.viewParamsComponent) {
            this.viewParamsComponentData = undefined;
        }
        else {
            this.viewParamsComponentData = {
                component: components.viewParamsComponent,
                inputs: {
                    control: this.form.controls.params,
                    context: this.context || this.contextNew.get(),
                    contextElement: this.contextElement
                },
                providers: this.getContextProviders()
            };
        }
        if (!components || !components.dataParamsComponent) {
            this.dataParamsComponentData = undefined;
        }
        else {
            this.dataParamsComponentData = {
                component: components.dataParamsComponent,
                inputs: {
                    control: this.form.controls.params,
                    context: this.context || this.contextNew.get(),
                    contextElement: this.contextElement
                },
                providers: this.getContextProviders()
            };
        }
        this.cd.markForCheck();
    };
    ProjectPropertyEditPopupComponent.prototype.updateDefaultValueParams = function (params) {
        var classes = params && params['classes'] ? params['classes'] : [];
        this.defaultValueParams = __assign({}, params, { classes: ['input_fill', 'select_fill'].concat(classes) });
        this.cd.markForCheck();
    };
    ProjectPropertyEditPopupComponent.prototype.submit = function () {
        var _this = this;
        this.submitLoading = true;
        this.cd.markForCheck();
        this.form
            .submit()
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            if (_this.property) {
                _this.updated.emit(result);
            }
            else {
                _this.created.emit(result);
            }
            _this.close();
            if (_this.property) {
                _this.notificationService.success('Saved', capitalize(_this.typeLabel) + " <strong>" + _this.property.name + "</strong> was successfully updated");
            }
            else {
                _this.notificationService.success('Created', capitalize(_this.typeLabel) + " was successfully created");
            }
            if (_this.property) {
                _this.analyticsService.sendSimpleEvent(AnalyticsEvent.Property.Updated, {
                    Type: _this.property.type,
                    Source: _this.analyticsSource
                });
            }
            else {
                _this.analyticsService.sendSimpleEvent(AnalyticsEvent.Property.Added, {
                    Type: _this.type,
                    Source: _this.analyticsSource
                });
            }
            _this.analyticsService.sendSimpleEvent(AnalyticsEvent.Property.Saved, {
                Type: _this.property ? _this.property.type : _this.type,
                Source: _this.analyticsSource
            });
        }, function (error) {
            _this.notificationService.error('Error', errorToString(error));
            _this.submitLoading = false;
            _this.cd.markForCheck();
        });
    };
    ProjectPropertyEditPopupComponent.prototype.close = function () {
        if (this.popupRef) {
            this.popupRef.close();
        }
    };
    ProjectPropertyEditPopupComponent.prototype.cancel = function () {
        this.close();
        if (this.property) {
            this.analyticsService.sendSimpleEvent(AnalyticsEvent.Property.UpdateCancelled, {
                Type: this.property.type,
                Source: this.analyticsSource
            });
        }
        else {
            this.analyticsService.sendSimpleEvent(AnalyticsEvent.Property.AddCancelled, {
                Type: this.type,
                Source: this.analyticsSource
            });
        }
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.Property.Cancelled, {
            Type: this.property ? this.property.type : this.type,
            Source: this.analyticsSource
        });
    };
    return ProjectPropertyEditPopupComponent;
}());
export { ProjectPropertyEditPopupComponent };
