import { of, throwError } from 'rxjs';
import { catchError, delayWhen, filter, map, switchMap, tap } from 'rxjs/operators';
import { DialogButtonHotkey, DialogButtonType, DialogService } from '@common/dialogs';
import { localize } from '@common/localize';
import { NotificationService } from '@common/notifications';
import { ServerRequestError } from '@modules/api';
import { ProjectService, ProjectsStore } from '@modules/projects';
import { CurrentUserStore } from '@modules/users';
import { errorToString } from '@shared';
import { SurveyService } from '../survey/survey.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../projects/services/project/project.service";
import * as i2 from "../../../projects/stores/projects.store";
import * as i3 from "../../../users/stores/current-user.store";
import * as i4 from "../../../../common/notifications/services/notification/notification.service";
import * as i5 from "../survey/survey.service";
import * as i6 from "../../../../common/dialogs/services/dialog/dialog.service";
var ProjectController = /** @class */ (function () {
    function ProjectController(projectService, projectsStore, currentUserStore, notificationService, surveyService, dialogService) {
        this.projectService = projectService;
        this.projectsStore = projectsStore;
        this.currentUserStore = currentUserStore;
        this.notificationService = notificationService;
        this.surveyService = surveyService;
        this.dialogService = dialogService;
    }
    ProjectController.prototype.processDeleteProject = function (project) {
        var _this = this;
        return this.projectService.delete(project).pipe(delayWhen(function () { return _this.projectsStore.updateIfLoaded(); }));
    };
    ProjectController.prototype.deleteProject = function (uniqueName, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        return this.projectService.getDetail(uniqueName).pipe(switchMap(function (project) {
            var survey$ = project.subscription && project.subscription.isCancellable()
                ? _this.surveyService
                    .openUnsubscribe({ project: project, user: _this.currentUserStore.instance })
                    .pipe(filter(function (result) { return result.finished; }))
                : of(true);
            return survey$.pipe(switchMap(function () {
                return _this.dialogService.dialog({
                    title: localize('Delete App'),
                    description: localize("\n                Are you sure want to delete app <strong>{0}</strong>?<br>\n                Your app will be permanently deleted. This cannot be undone.\n              ", [project.name]),
                    buttons: [
                        {
                            name: 'cancel',
                            label: localize('Cancel'),
                            type: DialogButtonType.Default,
                            hotkey: DialogButtonHotkey.Cancel
                        },
                        {
                            name: 'ok',
                            label: localize('Delete App'),
                            type: DialogButtonType.Danger,
                            hotkey: DialogButtonHotkey.Submit,
                            executor: function () { return _this.processDeleteProject(project); }
                        }
                    ],
                    dark: options.dark
                });
            }), filter(function (result) {
                if (result.executorError) {
                    throw result.executorError;
                }
                return result.executorResult;
            }), map(function () { return true; }), tap(function () {
                _this.notificationService.success('Deleted', "<strong>" + project.name + "</strong> was successfully deleted");
            }), catchError(function (error) {
                if (error instanceof ServerRequestError && error.errors.length) {
                    _this.notificationService.error('Error', "Deleting <strong>" + project.name + "</strong> failed: " + error.errors[0]);
                }
                else {
                    _this.notificationService.error('Error', errorToString(error));
                }
                return throwError(error);
            }));
        }));
    };
    ProjectController.ngInjectableDef = i0.defineInjectable({ factory: function ProjectController_Factory() { return new ProjectController(i0.inject(i1.ProjectService), i0.inject(i2.ProjectsStore), i0.inject(i3.CurrentUserStore), i0.inject(i4.NotificationService), i0.inject(i5.SurveyService), i0.inject(i6.DialogService)); }, token: ProjectController, providedIn: "root" });
    return ProjectController;
}());
export { ProjectController };
