import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import toPairs from 'lodash/toPairs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { debounceTime, map } from 'rxjs/operators';

import { CustomView } from '@modules/custom-views';
import { cleanElementName, CustomElementItem, ViewContext, ViewContextElement } from '@modules/customize';
import { ActionOutput, createFormFieldFactory, Input as FieldInput, InputValueType } from '@modules/fields';
import { CustomViewTemplateType, View } from '@modules/views';

import { CustomViewDefaults } from '../../data/custom-view-defaults';
import { CustomizeBarEditComponent } from '../../data/customize-bar-edit-component';
import { CustomizeBarEditEvent } from '../../data/customize-bar-edit-event';
import { CustomizeBarEditEventType } from '../../data/customize-bar-edit-event-type';
import { CustomizeBarContext } from '../../services/customize-bar-context/customize-bar.context';
import { CustomizeBarCustomViewEditForm } from './customize-bar-custom-view-edit.form';

@Component({
  selector: 'app-customize-bar-custom-view-edit',
  templateUrl: './customize-bar-custom-view-edit.component.html',
  providers: [CustomizeBarCustomViewEditForm],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeBarCustomViewEditComponent implements OnInit, OnDestroy, CustomizeBarEditComponent {
  @Input() element: CustomElementItem;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() titleEnabled = true;
  @Input() titleEditable: boolean;
  @Input() deleteEnabled = false;
  @Input() parentElement: any;
  @Input() parentPopup: any;
  @Input() firstInit = false;
  @Output() event = new EventEmitter<CustomizeBarEditEvent>();

  createField = createFormFieldFactory();
  customViewDefaults: CustomViewDefaults;
  customViewTemplateTypes = CustomViewTemplateType;
  customView: CustomView;

  titleCleanValue = (() => {
    return (value: string): string => {
      return cleanElementName(value, this.element, this.context.getElementItems());
    };
  })();

  trackActionOutput(i, item: ActionOutput) {
    return item.name;
  }

  constructor(
    public form: CustomizeBarCustomViewEditForm,
    private customizeBarContext: CustomizeBarContext,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.form
      .init(this.element, { context: this.context })
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.customViewDefaults = this.form.getCustomViewDefaults();

        this.form.valueChanges
          .pipe(
            debounceTime(200),
            map(() => this.form.submit()),
            untilDestroyed(this)
          )
          .subscribe(result => {
            this.emitUpdate(result);
          });

        this.form
          .getCustomView$()
          .pipe(untilDestroyed(this))
          .subscribe(customView => {
            this.customView = customView;
            this.cd.markForCheck();
          });
      });
  }

  ngOnDestroy(): void {}

  onTitleChanged(title: string) {
    this.form.controls.name.setValue(title);
  }

  close() {
    this.customizeBarContext.popSettingsComponent();
  }

  submit() {
    const result = this.form.submit();

    this.emitUpdate(result, true);
    this.close();
  }

  emitUpdate(result: CustomElementItem, submit = false) {
    if (this.element) {
      this.event.emit({ type: CustomizeBarEditEventType.Updated, args: { result: result, submit: submit } });
    } else {
      this.event.emit({ type: CustomizeBarEditEventType.Created, args: { result: result, submit: submit } });
    }
  }

  cancel() {
    this.event.emit({ type: CustomizeBarEditEventType.Canceled });
    this.close();
  }

  delete() {
    this.event.emit({ type: CustomizeBarEditEventType.Deleted });
    this.close();
  }

  getCustomViewTestParameters(customView: CustomView): FieldInput[] {
    return toPairs(customView.testParameters).map(([name, value]) => {
      const result = new FieldInput();

      result.path = [name];
      result.valueType = InputValueType.StaticValue;
      result.staticValue = value;

      return result;
    });
  }

  onCustomViewSelected(customView?: CustomView) {
    if (customView) {
      this.form.patchValue({
        source: customView.source,
        custom_view: customView.uniqueName,
        parameters: customView.parameters,
        ...(customView.uniqueName != this.form.controls.custom_view.value && {
          inputs: this.getCustomViewTestParameters(customView)
        })
      });
    } else {
      this.form.patchValue({
        source: customView.source,
        custom_view: undefined,
        parameters: [],
        inputs: {}
      });
    }
  }

  asView(value: any): View {
    return value as View;
  }
}
