<div class="bar-progress">
  <div *ngIf="(label | appIsSet) || showPercentage" class="bar-progress__upper">
    <div class="bar-progress__label" [class.bar-progress__label_wrap]="labelWrap">{{ label }}</div>
    <div *ngIf="showPercentage" class="bar-progress__percent">{{ progressRound }}%</div>
  </div>

  <div
    class="bar-progress__progress"
    [class.bar-progress__progress_contrast]="contrast"
    [class.bar-progress__progress_border]="border"
  >
    <div
      class="bar-progress__progress-inner"
      [class.bar-progress__progress-inner_secondary]="secondary"
      [style.width.%]="progress * 100"
      [style.background]="(accentColor | appIsSet) ? accentColor : null"
    ></div>
  </div>

  <div *ngIf="errors" class="bar-progress__lower">
    <div class="bar-progress__error">
      {{ errors | i18nPlural: { '=1': '# error', other: '# errors' } }}
    </div>
  </div>

  <div *ngIf="!errors && (error | appIsSet)" class="bar-progress__lower">
    <div class="bar-progress__error">
      {{ error }}
    </div>
  </div>

  <div *ngIf="!errors && (processed | appIsSet) && (total | appIsSet)" class="bar-progress__lower">
    <div class="bar-progress__status">{{ processed }} / {{ total }}</div>
  </div>
</div>
