<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayPanelClass]="['overlay']"
  [cdkConnectedOverlayPositions]="popoverPositions"
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="opened"
  [cdkConnectedOverlayPush]="true"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayBackdropClass]="'popover2-backdrop'"
  [cdkConnectedOverlayGrowAfterOpen]="true"
  (backdropClick)="setOpened(false)"
  (detach)="setOpened(false)"
>
  <div (cdkObserveContent)="onPopoverContentChanged()">
    <div class="popover2 popover2_theme overlay-content overlay-content_animated">
      <div class="popover2__inner popover2__inner_no-margin-collapse">
        <div class="tabs2">
          <div class="tabs2-navigation tabs2-navigation_center tabs2-navigation_theme">
            <a
              href="javascript:void(0)"
              class="tabs2-navigation__item tabs2-navigation__item_fill"
              [class.tabs2-navigation__item_active]="mode == modes.Since"
              (click)="setMode(modes.Since)"
            >
              {{ 'Since' | localize }}
            </a>
            <a
              href="javascript:void(0)"
              class="tabs2-navigation__item tabs2-navigation__item_fill"
              [class.tabs2-navigation__item_active]="mode == modes.Before"
              (click)="setMode(modes.Before)"
            >
              {{ 'Before' | localize }}
            </a>
            <a
              href="javascript:void(0)"
              class="tabs2-navigation__item tabs2-navigation__item_fill"
              [class.tabs2-navigation__item_active]="mode == modes.Between"
              (click)="setMode(modes.Between)"
            >
              {{ 'Between' | localize }}
            </a>
          </div>
        </div>

        <hr class="hr_theme" />

        <ng-container *ngIf="mode == modes.Since">
          <div style="display: flex; align-items: center; justify-content: center; padding: 10px;">
            <div class="input-icon-wrapper">
              <input
                type="text"
                [placeholder]="'From date' | localize"
                class="input input_small input_center"
                [class.input_icon-right]="xDateFromControl.value | appIsSet"
                [class.input_theme]="theme"
                [style.width.px]="inputWidth"
                [appDateValue]="{ format: editFormat, date: date, time: time }"
                [appAutofocus]="true"
                [formControl]="xDateFromControl"
                autocomplete="off"
                #dp_from
              />
              <span
                *ngIf="xDateFromControl.value | appIsSet"
                class="input-icon input-icon_small input-icon_interactive input-icon_right icon-close"
                [class.input-icon_theme]="theme"
                (click)="xDateFromControl.setValue(''); dp_from.focus()"
              ></span>
            </div>
          </div>

          <hr class="hr_theme" />

          <gxd-datepicker
            style="display: flex; align-items: center;"
            [options]="{
              theme: 'jet',
              date: date,
              time: time,
              format: editFormat,
              static: true,
              datepickerClasses: ['datepicker_static', 'datepicker_theme'],
              locale: {} | localizeDatepicker
            }"
            [input]="dp_from"
            (change)="dp_from.focus()"
          ></gxd-datepicker>
        </ng-container>

        <ng-container *ngIf="mode == modes.Before">
          <div style="display: flex; align-items: center; justify-content: center; padding: 10px;">
            <div class="input-icon-wrapper">
              <input
                type="text"
                [placeholder]="'To date' | localize"
                class="input input_small input_center"
                [class.input_icon-right]="xDateToControl.value | appIsSet"
                [class.input_theme]="theme"
                [style.width.px]="inputWidth"
                [appDateValue]="{ format: editFormat, date: date, time: time }"
                [appAutofocus]="true"
                [formControl]="xDateToControl"
                autocomplete="off"
                #dp_to
              />
              <span
                *ngIf="xDateToControl.value | appIsSet"
                class="input-icon input-icon_small input-icon_interactive input-icon_right icon-close"
                [class.input-icon_theme]="theme"
                (click)="xDateToControl.setValue(''); dp_to.focus()"
              ></span>
            </div>
          </div>

          <hr class="hr_theme" />

          <gxd-datepicker
            style="display: flex; align-items: center;"
            [options]="{
              theme: 'jet',
              date: date,
              time: time,
              format: editFormat,
              static: true,
              datepickerClasses: ['datepicker_static', 'datepicker_theme'],
              locale: {} | localizeDatepicker
            }"
            [input]="dp_to"
            (change)="dp_to.focus()"
          ></gxd-datepicker>
        </ng-container>

        <ng-container *ngIf="mode == modes.Between">
          <div style="display: flex; align-items: center; justify-content: center; padding: 10px;">
            <div class="input-icon-wrapper">
              <input
                type="text"
                [placeholder]="'From date' | localize"
                class="input input_small input_center"
                [class.input_icon-right]="xDateFromControl.value | appIsSet"
                [class.input_theme]="theme"
                [style.width.px]="inputWidth"
                [appDateValue]="{ format: editFormat, date: date, time: time }"
                [appAutofocus]="betweenModeFrom"
                [formControl]="xDateFromControl"
                (focus)="setBetweenModeFrom(true)"
                autocomplete="off"
                #dp_from
              />
              <span
                *ngIf="xDateFromControl.value | appIsSet"
                class="input-icon input-icon_small input-icon_interactive input-icon_right icon-close"
                [class.input-icon_theme]="theme"
                (click)="xDateFromControl.setValue(''); dp_from.focus()"
              ></span>
            </div>

            <span style="font-weight: 700; margin: 0 8px;">–</span>

            <div class="input-icon-wrapper">
              <input
                type="text"
                [placeholder]="'To date' | localize"
                class="input input_small input_center"
                [class.input_icon-right]="xDateToControl.value | appIsSet"
                [class.input_theme]="theme"
                [style.width.px]="inputWidth"
                [appDateValue]="{ format: editFormat, date: date, time: time }"
                [appAutofocus]="!betweenModeFrom"
                [formControl]="xDateToControl"
                (focus)="setBetweenModeFrom(false)"
                autocomplete="off"
                #dp_to
              />
              <span
                *ngIf="xDateToControl.value | appIsSet"
                class="input-icon input-icon_small input-icon_interactive input-icon_right icon-close"
                [class.input-icon_theme]="theme"
                (click)="xDateToControl.setValue(''); dp_to.focus()"
              ></span>
            </div>
          </div>

          <hr class="hr_theme" />

          <gxd-datepicker
            *ngIf="betweenModeFrom"
            style="display: flex; align-items: center;"
            [options]="{
              theme: 'jet',
              date: date,
              time: time,
              format: editFormat,
              static: true,
              datepickerClasses: ['datepicker_static', 'datepicker_theme'],
              locale: {} | localizeDatepicker
            }"
            [input]="dp_from"
            (change)="dp_to.focus()"
          ></gxd-datepicker>

          <gxd-datepicker
            *ngIf="!betweenModeFrom"
            style="display: flex; align-items: center;"
            [options]="{
              theme: 'jet',
              date: date,
              time: time,
              format: editFormat,
              static: true,
              datepickerClasses: ['datepicker_static', 'datepicker_theme'],
              locale: {} | localizeDatepicker
            }"
            [input]="dp_to"
            [defaultDate]="mode == modes.Between ? dp_from.value : undefined"
            (change)="dp_to.focus()"
          ></gxd-datepicker>
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>

<div class="input-icon-wrapper">
  <div
    class="input input_small input_button input_fill input_icon-right"
    [class.input_theme]="theme"
    [style.padding-left.px]="10"
    [style.padding-right.px]="valueLabel ? 26 : 10"
    (click)="setOpened(true)"
    cdkOverlayOrigin
    #trigger="cdkOverlayOrigin"
  >
    <ng-container *ngIf="valueLabel">{{ valueLabel }}</ng-container>
    <span *ngIf="!valueLabel" class="icon-calendar"></span>
  </div>
  <span
    *ngIf="valueLabel"
    class="input-icon input-icon_right input-icon_small icon-arrow_down_2"
    [class.input-icon_theme]="theme"
  ></span>
</div>
