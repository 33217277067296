var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';
import { delay, map, tap } from 'rxjs/operators';
import { copyTextToClipboard } from '@common/code';
import { NotificationService } from '@common/notifications';
import { BasePopupComponent } from '@common/popups';
import { AppConfigService } from '@core';
import { AnalyticsEvent, IntercomService, UniversalAnalyticsService } from '@modules/analytics';
import { ServerRequestError } from '@modules/api';
import { BooleanFieldStyle } from '@modules/field-components';
import { NumberFieldType } from '@modules/fields';
import { ModelDescriptionService } from '@modules/model-queries';
import { databaseResourcesEngines, ProjectTokenService, ResourceDeploy, ResourceName } from '@modules/projects';
import { RegionService } from '@modules/regions';
import { ResourceControllerService } from '@modules/resources';
import { capitalize, controlValue, errorToString, generateUUID, isSet, KeyboardEventKeyCode, readFileText } from '@shared';
import { registerResourceSettingsComponent } from '../../../data/resource-settings-components';
import { BaseResourceSettingsComponent } from '../base-resource-settings/base-resource-settings.component';
import { DatabasesResourceSettingsForm } from './databases-resource-settings.form';
var DatabasesResourceSettingsComponent = /** @class */ (function (_super) {
    __extends(DatabasesResourceSettingsComponent, _super);
    function DatabasesResourceSettingsComponent(modelDescriptionService, regionService, notificationService, form, appConfigService, resourceControllerService, popupComponent, projectTokenService, intercomService, analyticsService, cd) {
        var _this = _super.call(this, form, popupComponent, projectTokenService, intercomService, analyticsService, cd) || this;
        _this.modelDescriptionService = modelDescriptionService;
        _this.regionService = regionService;
        _this.notificationService = notificationService;
        _this.form = form;
        _this.appConfigService = appConfigService;
        _this.resourceControllerService = resourceControllerService;
        _this.jetBridgeDeploys = ResourceDeploy;
        _this.deploysWithInstructions = [ResourceDeploy.Python, ResourceDeploy.Docker];
        _this.resourceNames = ResourceName;
        _this.customSettingsResourceNames = [
            ResourceName.BigQuery,
            ResourceName.Snowflake,
            ResourceName.Databricks,
            ResourceName.AmazonAthena,
            ResourceName.MongoDB
        ];
        _this.SSLResourceNames = [ResourceName.MySQL, ResourceName.MariaDB];
        _this.postgreSQLBasedResourceNames = [
            ResourceName.PostgreSQL,
            ResourceName.Redshift,
            ResourceName.AlloyDB,
            ResourceName.Supabase
        ];
        _this.mongoDatabaseUrlEditing = false;
        _this.numberFieldTypes = NumberFieldType;
        _this.booleanFieldStyle = BooleanFieldStyle;
        _this.testConnectionLoading = false;
        _this.chooseTablesLoading = false;
        _this.regionsEnabled = false;
        _this.regionsLoading = false;
        _this.regionDelaysLoading = false;
        _this.defaultSSHPort = 22;
        _this.showPassword = false;
        _this.searchControl = new FormControl('');
        _this.filteredDatabaseTables = [];
        return _this;
    }
    DatabasesResourceSettingsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.regionsEnabled = this.appConfigService.jetBridgeRegions;
        _super.prototype.ngOnInit.call(this);
        this.defaultPort = databaseResourcesEngines
            .filter(function (item) { return item.name == _this.typeItem.name; })
            .map(function (item) { return item.defaultPort; })[0];
        var params = this.params;
        this.installToken = this.form.jetBridgeForm.value['token'] || params.defaultToken || generateUUID();
        controlValue(this.form.deployDirectMongoForm.controls['database_url'])
            .pipe(untilDestroyed(this))
            .subscribe(function (databaseUrl) {
            _this.mongoDatabaseUrlClean = isSet(databaseUrl)
                ? databaseUrl.replace(/^([\w+]+):\/\/([^:]+):[^@/]+@/, '$1://$2:********@')
                : undefined;
            _this.cd.markForCheck();
        });
        controlValue(this.form.form.controls['deploy'])
            .pipe(map(function (value, i) { return [value, i]; }), untilDestroyed(this))
            .subscribe(function (_a) {
            var deploy = _a[0], i = _a[1];
            _this.analyticsService.sendSimpleEvent(AnalyticsEvent.Resource.ResourceMethodSelected, {
                ResourceEditing: !!_this.resource,
                ResourceTypeID: _this.typeItem.name,
                MethodID: deploy,
                Source: _this.analyticsSource,
                Initial: i == 0
            });
        });
        combineLatest(controlValue(this.form.databaseTables), controlValue(this.searchControl))
            .pipe(delay(0), untilDestroyed(this))
            .subscribe(function (_a) {
            var _ = _a[0], search = _a[1];
            return _this.updateFilteredDatabaseTables(search);
        });
        this.form.deployDirectBigQueryForm.valueChanges.pipe(untilDestroyed(this)).subscribe(function () { return _this.cd.markForCheck(); });
    };
    DatabasesResourceSettingsComponent.prototype.onFormInit = function () {
        _super.prototype.onFormInit.call(this);
        if (this.regionsEnabled) {
            this.updateRegions();
        }
    };
    DatabasesResourceSettingsComponent.prototype.updateRegions = function () {
        var _this = this;
        this.regionsLoading = true;
        this.cd.markForCheck();
        this.regionService
            .get()
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            _this.regions = result;
            _this.regionOptions = _this.regions.map(function (item) {
                return {
                    value: item.uid,
                    name: item.name
                };
            });
            _this.regionsLoading = false;
            _this.currentRegion$ = controlValue(_this.form.regionControl).pipe(map(function (value) {
                return value ? _this.regions.find(function (item) { return item.uid == value; }) : undefined;
            }));
            _this.cd.markForCheck();
            _this.updateRegionDelays();
        }, function () {
            _this.regionsLoading = false;
            _this.cd.markForCheck();
        });
    };
    DatabasesResourceSettingsComponent.prototype.updateRegionDelays = function () {
        var _this = this;
        var defaultSet = false;
        this.regionDelaysLoading = true;
        this.cd.markForCheck();
        combineLatest.apply(void 0, this.regions.map(function (region) {
            return _this.regionService.getLatency(region).pipe(tap(function (latency) {
                _this.regionOptions = _this.regionOptions.map(function (item) {
                    if (item.value == region.uid && latency) {
                        return {
                            value: item.value,
                            name: item.name + " (" + latency + "ms delay)"
                        };
                    }
                    else {
                        return item;
                    }
                });
                _this.cd.markForCheck();
                if (!defaultSet) {
                    defaultSet = true;
                    _this.form.setDefaultRegion(region);
                }
            }));
        })).pipe(untilDestroyed(this))
            .subscribe(function () {
            _this.regionDelaysLoading = false;
            _this.cd.markForCheck();
            if (!defaultSet) {
                defaultSet = true;
                var defaultRegion = _this.regions.find(function (item) { return item.default; });
                _this.form.setDefaultRegion(defaultRegion);
            }
        }, function () {
            _this.regionDelaysLoading = false;
            _this.cd.markForCheck();
        });
    };
    DatabasesResourceSettingsComponent.prototype.setMongoDatabaseUrlEditing = function (value) {
        this.mongoDatabaseUrlEditing = value;
        this.cd.markForCheck();
    };
    DatabasesResourceSettingsComponent.prototype.isParamsStep = function () {
        return isSet(this.params['defaultToken']) || isSet(this.params['defaultApiBaseUrl']);
    };
    DatabasesResourceSettingsComponent.prototype.testConnection = function () {
        var _this = this;
        this.testConnectionLoading = true;
        this.cd.markForCheck();
        this.form
            .discoverConnection()
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            _this.testConnectionLoading = false;
            _this.cd.markForCheck();
            _this.notificationService.success('Connected successfully!', 'Database settings are correct');
        }, function (error) {
            _this.testConnectionLoading = false;
            _this.cd.markForCheck();
            if (error instanceof ServerRequestError && error.errors.length) {
                _this.notificationService.error('Unable to connect', error.errors[0]);
            }
            else {
                _this.notificationService.error('Unable to connect', errorToString(error));
            }
        });
    };
    DatabasesResourceSettingsComponent.prototype.chooseTables = function () {
        var _this = this;
        this.chooseTablesLoading = true;
        this.cd.markForCheck();
        this.form
            .discoverTables()
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            _this.form.form.patchValue({ choose_tables: true });
            _this.form.databaseTables.init(result.tables, result.max_tables);
            _this.chooseTablesLoading = false;
            _this.cd.markForCheck();
        }, function (error) {
            _this.chooseTablesLoading = false;
            _this.cd.markForCheck();
            if (error instanceof ServerRequestError && error.errors.length) {
                _this.notificationService.error('Unable to connect', error.errors[0]);
            }
            else {
                _this.notificationService.error('Unable to connect', errorToString(error));
            }
        });
    };
    DatabasesResourceSettingsComponent.prototype.updateFilteredDatabaseTables = function (search) {
        if (search === void 0) { search = ''; }
        if (!isSet(search)) {
            this.filteredDatabaseTables = this.form.databaseTables.controls;
            this.cd.markForCheck();
        }
        var searchClean = search.toLowerCase().trim();
        this.filteredDatabaseTables = this.form.databaseTables.controls.filter(function (control) {
            return control.controls.name.value.toLowerCase().includes(searchClean);
        });
        this.cd.markForCheck();
    };
    DatabasesResourceSettingsComponent.prototype.resetSearch = function () {
        this.searchControl.patchValue('');
        this.updateFilteredDatabaseTables('');
    };
    DatabasesResourceSettingsComponent.prototype.onSearchKey = function (e) {
        if (e.keyCode == KeyboardEventKeyCode.Escape) {
            this.resetSearch();
        }
    };
    DatabasesResourceSettingsComponent.prototype.copy = function (text, contentLabel) {
        var _this = this;
        copyTextToClipboard(text)
            .pipe(untilDestroyed(this))
            .subscribe(function (success) {
            if (!success) {
                return;
            }
            var description = isSet(contentLabel) ? capitalize(contentLabel) + " was copied to clipboard" : undefined;
            _this.notificationService.info('Copied', description);
        });
    };
    DatabasesResourceSettingsComponent.prototype.setShowPassword = function (value) {
        this.showPassword = value;
        this.cd.markForCheck();
    };
    DatabasesResourceSettingsComponent.prototype.toggleShowPassword = function () {
        this.setShowPassword(!this.showPassword);
    };
    DatabasesResourceSettingsComponent.prototype.onFileChange = function (control, el) {
        if (!el.files.length) {
            return;
        }
        var file = el.files[0];
        el.value = null;
        readFileText(file)
            .pipe(untilDestroyed(this))
            .subscribe(function (content) {
            control.patchValue(content);
            control.markAsDirty();
        });
    };
    return DatabasesResourceSettingsComponent;
}(BaseResourceSettingsComponent));
export { DatabasesResourceSettingsComponent };
registerResourceSettingsComponent(ResourceName.PostgreSQL, DatabasesResourceSettingsComponent);
registerResourceSettingsComponent(ResourceName.MySQL, DatabasesResourceSettingsComponent);
registerResourceSettingsComponent(ResourceName.MariaDB, DatabasesResourceSettingsComponent);
registerResourceSettingsComponent(ResourceName.MicrosoftSQL, DatabasesResourceSettingsComponent);
registerResourceSettingsComponent(ResourceName.Oracle, DatabasesResourceSettingsComponent);
registerResourceSettingsComponent(ResourceName.BigQuery, DatabasesResourceSettingsComponent);
registerResourceSettingsComponent(ResourceName.Snowflake, DatabasesResourceSettingsComponent);
registerResourceSettingsComponent(ResourceName.Redshift, DatabasesResourceSettingsComponent);
registerResourceSettingsComponent(ResourceName.Databricks, DatabasesResourceSettingsComponent);
registerResourceSettingsComponent(ResourceName.ClickHouse, DatabasesResourceSettingsComponent);
registerResourceSettingsComponent(ResourceName.AlloyDB, DatabasesResourceSettingsComponent);
registerResourceSettingsComponent(ResourceName.CockroachDB, DatabasesResourceSettingsComponent);
registerResourceSettingsComponent(ResourceName.AmazonAthena, DatabasesResourceSettingsComponent);
registerResourceSettingsComponent(ResourceName.Supabase, DatabasesResourceSettingsComponent);
registerResourceSettingsComponent(ResourceName.SQLite, DatabasesResourceSettingsComponent);
registerResourceSettingsComponent(ResourceName.MongoDB, DatabasesResourceSettingsComponent);
