var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { isPlatformServer } from '@angular/common';
import { environment } from '@env/environment';
// TODO: Refactor imports
import { parseBoolean } from '../../../shared/utils/types/types';
import * as i0 from "@angular/core";
/**
 * Компонент конфигурации
 */
var AppConfigService = /** @class */ (function () {
    function AppConfigService(platformId) {
        this.platformId = platformId;
        this.jetBridgeRegions = false;
        this.deploymentTypeEnabled = false;
        this.paddleSandbox = false;
        this.socialAuthFacebookEnabled = false;
        this.socialAuthGoogleEnabled = false;
        this.snapshotsEnabled = false;
        this.production = environment.production;
        this.environment = environment.environment;
        this.version = environment.version;
        this.webBaseUrl = environment.webBaseUrl;
        this.subdomainsBaseDomain = environment.subdomainsBaseDomain;
        this.serverBaseUrl = environment.serverBaseUrl;
        this.serverNodeBaseUrl = environment.serverNodeBaseUrl;
        this.serverNodeDirectBaseUrl = environment.serverNodeDirectBaseUrl;
        this.dataSyncBaseUrl = environment.dataSyncBaseUrl;
        this.workflowsBaseUrl = environment.workflowsBaseUrl;
        this.dataSourcesBaseUrl = environment.dataSourcesBaseUrl;
        this.dataSourcesDirectBaseUrl = environment.dataSourcesDirectBaseUrl;
        this.ideBaseUrl = environment.ideBaseUrl;
        this.jetBridgeCloudBaseUrl = environment.jetBridgeCloudBaseUrl;
        this.jetBridgeRegions = parseBoolean(environment.jetBridgeRegions);
        this.deploymentTypeEnabled = parseBoolean(environment.deploymentTypeEnabled);
        this.centrifugoUrl = environment.centrifugoUrl;
        this.googleSheetsAppId = environment.googleSheetsAppId;
        this.googleSheetsApiKey = environment.googleSheetsApiKey;
        this.airtableApiKey = environment.airtableApiKey;
        this.paddleVendorId = parseInt(environment.paddleVendorId, 10);
        this.paddleSandbox = parseBoolean(environment.paddleSandbox);
        this.socialAuthFacebookEnabled = parseBoolean(environment.socialAuthFacebookEnabled);
        this.socialAuthGoogleEnabled = parseBoolean(environment.socialAuthGoogleEnabled);
        this.defaultEmailFrom = environment.defaultEmailFrom || 'no-reply@jetadmin.app';
        this.googleTagManager = environment.googleTagManager;
        this.snapshotsEnabled = parseBoolean(environment.snapshotsEnabled);
        window['environment'] = __assign({}, environment);
    }
    AppConfigService.prototype.isPlatformServer = function () {
        return isPlatformServer(this.platformId);
    };
    AppConfigService.prototype.getMediaBaseUrl = function () {
        var project = window['project'];
        if (!project) {
            return;
        }
        return this.serverBaseUrl + "/media/static_files/projects/" + project;
    };
    AppConfigService.ngInjectableDef = i0.defineInjectable({ factory: function AppConfigService_Factory() { return new AppConfigService(i0.inject(i0.PLATFORM_ID)); }, token: AppConfigService, providedIn: "root" });
    return AppConfigService;
}());
export { AppConfigService };
