import { CommonModule } from '@angular/common';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgGxSelectModule } from 'ng-gxselect';

import { DialogsModule } from '@common/dialogs';
import { DragDropModule } from '@common/drag-drop';
import { FormUtilsModule } from '@common/form-utils';
import { TipsModule } from '@common/tips';
import { ApiModule } from '@modules/api';
import { FieldComponentsModule } from '@modules/field-components';
import { FieldsModule } from '@modules/fields';
import { ModelQueriesModule } from '@modules/model-queries';
import { RoutingModule } from '@modules/routing';
import { SidebarModule } from '@modules/sidebar';
import { SharedModule } from '@shared';
import { UiModule } from '@ui';

import { ChangeCustomViewFilesComponent } from './components/change-custom-view/change-custom-view-files/change-custom-view-files.component';
import { ChangeCustomViewComponent } from './components/change-custom-view/change-custom-view.component';
import { CommonCustomViewComponent } from './components/common-custom-view/common-custom-view.component';
import { StandaloneCustomViewComponent } from './components/custom-view/standalone-custom-view.component';
import { ModelCustomViewComponent } from './components/model-custom-view/model-custom-view.component';
import { ModelDescriptionCustomViewComponent } from './components/model-description-custom-view/model-description-custom-view.component';
import { routes } from './custom-views-routes.routes';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    RoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgGxSelectModule,
    ApiModule,
    FieldsModule,
    SharedModule,
    ModelQueriesModule,
    TipsModule,
    DialogsModule,
    FieldComponentsModule,
    DragDropModule,
    SidebarModule,
    UiModule,
    FormUtilsModule
  ],
  declarations: [
    ChangeCustomViewComponent,
    CommonCustomViewComponent,
    ModelDescriptionCustomViewComponent,
    ModelCustomViewComponent,
    StandaloneCustomViewComponent,
    ChangeCustomViewFilesComponent
  ],
  exports: [ModelDescriptionCustomViewComponent, ModelCustomViewComponent, ChangeCustomViewFilesComponent],
  schemas: [NO_ERRORS_SCHEMA]
})
export class CustomViewsRoutesModule {}
