<form [formGroup]="form" (ngSubmit)="submit()">
  <app-customize-bar-header
    [title]="form.controls.name.value || (titleEditable ? 'JavaScript' : 'JavaScript')"
    [titleEnabled]="titleEnabled"
    [titleEditable]="titleEditable"
    [titleCleanValue]="titleCleanValue"
    [backLabel]="'All components'"
    [deleteEnabled]="deleteEnabled"
    [parentLabel]="parentElement?.displayName || parentPopup?.displayName"
    (parentClick)="parentElement?.customize({ event: $event }) || parentPopup?.customize({ event: $event })"
    (deleteClick)="delete()"
    (titleChanged)="onTitleChanged($event)"
  >
  </app-customize-bar-header>

  <div class="sidebar__content">
    <app-sidebar-tabs>
      <app-sidebar-tab [label]="'General'">
        <app-sidebar-section>
          <div *ngIf="customView && !customView.filesIde" class="sidebar__element">
            <app-sidebar-field [label]="'Deprecation warning'" [icon]="'warning'">
              <app-alert [warning]="true" [compact]="true">
                Previously created JS components are now deprecated. They will continue to work, but if you want to
                apply changes – upload source codes and build them using JS editor.
              </app-alert>
            </app-sidebar-field>
          </div>

          <div class="sidebar__element">
            <app-custom-view-item-edit
              [customViewName]="form.controls.custom_view"
              [customViewDefaults]="customViewDefaults"
              [initialTemplatesFilter]="{ type: customViewTemplateTypes.Custom }"
              [analyticsSource]="element.analyticsName"
              (selectCustomView)="onCustomViewSelected($event)"
            ></app-custom-view-item-edit>
          </div>
        </app-sidebar-section>

        <app-sidebar-section [title]="'Parameters'" [documentation]="'inputs'" [disabled]="!customView?.filesJs.length">
          <div class="sidebar__list">
            <app-inputs-edit
              [control]="form.controls.inputs"
              [parameterProvider]="form.inputFieldProvider"
              [addInputEnabled]="true"
              [context]="context"
              [contextElement]="contextElement"
              [classes]="'custom-component-inputs-item'"
              [analyticsSource]="element.analyticsGenericName"
              (addInput)="itemEditComponent.openIDE()"
            >
            </app-inputs-edit>
          </div>
        </app-sidebar-section>
      </app-sidebar-tab>

      <app-sidebar-tab [label]="'Display'">
        <div class="sidebar__element">
          <app-sidebar-field [label]="'Width'" [layoutRight]="true">
            <div class="sidebar-icon-buttons sidebar-icon-buttons_border">
              <div
                class="sidebar-icon-button"
                [class.sidebar-icon-button_active]="!form.controls.width_fluid.value"
                (click)="form.controls.width_fluid.setValue(false)"
              >
                <div class="sidebar-icon-button__label">Fixed</div>
              </div>

              <div
                class="sidebar-icon-button"
                [class.sidebar-icon-button_active]="form.controls.width_fluid.value"
                (click)="form.controls.width_fluid.setValue(true)"
              >
                <div class="sidebar-icon-button__label">Fluid</div>
              </div>
            </div>
          </app-sidebar-field>
        </div>

        <div class="sidebar__element">
          <app-sidebar-field [label]="'Height'" [layoutRight]="true">
            <div class="sidebar-icon-buttons sidebar-icon-buttons_border">
              <div
                class="sidebar-icon-button"
                [class.sidebar-icon-button_active]="!form.controls.height_fluid.value"
                (click)="form.controls.height_fluid.setValue(false)"
              >
                <div class="sidebar-icon-button__label">Fixed</div>
              </div>

              <div
                class="sidebar-icon-button"
                [class.sidebar-icon-button_active]="form.controls.height_fluid.value"
                (click)="form.controls.height_fluid.setValue(true)"
              >
                <div class="sidebar-icon-button__label">Fluid</div>
              </div>
            </div>
          </app-sidebar-field>
        </div>

        <div class="sidebar__element">
          <app-sidebar-field
            [label]="'Conditional Visibility'"
            [documentation]="'conditional-visibility'"
            [documentationLabel]="'See how it works'"
            [documentationSource]="element.analyticsGenericName + '_visible'"
          >
            <app-input-edit
              [itemForm]="form.controls.visible_input"
              [context]="context"
              [staticValueDisabled]="true"
              [placeholder]="'Value'"
              [fill]="true"
              [analyticsSource]="element.analyticsGenericName + '_visible'"
            ></app-input-edit>

            <ng-container description>
              Example: elements.field.value >= 10
            </ng-container>
          </app-sidebar-field>
        </div>

        <div class="sidebar__element">
          <app-element-wrap-edit [control]="form.controls.card_wrap"></app-element-wrap-edit>
        </div>

        <div class="sidebar__element">
          <app-sidebar-field [label]="'Spacing'">
            <app-margin-control [control]="form.controls.margin"></app-margin-control>
          </app-sidebar-field>
        </div>
      </app-sidebar-tab>

      <app-sidebar-tab [label]="'Actions'" [disabled]="!customView">
        <app-sidebar-section *ngIf="customView">
          <div class="sidebar__list">
            <div class="sidebar-list__separator">
              <app-sidebar-list-separator [title]="'Component actions'"></app-sidebar-list-separator>
            </div>
            <div class="sidebar-list">
              <div *ngFor="let output of customView.actions; trackBy: trackActionOutput" class="sidebar-list__item">
                <app-action-single-edit
                  *ngIf="form.controls.actions.controls[output.name] as control"
                  [title]="output.verboseName"
                  [control]="control"
                  [context]="context"
                  [contextElement]="contextElement"
                  [defaultSubtitle]="'Not specified'"
                  [remove]="control.value"
                  [icon]="output.icon || 'power'"
                  [object]="element.analyticsName"
                  [firstInit]="firstInit"
                >
                </app-action-single-edit>
              </div>

              <div *ngIf="!customView.actions.length" class="sidebar__message">
                There are no actions in component
              </div>
            </div>
          </div>
        </app-sidebar-section>
      </app-sidebar-tab>
    </app-sidebar-tabs>
  </div>

  <!--  <app-sidebar-submit-->
  <!--    [saveDisabled]="form.form.invalid || form.form.disabled"-->
  <!--    [errors]="form.form | appFormErrors"-->
  <!--    (cancelClick)="cancel()"-->
  <!--    (deleteClick)="delete()"-->
  <!--  >-->
  <!--  </app-sidebar-submit>-->
</form>
