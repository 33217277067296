import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional
} from '@angular/core';
import isEqual from 'lodash/isEqual';
import pickBy from 'lodash/pickBy';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';

import { CustomView, CustomViewService, CustomViewSource, CustomViewsStore } from '@modules/custom-views';
import {
  CustomElementItem,
  CustomizeService,
  ElementType,
  registerElementComponent,
  ViewContextElement
} from '@modules/customize';
import { BaseElementComponent } from '@modules/customize-elements';
import { applyParamInputs$, LOADING_VALUE, NOT_SET_VALUE } from '@modules/fields';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { isSet, TypedChanges } from '@shared';

import { CustomPagePopupComponent } from '../custom-page-popup/custom-page-popup.component';

@Component({
  selector: 'app-custom-element',
  templateUrl: './custom-element.component.html',
  providers: [ViewContextElement],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomElementComponent extends BaseElementComponent implements OnInit, OnDestroy, OnChanges {
  @Input() element: CustomElementItem;

  loading = true;
  element$ = new BehaviorSubject<CustomElementItem>(undefined);
  source: CustomViewSource;
  customView$ = new BehaviorSubject<CustomView>(undefined);
  featureSources: CustomViewSource[] = [CustomViewSource.HTML, CustomViewSource.CustomElement];
  params = {};
  firstVisible = false;

  constructor(
    public currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    public customizeService: CustomizeService,
    public viewContextElement: ViewContextElement,
    private customViewService: CustomViewService,
    private customViewsStore: CustomViewsStore,
    private cd: ChangeDetectorRef,
    @Optional() private popup: CustomPagePopupComponent
  ) {
    super();
  }

  ngOnInit() {
    this.element$.next(this.element);
    this.initCustomView();
    this.initContext(this.element);
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<CustomElementComponent>): void {
    if (changes.element && !changes.element.firstChange) {
      this.element$.next(this.element);
      this.updateContextInfo(this.element);
    }
  }

  onFirstVisible() {
    this.firstVisible = true;
    this.initParams();
  }

  initCustomView() {
    this.element$
      .pipe(
        switchMap(element => {
          if (element && isSet(element.customView)) {
            return this.customViewsStore
              .getDetail(element.customView)
              .pipe(map(customView => ({ element: element, customView: customView })));
          } else {
            return of<{ element: CustomElementItem; customView: CustomView }>({
              element: element,
              customView: undefined
            });
          }
        }),
        untilDestroyed(this)
      )
      .subscribe(({ element, customView }) => {
        this.source = this.getSourceEffective(element, customView);
        this.customView$.next(customView);
        this.loading = false;
        this.cd.markForCheck();
      });
  }

  initParams() {
    combineLatest(this.element$, this.customView$)
      .pipe(
        switchMap(([element, customView]) => {
          if (!element || !customView) {
            return of({});
          }

          return applyParamInputs$({}, element.inputs, {
            context: this.context,
            parameters: customView.parameters,
            handleLoading: true
          });
        }),
        map(params => pickBy(params, (v, k) => v !== LOADING_VALUE && v !== NOT_SET_VALUE)),
        debounceTime(10),
        distinctUntilChanged((lhs, rhs) => isEqual(lhs, rhs)),
        untilDestroyed(this)
      )
      .subscribe(params => {
        this.params = params;
        this.cd.markForCheck();
      });
  }

  getSourceEffective(element: CustomElementItem, customView?: CustomView): CustomViewSource {
    return customView && isSet(customView.source) ? customView.source : element.source;
  }

  initContext(element: CustomElementItem) {
    this.viewContextElement.initElement({
      uniqueName: element.uid,
      name: element.name,
      element: element,
      popup: this.popup ? this.popup.popup : undefined
    });
  }

  updateContextInfo(element: CustomElementItem) {
    this.viewContextElement.initInfo({ name: element.name, element: this.element }, true);
  }
}

registerElementComponent({
  type: ElementType.Custom,
  component: CustomElementComponent,
  label: 'Custom Component',
  actions: []
});
