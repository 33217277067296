<form *ngIf="form.form" [formGroup]="form.form" (ngSubmit)="execute()">
  <app-popup2 [size]="'m'" [theme]="theme">
    <app-popup2-header>
      <app-popup2-title>
        <ng-container *ngIf="actionParams.length">{{ 'Specify parameters' | localize }}</ng-container>
        <ng-container *ngIf="!actionParams.length">{{ 'Executing action' | localize }}</ng-container>
      </app-popup2-title>

      <app-popup2-description>
        <span [innerHTML]="'You are executing <strong>{0}</strong>' | localize: [actionLabel]"></span>
        <ng-container *ngIf="executeTotalCount > 1"> ({{ '{0} items' | localize: [executeTotalCount] }})</ng-container>.
        <ng-container *ngIf="actionParams.length">
          <br />{{ 'Please fill in the parameters below.' | localize }}</ng-container
        >
      </app-popup2-description>
    </app-popup2-header>

    <ng-container *ngIf="!executeStarted">
      <app-page-block *ngFor="let item of actionParams">
        <app-auto-field
          [form]="form.form"
          [field]="
            createField({
              name: item.name,
              label: item.verboseName || item.name,
              required: item.required,
              field: item.field,
              placeholder: item.placeholder,
              params: item.params
            })
          "
          [context]="fieldContext"
          [accentColor]="'accentColor' | appThemeColorHexOption: true | async"
        >
        </app-auto-field>
      </app-page-block>

      <app-page-block>
        <app-form-errors [form]="form.form" [manualMargin]="true"></app-form-errors>
      </app-page-block>

      <app-popup2-footer>
        <app-page-block>
          <app-stack [align]="'right'">
            <a
              href="javascript:void(0)"
              class="button popup2__footer-item"
              [class.button_disabled]="executeStarted"
              [class.button_theme]="theme"
              [appButtonTintColor]="theme ? ('accentColor' | appThemeColorHexOption: true | async) : undefined"
              [appButtonTintColorStyle]="tintStyles.Default"
              (click)="cancel()"
            >
              <span class="button__label">{{ 'Cancel' | localize }}</span>
            </a>

            <a
              href="javascript:void(0)"
              class="button button_primary popup2__footer-item"
              [class.button_disabled]="
                form.form.invalid || executeStarted || executeProcessedCount == executeTotalCount
              "
              [class.button_theme]="theme"
              [appButtonTintColor]="theme ? ('accentColor' | appThemeColorHexOption: true | async) : undefined"
              [appButtonTintColorStyle]="tintStyles.Primary"
              style="margin-right: 10px;"
              (click)="execute()"
            >
              <app-loader-small
                *ngIf="executeStarted && executeProcessedCount < executeTotalCount"
                class="button__icon button__icon_left"
              ></app-loader-small>
              <span class="button__label">{{ submitLabel }}</span>
            </a>
          </app-stack>
        </app-page-block>
      </app-popup2-footer>
    </ng-container>

    <ng-container *ngIf="executeStarted">
      <app-page-block>
        <app-bar-progress
          *ngIf="actionDescription.queryAction"
          [label]="
            executeProcessedCount == executeTotalCount ? ('Finished' | localize) : ('Executing' | localize) + '...'
          "
          [progress]="executeProcessedCount / executeTotalCount"
          [processed]="executeProcessedCount"
          [total]="executeTotalCount"
          [accentColor]="'accentColor' | appThemeColorHexOption: true | async"
        >
        </app-bar-progress>
      </app-page-block>

      <app-popup2-footer>
        <app-page-block>
          <app-stack [align]="'right'">
            <a
              href="javascript:void(0)"
              class="button button_primary popup2__footer-item"
              [class.button_disabled]="executeProcessedCount != executeTotalCount"
              [class.button_theme]="theme"
              [appButtonTintColor]="theme ? ('accentColor' | appThemeColorHexOption: true | async) : undefined"
              [appButtonTintColorStyle]="tintStyles.Primary"
              (click)="close()"
            >
              <span class="button__label">
                <ng-container *ngIf="executeProcessedCount == executeTotalCount">{{ 'Done' | localize }}</ng-container>
                <ng-container *ngIf="executeProcessedCount != executeTotalCount">{{
                  'Executing' | localize
                }}</ng-container>
              </span>
            </a>
          </app-stack>
        </app-page-block>
      </app-popup2-footer>
    </ng-container>
  </app-popup2>
</form>
