/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../fields/components/auto-field/auto-field.component.ngfactory";
import * as i2 from "../../../fields/components/auto-field/auto-field.component";
import * as i3 from "@angular/common";
import * as i4 from "../../../custom-elements-components/components/custom-element-auto/custom-element-auto.component.ngfactory";
import * as i5 from "../../../custom-elements-components/components/custom-element-auto/custom-element-auto.component";
import * as i6 from "../../../action-queries/services/action-controller/action-controller.service";
import * as i7 from "../table-item-column-action/table-item-column-action.component.ngfactory";
import * as i8 from "../table-item-column-action/table-item-column-action.component";
import * as i9 from "../../../customize/services/customize/customize.service";
import * as i10 from "../../../action-queries/services/action/action.service";
import * as i11 from "../../../../common/notifications/services/notification/notification.service";
import * as i12 from "@angular/cdk/overlay";
import * as i13 from "@angular/cdk/bidi";
import * as i14 from "../../../../shared/pipes/is-set/is-set.pipe";
import * as i15 from "../../../../shared/pipes/safe-async/safe-async.pipe";
import * as i16 from "./table-item-column.component";
import * as i17 from "../../../custom-views/stores/custom-views.store";
var styles_TableItemColumnComponent = [];
var RenderType_TableItemColumnComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TableItemColumnComponent, data: {} });
export { RenderType_TableItemColumnComponent as RenderType_TableItemColumnComponent };
function View_TableItemColumnComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-auto-field", [], null, null, null, i1.View_AutoFieldComponent_0, i1.RenderType_AutoFieldComponent)), i0.ɵdid(1, 770048, null, 0, i2.AutoFieldComponent, [i0.ChangeDetectorRef], { form: [0, "form"], field: [1, "field"], readonly: [2, "readonly"], value: [3, "value"], label: [4, "label"], autofocus: [5, "autofocus"], context: [6, "context"], truncate: [7, "truncate"], compact: [8, "compact"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.parent.parent.context.readonly ? undefined : _co.form.form); var currVal_1 = _co.createField(_v.parent.parent.context.column.column); var currVal_2 = _v.parent.parent.context.readonly; var currVal_3 = _co.model.getAttribute(_v.parent.parent.context.column.column.name); var currVal_4 = false; var currVal_5 = true; var currVal_6 = _co.contextElement.context; var currVal_7 = !((_v.parent.parent.context.column.column.field == _co.fieldTypes.Text) && _v.parent.parent.context.column.column.params["multiline"]); var currVal_8 = true; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }, null); }
function View_TableItemColumnComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "])), i0.ɵppd(2, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.formatEmpty(i0.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i0.ɵnov(_v.parent.parent.parent, 1), _v.parent.parent.context.column.listItem.str))); _ck(_v, 1, 0, currVal_0); }); }
function View_TableItemColumnComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_TableItemColumnComponent_3)), i0.ɵdid(2, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵppd(3, 1), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_TableItemColumnComponent_4)), i0.ɵdid(5, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵppd(6, 1), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = (!_v.parent.context.readonly || !i0.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i0.ɵnov(_v.parent.parent, 0), ((_v.parent.context.column.listItem == null) ? null : _v.parent.context.column.listItem.str)))); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_v.parent.context.readonly && i0.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i0.ɵnov(_v.parent.parent, 0), ((_v.parent.context.column.listItem == null) ? null : _v.parent.context.column.listItem.str)))); _ck(_v, 5, 0, currVal_1); }, null); }
function View_TableItemColumnComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-custom-element-auto", [], null, null, null, i4.View_CustomElementAutoComponent_0, i4.RenderType_CustomElementAutoComponent)), i0.ɵdid(1, 245760, null, 0, i5.CustomElementAutoComponent, [i6.ActionControllerService, i0.Injector], { source: [0, "source"], customView: [1, "customView"], params: [2, "params"], context: [3, "context"], contextElement: [4, "contextElement"], stateSelectedEnabled: [5, "stateSelectedEnabled"], stateSelected: [6, "stateSelected"] }, null), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.customView.source; var currVal_1 = _co.customView; var currVal_2 = i0.ɵunv(_v, 1, 2, i0.ɵnov(_v, 2).transform(_co.customViewParams$)); var currVal_3 = _co.context; var currVal_4 = _co.contextElement; var currVal_5 = true; var currVal_6 = _co.selected; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
function View_TableItemColumnComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_TableItemColumnComponent_2)), i0.ɵdid(1, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TableItemColumnComponent_5)), i0.ɵdid(3, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.customView; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.customView; _ck(_v, 3, 0, currVal_1); }, null); }
function View_TableItemColumnComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-table-item-column-action", [["class", "related-link__action"]], [[2, "element__indicator-wrapper", null], [2, "hidden", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = ($event.stopPropagation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_TableItemColumnActionComponent_0, i7.RenderType_TableItemColumnActionComponent)), i0.ɵdid(1, 770048, null, 0, i8.TableItemColumnActionComponent, [i9.CustomizeService, i10.ActionService, i6.ActionControllerService, i11.NotificationService, i0.Injector, i0.ChangeDetectorRef], { row: [0, "row"], item: [1, "item"], context: [2, "context"], contextElement: [3, "contextElement"], theme: [4, "theme"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.row; var currVal_3 = _v.context.$implicit; var currVal_4 = _co.context; var currVal_5 = _co.contextElement; var currVal_6 = _co.theme; _ck(_v, 1, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).indicatorWrapper; var currVal_1 = i0.ɵnov(_v, 1).hidden; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_TableItemColumnComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, [[2, 0], ["action_element", 1]], null, 7, "div", [["class", "popover2"]], [[2, "popover2_theme", null]], null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i3.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 5, "div", [["class", "popover2__inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 3, "div", [["class", "popover2__content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 2, "div", [["class", "related-link"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TableItemColumnComponent_8)), i0.ɵdid(6, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(7, 0, null, null, 0, "div", [["class", "popover2__arrow"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_1 = "popover2"; var currVal_2 = _co.overlayClasses; _ck(_v, 1, 0, currVal_1, currVal_2); var currVal_3 = _co.actions; _ck(_v, 6, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.theme; _ck(_v, 0, 0, currVal_0); }); }
function View_TableItemColumnComponent_9(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "div", [], [[2, "table__column-inner", null]], null, null, null, null)), (_l()(), i0.ɵeld(2, 16777216, null, null, 2, null, null, null, null, null, null, null)), i0.ɵdid(3, 540672, null, 0, i3.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i0.ɵpod(4, { column: 0, readonly: 1 })], function (_ck, _v) { var currVal_1 = _ck(_v, 4, 0, _v.parent.context.column, true); var currVal_2 = i0.ɵnov(_v.parent.parent, 5); _ck(_v, 3, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !((_v.parent.context.column.column.field == _co.fieldTypes.Text) && _v.parent.context.column.column.params["wide"]); _ck(_v, 1, 0, currVal_0); }); }
function View_TableItemColumnComponent_11(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "a", [["class", "edit-link__action icon icon-check_2"], ["href", "javascript:void(0)"]], [[2, "edit-link__action_disabled", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        $event.stopPropagation();
        var pd_0 = (_co.saveEdit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "a", [["class", "edit-link__action icon icon-close"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        $event.stopPropagation();
        var pd_0 = (_co.cancelEdit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.editValid; _ck(_v, 1, 0, currVal_0); }); }
function View_TableItemColumnComponent_12(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "span", [["class", "edit-link__loader"]], null, null, null, null, null))], null, null); }
function View_TableItemColumnComponent_10(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 16777216, null, null, 2, null, null, null, null, null, null, null)), i0.ɵdid(2, 540672, null, 0, i3.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i0.ɵpod(3, { column: 0, readonly: 1 }), (_l()(), i0.ɵeld(4, 0, null, null, 4, "span", [["class", "edit-link__actions"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TableItemColumnComponent_11)), i0.ɵdid(6, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TableItemColumnComponent_12)), i0.ɵdid(8, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, _v.parent.context.column, false); var currVal_1 = i0.ɵnov(_v.parent.parent, 5); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = !_co.editLoading; _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.editLoading; _ck(_v, 8, 0, currVal_3); }, null); }
function View_TableItemColumnComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, [["overlay", 2]], null, 8, null, View_TableItemColumnComponent_7)), i0.ɵdid(1, 671744, [[3, 4]], 0, i12.CdkConnectedOverlay, [i12.Overlay, i0.TemplateRef, i0.ViewContainerRef, i12.ɵc, [2, i13.Directionality]], { origin: [0, "origin"], positions: [1, "positions"], backdropClass: [2, "backdropClass"], panelClass: [3, "panelClass"], open: [4, "open"], hasBackdrop: [5, "hasBackdrop"], growAfterOpen: [6, "growAfterOpen"], push: [7, "push"] }, null), i0.ɵpad(2, 1), i0.ɵpod(3, { panelClass: 0, originX: 1, overlayX: 2, originY: 3, overlayY: 4, offsetX: 5, offsetY: 6 }), i0.ɵpad(4, 1), i0.ɵpod(5, { panelClass: 0, originX: 1, overlayX: 2, originY: 3, overlayY: 4, offsetX: 5, offsetY: 6 }), i0.ɵpad(6, 2), i0.ɵpad(7, 1), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(9, 0, [[1, 0], ["column_element", 1]], null, 5, "div", [["cdkOverlayOrigin", ""]], [[2, "edit-link", null]], [[null, "mousedown"], [null, "mouseenter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mousedown" === en)) {
        var pd_0 = ($event.stopPropagation() !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (_co.onColumnMouseEnter() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(10, 16384, [["trigger", 4]], 0, i12.CdkOverlayOrigin, [i0.ElementRef], null, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TableItemColumnComponent_9)), i0.ɵdid(12, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TableItemColumnComponent_10)), i0.ɵdid(14, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 10); var currVal_1 = _ck(_v, 6, 0, _ck(_v, 3, 0, _ck(_v, 2, 0, "overlay_position_top-center"), "center", "center", "top", "bottom", 0, 0), _ck(_v, 5, 0, _ck(_v, 4, 0, "overlay_position_bottom-center"), "center", "center", "bottom", "top", 0, 0)); var currVal_2 = "popover2-backdrop"; var currVal_3 = _ck(_v, 7, 0, "overlay"); var currVal_4 = i0.ɵunv(_v, 1, 4, i0.ɵnov(_v, 8).transform(_co.actionOpened$)); var currVal_5 = false; var currVal_6 = true; var currVal_7 = true; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_9 = !_co.edit; _ck(_v, 12, 0, currVal_9); var currVal_10 = _co.edit; _ck(_v, 14, 0, currVal_10); }, function (_ck, _v) { var currVal_8 = _v.context.column.column.editable; _ck(_v, 9, 0, currVal_8); }); }
export function View_TableItemColumnComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i14.IsSetPipe, []), i0.ɵpid(0, i15.SafeAsyncPipe, [i0.ChangeDetectorRef]), i0.ɵqud(671088640, 1, { columnElement: 0 }), i0.ɵqud(671088640, 2, { actionElement: 0 }), i0.ɵqud(671088640, 3, { overlay: 0 }), (_l()(), i0.ɵand(0, [["field_template", 2]], null, 0, null, View_TableItemColumnComponent_1)), (_l()(), i0.ɵand(0, [["link_template", 2]], null, 0, null, View_TableItemColumnComponent_6)), (_l()(), i0.ɵeld(7, 16777216, null, null, 2, null, null, null, null, null, null, null)), i0.ɵdid(8, 540672, null, 0, i3.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i0.ɵpod(9, { column: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 9, 0, _co.column); var currVal_1 = i0.ɵnov(_v, 6); _ck(_v, 8, 0, currVal_0, currVal_1); }, null); }
export function View_TableItemColumnComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-table-item-column", [], null, null, null, View_TableItemColumnComponent_0, RenderType_TableItemColumnComponent)), i0.ɵdid(1, 770048, null, 0, i16.TableItemColumnComponent, [i17.CustomViewsStore, i6.ActionControllerService, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TableItemColumnComponentNgFactory = i0.ɵccf("app-table-item-column, [app-table-item-column]", i16.TableItemColumnComponent, View_TableItemColumnComponent_Host_0, { row: "row", column: "column", model: "model", modelDescription: "modelDescription", form: "form", settings: "settings", selected: "selected", context: "context", contextElement: "contextElement", theme: "theme" }, {}, []);
export { TableItemColumnComponentNgFactory as TableItemColumnComponentNgFactory };
