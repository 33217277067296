var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { InjectionToken, Injector, OnDestroy, Type } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import defaults from 'lodash/defaults';
import isArray from 'lodash/isArray';
import values from 'lodash/values';
import { combineLatest, merge, of, Subject } from 'rxjs';
import { debounceTime, delay, distinctUntilChanged, first, map, switchMap, tap } from 'rxjs/operators';
import { FormUtils } from '@common/form-utils';
import { NotificationType } from '@common/notifications';
import { ActionDescriptionService, ActionService, ActionStore } from '@modules/action-queries';
import { ActionDescription, ActionItem, ActionType, Approve, ClosePopupAction, CopyToClipboardAction, DownloadAction, DownloadActionType, ExportAction, ExportDataType, ImportAction, LinkAction, NotificationAction, OpenActionMenuAction, OpenPopupAction, QueryAction, RunJavaScriptAction, SegueType, SetPropertyAction, TintStyle, ViewSettingsAction, WorkflowAction } from '@modules/actions';
import { ActionElementStyles, CustomizeService, CustomViewSettings, PopupSettings, RawListViewSettingsColumn, ViewContext, ViewContextElement, ViewSettings, ViewSettingsService, ViewSettingsStore, ViewSettingsType } from '@modules/customize';
import { ElementConfigurationService } from '@modules/customize-configuration';
import { DataSourceType } from '@modules/data-sources';
import { FieldOutput, Input, Input as FieldInput, InputValueType, isInputSet, isRequiredInputsSet, ParameterArray, ParameterField } from '@modules/fields';
import { ModelDescriptionStore } from '@modules/model-queries';
import { PER_PAGE_PARAM } from '@modules/models';
import { FieldInputControl, InputFieldProvider, inputFieldProviderItemsFromModelGet, inputFieldProviderItemsFromModelGetDetail, modelDescriptionHasAutoParameters, parametersToProviderItems, parametersToProviderItemsFlat } from '@modules/parameters';
import { CurrentEnvironmentStore, CurrentProjectStore, getResourceTypeItemRequestName, ResourceType } from '@modules/projects';
import { ActionQuery, editableQueryTypes, ListModelDescriptionQuery, ModelDescriptionQuery, QueryService, QueryType } from '@modules/queries';
import { isResourceCustom, ResourceControllerService, RestAPIResourceParams } from '@modules/resources';
import { ascComparator, controlValid, controlValue, isSet, splitmax } from '@shared';
import { ListModelDescriptionDataSourceControl } from '../model-description-data-source-edit/list-model-description-data-source';
import { ActionElementStylesControl } from '../styles-action-element-edit/action-element-styles.control';
import { ConfirmationControl } from './confirmation.control';
export var validateResource = function (control) {
    if (!control.parent) {
        return;
    }
    var type = control.parent.controls['type'].value;
    var downloadActionType = control.parent.controls['download_action_type'].value;
    var downloadQueryAction = type == ActionType.Download && downloadActionType == DownloadActionType.Query;
    if (type != ActionType.Query && !downloadQueryAction) {
        return;
    }
    if (!isSet(control.value)) {
        return { required: true };
    }
};
export var validateQuery = function (control) {
    if (!control.parent) {
        return;
    }
    var type = control.parent.controls['type'].value;
    var downloadActionType = control.parent.controls['download_action_type'].value;
    var downloadQueryAction = type == ActionType.Download && downloadActionType == DownloadActionType.Query;
    if (type != ActionType.Query && !downloadQueryAction) {
        return;
    }
    var query = control.value;
    if (!query || !query.isConfigured()) {
        return { required: true };
    }
};
export var validateLink = function (control) {
    if (!control.parent) {
        return;
    }
    var type = control.parent.controls['type'].value;
    if (type != ActionType.Link) {
        return;
    }
    var value = control.value;
    if (!value || ![SegueType.PreviousPage, SegueType.Page, SegueType.ModelChange].includes(value.type)) {
        return { required: true };
    }
};
export var validateInputs = function (control) {
    var parent = control.parent;
    if (!parent) {
        return;
    }
    var type = control.parent.value['type'];
    var downloadActionType = control.parent.controls['download_action_type'].value;
    var downloadQueryAction = type == ActionType.Download && downloadActionType == DownloadActionType.Query;
    if (type != ActionType.Query && !downloadQueryAction) {
        return;
    }
    if (![ActionType.Query, ActionType.Link, ActionType.ExternalLink, ActionType.Export].includes(type) &&
        !downloadQueryAction) {
        return;
    }
    var fields = parent.inputFieldProvider.getFields();
    var inputs = control.value;
    if (!isRequiredInputsSet(fields, inputs)) {
        return { required: true };
    }
};
export var validateDownloadActionType = function (control) {
    if (!control.parent) {
        return;
    }
    var type = control.parent.controls['type'].value;
    if (type != ActionType.Download) {
        return;
    }
    if (!isSet(control.value)) {
        return { required: true };
    }
};
export var validateDownloadActionInput = function (control) {
    if (!control.parent) {
        return;
    }
    var type = control.parent.controls['type'].value;
    var downloadActionType = control.parent.controls['download_action_type'].value;
    if (type != ActionType.Download || downloadActionType != DownloadActionType.Input) {
        return;
    }
    if (!isInputSet(control.value)) {
        return { required: true };
    }
};
export var validateElementAction = function (control) {
    if (!control.parent) {
        return;
    }
    var type = control.parent.controls['type'].value;
    if (type != ActionType.ElementAction) {
        return;
    }
    if (!isSet(control.value)) {
        return { required: true };
    }
};
export var validateNotificationActionTitle = function (control) {
    if (!control.parent) {
        return;
    }
    var type = control.parent.controls['type'].value;
    if (type != ActionType.ShowNotification) {
        return;
    }
    if (!isInputSet(control.value)) {
        return { required: true };
    }
};
export var validateSetPropertyActionRequired = function (control) {
    if (!control.parent) {
        return;
    }
    var type = control.parent.controls['type'].value;
    if (type != ActionType.SetProperty) {
        return;
    }
    if (!isSet(control.value)) {
        return { required: true };
    }
};
export var validateRunJavascriptActionRequired = function (control) {
    if (!control.parent) {
        return;
    }
    var type = control.parent.controls['type'].value;
    if (type != ActionType.RunJavaScript) {
        return;
    }
    if (!isSet(control.value)) {
        return { required: true };
    }
};
export var validateSetPropertyActionInput = function (control) {
    if (!control.parent) {
        return;
    }
    var type = control.parent.controls['type'].value;
    if (type != ActionType.SetProperty) {
        return;
    }
    if (!isInputSet(control.value)) {
        return { required: true };
    }
};
export var validateCopyToClipboardActionTitle = function (control) {
    if (!control.parent) {
        return;
    }
    var type = control.parent.controls['type'].value;
    if (type != ActionType.CopyToClipboard) {
        return;
    }
    if (!isInputSet(control.value)) {
        return { required: true };
    }
};
export var validateActions = function (control) {
    var parent = control.parent;
    if (!parent) {
        return of(null);
    }
    if (!control.value || !control.value.length) {
        return of(null);
    }
    return combineLatest(control.value.map(function (item) { return parent.elementConfigurationService.isActionConfigured(item); })).pipe(map(function (result) {
        if (result.some(function (configured) { return !configured; })) {
            return { required: true };
        }
    }));
};
export var CustomizeBarActionEditFormDefaultType = new InjectionToken('CustomizeBarActionEditFormDefaultType');
export var CustomizeBarActionEditFormValidatorOrOpts = new InjectionToken('CustomizeBarActionEditFormValidatorOrOpts');
export var CustomizeBarActionEditFormAsyncValidator = new InjectionToken('CustomizeBarActionEditFormAsyncValidator');
export var CustomizeBarActionEditFormGetQueries = new InjectionToken('CustomizeBarActionEditFormGetQueries');
export var OPENED_MODAL_VALUE = '__OPENED_MODAL__';
var defaultVerboseName = {
    path: ['value'],
    value_type: InputValueType.StaticValue,
    static_value: 'Button'
};
var CustomizeBarActionEditForm = /** @class */ (function (_super) {
    __extends(CustomizeBarActionEditForm, _super);
    function CustomizeBarActionEditForm(formUtils, resourceControllerService, actionService, queryService, elementConfigurationService, actionDescriptionService, currentProjectStore, currentEnvironmentStore, actionStore, modelDescriptionStore, viewSettingsService, viewSettingsStore, exportDataSourceControl, customizeService, injector, defaultType, validatorOrOpts, asyncValidator, getQueries) {
        var _this = _super.call(this, (function () {
            var controls = {
                title: new FormControl(undefined),
                icon: new FormControl(null),
                type: new FormControl(defaultType, Validators.required),
                resource: new FormControl('', validateResource),
                model: new FormControl(''),
                action: new FormControl(''),
                action_params: new ParameterArray([]),
                outputs: new FormControl([]),
                array_output: new FormControl(false),
                verbose_name: new FieldInputControl(defaultVerboseName),
                query: new FormControl(undefined, validateQuery),
                link: new FormControl('', validateLink),
                inputs: new FormControl([], validateInputs),
                approve_enabled: new FormControl(false),
                approve: new FormControl(null),
                style: new FormControl(TintStyle.Primary),
                tint: new FormControl(null),
                disabled_input: new FieldInputControl({ path: ['value'] }),
                visible_input: new FieldInputControl({ path: ['value'] }),
                tooltip: new FormControl(''),
                card_wrap: new FormControl(true),
                new_tab: new FormControl(false),
                new_tab_custom: new FieldInputControl({ path: ['value'] }),
                on_success_notification: new FormControl(false),
                on_success_actions: new FormControl([], undefined, validateActions),
                on_error_actions: new FormControl([], undefined, validateActions),
                on_error_notification: new FormControl(false),
                download_action_type: new FormControl(null, validateDownloadActionType),
                download_action_file_column: new FormControl(null),
                download_action_input: new FieldInputControl({ path: ['value'] }, validateDownloadActionInput),
                element_action: new FormControl(null, validateElementAction),
                notification_action_title: new FieldInputControl({ path: ['value'] }, validateNotificationActionTitle),
                notification_action_description: new FieldInputControl({ path: ['value'] }),
                notification_action_icon: new FormControl(''),
                notification_action_type: new FormControl(NotificationType.Info),
                notification_action_color_enabled: new FormControl(false),
                notification_action_color: new FormControl('#7640f5'),
                notification_action_close_timeout_enabled: new FormControl(true),
                notification_action_close_timeout: new FormControl(undefined),
                set_property_action_property: new FormControl(undefined, validateSetPropertyActionRequired),
                set_property_action_value: new FieldInputControl({ path: ['value'] }),
                run_javascript: new FormControl(undefined, validateRunJavascriptActionRequired),
                copy_to_clipboard_action_value: new FieldInputControl({ path: ['value'] }, validateCopyToClipboardActionTitle),
                export_data_type: new FormControl(ExportDataType.DataSource),
                export_data_source: exportDataSourceControl,
                export_sorting_field: new FormControl(undefined),
                export_sorting_asc: new FormControl(true),
                // export_ids: new FieldInputControl({ path: ['value'] }),
                export_per_page: new FieldInputControl({ path: ['value'] }),
                open_popup: new FormControl(undefined),
                open_popup_close_other: new FormControl(true),
                open_popup_toggle_popup: new FormControl(false),
                close_popup: new FormControl(OPENED_MODAL_VALUE),
                open_action_menu_actions: new FormControl([]),
                workflow: new FormControl(undefined),
                confirmation_enabled: new FormControl(false),
                confirmation: new ConfirmationControl()
            };
            controls['element_styles'] = new ActionElementStylesControl(injector, {
                textStyleGlobalParams: controlValue(controls.style).pipe(map(function (style) {
                    return { tint: style };
                }))
            });
            return controls;
        })(), validatorOrOpts, asyncValidator) || this;
        _this.formUtils = formUtils;
        _this.resourceControllerService = resourceControllerService;
        _this.actionService = actionService;
        _this.queryService = queryService;
        _this.elementConfigurationService = elementConfigurationService;
        _this.actionDescriptionService = actionDescriptionService;
        _this.currentProjectStore = currentProjectStore;
        _this.currentEnvironmentStore = currentEnvironmentStore;
        _this.actionStore = actionStore;
        _this.modelDescriptionStore = modelDescriptionStore;
        _this.viewSettingsService = viewSettingsService;
        _this.viewSettingsStore = viewSettingsStore;
        _this.exportDataSourceControl = exportDataSourceControl;
        _this.customizeService = customizeService;
        _this.injector = injector;
        _this.getQueries = getQueries;
        _this.queryClass = ActionQuery;
        _this.options = {};
        _this.silentChanges = new Subject();
        _this.valueChangesWithSilent = merge(_this.valueChanges, _this.silentChanges);
        _this.inputFieldProvider = new InputFieldProvider();
        _this.pageParametersControl = new ParameterArray([]);
        _this.popupParametersControl = new ParameterArray([]);
        _this.allTypeOptions = [
            { value: ActionType.Query, name: 'Run Operation', icon: 'cloud_upload' },
            { value: ActionType.Workflow, name: 'Run Workflow', icon: 'workflow' },
            { value: ActionType.Link, name: 'Navigate to Page', icon: 'redo' },
            { value: ActionType.ExternalLink, name: 'Open URL', icon: 'model_link' },
            { value: ActionType.OpenPopup, name: 'Open Overlay', icon: 'copy' },
            { value: ActionType.ClosePopup, name: 'Close Overlay', icon: 'windows' },
            { value: ActionType.OpenActionMenu, name: 'Open Actions dropdown', icon: 'fileds', originRequired: true },
            { value: ActionType.ElementAction, name: 'Run Component action', icon: 'components' },
            { value: ActionType.ShowNotification, name: 'Show Notification', icon: 'notification' },
            { value: ActionType.SetProperty, name: 'Set Variable', icon: 'variable' },
            { value: ActionType.RunJavaScript, name: 'Run JavaScript', icon: 'console' },
            { value: ActionType.CopyToClipboard, name: 'Copy to Clipboard', icon: 'documents' },
            { value: ActionType.Export, name: 'Export Data', icon: 'download' },
            { value: ActionType.Import, name: 'Import Data', icon: 'upload' },
            { value: ActionType.Download, name: 'Download File', icon: 'save' },
            { value: ActionType.ScanCode, name: 'Scan QR/Bar code', icon: 'qr_code' }
        ].map(function (item) {
            return __assign({}, item, { attrs: { 'data-action-type': item.value } });
        });
        _this.notificationTypeOptions = [
            { value: NotificationType.Success, name: 'Success' },
            { value: NotificationType.Info, name: 'Info' },
            { value: NotificationType.Warning, name: 'Warning' },
            { value: NotificationType.Error, name: 'Error' }
        ];
        _this.notificationCloseTimeoutEnabledOptions = [
            { value: true, name: 'After time (seconds)' },
            { value: false, name: 'By click' }
        ];
        _this.styleOptions = [
            {
                value: TintStyle.Primary,
                image: 'button-primary'
            },
            {
                value: TintStyle.Default,
                image: 'button-default'
            },
            {
                value: TintStyle.Transparent,
                image: 'button-transparent'
            }
        ];
        _this.newTabOptions = [
            { value: true, name: 'Yes' },
            { value: false, name: 'No' },
            { value: 'custom', name: 'Custom' }
        ];
        controlValue(_this.controls.type)
            .pipe(distinctUntilChanged())
            .subscribe(function (type) {
            _this.updateValidators(type);
        });
        _this.controls.export_data_source.controls.columns.clearValidators();
        _this.controls.type.valueChanges.pipe(delay(0)).subscribe(function () {
            _this.controls.resource.updateValueAndValidity();
            _this.controls.query.updateValueAndValidity();
            _this.controls.link.updateValueAndValidity();
            _this.controls.inputs.updateValueAndValidity();
            _this.controls.element_action.updateValueAndValidity();
            _this.controls.notification_action_title.updateValueAndValidity();
            _this.controls.set_property_action_property.updateValueAndValidity();
            _this.controls.set_property_action_value.updateValueAndValidity();
            _this.controls.run_javascript.updateValueAndValidity();
            _this.controls.copy_to_clipboard_action_value.updateValueAndValidity();
        });
        _this.inputFieldProvider.getFields$().subscribe(function () {
            _this.controls.inputs.updateValueAndValidity();
        });
        return _this;
    }
    CustomizeBarActionEditForm.prototype.ngOnDestroy = function () {
        this.inputFieldProvider.clearProvider();
    };
    CustomizeBarActionEditForm.prototype.updateValidators = function (type) {
        this.controls.export_data_source.setRequired(type == ActionType.Export);
        if (type == ActionType.Import) {
            this.controls.model.setValidators(Validators.required);
        }
        else {
            this.controls.model.clearValidators();
        }
    };
    CustomizeBarActionEditForm.prototype.initObservers = function () {
        var _this = this;
        this.controls.resource.valueChanges.subscribe(function (value) { return _this.onResourceChange(value); });
        combineLatest(controlValue(this.controls.type), controlValue(this.controls.resource), this.controls.action.valueChanges)
            .pipe(debounceTime(0))
            .subscribe(function (_a) {
            var type = _a[0], resourceName = _a[1], action = _a[2];
            if ([ActionType.Query, ActionType.Download].includes(type)) {
                _this.onActionChange(resourceName, action);
            }
            else {
                _this.onActionChange(undefined, undefined);
            }
        });
        this.controls.action_params.valueChanges.subscribe(function (value) { return _this.onParametersChange(); });
        this.controls.query.valueChanges.subscribe(function (value) { return _this.onQueryChange(); });
        this.controls.type.valueChanges.subscribe(function (value) { return _this.onTypeChange(); });
        this.controls.link.valueChanges.subscribe(function (value) { return _this.onLinkChange(); });
        this.controls.element_action.valueChanges.subscribe(function (value) { return _this.onElementActionChange(); });
        this.controls.workflow.valueChanges.subscribe(function (value) { return _this.onWorkflowChange(); });
        this.pageParametersControl.valueChanges.subscribe(function (value) { return _this.onPageParametersChange(); });
        this.popupParametersControl.valueChanges.subscribe(function (value) { return _this.onOpenPopupParametersChange(); });
        this.pageParametersControl.valueChanges
            .pipe(debounceTime(200))
            .subscribe(function (value) { return _this.onPageParametersChangeDebounce(value); });
        this.popupParametersControl.valueChanges
            .pipe(debounceTime(200))
            .subscribe(function (value) { return _this.onPopupParametersChangeDebounce(value); });
        this.controls.type.valueChanges.subscribe(function (type) {
            var onSuccessActions = _this.controls.on_success_actions.value;
            var onErrorActions = _this.controls.on_error_actions.value;
            if ([ActionType.Query, ActionType.Download, ActionType.Workflow].includes(type) &&
                !onSuccessActions.length &&
                !_this.controls.on_success_notification.value) {
                _this.controls.on_success_notification.patchValue(true);
            }
            else if (!onSuccessActions.length && _this.controls.on_success_notification.value) {
                _this.controls.on_success_notification.patchValue(false);
            }
            if ([ActionType.Query, ActionType.Download, ActionType.Workflow].includes(type) &&
                !onErrorActions.length &&
                !_this.controls.on_error_notification.value) {
                _this.controls.on_error_notification.patchValue(true);
            }
            else if (!onErrorActions.length && _this.controls.on_error_notification.value) {
                _this.controls.on_error_notification.patchValue(false);
            }
        });
    };
    CustomizeBarActionEditForm.prototype.init = function (options, firstInit) {
        var _this = this;
        if (firstInit === void 0) { firstInit = false; }
        options = defaults(options, { actionItemClass: ActionItem });
        this.options = options;
        this.actionPatchValue(options.actionItem, { emitEvent: false }, options);
        if (options.titleEditable) {
            this.patchValue({
                title: options.title
            }, { emitEvent: false });
        }
        if (options.visibleEditable) {
            this.patchValue({
                visible_input: options.visibleInput ? options.visibleInput.serializeWithoutPath() : {}
            }, { emitEvent: false });
        }
        if (options.tooltipEditable) {
            this.patchValue({
                tooltip: options.tooltip
            }, { emitEvent: false });
        }
        if (options.cardWrapEditable) {
            this.controls.card_wrap.patchValue(options.cardWrap);
        }
        if (options.elementStylesEditable && options.elementStyles) {
            this.controls.element_styles.deserialize(options.elementStyles);
        }
        this.initObservers();
        this.updateInputFieldProvider().subscribe();
        if (!firstInit) {
            this.markAsDirty();
        }
        if (options.titleEditable) {
            this.controls.verbose_name.valueChanges.subscribe(function (value) {
                var input = value ? new FieldInput().deserialize(value) : undefined;
                if (!input || input.valueType != InputValueType.StaticValue) {
                    return;
                }
                var title = input.staticValue;
                if (options.titleCleanValue) {
                    title = options.titleCleanValue(title);
                }
                _this.controls.title.patchValue(title);
            });
        }
        this.linkPage$().subscribe(function (page) {
            if (page) {
                _this.pageParametersControl.patchValue(page.parameters, { emitEvent: false });
            }
        });
        this.openPopup$().subscribe(function (popup) {
            if (popup) {
                _this.popupParametersControl.patchValue(popup.parameters, { emitEvent: false });
            }
        });
    };
    CustomizeBarActionEditForm.prototype.actionPatchValue = function (actionItem, patchOptions, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        var value = {};
        if (actionItem) {
            this.actionService.getActionDescription(actionItem).subscribe(function (actionDescription) {
                var sharedActionDescription = actionItem.sharedActionDescription
                    ? splitmax(actionItem.sharedActionDescription, '.', 2)[1]
                    : undefined;
                var action;
                var query;
                if (actionDescription &&
                    actionDescription.type == ActionType.Query &&
                    actionDescription.queryAction &&
                    actionDescription.queryAction.query) {
                    action = {
                        queryType: sharedActionDescription ? QueryType.Simple : actionDescription.queryAction.query.queryType,
                        action: sharedActionDescription
                    };
                    query = actionDescription.queryAction.query;
                }
                else if (actionDescription &&
                    actionDescription.type == ActionType.Download &&
                    actionDescription.downloadAction &&
                    actionDescription.downloadAction.query) {
                    action = {
                        queryType: sharedActionDescription ? QueryType.Simple : actionDescription.downloadAction.query.queryType,
                        action: sharedActionDescription
                    };
                    query = actionDescription.downloadAction.query;
                }
                if (actionDescription) {
                    var exportSort = actionDescription.exportAction &&
                        actionDescription.exportAction.sort &&
                        actionDescription.exportAction.sort.length
                        ? actionDescription.exportAction.sort[0]
                        : undefined;
                    value = __assign({ type: actionDescription.type, resource: actionDescription.resource, action: action, outputs: actionDescription.outputs, array_output: actionDescription.arrayOutput, query: query, link: actionDescription.linkAction
                            ? {
                                type: actionDescription.linkAction.type,
                                page: actionDescription.linkAction.page,
                                model: actionDescription.linkAction.model
                            }
                            : undefined, inputs: actionItem.inputs, element_action: actionDescription.elementAction, download_action_type: actionDescription.downloadAction
                            ? actionDescription.downloadAction.type
                            : DownloadActionType.Query, download_action_file_column: actionDescription.downloadAction
                            ? actionDescription.downloadAction.fileColumn
                            : undefined, download_action_input: actionDescription.downloadAction && actionDescription.downloadAction.input
                            ? actionDescription.downloadAction.input.serializeWithoutPath()
                            : {}, notification_action_title: actionDescription.notificationAction && actionDescription.notificationAction.title
                            ? actionDescription.notificationAction.title.serializeWithoutPath()
                            : {}, notification_action_description: actionDescription.notificationAction && actionDescription.notificationAction.description
                            ? actionDescription.notificationAction.description.serializeWithoutPath()
                            : {}, notification_action_icon: actionDescription.notificationAction && actionDescription.notificationAction.icon
                            ? actionDescription.notificationAction.icon
                            : '', notification_action_type: actionDescription.notificationAction
                            ? actionDescription.notificationAction.type
                            : NotificationType.Info, notification_action_color_enabled: actionDescription.notificationAction && isSet(actionDescription.notificationAction.color), notification_action_color: actionDescription.notificationAction && isSet(actionDescription.notificationAction.color)
                            ? actionDescription.notificationAction.color
                            : '#7640f5', notification_action_close_timeout_enabled: actionDescription.notificationAction
                            ? actionDescription.notificationAction.closeTimeoutEnabled
                            : true, notification_action_close_timeout: actionDescription.notificationAction
                            ? actionDescription.notificationAction.closeTimeout
                            : undefined, export_data_type: actionDescription.exportAction
                            ? actionDescription.exportAction.dataType
                            : ExportDataType.DataSource }, (exportSort &&
                        isSet(exportSort.field) && {
                        export_sorting_field: exportSort.field,
                        export_sorting_asc: !exportSort.desc
                    }), { set_property_action_property: actionDescription.setPropertyAction
                            ? actionDescription.setPropertyAction.property
                            : undefined, set_property_action_value: actionDescription.setPropertyAction && actionDescription.setPropertyAction.value
                            ? actionDescription.setPropertyAction.value.serializeWithoutPath()
                            : {}, run_javascript: actionDescription.runJavaScriptAction
                            ? actionDescription.runJavaScriptAction.js
                            : undefined, copy_to_clipboard_action_value: actionDescription.copyToClipboardAction && actionDescription.copyToClipboardAction.value
                            ? actionDescription.copyToClipboardAction.value.serializeWithoutPath()
                            : {}, open_popup: actionDescription.openPopupAction ? actionDescription.openPopupAction.popup : undefined, open_popup_close_other: actionDescription.openPopupAction
                            ? actionDescription.openPopupAction.closeOther
                            : true, open_popup_toggle_popup: actionDescription.openPopupAction
                            ? actionDescription.openPopupAction.togglePopup
                            : false, close_popup: actionDescription.closePopupAction && actionDescription.closePopupAction.popup
                            ? actionDescription.closePopupAction.popup
                            : OPENED_MODAL_VALUE, open_action_menu_actions: actionDescription.openActionMenuAction
                            ? actionDescription.openActionMenuAction.actions
                            : [], workflow: actionDescription.workflowAction ? actionDescription.workflowAction.workflow : undefined });
                    if ([ActionType.Link, ActionType.ExternalLink].includes(actionDescription.type)) {
                        var newTabInput = actionItem.inputs.find(function (item) { return item.isName('new_tab'); });
                        value['inputs'] = value['inputs'].filter(function (item) { return !item.isName('new_tab'); });
                        if (newTabInput) {
                            if (newTabInput.valueType === InputValueType.StaticValue && newTabInput.staticValue === true) {
                                value['new_tab'] = true;
                                value['new_tab_custom'] = {};
                            }
                            else if (newTabInput.valueType === InputValueType.StaticValue && newTabInput.staticValue === false) {
                                value['new_tab'] = false;
                                value['new_tab_custom'] = {};
                            }
                            else {
                                value['new_tab'] = 'custom';
                                value['new_tab_custom'] = newTabInput.serializeWithoutPath();
                            }
                        }
                    }
                    else if (actionDescription.type == ActionType.Export) {
                        // const idsInput = actionItem.inputs.find(item => item.name === 'ids');
                        var perPageInput = actionItem.inputs.find(function (item) { return item.isName(PER_PAGE_PARAM); });
                        value['inputs'] = value['inputs'].filter(function (item) { return [PER_PAGE_PARAM].some(function (param) { return item.isName(param); }); });
                        // if (idsInput) {
                        //   value['export_ids'] = idsInput.serialize();
                        // }
                        if (perPageInput) {
                            value['export_per_page'] = perPageInput.serializeWithoutPath();
                        }
                    }
                    else if (actionDescription.type == ActionType.Import) {
                        var modelId = actionDescription.importAction &&
                            isSet(actionDescription.importAction.resource) &&
                            isSet(actionDescription.importAction.model)
                            ? [actionDescription.importAction.resource, actionDescription.importAction.model].join('.')
                            : undefined;
                        value['model'] = modelId ? { model: modelId } : undefined;
                    }
                }
                else {
                    value = {
                        type: undefined,
                        resource: undefined,
                        model: undefined,
                        action: action,
                        action_params: [],
                        outputs: [],
                        query: undefined,
                        link: '',
                        href: '',
                        inputs: actionItem.inputs,
                        new_tab_custom: {},
                        // export_ids: {},
                        export_per_page: {},
                        download_action_type: DownloadActionType.Query,
                        download_action_file_column: undefined,
                        download_action_input: {},
                        element_action: undefined
                    };
                }
                if (options.nameEditable) {
                    value['verbose_name'] = actionItem.verboseNameInput
                        ? actionItem.verboseNameInput.serializeWithoutPath()
                        : defaultVerboseName;
                }
                if (options.approveEnabled) {
                    value['approve_enabled'] = !!actionItem.approve;
                    value['approve'] = actionItem.approve;
                }
                if (options.confirmationEnabled) {
                    value['confirmation_enabled'] = !!actionItem.confirmation;
                }
                if (options.completionEditable) {
                    value['on_success_notification'] = actionItem.onSuccessNotification;
                    value['on_success_actions'] = actionItem.onSuccessActions;
                    value['on_error_notification'] = actionItem.onErrorNotification;
                    value['on_error_actions'] = actionItem.onErrorActions;
                }
                if (actionItem instanceof ViewSettingsAction) {
                    if (options.iconEditable) {
                        value['icon'] = actionItem.icon;
                    }
                    if (options.colorsEditable) {
                        value['style'] = actionItem.style;
                        value['tint'] = actionItem.tint;
                    }
                    if (options.disabledEditable) {
                        _this.patchValue({
                            disabled_input: actionItem.disabledInput ? actionItem.disabledInput.serializeWithoutPath() : {}
                        }, { emitEvent: false });
                    }
                }
                _this.updateValidators(actionDescription ? actionDescription.type : undefined);
                var exportActionDataSource = actionDescription && actionDescription.exportAction ? actionDescription.exportAction.dataSource : undefined;
                _this.controls.export_data_source.deserialize(exportActionDataSource);
                if (options.confirmationEnabled) {
                    _this.controls.confirmation.deserialize(actionItem.confirmation);
                }
            });
        }
        this.patchValue(value, patchOptions);
        if (actionItem) {
            if (actionItem.sharedActionDescription) {
                this.setActionParams(this.controls.resource.value, this.controls.action.value, true, patchOptions);
            }
            else if (actionItem.actionDescription) {
                this.controls.action_params.patchValue(actionItem.actionDescription.actionParams, patchOptions);
            }
        }
        if (!patchOptions.emitEvent) {
            this.silentChanges.next(this.value);
        }
    };
    CustomizeBarActionEditForm.prototype.setAction = function (action) {
        var query = new ActionQuery();
        query.queryType = action ? action.queryType : undefined;
        if (query.queryType == QueryType.Simple) {
            if (!query.simpleQuery) {
                query.simpleQuery = new query.simpleQueryClass();
            }
            query.simpleQuery.name = action.action;
        }
        this.controls.query.patchValue(query);
        this.controls.inputs.patchValue([]);
    };
    CustomizeBarActionEditForm.prototype.setActionParams = function (resourceName, action, init, patchOptions) {
        var _this = this;
        if (init === void 0) { init = false; }
        var id = action ? [resourceName, action.action].join('.') : undefined;
        this.actionStore.getDetailFirst(id).subscribe(function (actionDescription) {
            var value = {
                action_params: actionDescription ? actionDescription.actionParams : [],
                inputs: []
            };
            if (actionDescription) {
                if (actionDescription.verboseName) {
                    value['verbose_name'] = new Input()
                        .deserializeFromStatic('value', actionDescription.verboseName)
                        .serializeWithoutPath();
                }
                if (actionDescription.icon) {
                    value['icon'] = actionDescription.icon;
                }
                if (_this.options.modelDescriptionInContext &&
                    actionDescription.modelAction &&
                    actionDescription.resource == _this.options.modelDescriptionInContext.modelDescription.resource &&
                    actionDescription.model == _this.options.modelDescriptionInContext.modelDescription.model) {
                    value.inputs = actionDescription.actionParams
                        .filter(function (item) {
                        return _this.options.modelDescriptionInContext.modelDescription.fields.some(function (i) { return i.name == item.name; });
                    })
                        .filter(function (item) { return _this.options.modelDescriptionInContext.fieldToken(item.name); })
                        .map(function (item) {
                        var input = new Input();
                        input.path = [item.name];
                        input.valueType = InputValueType.Context;
                        input.contextValue = _this.options.modelDescriptionInContext.fieldToken(item.name);
                        return input;
                    });
                }
            }
            if (init) {
                _this.controls.action_params.patchValue(value['action_params'], patchOptions);
            }
            else {
                _this.patchValue(value, patchOptions);
            }
        });
    };
    CustomizeBarActionEditForm.prototype.getResource$ = function () {
        var _this = this;
        return controlValue(this.controls.resource).pipe(map(function (value) { return _this.currentEnvironmentStore.resources.find(function (item) { return item.uniqueName == value; }); }));
    };
    CustomizeBarActionEditForm.prototype.getActionDescription$ = function () {
        var _this = this;
        return combineLatest(controlValue(this.controls.resource), controlValue(this.controls.action)).pipe(switchMap(function (_a) {
            var resource = _a[0], action = _a[1];
            if (!action || !action.action) {
                return of(undefined);
            }
            return _this.actionStore.get().pipe(map(function (actions) {
                if (!actions) {
                    return;
                }
                return actions.find(function (item) { return item.resource == resource && item.name == action.action; });
            }));
        }));
    };
    CustomizeBarActionEditForm.prototype.getModelDescription$ = function () {
        var _this = this;
        return this.getActionDescription$().pipe(switchMap(function (actionDescription) {
            if (!actionDescription || !actionDescription.resource || !actionDescription.model) {
                return of(undefined);
            }
            var modelId = [actionDescription.resource, actionDescription.model].join('.');
            return _this.modelDescriptionStore.getDetailFirst(modelId);
        }));
    };
    CustomizeBarActionEditForm.prototype.updateInputFieldProvider = function () {
        var _this = this;
        return combineLatest(controlValue(this.controls.type), controlValue(this.controls.action_params), controlValue(this.controls.link), controlValue(this.pageParametersControl), controlValue(this.popupParametersControl), controlValue(this.controls.download_action_type), controlValue(this.controls.element_action), controlValue(this.controls.action), this.getResource$(), this.getActionDescription$(), this.getModelDescription$()).pipe(first(), map(function (result) {
            var type = result[0];
            var actionParams = result[1];
            var link = result[2];
            var pageParameters = result[3];
            var popupParameters = result[4];
            var downloadType = result[5];
            var elementAction = result[6];
            var action = result[7];
            var resource = result[8];
            var actionDescription = result[9];
            var modelDescription = result[10];
            if ((type == ActionType.Query || (type == ActionType.Download && downloadType == DownloadActionType.Query)) &&
                actionDescription &&
                ['get', 'get_detail'].includes(actionDescription.modelAction)) {
                if (actionDescription.modelAction == 'get_detail' &&
                    modelDescription &&
                    modelDescription.getDetailQuery &&
                    !(modelDescriptionHasAutoParameters(resource, modelDescription) && !modelDescription.virtual)) {
                    if (modelDescription) {
                        var controller = resource ? _this.resourceControllerService.get(resource.type) : undefined;
                        var modelParameters = controller
                            ? controller.getDetailParametersOrDefaults(resource, modelDescription)
                            : [];
                        return inputFieldProviderItemsFromModelGetDetail(resource, modelParameters, undefined);
                    }
                    else {
                        return parametersToProviderItems(actionParams);
                    }
                }
                else {
                    if (modelDescription) {
                        var getQuery = new ListModelDescriptionQuery();
                        getQuery.queryType = action ? action.queryType : undefined;
                        if (getQuery.queryType == QueryType.Simple) {
                            if (!getQuery.simpleQuery) {
                                getQuery.simpleQuery = new getQuery.simpleQueryClass();
                            }
                            getQuery.simpleQuery.model = actionDescription.model;
                        }
                        return inputFieldProviderItemsFromModelGet(resource, modelDescription, getQuery, [], DataSourceType.Query);
                    }
                    else {
                        return parametersToProviderItems(actionParams);
                    }
                }
            }
            else if (type == ActionType.Link) {
                return _this.actionDescriptionService.getLinkActionParameters(link, pageParameters);
            }
            else if (type == ActionType.OpenPopup) {
                return _this.actionDescriptionService.getOpenPopupActionParameters(popupParameters);
            }
            else if (type == ActionType.ExternalLink) {
                return _this.actionDescriptionService
                    .getExternalLinkActionParameters()
                    .filter(function (item) { return item.name !== 'new_tab'; })
                    .map(function (item) {
                    return {
                        label: item.verboseName || item.name,
                        field: __assign({}, item),
                        defaultValueType: InputValueType.StaticValue
                    };
                });
                // } else if (type == ActionType.Export) {
                //   const resource = modelDescription
                //     ? this.currentEnvironmentStore.resources.find(item => item.uniqueName == modelDescription.resource)
                //     : undefined;
                //
                //   return this.actionDescriptionService.getExportActionParameters(resource, modelDescription, actionParams);
            }
            else if (type == ActionType.ElementAction) {
                if (!_this.options.context || !isSet(elementAction)) {
                    return [];
                }
                return _this.options.context.getElementAction$(elementAction).pipe(map(function (item) {
                    if (!item) {
                        return [];
                    }
                    return parametersToProviderItemsFlat(item.parameters);
                }));
            }
            else {
                return parametersToProviderItemsFlat((actionParams || []).filter(function (item) { return item.name; }));
            }
        }), tap(function (items) {
            _this.inputFieldProvider.setProvider(items);
        }));
    };
    CustomizeBarActionEditForm.prototype.controlsValid$ = function (controls) {
        var _this = this;
        return combineLatest(controls.map(function (item) { return controlValid(_this.controls[item]); })).pipe(map(function (result) { return result.every(function (item) { return item; }); }), debounceTime(60));
    };
    CustomizeBarActionEditForm.prototype.operationTypeValid$ = function () {
        var _this = this;
        return combineLatest(controlValue(this.controls.type), controlValue(this.controls.export_data_type)).pipe(switchMap(function (_a) {
            var type = _a[0], exportDataType = _a[1];
            var controls = ['type'];
            if ([ActionType.Query, ActionType.Download].includes(type)) {
                controls.push('resource');
                controls.push('query');
            }
            else if (type == ActionType.Link) {
                controls.push('link');
            }
            else if (type == ActionType.ExternalLink) {
            }
            else if (type == ActionType.ElementAction) {
                controls.push('element_action');
            }
            else if (type == ActionType.ShowNotification) {
                controls.push('notification_action_title');
            }
            else if (type == ActionType.SetProperty) {
                controls.push('set_property_action_property');
                controls.push('set_property_action_value');
            }
            else if (type == ActionType.RunJavaScript) {
                controls.push('run_javascript');
            }
            else if (type == ActionType.CopyToClipboard) {
                controls.push('copy_to_clipboard_action_value');
            }
            else if (type == ActionType.Export) {
                if (exportDataType == ExportDataType.DataSource) {
                    controls.push('export_data_source');
                }
            }
            else if (type == ActionType.Import) {
                controls.push('model');
            }
            else if (type == ActionType.OpenPopup) {
                controls.push('open_popup');
                // } else if (type == ActionType.ClosePopup) {
            }
            else if (type == ActionType.OpenActionMenu) {
                controls.push('open_action_menu_actions');
            }
            else if (type == ActionType.Workflow) {
                controls.push('workflow');
            }
            return _this.controlsValid$(controls);
        }));
    };
    CustomizeBarActionEditForm.prototype.operationValid$ = function () {
        return combineLatest(this.operationTypeValid$(), this.controlsValid$(['inputs'])).pipe(map(function (items) { return items.every(function (item) { return item; }); }));
    };
    CustomizeBarActionEditForm.prototype.actionsValid$ = function () {
        return this.controlsValid$(['on_success_actions', 'on_error_actions']);
    };
    CustomizeBarActionEditForm.prototype.resource$ = function (control, onlyChanges) {
        var _this = this;
        if (onlyChanges === void 0) { onlyChanges = false; }
        var result = controlValue(control).pipe(switchMap(function (value) {
            return _this.currentEnvironmentStore.resources$.pipe(map(function (resources) { return resources.find(function (item) { return item.uniqueName == value; }); }));
        }));
        if (!onlyChanges) {
            return result;
        }
        return result.pipe(distinctUntilChanged(function (lhs, rhs) {
            var lhsUniqueName = lhs ? lhs.uniqueName : undefined;
            var rhsUniqueName = rhs ? rhs.uniqueName : undefined;
            return lhsUniqueName == rhsUniqueName;
        }));
    };
    CustomizeBarActionEditForm.prototype.modelDescription$ = function (modelControl) {
        var _this = this;
        return controlValue(modelControl).pipe(switchMap(function (modelValue) {
            if (!modelValue || !modelValue.model) {
                return of(undefined);
            }
            return _this.modelDescriptionStore.getDetailFirst(modelValue.model);
        }));
    };
    CustomizeBarActionEditForm.prototype.actionDescription$ = function (resourceControl, actionControl) {
        var _this = this;
        return combineLatest(controlValue(resourceControl), controlValue(actionControl)).pipe(switchMap(function (_a) {
            var resourceValue = _a[0], actionValue = _a[1];
            if (!resourceValue || !actionValue || !actionValue.action) {
                return of(undefined);
            }
            var modelId = [resourceValue, actionValue.action].join('.');
            return _this.actionStore.getDetail(modelId);
        }));
    };
    CustomizeBarActionEditForm.prototype.resourceBaseHttpQuery$ = function (control) {
        return this.resource$(control).pipe(map(function (resource) {
            if (!resource) {
                return undefined;
            }
            var resourceParams = resource.parseParams(RestAPIResourceParams);
            return resourceParams.baseHttpQuery;
        }));
    };
    CustomizeBarActionEditForm.prototype.getCustomActionOption = function (resource) {
        if (!isResourceCustom(resource) && resource.type != ResourceType.JetBridge) {
            return;
        }
        var controller = this.resourceControllerService.get(resource.type);
        var queryTypes = controller
            ? controller.supportedQueryTypes(resource.typeItem, ModelDescriptionQuery)
            : undefined;
        var name = "Make " + getResourceTypeItemRequestName(resource.typeItem);
        if (queryTypes.includes(QueryType.Http)) {
            return {
                value: { queryType: QueryType.Http },
                name: name,
                icon: 'plus'
            };
        }
        else if (queryTypes.includes(QueryType.SQL)) {
            return {
                value: { queryType: QueryType.SQL },
                name: name,
                icon: 'plus'
            };
        }
        else if (queryTypes.includes(QueryType.Object)) {
            return {
                value: { queryType: QueryType.Object },
                name: name,
                icon: 'plus'
            };
        }
    };
    CustomizeBarActionEditForm.prototype.resourceActionQueryItems$ = function (control) {
        var _this = this;
        return combineLatest(this.resource$(control), this.actionStore.get(), this.modelDescriptionStore.get()).pipe(map(function (_a) {
            var resource = _a[0], actionDescriptions = _a[1], modelDescriptions = _a[2];
            if (!resource) {
                return [];
            }
            var options = [];
            if (actionDescriptions) {
                var groupedOptions = values(actionDescriptions
                    .filter(function (item) { return item.resource == resource.uniqueName; })
                    .filter(function (item) { return item.type == ActionType.Query; })
                    .filter(function (item) { return _this.getQueries || !['get', 'get_detail'].includes(item.modelAction); })
                    .reduce(function (acc, actionDescription) {
                    var modelDescription = actionDescription.model
                        ? modelDescriptions.find(function (item) { return item.resource == resource.uniqueName && item.model == actionDescription.model; })
                        : undefined;
                    var storage = actionDescription.storage
                        ? resource.storages.find(function (item) { return item.isSame(actionDescription.storage); })
                        : undefined;
                    if (modelDescription && (!resource.demo || modelDescription.featured)) {
                        var key = "model_" + modelDescription.modelId;
                        if (!acc[key]) {
                            acc[key] = {
                                modelDescription: modelDescription,
                                labelSort: String(modelDescription.verboseNamePlural || modelDescription.model).toLowerCase(),
                                items: []
                            };
                        }
                        var label = actionDescription.verboseName;
                        var icon = void 0;
                        if (actionDescription.modelAction == 'get') {
                            label = 'Get Record List';
                            icon = 'documents';
                        }
                        else if (actionDescription.modelAction == 'get_detail') {
                            label = 'Get One Record';
                            icon = 'document';
                        }
                        else if (actionDescription.modelAction == 'create') {
                            label = 'Create Record';
                            icon = 'plus_circle';
                        }
                        else if (actionDescription.modelAction == 'update') {
                            label = 'Update Record';
                            icon = 'edit';
                        }
                        else if (actionDescription.modelAction == 'delete') {
                            label = 'Delete Record';
                            icon = 'bin';
                        }
                        acc[key].items.push({
                            option: {
                                value: { queryType: QueryType.Simple, action: actionDescription.name },
                                name: label,
                                icon: icon
                            },
                            subtitle: 'Actions',
                            valueLabel: actionDescription.verboseName || actionDescription.name,
                            valueIcon: null
                        });
                    }
                    else if (storage && !resource.demo) {
                        var key = "storage_" + storage.uniqueName;
                        if (!acc[key]) {
                            acc[key] = {
                                storage: storage,
                                labelSort: String(storage.name || storage.uniqueName).toLowerCase(),
                                items: []
                            };
                        }
                        var label = actionDescription.verboseName;
                        var icon = void 0;
                        if (actionDescription.storageAction == 'get_object_url') {
                            label = 'Get File URL';
                            icon = 'external_link';
                        }
                        else if (actionDescription.storageAction == 'upload') {
                            label = 'Upload File';
                            icon = 'cloud_upload';
                        }
                        else if (actionDescription.storageAction == 'get') {
                            label = 'Get Objects';
                            icon = 'documents';
                        }
                        else if (actionDescription.storageAction == 'create_directory') {
                            label = 'Create Directory';
                            icon = 'folder';
                        }
                        else if (actionDescription.storageAction == 'remove') {
                            label = 'Remove Object';
                            icon = 'bin';
                        }
                        acc[key].items.push({
                            option: {
                                value: { queryType: QueryType.Simple, action: actionDescription.name },
                                name: label,
                                icon: icon
                            },
                            subtitle: 'Actions',
                            valueLabel: actionDescription.verboseName || actionDescription.name,
                            valueIcon: null
                        });
                    }
                    else if (!modelDescription && (!resource.demo || actionDescription.featured)) {
                        if (!acc['']) {
                            acc[''] = {
                                items: []
                            };
                        }
                        acc[''].items.push({
                            option: {
                                value: { queryType: QueryType.Simple, action: actionDescription.name },
                                name: actionDescription.verboseName,
                                icon: 'play'
                            },
                            subtitle: 'Actions',
                            valueIcon: null
                        });
                    }
                    return acc;
                }, {}));
                var sectionSort_1 = function (item) {
                    if (item.modelDescription) {
                        return 0;
                    }
                    else if (item.storage) {
                        return 2;
                    }
                    else {
                        return 1;
                    }
                };
                options.push.apply(options, groupedOptions
                    .sort(function (lhs, rhs) {
                    var lhsSectionSort = sectionSort_1(lhs);
                    var rhsSectionSort = sectionSort_1(rhs);
                    var sectionCompare = ascComparator(lhsSectionSort, rhsSectionSort);
                    if (sectionCompare !== 0) {
                        return sectionCompare;
                    }
                    return ascComparator(lhs.labelSort || '', rhs.labelSort || '');
                })
                    .reduce(function (acc, item) {
                    if (item.modelDescription) {
                        acc.push({
                            button: {
                                name: item.modelDescription.model,
                                label: item.modelDescription.verboseNamePlural || item.modelDescription.model,
                                icon: 'components'
                            },
                            children: item.items,
                            subtitle: 'Collections'
                        });
                    }
                    else if (item.storage) {
                        acc.push({
                            button: {
                                name: item.storage.uniqueName,
                                label: item.storage.name || item.storage.uniqueName,
                                icon: 'open_folder'
                            },
                            children: item.items,
                            subtitle: 'Storages'
                        });
                    }
                    else {
                        acc.push.apply(acc, item.items.sort(function (lhs, rhs) {
                            return ascComparator(String(lhs.option.name).toLowerCase(), String(rhs.option.name).toLowerCase());
                        }));
                    }
                    return acc;
                }, []));
            }
            var customActionOption = _this.getCustomActionOption(resource);
            if (customActionOption) {
                options.push({
                    option: customActionOption,
                    stickyBottom: true,
                    orange: true,
                    large: true
                });
            }
            return options;
        }));
    };
    CustomizeBarActionEditForm.prototype.resourceActionDownloadItems$ = function (control) {
        var _this = this;
        return combineLatest(this.resource$(control), this.actionStore.get()).pipe(map(function (_a) {
            var resource = _a[0], actionDescriptions = _a[1];
            if (!resource) {
                return [];
            }
            var options = [];
            if (actionDescriptions) {
                options.push.apply(options, actionDescriptions
                    .filter(function (item) { return item.resource == resource.uniqueName; })
                    .filter(function (item) { return !resource.demo || item.featured; })
                    .filter(function (item) { return item.type == ActionType.Download; })
                    .sort(function (lhs, rhs) {
                    return ascComparator(String(lhs.verboseName).toLowerCase(), String(rhs.verboseName).toLowerCase());
                })
                    .map(function (item) {
                    return {
                        option: {
                            value: { queryType: QueryType.Simple, action: item.name },
                            name: item.verboseName,
                            icon: 'document'
                        }
                    };
                }));
            }
            var customActionOption = _this.getCustomActionOption(resource);
            if (customActionOption) {
                options.push({
                    option: customActionOption,
                    stickyBottom: true,
                    orange: true,
                    large: true
                });
            }
            return options;
        }));
    };
    CustomizeBarActionEditForm.prototype.getActionEditable$ = function () {
        return controlValue(this.controls.action).pipe(map(function (value) { return value && editableQueryTypes.includes(value.queryType); }));
    };
    CustomizeBarActionEditForm.prototype.getQueryEditable$ = function () {
        return controlValue(this.controls.query).pipe(map(function (value) { return value && editableQueryTypes.includes(value.queryType); }));
    };
    CustomizeBarActionEditForm.prototype.linkOptions$ = function () {
        var _this = this;
        return this.viewSettingsStore.get().pipe(map(function (viewSettings) {
            return [
                // {
                //   value: { type: SegueType.ModelCreate },
                //   name: 'Collection - Create'
                // },
                // {
                //   value: { type: SegueType.ModelChange },
                //   name: 'Collection - Change'
                // },
                // {
                //   value: { type: SegueType.ModelMassEdit },
                //   name: 'Collection - Mass Edit'
                // },
                // {
                //   value: { type: SegueType.ModelExport },
                //   name: 'Collection - Export'
                // },
                // {
                //   value: { type: SegueType.ModelActivityLog },
                //   name: 'Collection - Activity Log'
                // },
                // {
                //   value: { type: SegueType.ModelDelete },
                //   name: 'Collection - Delete'
                // },
                {
                    value: { type: SegueType.PreviousPage },
                    name: 'Previous Page'
                }
            ].concat(viewSettings
                .map(function (item) {
                if (item.uniqueName && item.view == ViewSettingsType.Custom) {
                    return {
                        value: { type: SegueType.Page, page: item.uniqueName },
                        name: item.name + " (" + item.uniqueName + ")"
                    };
                }
                else if (item.resource && item.model && item.view == ViewSettingsType.Change) {
                    var modelDescription = _this.modelDescriptionStore.instance.find(function (i) { return i.resource == item.resource && i.model == item.model; });
                    var modelDescriptionName = modelDescription ? modelDescription.verboseNamePlural : undefined;
                    var name_1 = modelDescriptionName || item.name || item.model;
                    return {
                        value: { type: SegueType.ModelChange, model: [item.resource, item.model].join('.') },
                        name: name_1 + " (Change page)"
                    };
                }
            })
                .filter(function (item) { return item != undefined; })
                .sort(function (lhs, rhs) {
                if (lhs.name.toLowerCase() < rhs.name.toLowerCase()) {
                    return -1;
                }
                else if (lhs.name.toLowerCase() > rhs.name.toLowerCase()) {
                    return 1;
                }
                else {
                    return 0;
                }
            })
            // ...modelDescriptions
            //   .map(item => {
            //     const resource = this.currentEnvironmentStore.resources.find(i => i.uniqueName == item.resource);
            //
            //     if (resource && resource.demo) {
            //       return;
            //     }
            //     const modelName = item.verboseNamePlural || item.model;
            //     const resourceName = resource ? resource.name || resource.uniqueName : undefined;
            //
            //     const label = resourceName ? `${modelName} (${resourceName})` : modelName;
            //
            //     return {
            //       label: label,
            //       type: MenuItemType.ModelLink,
            //       params: {
            //         title: modelName,
            //         model: item.modelId
            //       }
            //     };
            //   })
            //   .filter(item => item != undefined)
            );
        }));
    };
    CustomizeBarActionEditForm.prototype.linkPage$ = function () {
        var _this = this;
        return controlValue(this.controls.link).pipe(switchMap(function (value) {
            if (isSet(value) && value.type == SegueType.Page && isSet(value.page)) {
                return _this.viewSettingsStore.getDetailFirst(value.page);
            }
            else {
                return of(undefined);
            }
        }));
    };
    CustomizeBarActionEditForm.prototype.openPopup$ = function () {
        var _this = this;
        return controlValue(this.controls.open_popup).pipe(map(function (value) {
            if (isSet(value) &&
                _this.options.context &&
                _this.options.context.viewSettings &&
                _this.options.context.viewSettings instanceof CustomViewSettings) {
                return _this.options.context.viewSettings.popups.find(function (item) { return item.uid == value; });
            }
        }));
    };
    CustomizeBarActionEditForm.prototype.getOutputs$ = function () {
        var _this = this;
        return controlValue(this).pipe(switchMap(function () {
            var result = _this.submit();
            var actionDescription = result.action.actionDescription;
            return actionDescription
                ? _this.actionService.getActionDescriptionOutputs(actionDescription)
                : of({ outputs: [] });
        }));
    };
    CustomizeBarActionEditForm.prototype.actionValueEquals = function (lhs, rhs) {
        var lhsQueryType = lhs ? lhs.queryType : undefined;
        var lhsActionDescription = lhs && lhs.action ? lhs.action : undefined;
        var rhsQueryType = rhs ? rhs.queryType : undefined;
        var rhsActionDescription = rhs && rhs.action ? rhs.action : undefined;
        return lhsQueryType == rhsQueryType && lhsActionDescription == rhsActionDescription;
    };
    CustomizeBarActionEditForm.prototype.linkValueEquals = function (lhs, rhs) {
        if (lhs && rhs && lhs.type == SegueType.Page && rhs.type == SegueType.Page) {
            return lhs.page == rhs.page;
        }
        else if (lhs && rhs) {
            return lhs.type == rhs.type;
        }
        else {
            return lhs === rhs;
        }
    };
    CustomizeBarActionEditForm.prototype.setQuery = function (query) {
        this.controls.query.patchValue(query);
        this.markAsDirty();
    };
    CustomizeBarActionEditForm.prototype.setOutputs = function (columns, array, options) {
        if (options === void 0) { options = {}; }
        var outputs = columns.map(function (item) {
            var output = new FieldOutput();
            output.name = item.name;
            output.verboseName = item.verboseName;
            output.field = item.field;
            output.params = item.params;
            output.updateFieldDescription();
            return output;
        });
        this.controls.outputs.setValue(outputs);
        this.controls.array_output.setValue(array);
        if (options.markAsDirty) {
            this.controls.outputs.markAsDirty();
            this.controls.array_output.markAsDirty();
        }
    };
    CustomizeBarActionEditForm.prototype.setAutoDetectOutputs = function (query, options) {
        if (options === void 0) { options = {}; }
        var response = this.queryService.getResponseProcessed(query);
        var isArrayResponse = response && isArray(response);
        var responseColumns = this.queryService.getAutoDetectColumns(query, false);
        if (!responseColumns) {
            return;
        }
        this.setOutputs(responseColumns, isArrayResponse, { markAsDirty: options.markAsDirty });
    };
    CustomizeBarActionEditForm.prototype.resetExportColumns = function (options) {
        if (options === void 0) { options = {}; }
        var exportDataType = this.controls.export_data_type.value;
        if (exportDataType == ExportDataType.DataSource) {
            this.controls.export_data_source.resetInputColumns({
                context: options.context,
                contextElement: options.contextElement,
                markAsDirty: true
            });
        }
        else if (exportDataType == ExportDataType.CurrentComponent) {
            var newColumns = this.options.dataSourceControl.controls.columns.serialize(false);
            this.controls.export_data_source.controls.columns.deserialize(newColumns);
        }
    };
    CustomizeBarActionEditForm.prototype.toggleExportDefaultSorting = function () {
        var control = this.controls.export_sorting_asc;
        control.patchValue(!control.value);
    };
    CustomizeBarActionEditForm.prototype.onResourceChange = function (resourceName) {
        var _this = this;
        var resource = this.currentEnvironmentStore.resources.find(function (item) { return item.uniqueName == resourceName; });
        var firstAction = this.actionStore.instance.find(function (action) {
            if (action.resource != resourceName) {
                return false;
            }
            var modelDescription = action.model
                ? _this.modelDescriptionStore.instance.find(function (item) { return item.resource == resource.uniqueName && item.model == action.model; })
                : undefined;
            if (resource.demo && modelDescription) {
                return modelDescription.featured;
            }
            else if (resource.demo && !modelDescription) {
                return action.featured;
            }
            return true;
        });
        var actionOption;
        if (firstAction) {
            // actionOption = {
            //   queryType: QueryType.Simple,
            //   action: firstAction.name
            // };
        }
        else if (resource) {
            var customOption = this.getCustomActionOption(resource);
            if (customOption) {
                actionOption = customOption.value;
            }
        }
        this.controls.action.patchValue(actionOption);
        this.controls.inputs.patchValue([]);
    };
    CustomizeBarActionEditForm.prototype.onActionChange = function (resourceName, action) {
        this.setAction(action);
        this.setActionParams(resourceName, action);
        this.onActionParamsChange();
    };
    CustomizeBarActionEditForm.prototype.onParametersChange = function () {
        this.onActionParamsChange();
    };
    CustomizeBarActionEditForm.prototype.onQueryChange = function () {
        this.onActionParamsChange();
    };
    CustomizeBarActionEditForm.prototype.onTypeChange = function () {
        this.onActionParamsChange();
    };
    CustomizeBarActionEditForm.prototype.onLinkChange = function () {
        this.onActionParamsChange();
    };
    CustomizeBarActionEditForm.prototype.onElementActionChange = function () {
        this.onActionParamsChange();
    };
    CustomizeBarActionEditForm.prototype.onWorkflowChange = function () {
        this.onActionParamsChange();
    };
    CustomizeBarActionEditForm.prototype.onActionParamsChange = function () {
        var _this = this;
        setTimeout(function () {
            _this.updateInputFieldProvider().subscribe();
        }, 0);
    };
    CustomizeBarActionEditForm.prototype.onPageParametersChange = function () {
        var _this = this;
        setTimeout(function () {
            _this.updateInputFieldProvider().subscribe();
        }, 0);
    };
    CustomizeBarActionEditForm.prototype.onOpenPopupParametersChange = function () {
        var _this = this;
        setTimeout(function () {
            _this.updateInputFieldProvider().subscribe();
        }, 0);
    };
    CustomizeBarActionEditForm.prototype.onPageParametersChangeDebounce = function (value) {
        var link = this.controls.link.value;
        if (isSet(link) && link.type == SegueType.Page && isSet(link.page)) {
            this.submitPageParameters(link.page, value);
        }
    };
    CustomizeBarActionEditForm.prototype.onPopupParametersChangeDebounce = function (value) {
        var uid = this.controls.open_popup.value;
        if (isSet(uid)) {
            this.submitPopupParameters(uid, value);
        }
    };
    CustomizeBarActionEditForm.prototype.isConfigured = function (instance) {
        return this.elementConfigurationService.isActionConfigured(instance, { restrictDemo: true });
    };
    CustomizeBarActionEditForm.prototype.submitPageParameters = function (pageUniqueName, parameters) {
        var _this = this;
        this.viewSettingsStore
            .getDetailFirst(pageUniqueName)
            .pipe(switchMap(function (oldInstance) {
            var newInstance = cloneDeep(oldInstance);
            newInstance.parameters = parameters;
            return _this.viewSettingsService.update(_this.currentProjectStore.instance.uniqueName, _this.currentEnvironmentStore.instance.uniqueName, newInstance);
        }), tap(function (result) { return _this.viewSettingsStore.updateItem(result); }))
            .subscribe();
    };
    CustomizeBarActionEditForm.prototype.submitPopupParameters = function (uid, parameters) {
        var _this = this;
        if (!isSet(uid) ||
            !this.options.context ||
            !this.options.context.viewSettings ||
            !(this.options.context.viewSettings instanceof CustomViewSettings)) {
            return;
        }
        var page = this.options.context.viewSettings;
        this.viewSettingsStore.getDetailFirst(page.uniqueName).subscribe(function (viewSettings) {
            var popup = viewSettings.popups.find(function (item) { return item.uid == uid; });
            if (!popup) {
                return;
            }
            var newPopup = cloneDeep(popup);
            newPopup.parameters = parameters;
            if (_this.customizeService.handler.openPopup) {
                _this.customizeService.handler.updatePopup(popup.uid, newPopup);
            }
        });
    };
    CustomizeBarActionEditForm.prototype.submit = function () {
        var value = this.value;
        var instance = this.options.actionItem
            ? cloneDeep(this.options.actionItem)
            : new this.options.actionItemClass();
        if (this.options.nameEditable) {
            instance.verboseNameInput = value['verbose_name']
                ? new FieldInput().deserialize(value['verbose_name'])
                : undefined;
        }
        if (instance instanceof ViewSettingsAction) {
            if (this.options.iconEditable) {
                instance.icon = value['icon'];
            }
            if (this.options.styleEditable) {
                instance.style = value['style'];
            }
            if (this.options.colorsEditable) {
                instance.tint = value['tint'];
            }
            if (this.options.disabledEditable) {
                instance.disabledInput = value['disabled_input']
                    ? new FieldInput().deserialize(value['disabled_input'])
                    : undefined;
            }
        }
        if (this.options.completionEditable) {
            instance.onSuccessNotification = value['on_success_notification'];
            if (value['on_success_actions']) {
                instance.onSuccessActions = value['on_success_actions'];
            }
            instance.onErrorNotification = value['on_error_notification'];
            if (value['on_error_actions']) {
                instance.onErrorActions = value['on_error_actions'];
            }
        }
        instance.inputs = value['inputs'].slice();
        if ((value['type'] == ActionType.Query || value['type'] == ActionType.Download) &&
            value['action'] &&
            value['action'].action) {
            var action = value['action'];
            instance.actionDescription = undefined;
            instance.sharedActionDescription = [value['resource'], action.action].join('.');
        }
        else {
            var actionDescription = new ActionDescription();
            actionDescription.resource = value['resource'];
            // actionDescription.name = value['verbose_name'] ? slugify(value['verbose_name'], { separator: '_' }).replace(/_+/g, '_') : undefined;
            // actionDescription.verboseName = value['verbose_name'];
            actionDescription.actionParams = value['action_params'];
            actionDescription.outputs = value['outputs'];
            actionDescription.arrayOutput = value['array_output'];
            actionDescription.type = value['type'];
            actionDescription.icon = value['icon'];
            actionDescription.elementAction = value['element_action'];
            if (actionDescription.type == ActionType.Query) {
                if (!actionDescription.queryAction) {
                    actionDescription.queryAction = new QueryAction();
                }
                actionDescription.queryAction.query = value['query'];
            }
            else if (actionDescription.type == ActionType.Download) {
                if (!actionDescription.downloadAction) {
                    actionDescription.downloadAction = new DownloadAction();
                }
                actionDescription.downloadAction.type = value['download_action_type'];
                actionDescription.downloadAction.query = value['query'];
                actionDescription.downloadAction.fileColumn = value['download_action_file_column'];
                actionDescription.downloadAction.input = value['download_action_input']
                    ? new FieldInput().deserialize(value['download_action_input'])
                    : undefined;
            }
            else if (actionDescription.type == ActionType.Link) {
                if (!actionDescription.linkAction) {
                    actionDescription.linkAction = new LinkAction();
                }
                if (value['link']) {
                    actionDescription.linkAction.type = value['link'].type;
                    actionDescription.linkAction.page = value['link'].page;
                    actionDescription.linkAction.model = value['link'].model;
                }
                // actionDescription.linkAction.model = value['model'] ? value['model']['model'] : undefined;
                if (value['new_tab'] === true) {
                    var input = new Input();
                    input.path = ['new_tab'];
                    input.valueType = InputValueType.StaticValue;
                    input.staticValue = true;
                    instance.inputs.push(input);
                }
                else if (value['new_tab'] === false) {
                    var input = new Input();
                    input.path = ['new_tab'];
                    input.valueType = InputValueType.StaticValue;
                    input.staticValue = false;
                    instance.inputs.push(input);
                }
                else {
                    var input = new Input().deserialize(value['new_tab_custom']);
                    input.path = ['new_tab'];
                    instance.inputs.push(input);
                }
            }
            else if (actionDescription.type == ActionType.ShowNotification) {
                if (!actionDescription.notificationAction) {
                    actionDescription.notificationAction = new NotificationAction();
                }
                if (value['notification_action_title']) {
                    actionDescription.notificationAction.title = value['notification_action_title']
                        ? new FieldInput().deserialize(value['notification_action_title'])
                        : undefined;
                }
                if (value['notification_action_description']) {
                    actionDescription.notificationAction.description = value['notification_action_description']
                        ? new FieldInput().deserialize(value['notification_action_description'])
                        : undefined;
                }
                if (value['notification_action_icon']) {
                    actionDescription.notificationAction.icon = value['notification_action_icon'];
                }
                if (value['notification_action_type']) {
                    actionDescription.notificationAction.type = value['notification_action_type'];
                }
                if (value['notification_action_color_enabled'] && isSet(value['notification_action_color'])) {
                    actionDescription.notificationAction.color = value['notification_action_color'];
                }
                actionDescription.notificationAction.closeTimeoutEnabled = !!value['notification_action_close_timeout_enabled'];
                if (isSet(value['notification_action_close_timeout'])) {
                    var closeTimeout = parseInt(value['notification_action_close_timeout'], 10);
                    actionDescription.notificationAction.closeTimeout = !isNaN(closeTimeout) ? closeTimeout : undefined;
                }
                else {
                    actionDescription.notificationAction.closeTimeout = undefined;
                }
            }
            else if (actionDescription.type == ActionType.SetProperty) {
                if (!actionDescription.setPropertyAction) {
                    actionDescription.setPropertyAction = new SetPropertyAction();
                }
                actionDescription.setPropertyAction.property = value['set_property_action_property'];
                if (value['set_property_action_value']) {
                    actionDescription.setPropertyAction.value = value['set_property_action_value']
                        ? new FieldInput().deserialize(value['set_property_action_value'])
                        : undefined;
                }
            }
            else if (actionDescription.type == ActionType.RunJavaScript) {
                if (!actionDescription.runJavaScriptAction) {
                    actionDescription.runJavaScriptAction = new RunJavaScriptAction();
                }
                actionDescription.runJavaScriptAction.js = value['run_javascript'];
            }
            else if (actionDescription.type == ActionType.CopyToClipboard) {
                if (!actionDescription.copyToClipboardAction) {
                    actionDescription.copyToClipboardAction = new CopyToClipboardAction();
                }
                if (value['copy_to_clipboard_action_value']) {
                    actionDescription.copyToClipboardAction.value = value['copy_to_clipboard_action_value']
                        ? new FieldInput().deserialize(value['copy_to_clipboard_action_value'])
                        : undefined;
                }
            }
            else if (actionDescription.type == ActionType.Export) {
                if (!actionDescription.exportAction) {
                    actionDescription.exportAction = new ExportAction();
                }
                actionDescription.exportAction.dataType = this.controls.export_data_type.value;
                actionDescription.exportAction.dataSource = this.controls.export_data_source.serialize();
                actionDescription.exportAction.sort = isSet(this.controls.export_sorting_field.value)
                    ? [{ field: this.controls.export_sorting_field.value, desc: !this.controls.export_sorting_asc.value }]
                    : undefined;
                // const idsInput = new Input().deserialize(this.controls.export_ids.value);
                // idsInput.name = 'ids';
                var perPageInput = new Input().deserialize(this.controls.export_per_page.value);
                perPageInput.path = [PER_PAGE_PARAM];
                instance.inputs = instance.inputs.filter(function (item) { return ![PER_PAGE_PARAM].some(function (param) { return item.isName(param); }); }).concat([
                    perPageInput
                ]);
            }
            else if (actionDescription.type == ActionType.Import) {
                if (!actionDescription.importAction) {
                    actionDescription.importAction = new ImportAction();
                }
                var _a = value['model'] ? value['model']['model'].split('.') : [undefined, undefined], resource = _a[0], model = _a[1];
                actionDescription.resource = resource;
                actionDescription.importAction.resource = resource;
                actionDescription.importAction.model = model;
            }
            else if (actionDescription.type == ActionType.ExternalLink) {
                if (value['new_tab'] === true) {
                    var input = new Input();
                    input.path = ['new_tab'];
                    input.valueType = InputValueType.StaticValue;
                    input.staticValue = true;
                    instance.inputs.push(input);
                }
                else if (value['new_tab'] === false) {
                    var input = new Input();
                    input.path = ['new_tab'];
                    input.valueType = InputValueType.StaticValue;
                    input.staticValue = false;
                    instance.inputs.push(input);
                }
                else {
                    var input = new Input().deserialize(value['new_tab_custom']);
                    input.path = ['new_tab'];
                    instance.inputs.push(input);
                }
            }
            else if (actionDescription.type == ActionType.OpenPopup) {
                if (!actionDescription.openPopupAction) {
                    actionDescription.openPopupAction = new OpenPopupAction();
                }
                if (value['open_popup']) {
                    actionDescription.openPopupAction.popup = value['open_popup'];
                }
                actionDescription.openPopupAction.closeOther = value['open_popup_close_other'];
                actionDescription.openPopupAction.togglePopup = value['open_popup_toggle_popup'];
            }
            else if (actionDescription.type == ActionType.ClosePopup) {
                if (!actionDescription.closePopupAction) {
                    actionDescription.closePopupAction = new ClosePopupAction();
                }
                if (value['close_popup']) {
                    actionDescription.closePopupAction.popup =
                        value['close_popup'] == OPENED_MODAL_VALUE ? undefined : value['close_popup'];
                }
            }
            else if (actionDescription.type == ActionType.OpenActionMenu) {
                if (!actionDescription.openActionMenuAction) {
                    actionDescription.openActionMenuAction = new OpenActionMenuAction();
                }
                actionDescription.openActionMenuAction.actions = value['open_action_menu_actions'];
            }
            else if (actionDescription.type == ActionType.Workflow) {
                if (!actionDescription.workflowAction) {
                    actionDescription.workflowAction = new WorkflowAction();
                }
                if (value['workflow']) {
                    actionDescription.workflowAction.workflow = value['workflow'];
                }
            }
            if (actionDescription.type == ActionType.Link || actionDescription.type == ActionType.ExternalLink) {
                actionDescription.actionParams = this.inputFieldProvider.getFields().map(function (item) {
                    var result = new ParameterField();
                    result.name = item.name;
                    result.verboseName = item.verboseName;
                    result.description = item.description;
                    result.field = item.field;
                    result.required = item['required'];
                    result.defaultType = item['defaultType'];
                    result.defaultValue = item['defaultValue'];
                    result.params = item.params;
                    result.updateFieldDescription();
                    return result;
                });
            }
            instance.sharedActionDescription = undefined;
            instance.actionDescription = actionDescription;
        }
        if (this.options.approveEnabled) {
            if (value['approve_enabled'] && value['approve'] instanceof Approve) {
                instance.approve = value['approve'];
            }
            else {
                instance.approve = undefined;
            }
        }
        if (this.options.confirmationEnabled) {
            instance.confirmation = this.controls.confirmation_enabled.value
                ? this.controls.confirmation.serialize()
                : undefined;
        }
        return __assign({ action: instance, title: value['title'], visibleInput: value['visible_input'] ? new FieldInput().deserialize(value['visible_input']) : undefined, tooltip: isSet(value['tooltip']) ? value['tooltip'].trim() : undefined }, (this.options.cardWrapEditable && {
            cardWrap: this.controls.card_wrap.value
        }), (this.options.elementStylesEditable && {
            elementStyles: this.controls.element_styles.serialize()
        }));
    };
    return CustomizeBarActionEditForm;
}(FormGroup));
export { CustomizeBarActionEditForm };
