import { AggregateFunc, DatasetGroupLookup } from '@modules/charts';
import { ChartWidgetQuery, QueryType } from '@modules/queries';
import { isSet } from '@shared';

import { DataSource, DataSourceType } from './data-source';

export class ChartWidgetDataSource extends DataSource<ChartWidgetQuery> {
  public static queryCls = ChartWidgetQuery;
  public xColumn: string;
  public xLookup = DatasetGroupLookup.Auto;
  public xColumn2: string;
  public xLookup2 = DatasetGroupLookup.Auto;
  public yFunc: AggregateFunc;
  public yColumn: string;
  public yCumulative = false;

  constructor(options: Partial<ChartWidgetDataSource> = {}) {
    super(options);

    if (options.hasOwnProperty('xColumn')) {
      this.xColumn = options.xColumn;
    }

    if (options.hasOwnProperty('xLookup')) {
      this.xLookup = options.xLookup;
    }

    if (options.hasOwnProperty('xColumn2')) {
      this.xColumn2 = options.xColumn2;
    }

    if (options.hasOwnProperty('xLookup2')) {
      this.xLookup2 = options.xLookup2;
    }

    if (options.hasOwnProperty('yFunc')) {
      this.yFunc = options.yFunc;
    }

    if (options.hasOwnProperty('yColumn')) {
      this.yColumn = options.yColumn;
    }

    if (options.hasOwnProperty('yCumulative')) {
      this.yCumulative = options.yCumulative;
    }
  }

  deserialize(data: Object): this {
    super.deserialize(data);

    this.xColumn = data['x_column'];
    this.xLookup = data['x_lookup'];
    this.xColumn2 = data['x_column_2'];
    this.xLookup2 = data['x_lookup_2'];
    this.yFunc = data['y_func'];
    this.yColumn = data['y_column'];
    this.yCumulative = data['y_cumulative'];

    return this;
  }

  serialize(): Object {
    return {
      ...super.serialize(),
      x_column: this.xColumn,
      x_lookup: this.xLookup,
      x_column_2: this.xColumn2,
      x_lookup_2: this.xLookup2,
      y_func: this.yFunc,
      y_column: this.yColumn,
      y_cumulative: this.yCumulative
    };
  }

  isConfigured(): boolean {
    const isCount = this.yFunc == AggregateFunc.Count;

    return (
      super.isConfigured() &&
      (isCount ||
        (isSet(this.yFunc) && !isCount && isSet(this.yColumn)) ||
        (!isSet(this.yFunc) && isSet(this.yColumn))) &&
      isSet(this.xColumn) &&
      isSet(this.xLookup)
    );
  }

  getModelId(): string {
    return this.type == DataSourceType.Query &&
      this.query &&
      this.query.queryType == QueryType.Simple &&
      this.query.simpleQuery
      ? [this.queryResource, this.query.simpleQuery.model].join('.')
      : undefined;
  }
}
