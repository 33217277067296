import { Pipe, PipeTransform } from '@angular/core';
import { DatepickerLocale } from 'ng-gxdatepicker';

import { localize } from '../../utils/localize';

@Pipe({
  name: 'localizeDatepicker'
})
export class LocalizeDatepickerPipe implements PipeTransform {
  transform(value: any): DatepickerLocale {
    return {
      hours: localize('Hours'),
      minutes: localize('Minutes'),
      seconds: localize('Seconds')
    };
  }
}
