import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { Power2, TweenMax } from 'gsap';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { interval, Subscription } from 'rxjs';
import { delay, take } from 'rxjs/operators';

export interface TipOptions {
  hoverable?: boolean;
  flexible?: boolean;
  opaque?: boolean;
  stick?: boolean;
  style?: string;
  side?: TipSide | string;
  margin?: number;
  maxWidth?: string;
  showDelay?: number;
  hideDelay?: number;
  observeRemoveFromDom?: boolean;
}

export const defaultTipOptions: TipOptions = {
  hoverable: false,
  stick: false,
  style: 'default'
};

export interface TipPosition {
  x: number;
  y: number;
}

export enum TipSide {
  Top = 'top',
  Right = 'right',
  Bottom = 'bottom',
  Left = 'left',
  RightBottom = 'right-bottom'
}

@Component({
  selector: 'app-tip',
  templateUrl: './tip.component.html'
})
export class TipComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() content: string | TemplateRef<any>;
  @Input() options: TipOptions;
  @Input() origin: Element;
  @Input() closed = new EventEmitter<void>();
  @Output() moved = new EventEmitter<TipPosition>();
  @ViewChild('root') root: ElementRef;

  visible = true;
  positioned = false;
  side: TipSide = TipSide.Top;
  closeSubscription: Subscription;

  constructor(private zone: NgZone, private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.updateSide();
  }

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {
    this.updatePosition();

    if (this.options.stick) {
      interval(1000 / 30)
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          this.updatePosition();
        });
    }
  }

  setContent(content: string | TemplateRef<any>) {
    this.content = content;
    this.cd.markForCheck();
  }

  updatePosition() {
    const anchorRect = this.origin.getBoundingClientRect();
    const margin = this.options.margin || 0;
    let x, y, xPercent, yPercent;

    if (this.side == TipSide.Right) {
      x = anchorRect.left + anchorRect.width + margin;
      y = anchorRect.top + anchorRect.height / 2;
      xPercent = 0;
      yPercent = -50;
    } else if (this.side == TipSide.Left) {
      x = anchorRect.left - margin;
      y = anchorRect.top + anchorRect.height / 2;
      xPercent = -100;
      yPercent = -50;
    } else if (this.side == TipSide.Top) {
      x = anchorRect.left + anchorRect.width / 2;
      y = anchorRect.top - margin;
      xPercent = -50;
      yPercent = -100;
    } else if (this.side == TipSide.Bottom) {
      x = anchorRect.left + anchorRect.width / 2;
      y = anchorRect.bottom + margin;
      xPercent = -50;
      yPercent = 0;
    } else if (this.side == TipSide.RightBottom) {
      x = anchorRect.left + anchorRect.width / 2;
      y = anchorRect.bottom + margin;
      xPercent = 25;
      yPercent = 0;
    }

    if (!this.positioned) {
      this.positioned = true;

      TweenMax.set(this.root.nativeElement, {
        x: x,
        y: y,
        xPercent: xPercent,
        yPercent: yPercent
      });
    } else {
      TweenMax.to(this.root.nativeElement, 0.6, {
        x: x,
        y: y,
        xPercent: xPercent,
        yPercent: yPercent,
        ease: Power2.easeOut
      });
    }

    this.moved.emit({ x: x, y: y });
  }

  updateSide() {
    if (this.options.side) {
      this.side = this.options.side as TipSide;
      this.cd.markForCheck();
      return;
    }

    const anchorRect = this.origin.getBoundingClientRect();
    const x = anchorRect.left + anchorRect.width / 2;
    const leftSide = x < window.innerWidth / 2;

    let side: TipSide;

    if (leftSide) {
      side = TipSide.Right;
    } else {
      side = TipSide.Left;
    }

    this.side = side;
    this.cd.markForCheck();
  }

  get template(): TemplateRef<any> {
    if (this.content instanceof TemplateRef) {
      return this.content;
    }
  }

  show() {
    if (this.visible) {
      return;
    }

    if (this.closeSubscription) {
      this.closeSubscription.unsubscribe();
      this.closeSubscription = undefined;
    }

    this.visible = true;
    this.cd.markForCheck();
  }

  close() {
    if (this.closeSubscription) {
      return;
    }

    if (!this.visible) {
      this.closed.emit();
      return;
    }

    this.visible = false;
    this.cd.markForCheck();

    this.closeSubscription = this.zone.onStable
      .pipe(take(1), delay(150), untilDestroyed(this))
      .pipe()
      .subscribe(() => {
        this.closed.emit();
      });
  }
}
