var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { FormUtils } from '@common/form-utils';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { CustomViewTemplate, CustomViewTemplateService, CustomViewTemplateType } from '@modules/views';
import { controlValue } from '@shared';
var CustomViewTemplatesChangeForm = /** @class */ (function (_super) {
    __extends(CustomViewTemplatesChangeForm, _super);
    function CustomViewTemplatesChangeForm(customViewTemplateService, currentProjectStore, currentEnvironmentStore, formUtils) {
        var _this = _super.call(this, {
            name: new FormControl('', Validators.required),
            short_description: new FormControl(''),
            description: new FormControl(''),
            types: new FormControl([]),
            featured: new FormControl(false),
            icon: new FormControl(undefined),
            icon_color: new FormControl(undefined),
            thumbnail: new FormControl(undefined),
            view: new FormControl(null),
            parameters: new FormControl([]),
            actions: new FormControl([]),
            test_parameters: new FormControl({}),
            active: new FormControl(false),
            ordering: new FormControl(0)
        }) || this;
        _this.customViewTemplateService = customViewTemplateService;
        _this.currentProjectStore = currentProjectStore;
        _this.currentEnvironmentStore = currentEnvironmentStore;
        _this.formUtils = formUtils;
        _this.typeOptions = [
            {
                value: CustomViewTemplateType.ListItem,
                name: 'List cards'
            },
            {
                value: CustomViewTemplateType.ItemColumn,
                name: 'Record fields'
            },
            {
                value: CustomViewTemplateType.MenuItem,
                name: 'Menu components'
            },
            {
                value: CustomViewTemplateType.Custom,
                name: 'Standalone'
            }
        ];
        _this.uploaderResourceName = 'jet_templates_uploader';
        _this.uploaderResourceStorageName = 'jetadmin';
        return _this;
    }
    CustomViewTemplatesChangeForm.prototype.ngOnDestroy = function () { };
    CustomViewTemplatesChangeForm.prototype.init = function (value) {
        this.template = value;
        if (value) {
            this.controls.name.patchValue(value.name);
            this.controls.short_description.patchValue(value.shortDescription);
            this.controls.description.patchValue(value.description);
            this.controls.types.patchValue(value.types);
            this.controls.featured.patchValue(value.featured);
            this.controls.icon.patchValue(value.icon);
            this.controls.icon_color.patchValue(value.iconColor);
            this.controls.thumbnail.patchValue(value.thumbnail);
            this.controls.view.patchValue(value.view);
            this.controls.parameters.patchValue(value.parameters);
            this.controls.actions.patchValue(value.actions);
            this.controls.test_parameters.patchValue(value.testParameters);
            this.controls.active.patchValue(value.active);
            // this.controls.ordering.patchValue(value.ordering);
        }
    };
    CustomViewTemplatesChangeForm.prototype.getUploaderResource = function () {
        var _this = this;
        return this.currentEnvironmentStore.resources.find(function (item) { return item.name == _this.uploaderResourceName; });
    };
    CustomViewTemplatesChangeForm.prototype.getUploaderResourceStorage = function () {
        var _this = this;
        var result = this.getUploaderResource();
        if (!result) {
            return;
        }
        return result.storages.find(function (item) { return item.uniqueName == _this.uploaderResourceStorageName; });
    };
    CustomViewTemplatesChangeForm.prototype.getInstance = function () {
        var instance = this.template ? cloneDeep(this.template) : new CustomViewTemplate();
        instance.name = this.controls.name.value;
        instance.shortDescription = this.controls.short_description.value;
        instance.description = this.controls.description.value;
        instance.types = this.controls.types.value;
        instance.featured = this.controls.featured.value;
        instance.icon = this.controls.icon.value;
        instance.iconColor = this.controls.icon_color.value;
        instance.thumbnail = this.controls.thumbnail.value;
        instance.view = this.controls.view.value;
        instance.parameters = this.controls.parameters.value;
        instance.actions = this.controls.actions.value;
        instance.testParameters = this.controls.test_parameters.value;
        instance.active = this.controls.active.value;
        // instance.ordering = this.controls.ordering.value;
        return instance;
    };
    CustomViewTemplatesChangeForm.prototype.getInstance$ = function () {
        var _this = this;
        return controlValue(this).pipe(map(function () { return _this.getInstance(); }));
    };
    CustomViewTemplatesChangeForm.prototype.submit = function () {
        var _this = this;
        var instance = this.getInstance();
        var fields = ['name', 'short_description', 'description', 'types', 'featured', 'view', 'active', 'params'];
        if (this.template) {
            return this.customViewTemplateService.update(instance, fields).pipe(catchError(function (error) {
                _this.formUtils.showFormErrors(_this, error);
                return throwError(error);
            }));
        }
        else {
            return this.customViewTemplateService.create(instance, fields).pipe(catchError(function (error) {
                _this.formUtils.showFormErrors(_this, error);
                return throwError(error);
            }));
        }
    };
    return CustomViewTemplatesChangeForm;
}(FormGroup));
export { CustomViewTemplatesChangeForm };
