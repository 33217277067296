import pickBy from 'lodash/pickBy';
import * as moment from 'moment';

import { ActionOutput, ParameterField } from '@modules/fields';
import { isSet } from '@shared';

import { View } from './view';

export enum CustomViewTemplateType {
  ListItem = 'list_item',
  ItemColumn = 'item_column',
  MenuItem = 'menu_item',
  Custom = 'custom'
}

export interface CustomViewTemplateTypeItem {
  type: CustomViewTemplateType;
  label: string;
  icon?: string;
}

export const customViewTemplateTypeItems: CustomViewTemplateTypeItem[] = [
  {
    type: CustomViewTemplateType.ListItem,
    label: 'List cards',
    icon: 'blocks'
  },
  {
    type: CustomViewTemplateType.ItemColumn,
    label: 'Record fields',
    icon: 'fileds'
  },
  {
    type: CustomViewTemplateType.Custom,
    label: 'Standalone',
    icon: 'canvas'
  }
];

export function getCustomViewTemplateTypeItem(type: CustomViewTemplateType): CustomViewTemplateTypeItem {
  return customViewTemplateTypeItems.find(item => item.type == type);
}

export class CustomViewTemplate {
  public id: string;
  public name: string;
  public shortDescription: string;
  public description: string;
  public types: CustomViewTemplateType[] = [];
  public featured: boolean;
  public rating: number;
  public userRating: number;
  public usages = 0;
  public view: View;
  public parameters: ParameterField[] = [];
  public actions: ActionOutput[] = [];
  public testParameters: Record<string, unknown> = {};
  public active: boolean;
  public params = {};
  public icon: string;
  public iconColor: string;
  public thumbnail: string;
  public dateAdd: moment.Moment;
  public dateChange: moment.Moment;

  deserialize(data: Object): CustomViewTemplate {
    this.id = data['id'];
    this.shortDescription = data['short_description'];
    this.description = data['description'];
    this.types = data['types'];
    this.featured = data['featured'];
    this.rating = data['rating'];
    this.userRating = data['user_rating'];
    this.usages = data['usages'];
    this.active = data['active'];
    this.params = data['params'];
    this.dateAdd = moment(data['date_add']);
    this.dateChange = moment(data['date_change']);

    if (data['view']) {
      this.view = new View().deserialize(data['view']);
    }

    this.name = data['name'];
    // Backward compatibility
    if (!isSet(this.name) && data['view'] && isSet(data['view']['name'])) {
      this.name = data['view']['name'];
    }

    if (this.params['parameters']) {
      this.parameters = this.params['parameters'].map(status => new ParameterField().deserialize(status));
    } else if (data['view'] && data['view']['parameters']) {
      // Backward compatibility
      this.parameters = data['view']['parameters'].map(status => new ParameterField().deserialize(status));
    }

    if (this.params['actions']) {
      this.actions = this.params['actions'].map(item => new ActionOutput().deserialize(item));
    } else if (data['view'] && data['view']['actions']) {
      // Backward compatibility
      this.actions = data['view']['actions'].map(item => new ActionOutput().deserialize(item));
    }

    if (this.params['test_parameters']) {
      this.testParameters = this.params['test_parameters'];
    } else if (data['view'] && data['view']['test_parameters']) {
      // Backward compatibility
      this.testParameters = data['view']['test_parameters'];
    }

    this.icon = this.params['icon'];
    this.iconColor = this.params['icon_color'];
    this.thumbnail = this.params['thumbnail'];

    return this;
  }

  serialize(fields?: string[]): Object {
    this.params = {
      parameters: this.parameters.map(item => item.serialize()),
      actions: this.actions.map(item => item.serialize()),
      test_parameters: this.testParameters,
      icon: this.icon,
      icon_color: this.iconColor,
      thumbnail: this.thumbnail
    };

    let data: Object = {
      id: this.id,
      name: this.name,
      short_description: this.shortDescription,
      description: this.description,
      types: this.types,
      featured: this.featured,
      rating: this.rating,
      usages: this.usages,
      view: this.view ? this.view.serialize() : undefined,
      active: this.active,
      params: this.params
    };
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }

  getTypeItems(): CustomViewTemplateTypeItem[] {
    return this.types.map(item => getCustomViewTemplateTypeItem(item)).filter(item => item);
  }
}
