import { Injectable, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ApiService } from '@modules/api';
import { ProjectApiService } from '@modules/project-api';

import { IDEService } from '../ide-service/ide.service';

@Injectable()
export class IDEController implements OnDestroy {
  constructor(
    private ideService: IDEService,
    private apiService: ApiService,
    private projectApiService: ProjectApiService,
    private analyticsService: UniversalAnalyticsService
  ) {}

  ngOnDestroy(): void {}

  open(options: {
    projectName: string;
    environmentName: string;
    customViewName: string;
    analyticsSource?: string;
  }): Observable<boolean> {
    return this.projectApiService.getToken(options.projectName, options.environmentName).pipe(
      switchMap(token => {
        return this.ideService.initSession(options.projectName, options.environmentName, options.customViewName, token);
      }),
      map(session => {
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.JSEditor.Opened, {
          Name: options.customViewName,
          Source: options.analyticsSource
        });

        const path = `projects/${options.projectName}/${options.environmentName}/custom-views/${options.customViewName}`;
        const url = this.apiService.ideURL(`${path}#sid=${session.sid}`);

        window.open(url, '_blank');

        return true;
      })
    );
  }
}
