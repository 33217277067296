var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { FormUtils } from '@common/form-utils';
import { ApiService } from '@modules/api';
import { CurrentUserStore, User, UserService } from '@modules/users';
import { isSet } from '@shared';
export function validateCurrentPassword() {
    return function (control) {
        if (!control.value) {
            return;
        }
        var parent = control.parent;
        if (!parent) {
            return;
        }
        if (parent.user && !parent.user.hasPassword) {
            return;
        }
        if (!isSet(control.value)) {
            return { required: true };
        }
    };
}
var ChangePasswordForm = /** @class */ (function (_super) {
    __extends(ChangePasswordForm, _super);
    function ChangePasswordForm(formUtils, fb, apiService, userService, currentUserStore) {
        var _this = _super.call(this, {
            current_password: new FormControl('', validateCurrentPassword()),
            new_password: new FormControl('', Validators.required),
            repeat_password: new FormControl('', Validators.required)
        }) || this;
        _this.formUtils = formUtils;
        _this.fb = fb;
        _this.apiService = apiService;
        _this.userService = userService;
        _this.currentUserStore = currentUserStore;
        return _this;
    }
    ChangePasswordForm.prototype.init = function (user) {
        this.user = user;
        this.markAsPristine();
    };
    ChangePasswordForm.prototype.changePassword = function () {
        var _this = this;
        this.markAsDirty();
        var instance = new User();
        instance.currentPassword = this.controls.current_password.value;
        instance.password = this.controls.new_password.value;
        return this.userService.update(instance, ['password', 'current_password']).pipe(switchMap(function (result) {
            if (result.token) {
                _this.apiService.saveToken(result.token);
            }
            return _this.currentUserStore.getFirst(true);
        }), tap(function (result) {
            _this.user = result;
            _this.reset();
            _this.markAsPristine();
        }), catchError(function (error) {
            _this.formUtils.showFormErrors(_this, error);
            return throwError(error);
        }));
    };
    return ChangePasswordForm;
}(FormGroup));
export { ChangePasswordForm };
