/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../custom-view/standalone-custom-view.component.ngfactory";
import * as i2 from "../custom-view/standalone-custom-view.component";
import * as i3 from "../../../custom-views/services/custom-view-loader/custom-view-loader.service";
import * as i4 from "@angular/common";
import * as i5 from "./model-description-custom-view.component";
import * as i6 from "../../../custom-views/stores/custom-views.store";
import * as i7 from "@angular/router";
var styles_ModelDescriptionCustomViewComponent = [];
var RenderType_ModelDescriptionCustomViewComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ModelDescriptionCustomViewComponent, data: {} });
export { RenderType_ModelDescriptionCustomViewComponent as RenderType_ModelDescriptionCustomViewComponent };
function View_ModelDescriptionCustomViewComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-standalone-custom-view", [], null, null, null, i1.View_StandaloneCustomViewComponent_0, i1.RenderType_StandaloneCustomViewComponent)), i0.ɵdid(1, 4898816, null, 0, i2.StandaloneCustomViewComponent, [i3.CustomViewLoaderService], { customView: [0, "customView"], attrs: [1, "attrs"] }, null), i0.ɵpod(2, { "model-description": 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.customView; var currVal_1 = _ck(_v, 2, 0, _co.modelDescription); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_ModelDescriptionCustomViewComponent_0(_l) { return i0.ɵvid(2, [i0.ɵqud(402653184, 1, { container: 0 }), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "custom-view"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ModelDescriptionCustomViewComponent_1)), i0.ɵdid(3, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.customView; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_ModelDescriptionCustomViewComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-model-description-custom-view", [], null, null, null, View_ModelDescriptionCustomViewComponent_0, RenderType_ModelDescriptionCustomViewComponent)), i0.ɵdid(1, 245760, null, 0, i5.ModelDescriptionCustomViewComponent, [i6.CustomViewsStore, i7.ActivatedRoute, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ModelDescriptionCustomViewComponentNgFactory = i0.ɵccf("app-model-description-custom-view", i5.ModelDescriptionCustomViewComponent, View_ModelDescriptionCustomViewComponent_Host_0, {}, {}, []);
export { ModelDescriptionCustomViewComponentNgFactory as ModelDescriptionCustomViewComponentNgFactory };
