import { Injectable, Injector } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';

import { AccordionElementItem, CollapseIndicatorPosition, MarginControl } from '@modules/customize';
import { Option } from '@modules/field-components';
import { Input } from '@modules/fields';
import { FieldInputControl } from '@modules/parameters';

import { AccordionItemArray } from './accordion-item.array';

@Injectable()
export class CustomizeBarAccordionEditForm extends FormGroup {
  controls: {
    title: FormControl;
    items: AccordionItemArray;
    multiple_opened: FormControl;
    collapse_indicator_position: FormControl;
    visible_input: FieldInputControl;
    card_wrap: FormControl;
    margin: MarginControl;
  };

  element: AccordionElementItem;

  collapseIndicatorPositionOptions: Option<CollapseIndicatorPosition>[] = [
    {
      value: CollapseIndicatorPosition.None,
      name: 'None'
    },
    {
      value: CollapseIndicatorPosition.Left,
      name: 'Left'
    },
    {
      value: CollapseIndicatorPosition.Right,
      name: 'Right'
    }
  ];

  constructor(private injector: Injector) {
    super({
      title: new FormControl(''),
      items: AccordionItemArray.inject(injector, []),
      multiple_opened: new FormControl(false),
      collapse_indicator_position: new FormControl(CollapseIndicatorPosition.Right),
      visible_input: new FieldInputControl({ path: ['value'] }),
      card_wrap: new FormControl(true),
      margin: new MarginControl()
    });
  }

  init(element: AccordionElementItem) {
    this.element = element;

    this.controls.title.patchValue(element.name ? element.name : 'Tabs');
    this.controls.items.deserialize(element.items);
    this.controls.multiple_opened.patchValue(element.multipleOpened);
    this.controls.collapse_indicator_position.patchValue(
      element.collapseIndicatorPosition || CollapseIndicatorPosition.Right
    );
    this.controls.card_wrap.patchValue(element.cardWrap);
    this.controls.margin.patchValue(element.margin);
    this.controls.visible_input.patchValue(element.visibleInput ? element.visibleInput.serializeWithoutPath() : {});

    this.markAsPristine();
  }

  submit(): AccordionElementItem {
    const instance = cloneDeep(this.element) as AccordionElementItem;

    instance.name = this.controls.title.value;
    instance.items = this.controls.items.serialize();
    instance.multipleOpened = this.controls.multiple_opened.value;
    instance.collapseIndicatorPosition = this.controls.collapse_indicator_position.value;

    if (this.controls.visible_input.value) {
      instance.visibleInput = new Input().deserialize(this.controls.visible_input.value);
    } else {
      instance.visibleInput = undefined;
    }

    instance.cardWrap = this.controls.card_wrap.value;
    instance.margin = this.controls.margin.value;

    return instance;
  }
}
