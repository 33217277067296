import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  QueryList,
  ViewChildren
} from '@angular/core';
import isEqual from 'lodash/isEqual';
import toPairs from 'lodash/toPairs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, merge } from 'rxjs';
import { debounceTime, delayWhen, filter, first, map, pairwise } from 'rxjs/operators';

import { DialogService } from '@common/dialogs';
import { localize } from '@common/localize';
import { NotificationService } from '@common/notifications';
import { BasePopupComponent } from '@common/popups';
import { AdminMode, ROUTE_ADMIN_MODE } from '@modules/admin-mode';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ServerRequestError } from '@modules/api';
import { ViewSettings } from '@modules/customize';
import { createFormFieldFactory } from '@modules/fields';
import {
  CurrentEnvironmentStore,
  CurrentProjectStore,
  ProjectGroup,
  ProjectGroupService,
  ProjectGroupStore,
  ProjectInvite,
  ProjectInviteService,
  ProjectPermissionType,
  ProjectUser,
  ProjectUserService
} from '@modules/projects';
import { RoutingService } from '@modules/routing';
import { GuideService } from '@modules/tutorial';
import { CurrentUserStore } from '@modules/users';
import { controlValue, errorToString } from '@shared';

import { ProjectUserForm } from '../../forms/project-user.form';
import { ProjectPermissionActionsComponent } from '../project-permission-actions/project-permission-actions.component';
import { ProjectGroupChangeForm } from './project-group-change.form';
import { ProjectModelPermissionsArray } from './project-model-permissions.array';
import { ProjectPagePermissionsArray } from './project-page-permissions.array';

enum Tab {
  Members = 'members',
  PagePermissions = 'page_permissions',
  ProjectPermissions = 'project_permissions',
  Properties = 'properties'
}

interface TabItem {
  name: Tab;
  title: string;
}

@Component({
  selector: 'app-project-group-change',
  templateUrl: './project-group-change.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ProjectGroupChangeForm, ProjectModelPermissionsArray, ProjectPagePermissionsArray, ProjectUserForm]
})
export class ProjectGroupChangeComponent implements OnInit, OnDestroy {
  @Input() group: ProjectGroup;
  @Input() members = true;
  @Input() autoSave = false;
  @Input() propertiesEnabled = true;
  @Input() deleteEnabled = true;
  @Output() saved = new EventEmitter<ProjectGroup>();
  @Output() deleted = new EventEmitter<ProjectGroup>();

  @ViewChildren('page_permission_actions') pagePermissionActions = new QueryList<ProjectPermissionActionsComponent>();

  allTabItems: TabItem[] = [
    {
      name: Tab.Members,
      title: localize('Members')
    },
    {
      name: Tab.PagePermissions,
      title: localize('Page Permissions')
    },
    {
      name: Tab.ProjectPermissions,
      title: localize('App Permissions')
    },
    {
      name: Tab.Properties,
      title: localize('Properties')
    }
  ];
  tabItems: TabItem[] = [];
  tabs = Tab;
  activeTabName: Tab;
  users: ProjectUser[] = [];
  invites: ProjectInvite[] = [];
  loading = false;
  loadingUsers = false;
  editProperties = false;
  createField = createFormFieldFactory();
  pages: ViewSettings[];
  deleteUserLoading: boolean[] = [];

  constructor(
    @Inject(ROUTE_ADMIN_MODE) private mode: AdminMode,
    private projectGroupService: ProjectGroupService,
    private projectGroupStore: ProjectGroupStore,
    public currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    @Optional() private popupComponent: BasePopupComponent,
    private notificationService: NotificationService,
    public formGroupChange: ProjectGroupChangeForm,
    private routing: RoutingService,
    private guideService: GuideService,
    private cd: ChangeDetectorRef,
    private projectUserService: ProjectUserService,
    private projectInviteService: ProjectInviteService,
    private dialogService: DialogService,
    private analyticsService: UniversalAnalyticsService,
    public currentUserStore: CurrentUserStore,
    public formUser: ProjectUserForm
  ) {}

  ngOnInit() {
    this.initTabs();

    if (this.group) {
      this.getUsers();
    }

    this.initForm();

    this.formUser.init(
      new ProjectUser().deserialize({
        user: null,
        user_email: null,
        group: this.group,
        properties: {}
      })
    );

    this.formGroupChange.statusChanges.pipe(untilDestroyed(this)).subscribe(() => {
      this.cd.markForCheck();
    });
  }

  ngOnDestroy(): void {}

  initTabs() {
    this.tabItems = this.allTabItems.filter(item => {
      if (item.name == Tab.Members) {
        return this.members;
      } else if ([Tab.PagePermissions, Tab.ProjectPermissions].includes(item.name)) {
        return !this.group || !this.group.protected;
      } else if (item.name == Tab.Properties) {
        return this.propertiesEnabled;
      } else {
        return true;
      }
    });
    this.activeTabName = this.tabItems[0].name;
  }

  setActiveTabName(name: Tab) {
    this.activeTabName = name;
    this.cd.markForCheck();
  }

  initForm() {
    this.formGroupChange.init(this.group);

    this.formGroupChange.controls.model_permissions.initialized$.pipe(untilDestroyed(this)).subscribe(() => {
      this.cd.markForCheck();

      merge(
        this.formGroupChange.controls.permissions.valueChanges,
        this.formGroupChange.controls.page_permissions.valueChanges,
        this.formGroupChange.controls.model_permissions.valueChanges
      )
        .pipe(debounceTime(2000), untilDestroyed(this))
        .subscribe(() => this.guideService.completeTaskProgress('create_group', 1, 'set_permissions'));

      if (this.group && this.autoSave) {
        this.formGroupChange.valueChanges.pipe(debounceTime(700), untilDestroyed(this)).subscribe(() => {
          this.submit();
        });
      }

      this.initAnalytics();
    });
  }

  getUsers() {
    if (!this.members) {
      return;
    }

    this.loadingUsers = true;
    this.cd.markForCheck();

    combineLatest(
      this.projectUserService.get(
        this.currentProjectStore.instance.uniqueName,
        this.currentEnvironmentStore.instance.uniqueName
      ),
      this.projectInviteService.get(
        this.currentProjectStore.instance.uniqueName,
        this.currentEnvironmentStore.instance.uniqueName
      )
    )
      .pipe(untilDestroyed(this))
      .subscribe(
        ([users, invites]) => {
          this.users = users.filter(item => item.group && item.group.uid == this.group.uid);
          this.invites = invites.filter(item => item.group && item.group.uid == this.group.uid);
          this.loadingUsers = false;
          this.cd.markForCheck();
        },
        () => {
          this.loadingUsers = false;
          this.cd.markForCheck();
        }
      );
  }

  submit() {
    this.loading = true;
    this.cd.markForCheck();

    this.formGroupChange
      .submit()
      .pipe(untilDestroyed(this))
      .subscribe(
        group => {
          if (this.group) {
            this.notificationService.success('Saved', 'Team was successfully updated');

            this.analyticsService.sendSimpleEvent(AnalyticsEvent.Team.Updated, {
              TeamID: this.group ? this.group.name : undefined,
              ProjectPermissionsChanged: !isEqual(
                this.group.getPermissions(ProjectPermissionType.Project),
                group.getPermissions(ProjectPermissionType.Project)
              ),
              CollectionPermissionsChanged: !isEqual(
                this.group.getPermissions(ProjectPermissionType.Model),
                group.getPermissions(ProjectPermissionType.Model)
              ),
              PropertiesChanged: !isEqual(this.group.properties, group.properties),
              Mode: this.mode
            });
          } else {
            this.notificationService.success('Created', 'Team was successfully added to current App');

            this.analyticsService.sendSimpleEvent(AnalyticsEvent.Team.Created, {
              TeamID: this.group ? this.group.name : undefined,
              Mode: this.mode
            });
          }
          this.saved.emit(group);
          this.cd.markForCheck();
        },
        error => {
          if (error instanceof ServerRequestError && error.errors.length) {
            this.notificationService.error('Saving team failed', error.errors[0]);
          } else {
            this.notificationService.error('Saving team failed', errorToString(error));
          }

          this.loading = false;
          this.cd.markForCheck();
        }
      );
  }

  back() {
    if (this.popupComponent) {
      this.popupComponent.close();
    } else {
      this.routing.navigateApp(this.currentProjectStore.instance.settingsGroupsLink);
    }
  }

  setPagePermissionsFullAccess() {
    const wasNoAccess = this.formGroupChange.controls.page_permissions.controls.some(
      item => !item.controls.actions.isSelectedAny()
    );

    this.formGroupChange.controls.page_permissions.setEveryControlAllActions(true);
    this.pagePermissionActions.forEach(item => item.setCustom(false));

    if (wasNoAccess) {
      this.analyticsService.sendSimpleEvent(AnalyticsEvent.Permission.PageAdded, {
        Mode: this.mode,
        All: true
      });
    }
  }

  setPagePermissionsReadOnly() {
    const wasNoAccess = this.formGroupChange.controls.page_permissions.controls.some(
      item => !item.controls.actions.isSelectedAny()
    );

    this.formGroupChange.controls.page_permissions.setEveryControlAction('read', true);
    this.formGroupChange.controls.page_permissions.setEveryControlAction('write', false);
    this.formGroupChange.controls.page_permissions.setEveryControlAction('delete', false);
    this.pagePermissionActions.forEach(item => item.setCustom(false));

    if (wasNoAccess) {
      this.analyticsService.sendSimpleEvent(AnalyticsEvent.Permission.PageAdded, {
        Mode: this.mode,
        All: true
      });
    }
  }

  setPagePermissionsNoAccess() {
    const wasAccess = this.formGroupChange.controls.page_permissions.controls.some(item =>
      item.controls.actions.isSelectedAny()
    );

    this.formGroupChange.controls.page_permissions.setEveryControlAllActions(false);
    this.pagePermissionActions.forEach(item => item.setCustom(false));

    if (wasAccess) {
      this.analyticsService.sendSimpleEvent(AnalyticsEvent.Permission.PageRemoved, {
        Mode: this.mode,
        All: true
      });
    }
  }

  toggleEditProperties() {
    this.editProperties = !this.editProperties;
    this.cd.markForCheck();

    if (this.editProperties && !this.formGroupChange.isPropertiesSet()) {
      this.formGroupChange.controls.properties.patchValue({ property1: 'value1' });
    }
  }

  requestDelete() {
    this.dialogService
      .warning({
        title: 'Deleting',
        description: 'Are you sure want to delete this team from App?'
      })
      .pipe(
        filter(result => result == true),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.projectGroupService
          .delete(
            this.currentProjectStore.instance.uniqueName,
            this.currentEnvironmentStore.instance.uniqueName,
            this.group
          )
          .pipe(
            delayWhen(() => this.projectGroupStore.getFirst(true)),
            untilDestroyed(this)
          )
          .subscribe(() => {
            this.deleted.emit(this.group);

            this.notificationService.success('Deleted', 'Team was successfully deleted from App');

            this.analyticsService.sendSimpleEvent(AnalyticsEvent.Team.Deleted, {
              TeamID: this.group ? this.group.name : undefined,
              Mode: this.mode
            });

            this.back();
          });
      });
  }

  submitUser() {
    const group = this.formUser.form.value['group'];

    this.formUser
      .submit()
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          this.notificationService.success('Created', 'User was successfully added to current App');
          this.formUser.form.controls['user_email'].reset();
          this.cd.markForCheck();
          this.getUsers();

          this.analyticsService.sendSimpleEvent(AnalyticsEvent.Share.MemberInvited, {
            Email: result.getEmail(),
            Mode: this.mode,
            CustomizationPermission: this.currentProjectStore.instance.hasGroupCustomizationPermission(group),
            Source: 'project_team'
          });
        },
        error => {
          this.loading = false;
          this.cd.markForCheck();

          if (error instanceof ServerRequestError && error.errors.length) {
            this.notificationService.error('Update Failed', error.errors[0]);
          } else {
            this.notificationService.error('Update Failed', errorToString(error));
          }
        }
      );
  }

  onGroupNameChange(value: string) {
    this.formGroupChange.controls.name.patchValue(value);
    this.cd.markForCheck();
  }

  initAnalytics() {
    merge(
      this.formGroupChange.controls.permissions.valueChanges,
      this.formGroupChange.controls.page_permissions.valueChanges,
      this.formGroupChange.controls.model_permissions.valueChanges
    )
      .pipe(first(), untilDestroyed(this))
      .subscribe(() => {
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.Permission.StartedToSetUp, {
          TeamID: this.group ? this.group.name : undefined,
          Mode: this.mode
        });
      });

    merge(
      ...[
        this.formGroupChange.controls.permissions.controls.project_settings,
        this.formGroupChange.controls.permissions.controls.project_billing,
        this.formGroupChange.controls.permissions.controls.project_access,
        this.formGroupChange.controls.permissions.controls.project_customization
      ].map(control => {
        const name = toPairs(this.formGroupChange.controls.permissions.controls)
          .filter(([k, v]) => v === control)
          .map(([k, v]) => k)[0];

        return controlValue(control.controls.enabled).pipe(
          pairwise(),
          map(([prev, current]) => {
            if (!prev && current) {
              return { enabled: true, name: name };
            } else if (prev && !current) {
              return { enabled: false, name: name };
            }
          })
        );
      })
    )
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        if (result && result.enabled) {
          this.analyticsService.sendSimpleEvent(AnalyticsEvent.Permission.ProjectAdded, {
            Name: result.name,
            TeamID: this.group ? this.group.name : undefined,
            Mode: this.mode
          });
        } else if (result && !result.enabled) {
          this.analyticsService.sendSimpleEvent(AnalyticsEvent.Permission.ProjectRemoved, {
            Name: result.name,
            TeamID: this.group ? this.group.name : undefined,
            Mode: this.mode
          });
        }
      });
  }
}
