<div class="signature" [class.signature_fill-height]="fillHeight" [class.signature_theme]="theme">
  <div class="signature__content">
    <canvas class="signature__canvas" #canvas></canvas>

    <div *ngIf="!empty" class="signature__controls">
      <div class="signature__button signature__button_icon signature__control" (click)="reset()">
        <span
          class="signature__button-icon icon-repeat"
          [appTip]="'Clear' | localize"
          [appTipOptions]="{ side: 'top' }"
        ></span>
      </div>
    </div>
  </div>

  <div class="signature__description">
    <span class="signature__description-icon icon-pen"></span> {{ 'Sign using your finger or pointer' | localize }}
  </div>
</div>
