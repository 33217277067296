import pickBy from 'lodash/pickBy';

import { environment } from '@env/environment';
import { SSOSettings } from '@modules/sso';
import { initials } from '@shared';

export function getActualDomain(domain: string, custom: boolean): string {
  if (!custom) {
    return `${domain}.${environment.subdomainsBaseDomain}`;
  }

  return domain;
}

export class EmailConfig {
  public host: string;
  public port: number;
  public username: string;
  public password: string;
  public from: string;

  deserialize(data: Object): EmailConfig {
    if (data['host'] !== undefined) {
      this.host = data['host'];
    }

    if (data['port'] !== undefined) {
      this.port = data['port'];
    }

    if (data['username'] !== undefined) {
      this.username = data['username'];
    }

    if (data['password'] !== undefined) {
      this.password = data['password'];
    }

    if (data['from'] !== undefined) {
      this.from = data['from'];
    }

    return this;
  }

  serialize(fields?: string[]): Object {
    let data: Object = {
      host: this.host,
      port: this.port,
      username: this.username,
      password: this.password,
      from: this.from
    };
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }
}

export enum SignUpLayout {
  PosterRight = 'poster_right',
  PosterLeft = 'poster_left',
  Form = 'form',
  Background = 'background'
}

export const defaultSignUpLayout = SignUpLayout.Form;

export class Domain {
  public domain: string;
  public custom = true;
  public whiteLabel = false;
  public logo: string;
  public logoColor: string;
  public name: string;
  public initials: string;
  public defaultProject: string;
  public ssoSettings: SSOSettings[] = [];
  public titleShort: string;
  public title: string;
  public description: string;
  public image: string;
  public icon: string;
  public privacyPolicy: string;
  public termsOfService: string;
  public language: string;
  public timezone: string;
  public numberFractionSeparator: string;
  public numberThousandsSeparator: string;
  public params = {};
  public privateParams = {};
  public disableEmailSignIn = false;
  public disableEmailSignUp = false;
  public disableGoogleSignIn = false;
  public disableFacebookSignIn = false;
  public publicAppImageTop: string;
  public publicAppImageMiddle: string;
  public publicAppImageBottom: string;
  public publicAppImageBottomOverlay: string;
  public makeDefaultProject = false;
  public emailConfig: EmailConfig;
  public emailConfigFrom: string;
  public signUpLayout: SignUpLayout = SignUpLayout.Form;
  public signUpBackgroundColor: string;
  public signUpAccentColor: string;
  public signUpFontRegular: string;
  public signUpFontHeading: string;
  public signUpBackgroundImage: string;
  public signUpTitle: string;
  public signUpDescription: string;
  public signUpExternalUrl: string;
  public signInTitle: string;
  public signInDescription: string;
  public restoreExternalUrl: string;
  public appendScripts: string;
  public appendStyles: string;

  deserialize(data: Object): Domain {
    this.domain = data['domain'];
    this.whiteLabel = data['white_label'];
    this.title = data['title'];
    this.description = data['description'];
    this.logoColor = data['color'];
    this.logo = data['logo'];
    this.name = data['name'];
    this.initials = initials(this.name);
    this.defaultProject = data['default_project'];
    this.titleShort = data['title_short'];
    this.title = data['title'];
    this.description = data['description'];
    this.image = data['image'];
    this.icon = data['icon'];
    this.privacyPolicy = data['privacy_policy'];
    this.termsOfService = data['terms_of_service'];
    this.language = data['language'];
    this.timezone = data['timezone'];
    this.emailConfigFrom = data['email_config_from'];

    if (data['custom'] !== undefined) {
      this.custom = data['custom'];
    }

    if (data['sso_settings']) {
      this.ssoSettings = data['sso_settings'].map(item => new SSOSettings().deserialize(item));
    }

    if (data['params']) {
      this.params = data['params'];

      if (this.params['disable_email_sign_in'] !== undefined) {
        this.disableEmailSignIn = this.params['disable_email_sign_in'];
      }

      if (this.params['disable_email_sign_up'] !== undefined) {
        this.disableEmailSignUp = this.params['disable_email_sign_up'];
      }

      if (this.params['disable_google_sign_in'] !== undefined) {
        this.disableGoogleSignIn = this.params['disable_google_sign_in'];
      }

      if (this.params['disable_facebook_sign_in'] !== undefined) {
        this.disableFacebookSignIn = this.params['disable_facebook_sign_in'];
      }

      if (this.params['public_app_image_top'] !== undefined) {
        this.publicAppImageTop = this.params['public_app_image_top'];
      }

      if (this.params['public_app_image_middle'] !== undefined) {
        this.publicAppImageMiddle = this.params['public_app_image_middle'];
      }

      if (this.params['public_app_image_bottom'] !== undefined) {
        this.publicAppImageBottom = this.params['public_app_image_bottom'];
      }

      if (this.params['public_app_image_bottom_overlay'] !== undefined) {
        this.publicAppImageBottomOverlay = this.params['public_app_image_bottom_overlay'];
      }

      if (this.params['sign_up_layout'] !== undefined) {
        this.signUpLayout = this.params['sign_up_layout'];
      }

      if (this.params['sign_up_background_color'] !== undefined) {
        this.signUpBackgroundColor = this.params['sign_up_background_color'];
      }

      if (this.params['sign_up_accent_color'] !== undefined) {
        this.signUpAccentColor = this.params['sign_up_accent_color'];
      }

      if (this.params['sign_up_font_regular'] !== undefined) {
        this.signUpFontRegular = this.params['sign_up_font_regular'];
      }

      if (this.params['sign_up_font_heading'] !== undefined) {
        this.signUpFontHeading = this.params['sign_up_font_heading'];
      }

      if (this.params['sign_up_background_image'] !== undefined) {
        this.signUpBackgroundImage = this.params['sign_up_background_image'];
      }

      if (this.params['sign_up_title'] !== undefined) {
        this.signUpTitle = this.params['sign_up_title'];
      }

      if (this.params['sign_up_description'] !== undefined) {
        this.signUpDescription = this.params['sign_up_description'];
      }

      if (this.params['sign_up_external_url'] !== undefined) {
        this.signUpExternalUrl = this.params['sign_up_external_url'];
      }

      if (this.params['sign_in_title'] !== undefined) {
        this.signInTitle = this.params['sign_in_title'];
      }

      if (this.params['sign_in_description'] !== undefined) {
        this.signInDescription = this.params['sign_in_description'];
      }

      if (this.params['restore_external_url'] !== undefined) {
        this.restoreExternalUrl = this.params['restore_external_url'];
      }

      this.numberFractionSeparator = this.params['number_fraction_separator'];
      this.numberThousandsSeparator = this.params['number_thousands_separator'];
      this.appendScripts = this.params['append_scripts'];
      this.appendStyles = this.params['append_styles'];
    }

    if (data['private_params']) {
      this.privateParams = data['private_params'];

      if (this.privateParams['email_config']) {
        this.emailConfig = new EmailConfig().deserialize(this.privateParams['email_config']);
      }
    }

    return this;
  }

  serialize(fields?: string[]): Object {
    const params = { ...this.params };

    params['disable_email_sign_in'] = this.disableEmailSignIn;
    params['disable_email_sign_up'] = this.disableEmailSignUp;
    params['disable_google_sign_in'] = this.disableGoogleSignIn;
    params['disable_facebook_sign_in'] = this.disableFacebookSignIn;
    params['sign_up_layout'] = this.signUpLayout;
    params['sign_up_background_color'] = this.signUpBackgroundColor;
    params['sign_up_accent_color'] = this.signUpAccentColor;
    params['sign_up_font_regular'] = this.signUpFontRegular;
    params['sign_up_font_heading'] = this.signUpFontHeading;
    params['sign_up_background_image'] = this.signUpBackgroundImage;
    params['sign_up_title'] = this.signUpTitle;
    params['sign_up_description'] = this.signUpDescription;
    params['sign_up_external_url'] = this.signUpExternalUrl;
    params['sign_in_title'] = this.signInTitle;
    params['sign_in_description'] = this.signInDescription;
    params['restore_external_url'] = this.restoreExternalUrl;
    params['number_fraction_separator'] = this.numberFractionSeparator;
    params['number_thousands_separator'] = this.numberThousandsSeparator;
    params['append_scripts'] = this.appendScripts;
    params['append_styles'] = this.appendStyles;

    const privateParams = { ...this.privateParams };

    privateParams['email_config'] = this.emailConfig ? this.emailConfig.serialize() : undefined;

    let data: Object = {
      domain: this.domain,
      make_default_project: this.makeDefaultProject,
      white_label: this.whiteLabel,
      name: this.name,
      title: this.title,
      description: this.description,
      logo: this.logo,
      color: this.logoColor,
      icon: this.icon,
      image: this.image,
      privacy_policy: this.privacyPolicy,
      terms_of_service: this.termsOfService,
      language: this.language,
      timezone: this.timezone,
      params: params,
      private_params: privateParams
    };
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }

  get actualDomain(): string {
    return getActualDomain(this.domain, this.custom);
  }
}
