<div
  class="layer-container"
  (mouseenter)="onMouseEnter()"
  (mouseleave)="onMouseLeave()"
  (mouseup)="onMouseUp()"
  (click)="onMouseClick($event)"
>
  <div
    class="element-layer"
    [class.element-layer_interactive]="
      editorContext ? (customizing$ | async) && !(editorContext.movingLayer$ | async) : true
    "
    [style.opacity]="layer.opacity"
    [style.cursor]="layer.getCursor() || null"
    #layer_element
  >
    <app-layer-interaction
      *ngFor="let item of layer.interactions"
      [interaction]="item"
      [element]="layer_element"
      [context]="viewContext"
      [contextElement]="contextElement"
      [analyticsSource]="analyticsSource"
    ></app-layer-interaction>

    <app-auto-element
      *ngIf="layer.element"
      [element]="layer.element"
      [context]="viewContext"
      [wrapperEnabled]="false"
      [paddingEnabled]="false"
      [fillVertical]="true"
      (click)="$event.stopPropagation()"
    ></app-auto-element>
  </div>

  <ng-container *ngIf="editorContext">
    <ng-container *appViewEditorViewportOverlay="{ frame: layer.frame, translate: translate }">
      <app-view-editor-bounds
        class="element-layer__bounds"
        [class.element-layer__bounds_hover]="hover$ | async"
        [class.element-layer__bounds_active]="
          (customizing$ | async) &&
          !(editorContext.movingLayer$ | async) &&
          !(editorContext.customizingGradient$ | async)
        "
        [frame]="layer.frame"
        [translate]="translate"
        [snapFrame]="true"
        [snapFrameContainer]="container"
        [snapFrameExceptLayers]="[layer]"
        [constrainProportions]="!!layer.constrainProportion"
        [active]="customizing$ | async"
        [resizeHorizontal]="(customizing$ | async) && !(customizingMultiple$ | async) && !layer.widthFluid"
        [resizeVertical]="(customizing$ | async) && !(customizingMultiple$ | async) && !layer.heightFluid"
        (resizeStarted)="editorContext.resizingLayer$.next(layer)"
        (resizeFinished)="editorContext.resizingLayer$.next(undefined)"
        (updateFrame)="onFrameUpdate($event)"
      ></app-view-editor-bounds>
    </ng-container>
  </ng-container>
</div>
