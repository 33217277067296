var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import toPairs from 'lodash/toPairs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { of } from 'rxjs';
import { delayWhen, filter, map, switchMap, tap } from 'rxjs/operators';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { CustomView, customViewDataToInstance, CustomViewService, CustomViewSource, CustomViewsStore, CustomViewType } from '@modules/custom-views';
import { CustomizeService, ElementType, ViewContext } from '@modules/customize';
import { Input as FieldInput, InputValueType } from '@modules/fields';
import { IDEController } from '@modules/ide-components';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { CustomViewTemplateCounterStore, Frame, View } from '@modules/views';
import { CustomViewTemplatesController, ImportFigmaNodeController, ImportSketchFileController, ViewEditorController } from '@modules/views-components';
var ChangeCustomizeBarCustomViewsComponent = /** @class */ (function () {
    function ChangeCustomizeBarCustomViewsComponent(currentProjectStore, currentEnvironmentStore, viewEditorController, ideController, customViewService, customViewsStore, customViewTemplateCounterStore, customViewTemplatesController, importFigmaNodeController, importSketchFileController, customizeService, cd, analyticsService) {
        this.currentProjectStore = currentProjectStore;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.viewEditorController = viewEditorController;
        this.ideController = ideController;
        this.customViewService = customViewService;
        this.customViewsStore = customViewsStore;
        this.customViewTemplateCounterStore = customViewTemplateCounterStore;
        this.customViewTemplatesController = customViewTemplatesController;
        this.importFigmaNodeController = importFigmaNodeController;
        this.importSketchFileController = importSketchFileController;
        this.customizeService = customizeService;
        this.cd = cd;
        this.analyticsService = analyticsService;
        this.customViewsShared = [];
        this.createLoading = false;
    }
    ChangeCustomizeBarCustomViewsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.customViewTemplateCounterStore
            .getApproxFirst$()
            .pipe(untilDestroyed(this))
            .subscribe(function (value) {
            _this.templatesApprox = value;
            _this.cd.markForCheck();
        });
        this.customViewsStore
            .get()
            .pipe(untilDestroyed(this))
            .subscribe(function (customViews) {
            _this.customViewsShared = customViews.filter(function (item) { return item.shared; });
            _this.cd.markForCheck();
        });
    };
    ChangeCustomizeBarCustomViewsComponent.prototype.ngOnDestroy = function () { };
    ChangeCustomizeBarCustomViewsComponent.prototype.isDroppable = function (item) {
        return false;
    };
    ChangeCustomizeBarCustomViewsComponent.prototype.getCustomViewDefaults = function (element) {
        return {
            uniqueName: CustomView.generateUniqueName(),
            pageUid: this.context && this.context.viewSettings ? this.context.viewSettings.uid : undefined,
            elementUid: element ? element.uid : undefined
        };
    };
    ChangeCustomizeBarCustomViewsComponent.prototype.createCustomView = function (data) {
        var _this = this;
        var instance = customViewDataToInstance(data);
        var defaults = this.getCustomViewDefaults();
        instance.uniqueName = defaults.uniqueName;
        instance.viewType = CustomViewType.Component;
        instance.source = data.source;
        instance.pageUid = defaults.pageUid;
        instance.elementUid = defaults.elementUid;
        if (data.source == CustomViewSource.CustomElement) {
            instance.filesIde = true;
        }
        var fields = ['unique_name', 'name', 'view_type', 'source', 'view', 'html', 'params'];
        return this.customViewService
            .create(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, instance, { draft: true, fields: fields })
            .pipe(tap(function (customView) { return _this.customViewsStore.updateOrAddItem(customView); }));
    };
    ChangeCustomizeBarCustomViewsComponent.prototype.createdViewItem = function (data, template) {
        var _this = this;
        return this.createCustomView(data).pipe(map(function (customView) { return _this.createBarItem(customView, template); }));
    };
    ChangeCustomizeBarCustomViewsComponent.prototype.getCustomViewTestParameters = function (testParameters) {
        return toPairs(testParameters).map(function (_a) {
            var name = _a[0], value = _a[1];
            var result = new FieldInput();
            result.path = [name];
            result.valueType = InputValueType.StaticValue;
            result.staticValue = value;
            return result;
        });
    };
    ChangeCustomizeBarCustomViewsComponent.prototype.createBarItem = function (customView, template) {
        var inputs = template && template.view
            ? this.getCustomViewTestParameters(template.testParameters)
            : this.getCustomViewTestParameters(customView.testParameters);
        return {
            title: customView.name,
            image: 'canvas',
            purpleGradient: true,
            action: 'Add Custom',
            type: ElementType.Custom,
            defaultParams: __assign({}, (customView.view && {
                width: customView.view.frame.width + 20 * 2,
                height: customView.view.frame.height + 15 * 2
            }), { source: customView.source, custom_view: customView.uniqueName, parameters: customView.parameters.map(function (item) { return item.serialize(); }), inputs: inputs.map(function (item) { return item.serialize(); }) })
        };
    };
    ChangeCustomizeBarCustomViewsComponent.prototype.importFigmaNode = function () {
        var _this = this;
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.ViewEditor.ImportFigmaOpened, {
            Source: this.analyticsSource
        });
        this.importFigmaNodeController
            .importNode()
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            if (result.view) {
                _this.createCustomViewWithViewEditor(result.view);
                _this.analyticsService.sendSimpleEvent(AnalyticsEvent.ViewEditor.ImportFigmaApplied, {
                    Source: _this.analyticsSource
                });
            }
            else if (result.cancelled) {
                _this.analyticsService.sendSimpleEvent(AnalyticsEvent.ViewEditor.ImportFigmaCancelled, {
                    Source: _this.analyticsSource
                });
            }
        });
    };
    ChangeCustomizeBarCustomViewsComponent.prototype.importSketchFile = function () {
        var _this = this;
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.ViewEditor.ImportSketchOpened, {
            Source: this.analyticsSource
        });
        this.importSketchFileController
            .importFile()
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            if (result.view) {
                _this.createCustomViewWithViewEditor(result.view);
                _this.analyticsService.sendSimpleEvent(AnalyticsEvent.ViewEditor.ImportSketchApplied, {
                    Source: _this.analyticsSource
                });
            }
            else if (result.cancelled) {
                _this.analyticsService.sendSimpleEvent(AnalyticsEvent.ViewEditor.ImportSketchCancelled, {
                    Source: _this.analyticsSource
                });
            }
        });
    };
    ChangeCustomizeBarCustomViewsComponent.prototype.createSharedView = function (customView) {
        var item = this.createBarItem(customView);
        this.customizeService.createElement(item);
    };
    ChangeCustomizeBarCustomViewsComponent.prototype.createCustomViewWithViewEditor = function (view) {
        var _this = this;
        if (!view) {
            view = new View();
            view.generateId();
            view.frame = new Frame({ width: 300, height: 240 });
        }
        return this.customViewsStore
            .generateName()
            .pipe(switchMap(function (name) {
            return _this.viewEditorController.open({
                create: true,
                data: {
                    source: CustomViewSource.View,
                    view: view,
                    name: name
                },
                submitLabel: 'Create component',
                analyticsSource: _this.analyticsSource
            });
        }), filter(function (result) { return !result.cancelled; }), switchMap(function (result) {
            if (result.data) {
                _this.createLoading = true;
                _this.cd.markForCheck();
                return _this.createdViewItem(result.data);
            }
            else if (result.useShared) {
                return of(_this.createBarItem(result.useShared));
            }
            else {
                return of(undefined);
            }
        }), untilDestroyed(this))
            .subscribe(function (item) {
            _this.createLoading = false;
            _this.cd.markForCheck();
            if (item) {
                _this.customizeService.createElement(item);
            }
        }, function () {
            _this.createLoading = false;
            _this.cd.markForCheck();
        });
    };
    ChangeCustomizeBarCustomViewsComponent.prototype.createCustomViewWithIDE = function () {
        var _this = this;
        this.createLoading = true;
        this.cd.markForCheck();
        return this.customViewsStore
            .generateName()
            .pipe(switchMap(function (name) { return _this.createdViewItem({ source: CustomViewSource.CustomElement, name: name }); }), delayWhen(function (item) {
            return _this.ideController.open({
                projectName: _this.currentProjectStore.instance.uniqueName,
                environmentName: _this.currentEnvironmentStore.instance.uniqueName,
                customViewName: item.defaultParams['custom_view']
            });
        }), untilDestroyed(this))
            .subscribe(function (item) {
            _this.createLoading = false;
            _this.cd.markForCheck();
            if (item) {
                _this.customizeService.createElement(item);
            }
        }, function () {
            _this.createLoading = false;
            _this.cd.markForCheck();
        });
    };
    ChangeCustomizeBarCustomViewsComponent.prototype.openCustomViewTemplates = function () {
        var _this = this;
        this.customViewTemplatesController
            .chooseTemplate({
            viewCreateEnabled: true,
            analyticsSource: this.analyticsSource
        })
            .pipe(switchMap(function (result) {
            if (result.data) {
                return _this.createdViewItem(result.data);
            }
            else if (result.useShared) {
                return of(_this.createBarItem(result.useShared));
            }
            else {
                return of(undefined);
            }
        }), untilDestroyed(this))
            .subscribe(function (item) {
            if (item) {
                _this.customizeService.createElement(item);
            }
        });
    };
    return ChangeCustomizeBarCustomViewsComponent;
}());
export { ChangeCustomizeBarCustomViewsComponent };
