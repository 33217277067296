import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit
} from '@angular/core';
import pickBy from 'lodash/pickBy';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { CustomView, CustomViewsStore } from '@modules/custom-views';
import { applyParamInputs$, NOT_SET_VALUE } from '@modules/fields';
import { CustomMenuItem, MenuItemActionService } from '@modules/menu';
import { View } from '@modules/views';
import { isSet, TypedChanges } from '@shared';

import { BaseMenuItemComponent } from '../base-menu-item/base-menu-item.component';

@Component({
  selector: 'app-custom-menu-item',
  templateUrl: './custom-menu-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomMenuItemComponent extends BaseMenuItemComponent implements OnInit, OnDestroy, OnChanges {
  @Input() menuItem: CustomMenuItem;

  menuItem$ = new BehaviorSubject<CustomMenuItem>(undefined);
  customView: CustomView;
  view: View;
  viewParams$: Observable<Object> = of({});

  constructor(
    private menuItemActionService: MenuItemActionService,
    private customViewsStore: CustomViewsStore,
    private cd: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    this.menuItem$.next(this.menuItem);
    this.initView();
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<CustomMenuItemComponent>): void {
    if (changes.menuItem && !changes.menuItem.firstChange) {
      this.menuItem$.next(this.menuItem);
    }
  }

  initView() {
    this.menuItem$
      .pipe(
        switchMap(menuItem => {
          if (!menuItem || !isSet(menuItem.customView)) {
            return of(undefined);
          }

          return this.customViewsStore.getDetail(menuItem.customView);
        }),
        untilDestroyed(this)
      )
      .subscribe(customView => {
        this.customView = customView;
        this.view = customView ? customView.view : undefined;
        this.cd.markForCheck();
        this.updateViewParams(customView);
      });
  }

  updateViewParams(customView?: CustomView) {
    if (!customView) {
      this.viewParams$ = of({});
      this.cd.markForCheck();
      return;
    }

    this.viewParams$ = applyParamInputs$({}, this.menuItem.inputs, {
      context: this.context,
      parameters: customView.parameters
    }).pipe(
      map(params => {
        return <Object>pickBy(params, v => v !== NOT_SET_VALUE);
      })
    );
  }
}
