import { ElementRef, EventEmitter, OnChanges, OnDestroy, OnInit } from '@angular/core';
import isArray from 'lodash/isArray';
import isPlainObject from 'lodash/isPlainObject';
import toPairs from 'lodash/toPairs';
import { isSet, removeChildren } from '@shared';
var CustomElementDirective = /** @class */ (function () {
    function CustomElementDirective(el) {
        this.el = el;
        this.parameters = [];
        this.inputs = {};
        this.actions = [];
        this.actionEmitted = new EventEmitter();
    }
    CustomElementDirective.prototype.ngOnInit = function () {
        this.createElement();
        this.updateElementInputs();
        this.updateElementAction();
    };
    CustomElementDirective.prototype.ngOnDestroy = function () {
        this.destroyElement();
    };
    CustomElementDirective.prototype.ngOnChanges = function (changes) {
        var recreatedElement = false;
        if (changes.tagName && !changes.tagName.firstChange) {
            this.destroyElement();
            this.createElement();
            recreatedElement = true;
        }
        if (recreatedElement || (changes.inputs && !changes.inputs.firstChange)) {
            this.updateElementInputs();
        }
        if (recreatedElement || (changes.actions && !changes.actions.firstChange)) {
            this.updateElementAction();
        }
    };
    CustomElementDirective.prototype.createElement = function () {
        if (!isSet(this.tagName)) {
            return;
        }
        this.element = document.createElement(this.tagName);
        this.el.nativeElement.appendChild(this.element);
    };
    CustomElementDirective.prototype.destroyElement = function () {
        if (!this.element) {
            return;
        }
        if (this.actionListener) {
            this.element.removeEventListener('jet_action', this.actionListener);
            this.actionListener = undefined;
        }
        removeChildren(this.el.nativeElement);
        this.element = undefined;
    };
    CustomElementDirective.prototype.updateElementInputs = function () {
        var _this = this;
        if (!this.element) {
            return;
        }
        this.parameters.forEach(function (parameter) {
            var value = _this.inputs[parameter.name];
            if (parameter.fieldDescription && parameter.fieldDescription.valueToStr) {
                value = parameter.fieldDescription.valueToStr(_this.inputs[parameter.name], { noTruncate: true });
            }
            _this.setElementInputAttribute(parameter.name, value);
        });
        // Backward compatibility
        toPairs(this.inputs)
            .filter(function (_a) {
            var key = _a[0];
            return !_this.parameters.some(function (item) { return item.name == key; });
        })
            .forEach(function (_a) {
            var key = _a[0], value = _a[1];
            return _this.setElementInputAttribute(key, value);
        });
    };
    CustomElementDirective.prototype.setElementInputAttribute = function (name, value) {
        if (value === undefined) {
            this.element.removeAttribute(name);
        }
        else if (isPlainObject(value) || isArray(value)) {
            this.element.setAttribute(name, JSON.stringify(value));
        }
        else {
            this.element.setAttribute(name, String(value));
        }
    };
    CustomElementDirective.prototype.updateElementAction = function () {
        var _this = this;
        if (!this.element) {
            return;
        }
        if (this.actionListener) {
            return;
        }
        var listener = function (event) {
            if (event.detail) {
                _this.actionEmitted.emit({ name: event.detail.name, data: event.detail.data });
            }
        };
        this.element.addEventListener('jet_action', listener);
        this.actionListener = listener;
    };
    return CustomElementDirective;
}());
export { CustomElementDirective };
