import pickBy from 'lodash/pickBy';

import { ActionOutput, ParameterField } from '@modules/fields';
import { View } from '@modules/views';
import { generateAlphanumeric, isSet } from '@shared';

export enum CustomViewType {
  Common = 'common',
  ModelDescription = 'model_desc',
  Model = 'model',
  Component = 'component',
  ListItem = 'list_item',
  ItemColumn = 'item_column',
  MenuItem = 'menu_item'
}

export enum CustomViewSource {
  View = 'view',
  HTML = 'html',
  CustomElement = 'custom_element'
}

export enum CustomViewFileType {
  JS,
  CSS,
  Unknown
}

export interface CustomViewFile {
  name: string;
  disabled: boolean;
  fileType: CustomViewFileType;
  checked: boolean;
}

export const defaultCustomViewHtml = `<label for="file">
    Downloading progress:
</label>
<br>
<progress id="file" value="32" max="100">
    32%
</progress>`;

export function isCustomViewConfigured(customView: CustomView, source: CustomViewSource): boolean {
  if (source == CustomViewSource.View) {
    return !!customView.view;
  } else if (source == CustomViewSource.HTML) {
    return true;
  } else if (source == CustomViewSource.CustomElement) {
    return isSet(customView.tagNameEffective) && customView.filesJs.length > 0;
  } else {
    return false;
  }
}

export class CustomView {
  public uniqueName: string;
  public uid: string;
  public name: string;
  public viewType: CustomViewType;
  public source: CustomViewSource = CustomViewSource.View;
  public dist: File | string;
  public distBaseAbsoluteUrl: string;
  public html: string;
  public view: View;
  public params = {};
  public dateAdd: string;
  public buildId: string;
  public tagName: string;
  public baseTagName: string;
  public filesIde = false;
  public filesJs: CustomViewFile[] = [];
  public filesCss: CustomViewFile[] = [];
  public pageUid: string;
  public elementUid: string;
  public columnUniqueName: string;
  public menuItemUid: string;
  public parameters: ParameterField[] = [];
  public actions: ActionOutput[] = [];
  public testParameters: Record<string, unknown> = {};
  public shared = false;
  public draft = false;
  public deleted = false;

  static generateUniqueName(): string {
    return generateAlphanumeric(8, { letterFirst: true });
  }

  deserialize(data: Object): CustomView {
    this.uniqueName = data['unique_name'];
    this.uid = data['uid'];
    this.viewType = data['view_type'];
    this.source = data['source'];
    this.dist = data['dist'];
    this.distBaseAbsoluteUrl = data['dist_base_absolute_url'];
    this.html = data['html'];
    this.dateAdd = data['date_add'];

    if (data['view']) {
      this.view = new View().deserialize(data['view']);
    }

    this.name = data['name'];
    // Backward compatibility
    if (!isSet(this.name) && data['view'] && isSet(data['view']['name'])) {
      this.name = data['view']['name'];
    }

    if (isSet(data['params'])) {
      this.params = JSON.parse(data['params']);
    }

    if (this.params['build_id']) {
      this.buildId = this.params['build_id'];
    }

    if (this.params['tag_name']) {
      this.tagName = this.params['tag_name'];
    }

    if (this.params['base_tag_name']) {
      this.baseTagName = this.params['base_tag_name'];
    }

    if (this.params['files_ide'] !== undefined) {
      this.filesIde = this.params['files_ide'];
    }

    if (this.params['files']) {
      if (this.params['files']['js']) {
        this.filesJs = this.params['files']['js'];
      }

      if (this.params['files']['css']) {
        this.filesCss = this.params['files']['css'];
      }
    }

    if (this.params['page_uid']) {
      this.pageUid = this.params['page_uid'];
    }

    if (this.params['element_uid']) {
      this.elementUid = this.params['element_uid'];
    }

    if (this.params['column_unique_name']) {
      this.columnUniqueName = this.params['column_unique_name'];
    }

    if (this.params['menu_item_uid']) {
      this.menuItemUid = this.params['menu_item_uid'];
    }

    if (this.params['parameters']) {
      this.parameters = this.params['parameters'].map(status => new ParameterField().deserialize(status));
    } else if (data['view'] && data['view']['parameters']) {
      // Backward compatibility
      this.parameters = data['view']['parameters'].map(status => new ParameterField().deserialize(status));
    }

    if (this.params['actions']) {
      this.actions = this.params['actions'].map(item => new ActionOutput().deserialize(item));
    } else if (data['view'] && data['view']['actions']) {
      // Backward compatibility
      this.actions = data['view']['actions'].map(item => new ActionOutput().deserialize(item));
    }

    if (this.params['test_parameters']) {
      this.testParameters = this.params['test_parameters'];
    } else if (data['view'] && data['view']['test_parameters']) {
      // Backward compatibility
      this.testParameters = data['view']['test_parameters'];
    }

    if (data['shared'] !== undefined) {
      this.shared = data['shared'];
    }

    if (data['draft'] !== undefined) {
      this.draft = data['draft'];
    }

    if (data['deleted'] !== undefined) {
      this.deleted = data['deleted'];
    }

    return this;
  }

  serialize(fields?: string[]): Object {
    this.params = {};
    this.params['build_id'] = this.buildId;
    this.params['tag_name'] = this.tagName;
    this.params['base_tag_name'] = this.baseTagName;
    this.params['files_ide'] = this.filesIde;
    this.params['files'] = {
      js: this.filesJs,
      css: this.filesCss
    };
    this.params['page_uid'] = this.pageUid;
    this.params['element_uid'] = this.elementUid;
    this.params['column_unique_name'] = this.columnUniqueName;
    this.params['menu_item_uid'] = this.menuItemUid;
    this.params['parameters'] = this.parameters.map(item => item.serialize());
    this.params['actions'] = this.actions.map(item => item.serialize());
    this.params['test_parameters'] = this.testParameters;

    let data: Object = {
      unique_name: this.uniqueName,
      name: this.name,
      view_type: this.viewType,
      source: this.source,
      dist: this.dist || '',
      html: this.html || '',
      view: this.view ? this.view.serialize() : null,
      params: JSON.stringify(this.params),
      shared: this.shared,
      draft: this.draft,
      deleted: this.deleted
    };
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }

  commonLink() {
    return ['flex', this.uniqueName];
  }

  modelDescriptionLink(modelId: string) {
    return ['models', modelId, 'flex', this.uniqueName];
  }

  modelLink(modelId: string, id: string) {
    return ['models', modelId, id, 'flex', this.uniqueName];
  }

  get link() {
    return this.viewType == CustomViewType.Common ? ['flex', this.uniqueName] : undefined;
  }

  get changeLink() {
    return ['flexviews_edit', this.uniqueName];
  }

  getViewTypeStr(options: { page?: string; element?: string } = {}): string {
    if (this.viewType == CustomViewType.Common) {
      return `Standalone (${this.uniqueName})`;
    } else if (this.viewType == CustomViewType.ModelDescription) {
      return `Collection (${this.uniqueName})`;
    } else if (this.viewType == CustomViewType.Model) {
      return `Record (${this.uniqueName})`;
    } else if (this.viewType == CustomViewType.Component) {
      return [
        'Page component',
        ...(options.page ? [options.page] : []),
        ...(options.element ? [options.element] : []),
        this.uniqueName
      ].join(' - ');
    } else if (this.viewType == CustomViewType.ListItem) {
      return [
        'List card',
        ...(options.page ? [options.page] : []),
        ...(options.element ? [options.element] : []),
        this.uniqueName
      ].join(' - ');
    }
  }

  get defaultTagName(): string {
    return `jet-custom-${this.uid}`;
  }

  get tagNameEffective(): string {
    return isSet(this.tagName) ? this.tagName : this.defaultTagName;
  }

  isConfigured(): boolean {
    return isCustomViewConfigured(this, this.source);
  }
}
