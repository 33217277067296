/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../node_modules/@angular/material/menu/typings/index.ngfactory";
import * as i2 from "@angular/material/menu";
import * as i3 from "@angular/common";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "@angular/cdk/portal";
import * as i6 from "@angular/forms";
import * as i7 from "../../../../shared/directives/element/element.directive";
import * as i8 from "../../../../core/services/document/document.service";
import * as i9 from "@angular/cdk/overlay";
import * as i10 from "@angular/cdk/bidi";
import * as i11 from "../../../../shared/pipes/safe-style/safe-style.pipe";
import * as i12 from "@angular/platform-browser";
import * as i13 from "../../../../shared/pipes/is-set/is-set.pipe";
import * as i14 from "./custom-view-dropdown-item.component";
import * as i15 from "../../data/injection-tokens";
import * as i16 from "../../../projects/stores/current-project.store";
import * as i17 from "../../../projects/stores/current-environment.store";
import * as i18 from "../../../custom-views/services/custom-view/custom-view.service";
import * as i19 from "../../../custom-views/stores/custom-views.store";
import * as i20 from "../../../../common/dialogs/services/dialog/dialog.service";
import * as i21 from "../../../../common/notifications/services/notification/notification.service";
var styles_CustomViewDropdownItemComponent = [];
var RenderType_CustomViewDropdownItemComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CustomViewDropdownItemComponent, data: {} });
export { RenderType_CustomViewDropdownItemComponent as RenderType_CustomViewDropdownItemComponent };
function View_CustomViewDropdownItemComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "button", [["class", "mat-menu-item-odd mat-menu-item"], ["mat-menu-item", ""]], [[1, "role", 0], [2, "mat-menu-item-highlighted", null], [2, "mat-menu-item-submenu-trigger", null], [1, "tabindex", 0], [1, "aria-disabled", 0], [1, "disabled", 0]], [[null, "click"], [null, "mouseenter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1)._checkDisabled($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i0.ɵnov(_v, 1)._handleMouseEnter() !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (_co.startEditing() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i1.View_MatMenuItem_0, i1.RenderType_MatMenuItem)), i0.ɵdid(1, 180224, [[2, 4]], 0, i2.MatMenuItem, [i0.ElementRef, i3.DOCUMENT, i4.FocusMonitor, [2, i2.ɵf24]], { disableRipple: [0, "disableRipple"] }, null), (_l()(), i0.ɵeld(2, 0, null, 0, 0, "span", [["class", "choose-items-item__dropdown-icon icon-edit"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, 0, [" Rename component "])), (_l()(), i0.ɵeld(4, 0, null, null, 3, "button", [["class", "mat-menu-item-odd mat-menu-item-red mat-menu-item"], ["mat-menu-item", ""]], [[1, "role", 0], [2, "mat-menu-item-highlighted", null], [2, "mat-menu-item-submenu-trigger", null], [1, "tabindex", 0], [1, "aria-disabled", 0], [1, "disabled", 0]], [[null, "click"], [null, "mouseenter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 5)._checkDisabled($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i0.ɵnov(_v, 5)._handleMouseEnter() !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (_co.delete() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i1.View_MatMenuItem_0, i1.RenderType_MatMenuItem)), i0.ɵdid(5, 180224, [[2, 4]], 0, i2.MatMenuItem, [i0.ElementRef, i3.DOCUMENT, i4.FocusMonitor, [2, i2.ɵf24]], { disableRipple: [0, "disableRipple"] }, null), (_l()(), i0.ɵeld(6, 0, null, 0, 0, "span", [["class", "choose-items-item__dropdown-icon choose-items-item__dropdown-icon_red icon-bin"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, 0, [" Remove component "]))], function (_ck, _v) { var currVal_6 = true; _ck(_v, 1, 0, currVal_6); var currVal_13 = true; _ck(_v, 5, 0, currVal_13); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).role; var currVal_1 = i0.ɵnov(_v, 1)._highlighted; var currVal_2 = i0.ɵnov(_v, 1)._triggersSubmenu; var currVal_3 = i0.ɵnov(_v, 1)._getTabIndex(); var currVal_4 = i0.ɵnov(_v, 1).disabled.toString(); var currVal_5 = (i0.ɵnov(_v, 1).disabled || null); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_7 = i0.ɵnov(_v, 5).role; var currVal_8 = i0.ɵnov(_v, 5)._highlighted; var currVal_9 = i0.ɵnov(_v, 5)._triggersSubmenu; var currVal_10 = i0.ɵnov(_v, 5)._getTabIndex(); var currVal_11 = i0.ɵnov(_v, 5).disabled.toString(); var currVal_12 = (i0.ɵnov(_v, 5).disabled || null); _ck(_v, 4, 0, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12); }); }
function View_CustomViewDropdownItemComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "custom-view-dropdown-item__image-inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "div", [["class", "custom-view-dropdown-item__image-stub"]], null, null, null, null, null))], null, null); }
function View_CustomViewDropdownItemComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(0, null, null, 0))], null, null); }
function View_CustomViewDropdownItemComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomViewDropdownItemComponent_6)), i0.ɵdid(1, 212992, null, 0, i5.CdkPortalOutlet, [i0.ComponentFactoryResolver, i0.ViewContainerRef], { portal: [0, "portal"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.viewPortal; _ck(_v, 1, 0, currVal_0); }, null); }
function View_CustomViewDropdownItemComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [["class", "custom-view-dropdown-item__image-stub"]], null, null, null, null, null))], null, null); }
function View_CustomViewDropdownItemComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "custom-view-dropdown-item__image-inner custom-view-dropdown-item__image-inner_absolute"]], [[4, "width", "px"], [4, "height", "px"], [4, "transform", null]], null, null, null, null)), i0.ɵppd(1, 1), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomViewDropdownItemComponent_5)), i0.ɵdid(3, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomViewDropdownItemComponent_7)), i0.ɵdid(5, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = (!_co.viewLoading && _co.viewPortal); _ck(_v, 3, 0, currVal_3); var currVal_4 = _co.viewLoading; _ck(_v, 5, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.item.view.frame.width; var currVal_1 = _co.item.view.frame.height; var currVal_2 = (_co.viewScale ? i0.ɵunv(_v, 0, 2, _ck(_v, 1, 0, i0.ɵnov(_v.parent.parent, 0), (("translate(-50%, -50%) scale(" + _co.viewScale) + ")"))) : null); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_CustomViewDropdownItemComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "custom-view-dropdown-item__image-inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [["class", "custom-view-dropdown-item__image-tag"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "div", [["class", "custom-view-dropdown-item__image-tag-name"]], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", "div", ""])), (_l()(), i0.ɵeld(4, 0, null, null, 1, "div", [["class", "custom-view-dropdown-item__image-tag-subtitle"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["HTML"]))], null, function (_ck, _v) { var currVal_0 = "<"; var currVal_1 = ">"; _ck(_v, 3, 0, currVal_0, currVal_1); }); }
function View_CustomViewDropdownItemComponent_9(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "custom-view-dropdown-item__image-inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [["class", "custom-view-dropdown-item__image-tag"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "div", [["class", "custom-view-dropdown-item__image-tag-name"]], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", "jet-custom", ""])), (_l()(), i0.ɵeld(4, 0, null, null, 1, "div", [["class", "custom-view-dropdown-item__image-tag-subtitle"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["JavaScript"]))], null, function (_ck, _v) { var currVal_0 = "<"; var currVal_1 = ">"; _ck(_v, 3, 0, currVal_0, currVal_1); }); }
function View_CustomViewDropdownItemComponent_10(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "custom-view-dropdown-item__image-label"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Selected "]))], null, null); }
function View_CustomViewDropdownItemComponent_12(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.item.name; _ck(_v, 1, 0, currVal_0); }); }
function View_CustomViewDropdownItemComponent_13(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["No name"]))], null, null); }
function View_CustomViewDropdownItemComponent_11(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "custom-view-dropdown-item__title"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_CustomViewDropdownItemComponent_12)), i0.ɵdid(2, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵppd(3, 1), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_CustomViewDropdownItemComponent_13)), i0.ɵdid(5, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵppd(6, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i0.ɵnov(_v.parent.parent, 1), _co.item.name)); _ck(_v, 2, 0, currVal_0); var currVal_1 = !i0.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i0.ɵnov(_v.parent.parent, 1), _co.item.name)); _ck(_v, 5, 0, currVal_1); }, null); }
function View_CustomViewDropdownItemComponent_14(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, [[1, 0], ["input", 1]], null, 5, "input", [["class", "custom-view-dropdown-item__title-input"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "blur"], [null, "keydown"], [null, "keyup"], [null, "input"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 1).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 1)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 1)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.nameValue = $event) !== false);
        ad = (pd_4 && ad);
    } if (("blur" === en)) {
        var pd_5 = (_co.finishEditing() !== false);
        ad = (pd_5 && ad);
    } if (("keydown" === en)) {
        var pd_6 = ($event.stopPropagation() !== false);
        ad = (pd_6 && ad);
    } if (("keyup" === en)) {
        var pd_7 = (_co.onKeyUp($event) !== false);
        ad = (pd_7 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 16384, null, 0, i6.DefaultValueAccessor, [i0.Renderer2, i0.ElementRef, [2, i6.COMPOSITION_BUFFER_MODE]], null, null), i0.ɵprd(1024, null, i6.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i6.DefaultValueAccessor]), i0.ɵdid(3, 671744, null, 0, i6.NgModel, [[8, null], [8, null], [8, null], [6, i6.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i6.NgControl, null, [i6.NgModel]), i0.ɵdid(5, 16384, null, 0, i6.NgControlStatus, [[4, i6.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.nameValue; _ck(_v, 3, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 5).ngClassUntouched; var currVal_1 = i0.ɵnov(_v, 5).ngClassTouched; var currVal_2 = i0.ɵnov(_v, 5).ngClassPristine; var currVal_3 = i0.ɵnov(_v, 5).ngClassDirty; var currVal_4 = i0.ɵnov(_v, 5).ngClassValid; var currVal_5 = i0.ɵnov(_v, 5).ngClassInvalid; var currVal_6 = i0.ɵnov(_v, 5).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
function View_CustomViewDropdownItemComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 28, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 6, "mat-menu", [], null, null, null, i1.View_MatMenu_0, i1.RenderType_MatMenu)), i0.ɵprd(6144, null, i2.ɵf24, null, [i2.MatMenu]), i0.ɵdid(3, 1294336, [["dropdown", 4]], 2, i2.MatMenu, [i0.ElementRef, i0.NgZone, i2.MAT_MENU_DEFAULT_OPTIONS], null, null), i0.ɵqud(603979776, 2, { items: 1 }), i0.ɵqud(335544320, 3, { lazyContent: 0 }), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_CustomViewDropdownItemComponent_2)), i0.ɵdid(7, 147456, [[3, 4]], 0, i2.MatMenuContent, [i0.TemplateRef, i0.ComponentFactoryResolver, i0.ApplicationRef, i0.Injector, i0.ViewContainerRef, i3.DOCUMENT], null, null), (_l()(), i0.ɵeld(8, 0, null, null, 20, "div", [["appElement", ""], ["class", "custom-view-dropdown-item"]], [[2, "custom-view-dropdown-item_active", null], [2, "custom-view-dropdown-item_interactive", null]], [[null, "appElementFirstVisible"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("appElementFirstVisible" === en)) {
        var pd_0 = (_co.firstVisible$.next(true) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.onClick() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(9, 8601600, null, 0, i7.ElementDirective, [i0.ElementRef, i8.DocumentService], { appElementDetectVisible: [0, "appElementDetectVisible"] }, { appElementFirstVisible: "appElementFirstVisible" }), i0.ɵpod(10, { intervalCheck: 0 }), (_l()(), i0.ɵeld(11, 0, null, null, 12, "div", [["class", "custom-view-dropdown-item__image"]], [[2, "custom-view-dropdown-item__image_disabled", null], [4, "width", "px"], [4, "height", "px"]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomViewDropdownItemComponent_3)), i0.ɵdid(13, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomViewDropdownItemComponent_4)), i0.ɵdid(15, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomViewDropdownItemComponent_8)), i0.ɵdid(17, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomViewDropdownItemComponent_9)), i0.ɵdid(19, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomViewDropdownItemComponent_10)), i0.ɵdid(21, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(22, 16777216, null, null, 1, "div", [["aria-haspopup", "true"], ["class", "custom-view-dropdown-item__image-button icon-more"]], [[2, "custom-view-dropdown-item__image-button_active", null], [1, "aria-expanded", 0]], [[null, "click"], [null, "mousedown"], [null, "keydown"]], function (_v, en, $event) { var ad = true; if (("mousedown" === en)) {
        var pd_0 = (i0.ɵnov(_v, 23)._handleMousedown($event) !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i0.ɵnov(_v, 23)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (i0.ɵnov(_v, 23)._handleClick($event) !== false);
        ad = (pd_2 && ad);
    } if (("click" === en)) {
        var pd_3 = ($event.stopPropagation() !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i0.ɵdid(23, 1196032, [["dropdown_trigger", 4]], 0, i2.MatMenuTrigger, [i9.Overlay, i0.ElementRef, i0.ViewContainerRef, i2.MAT_MENU_SCROLL_STRATEGY, [2, i2.MatMenu], [8, null], [2, i10.Directionality], i4.FocusMonitor], { menu: [0, "menu"] }, null), (_l()(), i0.ɵeld(24, 0, null, null, 4, "div", [["class", "custom-view-dropdown-item__info"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomViewDropdownItemComponent_11)), i0.ɵdid(26, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomViewDropdownItemComponent_14)), i0.ɵdid(28, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 3, 0); var currVal_2 = _ck(_v, 10, 0, 600); _ck(_v, 9, 0, currVal_2); var currVal_6 = !_co.item.source; _ck(_v, 13, 0, currVal_6); var currVal_7 = (_co.item.source == _co.sources.View); _ck(_v, 15, 0, currVal_7); var currVal_8 = (_co.item.source == _co.sources.HTML); _ck(_v, 17, 0, currVal_8); var currVal_9 = (_co.item.source == _co.sources.CustomElement); _ck(_v, 19, 0, currVal_9); var currVal_10 = _co.active; _ck(_v, 21, 0, currVal_10); var currVal_13 = i0.ɵnov(_v, 3); _ck(_v, 23, 0, currVal_13); var currVal_14 = !_co.nameEditing; _ck(_v, 26, 0, currVal_14); var currVal_15 = _co.nameEditing; _ck(_v, 28, 0, currVal_15); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.active; var currVal_1 = !_co.nameEditing; _ck(_v, 8, 0, currVal_0, currVal_1); var currVal_3 = _co.nameEditing; var currVal_4 = (_co.thumbWidth + (_co.thumbPadding * 2)); var currVal_5 = (_co.thumbHeight + (_co.thumbPadding * 2)); _ck(_v, 11, 0, currVal_3, currVal_4, currVal_5); var currVal_11 = i0.ɵnov(_v, 23).menuOpen; var currVal_12 = (i0.ɵnov(_v, 23).menuOpen || null); _ck(_v, 22, 0, currVal_11, currVal_12); }); }
function View_CustomViewDropdownItemComponent_16(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "custom-view-dropdown-item__title"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "span", [], [[2, "loading-animation", null]], null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "span", [["class", "stub-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Name"]))], null, function (_ck, _v) { var currVal_0 = true; _ck(_v, 1, 0, currVal_0); }); }
function View_CustomViewDropdownItemComponent_15(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 7, "div", [["class", "custom-view-dropdown-item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 3, "div", [["class", "custom-view-dropdown-item__image"]], [[4, "width", "px"], [4, "height", "px"]], null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["class", "custom-view-dropdown-item__image-inner custom-view-dropdown-item__image-inner_absolute"]], [[4, "width", "px"], [4, "height", "px"], [4, "transform", null]], null, null, null, null)), i0.ɵppd(4, 1), (_l()(), i0.ɵeld(5, 0, null, null, 0, "div", [["class", "custom-view-dropdown-item__image-stub"]], [[2, "loading-animation", null]], null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 2, "div", [["class", "custom-view-dropdown-item__info"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomViewDropdownItemComponent_16)), i0.ɵdid(8, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_6 = !_co.nameEditing; _ck(_v, 8, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.thumbWidth + (_co.thumbPadding * 2)); var currVal_1 = (_co.thumbHeight + (_co.thumbPadding * 2)); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = (_co.item.view ? _co.item.view.frame.width : null); var currVal_3 = (_co.item.view ? _co.item.view.frame.height : null); var currVal_4 = (_co.viewScale ? i0.ɵunv(_v, 3, 2, _ck(_v, 4, 0, i0.ɵnov(_v.parent, 0), (("translate(-50%, -50%) scale(" + _co.viewScale) + ")"))) : null); _ck(_v, 3, 0, currVal_2, currVal_3, currVal_4); var currVal_5 = true; _ck(_v, 5, 0, currVal_5); }); }
export function View_CustomViewDropdownItemComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i11.SafeStylePipe, [i12.DomSanitizer]), i0.ɵpid(0, i13.IsSetPipe, []), i0.ɵqud(671088640, 1, { inputElement: 0 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomViewDropdownItemComponent_1)), i0.ɵdid(4, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomViewDropdownItemComponent_15)), i0.ɵdid(6, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.loadingSave; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.loadingSave; _ck(_v, 6, 0, currVal_1); }, null); }
export function View_CustomViewDropdownItemComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-custom-view-dropdown-item", [], null, null, null, View_CustomViewDropdownItemComponent_0, RenderType_CustomViewDropdownItemComponent)), i0.ɵdid(1, 770048, null, 0, i14.CustomViewDropdownItemComponent, [i15.CUSTOM_VIEW_COMPONENT, i16.CurrentProjectStore, i17.CurrentEnvironmentStore, i18.CustomViewService, i19.CustomViewsStore, i20.DialogService, i21.NotificationService, i0.Injector, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CustomViewDropdownItemComponentNgFactory = i0.ɵccf("app-custom-view-dropdown-item", i14.CustomViewDropdownItemComponent, View_CustomViewDropdownItemComponent_Host_0, { item: "item", active: "active", thumbWidth: "thumbWidth", thumbHeight: "thumbHeight", thumbPadding: "thumbPadding" }, { selectItem: "selectItem" }, []);
export { CustomViewDropdownItemComponentNgFactory as CustomViewDropdownItemComponentNgFactory };
