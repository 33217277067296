import { Injectable, Injector, OnDestroy } from '@angular/core';
import { combineLatest, Observable, of, throwError } from 'rxjs';
import { catchError, delayWhen, map } from 'rxjs/operators';

import { DialogButtonHotkey, DialogButtonType, DialogService } from '@common/dialogs';
import { NotificationService } from '@common/notifications';
import { PopupService } from '@common/popups';
import { ActionStore } from '@modules/action-queries';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ServerRequestError } from '@modules/api';
import { ViewContext, ViewContextElement } from '@modules/customize';
import { ModelDescriptionStore } from '@modules/model-queries';
import { ViewContextTokenProvider } from '@modules/parameters-components';
import {
  CurrentEnvironmentStore,
  CurrentProjectStore,
  getProjectPropertyTypeLabel,
  ProjectGroupStore,
  ProjectProperty,
  ProjectPropertyService,
  ProjectPropertyStore,
  ProjectPropertyType
} from '@modules/projects';
import { capitalize, errorToString } from '@shared';

import { ProjectPropertyEditPopupComponent } from '../../components/project-property-edit-popup/project-property-edit-popup.component';
import { ProjectPropertyEditResult } from '../../components/project-property-edit-popup/project-property-edit-popup.form';

@Injectable()
export class ProjectPropertyEditController implements OnDestroy {
  constructor(
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private projectPropertyService: ProjectPropertyService,
    private projectPropertyStore: ProjectPropertyStore,
    private modelDescriptionStore: ModelDescriptionStore,
    private actionStore: ActionStore,
    private dialogService: DialogService,
    private injector: Injector,
    private popupService: PopupService,
    private notificationService: NotificationService,
    private analyticsService: UniversalAnalyticsService
  ) {}

  ngOnDestroy(): void {}

  createInjector(options: { context?: ViewContext; contextTokenProvider?: ViewContextTokenProvider }): Injector {
    return Injector.create(
      [
        {
          provide: ViewContext,
          useFactory: (currentEnvironmentStore: CurrentEnvironmentStore) => {
            if (options.context) {
              return options.context;
            } else {
              return new ViewContext(currentEnvironmentStore);
            }
          },
          deps: [CurrentEnvironmentStore]
        },
        {
          provide: ViewContextTokenProvider,
          useFactory: (viewContext: ViewContext, projectGroupStore: ProjectGroupStore) => {
            if (options.contextTokenProvider) {
              return options.contextTokenProvider;
            } else {
              return new ViewContextTokenProvider(viewContext, projectGroupStore);
            }
          },
          deps: [ViewContext, ProjectGroupStore]
        }
      ],
      this.injector
    );
  }

  create(options: {
    type: ProjectPropertyType;
    defaultName?: string;
    defaultValueEnabled?: boolean;
    requiredEnabled?: boolean;
    required?: boolean;
    placeholderEnabled?: boolean;
    placeholder?: string;
    pageUid?: string;
    saveLocal?: boolean;
    context?: ViewContext;
    contextElement?: ViewContextElement;
    contextTokenProvider?: ViewContextTokenProvider;
    analyticsSource?: string;
  }): Observable<ProjectPropertyEditResult> {
    const injector = this.createInjector(options);
    const popup = this.popupService.showComponent<ProjectPropertyEditPopupComponent>({
      component: ProjectPropertyEditPopupComponent,
      injector: injector,
      inputs: {
        type: options.type,
        defaultName: options.defaultName,
        defaultValueEnabled: options.defaultValueEnabled,
        requiredEnabled: options.requiredEnabled,
        required: options.required,
        placeholderEnabled: options.placeholderEnabled,
        placeholder: options.placeholder,
        pageUid: options.pageUid,
        saveLocal: options.saveLocal,
        context: options.context,
        contextElement: options.contextElement,
        analyticsSource: options.analyticsSource
      },
      scrollable: true
    });

    return popup.instance.created.asObservable();
  }

  edit(options: {
    property: ProjectProperty;
    defaultValueEnabled?: boolean;
    requiredEnabled?: boolean;
    required?: boolean;
    placeholderEnabled?: boolean;
    placeholder?: string;
    pageUid?: string;
    context?: ViewContext;
    contextElement?: ViewContextElement;
    analyticsSource?: string;
  }): Observable<ProjectPropertyEditResult> {
    const injector = this.createInjector(options);
    const popup = this.popupService.showComponent<ProjectPropertyEditPopupComponent>({
      component: ProjectPropertyEditPopupComponent,
      injector: injector,
      inputs: {
        type: options.property.type,
        property: options.property,
        defaultValueEnabled: options.defaultValueEnabled,
        requiredEnabled: options.requiredEnabled,
        required: options.required,
        placeholderEnabled: options.placeholderEnabled,
        placeholder: options.placeholder,
        context: options.context,
        contextElement: options.contextElement,
        analyticsSource: options.analyticsSource
      },
      scrollable: true
    });

    return popup.instance.updated.asObservable();
  }

  delete(options: { property: ProjectProperty; analyticsSource?: string }): Observable<boolean> {
    this.analyticsService.sendSimpleEvent(AnalyticsEvent.Property.DeleteStarted, {
      Type: options.property.type,
      Source: options.analyticsSource
    });

    const typeLabel = getProjectPropertyTypeLabel(options.property.type);

    return this.dialogService
      .dialog({
        title: 'Deleting',
        description: `Are you sure want to delete ${typeLabel} <strong>${options.property.name}</strong>?`,
        style: 'orange',
        buttons: [
          {
            name: 'cancel',
            label: 'Cancel',
            type: DialogButtonType.Default,
            hotkey: DialogButtonHotkey.Cancel
          },
          {
            name: 'ok',
            label: `Delete ${typeLabel}`,
            type: DialogButtonType.Danger,
            hotkey: DialogButtonHotkey.Submit,
            executor: () => this.deleteProcess(options)
          }
        ]
      })
      .pipe(
        map(result => {
          const submit = result.button == 'ok';
          if (!submit) {
            this.analyticsService.sendSimpleEvent(AnalyticsEvent.Property.DeleteCancelled, {
              Type: options.property.type,
              Source: options.analyticsSource
            });
          }

          return submit;
        })
      );
  }

  deleteProcess(options: { property: ProjectProperty; analyticsSource?: string }): Observable<boolean> {
    const typeLabel = getProjectPropertyTypeLabel(options.property.type);

    return this.projectPropertyService
      .delete(
        this.currentProjectStore.instance.uniqueName,
        this.currentEnvironmentStore.instance.uniqueName,
        options.property
      )
      .pipe(
        delayWhen(() => this.projectPropertyStore.getFirst(true)),
        delayWhen(() => {
          if ([ProjectPropertyType.User, ProjectPropertyType.Group].includes(options.property.type)) {
            return combineLatest(this.modelDescriptionStore.getFirst(true), this.actionStore.getFirst(true));
          } else {
            return of(undefined);
          }
        }),
        map(() => {
          this.notificationService.success(
            'Deleted',
            `${capitalize(typeLabel)} <strong>${options.property.name}</strong> was successfully deleted`
          );

          this.analyticsService.sendSimpleEvent(AnalyticsEvent.Property.Deleted, {
            Type: options.property.type,
            Source: options.analyticsSource
          });

          return true;
        }),
        catchError(error => {
          if (error instanceof ServerRequestError && error.errors.length) {
            this.notificationService.error('Delete Failed', error.errors[0]);
          } else {
            this.notificationService.error('Delete Failed', errorToString(error));
          }

          return throwError(error);
        })
      );
  }
}
