import { moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren
} from '@angular/core';
import clamp from 'lodash/clamp';
import range from 'lodash/range';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, fromEvent, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { DialogService } from '@common/dialogs';
import { AppDrag, AppDragDrop, AppDropList, DropListService } from '@common/drag-drop2';
import { CustomizeBarItem } from '@modules/change-components/data/customize-bar-item';
import {
  ColumnsLayoutColumnElementItem,
  ColumnsLayoutElementItem,
  ColumnsStyle,
  CustomizeService,
  ElementItem,
  ElementType,
  registerElementComponent,
  traverseElementItems
} from '@modules/customize';
import {
  AutoElementComponent,
  BaseElementComponent,
  DRAG_DROP_SIBLING_ACTIVE_CLASS,
  ElementContainerService
} from '@modules/customize-elements';
import { RoutingService } from '@modules/routing';
import { addClass, isSet, MouseButton, removeClass } from '@shared';

import { ElementGroupsContainerDirective } from '../../directives/element-groups-container/element-groups-container.directive';
import { FormElementComponent } from '../form-element/form-element.component';
import { RootLayoutComponent } from '../root-layout/root-layout.component';

export const COLUMN_RESIZING_CLASS = 'app-column-resizing';

export interface StickPosition {
  label: string;
  position: number;
}

@Component({
  selector: 'app-columns-layout',
  templateUrl: './columns-layout.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColumnsLayoutComponent extends BaseElementComponent
  implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() element: ColumnsLayoutElementItem;

  @ViewChildren(ElementGroupsContainerDirective) elementGroupsContainer = new QueryList<
    ElementGroupsContainerDirective
  >();
  @ViewChild('columns_element') columnsElement: ElementRef;
  @ViewChildren('column_element') columnElements = new QueryList<ElementRef>();
  @ViewChildren(AutoElementComponent) elementComponents = new QueryList<AutoElementComponent>();

  dragging = false;
  draggingIndex: number;
  stick: StickPosition;
  stickDistance = 8;
  wrapperPadding = 15;
  columnsStyle = ColumnsStyle;

  customizeEnabled$: Observable<boolean>;
  siblingEntered$ = new BehaviorSubject<number>(undefined);

  canEnter = (() => {
    return (drag: AppDrag, drop: AppDropList): boolean => {
      if (this.rootLayoutComponent && !this.rootLayoutComponent.active) {
        return false;
      }

      if (drag.data instanceof ElementItem && drag.data.type == ElementType.FormSubmit) {
        let isInsideCurrentForm = false;

        if (this.formElementComponent) {
          traverseElementItems(this.formElementComponent.element, item => {
            if (item === drag.data) {
              isInsideCurrentForm = true;
            }
          });
        }

        return isInsideCurrentForm;
      } else {
        return true;
      }
    };
  })();

  canEnterAfter = (() => {
    return (drag: AppDrag, drop: AppDropList): boolean => {
      const barItem = drag.data instanceof ElementItem ? undefined : (drag.data as CustomizeBarItem);
      if (barItem && barItem.popup) {
        return false;
      }

      return this.canEnter(drag, drop);
    };
  })();

  trackColumn = (() => {
    return (i, item: ColumnsLayoutColumnElementItem) => {
      const pageUid = this.context && this.context.viewSettings ? this.context.viewSettings.uid : undefined;
      return [pageUid, item.uid].join('_');
    };
  })();

  trackElement = (() => {
    return (i, item: ElementItem) => {
      const pageUid =
        this.context && this.context.viewSettings && !this.context.viewSettings.newlyCreated
          ? this.context.viewSettings.uid
          : undefined;
      return [pageUid, item.uid].join('_');
    };
  })();

  constructor(
    public customizeService: CustomizeService,
    private elementContainerService: ElementContainerService,
    private routing: RoutingService,
    private dialogService: DialogService,
    private dropListService: DropListService,
    @Optional() private rootLayoutComponent: RootLayoutComponent,
    @Optional() private formElementComponent: FormElementComponent,
    private cd: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    this.customizeEnabled$ = this.customizeService.enabled$.pipe(map(item => !!item));
    this.actionClicked
      .pipe(
        filter(item => item == 'add'),
        untilDestroyed(this)
      )
      .subscribe(() => this.addColumn());

    this.dropListService.dragging$.pipe(untilDestroyed(this)).subscribe(dragging => {
      if (!dragging) {
        this.siblingEntered$.next(undefined);
      }
    });

    this.siblingEntered$.pipe(untilDestroyed(this)).subscribe(siblingEntered => {
      if (siblingEntered !== undefined) {
        addClass(document.body, DRAG_DROP_SIBLING_ACTIVE_CLASS);
      } else {
        removeClass(document.body, DRAG_DROP_SIBLING_ACTIVE_CLASS);
      }
    });
  }

  ngOnDestroy(): void {
    if (this.dragging) {
      removeClass(document.body, COLUMN_RESIZING_CLASS);
    }

    if (this.siblingEntered$.value !== undefined) {
      removeClass(document.body, DRAG_DROP_SIBLING_ACTIVE_CLASS);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['element'] && !changes['element'].firstChange) {
      setTimeout(() => this.updateAllElementStatesOnStable(), 0);
    }
  }

  ngAfterViewInit(): void {
    this.updateAllElementStates();
    this.initHandles();
  }

  getFluidTotalWidth() {
    return this.columnElements
      .filter((_, i) => !isSet(this.element.columns[i].width))
      .reduce((sum, current, i) => {
        return sum + current.nativeElement.getBoundingClientRect().width;
      }, 0);
  }

  getColumnWidth(index: number) {
    return this.columnElements.toArray()[index].nativeElement.getBoundingClientRect().width;
  }

  onHandleDragStarted(columnIndex: number) {
    this.draggingIndex = columnIndex;
    this.dragging = true;
    this.cd.markForCheck();

    addClass(document.body, COLUMN_RESIZING_CLASS);
  }

  initHandles() {
    fromEvent<MouseEvent>(window.document, 'mousemove')
      .pipe(untilDestroyed(this))
      .subscribe(e => {
        if (!this.dragging) {
          return;
        }

        const index = this.draggingIndex;
        const bounds = this.columnsElement.nativeElement.getBoundingClientRect();
        const lhs = this.columnElements.toArray()[index].nativeElement;
        const lhsBounds = lhs.getBoundingClientRect();
        const rhs = this.columnElements.toArray()[index + 1].nativeElement;
        const rhsBounds = rhs.getBoundingClientRect();
        const minWidth = 40;
        let position = clamp(
          e.clientX,
          lhsBounds.left + this.wrapperPadding + minWidth,
          rhsBounds.right - this.wrapperPadding - minWidth
        );
        const stickPosition = this.getStickPositions(bounds).find(item => {
          return position >= item.position - this.stickDistance && position <= item.position + this.stickDistance;
        });

        if (stickPosition) {
          this.stick = stickPosition;
          position = stickPosition.position;
        } else {
          this.stick = undefined;
        }

        const totalWidth = this.getFluidTotalWidth();
        const leftWidth = position - lhsBounds.left + this.wrapperPadding * 0.5;
        const rightWidth = rhsBounds.right - (position - this.wrapperPadding) - this.wrapperPadding * 0.5;
        const lhsWeight = leftWidth / totalWidth;
        const rhsWeight = rightWidth / totalWidth;

        this.columnElements.forEach((item, i) => {
          if (i == index || i == index + 1) {
            return;
          }

          if (!isSet(this.element.columns[i].width)) {
            this.element.columns[i].weight = item.nativeElement.getBoundingClientRect().width / totalWidth;
          }
        });

        if (!this.element.columns[index].fit && isSet(this.element.columns[index].width)) {
          this.element.columns[index].width = leftWidth;
          this.element.columns[index].fit = false;
        } else {
          this.element.columns[index].weight = lhsWeight;
          this.element.columns[index].fit = false;
        }

        if (!this.element.columns[index + 1].fit) {
          if (isSet(this.element.columns[index + 1].width)) {
            this.element.columns[index + 1].width = rightWidth;
          } else {
            this.element.columns[index + 1].weight = rhsWeight;
          }
        }

        this.cd.markForCheck();
      });

    fromEvent<MouseEvent>(window.document, 'mouseup')
      .pipe(
        filter(e => e.button == MouseButton.Main),
        untilDestroyed(this)
      )
      .subscribe(() => {
        if (!this.dragging) {
          return;
        }
        this.dragging = false;
        this.draggingIndex = undefined;
        this.stick = undefined;
        removeClass(document.body, COLUMN_RESIZING_CLASS);
        this.customizeService.markChanged();
        this.cd.markForCheck();
      });
  }

  draggedOutside() {
    if (this.element.childrenCount() == 0) {
      this.deleteRequested.emit();
    }
  }

  dragDropAfter(tabIndex: number, event: AppDragDrop<ElementItem[] | CustomizeBarItem[]>) {
    this.elementContainerService.dragDropIntoNewColumn({
      columnsElement: this.element,
      columnIndex: tabIndex + 1,
      sourceContainer: event.previousContainer.data as (ElementItem | CustomizeBarItem)[],
      sourceIndex: event.previousIndex,
      sourceCloneItem: event.previousContainer.cloneItems,
      context: this.context
    });

    this.customizeService.markChanged();
    this.updateElementStatesOnStable(tabIndex);
  }

  dragDrop(tabIndex: number, event: AppDragDrop<ElementItem[] | CustomizeBarItem[]>) {
    const item = event.previousContainer.data[event.previousIndex];
    const barItem = item instanceof ElementItem ? undefined : (item as CustomizeBarItem);

    if (barItem && barItem.popup) {
      if (this.customizeService.handler && this.customizeService.handler.createPopup) {
        this.customizeService.handler.createPopup(true, {
          ...(barItem.defaultParams && {
            width: barItem.defaultParams['width'],
            style: barItem.defaultParams['style'],
            position: barItem.defaultParams['position']
          }),
          analyticsSource: 'components_library'
        });
        this.elementContainerService.sendAddPopupAnalytics();
      }

      return;
    }

    const siblingLeftEntered = event.data ? !!event.data['siblingLeftEntered'] : false;
    const siblingRightEntered = event.data ? !!event.data['siblingRightEntered'] : false;
    const siblingSelf = event.data ? !!event.data['siblingSelf'] : false;
    const siblingAnchor: ElementItem = event.data ? event.data['siblingAnchor'] : undefined;
    const siblingAnchorContainer: AppDropList = event.data ? event.data['siblingAnchorContainer'] : undefined;

    if (siblingLeftEntered || siblingRightEntered) {
      const anchorContainer: ElementItem[] = siblingSelf ? event.container.data : siblingAnchorContainer.data;
      const anchorIndex = siblingSelf ? event.currentIndex : anchorContainer.indexOf(siblingAnchor);

      this.elementContainerService.dragDropIntoSiblingColumn({
        sourceContainer: event.previousContainer.data as (ElementItem | CustomizeBarItem)[],
        sourceIndex: event.previousIndex,
        sourceCloneItem: event.previousContainer.cloneItems,
        anchorContainer: anchorContainer,
        anchorIndex: anchorIndex,
        anchorSelf: siblingSelf,
        left: siblingLeftEntered,
        context: this.context,
        parent: this.element
      });

      // TODO: Implement onAdd
      // this.cd.detectChanges();
      // const component = this.elementComponents.find(i => i.element === elementItem);
      // this.onAdd(elementItem, item, component);
    } else {
      if (event.previousContainer === event.container) {
        moveItemInArray(event.container.data as ElementItem[], event.previousIndex, event.currentIndex);
      } else if (event.previousContainer.cloneItems) {
        const elementItem = this.elementContainerService.copyElementItem(
          event.previousContainer.data as CustomizeBarItem[],
          event.container.data as ElementItem[],
          event.previousIndex,
          event.currentIndex,
          this.context
        );
        this.customizeService.registerCreatedElement(elementItem, barItem);
      } else {
        transferArrayItem(
          event.previousContainer.data as ElementItem[],
          event.container.data as ElementItem[],
          event.previousIndex,
          event.currentIndex
        );
      }
    }

    this.customizeService.markChanged();
    this.updateElementStatesOnStable(tabIndex);
  }

  getStickPositions(bounds: ClientRect) {
    return [1 / 2, 1 / 3, 1 / 4, 1 / 5, 1 / 6, 1 / 8]
      .filter(multiplier => bounds.width * multiplier >= 120)
      .reduce((acc, multiplier) => {
        const parts = 1 / multiplier;

        acc.push(
          ...range(parts)
            .slice(1)
            .map(i => {
              const pos = bounds.left + multiplier * bounds.width * i;

              return {
                label: `${i}/${parts}`,
                position: pos
              };
            })
        );
        return acc;
      }, []);
  }

  replaceItem(columnIndex: number, index: number, elements: ElementItem[]) {
    this.elementContainerService.replaceElementItem(this.element.columns[columnIndex].children, index, elements);
    this.cd.markForCheck();
    this.customizeService.markChanged();
    this.updateElementStatesOnStable(columnIndex);
  }

  duplicateItem(columnIndex: number, index: number) {
    this.elementContainerService
      .duplicateElementItem(this.element.columns[columnIndex].children, this.context, index)
      .pipe(untilDestroyed(this))
      .subscribe(elementItem => {
        this.cd.detectChanges();
        this.customizeService.markChanged();
        this.updateElementStatesOnStable(columnIndex);

        const component = this.elementComponents.find(i => i.element === elementItem);

        if (component) {
          component.customize();
        }
      });
  }

  deleteItem(element: ElementItem) {
    this.element.columns.forEach((tab, columnIndex) => {
      const index = tab.children.findIndex(item => item === element);

      if (index == -1) {
        return;
      }

      this.elementContainerService.deleteElementItem(this.element.columns[columnIndex].children, index);
      this.cd.markForCheck();
      this.customizeService.markChanged();
      this.updateElementStatesOnStable(columnIndex);
    });

    if (this.element.childrenCount() == 0) {
      this.deleteRequested.emit();
    }
  }

  moveItemTo(element: ElementItem, link: any[]) {
    this.deleteItem(element);

    this.customizeService.stopTrackChanges();
    this.customizeService
      .saveActualChanges()
      .pipe(untilDestroyed(this))
      .subscribe(() => this.routing.navigateApp(link));
  }

  updateAllElementStatesOnStable() {
    this.element.columns.forEach((_, i) => this.updateElementStatesOnStable(i));
  }

  updateAllElementStates() {
    this.element.columns.forEach((_, i) => this.updateElementStates(i));
  }

  updateElementStatesOnStable(columnIndex: number) {
    const container = this.elementGroupsContainer.toArray()[columnIndex];
    if (container) {
      container.updateElementStatesOnStable();
    }
  }

  updateElementStates(columnIndex: number) {
    const container = this.elementGroupsContainer.toArray()[columnIndex];
    if (container) {
      container.updateElementStates();
    }
  }

  setFitContents(columnIndex: number) {
    const column = this.element.columns[columnIndex];

    column.width = undefined;
    column.weight = undefined;
    column.fit = true;

    this.cd.markForCheck();
    this.customizeService.markChanged();
  }

  setFluidWidth(columnIndex: number) {
    const column = this.element.columns[columnIndex];

    column.width = undefined;
    column.fit = false;

    const totalWidth = this.getFluidTotalWidth();

    this.element.columns.forEach((item, i) => {
      if (this.element.columns[i].fit || isSet(this.element.columns[i].width)) {
        return;
      }

      item.weight = totalWidth ? this.getColumnWidth(i) / totalWidth : 1;
    });

    this.cd.markForCheck();
    this.customizeService.markChanged();
  }

  setFixedWidth(columnIndex: number) {
    const column = this.element.columns[columnIndex];

    column.width = this.getColumnWidth(columnIndex);
    column.weight = undefined;
    column.fit = false;

    const totalWidth = this.getFluidTotalWidth();

    this.element.columns.forEach((item, i) => {
      if (this.element.columns[i].fit || isSet(this.element.columns[i].width)) {
        return;
      }

      item.weight = totalWidth ? this.getColumnWidth(i) / totalWidth : 1;
    });

    this.cd.markForCheck();
    this.customizeService.markChanged();
  }

  deleteColumn(columnIndex: number) {
    const components = this.element.columns[columnIndex].children.length;

    if (!components) {
      this.deleteColumnProcess(columnIndex);
      return;
    }

    this.dialogService
      .warning({
        title: `Delete columns components (${components})`,
        description: `
          This column contains other components.
          Are you sure want to delete this columns with the contained components?
        `,
        style: 'orange'
      })
      .pipe(
        filter(result => result),
        untilDestroyed(this)
      )
      .subscribe(() => this.deleteColumnProcess(columnIndex));
  }

  deleteColumnProcess(columnIndex: number) {
    if (this.element.columns.length == 2) {
      const anotherColumn = columnIndex == 0 ? 1 : 0;
      this.replaceRequested.emit(this.element.columns[anotherColumn].children);
    } else {
      this.element.columns = this.element.columns.filter((item, i) => i !== columnIndex);
      this.cd.markForCheck();
      this.customizeService.markChanged();
    }
  }

  addColumn() {
    const fluidColumns = this.element.columns.filter(item => isSet(item.weight));
    const weight = fluidColumns.length
      ? fluidColumns.map(item => item.weight).reduce((a, b) => a + b, 0) / fluidColumns.length
      : 1;
    const column = new ColumnsLayoutColumnElementItem();

    column.weight = weight;
    column.generateUid();

    this.element.columns.push(column);
    this.cd.detectChanges();
    this.customizeService.markChanged();
  }
}

registerElementComponent({
  type: ElementType.Columns,
  component: ColumnsLayoutComponent,
  label: 'Columns',
  alwaysActive: false,
  actions: [{ name: 'add', label: 'Add Column' }]
});
