import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';

import { NotificationService } from '@common/notifications';
import { BasePopupComponent } from '@common/popups';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ServerRequestError } from '@modules/api';
import { createFormFieldFactory } from '@modules/fields';
import { CurrentProjectStore, Environment, Resource } from '@modules/projects';
import { RoutingService } from '@modules/routing';
import { controlValue, errorToString } from '@shared';

import { ProjectEnvironmentMergePopupForm } from './project-environment-merge-popup.form';

@Component({
  selector: 'app-project-environment-merge-popup',
  templateUrl: './project-environment-merge-popup.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ProjectEnvironmentMergePopupForm]
})
export class ProjectEnvironmentMergePopupComponent implements OnInit, OnDestroy {
  @Input() environmentFrom: Environment;
  @Input() environmentTo: Environment;
  @Input() source: string;
  @Output() merged = new EventEmitter<void>();

  createField = createFormFieldFactory();
  loading = false;
  resources: { from: Resource; to?: Resource }[] = [];

  constructor(
    public form: ProjectEnvironmentMergePopupForm,
    private currentProjectStore: CurrentProjectStore,
    private popupComponent: BasePopupComponent,
    private cd: ChangeDetectorRef,
    private routing: RoutingService,
    private notificationService: NotificationService,
    private analyticsService: UniversalAnalyticsService
  ) {}

  ngOnInit(): void {
    this.form.init({ environmentFrom: this.environmentFrom, environmentTo: this.environmentTo });

    combineLatest(
      controlValue<Environment>(this.form.controls.from_environment),
      controlValue<Environment>(this.form.controls.to_environment)
    )
      .pipe(untilDestroyed(this))
      .subscribe(([fromEnvironment, toEnvironment]) => {
        if (!fromEnvironment || !toEnvironment) {
          this.resources = undefined;
          this.cd.markForCheck();
          return;
        }

        const fromResources = this.currentProjectStore.instance
          .getEnvironmentResources(fromEnvironment.uniqueName)
          .filter(item => !item.demo);
        const toResources = this.currentProjectStore.instance
          .getEnvironmentResources(toEnvironment.uniqueName)
          .filter(item => !item.demo);

        this.resources = fromResources.map(fromResource => {
          const toResource = toResources.find(item => item.uniqueName == fromResource.uniqueName);

          return {
            from: fromResource,
            to: toResource
          };
        });
        this.cd.markForCheck();
      });

    this.analyticsService.sendSimpleEvent(AnalyticsEvent.Environment.StartedToMerge, {
      EnvironmentFromName: this.environmentFrom ? this.environmentFrom.name : undefined,
      EnvironmentToName: this.environmentTo ? this.environmentTo.name : undefined,
      Source: this.source
    });
  }

  ngOnDestroy(): void {}

  submit(): void {
    if (this.form.invalid) {
      return;
    }

    this.loading = true;
    this.cd.markForCheck();

    this.form
      .submit()
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          this.close();
          this.merged.emit();

          const copyFrom = this.form.controls.from_environment.value.name;
          const copyTo = this.form.controls.to_environment.value.name;

          this.notificationService.success('Copied', `Environment ${copyFrom} was copied to ${copyTo} successfully`);

          this.analyticsService.sendSimpleEvent(AnalyticsEvent.Environment.MergedSuccessfully, {
            EnvironmentFromName: copyFrom,
            EnvironmentToName: copyTo,
            Source: this.source
          });
        },
        error => {
          const errorMessage =
            error instanceof ServerRequestError && error.errors.length ? error.errors[0] : errorToString(error);
          this.notificationService.error('Copy failed', errorMessage);

          this.loading = false;
          this.cd.markForCheck();

          this.analyticsService.sendSimpleEvent(AnalyticsEvent.Environment.MergeFailed, {
            EnvironmentFromName: this.form.controls.from_environment.value.name,
            EnvironmentToName: this.form.controls.to_environment.value.name,
            Error: errorMessage,
            Source: this.source
          });
        }
      );
  }

  close() {
    this.popupComponent.close();
  }
}
