var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, throwError } from 'rxjs';
import { catchError, delayWhen, filter, map, switchMap } from 'rxjs/operators';
import { copyTextToClipboard } from '@common/code';
import { DialogButtonHotkey, DialogButtonType, DialogService } from '@common/dialogs';
import { NotificationService } from '@common/notifications';
import { BasePopupComponent } from '@common/popups';
import { AppConfigService, RuntimeEnvironment } from '@core';
import { ActionType } from '@modules/actions';
import { AnalyticsEvent, IntercomService, UniversalAnalyticsService } from '@modules/analytics';
import { ServerRequestError } from '@modules/api';
import { WorkflowEditController } from '@modules/customize-bar';
import { createFormFieldFactory, FieldOutput, FieldType, getFieldDescriptionByType, ImageFit } from '@modules/fields';
import { ProjectSSOItemForm } from '@modules/project-settings-routes';
import { CurrentEnvironmentStore, CurrentProjectStore, customOAuth2BackendName, ProjectPropertyStore } from '@modules/projects';
import { SharedCustomSSOType, SSOSettings, SSOSettingsService, SSOType } from '@modules/sso';
import { Workflow } from '@modules/workflow';
import { capitalize, controlValue, errorToString, getExtension, getFilenameWithExtension, isSet } from '@shared';
var SSOSettingsEditPopupComponent = /** @class */ (function () {
    function SSOSettingsEditPopupComponent(popupComponent, currentProjectStore, currentEnvironmentStore, ssoSettingsService, workflowEditController, projectPropertyStore, form, appConfigService, intercomService, notificationService, dialogService, cd, analyticsService) {
        this.popupComponent = popupComponent;
        this.currentProjectStore = currentProjectStore;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.ssoSettingsService = ssoSettingsService;
        this.workflowEditController = workflowEditController;
        this.projectPropertyStore = projectPropertyStore;
        this.form = form;
        this.appConfigService = appConfigService;
        this.intercomService = intercomService;
        this.notificationService = notificationService;
        this.dialogService = dialogService;
        this.cd = cd;
        this.analyticsService = analyticsService;
        this.initialValue = {};
        this.created = new EventEmitter();
        this.updated = new EventEmitter();
        this.deleted = new EventEmitter();
        this.loading = false;
        this.deleteLoading = false;
        this.submitLoading = false;
        this.ssoTypes = SSOType;
        this.metadataDraggingOver = false;
        this.customOAuth2BackendName = customOAuth2BackendName;
        this.createField = createFormFieldFactory();
        this.parametersGroup = new FormGroup({});
        this.imageFits = ImageFit;
        this.sharedCustomSSOWithSide = [SharedCustomSSOType.Firebase, SharedCustomSSOType.Supabase];
        this.sharedCustomSSOTypes = SharedCustomSSOType;
    }
    SSOSettingsEditPopupComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.form.init({ instance: this.sso, initialValue: this.initialValue });
        combineLatest(controlValue(this.form.controls.custom.controls.auth_workflow), controlValue(this.form.controls.custom.controls.refresh_token_workflow))
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var authWorkflow = _a[0], refreshTokenWorkflow = _a[1];
            _this.authWorkflow = authWorkflow;
            _this.refreshTokenWorkflow = refreshTokenWorkflow;
            _this.cd.markForCheck();
        });
        this.form.controls.custom.controls.parameters
            .getGroup$()
            .pipe(untilDestroyed(this))
            .subscribe(function (value) {
            _this.parametersGroup = value;
            _this.cd.markForCheck();
        });
        if (this.sso) {
            this.analyticsService.sendSimpleEvent(AnalyticsEvent.SSO.UpdateStarted, {
                Type: this.sso.type,
                Source: this.analyticsSource
            });
        }
        else {
            this.analyticsService.sendSimpleEvent(AnalyticsEvent.SSO.AddStarted, {
                Type: this.initialValue.type,
                Source: this.analyticsSource
            });
        }
    };
    SSOSettingsEditPopupComponent.prototype.ngOnDestroy = function () { };
    Object.defineProperty(SSOSettingsEditPopupComponent.prototype, "samlRedirectUrl", {
        get: function () {
            if (!this.sso) {
                return;
            }
            return this.appConfigService.serverBaseUrl + "/saml2_auth/acs/" + this.sso.uid + "/";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SSOSettingsEditPopupComponent.prototype, "oauthRedirectUrl", {
        get: function () {
            return this.appConfigService.serverBaseUrl + "/complete/" + this.form.controls.oauth2.controls.oauth_params.value['backend'] + "/";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SSOSettingsEditPopupComponent.prototype, "jetBridgeRedirectUrl", {
        get: function () {
            if (!this.sso) {
                return;
            }
            return this.form.controls.jet_bridge.controls.url.value + "external_auth/complete/" + this.sso.uid + "/";
        },
        enumerable: true,
        configurable: true
    });
    SSOSettingsEditPopupComponent.prototype.copy = function (text, contentLabel) {
        var _this = this;
        copyTextToClipboard(text)
            .pipe(untilDestroyed(this))
            .subscribe(function (success) {
            if (!success) {
                return;
            }
            var description = isSet(contentLabel) ? capitalize(contentLabel) + " was copied to clipboard" : undefined;
            _this.notificationService.info('Copied', description);
        });
    };
    SSOSettingsEditPopupComponent.prototype.updateMetadataPreview = function () {
        var value = this.form.controls.saml2.controls.metadata.value;
        var url = value instanceof File ? value.name : value;
        this.metadataFilename = getFilenameWithExtension(url);
        this.metadataExtension = getExtension(url);
        this.cd.markForCheck();
    };
    SSOSettingsEditPopupComponent.prototype.onMetadataFileChange = function (el) {
        if (!el.files.length) {
            return;
        }
        var file = el.files[0];
        el.value = null;
        this.form.controls.saml2.controls.metadata.patchValue(file);
        this.form.controls.saml2.controls.metadata.markAsDirty();
        this.updateMetadataPreview();
    };
    SSOSettingsEditPopupComponent.prototype.onMetadataDragOver = function (e) {
        e.stopPropagation();
        e.preventDefault();
        this.metadataDraggingOver = true;
        this.cd.markForCheck();
    };
    SSOSettingsEditPopupComponent.prototype.onMetadataDragLeave = function (e) {
        e.stopPropagation();
        e.preventDefault();
        this.metadataDraggingOver = false;
        this.cd.markForCheck();
    };
    SSOSettingsEditPopupComponent.prototype.onMetadataDrop = function (e) {
        e.stopPropagation();
        e.preventDefault();
        if (!e.dataTransfer.files.length) {
            return;
        }
        var file = e.dataTransfer.files[0];
        this.metadataDraggingOver = false;
        this.cd.markForCheck();
        if (file) {
            this.form.controls.saml2.controls.metadata.setValue(file);
            this.form.markAsPristine();
        }
    };
    SSOSettingsEditPopupComponent.prototype.submit = function () {
        var _this = this;
        this.submitLoading = true;
        this.cd.markForCheck();
        this.form
            .submit()
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            if (_this.sso) {
                _this.updated.emit(result);
                _this.notificationService.success('Saved', "<strong>" + _this.sso.name + "</strong> was successfully updated");
                _this.analyticsService.sendSimpleEvent(AnalyticsEvent.SSO.Updated, {
                    Type: _this.sso.type,
                    Source: _this.analyticsSource
                });
            }
            else {
                _this.created.emit(result);
                _this.notificationService.success('Created', "<strong>" + _this.sso.name + "</strong> was successfully added to current App");
                _this.analyticsService.sendSimpleEvent(AnalyticsEvent.SSO.Added, {
                    Type: _this.initialValue.type,
                    Source: _this.analyticsSource
                });
            }
            _this.analyticsService.sendSimpleEvent(AnalyticsEvent.SSO.Saved, {
                Type: _this.sso ? _this.sso.type : _this.initialValue.type,
                Source: _this.analyticsSource
            });
            _this.close();
        }, function () {
            _this.notificationService.error('Error', 'Saving External authentication failed');
            _this.submitLoading = false;
            _this.cd.markForCheck();
        });
    };
    SSOSettingsEditPopupComponent.prototype.close = function () {
        if (this.popupComponent) {
            this.popupComponent.close();
        }
    };
    SSOSettingsEditPopupComponent.prototype.cancel = function () {
        this.close();
        if (this.sso) {
            this.analyticsService.sendSimpleEvent(AnalyticsEvent.SSO.UpdateCancelled, {
                Type: this.sso.type,
                Source: this.analyticsSource
            });
        }
        else {
            this.analyticsService.sendSimpleEvent(AnalyticsEvent.SSO.AddCancelled, {
                Type: this.initialValue.type,
                Source: this.analyticsSource
            });
        }
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.SSO.Cancelled, {
            Type: this.sso ? this.sso.type : this.initialValue.type,
            Source: this.analyticsSource
        });
    };
    SSOSettingsEditPopupComponent.prototype.openChat = function () {
        this.intercomService.openChat();
    };
    SSOSettingsEditPopupComponent.prototype.customizeWorkflow = function (options) {
        var workflow;
        if (options.workflow) {
            workflow = cloneDeep(options.workflow);
        }
        else {
            workflow = new Workflow();
            workflow.generateUid();
        }
        return this.workflowEditController.open({
            runtime: RuntimeEnvironment.NodeJS,
            create: !this.authWorkflow,
            workflow: workflow,
            workflowRun: workflow.testRun,
            workflowEditable: true,
            parameters: [],
            triggerOutputs: options.triggerOutputs,
            triggerLabel: options.triggerLabel,
            triggerIcon: options.triggerIcon,
            customizeTrigger: false,
            actionTypesEnabled: [
                ActionType.Query,
                ActionType.RunJavaScript
                // ActionType.Link,
                // ActionType.ExternalLink
            ],
            historyEnabled: true,
            resultEnabled: options.resultOutputs && options.resultOutputs.length > 0,
            resultOutputs: options.resultOutputs,
            analyticsSource: [this.analyticsSource, 'auth_workflow'].join('_')
        });
    };
    SSOSettingsEditPopupComponent.prototype.customizeAuthWorkflow = function () {
        var _this = this;
        this.projectPropertyStore
            .getUser()
            .pipe(switchMap(function (projectProperties) {
            var triggerOutputItems = _this.form.controls.custom.controls.parameters.controls.map(function (control) {
                var fieldDescription = getFieldDescriptionByType(control.controls.field.value);
                return {
                    name: control.controls.name.value,
                    verboseName: control.controls.name.value,
                    field: control.controls.field.value,
                    params: control.controls.params.value,
                    icon: fieldDescription.icon
                };
            }).concat([
                {
                    name: 'username',
                    verboseName: 'Email',
                    field: FieldType.Text,
                    icon: 'email'
                },
                {
                    name: 'password',
                    verboseName: 'Password',
                    field: FieldType.Password,
                    icon: 'lock_close'
                },
                {
                    name: 'sso',
                    verboseName: 'SSO ID',
                    field: FieldType.Text,
                    icon: 'number'
                }
            ]);
            var triggerOutputs = triggerOutputItems.map(function (item) {
                var output = new FieldOutput();
                output.name = item.name;
                output.verboseName = item.verboseName;
                output.field = item.field;
                output.icon = item.icon;
                output.updateFieldDescription();
                return output;
            });
            var resultOutputs = [
                {
                    name: 'email',
                    verboseName: 'E-mail',
                    field: FieldType.Text,
                    required: true,
                    description: 'Used as unique username',
                    icon: 'email'
                },
                {
                    name: 'first_name',
                    verboseName: 'First name',
                    field: FieldType.Text,
                    icon: 'user'
                },
                {
                    name: 'last_name',
                    verboseName: 'Last name',
                    field: FieldType.Text,
                    icon: 'user'
                },
                {
                    name: 'photo',
                    verboseName: 'Photo URL',
                    field: FieldType.Text,
                    icon: 'image'
                },
                {
                    name: 'token',
                    verboseName: 'Token',
                    field: FieldType.Text,
                    description: 'External user token which can be used in custom queries',
                    icon: 'key'
                },
                {
                    name: 'token_expire',
                    verboseName: 'Token expiration date',
                    field: FieldType.DateTime,
                    description: 'Date when token will be expired and must be refreshed',
                    icon: 'calendar'
                },
                {
                    name: 'extra_data',
                    verboseName: 'Extra data',
                    field: FieldType.JSON,
                    description: 'Additional data which can be used in other queries',
                    icon: 'components'
                }
            ].concat((projectProperties || []).map(function (item) {
                return __assign({ name: item.uid, verboseName: item.name }, (item.field && {
                    field: item.field.field,
                    params: item.field.params
                }), { required: false, description: undefined, icon: item.fieldDescription.icon });
            })).map(function (item) {
                var output = new FieldOutput();
                output.name = item.name;
                output.verboseName = item.verboseName;
                output.description = item.description;
                output.field = item.field;
                output.icon = item.icon;
                output.required = item.required;
                output.updateFieldDescription();
                return output;
            });
            return _this.customizeWorkflow({
                triggerLabel: 'When login clicked',
                triggerIcon: 'signin',
                workflow: _this.authWorkflow,
                triggerOutputs: triggerOutputs,
                resultOutputs: resultOutputs
            });
        }), filter(function (result) { return !result.cancelled; }), untilDestroyed(this))
            .subscribe(function (result) {
            result.workflow.testRun = result.workflowRun;
            _this.form.controls.custom.controls.auth_workflow.patchValue(result.workflow);
            _this.cd.markForCheck();
        });
    };
    SSOSettingsEditPopupComponent.prototype.customizeRefreshTokenWorkflow = function () {
        var _this = this;
        var triggerOutputItems = this.form.controls.custom.controls.parameters.controls.map(function (control) {
            var fieldDescription = getFieldDescriptionByType(control.controls.field.value);
            return {
                name: control.controls.name.value,
                verboseName: control.controls.name.value,
                field: control.controls.field.value,
                params: control.controls.params.value,
                icon: fieldDescription.icon
            };
        }).concat([
            {
                name: 'token',
                verboseName: 'Token',
                field: FieldType.Text,
                description: 'External user token which can be used in custom queries',
                icon: 'key'
            },
            {
                name: 'token_expire',
                verboseName: 'Token expiration date',
                field: FieldType.DateTime,
                description: 'Date when token will be expired and must be refreshed',
                icon: 'calendar'
            },
            {
                name: 'extra_data',
                verboseName: 'Extra data',
                field: FieldType.JSON,
                description: 'Additional data which can be used in other queries',
                icon: 'components'
            },
            {
                name: 'email',
                verboseName: 'E-mail',
                field: FieldType.Text,
                description: 'Used as unique username',
                icon: 'email'
            },
            {
                name: 'sso',
                verboseName: 'SSO ID',
                field: FieldType.Text,
                icon: 'number'
            }
        ]);
        var triggerOutputs = triggerOutputItems.map(function (item) {
            var output = new FieldOutput();
            output.name = item.name;
            output.verboseName = item.verboseName;
            output.description = item.description;
            output.field = item.field;
            output.icon = item.icon;
            output.updateFieldDescription();
            return output;
        });
        var resultOutputs = [
            {
                name: 'token',
                verboseName: 'Token',
                field: FieldType.Text,
                description: 'External user token which can be used in custom queries',
                icon: 'key',
                required: true
            },
            {
                name: 'token_expire',
                verboseName: 'Token expiration date',
                field: FieldType.DateTime,
                description: 'Date when token will be expired and must be refreshed',
                icon: 'date',
                required: true
            },
            {
                name: 'extra_data',
                verboseName: 'Extra data',
                field: FieldType.JSON,
                description: 'Additional data which can be used in other queries',
                icon: 'components'
            }
        ].map(function (item) {
            var output = new FieldOutput();
            output.name = item.name;
            output.verboseName = item.verboseName;
            output.description = item.description;
            output.field = item.field;
            output.icon = item.icon;
            output.required = item.required;
            output.updateFieldDescription();
            return output;
        });
        this.customizeWorkflow({
            triggerLabel: 'When token expired',
            triggerIcon: 'repeat',
            workflow: this.refreshTokenWorkflow,
            triggerOutputs: triggerOutputs,
            resultOutputs: resultOutputs
        })
            .pipe(filter(function (result) { return !result.cancelled; }), untilDestroyed(this))
            .subscribe(function (result) {
            result.workflow.testRun = result.workflowRun;
            _this.form.controls.custom.controls.refresh_token_workflow.patchValue(result.workflow);
            _this.cd.markForCheck();
        });
    };
    SSOSettingsEditPopupComponent.prototype.deleteRefreshTokenWorkflow = function () {
        var _this = this;
        this.dialogService
            .warning({
            title: 'Delete workflow',
            description: "Are you sure want to delete <strong>Refresh token workflow</strong>?",
            style: 'orange'
        })
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            if (result) {
                _this.form.controls.custom.controls.refresh_token_workflow.patchValue(undefined);
                _this.cd.markForCheck();
            }
        });
    };
    SSOSettingsEditPopupComponent.prototype.deleteSSOSettings = function () {
        var _this = this;
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.SSO.DeleteStarted, {
            Type: this.sso.type,
            Source: this.analyticsSource
        });
        this.dialogService
            .dialog({
            title: 'Deleting',
            description: "Are you sure want to delete External authentication <strong>" + this.sso.name + "</strong>?",
            style: 'orange',
            buttons: [
                {
                    name: 'cancel',
                    label: 'Cancel',
                    type: DialogButtonType.Default,
                    hotkey: DialogButtonHotkey.Cancel
                },
                {
                    name: 'ok',
                    label: 'Delete authentication',
                    type: DialogButtonType.Danger,
                    hotkey: DialogButtonHotkey.Submit,
                    executor: function () {
                        _this.deleteLoading = true;
                        _this.cd.markForCheck();
                        return _this.deleteSSOSettingsProcess();
                    }
                }
            ]
        })
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            var submit = result.button == 'ok';
            _this.deleteLoading = false;
            _this.cd.markForCheck();
            if (submit && result.executorResult) {
                _this.deleted.emit(_this.sso);
                _this.close();
            }
            else if (!submit) {
                _this.analyticsService.sendSimpleEvent(AnalyticsEvent.SSO.DeleteCancelled, {
                    Type: _this.sso.type,
                    Source: _this.analyticsSource
                });
            }
        });
    };
    SSOSettingsEditPopupComponent.prototype.deleteSSOSettingsProcess = function () {
        var _this = this;
        return this.ssoSettingsService.delete(this.currentProjectStore.instance.uniqueName, this.sso).pipe(delayWhen(function () { return _this.currentProjectStore.getFirst(true); }), map(function () {
            _this.notificationService.success('Deleted', "<strong>" + _this.sso.name + "</strong> was successfully deleted");
            _this.analyticsService.sendSimpleEvent(AnalyticsEvent.SSO.Deleted, {
                Type: _this.sso.type,
                Source: _this.analyticsSource
            });
            return true;
        }), catchError(function (error) {
            if (error instanceof ServerRequestError && error.errors.length) {
                _this.notificationService.error('Delete Failed', error.errors[0]);
            }
            else {
                _this.notificationService.error('Delete Failed', errorToString(error));
            }
            return throwError(error);
        }));
    };
    return SSOSettingsEditPopupComponent;
}());
export { SSOSettingsEditPopupComponent };
