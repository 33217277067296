var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { map, pairwise } from 'rxjs/operators';
import { ResourceType } from '@modules/projects';
import { DefaultHttpQueryBodyTransformer, DefaultHttpQueryErrorTransformer, HttpContentType, HttpMethod, HttpQuery, HttpResponseType } from '@modules/queries';
import { controlValue, isSet } from '@shared';
import { HttpParameterArray } from './http-parameter.array';
var QueryBuilderHttpForm = /** @class */ (function (_super) {
    __extends(QueryBuilderHttpForm, _super);
    function QueryBuilderHttpForm() {
        var _this = _super.call(this, {
            method: new FormControl(HttpMethod.GET),
            base_url_enabled: new FormControl(false),
            url: new FormControl(''),
            url_path: new FormControl(''),
            query_params: new HttpParameterArray([]),
            headers: new HttpParameterArray([]),
            body_type: new FormControl(''),
            form_data: new HttpParameterArray([]),
            body_json: new FormControl(''),
            body_raw: new FormControl(''),
            body_transformer: new FormControl(DefaultHttpQueryBodyTransformer),
            body_transformer_visible: new FormControl(false),
            response_type: new FormControl(''),
            response_transformer: new FormControl(DefaultHttpQueryBodyTransformer),
            response_transformer_enabled: new FormControl(false),
            response_path: new FormControl([]),
            error_transformer: new FormControl(DefaultHttpQueryErrorTransformer),
            error_transformer_visible: new FormControl(false),
            request_response: new FormControl(''),
            request_response_headers: new FormControl([]),
            request_tokens: new FormControl('')
        }) || this;
        _this.responseTypeOptions = [
            { value: HttpResponseType.JSON, name: 'JSON' },
            { value: HttpResponseType.XML, name: 'XML' },
            { value: HttpResponseType.Text, name: 'Text' },
            { value: HttpResponseType.Blob, name: 'Blob' }
        ];
        _this.baseUrlAllowed = false;
        _this.isBodyJsonDefault$ = new BehaviorSubject(false);
        return _this;
    }
    QueryBuilderHttpForm.prototype.ngOnDestroy = function () {
        this.controls.query_params.ngOnDestroy();
        this.controls.headers.ngOnDestroy();
        this.controls.form_data.ngOnDestroy();
    };
    QueryBuilderHttpForm.prototype.init = function (resource, query, options, parametersControl, useFileObjects) {
        var _this = this;
        this.parametersControl = parametersControl;
        var instanceValue = {};
        var overrides = {};
        var defaults = options && options.defaults ? options.defaults : {};
        var enabledBodyTypes = options && options.enabledBodyTypes ? options.enabledBodyTypes : undefined;
        var createQuery = !query;
        if (!query) {
            query = new HttpQuery();
            if (options.defaults && options.defaults['method']) {
                query.method = options.defaults['method'];
            }
        }
        if (options && options.baseQuery) {
            query.merge(options.baseQuery, createQuery);
        }
        if (resource.type == ResourceType.GraphQL) {
            overrides['body_type'] = HttpContentType.GraphQL;
        }
        var baseUrl = options && options.baseQuery && isSet(options.baseQuery.url) ? options.baseQuery.url : undefined;
        this.baseUrl = baseUrl;
        this.baseUrlAllowed = isSet(baseUrl);
        var baseUrlEnabled = this.baseUrlAllowed;
        this.controls.base_url_enabled.patchValue(this.baseUrlAllowed);
        if (query) {
            var url = query.url;
            var urlPath = query.urlPath;
            var formData = query.bodyType == HttpContentType.FormData || query.bodyType == HttpContentType.FormUrlEncoded;
            if (this.baseUrlAllowed && isSet(query.url) && !isSet(query.urlPath)) {
                if (String(url).toLowerCase().startsWith(String(baseUrl).toLowerCase())) {
                    url = '';
                    urlPath = url.substring(options.baseQuery.url.length);
                }
                else {
                    baseUrlEnabled = false;
                }
            }
            instanceValue = {
                method: query.method,
                base_url_enabled: baseUrlEnabled,
                url: url,
                url_path: urlPath,
                query_params: query.queryParams,
                form_data: formData ? query.body || [] : [],
                body_json: query.bodyType == HttpContentType.JSON ? query.body : '',
                body_raw: query.bodyType == HttpContentType.Raw || query.bodyType == HttpContentType.GraphQL ? query.body : '',
                response_type: query.responseType,
                response_path: query.responsePath ? query.responsePath.split('.') : [],
                request_response: query.requestResponse,
                request_response_headers: query.requestResponseHeaders,
                request_tokens: query.requestTokens
            };
            if (query.headers !== undefined) {
                instanceValue['headers'] = query.headers;
            }
            if (query.bodyType && (!enabledBodyTypes || (enabledBodyTypes && enabledBodyTypes.includes(query.bodyType)))) {
                instanceValue['body_type'] = query.bodyType;
            }
            if (query.errorTransformer) {
                instanceValue['error_transformer'] = query.errorTransformer;
                instanceValue['error_transformer_visible'] = true;
            }
            else {
                instanceValue['error_transformer_visible'] = false;
            }
            if (query.bodyTransformer) {
                instanceValue['body_transformer'] = query.bodyTransformer;
                instanceValue['body_transformer_visible'] = true;
            }
            else {
                instanceValue['body_transformer_visible'] = false;
            }
            if (query.responseTransformer) {
                instanceValue['response_transformer'] = query.responseTransformer;
                instanceValue['response_transformer_enabled'] = true;
            }
            else {
                instanceValue['response_transformer_enabled'] = false;
            }
        }
        var value = __assign({ body_type: HttpContentType.JSON }, defaults, instanceValue, overrides);
        this.patchValue(value);
        combineLatest(controlValue(this.controls.method), controlValue(this.controls.body_type), this.parametersControl
            ? controlValue(this.parametersControl).pipe(map(function () { return _this.parametersControl.serialize(); }), pairwise())
            : of([[], []]))
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var method = _a[0], bodyType = _a[1], _b = _a[2], parametersBefore = _b[0], parametersNew = _b[1];
            if (method != HttpMethod.GET && bodyType == HttpContentType.JSON) {
                var jsonBefore = _this.getDefaultBodyJson(parametersBefore);
                if (_this.controls.body_json.value == jsonBefore) {
                    var jsonNew = _this.getDefaultBodyJson(parametersNew);
                    _this.controls.body_json.patchValue(jsonNew);
                }
            }
        });
        combineLatest(controlValue(this.controls.body_type), controlValue(this.controls.body_json), this.parametersControl
            ? controlValue(this.parametersControl).pipe(map(function () { return _this.parametersControl.serialize(); }))
            : of([]))
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var bodyType = _a[0], bodyJson = _a[1], parameters = _a[2];
            var json = _this.getDefaultBodyJson(parameters);
            _this.isBodyJsonDefault$.next(bodyType == HttpContentType.JSON && bodyJson == json);
        });
        controlValue(this.controls.body_type)
            .pipe(untilDestroyed(this))
            .subscribe(function (bodyType) {
            _this.controls.form_data.typeEnabled = bodyType == HttpContentType.FormData && !useFileObjects;
        });
    };
    QueryBuilderHttpForm.prototype.toggleBodyTransformerVisible = function () {
        this.controls.body_transformer_visible.patchValue(!this.controls.body_transformer_visible.value);
    };
    QueryBuilderHttpForm.prototype.toggleErrorTransformerVisible = function () {
        this.controls.error_transformer_visible.patchValue(!this.controls.error_transformer_visible.value);
    };
    QueryBuilderHttpForm.prototype.isResponseTransformerChanged = function () {
        return this.controls.response_transformer.value != DefaultHttpQueryBodyTransformer;
    };
    QueryBuilderHttpForm.prototype.isErrorTransformerChanged = function () {
        return this.controls.error_transformer.value != DefaultHttpQueryErrorTransformer;
    };
    QueryBuilderHttpForm.prototype.isBodyTransformerChanged = function () {
        return this.controls.body_transformer.value != DefaultHttpQueryBodyTransformer;
    };
    QueryBuilderHttpForm.prototype.getDefaultBodyJson = function (parameters) {
        if (!parameters.length) {
            return '';
        }
        return "{\n" + parameters
            .map(function (item, i) {
            var result = "  \"" + item.name + "\": params." + item.name;
            if (i < parameters.length - 1) {
                result += ',';
            }
            return result;
        })
            .join('\n') + "\n}";
    };
    QueryBuilderHttpForm.prototype.setDefaultBodyJson = function () {
        var json = this.getDefaultBodyJson(this.parametersControl.serialize());
        this.controls.body_json.patchValue(json);
    };
    QueryBuilderHttpForm.prototype.urlPathToUrl = function () {
        var baseUrl = isSet(this.baseUrl) ? this.baseUrl : '';
        var urlPath = isSet(this.controls.url_path.value) ? this.controls.url_path.value : '';
        return baseUrl + urlPath;
    };
    QueryBuilderHttpForm.prototype.enableBaseURL = function () {
        this.controls.base_url_enabled.patchValue(true);
    };
    QueryBuilderHttpForm.prototype.disableBaseURL = function () {
        this.controls.base_url_enabled.patchValue(false);
        this.controls.url.patchValue(this.urlPathToUrl());
    };
    QueryBuilderHttpForm.prototype.getInstance = function () {
        var instance = new HttpQuery();
        instance.method = this.controls.method.value;
        if (this.controls.base_url_enabled.value) {
            instance.url = undefined;
            instance.urlPath = this.controls.url_path.value;
        }
        else {
            instance.url = this.controls.url.value;
            instance.urlPath = undefined;
        }
        instance.queryParams = this.controls.query_params.serialize();
        instance.headers = this.controls.headers.serialize();
        instance.bodyType = this.controls.body_type.value;
        instance.responseType = this.controls.response_type.value;
        instance.responsePath = this.controls.response_path.value.join('.');
        instance.requestResponse = this.controls.request_response.value;
        instance.requestResponseHeaders = this.controls.request_response_headers.value;
        instance.requestTokens = this.controls.request_tokens.value;
        var formData = instance.bodyType == HttpContentType.FormData || instance.bodyType == HttpContentType.FormUrlEncoded;
        if (formData) {
            instance.body = this.controls.form_data.serialize();
        }
        else if (instance.bodyType == HttpContentType.JSON) {
            instance.body = this.controls.body_json.value;
        }
        else if (instance.bodyType == HttpContentType.Raw || instance.bodyType == HttpContentType.GraphQL) {
            instance.body = this.controls.body_raw.value;
        }
        if (this.controls.response_transformer_enabled.value) {
            if (this.isResponseTransformerChanged()) {
                instance.responseTransformer = this.controls.response_transformer.value;
            }
            else {
                instance.responseTransformer = undefined;
            }
            instance.responsePath = undefined;
        }
        else {
            instance.responseTransformer = undefined;
            instance.responsePath = this.controls.response_path.value.join('.');
        }
        if (this.controls.error_transformer_visible.value) {
            instance.errorTransformer = this.controls.error_transformer.value;
        }
        else {
            instance.errorTransformer = undefined;
        }
        if (this.isBodyTransformerChanged()) {
            instance.bodyTransformer = this.controls.body_transformer.value;
        }
        return instance;
    };
    return QueryBuilderHttpForm;
}(FormGroup));
export { QueryBuilderHttpForm };
