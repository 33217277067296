import fromPairs from 'lodash/fromPairs';
import keys from 'lodash/keys';

import { capitalize, isSet, UnexpectedError } from '@shared';

import { Locale, LocaleItem } from './locale';
import { locale as azLocale } from './locale.az';
import { locale as beLocale } from './locale.be';
import { locale as bgLocale } from './locale.bg';
import { locale as bnLocale } from './locale.bn';
import { locale as bsLocale } from './locale.bs';
import { locale as csLocale } from './locale.cs';
import { locale as daLocale } from './locale.da';
import { locale as deLocale } from './locale.de';
import { locale as elLocale } from './locale.el';
import { locale as esLocale } from './locale.es';
import { locale as etLocale } from './locale.et';
import { locale as fiLocale } from './locale.fi';
import { locale as frLocale } from './locale.fr';
import { locale as hrLocale } from './locale.hr';
import { locale as huLocale } from './locale.hu';
import { locale as idLocale } from './locale.id';
import { locale as isLocale } from './locale.is';
import { locale as itLocale } from './locale.it';
import { locale as jaLocale } from './locale.ja';
import { locale as kaLocale } from './locale.ka';
import { locale as kkLocale } from './locale.kk';
import { locale as koLocale } from './locale.ko';
import { locale as ltLocale } from './locale.lt';
import { locale as lvLocale } from './locale.lv';
import { locale as mkLocale } from './locale.mk';
import { locale as msLocale } from './locale.ms';
import { locale as mtLocale } from './locale.mt';
import { locale as nbLocale } from './locale.nb';
import { locale as nlLocale } from './locale.nl';
import { locale as plLocale } from './locale.pl';
import { locale as ptLocale } from './locale.pt';
import { locale as ptBrLocale } from './locale.pt-br';
import { locale as roLocale } from './locale.ro';
import { locale as ruLocale } from './locale.ru';
import { locale as skLocale } from './locale.sk';
import { locale as slLocale } from './locale.sl';
import { locale as sqLocale } from './locale.sq';
import { locale as srLocale } from './locale.sr';
import { locale as svLocale } from './locale.sv';
import { locale as thLocale } from './locale.th';
import { locale as tlLocale } from './locale.tl';
import { locale as trLocale } from './locale.tr';
import { locale as ukLocale } from './locale.uk';
import { locale as uzLocale } from './locale.uz';
import { locale as viLocale } from './locale.vi';
import { locale as zhHansLocale } from './locale.zh-hans';
import { locale as zhHantLocale } from './locale.zh-hant';

export function localeReplaceKey(label: string, options: { context?: string } = {}): string {
  return [label, isSet(options.context) ? [options.context] : []].join('|');
}

export function localeItemKey(item: LocaleItem): string {
  if (typeof item.source == 'object') {
    return localeReplaceKey(item.source.label, { context: item.source.context });
  } else if (typeof item.source == 'string') {
    return localeReplaceKey(item.source);
  }
}

function mapLocale(locale: Locale, checkDuplicateKeys = false): { [label: string]: any } {
  const duplicates: string[] = [];
  const result = locale.items.reduce((acc, item) => {
    const key = localeItemKey(item);

    if (checkDuplicateKeys && acc.hasOwnProperty(key) && !duplicates.includes(key)) {
      duplicates.push(key);
    }

    acc[key] = item.target;
    return acc;
  }, {});

  if (checkDuplicateKeys && duplicates.length) {
    const items = duplicates.map(item => `"${item}"`).join(', ');
    throw new UnexpectedError(`Locale "${capitalize(locale.name)}" has duplicated items: ${items}`);
  }

  return result;
}

function checkLocaleMissingKeys() {
  const allKeys = keys(
    locales.reduce((acc, locale) => {
      const localeReplaces = localesReplaces[locale.language];
      if (localeReplaces) {
        keys(localeReplaces).forEach(key => (acc[key] = true));
      }
      return acc;
    }, {})
  );

  locales.forEach(locale => {
    const missing = allKeys.filter(key => {
      const localeReplaces = localesReplaces[locale.language];
      return localeReplaces && !isSet(localeReplaces[key]);
    });

    if (missing.length) {
      const items = missing.map(item => `"${item}"`).join(', ');
      const error = new UnexpectedError(`Locale "${capitalize(locale.name)}" has missing items: ${items}`);
      console.error(error);
    }
  });
}

export const locales = [
  sqLocale,
  azLocale,
  beLocale,
  bnLocale,
  bgLocale,
  bsLocale,
  zhHansLocale,
  zhHantLocale,
  hrLocale,
  csLocale,
  daLocale,
  nlLocale,
  etLocale,
  fiLocale,
  frLocale,
  deLocale,
  elLocale,
  kaLocale,
  huLocale,
  isLocale,
  idLocale,
  itLocale,
  jaLocale,
  kkLocale,
  koLocale,
  lvLocale,
  ltLocale,
  mkLocale,
  msLocale,
  mtLocale,
  nbLocale,
  plLocale,
  ptBrLocale,
  ptLocale,
  roLocale,
  ruLocale,
  srLocale,
  skLocale,
  slLocale,
  esLocale,
  svLocale,
  tlLocale,
  thLocale,
  trLocale,
  ukLocale,
  uzLocale,
  viLocale
];

export const localesReplaces: {
  [language: string]: { [label: string]: any };
} = fromPairs(locales.map(item => [item.language, mapLocale(item, true)]));

export const DEFAULT_LANGUAGE = 'en';

checkLocaleMissingKeys();
