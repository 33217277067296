<div *ngIf="!customView" class="grid__item-inner">
  <app-model-card
    [item]="item"
    [modelDescription]="modelDescription"
    [visibleColumns]="visibleColumns"
    [columns]="settings.dataSource?.columns || []"
    [columnActions]="settings.columnActions"
    [context]="context"
    [contextElement]="contextElement"
    [theme]="theme"
    (modelUpdated)="modelUpdated.next($event)"
  >
  </app-model-card>

  <div *ngIf="settings.modelActions.length" class="grid__item-buttons">
    <ng-container *ngFor="let action of settings.modelActions">
      <app-model-card-action
        class="grid__item-buttons-item"
        [model]="item.model"
        [action]="action"
        [context]="context"
        [contextElement]="contextElement"
        [accentColor]="accentColor"
        [theme]="theme"
        (click)="$event.stopPropagation()"
      >
      </app-model-card-action>
    </ng-container>
  </div>
</div>

<app-custom-element-auto
  *ngIf="customView"
  class="grid__item-view"
  [source]="customView.source"
  [customView]="customView"
  [actions]="settings.customActions"
  [params]="viewParams$ | async"
  [context]="context"
  [contextElement]="contextElement"
  [localContext]="localContext"
  [stateSelectedEnabled]="true"
  [stateSelected]="selected"
></app-custom-element-auto>
