var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import range from 'lodash/range';
import { Input, InputValueType, isInputSet } from '@modules/fields';
import { contextToFormulaValue, FieldInputControl } from '@modules/parameters';
import { ViewMapping } from '@modules/views';
import { EMPTY, isSet } from '@shared';
export function validateMapParametersSourceControl() {
    return function (control) {
        if (!control.isSet()) {
            return { local: ['Bindings not set'] };
        }
    };
}
var CustomViewMapParametersSourceControl = /** @class */ (function (_super) {
    __extends(CustomViewMapParametersSourceControl, _super);
    function CustomViewMapParametersSourceControl(target, state) {
        if (state === void 0) { state = {}; }
        var _this = _super.call(this, {
            source_parameter: new FormControl(isSet(state.sourceParameter) ? !!state.sourceParameter : undefined),
            source_parameter_input: new FieldInputControl({ path: ['value'] }),
            source_parameter_input_enabled: new FormControl(isSet(state.sourceParameterInput) ? !!state.sourceParameterInput : false)
        }, [validateMapParametersSourceControl()]) || this;
        _this.target = target;
        return _this;
    }
    CustomViewMapParametersSourceControl.prototype.patchValue = function (value, options) {
        _super.prototype.patchValue.call(this, value, options);
    };
    CustomViewMapParametersSourceControl.prototype.deserialize = function (value, options) {
        if (options === void 0) { options = {}; }
        this.instance = value;
        this.controls.source_parameter.patchValue(isSet(value.sourceParameter) ? value.sourceParameter : EMPTY, {
            emitEvent: options.emitEvent
        });
        this.controls.source_parameter_input.patchValue(value.sourceParameterInput ? value.sourceParameterInput.serializeWithoutPath() : {}, {
            emitEvent: options.emitEvent
        });
        this.controls.source_parameter_input_enabled.patchValue(!!value.sourceParameterInput, {
            emitEvent: options.emitEvent
        });
        this.markAsPristine();
    };
    CustomViewMapParametersSourceControl.prototype.isSet = function () {
        return this.controls.source_parameter_input_enabled.value
            ? isInputSet(this.controls.source_parameter_input.value)
            : isSet(this.controls.source_parameter.value);
    };
    CustomViewMapParametersSourceControl.prototype.isSourceParameterInputDefaultValueType = function () {
        var valueType = this.controls.source_parameter_input.controls.value_type.value;
        return !valueType || [InputValueType.Context, InputValueType.Formula].includes(valueType);
    };
    CustomViewMapParametersSourceControl.prototype.getSourceParameterInput = function () {
        return this.controls.source_parameter_input.value
            ? new Input().deserialize(this.controls.source_parameter_input.value)
            : undefined;
    };
    CustomViewMapParametersSourceControl.prototype.toggleSourceParameterInput = function () {
        var sourceParameter = this.controls.source_parameter.value;
        this.controls.source_parameter_input_enabled.patchValue(!this.controls.source_parameter_input_enabled.value);
        if (isSet(sourceParameter) && !isInputSet(this.controls.source_parameter_input.value)) {
            var formulaValue = contextToFormulaValue(['item', sourceParameter]);
            this.controls.source_parameter_input.controls.value_type.patchValue(InputValueType.Formula);
            this.controls.source_parameter_input.controls.formula_value.patchValue(formulaValue);
        }
    };
    CustomViewMapParametersSourceControl.prototype.serialize = function () {
        var instance = this.instance ? cloneDeep(this.instance) : new ViewMapping();
        instance.targetParameter = this.target.name;
        if (this.controls.source_parameter_input_enabled.value) {
            instance.sourceParameter = this.controls.source_parameter.value;
            instance.sourceParameterInput = this.getSourceParameterInput();
        }
        else {
            instance.sourceParameter =
                this.controls.source_parameter.value !== EMPTY ? this.controls.source_parameter.value : undefined;
            instance.sourceParameterInput = undefined;
        }
        return instance;
    };
    return CustomViewMapParametersSourceControl;
}(FormGroup));
export { CustomViewMapParametersSourceControl };
var CustomViewMapParametersForm = /** @class */ (function (_super) {
    __extends(CustomViewMapParametersForm, _super);
    function CustomViewMapParametersForm() {
        var _this = _super.call(this, []) || this;
        _this.sourceOptions = [];
        return _this;
    }
    CustomViewMapParametersForm.prototype.init = function (options) {
        this.sourceParameters = options.sourceParameters;
        this.sourceOptions = [
            {
                value: EMPTY,
                name: 'Empty value',
                icon: 'delete',
                subtitle: 'Use empty value'
            }
        ].concat(options.sourceParameters.map(function (item) {
            return {
                value: item.name,
                name: item.verboseName || item.name,
                icon: item.fieldDescription.icon,
                subtitle: item.fieldDescription.label
            };
        }));
        this.deserialize(options.parameters, options.mappings);
        this.markAsPristine();
    };
    CustomViewMapParametersForm.prototype.deserialize = function (parameters, mappings) {
        var _this = this;
        parameters.forEach(function (parameter, i) {
            var control = _this.controls[i];
            if (control) {
                control.target = parameter;
            }
            else {
                control = _this.appendControl(parameter);
            }
            var mapping = mappings ? mappings.find(function (item) { return item.targetParameter == parameter.name; }) : undefined;
            if (mapping) {
                control.deserialize(mapping);
            }
        });
        this.controls.slice(parameters.length).forEach(function (item) { return _this.removeControl(item); });
    };
    CustomViewMapParametersForm.prototype.setControls = function (controls) {
        var _this = this;
        this.removeControls();
        controls.forEach(function (item) { return _this.push(item); });
    };
    CustomViewMapParametersForm.prototype.removeControls = function () {
        var _this = this;
        range(this.controls.length).forEach(function () { return _this.removeAt(0); });
    };
    CustomViewMapParametersForm.prototype.removeControl = function (control) {
        var newControls = this.controls.filter(function (item) { return !isEqual(item, control); });
        this.setControls(newControls);
    };
    CustomViewMapParametersForm.prototype.appendControl = function (item, value) {
        var control = new CustomViewMapParametersSourceControl(item, value);
        this.push(control);
        return control;
    };
    CustomViewMapParametersForm.prototype.setDefaultEmpty = function () {
        this.controls
            .filter(function (control) { return !control.isSet(); })
            .forEach(function (control) {
            control.patchValue({
                source_parameter_input_enabled: false,
                source_parameter: EMPTY
            });
        });
    };
    CustomViewMapParametersForm.prototype.submit = function () {
        return this.controls.map(function (item) { return item.serialize(); });
    };
    return CustomViewMapParametersForm;
}(FormArray));
export { CustomViewMapParametersForm };
