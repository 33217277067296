<ng-template #label_template>
  <div class="sidebar-field__label-main">
    <span *ngIf="icon" class="sidebar-field__label-icon" [ngClass]="'icon-' + icon"></span>
    <span class="sidebar-field__label-title">
      {{ label | appCapitalize }}
    </span>
    <span *ngIf="required" class="sidebar-field__label-required">*</span>
    <span *ngIf="additional" class="sidebar-field__label-additional">{{ additional }}</span>
    <span *ngIf="tag" class="sidebar-field__label-tag" [ngClass]="'sidebar-field__label-tag_color_' + tagColor">{{
      tag
    }}</span>
    <app-help-icon
      *ngIf="documentation"
      [documentation]="documentation"
      [label]="documentationLabel"
      [left]="documentationLeft"
      [source]="documentationSource"
      class="sidebar-field__label-help"
    ></app-help-icon>
    <ng-content select="ng-container[actions]"></ng-content>
  </div>
  <div class="sidebar-field__label-right">
    <ng-content select="ng-container[actions-right]"></ng-content>
  </div>
</ng-template>

<ng-template #right_template>
  <div
    class="sidebar-field__right"
    [class.sidebar-field__right_hidden]="field_right.childNodes.length == 0"
    #field_right
  >
    <ng-content select="ng-container[right]"></ng-content>
  </div>
</ng-template>

<ng-template #content_template>
  <ng-content></ng-content>
</ng-template>

<ng-template #description_template>
  <div
    class="sidebar-field__description"
    [class.sidebar-field__description_hidden]="description.childNodes.length == 0"
    [class.sidebar-field__description_full-width]="fullWidth"
    #description
  >
    <ng-content select="ng-container[description]"></ng-content>
  </div>
</ng-template>

<div class="sidebar-field">
  <div *ngIf="!layoutLeft && !layoutRight" class="sidebar-field-layout-default">
    <div
      *ngIf="label"
      class="sidebar-field-layout-default__label sidebar-field__label"
      [class.sidebar-field-layout-default__label_full-width]="fullWidth"
    >
      <ng-container *ngTemplateOutlet="label_template"></ng-container>
    </div>
    <div *ngIf="subtitle" class="sidebar-field-layout-default__subtitle">
      {{ subtitle }}
    </div>
    <div class="sidebar-field-layout-default__content">
      <div class="sidebar-field-layout-default__content-main" [ngClass]="classes">
        <ng-container *ngTemplateOutlet="content_template"></ng-container>
      </div>
      <div class="sidebar-field-layout-default__content-right">
        <ng-container *ngTemplateOutlet="right_template"></ng-container>
      </div>
    </div>
    <div class="sidebar-field-layout-default__description">
      <ng-container *ngTemplateOutlet="description_template"></ng-container>
    </div>
  </div>

  <div *ngIf="!layoutLeft && layoutRight" class="sidebar-field-layout-right">
    <div class="sidebar-field-layout-right__main">
      <div *ngIf="label" class="sidebar-field-layout-right__label sidebar-field__label">
        <ng-container *ngTemplateOutlet="label_template"></ng-container>
      </div>
      <div class="sidebar-field-layout-right__content" [ngClass]="classes">
        <div class="sidebar-field-layout-right__content-inner">
          <ng-container *ngTemplateOutlet="content_template"></ng-container>
        </div>
      </div>
    </div>
    <div class="sidebar-field-layout-right__description">
      <ng-container *ngTemplateOutlet="description_template"></ng-container>
    </div>
  </div>

  <div *ngIf="layoutLeft && !layoutRight" class="sidebar-field-layout-left">
    <div class="sidebar-field-layout-left__main" [class.sidebar-field-layout-left__main_full-width]="fullWidth">
      <div class="sidebar-field-layout-left__content" [ngClass]="classes">
        <ng-container *ngTemplateOutlet="content_template"></ng-container>
      </div>
      <div *ngIf="label" class="sidebar-field-layout-left__label sidebar-field__label">
        <ng-container *ngTemplateOutlet="label_template"></ng-container>
      </div>
      <div class="sidebar-field-layout-left__right">
        <ng-container *ngTemplateOutlet="right_template"></ng-container>
      </div>
    </div>
    <div class="sidebar-field-layout-left__description">
      <ng-container *ngTemplateOutlet="description_template"></ng-container>
    </div>
  </div>
</div>
