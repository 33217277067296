var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import { applyCollapseElementStyles, CollapseIndicatorPosition, getCollapseElementStyles } from '@modules/customize';
import { Input } from '@modules/fields';
import { FieldInputControl } from '@modules/parameters';
import { isSet } from '@shared';
import { CollapseElementStylesControl } from '../styles-collapse-element-edit/collapse-element-styles.control';
var CustomizeBarCollapseEditForm = /** @class */ (function (_super) {
    __extends(CustomizeBarCollapseEditForm, _super);
    function CustomizeBarCollapseEditForm(injector) {
        var _this = _super.call(this, {
            name: new FormControl(''),
            title: new FieldInputControl({ path: ['value'] }),
            icon: new FormControl(null),
            opened_input: new FieldInputControl({ path: ['value'] }),
            tooltip: new FormControl(''),
            collapse_indicator_position: new FormControl(CollapseIndicatorPosition.Right),
            visible_input: new FieldInputControl({ path: ['value'] }),
            card_wrap: new FormControl(true),
            element_styles: new CollapseElementStylesControl(injector)
        }) || this;
        _this.injector = injector;
        _this.collapseIndicatorPositionOptions = [
            {
                value: CollapseIndicatorPosition.None,
                name: 'None'
            },
            {
                value: CollapseIndicatorPosition.Left,
                name: 'Left'
            },
            {
                value: CollapseIndicatorPosition.Right,
                name: 'Right'
            }
        ];
        return _this;
    }
    CustomizeBarCollapseEditForm.prototype.init = function (element) {
        this.element = element;
        this.controls.name.patchValue(element.name ? element.name : 'Collapse');
        this.controls.title.patchValue(element.titleInput ? element.titleInput.serializeWithoutPath() : {});
        this.controls.icon.patchValue(element.icon);
        this.controls.opened_input.patchValue(element.openedInput ? element.openedInput.serializeWithoutPath() : {});
        this.controls.tooltip.patchValue(element.tooltip);
        this.controls.collapse_indicator_position.patchValue(element.collapseIndicatorPosition || CollapseIndicatorPosition.Right);
        this.controls.visible_input.patchValue(element.visibleInput ? element.visibleInput.serializeWithoutPath() : {});
        this.controls.card_wrap.patchValue(element.cardWrap);
        var elementStyles = getCollapseElementStyles(element);
        this.controls.element_styles.deserialize(elementStyles);
        this.markAsPristine();
    };
    CustomizeBarCollapseEditForm.prototype.submit = function () {
        var instance = cloneDeep(this.element);
        instance.name = this.controls.name.value;
        instance.titleInput = this.controls.title.value ? new Input().deserialize(this.controls.title.value) : undefined;
        instance.icon = this.controls.icon.value;
        if (this.controls.opened_input.value) {
            instance.openedInput = new Input().deserialize(this.controls.opened_input.value);
        }
        else {
            instance.openedInput = undefined;
        }
        instance.tooltip = isSet(this.controls.tooltip.value) ? this.controls.tooltip.value.trim() : undefined;
        instance.collapseIndicatorPosition = this.controls.collapse_indicator_position.value;
        if (this.controls.visible_input.value) {
            instance.visibleInput = new Input().deserialize(this.controls.visible_input.value);
        }
        else {
            instance.visibleInput = undefined;
        }
        instance.cardWrap = this.controls.card_wrap.value;
        var elementStyles = this.controls.element_styles.serialize();
        applyCollapseElementStyles(instance, elementStyles);
        return instance;
    };
    return CustomizeBarCollapseEditForm;
}(FormGroup));
export { CustomizeBarCollapseEditForm };
