<app-custom-view
  *ngIf="view"
  [view]="view"
  [parameters]="parameters"
  [actionOutputs]="actionOutputs"
  [actions]="actions"
  [params]="params"
  [parentContext]="parentContext"
  [localContext]="localContext"
  [stateSelectedEnabled]="stateSelectedEnabled"
  [stateSelected]="stateSelected"
></app-custom-view>

<ng-container *ngIf="!view">
  This component is not configured yet
</ng-container>
