<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayPanelClass]="['overlay', 'overlay_horizontal_center']"
  [cdkConnectedOverlayPositions]="popoverPositions"
  [cdkConnectedOverlayOrigin]="origin"
  [cdkConnectedOverlayOpen]="dropdownOpened"
  [cdkConnectedOverlayPush]="true"
  [cdkConnectedOverlayGrowAfterOpen]="true"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayBackdropClass]="'popover2-backdrop'"
  (backdropClick)="setDropdownOpened(false)"
  (detach)="setDropdownOpened(false)"
>
  <div class="popover2 overlay-content overlay-content_animated">
    <div class="popover2__inner popover2__inner_sidebar">
      <div class="popover2__viewport popover2__viewport_scrollable" [style.max-height.vh]="80" #scrollable_element>
        <div class="custom-view-dropdown">
          <div class="custom-view-dropdown__search">
            <span class="input-icon input-icon_small input-icon_interactive input-icon_left icon-search"></span>

            <a
              *ngIf="searchControl.value | appIsSet"
              href="javascript:void(0)"
              class="input-icon input-icon_small input-icon_interactive input-icon_right icon-close"
              (click)="clearSearch()"
            ></a>

            <input
              class="input input_fill input_small input_icon-left input_icon-right"
              type="text"
              placeholder="Search..."
              [formControl]="searchControl"
              [appAutofocus]="true"
              (keydown)="$event.stopPropagation(); onSearch()"
              (keyup)="onSearchKey($event)"
            />
          </div>

          <div [style.width.px]="(thumbWidth + thumbPadding * 2 + thumbMargin * 2) * thumbsPerRow">
            <div *ngIf="customViewsFiltered.length" class="custom-view-dropdown__items">
              <app-custom-view-dropdown-item
                *ngFor="let item of customViewsFiltered; trackBy: trackCustomViewFn"
                class="custom-view-dropdown__item"
                [item]="item"
                [active]="value == item.uniqueName"
                [thumbWidth]="thumbWidth"
                [thumbHeight]="thumbHeight"
                [thumbPadding]="thumbPadding"
                (selectItem)="selectItem.emit(item); setDropdownOpened(false)"
              ></app-custom-view-dropdown-item>
            </div>

            <div *ngIf="!customViewsFiltered.length" class="custom-view-dropdown__empty">
              Nothing found
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
