import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  Provider
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { localize } from '@common/localize';
import { NotificationService } from '@common/notifications';
import { BasePopupComponent } from '@common/popups';
import { ACTION_SERVICE_EXPORT_COMPONENT } from '@modules/action-queries';
import { TintStyle } from '@modules/actions';
import { ServerRequestError } from '@modules/api';
import { ViewContext, ViewContextElement } from '@modules/customize';
import { ListModelDescriptionDataSource } from '@modules/data-sources';
import { Option } from '@modules/field-components';
import { applyParamInput } from '@modules/fields';
import { FilterItem2, Sort } from '@modules/filters';
import { ColumnsModelListStore } from '@modules/list';
import { Model, PAGE_PARAM } from '@modules/models';
import { ModelListStore } from '@modules/models-list';
import { GetQueryOptions, paramsToGetQueryOptions } from '@modules/resources';
import { errorToString, isSet } from '@shared';

import { exportFormats, ExportFormatType } from '../../data/export-formats';
import { ExportService } from '../../services/export/export.service';

export const ACTION_SERVICE_EXPORT_COMPONENT_PROVIDER: Provider = {
  provide: ACTION_SERVICE_EXPORT_COMPONENT,
  useFactory: actionServiceExportComponentFactory
};

export function actionServiceExportComponentFactory(): any {
  return ExportComponent;
}

@Component({
  selector: 'app-export',
  templateUrl: './export.component.html',
  providers: [ModelListStore, ColumnsModelListStore, ExportService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExportComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() dataSource: ListModelDescriptionDataSource;
  @Input() queryOptions: {
    filters?: FilterItem2[];
    search?: string;
    sort?: Sort[];
  };
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() localContext: Object;
  @Input() params: Object;
  @Input() primaryKey: string;
  @Input() ids: number[];
  @Input() inverseIds = false;
  @Input() theme = false;

  loading = true;
  formatTypeControl = new FormControl(undefined, Validators.required);
  exportFormats: Option<ExportFormatType>[] = exportFormats.map(item => {
    return { value: item.type, name: item.label };
  });
  items: Model[];
  count: number;
  executeActionStarted = false;
  executeActionProgress = 0;
  executeActionSucceeded = false;
  tintStyles = TintStyle;

  constructor(
    private exportService: ExportService,
    private modelListStore: ModelListStore,
    private popupComponent: BasePopupComponent,
    private notificationService: NotificationService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    const queryOptions = this.getGetQueryOptions();
    let staticData = [];

    try {
      staticData = applyParamInput(this.dataSource.input, {
        context: this.context,
        contextElement: this.contextElement,
        localContext: this.localContext,
        defaultValue: []
      });
    } catch (e) {}

    this.modelListStore.dataSource = this.dataSource;
    this.modelListStore.queryOptions = queryOptions;
    this.modelListStore.staticData = staticData;
    this.modelListStore.context = this.context;
    this.modelListStore.contextElement = this.contextElement;
    this.modelListStore.localContext = this.localContext;

    this.modelListStore
      .getNext()
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          this.loading = false;
          this.items = this.modelListStore.items;
          this.count = this.modelListStore.count;
          this.cd.markForCheck();
        },
        () => {
          this.loading = false;
          this.cd.markForCheck();
        }
      );
  }

  ngOnDestroy(): void {}

  getQueryParams() {
    let params = {};

    if (this.primaryKey && this.ids && this.ids.length) {
      if (this.inverseIds) {
        params[`exclude__${this.primaryKey}__in`] = this.ids;
      } else {
        params[`${this.primaryKey}__in`] = this.ids;
      }
    }

    params = { ...this.params, ...params };

    if (params[PAGE_PARAM] != undefined) {
      delete params[PAGE_PARAM];
    }

    return params;
  }

  getGetQueryOptions(): GetQueryOptions {
    const queryParams = this.getQueryParams();
    const queryOptions: GetQueryOptions = queryParams ? paramsToGetQueryOptions(queryParams) : {};

    queryOptions.filters = [
      ...(this.queryOptions && this.queryOptions.filters ? this.queryOptions.filters : []),
      ...(queryOptions.filters ? queryOptions.filters : [])
    ];

    if (!isSet(queryOptions.search) && this.queryOptions && isSet(this.queryOptions.search)) {
      queryOptions.search = this.queryOptions.search;
    }

    queryOptions.sort = [
      ...(this.queryOptions && this.queryOptions.sort ? this.queryOptions.sort : []),
      ...(queryOptions.sort ? queryOptions.sort : [])
    ];

    return queryOptions;
  }

  get executeActionStatus() {
    if (this.executeActionProgress === undefined || this.executeActionProgress < 1) {
      return localize('Exporting') + '...';
    } else if (this.executeActionSucceeded) {
      return localize('Export completed');
    } else if (!this.executeActionSucceeded) {
      return localize('Failed to export');
    }
  }

  exportData() {
    if (!this.formatTypeControl.value || this.executeActionStarted) {
      return;
    }

    this.executeActionStarted = true;
    this.executeActionProgress = 0;
    this.cd.markForCheck();

    const queryOptions = this.getGetQueryOptions();

    this.exportService
      .exportModels(
        this.title,
        this.dataSource,
        queryOptions,
        this.formatTypeControl.value,
        this.context,
        this.contextElement,
        this.localContext
      )
      .pipe(untilDestroyed(this))
      .subscribe(
        progress => {
          this.executeActionProgress = progress;
          this.executeActionSucceeded = progress == 1;
          this.cd.markForCheck();
        },
        error => {
          console.error(error);

          if (error instanceof ServerRequestError && error.errors.length) {
            this.notificationService.error('Export Failed', error.errors[0]);
          } else {
            this.notificationService.error('Export Failed', errorToString(error));
          }

          this.executeActionProgress = 1;
          this.executeActionSucceeded = false;
          this.cd.markForCheck();
        }
      );
  }

  close() {
    this.exportService.cancel = true;
    this.popupComponent.close();
  }
}
