import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { FormControl } from '@angular/forms';
import * as JSZip from 'jszip';
import values from 'lodash/values';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { from, of } from 'rxjs';
import { delay, switchMap } from 'rxjs/operators';

import { CustomViewFile, CustomViewFileType, CustomViewService } from '@modules/custom-views';

@Component({
  selector: 'app-change-custom-view-files',
  templateUrl: './change-custom-view-files.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
// TODO: Remove unused
export class ChangeCustomViewFilesComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() distControl: FormControl;
  @Input() distFilesControl: FormControl;
  @Input() filesControl: FormControl;

  files: CustomViewFile[];

  constructor(private customViewService: CustomViewService, private cd: ChangeDetectorRef) {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {}

  ngAfterViewInit(): void {
    this.distControl.valueChanges.pipe(delay(0), untilDestroyed(this)).subscribe(() => this.updateFiles());

    if (this.distFilesControl) {
      this.distFilesControl.valueChanges.pipe(delay(0), untilDestroyed(this)).subscribe(() => this.updateFiles());
    }

    this.updateFiles();
  }

  updateFiles() {
    const zip = new JSZip();
    const value = this.filesControl.value;
    const valueJs = value['js'] || [];
    const valueCss = value['css'] || [];
    let obs;

    if (this.distFilesControl && this.distFilesControl.value) {
      obs = of(this.distFilesControl.value);
    } else if (this.distControl.value instanceof File) {
      obs = of(this.distControl.value);
    } else if (typeof this.distControl.value == 'string') {
      obs = this.customViewService.downloadDist(this.distControl.value);
    } else {
      this.files = undefined;
      this.cd.markForCheck();
      return;
    }

    obs
      .pipe(
        switchMap(file => from(zip.loadAsync(file))),
        untilDestroyed(this)
      )
      .subscribe(
        result => {
          this.files = values(result.files)
            .filter(item => !item.dir)
            .map(item => {
              const extension = item.name.split('.').pop().toLowerCase();
              let fileType = CustomViewFileType.Unknown;
              let checked = false;

              if (extension == 'js') {
                fileType = CustomViewFileType.JS;
                checked = valueJs.includes(item.name);
              } else if (extension == 'css') {
                fileType = CustomViewFileType.CSS;
                checked = valueCss.includes(item.name);
              }

              const disabled = fileType == CustomViewFileType.Unknown;

              return {
                name: item.name,
                fileType: fileType,
                disabled: disabled,
                checked: checked && !disabled
              };
            })
            .sort((lhs, rhs) => {
              let lhsIndex = -1;
              let rhsIndex = -1;

              if (lhs.fileType == CustomViewFileType.JS) {
                lhsIndex = valueJs.indexOf(lhs.name);
              } else if (lhs.fileType == CustomViewFileType.CSS) {
                lhsIndex = valueCss.indexOf(lhs.name);
              }

              if (rhs.fileType == CustomViewFileType.JS) {
                rhsIndex = valueJs.indexOf(rhs.name);
              } else if (rhs.fileType == CustomViewFileType.CSS) {
                rhsIndex = valueCss.indexOf(rhs.name);
              }

              return (lhs.checked && !rhs.checked) ||
                (lhs.checked == rhs.checked &&
                  lhs.fileType == CustomViewFileType.JS &&
                  rhs.fileType == CustomViewFileType.CSS) ||
                (lhs.checked == rhs.checked && lhs.fileType == rhs.fileType && lhsIndex < rhsIndex)
                ? -1
                : lhsIndex == rhsIndex && lhs.checked == rhs.checked && lhs.fileType == rhs.fileType
                ? 0
                : 1;
            });
          this.cd.markForCheck();
        },
        error => {
          this.files = undefined;
          this.cd.markForCheck();
          console.error('error', error);
        }
      );
  }

  updateParams() {
    const js = this.files.filter(item => item.fileType == CustomViewFileType.JS && item.checked).map(item => item.name);
    const css = this.files
      .filter(item => item.fileType == CustomViewFileType.CSS && item.checked)
      .map(item => item.name);

    this.setFiles(js, css);
  }

  setFiles(js: string[], css: string[]) {
    const params = {
      ...this.filesControl.value,
      js: js,
      css: css
    };

    this.filesControl.patchValue(params);
    this.filesControl.markAsDirty();
  }

  onOrderingUpdated(result) {
    this.files = result.map(item => item.data);
    this.cd.markForCheck();
    this.updateParams();
  }

  setAllSelect(select) {
    this.files = this.files.map(item => {
      if (!item.disabled) {
        item.checked = select;
      }

      return item;
    });
    this.cd.markForCheck();
    this.updateParams();
  }
}
