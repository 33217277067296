import { ElementType } from '@modules/customize';
import { ChartType, WidgetType } from '@modules/dashboard';

export const changeCustomizeBarComponentsCharts = [
  {
    title: 'Line',
    image: 'line_chart',
    action: 'Add Chart',
    type: ElementType.Widget,
    defaultParams: {
      widget: {
        widget_type: WidgetType.Chart,
        name: 'Line Chart',
        params: JSON.stringify({
          chart_type: ChartType.Line
        })
      }
    }
  },
  {
    title: 'Bar',
    image: 'bar_chart',
    action: 'Add Chart',
    type: ElementType.Widget,
    defaultParams: {
      widget: {
        widget_type: WidgetType.Chart,
        name: 'Bar Chart',
        params: JSON.stringify({
          chart_type: ChartType.Bar
        })
      }
    }
  },
  {
    title: 'Bar (H)',
    image: 'bar_chart_horizontal',
    action: 'Add Chart',
    type: ElementType.Widget,
    defaultParams: {
      widget: {
        widget_type: WidgetType.Chart,
        name: 'Bar Chart (Horizontal)',
        params: JSON.stringify({
          chart_type: ChartType.BarHorizontal
        })
      }
    }
  },
  {
    title: 'Stacked',
    image: 'stacked_bar_chart',
    action: 'Add Chart',
    type: ElementType.Widget,
    defaultParams: {
      widget: {
        widget_type: WidgetType.Chart,
        name: 'Stacked Bar Chart',
        params: JSON.stringify({
          chart_type: ChartType.StackedBar
        })
      }
    }
  },
  {
    title: 'Stacked (H)',
    image: 'stacked_bar_chart_horizontal',
    action: 'Add Chart',
    type: ElementType.Widget,
    defaultParams: {
      widget: {
        widget_type: WidgetType.Chart,
        name: 'Stacked Bar Chart (Horizontal)',
        params: JSON.stringify({
          chart_type: ChartType.StackedBarHorizontal
        })
      }
    }
  },
  {
    title: 'Pie',
    image: 'pie_chart',
    action: 'Add Chart',
    type: ElementType.Widget,
    defaultParams: {
      widget: {
        widget_type: WidgetType.Chart,
        name: 'Pie Chart',
        params: JSON.stringify({
          chart_type: ChartType.Pie
        })
      }
    }
  },
  {
    title: 'Doughnut',
    image: 'doughnut_chart',
    action: 'Add Chart',
    type: ElementType.Widget,
    defaultParams: {
      widget: {
        widget_type: WidgetType.Chart,
        name: 'Doughnut Chart',
        params: JSON.stringify({
          chart_type: ChartType.Doughnut
        })
      }
    }
  },
  {
    title: 'Single Value',
    image: 'single_value_2',
    action: 'Add Chart',
    type: ElementType.Widget,
    defaultParams: {
      widget: {
        widget_type: WidgetType.Value,
        name: 'Single Value',
        params: JSON.stringify({
          y_column: {}
        })
      }
    }
  },
  {
    title: 'Radar',
    image: 'radar_chart',
    action: 'Add Chart',
    type: ElementType.Widget,
    defaultParams: {
      widget: {
        widget_type: WidgetType.Chart,
        name: 'Radar Chart',
        params: JSON.stringify({
          chart_type: ChartType.Radar
        })
      }
    }
  },
  {
    title: 'Polar Area',
    image: 'polar_area_chart',
    action: 'Add Chart',
    type: ElementType.Widget,
    defaultParams: {
      widget: {
        widget_type: WidgetType.Chart,
        name: 'Polar Area Chart',
        params: JSON.stringify({
          chart_type: ChartType.PolarArea
        })
      }
    }
  },
  {
    title: 'Scatter',
    image: 'scatter_chart',
    action: 'Add Chart',
    type: ElementType.Widget,
    defaultParams: {
      widget: {
        widget_type: WidgetType.Chart,
        name: 'Scatter Chart',
        params: JSON.stringify({
          chart_type: ChartType.Scatter
        })
      }
    }
  },
  {
    title: 'Bubble',
    image: 'bubble_chart',
    action: 'Add Chart',
    type: ElementType.Widget,
    defaultParams: {
      widget: {
        widget_type: WidgetType.Chart,
        name: 'Bubble Chart',
        params: JSON.stringify({
          chart_type: ChartType.Bubble
        })
      }
    }
  }
];
