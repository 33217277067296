<div
  class="timeline__item"
  [class.timeline__item_clickable]="true"
  [class.timeline__item_selected]="selected"
  [style.margin-bottom.px]="(settings.gapVertical | appIsSet) ? settings.gapVertical : null"
  (click)="onClick($event, root_element)"
  #root_element
>
  <div *ngIf="!customView" class="timeline__item-inner">
    <app-model-card
      [item]="item"
      [modelDescription]="modelDescription"
      [visibleColumns]="visibleColumns"
      [columns]="settings.dataSource?.columns || []"
      [columnActions]="settings.columnActions"
      [labels]="!settings.hideLabels"
      [compact]="true"
      [context]="context"
      [contextElement]="contextElement"
      [theme]="theme"
    >
    </app-model-card>

    <div *ngIf="settings.modelActions.length" class="timeline__item-buttons">
      <ng-container *ngFor="let action of settings.modelActions">
        <app-model-card-action
          class="timeline__item-buttons-item"
          [model]="item.model"
          [action]="action"
          [context]="context"
          [contextElement]="contextElement"
          [accentColor]="accentColor"
          [theme]="theme"
          (click)="$event.stopPropagation()"
        >
        </app-model-card-action>
      </ng-container>
    </div>
  </div>

  <app-custom-element-auto
    *ngIf="customView"
    class="timeline__item-view"
    [source]="customView.source"
    [customView]="customView"
    [actions]="settings.customActions"
    [params]="viewParams$ | async"
    [context]="context"
    [contextElement]="contextElement"
    [localContext]="localContext"
    [stateSelectedEnabled]="true"
    [stateSelected]="selected"
  ></app-custom-element-auto>

  <div class="timeline__item-title">
    <app-auto-field
      *ngIf="dateColumnListItem"
      [field]="createField(dateColumnListItem.column)"
      [readonly]="true"
      [value]="item.model.getAttribute(dateColumnListItem.column.name)"
      [label]="false"
      [context]="contextElement?.context"
    >
    </app-auto-field>
    <ng-container *ngIf="!dateColumnListItem">&nbsp;</ng-container>
  </div>
  <div class="timeline__item-dot" [class.timeline__item-dot_icon]="first" [class.icon-time]="first"></div>
</div>
