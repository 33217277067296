import { Injectable, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { delay, map, switchMap } from 'rxjs/operators';

import { CustomView, CustomViewService, CustomViewSource, CustomViewsStore } from '@modules/custom-views';
import { CustomElementItem, MarginControl, ViewContext } from '@modules/customize';
import { ElementConfigurationService } from '@modules/customize-configuration';
import { Input, isRequiredInputsSet, ParameterArray, ParameterField } from '@modules/fields';
import { FieldInputControl, InputFieldProvider, parametersToProviderItems } from '@modules/parameters';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { controlValue, isSet } from '@shared';

import { CustomViewDefaults } from '../../data/custom-view-defaults';
import { ActionOutputFormGroup } from '../../forms/action-output.form-group';

export function validateDistFile(): ValidatorFn {
  return control => {
    const parent = control.parent as CustomizeBarCustomElementEditForm;

    if (!parent) {
      return;
    }

    const dist = parent.controls.dist.value;

    if (!control.value && !dist) {
      return { required: true };
    }
  };
}

export function validateTagName(): ValidatorFn {
  return control => {
    const parent = control.parent as CustomizeBarCustomElementEditForm;

    if (!parent) {
      return;
    }

    if (!control.value) {
      return { required: true };
    }
  };
}

export function validateRequiredFiles(): ValidatorFn {
  return control => {
    const parent = control.parent as CustomizeBarCustomElementEditForm;

    if (!parent) {
      return;
    }

    if (!control.value || !control.value.length) {
      return { required: true };
    }
  };
}

export function validateInputs(): ValidatorFn {
  return control => {
    const parent = control.parent as CustomizeBarCustomElementEditForm;

    if (!parent) {
      return;
    }

    const fields = parent.inputFieldProvider.getFields();
    const inputs: Input[] = control.value;

    if (!isRequiredInputsSet(fields, inputs)) {
      return { required: true };
    }
  };
}

@Injectable()
export class CustomizeBarCustomElementEditForm extends FormGroup implements OnDestroy {
  controls: {
    name: FormControl;
    source: FormControl;
    custom_view: FormControl;
    dist: FormControl;
    dist_file: FormControl;
    tag_name: FormControl;
    files_js: FormControl;
    files_css: FormControl;
    parameters: ParameterArray;
    inputs: FormControl;
    actions: ActionOutputFormGroup;
    width_fluid: FormControl;
    height_fluid: FormControl;
    visible_input: FieldInputControl;
    card_wrap: FormControl;
    margin: MarginControl;
  };

  element: CustomElementItem;
  context: ViewContext;

  inputFieldProvider = new InputFieldProvider();

  constructor(
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private customViewService: CustomViewService,
    private customViewsStore: CustomViewsStore,
    private elementConfigurationService: ElementConfigurationService
  ) {
    super({
      name: new FormControl(''),
      source: new FormControl(CustomViewSource.CustomElement),
      custom_view: new FormControl(''),
      dist: new FormControl(''),
      dist_file: new FormControl('', validateDistFile()),
      tag_name: new FormControl('', validateTagName()),
      files_js: new FormControl([], validateRequiredFiles()),
      files_css: new FormControl([]),
      parameters: new ParameterArray([]),
      inputs: new FormControl([], validateInputs()),
      actions: new ActionOutputFormGroup(elementConfigurationService),
      width_fluid: new FormControl(false),
      height_fluid: new FormControl(false),
      visible_input: new FieldInputControl({ path: ['value'] }),
      card_wrap: new FormControl(true),
      margin: new MarginControl()
    });
  }

  ngOnDestroy(): void {
    this.inputFieldProvider.clearProvider();
  }

  init(element: CustomElementItem, options: { context?: ViewContext } = {}): Observable<void> {
    this.element = element;
    this.context = options.context;

    const customView$ = isSet(element.customView)
      ? this.customViewsStore.getDetailFirst(element.customView)
      : of(undefined);

    return customView$.pipe(
      map(customView => {
        this.controls.name.patchValue(element.name ? element.name : 'JavaScript');
        this.controls.source.patchValue(element.source ? element.source : CustomViewSource.CustomElement);
        this.controls.custom_view.patchValue(element.customView);
        this.controls.dist.patchValue(customView ? customView.dist : undefined);
        this.controls.tag_name.patchValue(customView ? customView.tagName : undefined);
        this.controls.files_js.patchValue(customView ? customView.filesJs : []);
        this.controls.files_css.patchValue(customView ? customView.filesCss : []);
        // Backward compatibility
        this.controls.parameters.patchValue(
          customView && customView.parameters.length ? customView.parameters : element.parameters
        );
        this.controls.inputs.patchValue(element.inputs);
        this.controls.actions.deserialize(customView ? customView.actions : [], element.actions);
        this.controls.width_fluid.patchValue(element.widthFluid);
        this.controls.height_fluid.patchValue(element.heightFluid);
        this.controls.visible_input.patchValue(element.visibleInput ? element.visibleInput.serializeWithoutPath() : {});
        this.controls.card_wrap.patchValue(element.cardWrap);
        this.controls.margin.patchValue(element.margin);

        this.updateInputFieldProvider();
        this.trackChanges();

        this.markAsPristine();
      })
    );
  }

  updateInputFieldProvider() {
    this.inputFieldProvider.setProvider(
      controlValue<ParameterField[]>(this.controls.parameters).pipe(
        map(parameters => {
          return parameters ? parametersToProviderItems(parameters) : [];
        })
      )
    );
  }

  trackChanges() {
    this.controls.dist.valueChanges
      .pipe(delay(0))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.controls.dist_file.updateValueAndValidity();
      });

    this.inputFieldProvider
      .getFields$()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.controls.inputs.updateValueAndValidity();
      });
  }

  getCustomView$(): Observable<CustomView> {
    return controlValue<string>(this.controls.custom_view).pipe(
      switchMap(customView => {
        return isSet(customView) ? this.customViewsStore.getDetail(customView) : of(undefined);
      })
    );
  }

  getCustomViewDefaults(): CustomViewDefaults {
    return {
      uniqueName: CustomView.generateUniqueName(),
      pageUid: this.context && this.context.viewSettings ? this.context.viewSettings.uid : undefined,
      elementUid: this.element.uid
    };
  }

  submit(): CustomElementItem {
    const instance = cloneDeep(this.element) as CustomElementItem;

    instance.name = this.controls.name.value;
    instance.source = this.controls.source.value;
    instance.customView = this.controls.custom_view.value;
    instance.inputs = this.controls.inputs.value;
    instance.actions = this.controls.actions.serialize();
    instance.widthFluid = this.controls.width_fluid.value;
    instance.heightFluid = this.controls.height_fluid.value;

    if (this.controls.visible_input.value) {
      instance.visibleInput = new Input().deserialize(this.controls.visible_input.value);
    } else {
      instance.visibleInput = undefined;
    }

    instance.cardWrap = this.controls.card_wrap.value;
    instance.margin = this.controls.margin.value;

    return instance;
  }
}
