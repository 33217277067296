import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import fromPairs from 'lodash/fromPairs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { NotificationService } from '@common/notifications';
import { ActionControllerService } from '@modules/action-queries';
import { CustomView, CustomViewsStore } from '@modules/custom-views';
import { getModelAttributesByColumns, MapSettings, ViewContext, ViewContextElement } from '@modules/customize';
import { applyParamInput$, DisplayField, ParameterField } from '@modules/fields';
import { ITEM_OUTPUT, ListItem } from '@modules/list';
import { Model, ModelDescription } from '@modules/models';
import { CurrentProjectStore } from '@modules/projects';
import { EMPTY, isSet, TypedChanges } from '@shared';

@Component({
  selector: 'app-map-popup',
  templateUrl: './map-popup.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MapPopupComponent implements OnInit, OnDestroy, OnChanges {
  @Input() item: ListItem;
  @Input() modelDescription: ModelDescription;
  @Input() settings: MapSettings;
  @Input() visibleColumns: DisplayField[] = [];
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() accentColor: string;
  @Input() theme = false;
  @Output() modelUpdated = new EventEmitter<Model>();
  @Output() close = new EventEmitter<void>();

  settings$ = new BehaviorSubject<MapSettings>(undefined);
  customView: CustomView;
  viewParameters: ParameterField[] = [];
  viewParams$: Observable<Object> = of({});
  localContext: Object = {};

  constructor(
    public currentProjectStore: CurrentProjectStore,
    private actionControllerService: ActionControllerService,
    private customViewsStore: CustomViewsStore,
    private notificationService: NotificationService,
    private injector: Injector,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.settings$
      .pipe(
        filter(settings => !!settings),
        switchMap(settings => {
          if (!isSet(settings.cardCustomView)) {
            return of(undefined);
          }

          return this.customViewsStore.getDetail(settings.cardCustomView);
        }),
        untilDestroyed(this)
      )
      .subscribe(customView => {
        this.customView = customView;
        this.cd.markForCheck();
        this.updateViewParameters();
        this.updateViewParams();
      });
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<MapPopupComponent>): void {
    if (changes.item) {
      this.localContext = {
        [ITEM_OUTPUT]: this.item.model.getAttributes()
      };
    }

    if (changes.settings) {
      this.settings$.next(this.settings);
      this.updateViewParameters();
    }

    if (changes.settings || changes.item) {
      this.updateViewParams();
    }
  }

  updateViewParameters() {
    if (!this.customView) {
      this.viewParameters = [];
      this.cd.markForCheck();
      return;
    }

    const columns = this.settings.dataSource ? this.settings.dataSource.columns : [];

    this.viewParameters = columns.map(item => {
      const parameter = new ParameterField();

      parameter.name = item.name;
      parameter.verboseName = item.verboseName;
      parameter.field = item.field;
      parameter.params = item.params;

      return parameter;
    });
    this.cd.markForCheck();
  }

  updateViewParams() {
    if (!this.customView) {
      this.viewParams$ = of({});
      this.cd.markForCheck();
      return;
    }

    const columns = this.settings.dataSource ? this.settings.dataSource.columns : [];
    const modelParams = getModelAttributesByColumns(this.item.model, columns);

    this.viewParams$ = combineLatest(
      this.settings.cardCustomViewMappings.map(mapping => {
        if (mapping.sourceParameterInput) {
          return applyParamInput$(mapping.sourceParameterInput, {
            context: this.context,
            contextElement: this.contextElement,
            localContext: {
              [ITEM_OUTPUT]: modelParams
            }
          }).pipe(
            map(value => {
              return {
                name: mapping.targetParameter,
                value: value !== EMPTY ? value : undefined
              };
            })
          );
        } else {
          const value = modelParams[mapping.sourceParameter];
          return of({
            name: mapping.targetParameter,
            value: value
          });
        }
      })
    ).pipe(
      map(values => {
        return fromPairs(values.map(item => [item.name, item.value]));
      })
    );
    this.cd.markForCheck();
  }

  open(e: MouseEvent, element: HTMLElement) {
    if (!this.settings.cardClickAction) {
      return;
    }

    this.context.clickEvent = e;

    this.actionControllerService
      .execute(this.settings.cardClickAction, {
        context: this.contextElement.context,
        contextElement: this.contextElement,
        localContext: {
          [ITEM_OUTPUT]: this.item.model.getAttributes()
        },
        injector: this.injector,
        origin: element
      })
      .subscribe();
  }
}
