import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormControl, FormGroup } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import { combineLatest, Observable, of } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

import { ImageElementItem, MarginControl } from '@modules/customize';
import { ElementConfigurationService } from '@modules/customize-configuration';
import { imageFieldCrops, ImageFieldType, imageFieldTypes } from '@modules/field-components';
import { imageFieldFits, ImageOutputFormat, Input } from '@modules/fields';
import { FieldInputControl } from '@modules/parameters';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { controlValid } from '@shared';

export const validateAction: AsyncValidatorFn = control => {
  const parent = control.parent as CustomizeBarImageEditForm;

  if (!parent) {
    return of(null);
  }

  if (!control.value) {
    return of(null);
  }

  return parent.elementConfigurationService.isActionConfigured(control.value).pipe(
    map(configured => {
      if (!configured) {
        return { required: true };
      }
    })
  );
};

@Injectable()
export class CustomizeBarImageEditForm extends FormGroup {
  element: ImageElementItem;

  controls: {
    value_input: FieldInputControl;
    output_format: FormControl;
    storage: FormControl;
    path: FieldInputControl;
    display_type: FormControl;
    fit: FormControl;
    show_preview: FormControl;
    lightbox: FormControl;
    click_action: FormControl;
    visible_input: FieldInputControl;
    card_wrap: FormControl;
    margin: MarginControl;
  };

  outputFormatOptions = [
    { value: ImageOutputFormat.URL, name: 'Specify URL' },
    { value: ImageOutputFormat.Storage, name: 'Save to Storage' }
  ];
  displayTypeOptions = imageFieldTypes.filter(item =>
    [ImageFieldType.Rectangular, ImageFieldType.Circular].includes(item.value)
  );
  fitOptions = imageFieldFits;
  imageFieldCrops = imageFieldCrops;

  constructor(
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    public elementConfigurationService: ElementConfigurationService
  ) {
    super({
      value_input: new FieldInputControl({ path: ['value'] }),
      output_format: new FormControl(ImageOutputFormat.Storage),
      storage: new FormControl(undefined),
      path: new FieldInputControl({ path: ['value'] }),
      display_type: new FormControl(imageFieldTypes[0].value),
      fit: new FormControl(imageFieldFits[0].value),
      show_preview: new FormControl(false),
      lightbox: new FormControl(false),
      click_action: new FormControl(undefined, undefined, validateAction),
      visible_input: new FieldInputControl({ path: ['value'] }),
      card_wrap: new FormControl(true),
      margin: new MarginControl()
    });
  }

  init(element: ImageElementItem, firstInit = false) {
    this.element = element;

    const storage = this.currentProjectStore.instance.getStorage(
      this.currentEnvironmentStore.instance.uniqueName,
      element.storageResource,
      element.storageName,
      { defaultFirst: true }
    );

    this.controls.value_input.patchValue(element.valueInput ? element.valueInput.serializeWithoutPath() : {});
    this.controls.output_format.patchValue(element.outputFormat || ImageOutputFormat.Storage);
    this.controls.storage.patchValue(
      storage ? { resource: storage.resource.uniqueName, name: storage.storage.uniqueName } : undefined
    );
    this.controls.path.patchValue(element.path ? element.path.serializeWithoutPath() : {});
    this.controls.display_type.patchValue(element.displayType || imageFieldTypes[0].value);
    this.controls.fit.patchValue(element.fit || imageFieldFits[0].value);
    this.controls.show_preview.patchValue(element.showPreview);
    this.controls.lightbox.patchValue(element.lightbox);
    this.controls.click_action.patchValue(element.clickAction);
    this.controls.visible_input.patchValue(element.visibleInput ? element.visibleInput.serializeWithoutPath() : {});
    this.controls.card_wrap.patchValue(element.cardWrap);
    this.controls.margin.patchValue(element.margin);

    if (!firstInit) {
      this.markAsDirty();
    }
  }

  isConfigured(instance: ImageElementItem): Observable<boolean> {
    return this.elementConfigurationService.isImageConfigured(instance);
  }

  controlsValid$(controls: AbstractControl[]): Observable<boolean> {
    return combineLatest(controls.map(control => controlValid(control))).pipe(
      map(result => result.every(item => item)),
      debounceTime(60)
    );
  }

  actionsValid$(): Observable<boolean> {
    return this.controlsValid$([this.controls.click_action]);
  }

  submit(): ImageElementItem {
    const instance = cloneDeep(this.element) as ImageElementItem;

    if (this.controls.value_input.value) {
      instance.valueInput = new Input().deserialize(this.controls.value_input.value);
    } else {
      instance.valueInput = undefined;
    }

    instance.outputFormat = this.controls.output_format.value;

    if (this.controls.storage.value) {
      instance.storageResource = this.controls.storage.value['resource'];
      instance.storageName = this.controls.storage.value['name'];
    }

    instance.path = this.controls.path.value;
    instance.displayType = this.controls.display_type.value;
    instance.fit = this.controls.fit.value;
    instance.showPreview = this.controls.show_preview.value;
    instance.lightbox = this.controls.lightbox.value;

    if (this.controls.click_action.value) {
      instance.clickAction = this.controls.click_action.value;
    } else {
      instance.clickAction = undefined;
    }

    if (this.controls.visible_input.value) {
      instance.visibleInput = new Input().deserialize(this.controls.visible_input.value);
    } else {
      instance.visibleInput = undefined;
    }

    instance.cardWrap = this.controls.card_wrap.value;
    instance.margin = this.controls.margin.value;

    return instance;
  }
}
