var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { AfterViewInit, ChangeDetectorRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import cloneDeep from 'lodash/cloneDeep';
import toPairs from 'lodash/toPairs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, of } from 'rxjs';
import { delayWhen, filter, map, switchMap, tap } from 'rxjs/operators';
import { NotificationService } from '@common/notifications';
import { UniversalAnalyticsService } from '@modules/analytics';
import { CustomView, CustomViewService, CustomViewSource, CustomViewsStore, CustomViewType } from '@modules/custom-views';
import { Input as FieldInput, InputValueType } from '@modules/fields';
import { IDEController, IDEService } from '@modules/ide-components';
import { MenuBlockLayout, MenuBlockLayouts } from '@modules/menu';
import { MenuContext } from '@modules/menu-components';
import { ViewContextTokenProvider } from '@modules/parameters-components';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { CurrentUserStore } from '@modules/users';
import { CustomViewTemplateType, Frame, View } from '@modules/views';
import { CustomViewTemplatesController, ViewEditorController } from '@modules/views-components';
import { controlValue, isSet } from '@shared';
import { CustomizeBarPagesEditCustomControl } from '../customize-bar-pages-edit/custom-control';
var CustomMenuItemEditComponent = /** @class */ (function () {
    function CustomMenuItemEditComponent(currentUserStore, currentProjectStore, currentEnvironmentStore, context, contextTokenProvider, customViewService, customViewsStore, customViewTemplatesController, viewEditorController, ideService, ideController, notificationService, analyticsService, cd) {
        this.currentUserStore = currentUserStore;
        this.currentProjectStore = currentProjectStore;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.context = context;
        this.contextTokenProvider = contextTokenProvider;
        this.customViewService = customViewService;
        this.customViewsStore = customViewsStore;
        this.customViewTemplatesController = customViewTemplatesController;
        this.viewEditorController = viewEditorController;
        this.ideService = ideService;
        this.ideController = ideController;
        this.notificationService = notificationService;
        this.analyticsService = analyticsService;
        this.cd = cd;
        this.deleteRequested = new EventEmitter();
        this.loading = false;
        this.customViewsShared = [];
        this.valueShared = false;
        this.editPopoverOpened = false;
        this.analyticsSource = 'custom_menu_item';
        this.sources = CustomViewSource;
    }
    CustomMenuItemEditComponent.prototype.trackMenuItemFn = function (i, item) {
        return item.menuItem.id;
    };
    CustomMenuItemEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        combineLatest(this.customViewsStore.get(), controlValue(this.control.controls.custom_view))
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var customViews = _a[0], customView = _a[1];
            _this.customView = isSet(customView) ? customViews.find(function (item) { return item.uniqueName == customView; }) : undefined;
            _this.customViewsShared = customViews.filter(function (item) { return item.shared; });
            _this.valueShared = isSet(customView)
                ? _this.customViewsShared.some(function (item) { return item.uniqueName == customView; })
                : undefined;
            _this.cd.markForCheck();
            _this.control.setInputsParameters(_this.customView ? _this.customView.parameters : []);
        });
        this.control.inputFieldProvider.getFields$().subscribe(function () {
            _this.control.controls.inputs.updateValueAndValidity();
        });
    };
    CustomMenuItemEditComponent.prototype.ngOnDestroy = function () {
        this.control.inputFieldProvider.clearProvider();
    };
    CustomMenuItemEditComponent.prototype.ngAfterViewInit = function () {
        if (this.createdOptions && this.createdOptions.customize && this.menuTrigger) {
            this.menuTrigger.openMenu();
        }
    };
    CustomMenuItemEditComponent.prototype.onTitleChanged = function (value) {
        this.control.controls.title.patchValue(value);
        this.cd.markForCheck();
    };
    CustomMenuItemEditComponent.prototype.openEditPopover = function (value) {
        this.editPopoverOpened = value;
        this.cd.markForCheck();
    };
    CustomMenuItemEditComponent.prototype.createView = function () {
        var result = new View();
        result.generateId();
        result.frame = MenuBlockLayouts.isHorizontal(this.layout)
            ? new Frame({ width: 120, height: 50 })
            : new Frame({ width: 250, height: 50 });
        return result;
    };
    CustomMenuItemEditComponent.prototype.updateOrCreateCustomView = function (options) {
        var _this = this;
        var existingInstance = this.customView;
        var uniqueName = this.control.controls.custom_view.value;
        var instance = existingInstance || new CustomView();
        if (isSet(uniqueName)) {
            instance.uniqueName = uniqueName;
        }
        else {
            instance.uniqueName = CustomView.generateUniqueName();
        }
        instance.viewType = CustomViewType.MenuItem;
        instance.source = options.source;
        if (!existingInstance && options.source == CustomViewSource.CustomElement) {
            instance.filesIde = true;
        }
        var fields = ['unique_name', 'view_type', 'source', 'params'];
        if (options.beforeSave) {
            options.beforeSave(instance, fields);
        }
        var obs$ = existingInstance
            ? this.customViewService.update(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, instance, { draft: true, fields: fields })
            : this.customViewService.create(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, instance, { draft: true, fields: fields });
        return obs$.pipe(tap(function (customView) { return _this.customViewsStore.updateOrAddItem(customView); }));
    };
    CustomMenuItemEditComponent.prototype.openViewEditor = function (customView) {
        var _this = this;
        var view = customView ? customView.view : undefined;
        var create = !view;
        if (!view) {
            view = this.createView();
        }
        var data$ = customView
            ? of({
                source: CustomViewSource.View,
                view: view,
                name: customView.name,
                parameters: customView.parameters,
                actions: customView.actions,
                testParameters: customView.testParameters
            })
            : this.customViewsStore.generateName().pipe(map(function (name) { return ({
                source: CustomViewSource.View,
                view: view,
                name: name
            }); }));
        data$
            .pipe(switchMap(function (data) {
            return _this.viewEditorController.open({
                create: create,
                data: data,
                componentLabel: _this.control.controls.title.value,
                submitLabel: create ? 'Create menu item' : 'Save changes',
                nameEditingEnabled: true,
                analyticsSource: _this.analyticsSource
            });
        }), filter(function (result) { return !result.cancelled; }), switchMap(function (result) {
            if (result.useShared) {
                return of(result.useShared);
            }
            else {
                _this.loading = true;
                _this.cd.markForCheck();
                return _this.updateOrCreateCustomView({
                    source: CustomViewSource.View,
                    beforeSave: function (instance, fields) {
                        instance.name = result.data.name;
                        instance.view = result.data.view;
                        instance.parameters = result.data.parameters;
                        instance.actions = result.data.actions;
                        instance.testParameters = result.data.testParameters;
                        fields.push('name', 'view');
                    }
                });
            }
        }), untilDestroyed(this))
            .subscribe(function (result) {
            _this.loading = false;
            _this.cd.markForCheck();
            _this.selectCustomView(result);
        }, function () {
            _this.loading = false;
            _this.cd.markForCheck();
        });
    };
    CustomMenuItemEditComponent.prototype.openIDE = function (customView) {
        var _this = this;
        if (customView) {
            this.loading = true;
            this.cd.markForCheck();
            return this.ideController
                .open({
                projectName: this.currentProjectStore.instance.uniqueName,
                environmentName: this.currentEnvironmentStore.instance.uniqueName,
                customViewName: customView.uniqueName
            })
                .pipe(untilDestroyed(this))
                .subscribe(function () {
                _this.loading = false;
                _this.cd.markForCheck();
            }, function (error) {
                console.error(error);
                _this.loading = false;
                _this.cd.markForCheck();
            });
        }
        else {
            this.loading = true;
            this.cd.markForCheck();
            this.updateOrCreateCustomView({
                source: CustomViewSource.CustomElement
            })
                .pipe(tap(function (result) {
                _this.selectCustomView(result);
            }), switchMap(function (result) {
                return _this.ideController.open({
                    projectName: _this.currentProjectStore.instance.uniqueName,
                    environmentName: _this.currentEnvironmentStore.instance.uniqueName,
                    customViewName: result.uniqueName
                });
            }), untilDestroyed(this))
                .subscribe(function () {
                _this.loading = false;
                _this.cd.markForCheck();
            }, function (error) {
                console.error(error);
                _this.loading = false;
                _this.cd.markForCheck();
            });
        }
    };
    CustomMenuItemEditComponent.prototype.openCustomViewTemplates = function () {
        var _this = this;
        this.customViewTemplatesController
            .chooseTemplate({
            initialFilter: {
                type: CustomViewTemplateType.MenuItem
            },
            viewCreateEnabled: true,
            componentLabel: this.control.controls.title.value,
            analyticsSource: this.analyticsSource
        })
            .pipe(filter(function (result) { return !result.cancelled; }), switchMap(function (result) {
            if (result.useShared) {
                return of(result.useShared);
            }
            else {
                _this.loading = true;
                _this.cd.markForCheck();
                return _this.updateOrCreateCustomView({
                    source: CustomViewSource.View,
                    beforeSave: function (instance, fields) {
                        instance.name = result.data.name;
                        instance.view = result.data.view;
                        instance.parameters = result.data.parameters;
                        instance.actions = result.data.actions;
                        instance.testParameters = result.data.testParameters;
                        fields.push('name', 'view');
                    }
                });
            }
        }), untilDestroyed(this))
            .subscribe(function (customView) {
            _this.loading = false;
            _this.cd.markForCheck();
            _this.selectCustomView(customView);
        }, function () {
            _this.loading = false;
            _this.cd.markForCheck();
        });
    };
    CustomMenuItemEditComponent.prototype.updateCustomViewTemplate = function (customView) {
        var view = customView ? customView.view : undefined;
        if (!view) {
            return;
        }
        this.customViewTemplatesController
            .setTemplateView(view, { componentLabel: this.control.controls.title.value })
            .pipe(filter(function (result) { return !result.cancelled; }), untilDestroyed(this))
            .subscribe();
    };
    CustomMenuItemEditComponent.prototype.cloneCustomView = function (customView, options) {
        var _this = this;
        var instance = new CustomView();
        instance.uniqueName = CustomView.generateUniqueName();
        instance.name = customView.name;
        instance.viewType = CustomViewType.MenuItem;
        instance.source = customView.source;
        instance.parameters = cloneDeep(customView.parameters);
        instance.actions = cloneDeep(customView.actions);
        instance.testParameters = cloneDeep(customView.testParameters);
        instance.shared = options.shared;
        if (customView.source == CustomViewSource.View) {
            instance.view = customView.view;
        }
        else if (customView.source == CustomViewSource.HTML) {
            instance.html = customView.html;
        }
        else if (customView.source == CustomViewSource.CustomElement) {
            instance.buildId = customView.buildId;
            instance.tagName = customView.tagName;
            instance.baseTagName = customView.baseTagName;
            instance.filesJs = cloneDeep(customView.filesJs);
            instance.filesCss = cloneDeep(customView.filesCss);
        }
        return this.customViewService
            .create(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, instance, { draft: true })
            .pipe(delayWhen(function (result) {
            if (customView.source == CustomViewSource.CustomElement) {
                return _this.ideService.clone(_this.currentProjectStore.instance.uniqueName, _this.currentEnvironmentStore.instance.uniqueName, customView.uniqueName, result.uniqueName);
            }
            else {
                return of(undefined);
            }
        }), tap(function (result) { return _this.customViewsStore.addItem(result); }));
    };
    CustomMenuItemEditComponent.prototype.createCustomViewShared = function (customView) {
        var _this = this;
        this.loading = true;
        this.cd.markForCheck();
        this.cloneCustomView(customView, { shared: true })
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            _this.loading = false;
            _this.cd.markForCheck();
            _this.selectCustomView(result);
        }, function () {
            _this.loading = false;
            _this.cd.markForCheck();
        });
    };
    CustomMenuItemEditComponent.prototype.detachCustomViewShared = function (customView) {
        var _this = this;
        this.loading = true;
        this.cd.markForCheck();
        this.cloneCustomView(customView, { shared: false })
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            _this.loading = false;
            _this.cd.markForCheck();
            _this.selectCustomView(result);
        }, function () {
            _this.loading = false;
            _this.cd.markForCheck();
        });
    };
    CustomMenuItemEditComponent.prototype.getCustomViewTestParameters = function (customView) {
        return toPairs(customView.testParameters).map(function (_a) {
            var name = _a[0], value = _a[1];
            var result = new FieldInput();
            result.path = [name];
            result.valueType = InputValueType.StaticValue;
            result.staticValue = value;
            return result;
        });
    };
    CustomMenuItemEditComponent.prototype.selectCustomView = function (customView) {
        if (customView) {
            this.control.patchValue(__assign({ custom_view: customView.uniqueName }, (customView.uniqueName != this.control.controls.custom_view.value && {
                inputs: this.getCustomViewTestParameters(customView)
            })));
        }
        else {
            this.control.patchValue({
                custom_view: undefined,
                inputs: {}
            });
        }
    };
    return CustomMenuItemEditComponent;
}());
export { CustomMenuItemEditComponent };
