<ng-container *ngIf="visible || (customizeEnabled$ | async)">
  <div class="action-button action-button_manual-margin">
    <button
      type="button"
      class="button button_theme button_styles"
      [class.button_primary]="!element.actionItem?.tint && style == tintStyles.Primary"
      [class.button_primary-transparent]="!element.actionItem?.tint && style == tintStyles.Transparent"
      [class.button_disabled]="disabled || loading || submitLoading"
      [class.button_icon]="!(verboseName | appIsSet)"
      [class.button_fill]="element.alignHorizontal == alignHorizontals.Justify"
      [appButtonTintColor]="element.actionItem?.tint || accentColor"
      [appButtonTintColorStyle]="style"
      [appActionElementStyles]="element | appActionElementStyles"
      [appActionElementStylesTintColorStyle]="style"
      (click)="execute(button_element)"
      #button_element
    >
      <app-loader-small
        *ngIf="submitLoading"
        class="button__icon"
        [class.button__icon_left]="verboseName | appIsSet"
      ></app-loader-small>
      <span
        *ngIf="element.actionItem?.icon && !submitLoading"
        class="button__icon"
        [class.button__icon_left]="verboseName | appIsSet"
        [appIcon]="element.actionItem.icon"
      ></span>
      <span *ngIf="verboseName | appIsSet" class="button__label">
        {{ verboseName }}
      </span>
    </button>

    <span
      *ngIf="element.tooltip | appIsSet"
      class="action-button__help help-icon icon-help"
      [appTip]="element.tooltip"
      [appTipOptions]="{ side: 'top' }"
    ></span>
  </div>
</ng-container>
