import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import uniq from 'lodash/uniq';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { switchMap } from 'rxjs/operators';
import { NotificationService } from '@common/notifications';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ServerRequestError } from '@modules/api';
import { HomeTriggerOutput, ProjectInviteService } from '@modules/projects';
import { RoutingService } from '@modules/routing';
import { errorToString } from '@shared';
var ProjectInviteComponent = /** @class */ (function () {
    function ProjectInviteComponent(activatedRoute, projectInviteService, routing, notificationService, analyticsService, cd) {
        this.activatedRoute = activatedRoute;
        this.projectInviteService = projectInviteService;
        this.routing = routing;
        this.notificationService = notificationService;
        this.analyticsService = analyticsService;
        this.cd = cd;
        this.loading = false;
        this.submitLoading = false;
        this.notFound = false;
        this.error = false;
    }
    ProjectInviteComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.activatedRoute.params
            .pipe(switchMap(function (params) {
            _this.loading = true;
            _this.notFound = false;
            _this.error = false;
            _this.code = params['code'];
            _this.cd.markForCheck();
            return _this.projectInviteService.getByCode(_this.code);
        }), untilDestroyed(this))
            .subscribe(function (invite) {
            _this.invite = invite;
            _this.loading = false;
            _this.cd.markForCheck();
            _this.analyticsService.sendSimpleEvent(AnalyticsEvent.Invitation.Opened, {
                ProjectID: _this.invite.project.uniqueName
            });
        }, function (error) {
            var errorMessage = error instanceof ServerRequestError && error.errors.length ? error.errors[0] : error;
            if (error instanceof ServerRequestError && error.response && error.response.status == 404) {
                _this.notFound = true;
            }
            _this.loading = false;
            _this.error = true;
            _this.cd.markForCheck();
            _this.analyticsService.sendSimpleEvent(AnalyticsEvent.Invitation.OpenedFailed, {
                Error: errorMessage
            });
        });
        this.activatedRoute.data.pipe(untilDestroyed(this)).subscribe(function (data) {
            _this.domain = data.domain;
            _this.cd.markForCheck();
        });
    };
    ProjectInviteComponent.prototype.ngOnDestroy = function () { };
    Object.defineProperty(ProjectInviteComponent.prototype, "isWhiteLabel", {
        get: function () {
            return this.domain && this.domain.whiteLabel;
        },
        enumerable: true,
        configurable: true
    });
    ProjectInviteComponent.prototype.accept = function () {
        var _this = this;
        this.submitLoading = true;
        this.cd.markForCheck();
        this.projectInviteService
            .accept(this.code)
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            _this.routing.navigateLink(_this.invite.project.getHomeLinkWithProtocol({ environmentName: _this.invite.environment.uniqueName }), { queryParams: { src: HomeTriggerOutput.AcceptInvite } });
            _this.cd.markForCheck();
            _this.notificationService.success('Accepted', "You have successfully joined project " + _this.invite.project.name);
            _this.analyticsService.sendSimpleEvent(AnalyticsEvent.Invitation.Accepted, {
                ProjectID: _this.invite.project.uniqueName,
                CustomizationPermission: _this.invite.project.hasGroupCustomizationPermission(_this.invite.group)
            });
            _this.analyticsService.sendSimpleEvent(AnalyticsEvent.Project.UsersChanged, {
                ProjectID: _this.invite.project.uniqueName,
                UsersCount: uniq(_this.invite.projectUsers.map(function (item) { return item.getEmail(); })).length + 1
            });
        }, function (error) {
            var errorMessage = error instanceof ServerRequestError && error.errors.length ? error.errors[0] : errorToString(error);
            _this.notificationService.error("Can't Accept", errorMessage);
            _this.submitLoading = false;
            _this.cd.markForCheck();
            _this.analyticsService.sendSimpleEvent(AnalyticsEvent.Invitation.AcceptedFailed, {
                ProjectID: _this.invite.project.uniqueName,
                Error: errorMessage
            });
        });
    };
    ProjectInviteComponent.prototype.reject = function () {
        var _this = this;
        this.submitLoading = true;
        this.cd.markForCheck();
        this.projectInviteService
            .reject(this.code)
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            _this.routing.navigate(['/']);
            _this.submitLoading = false;
            _this.cd.markForCheck();
            _this.notificationService.info('Rejected', "You have rejected to join project " + _this.invite.project.name);
            _this.analyticsService.sendSimpleEvent(AnalyticsEvent.Invitation.Rejected, {
                ProjectID: _this.invite.project.uniqueName
            });
        }, function (error) {
            var errorMessage = error instanceof ServerRequestError && error.errors.length ? error.errors[0] : errorToString(error);
            _this.notificationService.error("Can't Reject", errorMessage);
            _this.submitLoading = false;
            _this.cd.markForCheck();
            _this.analyticsService.sendSimpleEvent(AnalyticsEvent.Invitation.RejectedFailed, {
                ProjectID: _this.invite.project.uniqueName,
                Error: errorMessage
            });
        });
    };
    return ProjectInviteComponent;
}());
export { ProjectInviteComponent };
