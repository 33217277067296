import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import range from 'lodash/range';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, filter, switchMap, tap } from 'rxjs/operators';

import { NotificationService } from '@common/notifications';
import { BasePopupComponent } from '@common/popups';
import { Resource } from '@modules/projects';
import { ModelResponse } from '@modules/resources';
import { Storage } from '@modules/storages';
import { StorageService } from '@modules/storages-queries';

export interface StorageUploadStateFile {
  file: File;
  path: string;
  uploadProgress: number;
  result?: ModelResponse.UploadFileResult;
  error?: string;
}

export interface StorageUploadState {
  files: StorageUploadStateFile[];
  processed: number;
  succeeded: number;
  failed: number;
  cancelled?: boolean;
}

@Component({
  selector: 'app-storage-upload-popup',
  templateUrl: './storage-upload-popup.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StorageUploadPopupComponent implements OnInit, OnDestroy {
  @Input() files: File[] = [];
  @Input() resource: Resource;
  @Input() storage: Storage;
  @Input() path: string[] = [];
  @Output() finished = new EventEmitter<StorageUploadState>();
  @Output() cancelled = new EventEmitter<StorageUploadState>();

  threads = 4;
  state: StorageUploadState;
  uploadLoading = false;

  constructor(
    private popupComponent: BasePopupComponent,
    private storageService: StorageService,
    private notificationService: NotificationService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.upload();
  }

  ngOnDestroy(): void {}

  upload() {
    const files = this.files.map(item => {
      return {
        file: item,
        path: [...this.path, item.name].join('/'),
        uploadProgress: 0
      };
    });

    this.state = {
      files: files,
      processed: 0,
      succeeded: 0,
      failed: 0
    };
    this.uploadLoading = true;
    this.cd.markForCheck();

    const upload: StorageUploadStateFile[] = [...files];
    const uploadThreads = range(this.threads).map(() => this.startUpload(upload));

    combineLatest(uploadThreads)
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          this.uploadLoading = false;
          this.cd.markForCheck();

          if (this.state.succeeded == this.state.processed) {
            this.finish();
          }
        },
        () => {
          this.uploadLoading = false;
          this.cd.markForCheck();
        }
      );
  }

  startUpload(upload: StorageUploadStateFile[]): Observable<any> {
    const file = upload.splice(0, 1)[0];
    if (!file) {
      return of(undefined);
    }

    return this.storageService.upload(this.resource, this.storage, this.storage.uploadQuery, file.file, file.path).pipe(
      tap(event => {
        file.uploadProgress = event.state.uploadProgress;
        this.cd.markForCheck();
      }),
      filter(event => !!event.result),
      tap(event => {
        file.result = event.result;
        ++this.state.processed;
        ++this.state.succeeded;
        this.cd.markForCheck();
      }),
      catchError(error => {
        file.error = String(error);
        ++this.state.processed;
        ++this.state.failed;
        this.cd.markForCheck();

        return of(undefined);
      }),
      switchMap(() => this.startUpload(upload))
    );
  }

  finish() {
    this.finished.emit(this.state);
    this.close();
  }

  cancel() {
    this.cancelled.emit(this.state);
    this.close();
  }

  close() {
    this.popupComponent.close();
  }
}
