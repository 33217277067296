<div
  class="widget"
  [class.widget_theme]="theme"
  appElement
  [appElementDetectVisible]="true"
  (appElementFirstVisible)="firstVisible$.next(true)"
>
  <div
    *ngIf="title || (xColumnDate && (widget.customQueryXColumn || widget.customDateRange))"
    class="widget__header dashboard-item__draggable"
  >
    <div class="widget__header-main">
      <div *ngIf="!loading" class="widget__title">
        <span
          class="widget__title-text"
          [appTextStyle]="widget.titleStyle"
          [appTextStyleGlobal]="'regularTitleTextStyle'"
        >
          {{ title }}
        </span>

        <span
          *ngIf="widget.tooltip | appIsSet"
          class="widget__title-help help-icon icon-help"
          [appTip]="widget.tooltip"
          [appTipOptions]="{ side: 'top' }"
        ></span>
      </div>

      <div *ngIf="loading" class="widget__title" [class.loading-animation]="true">
        <span class="widget__title-text stub-text">Title</span>
      </div>
    </div>

    <div
      *ngIf="(xColumnDate && (widget.customQueryXColumn || widget.customDateRange)) || widget.showReload"
      class="widget__header-right widget__header-controls"
    >
      <app-select
        *ngIf="xColumnDate && widget.customQueryXColumn"
        class="widget__header-control"
        [control]="xLookupControl"
        [options]="xLookupOptions"
        [theme]="theme"
        [fill]="true"
        [small]="true"
        [searchEnabled]="false"
      >
      </app-select>

      <app-widget-date-selector
        *ngIf="xColumnDate && widget.customDateRange"
        class="widget__header-control"
        [date]="xColumnHasDate"
        [time]="xColumnHasTime"
        [xDateFromControl]="xColumnFromControl"
        [xDateToControl]="xColumnToControl"
        [theme]="theme"
      ></app-widget-date-selector>

      <div
        *ngIf="widget.showReload"
        class="widget__header-button widget__header-button_icon widget__header-control"
        (click)="reloadData()"
      >
        <span class="widget__header-button-icon icon-repeat"></span>
      </div>
    </div>
  </div>

  <div *ngIf="configured && !error && !loading && datasets" class="widget__body widget__body_responsive-height">
    <app-line-chart2
      *ngIf="widget.chartType == chartTypes.Line"
      [datasets]="datasets"
      [smooth]="widget.smooth"
      [yFormat]="widget.yFormat"
      [min]="widget.min"
      [max]="widget.max"
      [defaultColors]="defaultColors"
      [legend]="widget.legend"
      [dataClickEnabled]="!!widget.clickAction"
      [theme]="theme"
      (dataClick)="onDataClick($event)"
      style="position: relative; display: block; height: 100%;"
    ></app-line-chart2>
    <app-bar-chart2
      *ngIf="widget.chartType == chartTypes.Bar"
      [datasets]="datasets"
      [percentage]="widget.percentage"
      [horizontal]="false"
      [yFormat]="widget.yFormat"
      [min]="widget.min"
      [max]="widget.max"
      [defaultColors]="defaultColors"
      [legend]="widget.legend"
      [dataClickEnabled]="!!widget.clickAction"
      [theme]="theme"
      (dataClick)="onDataClick($event)"
      style="position: relative; display: block; height: 100%;"
    ></app-bar-chart2>
    <app-bar-chart2
      *ngIf="widget.chartType == chartTypes.BarHorizontal"
      [datasets]="datasets"
      [percentage]="widget.percentage"
      [horizontal]="true"
      [yFormat]="widget.yFormat"
      [min]="widget.min"
      [max]="widget.max"
      [defaultColors]="defaultColors"
      [legend]="widget.legend"
      [dataClickEnabled]="!!widget.clickAction"
      [theme]="theme"
      (dataClick)="onDataClick($event)"
      style="position: relative; display: block; height: 100%;"
    ></app-bar-chart2>
    <app-bar-chart2
      *ngIf="widget.chartType == chartTypes.StackedBar"
      [datasets]="datasets"
      [stacked]="true"
      [percentage]="widget.percentage"
      [horizontal]="false"
      [yFormat]="widget.yFormat"
      [min]="widget.min"
      [max]="widget.max"
      [defaultColors]="defaultColors"
      [legend]="widget.legend"
      [dataClickEnabled]="!!widget.clickAction"
      [theme]="theme"
      (dataClick)="onDataClick($event)"
      style="position: relative; display: block; height: 100%;"
    ></app-bar-chart2>
    <app-bar-chart2
      *ngIf="widget.chartType == chartTypes.StackedBarHorizontal"
      [datasets]="datasets"
      [stacked]="true"
      [percentage]="widget.percentage"
      [horizontal]="true"
      [yFormat]="widget.yFormat"
      [min]="widget.min"
      [max]="widget.max"
      [defaultColors]="defaultColors"
      [legend]="widget.legend"
      [dataClickEnabled]="!!widget.clickAction"
      [theme]="theme"
      (dataClick)="onDataClick($event)"
      style="position: relative; display: block; height: 100%;"
    ></app-bar-chart2>
    <app-pie-chart2
      *ngIf="widget.chartType == chartTypes.Pie"
      [datasets]="datasets"
      [yFormat]="widget.yFormat"
      [min]="widget.min"
      [max]="widget.max"
      [defaultColors]="defaultColors"
      [legend]="widget.legend"
      [dataClickEnabled]="!!widget.clickAction"
      [theme]="theme"
      (dataClick)="onDataClick($event)"
      style="position: relative; display: block; height: 100%;"
    ></app-pie-chart2>
    <app-pie-chart2
      *ngIf="widget.chartType == chartTypes.Doughnut"
      [datasets]="datasets"
      [doughnut]="true"
      [yFormat]="widget.yFormat"
      [min]="widget.min"
      [max]="widget.max"
      [defaultColors]="defaultColors"
      [legend]="widget.legend"
      [dataClickEnabled]="!!widget.clickAction"
      [theme]="theme"
      (dataClick)="onDataClick($event)"
      style="position: relative; display: block; height: 100%;"
    ></app-pie-chart2>
    <app-radar-chart
      *ngIf="widget.chartType == chartTypes.Radar"
      [datasets]="datasets"
      [legend]="widget.legend"
      style="position: relative; display: block; height: 100%;"
    ></app-radar-chart>
    <app-polar-area-chart
      *ngIf="widget.chartType == chartTypes.PolarArea"
      [datasets]="datasets"
      [legend]="widget.legend"
      style="position: relative; display: block; height: 100%;"
    ></app-polar-area-chart>
    <app-scatter-chart2
      *ngIf="widget.chartType == chartTypes.Scatter"
      [datasets]="datasets"
      [yFormat]="widget.yFormat"
      [min]="widget.min"
      [max]="widget.max"
      [legend]="widget.legend"
      [dataClickEnabled]="!!widget.clickAction"
      [theme]="theme"
      (dataClick)="onDataClick($event)"
      style="position: relative; display: block; height: 100%;"
    ></app-scatter-chart2>
    <app-scatter-chart2
      *ngIf="widget.chartType == chartTypes.Bubble"
      [datasets]="datasets"
      [bubbles]="true"
      [yFormat]="widget.yFormat"
      [min]="widget.min"
      [max]="widget.max"
      [legend]="widget.legend"
      [dataClickEnabled]="!!widget.clickAction"
      [theme]="theme"
      (dataClick)="onDataClick($event)"
      style="position: relative; display: block; height: 100%;"
    ></app-scatter-chart2>
  </div>

  <ng-container *ngIf="!configured || error || loading || !datasets">
    <div class="widget__body widget__body_no-overflow">
      <div class="widget__disabled">
        <div [class.loading-animation]="loading" style="height: 100%;">
          <app-line-chart2
            *ngIf="widget.chartType == chartTypes.Line"
            [datasets]="sampleDatasets"
            [animate]="false"
            [xAxisVisible]="false"
            [yAxisVisible]="false"
            [legend]="false"
            [interactive]="false"
            style="display: block; height: 100%;"
          ></app-line-chart2>
          <app-bar-chart2
            *ngIf="widget.chartType == chartTypes.Bar"
            [datasets]="sampleDatasets"
            [horizontal]="false"
            [animate]="false"
            [xAxisVisible]="false"
            [yAxisVisible]="false"
            [yFormat]="widget.yFormat"
            [legend]="false"
            [interactive]="false"
            style="display: block; height: 100%;"
          ></app-bar-chart2>
          <app-bar-chart2
            *ngIf="widget.chartType == chartTypes.BarHorizontal"
            [datasets]="sampleDatasets"
            [horizontal]="true"
            [animate]="false"
            [xAxisVisible]="false"
            [yAxisVisible]="false"
            [yFormat]="widget.yFormat"
            [legend]="false"
            [interactive]="false"
            style="display: block; height: 100%;"
          ></app-bar-chart2>
          <app-bar-chart2
            *ngIf="widget.chartType == chartTypes.StackedBar"
            [datasets]="sampleDatasets"
            [stacked]="true"
            [horizontal]="false"
            [animate]="false"
            [xAxisVisible]="false"
            [yAxisVisible]="false"
            [yFormat]="widget.yFormat"
            [legend]="false"
            [interactive]="false"
            style="display: block; height: 100%;"
          ></app-bar-chart2>
          <app-bar-chart2
            *ngIf="widget.chartType == chartTypes.StackedBarHorizontal"
            [datasets]="sampleDatasets"
            [stacked]="true"
            [horizontal]="true"
            [animate]="false"
            [xAxisVisible]="false"
            [yAxisVisible]="false"
            [yFormat]="widget.yFormat"
            [legend]="false"
            [interactive]="false"
            style="display: block; height: 100%;"
          ></app-bar-chart2>
          <app-pie-chart2
            *ngIf="widget.chartType == chartTypes.Pie"
            [datasets]="sampleDatasets"
            [animate]="false"
            [legend]="false"
            [interactive]="false"
            style="display: block; height: 100%;"
          ></app-pie-chart2>
          <app-pie-chart2
            *ngIf="widget.chartType == chartTypes.Doughnut"
            [datasets]="sampleDatasets"
            [animate]="false"
            [legend]="false"
            [interactive]="false"
            style="display: block; height: 100%;"
          ></app-pie-chart2>
          <app-radar-chart
            *ngIf="widget.chartType == chartTypes.Radar"
            [datasets]="sampleDatasets"
            [animate]="false"
            [legend]="false"
            style="display: block; height: 100%;"
          ></app-radar-chart>
          <app-polar-area-chart
            *ngIf="widget.chartType == chartTypes.PolarArea"
            [datasets]="sampleDatasets"
            [animate]="false"
            [legend]="false"
            style="display: block; height: 100%;"
          ></app-polar-area-chart>
          <app-scatter-chart2
            *ngIf="widget.chartType == chartTypes.Scatter"
            [datasets]="sampleDatasets"
            [min]="0"
            [max]="100"
            [animate]="false"
            [xAxisVisible]="false"
            [yAxisVisible]="false"
            style="display: block; height: 100%;"
          ></app-scatter-chart2>
          <app-scatter-chart2
            *ngIf="widget.chartType == chartTypes.Bubble"
            [datasets]="sampleDatasets"
            [min]="0"
            [max]="100"
            [animate]="false"
            [xAxisVisible]="false"
            [yAxisVisible]="false"
            style="display: block; height: 100%;"
          ></app-scatter-chart2>
        </div>
      </div>

      <ng-container *ngIf="error">
        <div class="widget__error">
          <div class="widget-error">
            <div class="widget-error__title">{{ 'Loading failed' | localize }}</div>
            <div class="widget-error__description" [title]="error">{{ error }}</div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="!error && !configured">
        <div class="widget__error">
          <div class="widget-error">
            <div class="widget-error__title">{{ 'Not configured' | localize }}</div>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>
