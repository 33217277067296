<div class="user-activities">
  <ng-container
    *ngIf="(currentProjectStore.instance$ | async).features.isActivityLogEnabled(); then content; else not_enabled"
  ></ng-container>

  <ng-template #content>
    <div class="user-activities__content">
      <div class="user-activities__header">
        <h2 class="user-activities__title">
          <ng-container *ngIf="modelDescription && !model">
            <a [appLink]="modelDescription.link" [queryParams]="backParams" class="change__title-back">
              <span class="icon icon-arrow_backward_3"></span> {{ modelDescription.verboseNamePlural | appCapitalize }}
            </a>
            –
          </ng-container>
          <ng-container *ngIf="model">
            <a [appLink]="model.getLink()" [queryParams]="backParams" class="change__title-back">
              <span class="icon icon-arrow_backward_3"></span>
              <span [innerHTML]="model | appModelStr | appStub | async"></span>
            </a>
            –
          </ng-container>
          Activity Log
        </h2>
        <p class="user-activities__description">
          See what App members are doing and investigate changes in your workspace.
        </p>
        <div class="user-activities__filter">
          <span class="user-activities__filter-title">Filter by</span>
          <div [formGroup]="filters" class="user-activities__form">
            <div class="user-activities__form-date-range">
              <div class="input-icon-wrapper">
                <input
                  #dp_gte
                  cdkOverlayOrigin
                  #dp_gte_origin="cdkOverlayOrigin"
                  [appDateValue]="{ format: formatDate }"
                  autocomplete="off"
                  class="input input_icon-right"
                  formControlName="date_add_gte"
                  style="width: 150px;"
                  type="text"
                />
                <span class="input-icon input-icon_right icon-calendar"></span>
                <gxd-datepicker
                  [input]="dp_gte"
                  [origin]="dp_gte_origin"
                  [options]="{ theme: 'jet', format: formatDate, time: false, locale: {} | localizeDatepicker }"
                ></gxd-datepicker>
              </div>

              <div class="user-activities__form-date-range-separator"></div>

              <div class="input-icon-wrapper">
                <input
                  style="width: 150px;"
                  #dp_lte
                  cdkOverlayOrigin
                  #dp_lte_origin="cdkOverlayOrigin"
                  [appDateValue]="{ format: formatDate }"
                  autocomplete="off"
                  class="input input_icon-right"
                  formControlName="date_add_lte"
                  type="text"
                />
                <span class="input-icon input-icon_right icon-calendar"></span>
                <gxd-datepicker
                  [input]="dp_lte"
                  [origin]="dp_lte_origin"
                  [options]="{ theme: 'jet', format: formatDate, time: false, locale: {} | localizeDatepicker }"
                ></gxd-datepicker>
              </div>
            </div>

            <div class="user-activities__form-teammates">
              <gxs-select
                [options]="{
                  theme: 'jet',
                  search: true,
                  searchMinimumLength: 1,
                  searchDebounce: 0,
                  labels: {} | localizeSelect,
                  classes: ['input_fill']
                }"
                formControlName="user"
              >
                <gxs-option
                  *ngFor="let item of activityUserOptions"
                  [name]="item.name"
                  [value]="item.value"
                ></gxs-option>
              </gxs-select>
            </div>

            <div class="user-activities__form-activity">
              <gxs-select
                [options]="{
                  theme: 'jet',
                  search: true,
                  searchMinimumLength: 1,
                  searchDebounce: 0,
                  labels: {} | localizeSelect,
                  classes: ['input_fill']
                }"
                formControlName="activity_type_in"
              >
                <gxs-option
                  *ngFor="let item of activityTypeOptions"
                  [name]="item.name"
                  [value]="item.value"
                ></gxs-option>
              </gxs-select>
            </div>
          </div>
        </div>
      </div>

      <ng-container *ngIf="(userActivityListStore.items$ | async)?.length !== 0">
        <div *ngIf="(userActivityListStore.items$ | async) !== undefined" class="user-activities__list">
          <div class="card-list__wrap">
            <div class="card-list__header">
              <div class="card-list__header-col user-activities__list-date-col">
                <div class="card-list__header-col-inner">
                  <span class="card-list__header-col-title">Date</span>
                </div>
              </div>
              <div class="card-list__header-col">
                <div class="card-list__header-col-inner">
                  <span class="card-list__header-col-title">Member</span>
                </div>
              </div>
              <div class="card-list__header-col user-activities__list-type-col">
                <div class="card-list__header-col-inner">
                  <span class="card-list__header-col-title">Activity</span>
                </div>
              </div>
              <div class="card-list__header-col user-activities__list-description-col">
                <div class="card-list__header-col-inner">
                  <span class="card-list__header-col-title"></span>
                </div>
              </div>
            </div>
            <ul class="card-list">
              <li *ngFor="let item of userActivityListStore.items$ | async" class="card-list__item">
                <app-user-activities-item [item]="item"></app-user-activities-item>
              </li>
              <ng-container
                *ngIf="(userActivityListStore.loading$ | async) && (userActivityListStore.items$ | async) !== undefined"
              >
                <li *ngFor="let item of [1, 2, 3]" class="card-list__item">
                  <div class="card-row">
                    <div class="card-row__inner">
                      <div class="card-row__col user-activities__list-date-col">
                        <div class="card-row__col-inner">
                          <span class="user-activities__list-date">
                            <span [class.loading-animation]="true">
                              <span class="stub-text">Apr 15, 2020, 09:53 PM</span>
                            </span>
                          </span>
                        </div>
                      </div>
                      <div class="card-row__col">
                        <div class="card-row__col-inner">
                          <span [class.loading-animation]="true"
                            ><span class="stub-image stub-image_square user-activities__list-avatar"></span
                          ></span>
                          <span class="user-activities__list-name">
                            <span [class.loading-animation]="true">
                              <span class="stub-text">Victor Egorov</span>
                            </span>
                          </span>
                        </div>
                      </div>
                      <div class="card-row__col user-activities__list-type-col">
                        <div class="card-row__col-inner">
                          <span class="user-activities__list-type">
                            <span [class.loading-animation]="true">
                              <span class="stub-text">Created</span>
                            </span>
                          </span>
                        </div>
                      </div>
                      <div class="card-row__col user-activities__list-description-col">
                        <div class="card-row__col-inner">
                          <span class="user-activities__list-description">
                            <span [class.loading-animation]="true">
                              <span class="stub-text">New Record: Customer Order #1246</span>
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ng-container>
            </ul>
          </div>
        </div>

        <div
          *ngIf="(userActivityListStore.items$ | async) === undefined"
          class="user-activities__list user-activities__list-stub"
        >
          <div class="card-list__wrap">
            <div class="card-list__header">
              <div class="card-list__header-col user-activities__list-date-col">
                <div class="card-list__header-col-inner">
                  <span class="card-list__header-col-title">
                    <span [class.loading-animation]="true">
                      <span class="stub-text">Date</span>
                    </span>
                  </span>
                </div>
              </div>
              <div class="card-list__header-col">
                <div class="card-list__header-col-inner">
                  <span class="card-list__header-col-title">
                    <span [class.loading-animation]="true">
                      <span class="stub-text">Member</span>
                    </span>
                  </span>
                </div>
              </div>
              <div class="card-list__header-col user-activities__list-type-col">
                <div class="card-list__header-col-inner">
                  <span class="card-list__header-col-title">
                    <span [class.loading-animation]="true">
                      <span class="stub-text">Activity</span>
                    </span>
                  </span>
                </div>
              </div>
              <div class="card-list__header-col user-activities__list-description-col">
                <div class="card-list__header-col-inner">
                  <span class="card-list__header-col-title"></span>
                </div>
              </div>
            </div>
            <ul class="card-list">
              <li *ngFor="let item of [1, 2, 3, 4, 5, 6, 7, 8]" class="card-list__item">
                <div class="card-row">
                  <div class="card-row__inner">
                    <div class="card-row__col user-activities__list-date-col">
                      <div class="card-row__col-inner">
                        <span class="user-activities__list-date">
                          <span [class.loading-animation]="true">
                            <span class="stub-text">Apr 15, 2020, 09:53 PM</span>
                          </span>
                        </span>
                      </div>
                    </div>
                    <div class="card-row__col">
                      <div class="card-row__col-inner">
                        <span [class.loading-animation]="true"
                          ><span class="stub-image stub-image_square user-activities__list-avatar"></span
                        ></span>
                        <span class="user-activities__list-name">
                          <span [class.loading-animation]="true">
                            <span class="stub-text">Victor Egorov</span>
                          </span>
                        </span>
                      </div>
                    </div>
                    <div class="card-row__col user-activities__list-type-col">
                      <div class="card-row__col-inner">
                        <span class="user-activities__list-type">
                          <span [class.loading-animation]="true">
                            <span class="stub-text">Created</span>
                          </span>
                        </span>
                      </div>
                    </div>
                    <div class="card-row__col user-activities__list-description-col">
                      <div class="card-row__col-inner">
                        <span class="user-activities__list-description">
                          <span [class.loading-animation]="true">
                            <span class="stub-text">New Record: Customer Order #1246</span>
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </ng-container>
      <div *ngIf="(userActivityListStore.items$ | async)?.length === 0" class="table__empty">
        <app-error [title]="'Nothing found'">
          Unfortunately, no user activities found
        </app-error>
      </div>
    </div>
  </ng-template>

  <ng-template #not_enabled>
    <app-feature-overview>
      <div data-subtitle>Paid Feature</div>
      <div data-title>Track user activities with <strong>Activity Log</strong></div>
      <div data-description>
        Keep track of all changes made to any item with activity log. Search a specific modification in the log and
        filter results by types of activity.
      </div>
    </app-feature-overview>
  </ng-template>
</div>
