import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';

import { ApiService, TokenOptions } from '@modules/api';

@Injectable({
  providedIn: 'root'
})
export class IDEService {
  constructor(private http: HttpClient, private apiService: ApiService) {}

  initSession(
    projectName: string,
    environmentName: string,
    customView: string,
    token: TokenOptions
  ): Observable<{ sid: string }> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.nodeEnvironmentMethodURL(
          projectName,
          environmentName,
          `custom_views/${customView}/init_session`
        );
        let headers = new HttpHeaders();
        const data = {
          token: token
        };

        headers = this.apiService.setHeadersToken(headers);

        return this.http.post(url, data, { headers: headers });
      }),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  clone(
    projectName: string,
    environmentName: string,
    customViewName: string,
    toCustomViewName: string
  ): Observable<boolean> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.nodeEnvironmentMethodURL(
          projectName,
          environmentName,
          `custom_views/${customViewName}/clone`
        );
        let headers = new HttpHeaders();
        const data = {
          to_custom_view: toCustomViewName
        };

        headers = this.apiService.setHeadersToken(headers);

        return this.http.post<{ result: boolean }>(url, data, { headers: headers });
      }),
      map(result => result.result),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }
}
