var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormControl, FormGroup } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import { combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { MarginControl } from '@modules/customize';
import { ElementConfigurationService } from '@modules/customize-configuration';
import { Input } from '@modules/fields';
import { FieldInputControl } from '@modules/parameters';
import { controlValid } from '@shared';
export function validateActions() {
    return function (control) {
        var parent = control.parent;
        if (!parent) {
            return of(null);
        }
        if (!control.value || !control.value.length) {
            return of(null);
        }
        return combineLatest(control.value.map(function (item) { return parent.elementConfigurationService.isActionConfigured(item); })).pipe(map(function (result) {
            if (result.some(function (configured) { return !configured; })) {
                return { required: true };
            }
        }));
    };
}
var CustomizeBarScannerEditForm = /** @class */ (function (_super) {
    __extends(CustomizeBarScannerEditForm, _super);
    function CustomizeBarScannerEditForm(elementConfigurationService) {
        var _this = _super.call(this, {
            scanning_initial: new FormControl(false),
            ignore_duplicate_scans: new FormControl(true),
            scan_confirm: new FormControl(false),
            on_scan_actions: new FormControl([], undefined, validateActions()),
            visible_input: new FieldInputControl({ path: ['value'] }),
            card_wrap: new FormControl(true),
            margin: new MarginControl()
        }) || this;
        _this.elementConfigurationService = elementConfigurationService;
        return _this;
    }
    CustomizeBarScannerEditForm.prototype.init = function (element, firstInit) {
        if (firstInit === void 0) { firstInit = false; }
        this.element = element;
        var value = {
            scanning_initial: element.scanningInitial,
            ignore_duplicate_scans: element.ignoreDuplicateScans,
            scan_confirm: element.scanConfirm,
            on_scan_actions: element.onScanActions,
            visible_input: element.visibleInput ? element.visibleInput.serializeWithoutPath() : {},
            card_wrap: element.cardWrap,
            margin: element.margin
        };
        this.patchValue(value, { emitEvent: false });
        if (!firstInit) {
            this.markAsDirty();
        }
    };
    CustomizeBarScannerEditForm.prototype.isConfigured = function (instance) {
        return this.elementConfigurationService.isScannerConfigured(instance);
    };
    CustomizeBarScannerEditForm.prototype.controlsValid$ = function (controls) {
        if (!controls.length) {
            return of(true);
        }
        return combineLatest(controls.map(function (control) { return controlValid(control); })).pipe(map(function (result) { return result.every(function (item) { return item; }); })
        // debounceTime(60) TODO: Too long wait with debounceTime
        );
    };
    CustomizeBarScannerEditForm.prototype.actionsValid$ = function () {
        return this.controlsValid$([this.controls.on_scan_actions]);
    };
    CustomizeBarScannerEditForm.prototype.submit = function () {
        var instance = cloneDeep(this.element);
        instance.scanningInitial = this.controls.scanning_initial.value;
        instance.ignoreDuplicateScans = this.controls.ignore_duplicate_scans.value;
        instance.scanConfirm = this.controls.scan_confirm.value;
        instance.onScanActions = this.controls.on_scan_actions.value;
        instance.cardWrap = this.controls.card_wrap.value;
        instance.margin = this.controls.margin.value;
        if (this.controls.visible_input.value) {
            instance.visibleInput = new Input().deserialize(this.controls.visible_input.value);
        }
        else {
            instance.visibleInput = undefined;
        }
        return instance;
    };
    return CustomizeBarScannerEditForm;
}(FormGroup));
export { CustomizeBarScannerEditForm };
