<div
  *ngIf="visible || (customizeService.enabled$ | async)"
  style="width: 100%; height: 100%;"
  appElement
  [appElementDetectVisible]="true"
  (appElementFirstVisible)="onFirstVisible()"
>
  <ng-container
    *ngIf="
      featureSources.includes(source) && !(currentProjectStore.instance$ | async).features.isCustomViewsEnabled();
      then not_enabled;
      else content
    "
  ></ng-container>

  <ng-template #content>
    <ng-container *ngIf="!loading">
      <app-custom-element-auto
        *ngIf="customView$.value"
        [source]="source"
        [customView]="customView$.value"
        [actions]="element.actions"
        [params]="params"
        [context]="context"
        [contextElement]="viewContextElement"
      ></app-custom-element-auto>

      <ng-container *ngIf="!customView$.value">
        This component is not configured yet
      </ng-container>
    </ng-container>
  </ng-template>

  <ng-template #not_enabled>
    <app-feature-overview-line>
      <div data-subtitle>Paid Feature</div>
      <div data-title>Create any component with <strong>Custom Components</strong></div>
      <div data-description>
        Write HTML or upload JS-based component built with React/Vue/Angular or JS Vanilla
      </div>
    </app-feature-overview-line>
  </ng-template>
</div>
