import pickBy from 'lodash/pickBy';

import { ActionItem } from '@modules/actions';
import { FieldOutput, Input, ParameterField } from '@modules/fields';

// TODO: Refactor import
import { CustomViewSource } from '../../../../custom-views/data/custom-view';

import { registerElementForType } from '../element-items';
import { ElementType } from '../element-type';
import { ElementItem } from './base';

export class CustomElementItem extends ElementItem {
  public type = ElementType.Custom;
  public width: number;
  public height: number;
  public source: CustomViewSource;
  public customView: string;
  public inputs: Input[] = [];
  public actions: { name: string; action: ActionItem }[] = [];
  public widthFluid = false;
  public heightFluid = false;

  // Backward compatibility
  public parameters: ParameterField[] = [];
  public outputs: FieldOutput[] = [];

  deserialize(data: Object): CustomElementItem {
    super.deserialize(data);
    this.width = this.params['width'];
    this.height = this.params['height'];
    this.source = this.params['source'];
    this.customView = this.params['custom_view'];
    this.widthFluid = this.params['width_fluid'];
    this.heightFluid = this.params['height_fluid'];

    if (this.params['inputs']) {
      this.inputs = this.params['inputs'].map(item => new Input().deserialize(item));
    }

    if (this.params['actions']) {
      this.actions = this.params['actions'].map(item => ({
        name: item['name'],
        action: new ActionItem().deserialize(item['action'])
      }));
    }

    // Backward compatibility
    if (this.params['parameters']) {
      this.parameters = this.params['parameters'].map(item => new ParameterField().deserialize(item));
    }

    // Backward compatibility
    if (this.params['outputs']) {
      this.outputs = this.params['outputs'].map(item => new FieldOutput().deserialize(item));
    }

    return this;
  }

  serialize(fields?: string[]): Object {
    this.params = {
      width: this.width,
      height: this.height,
      source: this.source,
      width_fluid: this.widthFluid,
      height_fluid: this.heightFluid
    };

    this.params['custom_view'] = this.customView;
    this.params['inputs'] = this.inputs.map(item => item.serialize());
    this.params['actions'] = this.actions.map(item => ({ name: item.name, action: item.action.serialize() }));

    // Backward compatibility
    this.params['parameters'] = this.parameters.map(item => item.serialize());
    this.params['outputs'] = this.outputs.map(item => item.serialize());

    let data = super.serialize();
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }

  get typeStr(): string {
    return 'custom created component';
  }

  get analyticsName(): string {
    return 'custom';
  }

  defaultName() {
    return 'Custom';
  }
}

registerElementForType(ElementType.Custom, CustomElementItem);
