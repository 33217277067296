<div *ngIf="loading" class="status-page">
  <div class="status-page__main">
    <div class="status-page__content">
      <h1 class="status-page__title">
        <span [class.loading-animation]="true">
          <span class="stub-text">Join project</span>
        </span>
      </h1>
      <div class="status-page__description">
        <span [class.loading-animation]="true">
          <span class="stub-text">
            User has invited you to join the workspace project. Join now to start collaborating!
          </span>
        </span>
      </div>
    </div>
  </div>
  <div class="status-page__right">
    <div class="status-page__image">
      <div class="status-page__image-inner">
        <span [class.loading-animation]="true">
          <span class="stub-image stub-image_contrast stub-image_square status-page__image-stub"></span>
        </span>
      </div>
    </div>
  </div>
</div>

<div *ngIf="error" class="status-page">
  <div class="status-page__main">
    <div class="status-page__content">
      <h1 class="status-page__title">
        <ng-container *ngIf="notFound">{{ 'Invite link is wrong' | localize }}</ng-container>
        <ng-container *ngIf="!notFound">{{ 'Loading failed' | localize }}</ng-container>
      </h1>
      <div class="status-page__description">
        <ng-container *ngIf="notFound">
          {{
            'Looks like your invitation link has expired or maybe you have followed the wrong link. Please try to ask to invite your again.'
              | localize
          }}
        </ng-container>
        <ng-container *ngIf="!notFound">
          {{ 'Unfortunately we failed to load information about this invitation' | localize }}
        </ng-container>
      </div>
      <div *ngIf="!isWhiteLabel" class="status-page__actions">
        <a [routerLink]="['/']" class="status-page__button button button_primary button_shadow button_bigger">
          {{ 'Go to home page' | localize }}
        </a>
      </div>
    </div>
  </div>
  <div class="status-page__right">
    <div class="status-page__image">
      <div class="status-page__image-inner status-page__image-inner_type_not-found"></div>
    </div>
  </div>
</div>

<div *ngIf="invite" class="status-page">
  <div class="status-page__main">
    <div class="status-page__content">
      <app-project-logo
        class="status-page__logo"
        [color]="invite.project.logoColor ? invite.project.logoColor : ''"
        [logo]="invite.project.logo ? invite.project.logo : ''"
        [initials]="invite.project.initials ? invite.project.initials : ''"
        [fill]="invite.project.logoFill"
      ></app-project-logo>
      <h1 class="status-page__title">
        <span
          [innerHTML]="'Join <span class=&quot;auth-form__accent&quot;>{0}</span>' | localize: [invite.project.name]"
        ></span>
      </h1>
      <div class="status-page__description">
        <span
          *ngIf="invite.inviter"
          [innerHTML]="
            '<strong>{0}</strong> has invited you to join <strong>{1}</strong>'
              | localize: [invite.inviter.strFullWithEmail, invite.project.name]
          "
        ></span>
        <span
          *ngIf="!invite.inviter"
          [innerHTML]="'Someone has invited you to join <strong>{0}</strong>' | localize: [invite.project.name]"
        ></span>
      </div>
      <div class="status-page__actions">
        <a
          href="javascript:void(0)"
          class="status-page__button button button_primary button_shadow button_bigger"
          [class.button_disabled]="submitLoading"
          (click)="accept()"
        >
          {{ 'Join' | localize }}
        </a>
        <!--        <a-->
        <!--          href="javascript:void(0)"-->
        <!--          class="status-page__button button button_orange-alternative button_bigger"-->
        <!--          [class.button_disabled]="submitLoading"-->
        <!--          (click)="reject()"-->
        <!--        >-->
        <!--          Reject-->
        <!--        </a>-->
        <a *ngIf="!isWhiteLabel" [routerLink]="['/']" class="status-page__button button button_bigger">
          {{ 'Go to home page' | localize }}
        </a>
      </div>
    </div>
  </div>
  <div class="status-page__right">
    <div class="status-page__image">
      <div class="status-page__image-inner status-page__image-inner_type_edit-list"></div>
    </div>
  </div>
</div>
