<div class="sidebar-button-group">
  <div class="sidebar-button-group-gallery" (click)="openCustomViewTemplates()">
    <div class="sidebar-button-group-gallery__background"></div>
    <div class="sidebar-button-group-gallery__overlay"></div>

    <div class="sidebar-button-group-gallery__info">
      <div class="sidebar-button-group-gallery__info-main">
        <div class="sidebar-button-group-gallery__info-title">
          Explore Marketplace
        </div>
        <div class="sidebar-button-group-gallery__info-subtitle">
          <ng-container *ngIf="templatesApprox | appIsSet">{{ templatesApprox }}+ components</ng-container>
          <ng-container *ngIf="!(templatesApprox | appIsSet)">Components library</ng-container>
        </div>
      </div>

      <div class="sidebar-button-group-gallery__info-right">
        <div class="sidebar-button-group-gallery__info-arrow icon-arrow_forward_2"></div>
      </div>
    </div>
  </div>
</div>

<div class="sidebar-button-group sidebar-button-group_rows">
  <!--  <div class="sidebar-button-group__item sidebar-button-group__item_fill">-->
  <!--    <div-->
  <!--      class="sidebar-button-group-info-button sidebar-button-group-info-button_purple-gradient"-->
  <!--      (click)="openCustomViewTemplates()"-->
  <!--    >-->
  <!--      <div class="sidebar-button-group-info-button__left">-->
  <!--        <div class="sidebar-button-group-info-button__icon icon-blocks"></div>-->
  <!--      </div>-->
  <!--      <div class="sidebar-button-group-info-button__main">-->
  <!--        <div class="sidebar-button-group-info-button__title">Explore Marketplace</div>-->
  <!--        <div class="sidebar-button-group-info-button__subtitle">-->
  <!--          <ng-container *ngIf="templatesApprox | appIsSet">{{ templatesApprox }}+ components</ng-container>-->
  <!--          <ng-container *ngIf="!(templatesApprox | appIsSet)">Components library</ng-container>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--      <div class="sidebar-button-group-info-button__right">-->
  <!--        <div class="sidebar-button-group-info-button__arrow icon-arrow_forward_2"></div>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </div>-->

  <div class="sidebar-button-group__row">
    <app-custom-view-dropdown
      [origin]="dropdown_origin"
      (selectItem)="createSharedView($event)"
      #custom_view_dropdown
    ></app-custom-view-dropdown>

    <div class="sidebar-button-group__item sidebar-button-group__item_fill">
      <div
        class="sidebar-button-group-button sidebar-button-group-button_purple"
        [class.sidebar-button-group-button_disabled]="!customViewsShared.length"
        cdkOverlayOrigin
        #dropdown_origin="cdkOverlayOrigin"
        (click)="custom_view_dropdown.open()"
      >
        <div class="sidebar-button-group-button__icon sidebar-button-group-button__icon_left icon-open_folder"></div>
        <div class="sidebar-button-group-button__label sidebar-button-group-button__label_fill">
          Use saved components
        </div>
        <div class="sidebar-button-group-button__additional">
          {{ customViewsShared.length }}
        </div>
      </div>
    </div>
  </div>

  <div class="sidebar-button-group__row">
    <div class="sidebar-button-group__item">
      <div
        class="sidebar-button-group-button sidebar-button-group-button_icon sidebar-button-group-button_purple"
        (click)="importFigmaNode()"
        [appTip]="'Import from Figma'"
        [appTipOptions]="{ side: 'bottom' }"
      >
        <div class="sidebar-button-group-button__icon sidebar-button-group-button__icon_figma"></div>
      </div>
    </div>

    <div class="sidebar-button-group__item">
      <div
        class="sidebar-button-group-button sidebar-button-group-button_icon sidebar-button-group-button_purple"
        (click)="importSketchFile()"
        [appTip]="'Import from Sketch'"
        [appTipOptions]="{ side: 'bottom' }"
      >
        <div class="sidebar-button-group-button__icon sidebar-button-group-button__icon_sketch"></div>
      </div>
    </div>

    <mat-menu #dropdown="matMenu">
      <ng-template matMenuContent>
        <button
          mat-menu-item
          class="mat-menu-item-odd"
          [disableRipple]="true"
          (click)="createCustomViewWithViewEditor()"
        >
          <span class="choose-items-item__dropdown-icon icon-canvas"></span>
          Create with Designer
        </button>

        <button mat-menu-item class="mat-menu-item-odd" [disableRipple]="true" (click)="createCustomViewWithIDE()">
          <span class="choose-items-item__dropdown-icon icon-console"></span>
          Create with JS Editor <span class="mat-menu-item-tag mat-menu-item-tag_color_purple">AI</span>
        </button>
      </ng-template>
    </mat-menu>

    <div class="sidebar-button-group__item sidebar-button-group__item_fill">
      <div
        class="sidebar-button-group-button sidebar-button-group-button_purple"
        [class.sidebar-button-group-button_disabled]="createLoading || dropdown_trigger.menuOpen"
        [matMenuTriggerFor]="dropdown"
        #dropdown_trigger="matMenuTrigger"
      >
        <app-loader-small
          *ngIf="createLoading"
          class="sidebar-button-group-button__icon sidebar-button-group-button__icon_left"
        ></app-loader-small>
        <div
          *ngIf="!createLoading"
          class="sidebar-button-group-button__icon sidebar-button-group-button__icon_left icon-plus"
        ></div>
        <div class="sidebar-button-group-button__label">Create new</div>
        <div class="sidebar-button-group-button__tag sidebar-button-group-button__tag_color_purple">AI</div>
      </div>
    </div>
  </div>
</div>
