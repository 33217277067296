var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Injector } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import range from 'lodash/range';
import toPairs from 'lodash/toPairs';
import { of } from 'rxjs';
import { delay, delayWhen, map, switchMap, tap } from 'rxjs/operators';
import { AppFormGroup, FormUtils } from '@common/form-utils';
import { PopupService } from '@common/popups';
import { AppConfigService } from '@core';
import { ServerRequestError } from '@modules/api';
import { MenuGeneratorService } from '@modules/menu';
import { ProjectSettingsService } from '@modules/project-settings';
import { KeygenService, ProjectTokenService, Resource, ResourceDeploy, ResourceName, ResourceType, SecretTokenService } from '@modules/projects';
import { RegionService } from '@modules/regions';
import { DatabaseGeneratorService } from '@modules/resource-generators';
import { ResourceControllerService } from '@modules/resources';
import { ascComparator, controlValue, forceObservable, generateUUID, isSet } from '@shared';
import { BaseResourceSettingsForm } from '../base-resource-settings/base-resource-settings.form';
var DatabaseTableControl = /** @class */ (function (_super) {
    __extends(DatabaseTableControl, _super);
    function DatabaseTableControl(state) {
        if (state === void 0) { state = {}; }
        return _super.call(this, {
            name: new FormControl(isSet(state.name) ? state.name : ''),
            active: new FormControl(isSet(state.active) ? state.active : false)
        }) || this;
    }
    DatabaseTableControl.prototype.setActive = function (value) {
        this.controls.active.patchValue(value);
        this.controls.active.markAsTouched();
    };
    DatabaseTableControl.prototype.toggleActive = function () {
        this.setActive(!this.controls.active.value);
    };
    return DatabaseTableControl;
}(FormGroup));
export { DatabaseTableControl };
export function validateTableArray() {
    return function (control) {
        var selected = control.controls.filter(function (item) { return item.controls.active.value; }).length;
        if (!selected) {
            return { local: ['No tables selected'] };
        }
        if (isSet(control.maxTables) && selected > control.maxTables) {
            return { local: ["You can select only up to " + control.maxTables + " tables"] };
        }
    };
}
var DatabaseTableArray = /** @class */ (function (_super) {
    __extends(DatabaseTableArray, _super);
    function DatabaseTableArray(controls) {
        return _super.call(this, controls, validateTableArray()) || this;
    }
    DatabaseTableArray.prototype.init = function (tables, maxTables) {
        var _this = this;
        this.maxTables = maxTables;
        this.removeControls();
        tables
            .sort(function (lhs, rhs) { return ascComparator(String(lhs).toUpperCase(), String(rhs).toUpperCase()); })
            .forEach(function (item) {
            var control = _this.appendControl(item);
            if (_this.initialValue && _this.initialValue.includes(item)) {
                control.controls.active.patchValue(true);
            }
        });
        this.updateValueAndValidity();
        this.markAsPristine();
    };
    DatabaseTableArray.prototype.setControls = function (controls) {
        var _this = this;
        this.removeControls();
        controls.forEach(function (item) { return _this.push(item); });
    };
    DatabaseTableArray.prototype.removeControls = function () {
        var _this = this;
        range(this.controls.length).forEach(function () { return _this.removeAt(0); });
    };
    DatabaseTableArray.prototype.removeControl = function (control) {
        var newControls = this.controls.filter(function (item) { return !isEqual(item, control); });
        this.setControls(newControls);
    };
    DatabaseTableArray.prototype.createControl = function (name) {
        var control = new DatabaseTableControl({
            name: name
        });
        control.markAsPristine();
        return control;
    };
    DatabaseTableArray.prototype.appendControl = function (name) {
        var control = this.createControl(name);
        this.push(control);
        return control;
    };
    DatabaseTableArray.prototype.getActiveControls = function () {
        return this.controls.filter(function (item) { return item.controls.active.value; });
    };
    DatabaseTableArray.prototype.isAllActive = function () {
        return this.controls.every(function (item) { return item.controls.active.value; });
    };
    DatabaseTableArray.prototype.isAnyActive = function () {
        return this.controls.some(function (item) { return item.controls.active.value; });
    };
    DatabaseTableArray.prototype.setAllActive = function (value) {
        this.controls.forEach(function (item) { return item.setActive(value); });
    };
    DatabaseTableArray.prototype.toggleAllActive = function () {
        if (this.isAllActive()) {
            this.setAllActive(false);
        }
        else {
            this.setAllActive(true);
        }
    };
    return DatabaseTableArray;
}(FormArray));
export { DatabaseTableArray };
var DatabasesResourceSettingsForm = /** @class */ (function (_super) {
    __extends(DatabasesResourceSettingsForm, _super);
    function DatabasesResourceSettingsForm(databaseGeneratorService, resourceControllerService, regionService, keygenService, appConfigService, secretTokenService, formUtils, projectSettingsService, projectTokenService, popupService, menuGeneratorService, injector) {
        var _this = _super.call(this, secretTokenService, formUtils, projectSettingsService, projectTokenService, popupService, menuGeneratorService, injector) || this;
        _this.databaseGeneratorService = databaseGeneratorService;
        _this.resourceControllerService = resourceControllerService;
        _this.regionService = regionService;
        _this.keygenService = keygenService;
        _this.appConfigService = appConfigService;
        _this.secretTokenService = secretTokenService;
        _this.formUtils = formUtils;
        _this.projectSettingsService = projectSettingsService;
        _this.projectTokenService = projectTokenService;
        _this.popupService = popupService;
        _this.menuGeneratorService = menuGeneratorService;
        _this.injector = injector;
        _this.form = new AppFormGroup({
            deploy: new FormControl(ResourceDeploy.Direct, Validators.required),
            choose_tables: new FormControl(false),
            show_instructions: new FormControl(true)
        });
        _this.deployDirectForm = new AppFormGroup({
            region: new FormControl('', _this.appConfigService.jetBridgeRegions ? Validators.required : undefined),
            database_host: new FormControl('', [Validators.required, _this.validateHost(), _this.validatePublicHost()]),
            database_port: new FormControl('', [Validators.required, _this.validateOnlyNumbers()]),
            database_user: new FormControl('', Validators.required),
            database_password: new FormControl(''),
            database_name: new FormControl('', Validators.required),
            database_schema: new FormControl(''),
            database_timezone: new FormControl(''),
            database_extra: new FormControl(''),
            database_ssl_enabled: new FormControl(false),
            database_ssl_ca: new FormControl(''),
            database_ssl_cert: new FormControl(''),
            database_ssl_key: new FormControl(''),
            database_ssh_enabled: new FormControl(false),
            database_ssh_host: new FormControl('', _this.validateSSHRequired()),
            database_ssh_port: new FormControl('', _this.validateSSHRequired()),
            database_ssh_user: new FormControl('', _this.validateSSHRequired()),
            database_ssh_public_key: new FormControl(''),
            database_ssh_private_key: new FormControl(''),
            database_tables: new DatabaseTableArray([])
        });
        _this.deployDirectBigQueryForm = new AppFormGroup({
            region: new FormControl('', _this.appConfigService.jetBridgeRegions ? Validators.required : undefined),
            service_token: new FormControl(null, [Validators.required, _this.validateServiceToken()])
        });
        _this.deployDirectSnowflakeForm = new AppFormGroup({
            region: new FormControl('', _this.appConfigService.jetBridgeRegions ? Validators.required : undefined),
            database_account: new FormControl('', Validators.required),
            database_user: new FormControl('', Validators.required),
            database_password: new FormControl('', Validators.required),
            database_name: new FormControl('', Validators.required),
            database_schema: new FormControl('', Validators.required),
            database_warehouse: new FormControl('', Validators.required)
        });
        _this.deployDirectDatabricksForm = new AppFormGroup({
            region: new FormControl('', _this.appConfigService.jetBridgeRegions ? Validators.required : undefined),
            database_host: new FormControl('', [Validators.required, _this.validateHost(), _this.validatePublicHost()]),
            database_password: new FormControl('', Validators.required),
            database_name: new FormControl('', Validators.required),
            database_http_path: new FormControl('', Validators.required),
            database_catalog: new FormControl('', Validators.required)
        });
        _this.deployDirectAmazonAthenaForm = new AppFormGroup({
            region: new FormControl('', _this.appConfigService.jetBridgeRegions ? Validators.required : undefined),
            database_host: new FormControl('', [Validators.required, _this.validateHost(), _this.validatePublicHost()]),
            database_port: new FormControl('', [Validators.required, _this.validateOnlyNumbers()]),
            database_user: new FormControl('', Validators.required),
            database_password: new FormControl('', Validators.required),
            database_name: new FormControl('', Validators.required),
            database_schema: new FormControl(''),
            database_s3_dir: new FormControl('', Validators.required)
        });
        _this.deployDirectMongoForm = new AppFormGroup({
            region: new FormControl('', _this.appConfigService.jetBridgeRegions ? Validators.required : undefined),
            database_url: new FormControl('', Validators.required),
            database_name: new FormControl('', Validators.required)
        });
        _this.jetBridgeForm = new AppFormGroup({
            jetbridge_manual: new FormControl(false),
            url: new FormControl('', Validators.required),
            token: new FormControl('', [Validators.required, _this.validateUUID()])
        });
        controlValue(_this.deployDirectForm.controls['database_ssh_enabled'])
            .pipe(delay(0))
            .subscribe(function () {
            _this.deployDirectForm.controls['database_host'].updateValueAndValidity();
            _this.deployDirectForm.controls['database_ssh_host'].updateValueAndValidity();
            _this.deployDirectForm.controls['database_ssh_port'].updateValueAndValidity();
            _this.deployDirectForm.controls['database_ssh_user'].updateValueAndValidity();
        });
        return _this;
    }
    DatabasesResourceSettingsForm.prototype.validateHost = function () {
        return function (control) {
            if (!isSet(control.value)) {
                return;
            }
            var value = (control.value || '').toLowerCase();
            if (['http://', 'https://', 'ftp://', '//'].some(function (item) { return value.startsWith(item); }) ||
                value.indexOf('/') !== -1 ||
                value.match(/:\d+$/)) {
                return { local: ['Only HOST (ex. db.example.com, 136.244.82.XXX) should be specified'] };
            }
        };
    };
    DatabasesResourceSettingsForm.prototype.validatePublicHost = function () {
        return function (control) {
            if (!isSet(control.value)) {
                return;
            }
            var parent = control.parent;
            if (!parent) {
                return;
            }
            var sshEnabled = parent.controls['database_ssh_enabled']
                ? parent.controls['database_ssh_enabled'].value
                : false;
            if (sshEnabled) {
                return;
            }
            var privateHosts = [/^localhost$/, /^127\.0\.0\.1$/, /^192\.\d{1,3}\.\d{1,3}\.\d{1,3}$/];
            if (privateHosts.some(function (regex) { return control.value && control.value.trim().match(regex); })) {
                return { local: ['Only public HOST is allowed here, use Docker or Python installation for private networks'] };
            }
        };
    };
    DatabasesResourceSettingsForm.prototype.validateOnlyNumbers = function () {
        return function (control) {
            if (!isSet(control.value)) {
                return;
            }
            var isNumber = /^[0-9]*$/.test(control.value);
            if (!isNumber) {
                return { local: ['Only numbers are allowed'] };
            }
            return null;
        };
    };
    DatabasesResourceSettingsForm.prototype.validateSSHRequired = function () {
        return function (control) {
            var parent = control.parent;
            if (!parent || !parent.controls['database_ssh_enabled'].value) {
                return;
            }
            if (!isSet(control.value)) {
                return { required: true };
            }
        };
    };
    DatabasesResourceSettingsForm.prototype.validateServiceToken = function () {
        return function (control) {
            if (!isSet(control.value)) {
                return;
            }
            try {
                var value = JSON.parse(control.value);
                if (!value['type'] || value['type'] != 'service_account' || !value['project_id'] || !value['private_key']) {
                    return { local: ['Service key is not valid or not enough permissions'] };
                }
            }
            catch (e) {
                return { local: ['Service key has incorrect format'] };
            }
        };
    };
    DatabasesResourceSettingsForm.prototype.validateUUID = function () {
        return function (control) {
            if (!isSet(control.value)) {
                return;
            }
            var cleanValue = String(control.value).toLowerCase().replace(/-/g, '');
            var isUUID = /^[a-f0-9]{32}$/.test(cleanValue);
            if (!isUUID) {
                return { local: ['Not a valid UUID, should be string of 32 hexadecimal digits'] };
            }
            return null;
        };
    };
    DatabasesResourceSettingsForm.prototype.initResourceValue = function () {
        var _this = this;
        return this.databaseGeneratorService.getParamsOptions(this.project, this.environment, this.resource).pipe(map(function (result) {
            _this.form.patchValue({
                deploy: result.deploy,
                show_instructions: false
            });
            _this.jetBridgeForm.patchValue({
                url: result.url,
                token: _this.resource.token || ''
            });
            if (_this.typeItem.name == ResourceName.BigQuery) {
                try {
                    var serviceTokenStr = atob(result.database_password);
                    _this.deployDirectBigQueryForm.patchValue({
                        region: result.region,
                        service_token: serviceTokenStr
                    });
                }
                catch (e) { }
                _this.databaseTables.initialValue = result.database_only;
            }
            else if (_this.typeItem.name == ResourceName.Snowflake) {
                var databaseExtra = new URLSearchParams(result.database_extra);
                _this.deployDirectSnowflakeForm.patchValue({
                    region: result.region,
                    database_account: result.database_host,
                    database_port: result.database_port,
                    database_name: result.database_name,
                    database_user: result.database_user,
                    database_password: result.database_password,
                    database_schema: result.database_schema,
                    database_warehouse: databaseExtra.get('warehouse')
                });
                _this.databaseTables.initialValue = result.database_only;
            }
            else if (_this.typeItem.name == ResourceName.Databricks) {
                var databaseExtra = new URLSearchParams(result.database_extra);
                _this.deployDirectDatabricksForm.patchValue({
                    region: result.region,
                    database_host: result.database_host,
                    database_name: result.database_name,
                    database_password: result.database_password,
                    database_http_path: databaseExtra.get('http_path'),
                    database_catalog: databaseExtra.get('catalog')
                });
                _this.databaseTables.initialValue = result.database_only;
            }
            else if (_this.typeItem.name == ResourceName.AmazonAthena) {
                var databaseExtra = new URLSearchParams(result.database_extra);
                _this.deployDirectAmazonAthenaForm.patchValue({
                    region: result.region,
                    database_host: result.database_host,
                    database_port: result.database_port,
                    database_name: result.database_name,
                    database_user: result.database_user,
                    database_password: result.database_password,
                    database_schema: result.database_schema,
                    database_s3_dir: databaseExtra.get('s3_staging_dir')
                });
                _this.databaseTables.initialValue = result.database_only;
            }
            else if (_this.typeItem.name == ResourceName.MongoDB) {
                _this.deployDirectMongoForm.patchValue({
                    region: result.region,
                    database_url: result.database_url,
                    database_name: result.database_name
                });
                _this.databaseTables.initialValue = result.database_only;
            }
            else {
                _this.deployDirectForm.patchValue({
                    region: result.region,
                    database_host: result.database_host,
                    database_port: result.database_port,
                    database_name: result.database_name,
                    database_user: result.database_user,
                    database_password: result.database_password,
                    database_schema: result.database_schema,
                    database_timezone: result.database_timezone,
                    database_extra: result.database_extra,
                    database_only: result.database_only,
                    database_ssl_enabled: [
                        result.database_ssl_ca,
                        result.database_ssl_cert,
                        result.database_ssl_key
                    ].some(function (item) { return isSet(item); }),
                    database_ssl_ca: result.database_ssl_ca,
                    database_ssl_cert: result.database_ssl_cert,
                    database_ssl_key: result.database_ssl_key,
                    database_ssh_enabled: [
                        result.database_ssh_host,
                        result.database_ssh_port,
                        result.database_ssh_user,
                        result.database_ssh_public_key,
                        result.database_ssh_private_key
                    ].some(function (item) { return isSet(item); }),
                    database_ssh_host: result.database_ssh_host,
                    database_ssh_port: result.database_ssh_port,
                    database_ssh_user: result.database_ssh_user,
                    database_ssh_public_key: result.database_ssh_public_key,
                    database_ssh_private_key: result.database_ssh_private_key
                });
                _this.databaseTables.initialValue = result.database_only;
                if (!isSet(result.database_ssh_public_key) || !isSet(result.database_ssh_private_key)) {
                    _this.initSSHKeys();
                }
            }
        }));
    };
    DatabasesResourceSettingsForm.prototype.initDefaultValue = function () {
        var _a = this.params, defaultShowInstructions = _a.defaultShowInstructions, defaultToken = _a.defaultToken, defaultApiBaseUrl = _a.defaultApiBaseUrl, defaultDeploy = _a.defaultDeploy;
        if (this.typeItem.name == ResourceName.SQLite) {
            this.form.patchValue({ deploy: ResourceDeploy.Docker }, { emitEvent: false });
        }
        if (isSet(defaultDeploy)) {
            this.form.patchValue({ deploy: defaultDeploy });
        }
        if (isSet(defaultShowInstructions)) {
            this.form.patchValue({ show_instructions: defaultShowInstructions });
        }
        if (isSet(defaultApiBaseUrl)) {
            this.jetBridgeForm.patchValue({ url: defaultApiBaseUrl });
        }
        if (isSet(defaultToken)) {
            this.jetBridgeForm.patchValue({ token: defaultToken });
        }
        if (![ResourceName.BigQuery, ResourceName.Snowflake].includes(this.typeItem.name)) {
            this.initSSHKeys();
        }
        return of(undefined);
    };
    DatabasesResourceSettingsForm.prototype.initSSHKeys = function () {
        var _this = this;
        this.keygenService.generate(this.project, this.environment).subscribe(function (result) {
            _this.deployDirectForm.patchValue({
                database_ssh_public_key: result.public_key,
                database_ssh_private_key: result.private_key
            });
        });
    };
    DatabasesResourceSettingsForm.prototype.isDeployDirectFormConnectionValid = function () {
        if (this.typeItem.name == ResourceName.BigQuery) {
            return this.deployDirectBigQueryForm.valid;
        }
        else if (this.typeItem.name == ResourceName.Snowflake) {
            return this.deployDirectSnowflakeForm.valid;
        }
        else if (this.typeItem.name == ResourceName.Databricks) {
            return this.deployDirectDatabricksForm.valid;
        }
        else if (this.typeItem.name == ResourceName.AmazonAthena) {
            return this.deployDirectAmazonAthenaForm.valid;
        }
        else if (this.typeItem.name == ResourceName.MongoDB) {
            return this.deployDirectMongoForm.valid;
        }
        else {
            return toPairs(this.deployDirectForm.controls)
                .filter(function (_a) {
                var name = _a[0];
                return name != 'database_tables';
            })
                .every(function (_a) {
                var name = _a[0], control = _a[1];
                return control.valid;
            });
        }
    };
    DatabasesResourceSettingsForm.prototype.isValid = function () {
        if (!_super.prototype.isValid.call(this)) {
            return false;
        }
        if (this.form.value['deploy'] == ResourceDeploy.Direct) {
            if (this.typeItem.name == ResourceName.BigQuery) {
                return this.deployDirectBigQueryForm.valid && this.deployDirectForm.controls['database_tables'].valid;
            }
            else if (this.typeItem.name == ResourceName.Snowflake) {
                return this.deployDirectSnowflakeForm.valid && this.deployDirectForm.controls['database_tables'].valid;
            }
            else if (this.typeItem.name == ResourceName.Databricks) {
                return this.deployDirectDatabricksForm.valid && this.deployDirectForm.controls['database_tables'].valid;
            }
            else if (this.typeItem.name == ResourceName.AmazonAthena) {
                return this.deployDirectAmazonAthenaForm.valid && this.deployDirectForm.controls['database_tables'].valid;
            }
            else if (this.typeItem.name == ResourceName.MongoDB) {
                return this.deployDirectMongoForm.valid && this.deployDirectForm.controls['database_tables'].valid;
            }
            else {
                return this.deployDirectForm.valid;
            }
        }
        else if (this.form.value['deploy'] == ResourceDeploy.Docker) {
            return this.jetBridgeForm.valid;
        }
        else if (this.form.value['deploy'] == ResourceDeploy.Python) {
            return this.jetBridgeForm.valid;
        }
        else {
            return true;
        }
    };
    Object.defineProperty(DatabasesResourceSettingsForm.prototype, "regionControl", {
        get: function () {
            if (this.typeItem.name == ResourceName.BigQuery) {
                return this.deployDirectBigQueryForm.controls['region'];
            }
            else if (this.typeItem.name == ResourceName.Snowflake) {
                return this.deployDirectSnowflakeForm.controls['region'];
            }
            else if (this.typeItem.name == ResourceName.Databricks) {
                return this.deployDirectDatabricksForm.controls['region'];
            }
            else if (this.typeItem.name == ResourceName.AmazonAthena) {
                return this.deployDirectAmazonAthenaForm.controls['region'];
            }
            else if (this.typeItem.name == ResourceName.MongoDB) {
                return this.deployDirectMongoForm.controls['region'];
            }
            else {
                return this.deployDirectForm.controls['region'];
            }
        },
        enumerable: true,
        configurable: true
    });
    DatabasesResourceSettingsForm.prototype.setDefaultRegion = function (region) {
        if (!this.resource && region) {
            if (this.typeItem.name == ResourceName.BigQuery) {
                if (!this.deployDirectBigQueryForm.value['region']) {
                    this.deployDirectBigQueryForm.patchValue({ region: region.uid });
                }
            }
            else if (this.typeItem.name == ResourceName.Snowflake) {
                if (!this.deployDirectSnowflakeForm.value['region']) {
                    this.deployDirectSnowflakeForm.patchValue({ region: region.uid });
                }
            }
            else if (this.typeItem.name == ResourceName.Databricks) {
                if (!this.deployDirectDatabricksForm.value['region']) {
                    this.deployDirectDatabricksForm.patchValue({ region: region.uid });
                }
            }
            else if (this.typeItem.name == ResourceName.AmazonAthena) {
                if (!this.deployDirectAmazonAthenaForm.value['region']) {
                    this.deployDirectAmazonAthenaForm.patchValue({ region: region.uid });
                }
            }
            else if (this.typeItem.name == ResourceName.MongoDB) {
                if (!this.deployDirectMongoForm.value['region']) {
                    this.deployDirectMongoForm.patchValue({ region: region.uid });
                }
            }
            else {
                if (!this.deployDirectForm.value['region']) {
                    this.deployDirectForm.patchValue({ region: region.uid });
                }
            }
        }
    };
    Object.defineProperty(DatabasesResourceSettingsForm.prototype, "databaseTables", {
        get: function () {
            return this.deployDirectForm.controls['database_tables'];
        },
        enumerable: true,
        configurable: true
    });
    DatabasesResourceSettingsForm.prototype.getOptions = function () {
        var options = {
            deploy: this.form.value['deploy']
        };
        if (this.form.value['deploy'] == ResourceDeploy.Direct) {
            if (this.typeItem.name == ResourceName.BigQuery) {
                var value = this.deployDirectBigQueryForm.value;
                options.region = value['region'];
                try {
                    var serviceToken = JSON.parse(value['service_token']);
                    options.database_name = serviceToken['project_id'];
                    options.database_password = btoa(JSON.stringify(serviceToken));
                }
                catch (e) {
                    console.error(e);
                }
            }
            else if (this.typeItem.name == ResourceName.Snowflake) {
                var value = this.deployDirectSnowflakeForm.value;
                options.region = value['region'];
                options.database_host = value['database_account'];
                options.database_name = value['database_name'];
                options.database_user = value['database_user'];
                options.database_password = value['database_password'];
                options.database_schema = value['database_schema'];
                options.database_extra = "warehouse=" + value['database_warehouse'];
            }
            else if (this.typeItem.name == ResourceName.Databricks) {
                var value = this.deployDirectDatabricksForm.value;
                options.region = value['region'];
                options.database_host = value['database_host'];
                options.database_name = value['database_name'];
                options.database_user = 'token';
                options.database_password = value['database_password'];
                options.database_schema = value['database_schema'];
                options.database_extra = "http_path=" + value['database_http_path'] + "&catalog=" + value['database_catalog'];
            }
            else if (this.typeItem.name == ResourceName.AmazonAthena) {
                var value = this.deployDirectAmazonAthenaForm.value;
                options.region = value['region'];
                options.database_host = value['database_host'];
                options.database_port = value['database_port'];
                options.database_name = value['database_name'];
                options.database_user = value['database_user'];
                options.database_password = value['database_password'];
                options.database_schema = value['database_schema'];
                options.database_extra = "s3_staging_dir=" + value['database_s3_dir'];
            }
            else if (this.typeItem.name == ResourceName.MongoDB) {
                var value = this.deployDirectMongoForm.value;
                options.region = value['region'];
                options.database_url = value['database_url'];
                options.database_name = value['database_name'];
            }
            else {
                var value = this.deployDirectForm.value;
                options.region = value['region'];
                options.database_host = value['database_host'];
                options.database_port = value['database_port'];
                options.database_name = value['database_name'];
                options.database_user = value['database_user'];
                options.database_password = value['database_password'];
                options.database_schema = value['database_schema'];
                options.database_timezone = value['database_timezone'];
                options.database_extra = value['database_extra'];
                if (value['database_ssl_enabled']) {
                    options.database_ssl_ca = value['database_ssl_ca'];
                    options.database_ssl_cert = value['database_ssl_cert'];
                    options.database_ssl_key = value['database_ssl_key'];
                }
                if (value['database_ssh_enabled']) {
                    options.database_ssh_host = value['database_ssh_host'];
                    options.database_ssh_port = value['database_ssh_port'];
                    options.database_ssh_user = value['database_ssh_user'];
                    options.database_ssh_public_key = value['database_ssh_public_key'];
                    options.database_ssh_private_key = value['database_ssh_private_key'];
                }
            }
            options.database_only = this.databaseTables.getActiveControls().map(function (item) { return item.controls.name.value; });
            options.token = this.jetBridgeForm.value['token'] || generateUUID();
            if (this.appConfigService.jetBridgeRegions) {
                return this.regionService.getDetail(options.region).pipe(map(function (region) {
                    options.url = region.jetBridgeCloudUrl;
                    return options;
                }));
            }
            else {
                options.url = this.appConfigService.jetBridgeCloudBaseUrl;
                return of(options);
            }
        }
        else {
            options.url = this.jetBridgeForm.value['url'];
            options.token = this.jetBridgeForm.value['token'];
            return of(options);
        }
    };
    DatabasesResourceSettingsForm.prototype.getGeneralParams = function () {
        var _this = this;
        return this.getOptions().pipe(switchMap(function (options) {
            return _this.databaseGeneratorService.generateGeneralParams(_this.project, _this.environment, _this.typeItem, options);
        }), map(function (result) {
            return __assign({}, result, { resourceName: _this.resourceForm.value['name'] });
        }));
    };
    DatabasesResourceSettingsForm.prototype.discoverConnection = function () {
        var _this = this;
        return forceObservable(this.getGeneralParams()).pipe(switchMap(function (result) {
            var instance = _this.resource ? cloneDeep(_this.resource) : new Resource();
            instance.type = _this.typeItem.resourceType;
            instance.typeItem = _this.typeItem;
            instance.token = result.resourceToken;
            instance.params = result.resourceParams;
            var controller = _this.resourceControllerService.get(ResourceType.JetBridge);
            return controller.discoverConnection(instance);
        }));
    };
    DatabasesResourceSettingsForm.prototype.discoverTables = function () {
        var _this = this;
        return forceObservable(this.getGeneralParams()).pipe(switchMap(function (result) {
            var instance = _this.resource ? cloneDeep(_this.resource) : new Resource();
            instance.type = _this.typeItem.resourceType;
            instance.typeItem = _this.typeItem;
            instance.token = result.resourceToken;
            instance.params = result.resourceParams;
            var controller = _this.resourceControllerService.get(ResourceType.JetBridge);
            return controller.discoverTables(instance);
        }), tap(function (result) {
            if (!result.tables.length) {
                throw new ServerRequestError('Database does not have any valid Tables');
            }
        }));
    };
    DatabasesResourceSettingsForm.prototype.getParams = function () {
        var _this = this;
        return this.getOptions().pipe(switchMap(function (options) {
            return _this.databaseGeneratorService.generateParams(_this.project, _this.environment, _this.typeItem, options);
        }), map(function (result) {
            return __assign({}, result, { resourceName: _this.resourceForm.value['name'] });
        }));
    };
    DatabasesResourceSettingsForm.prototype.submit = function () {
        var _this = this;
        var createResource = !this.resource;
        this.deploy = this.form.value['deploy'];
        return _super.prototype.submit.call(this).pipe(delayWhen(function () {
            if (createResource) {
                return _this.keygenService.clear(_this.project, _this.environment);
            }
            else {
                return of(undefined);
            }
        }));
    };
    return DatabasesResourceSettingsForm;
}(BaseResourceSettingsForm));
export { DatabasesResourceSettingsForm };
