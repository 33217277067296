<app-customize-bar-table-settings
  *ngIf="settings?.type == layouts.Table"
  [name]="title"
  [nameEnabled]="titleEnabled"
  [nameEditable]="titleEditable"
  [backEnabled]="backEnabled"
  [parentElement]="parentElement"
  [parentPopup]="parentPopup"
  [settings]="settings"
  [element]="element"
  [visibleInput]="visibleInput"
  [visibleEditable]="visibleEditable"
  [cardWrapEditable]="cardWrapEditable"
  [cardWrap]="cardWrap"
  [elementStyles]="elementStyles"
  [elementStylesEditable]="elementStylesEditable"
  [context]="context"
  [contextElement]="contextElement"
  [contextElementPath]="contextElementPath"
  [contextElementPaths]="contextElementPaths"
  [submitEnabled]="submitEnabled"
  [deleteEnabled]="deleteEnabled"
  [firstInit]="firstInit"
  [setupOnCreate]="setupOnCreate"
  (event)="onSaveEvent(layouts.Table, $event)"
>
</app-customize-bar-table-settings>
<app-customize-bar-map-settings
  *ngIf="settings?.type == layouts.Map"
  [name]="title"
  [nameEnabled]="titleEnabled"
  [nameEditable]="titleEditable"
  [backEnabled]="backEnabled"
  [parentElement]="parentElement"
  [parentPopup]="parentPopup"
  [settings]="settings"
  [element]="element"
  [visibleInput]="visibleInput"
  [visibleEditable]="visibleEditable"
  [cardWrapEditable]="cardWrapEditable"
  [cardWrap]="cardWrap"
  [elementStyles]="elementStyles"
  [elementStylesEditable]="elementStylesEditable"
  [context]="context"
  [contextElement]="contextElement"
  [contextElementPath]="contextElementPath"
  [contextElementPaths]="contextElementPaths"
  [submitEnabled]="submitEnabled"
  [deleteEnabled]="deleteEnabled"
  [firstInit]="firstInit"
  [setupOnCreate]="setupOnCreate"
  (event)="onSaveEvent(layouts.Map, $event)"
>
</app-customize-bar-map-settings>
<app-customize-bar-kanban-board-settings
  *ngIf="settings?.type == layouts.KanbanBoard"
  [name]="title"
  [nameEnabled]="titleEnabled"
  [nameEditable]="titleEditable"
  [backEnabled]="backEnabled"
  [parentElement]="parentElement"
  [parentPopup]="parentPopup"
  [settings]="settings"
  [element]="element"
  [visibleInput]="visibleInput"
  [visibleEditable]="visibleEditable"
  [cardWrapEditable]="cardWrapEditable"
  [cardWrap]="cardWrap"
  [elementStyles]="elementStyles"
  [elementStylesEditable]="elementStylesEditable"
  [context]="context"
  [contextElement]="contextElement"
  [contextElementPath]="contextElementPath"
  [contextElementPaths]="contextElementPaths"
  [submitEnabled]="submitEnabled"
  [deleteEnabled]="deleteEnabled"
  [firstInit]="firstInit"
  [setupOnCreate]="setupOnCreate"
  (event)="onSaveEvent(layouts.KanbanBoard, $event)"
>
</app-customize-bar-kanban-board-settings>
<app-customize-bar-calendar-settings
  *ngIf="settings?.type == layouts.Calendar"
  [name]="title"
  [nameEnabled]="titleEnabled"
  [nameEditable]="titleEditable"
  [backEnabled]="backEnabled"
  [parentElement]="parentElement"
  [parentPopup]="parentPopup"
  [settings]="settings"
  [element]="element"
  [visibleInput]="visibleInput"
  [visibleEditable]="visibleEditable"
  [cardWrapEditable]="cardWrapEditable"
  [cardWrap]="cardWrap"
  [elementStyles]="elementStyles"
  [elementStylesEditable]="elementStylesEditable"
  [context]="context"
  [contextElement]="contextElement"
  [contextElementPath]="contextElementPath"
  [contextElementPaths]="contextElementPaths"
  [submitEnabled]="submitEnabled"
  [deleteEnabled]="deleteEnabled"
  [firstInit]="firstInit"
  [setupOnCreate]="setupOnCreate"
  (event)="onSaveEvent(layouts.Calendar, $event)"
>
</app-customize-bar-calendar-settings>
<app-customize-bar-grid-settings
  *ngIf="settings?.type == layouts.Grid"
  [name]="title"
  [nameEnabled]="titleEnabled"
  [nameEditable]="titleEditable"
  [backEnabled]="backEnabled"
  [parentElement]="parentElement"
  [parentPopup]="parentPopup"
  [settings]="settings"
  [element]="element"
  [visibleInput]="visibleInput"
  [visibleEditable]="visibleEditable"
  [cardWrapEditable]="cardWrapEditable"
  [cardWrap]="cardWrap"
  [elementStyles]="elementStyles"
  [elementStylesEditable]="elementStylesEditable"
  [context]="context"
  [contextElement]="contextElement"
  [contextElementPath]="contextElementPath"
  [contextElementPaths]="contextElementPaths"
  [submitEnabled]="submitEnabled"
  [deleteEnabled]="deleteEnabled"
  [firstInit]="firstInit"
  [setupOnCreate]="setupOnCreate"
  (event)="onSaveEvent(layouts.Grid, $event)"
>
</app-customize-bar-grid-settings>
<app-customize-bar-carousel-settings
  *ngIf="settings?.type == layouts.Carousel"
  [name]="title"
  [nameEnabled]="titleEnabled"
  [nameEditable]="titleEditable"
  [backEnabled]="backEnabled"
  [parentElement]="parentElement"
  [parentPopup]="parentPopup"
  [settings]="settings"
  [element]="element"
  [visibleInput]="visibleInput"
  [visibleEditable]="visibleEditable"
  [cardWrapEditable]="cardWrapEditable"
  [cardWrap]="cardWrap"
  [elementStyles]="elementStyles"
  [elementStylesEditable]="elementStylesEditable"
  [context]="context"
  [contextElement]="contextElement"
  [contextElementPath]="contextElementPath"
  [contextElementPaths]="contextElementPaths"
  [submitEnabled]="submitEnabled"
  [deleteEnabled]="deleteEnabled"
  [firstInit]="firstInit"
  [setupOnCreate]="setupOnCreate"
  (event)="onSaveEvent(layouts.Grid, $event)"
>
</app-customize-bar-carousel-settings>
<app-customize-bar-timeline-settings
  *ngIf="settings?.type == layouts.Timeline"
  [name]="title"
  [nameEnabled]="titleEnabled"
  [nameEditable]="titleEditable"
  [backEnabled]="backEnabled"
  [parentElement]="parentElement"
  [parentPopup]="parentPopup"
  [settings]="settings"
  [element]="element"
  [visibleInput]="visibleInput"
  [visibleEditable]="visibleEditable"
  [cardWrapEditable]="cardWrapEditable"
  [cardWrap]="cardWrap"
  [elementStyles]="elementStyles"
  [elementStylesEditable]="elementStylesEditable"
  [context]="context"
  [contextElement]="contextElement"
  [contextElementPath]="contextElementPath"
  [contextElementPaths]="contextElementPaths"
  [submitEnabled]="submitEnabled"
  [deleteEnabled]="deleteEnabled"
  [firstInit]="firstInit"
  [setupOnCreate]="setupOnCreate"
  (event)="onSaveEvent(layouts.Grid, $event)"
>
</app-customize-bar-timeline-settings>
