import { ChangeDetectorRef, OnChanges, OnDestroy, OnInit } from '@angular/core';
import fromPairs from 'lodash/fromPairs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { CustomViewsStore } from '@modules/custom-views';
import { getModelAttributesByColumns, ViewContext, ViewContextElement } from '@modules/customize';
import { applyParamInput$, createFormFieldFactory, CustomViewDisplayField, DisplayField, ParameterField } from '@modules/fields';
import { ITEM_OUTPUT } from '@modules/list';
import { Model } from '@modules/models';
import { EMPTY } from '@shared';
var ModelCardItemComponent = /** @class */ (function () {
    function ModelCardItemComponent(customViewsStore, cd) {
        this.customViewsStore = customViewsStore;
        this.cd = cd;
        this.columns = [];
        this.label = true;
        this.columnActions = [];
        this.createField = createFormFieldFactory();
        this.item$ = new BehaviorSubject(undefined);
        this.viewParameters = [];
        this.viewParams$ = of({});
        this.actions = [];
    }
    ModelCardItemComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.item$
            .pipe(switchMap(function (item) {
            if (item instanceof CustomViewDisplayField) {
                return _this.customViewsStore.getDetail(item.customView);
            }
            else {
                return of(undefined);
            }
        }), untilDestroyed(this))
            .subscribe(function (customView) {
            _this.customView = customView;
            _this.cd.markForCheck();
            _this.updateViewParameters();
            _this.updateViewParams();
        });
    };
    ModelCardItemComponent.prototype.ngOnDestroy = function () { };
    ModelCardItemComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if (changes.item) {
            this.item$.next(this.item);
        }
        if (changes.item || changes.columnActions) {
            var columnActions = this.columnActions.find(function (item) { return item.name == _this.item.name; });
            this.actions = columnActions ? columnActions.actions : [];
        }
    };
    ModelCardItemComponent.prototype.updateViewParameters = function () {
        if (!this.customView) {
            this.viewParameters = [];
            this.cd.markForCheck();
            return;
        }
        this.viewParameters = this.columns.map(function (item) {
            var parameter = new ParameterField();
            parameter.name = item.name;
            parameter.verboseName = item.verboseName;
            parameter.field = item.field;
            parameter.params = item.params;
            return parameter;
        });
        this.cd.markForCheck();
    };
    ModelCardItemComponent.prototype.updateViewParams = function () {
        var _this = this;
        if (!this.customView) {
            this.viewParams$ = of({});
            this.cd.markForCheck();
            return;
        }
        var modelParams = getModelAttributesByColumns(this.model, this.columns);
        var customViewField = this.item instanceof CustomViewDisplayField ? this.item : undefined;
        var mappings = customViewField ? customViewField.customViewMappings : [];
        this.viewParams$ = combineLatest(mappings.map(function (mapping) {
            var _a;
            if (mapping.sourceParameterInput) {
                return applyParamInput$(mapping.sourceParameterInput, {
                    context: _this.context,
                    contextElement: _this.contextElement,
                    localContext: (_a = {},
                        _a[ITEM_OUTPUT] = modelParams,
                        _a)
                }).pipe(map(function (value) {
                    return {
                        name: mapping.targetParameter,
                        value: value !== EMPTY ? value : undefined
                    };
                }));
            }
            else {
                var value = modelParams[mapping.sourceParameter];
                return of({
                    name: mapping.targetParameter,
                    value: value
                });
            }
        })).pipe(map(function (values) {
            return fromPairs(values.map(function (item) { return [item.name, item.value]; }));
        }));
        this.cd.markForCheck();
    };
    return ModelCardItemComponent;
}());
export { ModelCardItemComponent };
