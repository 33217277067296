import isEqual from 'lodash/isEqual';
import pickBy from 'lodash/pickBy';

import { isSet } from '@shared';

import { HttpContentType } from './http-content-type';
import { HttpMethod } from './http-method';

export enum HttpResponseType {
  JSON = 'json',
  Text = 'text',
  XML = 'xml',
  Blob = 'blob'
}

export const DefaultHttpQueryBodyTransformer = `// return Body as Object
return data;`;

export const DefaultHttpQueryErrorTransformer = `// add custom transformation here
if (http.code >= 200 && http.code < 400) {
  // no error if success code
  return null;
} else if (data['message']) {
  // display error message if any
  return data['message'];
} else {
  // display error without message otherwise
  return true;
}`;

export enum HttpQueryAuthType {
  BasicAuth = 'basic_auth',
  Key = 'key',
  OAuth2 = 'oauth_2'
}

export class HttpQuery {
  method: HttpMethod = HttpMethod.GET;
  url: string;
  urlPath: string;
  queryParams: { name: string; value: string }[] = [];
  headers: { name: string; value: string }[];
  authType: HttpQueryAuthType;
  authParams: Object = {};
  bodyType: HttpContentType = HttpContentType.JSON;
  body: any | null = '';
  responseType: HttpResponseType = HttpResponseType.JSON;
  responseTransformer: string;
  responsePath: string;
  errorTransformer: string;
  bodyTransformer: string;
  requestResponse: any;
  requestResponseHeaders: { name: string; value: string }[] = [];
  requestTokens = {};

  deserialize(data: Object): HttpQuery {
    this.url = data['url'];
    this.urlPath = data['url_path'];
    this.responseTransformer = data['response_transformer'];
    this.responsePath = data['response_path'];
    this.errorTransformer = data['error_transformer'];
    this.bodyTransformer = data['body_transformer'];

    if (data['method']) {
      this.method = data['method'];
    }

    if (data['auth_type'] && data['auth_type'] != 'none') {
      this.authType = data['auth_type'];
    }

    if (data['auth_params']) {
      this.authParams = data['auth_params'];
    }

    if (data['body_type']) {
      this.bodyType = data['body_type'];
    }

    if (data['body']) {
      this.body = data['body'];
    }

    if (data['query_params']) {
      this.queryParams = data['query_params'];
    }

    if (data['headers']) {
      this.headers = data['headers'];
    }

    if (data['response_type']) {
      this.responseType = data['response_type'];
    }

    if (data['request_response']) {
      this.requestResponse = data['request_response'];
    }

    if (data['request_response_headers']) {
      this.requestResponseHeaders = data['request_response_headers'];
    }

    if (data['request_tokens']) {
      this.requestTokens = data['request_tokens'];
    }

    return this;
  }

  serialize(fields?: string[]): Object {
    let data: Object = {
      method: this.method,
      url: this.url,
      url_path: this.urlPath,
      auth_type: this.authType,
      auth_params: this.authParams,
      body_type: this.bodyType,
      body: this.body,
      headers: this.headers || [],
      query_params: this.queryParams,
      response_type: this.responseType,
      response_transformer: this.responseTransformer,
      response_path: this.responsePath,
      error_transformer: this.errorTransformer,
      body_transformer: this.bodyTransformer,
      request_response: this.requestResponse,
      request_response_headers: this.requestResponseHeaders,
      request_tokens: this.requestTokens
    };
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }

  merge(query: HttpQuery, onCreate = false) {
    if (!query) {
      return;
    }

    if (!this.url) {
      // this.url = query.url;
    }

    if (query.headers) {
      query.headers.forEach(item => {
        if (this.headers && this.headers.find(i => i.name == item.name)) {
          return;
        }

        if (!this.headers) {
          this.headers = [];
        }

        this.headers.push(item);
      });
    }

    query.queryParams.forEach(item => {
      if (this.queryParams.find(i => i.name == item.name)) {
        return;
      }

      this.queryParams.push(item);
    });

    if (onCreate) {
      if (isSet(query.method) && query.method != HttpMethod.GET) {
        this.method = query.method;
      }

      if (isSet(query.bodyType) && query.bodyType != HttpContentType.JSON) {
        this.bodyType = query.bodyType;
      }
    }
  }

  getEffectiveUrl(baseQuery?: HttpQuery): string {
    if (baseQuery && isSet(baseQuery.url) && isSet(this.urlPath)) {
      return baseQuery.url + this.urlPath;
    } else if (isSet(this.url)) {
      return this.url;
    } else if (baseQuery && isSet(baseQuery.url)) {
      return baseQuery.url;
    } else if (isSet(this.urlPath)) {
      return this.urlPath;
    } else {
      return '';
    }
  }

  isConfigured(baseQuery?: HttpQuery): boolean {
    // return isSet(this.getEffectiveUrl(baseQuery));
    if (!baseQuery) {
      return true;
    }

    return isSet(this.getEffectiveUrl(baseQuery));
  }

  equals(another: this): boolean {
    return isEqual(this.serialize(), another.serialize());
  }

  getRequestResponseHeadersAsObject(): Record<string, string> {
    if (!this.requestResponseHeaders) {
      return {};
    }

    return this.requestResponseHeaders.reduce((acc, item) => {
      if (!acc.hasOwnProperty(item.name)) {
        acc[item.name] = item.value;
      }
      return acc;
    }, {});
  }
}
