import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';

import { AlignHorizontal, AlignVertical } from '@modules/customize';

@Component({
  selector: 'app-element-wrapper',
  templateUrl: './element-wrapper.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ElementWrapperComponent implements OnInit {
  @HostBinding('class.element-wrapper') cls = true;
  @Input() @HostBinding('class.element-wrapper_wrap') wrap = true;
  @Input() @HostBinding('class.element-wrapper_styles') styles = true;
  @Input() alignHorizontal: AlignHorizontal;
  @Input() alignVertical: AlignVertical;
  @Input() @HostBinding('class.element-wrapper_fill-vertical') fillVertical = false;

  alignsHorizontal = AlignHorizontal;
  alignsVertical = AlignVertical;

  constructor() {}

  ngOnInit() {}
}
