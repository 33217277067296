import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import fromPairs from 'lodash/fromPairs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { filter, switchMap } from 'rxjs/operators';

import { NotificationService } from '@common/notifications';
import { RuntimeEnvironment } from '@core';
import { ActionType } from '@modules/actions';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ServerRequestError } from '@modules/api';
import { WorkflowEditController } from '@modules/customize-bar';
import { MenuSection, MenuService } from '@modules/menu';
import {
  CurrentEnvironmentStore,
  CurrentProjectStore,
  hasEnvironmentPermission,
  ProjectPermissions
} from '@modules/projects';
import { RoutingService } from '@modules/routing';
import { Automation, AutomationService, Workflow, WorkflowRunStatus } from '@modules/workflow';
import { ascComparator, errorToString, isSet } from '@shared';

@Component({
  selector: 'app-automations',
  templateUrl: './automations.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AutomationsComponent implements OnInit, OnDestroy {
  automations: Automation[];
  loading = false;
  error: string;
  createLoading = false;
  analyticsSource = 'automations';

  trackAutomationFn(i, item: Automation) {
    return item.uid;
  }

  constructor(
    public currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private automationService: AutomationService,
    private workflowEditController: WorkflowEditController,
    private menuService: MenuService,
    private routing: RoutingService,
    private notificationService: NotificationService,
    private analyticsService: UniversalAnalyticsService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    if (!hasEnvironmentPermission(this.currentEnvironmentStore.instance, ProjectPermissions.ProjectCustomization)) {
      this.routing.navigateApp(['not-allowed'], { skipLocationChange: true });
      return;
    }

    this.menuService.section = MenuSection.None;

    this.fetch();
  }

  ngOnDestroy(): void {
    this.menuService.section = MenuSection.Default;
  }

  fetch() {
    this.loading = true;
    this.automations = undefined;
    this.error = undefined;
    this.cd.markForCheck();

    this.automationService
      .get(this.currentProjectStore.instance, this.currentEnvironmentStore.instance)
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          this.automations = result.sort((lhs, rhs) => {
            if (lhs.active !== rhs.active) {
              return ascComparator(lhs.active, rhs.active) * -1;
            } else {
              return ascComparator(lhs.name.toLowerCase(), rhs.name.toLowerCase());
            }
          });
          this.loading = false;
          this.cd.markForCheck();
        },
        error => {
          if (error instanceof ServerRequestError && error.errors.length) {
            this.error = error.errors[0];
          } else {
            this.error = String(error);
          }

          this.loading = false;
          this.cd.markForCheck();
        }
      );
  }

  generateAutomationStepName(
    defaultName: string,
    names: { [name: string]: any },
    caseSensitive = true,
    numberSeparator = ' '
  ): string {
    let i = 1;
    let newName: string;

    do {
      newName = i > 1 ? [defaultName, i].join(numberSeparator) : defaultName;
      ++i;
    } while (names.hasOwnProperty(caseSensitive ? newName : newName.toLowerCase()));

    return newName;
  }

  create(duplicate?: Automation) {
    const names = this.automations ? fromPairs(this.automations.map(item => [item.name, true])) : {};
    const workflow = duplicate && duplicate.workflow ? cloneDeep(duplicate.workflow) : new Workflow();
    const trigger = duplicate && duplicate.trigger ? cloneDeep(duplicate.trigger) : undefined;
    const defaultName = duplicate ? `${duplicate.name} copy` : 'New Automation';

    const automation = new Automation();

    automation.name = this.generateAutomationStepName(defaultName, names);
    automation.workflow = workflow;
    automation.trigger = trigger;

    this.createLoading = true;
    this.cd.markForCheck();

    return this.automationService
      .create(this.currentProjectStore.instance, this.currentEnvironmentStore.instance, automation)
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          this.createLoading = false;
          this.cd.markForCheck();

          this.editItem(result, true);
          this.fetch();

          this.analyticsService.sendSimpleEvent(AnalyticsEvent.Project.BuilderChange, {
            Type: 'automation',
            AutomationUid: result.uid
          });
        },
        error => {
          this.createLoading = false;
          this.cd.markForCheck();

          this.notificationService.error('Error', `Creating failed: ${error}`);
        }
      );
  }

  editItem(original: Automation, silent = false) {
    const workflow = cloneDeep(original.workflow);
    const trigger = cloneDeep(original.trigger);
    const historyOpenedInitial = original.lastRun && original.lastRun.status == WorkflowRunStatus.Failed;

    this.workflowEditController
      .open({
        runtime: RuntimeEnvironment.NodeJS,
        nameEditable: true,
        name: original.name,
        workflow: workflow,
        workflowRun: workflow.testRun,
        workflowEditable: true,
        parameters: [],
        automation: original,
        triggerEditable: true,
        trigger: trigger,
        customizeTrigger: !trigger,
        actionTypesEnabled: [ActionType.Query, ActionType.RunJavaScript],
        historyEnabled: true,
        historyOpenedInitial: historyOpenedInitial,
        resultEnabled: true,
        analyticsSource: this.analyticsSource
      })
      .pipe(
        filter(result => !result.cancelled),
        switchMap(result => {
          const automation: Automation = cloneDeep(original);

          result.workflow.testRun = result.workflowRun;

          automation.name = result.name;
          automation.workflow = result.workflow;
          automation.trigger = result.trigger;

          if (isSet(result.automationActive)) {
            automation.active = result.automationActive;
          }

          return this.automationService.update(
            this.currentProjectStore.instance,
            this.currentEnvironmentStore.instance,
            automation
          );
        }),
        untilDestroyed(this)
      )
      .subscribe(
        result => {
          this.updateItem(result);

          if (!silent) {
            if (result.active && !original.active) {
              this.notificationService.success(
                'Published',
                `Automation <strong>${original.name}</strong> was successfully published`
              );
            }
          }

          this.analyticsService.sendSimpleEvent(AnalyticsEvent.Project.BuilderChange, {
            Type: 'automation',
            AutomationUid: result.uid
          });
        },
        error => {
          this.notificationService.error('Error', errorToString(error));
        }
      );
  }

  updateItem(newItem: Automation) {
    this.automations = this.automations
      ? this.automations.map(item => {
          if (item.uid === newItem.uid) {
            return newItem;
          } else {
            return item;
          }
        })
      : [newItem];
    this.cd.markForCheck();
  }
}
