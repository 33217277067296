var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import range from 'lodash/range';
import { MarginControl, StepsItem } from '@modules/customize';
import { ElementConfigurationService } from '@modules/customize-configuration';
import { Input } from '@modules/fields';
import { FieldInputControl } from '@modules/parameters';
import { isSet } from '@shared';
var CustomizeBarStepsItemControl = /** @class */ (function (_super) {
    __extends(CustomizeBarStepsItemControl, _super);
    function CustomizeBarStepsItemControl(state) {
        if (state === void 0) { state = {}; }
        return _super.call(this, {
            value: new FormControl(isSet(state.value) ? state.value : ''),
            name: new FormControl(isSet(state.name) ? state.name : '', Validators.required),
            description: new FormControl(isSet(state.description) ? state.description : ''),
            color: new FormControl(isSet(state.color) ? state.color : ''),
            visible_input: new FieldInputControl(state && state.visibleInput ? state.visibleInput.serializeWithoutPath() : { path: ['value'] })
        }) || this;
    }
    CustomizeBarStepsItemControl.prototype.deserialize = function (instance) {
        this.instance = instance;
        if (instance) {
            this.controls.value.patchValue(instance.value);
            this.controls.name.patchValue(instance.name);
            this.controls.description.patchValue(instance.description);
            this.controls.color.patchValue(instance.color);
            this.controls.visible_input.patchValue(instance.visibleInput ? instance.visibleInput.serializeWithoutPath() : {});
        }
        else {
            this.controls.value.patchValue('');
            this.controls.name.patchValue('');
            this.controls.description.patchValue('');
            this.controls.color.patchValue('');
        }
        this.markAsPristine();
        return this;
    };
    CustomizeBarStepsItemControl.prototype.serialize = function () {
        var result = cloneDeep(this.instance) || new StepsItem();
        if (!result.uid) {
            result.generateUid();
        }
        result.value = this.controls.value.value;
        result.name = this.controls.name.value;
        result.description = this.controls.description.value;
        result.color = this.controls.color.value;
        if (this.controls.visible_input.value) {
            result.visibleInput = new Input().deserialize(this.controls.visible_input.value);
        }
        else {
            result.visibleInput = undefined;
        }
        return result;
    };
    return CustomizeBarStepsItemControl;
}(FormGroup));
export { CustomizeBarStepsItemControl };
var CustomizeBarStepsItemArray = /** @class */ (function (_super) {
    __extends(CustomizeBarStepsItemArray, _super);
    function CustomizeBarStepsItemArray() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CustomizeBarStepsItemArray.prototype.deserialize = function (instances) {
        var controls = instances.map(function (item) { return new CustomizeBarStepsItemControl().deserialize(item); });
        this.set(controls);
    };
    CustomizeBarStepsItemArray.prototype.serialize = function () {
        return this.controls.map(function (item) { return item.serialize(); });
    };
    CustomizeBarStepsItemArray.prototype.set = function (controls) {
        var _this = this;
        range(this.controls.length).forEach(function () { return _this.removeAt(0); });
        controls.forEach(function (item) { return _this.push(item); });
        this.updateValueAndValidity();
    };
    CustomizeBarStepsItemArray.prototype.append = function (control) {
        this.push(control);
        this.updateValueAndValidity();
    };
    CustomizeBarStepsItemArray.prototype.remove = function (control) {
        var index = this.controls.findIndex(function (item) { return item === control; });
        if (index == -1) {
            return;
        }
        this.removeAt(index);
        this.updateValueAndValidity();
    };
    return CustomizeBarStepsItemArray;
}(FormArray));
export { CustomizeBarStepsItemArray };
var CustomizeBarStepsEditForm = /** @class */ (function (_super) {
    __extends(CustomizeBarStepsEditForm, _super);
    function CustomizeBarStepsEditForm(elementConfigurationService) {
        var _this = _super.call(this, {
            url: new FieldInputControl({ path: ['value'] }, { validators: Validators.required, updateOn: 'blur' }),
            name: new FormControl(''),
            items: new CustomizeBarStepsItemArray([]),
            current_item: new FieldInputControl({ path: ['value'] }),
            visible_input: new FieldInputControl({ path: ['value'] }),
            card_wrap: new FormControl(true),
            margin: new MarginControl()
        }) || this;
        _this.elementConfigurationService = elementConfigurationService;
        return _this;
    }
    CustomizeBarStepsEditForm.prototype.init = function (element, firstInit) {
        if (firstInit === void 0) { firstInit = false; }
        this.element = element;
        this.controls.name.patchValue(element.name ? element.name : 'Steps');
        this.controls.items.deserialize(element.items);
        this.controls.current_item.patchValue(element.currentItem ? element.currentItem.serializeWithoutPath() : {});
        this.controls.visible_input.patchValue(element.visibleInput ? element.visibleInput.serializeWithoutPath() : {});
        this.controls.card_wrap.patchValue(element.cardWrap);
        this.controls.margin.patchValue(element.margin);
        if (!firstInit) {
            this.markAsDirty();
        }
        else {
            this.markAsPristine();
        }
    };
    CustomizeBarStepsEditForm.prototype.isConfigured = function (instance) {
        return this.elementConfigurationService.isStepsConfigured(instance);
    };
    CustomizeBarStepsEditForm.prototype.submit = function () {
        var instance = cloneDeep(this.element);
        instance.name = this.controls.name.value;
        instance.items = this.controls.items.serialize();
        instance.cardWrap = this.controls.card_wrap.value;
        instance.margin = this.controls.margin.value;
        if (this.controls.current_item.value) {
            instance.currentItem = new Input().deserialize(this.controls.current_item.value);
        }
        else {
            instance.currentItem = undefined;
        }
        if (this.controls.visible_input.value) {
            instance.visibleInput = new Input().deserialize(this.controls.visible_input.value);
        }
        else {
            instance.visibleInput = undefined;
        }
        return instance;
    };
    return CustomizeBarStepsEditForm;
}(FormGroup));
export { CustomizeBarStepsEditForm };
