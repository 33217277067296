<div
  *ngIf="customView.filesJs.length"
  appCustomElement
  [tagName]="customView.tagNameEffective"
  [parameters]="parameters"
  [inputs]="params"
  [actions]="customView.actions"
  (actionEmitted)="actionEmitted.emit({ name: $event.name, data: $event.data })"
></div>

<ng-container *ngIf="!customView.filesJs.length">
  This component is not configured yet
</ng-container>

<ng-container *ngIf="customViewLoaderService.isLoaded(customView) as state">
  <ng-container
    *ngIf="
      (customizeService.enabled$ | async) &&
      state.loaded &&
      (customView.buildId | appIsSet) &&
      state.buildId != customView.buildId &&
      !customElementBuildIdCheckHidden
    "
  >
    <div class="element__message-background"></div>
    <div class="element__message">
      <div class="element-message">
        This component was updated.<br />
        Reload the page to see updated version.
      </div>
      <div class="element__message-close icon-close" (click)="hideCustomElementBuildIdCheck()"></div>
    </div>
  </ng-container>
</ng-container>
