import { OnChanges, OnInit } from '@angular/core';
import clamp from 'lodash/clamp';
var BarProgressComponent = /** @class */ (function () {
    function BarProgressComponent() {
        this.labelWrap = false;
        this.progress = 0;
        this.errors = 0;
        this.showPercentage = true;
        this.secondary = false;
        this.contrast = false;
        this.border = false;
    }
    BarProgressComponent.prototype.ngOnInit = function () { };
    BarProgressComponent.prototype.ngOnChanges = function (changes) {
        this.progress = clamp(this.progress, 0, 1);
    };
    Object.defineProperty(BarProgressComponent.prototype, "progressRound", {
        get: function () {
            return Math.round(this.progress * 100);
        },
        enumerable: true,
        configurable: true
    });
    return BarProgressComponent;
}());
export { BarProgressComponent };
