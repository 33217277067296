import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { CustomViewSource } from '@modules/custom-views';
import { CustomElementItem, ViewContext, ViewContextElement } from '@modules/customize';

import { CustomizeBarEditComponent } from '../../data/customize-bar-edit-component';
import { CustomizeBarEditEvent } from '../../data/customize-bar-edit-event';

@Component({
  selector: 'app-customize-bar-custom-edit',
  templateUrl: './customize-bar-custom-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeBarCustomEditComponent implements CustomizeBarEditComponent {
  @Input() title: string;
  @Input() titleEnabled = true;
  @Input() element: CustomElementItem;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() deleteEnabled = false;
  @Input() titleEditable = false;
  @Input() parentElement: any;
  @Input() parentPopup: any;
  @Input() firstInit = false;
  @Output() event = new EventEmitter<CustomizeBarEditEvent>();

  sources = CustomViewSource;
}
