import { Directive, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import isArray from 'lodash/isArray';
import isPlainObject from 'lodash/isPlainObject';
import toPairs from 'lodash/toPairs';

import { ActionOutput, ParameterField } from '@modules/fields';
import { isSet, removeChildren, TypedChanges } from '@shared';

@Directive({
  selector: '[appCustomElement]'
})
export class CustomElementDirective implements OnInit, OnDestroy, OnChanges {
  @Input() tagName: string;
  @Input() parameters: ParameterField[] = [];
  @Input() inputs: Object = {};
  @Input() actions: ActionOutput[] = [];
  @Output() actionEmitted = new EventEmitter<{ name: string; data: any }>();

  element: HTMLElement;
  actionListener?: any;

  constructor(private el: ElementRef) {}

  ngOnInit(): void {
    this.createElement();
    this.updateElementInputs();
    this.updateElementAction();
  }

  ngOnDestroy(): void {
    this.destroyElement();
  }

  ngOnChanges(changes: TypedChanges<CustomElementDirective>): void {
    let recreatedElement = false;

    if (changes.tagName && !changes.tagName.firstChange) {
      this.destroyElement();
      this.createElement();
      recreatedElement = true;
    }

    if (recreatedElement || (changes.inputs && !changes.inputs.firstChange)) {
      this.updateElementInputs();
    }

    if (recreatedElement || (changes.actions && !changes.actions.firstChange)) {
      this.updateElementAction();
    }
  }

  createElement() {
    if (!isSet(this.tagName)) {
      return;
    }

    this.element = document.createElement(this.tagName);
    this.el.nativeElement.appendChild(this.element);
  }

  destroyElement() {
    if (!this.element) {
      return;
    }

    if (this.actionListener) {
      this.element.removeEventListener('jet_action', this.actionListener);
      this.actionListener = undefined;
    }

    removeChildren(this.el.nativeElement);

    this.element = undefined;
  }

  updateElementInputs() {
    if (!this.element) {
      return;
    }

    this.parameters.forEach(parameter => {
      let value = this.inputs[parameter.name];

      if (parameter.fieldDescription && parameter.fieldDescription.valueToStr) {
        value = parameter.fieldDescription.valueToStr(this.inputs[parameter.name], { noTruncate: true });
      }

      this.setElementInputAttribute(parameter.name, value);
    });

    // Backward compatibility
    toPairs(this.inputs)
      .filter(([key]) => !this.parameters.some(item => item.name == key))
      .forEach(([key, value]) => this.setElementInputAttribute(key, value));
  }

  setElementInputAttribute(name: string, value: any) {
    if (value === undefined) {
      this.element.removeAttribute(name);
    } else if (isPlainObject(value) || isArray(value)) {
      this.element.setAttribute(name, JSON.stringify(value));
    } else {
      this.element.setAttribute(name, String(value));
    }
  }

  updateElementAction() {
    if (!this.element) {
      return;
    }

    if (this.actionListener) {
      return;
    }

    const listener = (event: CustomEvent) => {
      if (event.detail) {
        this.actionEmitted.emit({ name: event.detail.name, data: event.detail.data });
      }
    };

    this.element.addEventListener('jet_action', listener);
    this.actionListener = listener;
  }
}
