import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { debounceTime } from 'rxjs/operators';

import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ParameterArray, ParameterControl } from '@modules/fields';
import { SidebarCollapseContext } from '@modules/sidebar';
import { isSet } from '@shared';

import { ParametersConfigurable } from '../parameters-edit-item/parameters-edit-item.component';

@Component({
  selector: 'app-parameters-edit',
  templateUrl: './parameters-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ParametersEditComponent implements OnInit, OnDestroy {
  @Input() control: ParameterArray;
  @Input() configurable: ParametersConfigurable = {};
  @Input() parametersValueEditable = false;
  @Input() parametersValue = {};
  @Input() placeholder: string;
  @Input() bannerTitle = 'Page parameters';
  @Input() bannerDescription = 'Use page parameters when you need to pass IDs or other data between pages.';
  @Input() addLabel = 'Add Parameter';
  @Input() addBaseName = 'param';
  @Input() emptyBanner = false;
  @Input() analyticsSource: string;
  @Output() parameterValueUpdated = new EventEmitter<{ name: string; value: any }>();

  firstForm: ParameterControl;
  lastAddedForm: ParameterControl;
  collapseContext = new SidebarCollapseContext();
  sendParametersAnalytics = true;

  constructor(private analyticsService: UniversalAnalyticsService, private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.control.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      this.cd.markForCheck();
    });

    this.firstForm = this.control.controls[0];

    if (this.sendParametersAnalytics) {
      this.control.valueChanges.pipe(debounceTime(1000), untilDestroyed(this)).subscribe(value => {
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.Parameter.SuccessfullySetUp, {
          Object: this.analyticsSource
        });
      });
    }
  }

  ngOnDestroy(): void {}

  getDistinctName(baseName: string, template = (n, i) => `${n}_${i}`, startIndex = 1) {
    const names = this.control.controls.map(item => {
      const value = item.controls.name.value;
      return isSet(value) ? value : '';
    });
    let name: string;
    let index = startIndex;

    do {
      name = template(baseName, index);
      ++index;
    } while (names.find(item => item.toLowerCase() == name.toLowerCase()));

    return name;
  }

  addItem() {
    const name = this.getDistinctName(this.addBaseName);
    this.lastAddedForm = this.control.appendControl(undefined, { name: name });

    if (this.sendParametersAnalytics) {
      this.analyticsService.sendSimpleEvent(AnalyticsEvent.Parameter.Added, {
        Object: this.analyticsSource
      });
    }
  }

  removeItem(control: ParameterControl) {
    this.control.removeControl(control);

    if (this.sendParametersAnalytics) {
      this.analyticsService.sendSimpleEvent(AnalyticsEvent.Parameter.Deleted, {
        Object: this.analyticsSource
      });
    }
  }

  dragDrop(event: CdkDragDrop<FormControl[]>) {
    if (event.previousIndex !== event.currentIndex) {
      moveItemInArray(this.control.controls, event.previousIndex, event.currentIndex);
      this.control.updateValueAndValidity();
    }
  }
}
