<div
  *ngIf="source == sources.View && customView?.view"
  [style.height.px]="customView.view.frame.height"
  [style.min-width.px]="
    customView.view.widthResize.enabled && (customView.view.widthResize.min | appIsSet)
      ? customView.view.widthResize.min
      : null
  "
  [style.max-width.px]="
    customView.view.widthResize.enabled && (customView.view.widthResize.max | appIsSet)
      ? customView.view.widthResize.max
      : null
  "
  [style.min-height.px]="
    customView.view.heightResize.enabled && (customView.view.heightResize.min | appIsSet)
      ? customView.view.heightResize.min
      : null
  "
  [style.max-height.px]="
    customView.view.heightResize.enabled && (customView.view.heightResize.max | appIsSet)
      ? customView.view.heightResize.max
      : null
  "
>
  <app-custom-element-view
    [view]="customView.view"
    [parameters]="customView.parameters"
    [actionOutputs]="customView.actions"
    [actions]="actions"
    [params]="params"
    [parentContext]="context"
    [parentContextElement]="contextElement"
    [localContext]="localContext"
    [stateSelectedEnabled]="stateSelectedEnabled"
    [stateSelected]="stateSelected"
  ></app-custom-element-view>
</div>

<app-custom-element-html
  *ngIf="source == sources.HTML && customView"
  [html]="customView.html"
  [params]="params"
></app-custom-element-html>

<app-custom-element-js
  *ngIf="source == sources.CustomElement && customView"
  [customView]="customView"
  [parameters]="customView.parameters"
  [params]="params"
  (actionEmitted)="onActionEmitted($event.name, $event.data)"
></app-custom-element-js>

<ng-container *ngIf="!source">
  This component is not configured yet
</ng-container>
