import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, delayWhen, filter, map, switchMap, tap } from 'rxjs/operators';

import { DialogButtonHotkey, DialogButtonType, DialogService } from '@common/dialogs';
import { localize } from '@common/localize';
import { NotificationService } from '@common/notifications';
import { ServerRequestError } from '@modules/api';
import { Project, ProjectService, ProjectsStore } from '@modules/projects';
import { CurrentUserStore } from '@modules/users';
import { errorToString } from '@shared';

import { SurveyService } from '../survey/survey.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectController {
  constructor(
    private projectService: ProjectService,
    private projectsStore: ProjectsStore,
    private currentUserStore: CurrentUserStore,
    private notificationService: NotificationService,
    private surveyService: SurveyService,
    private dialogService: DialogService
  ) {}

  processDeleteProject(project: Project) {
    return this.projectService.delete(project).pipe(delayWhen(() => this.projectsStore.updateIfLoaded()));
  }

  deleteProject(uniqueName: string, options: { dark?: boolean } = {}): Observable<boolean> {
    return this.projectService.getDetail(uniqueName).pipe(
      switchMap(project => {
        const survey$: Observable<any> =
          project.subscription && project.subscription.isCancellable()
            ? this.surveyService
                .openUnsubscribe({ project: project, user: this.currentUserStore.instance })
                .pipe(filter(result => result.finished))
            : of(true);

        return survey$.pipe(
          switchMap(() => {
            return this.dialogService.dialog({
              title: localize('Delete App'),
              description: localize(
                `
                Are you sure want to delete app <strong>{0}</strong>?<br>
                Your app will be permanently deleted. This cannot be undone.
              `,
                [project.name]
              ),
              buttons: [
                {
                  name: 'cancel',
                  label: localize('Cancel'),
                  type: DialogButtonType.Default,
                  hotkey: DialogButtonHotkey.Cancel
                },
                {
                  name: 'ok',
                  label: localize('Delete App'),
                  type: DialogButtonType.Danger,
                  hotkey: DialogButtonHotkey.Submit,
                  executor: () => this.processDeleteProject(project)
                }
              ],
              dark: options.dark
            });
          }),
          filter(result => {
            if (result.executorError) {
              throw result.executorError;
            }

            return result.executorResult;
          }),
          map(() => true),
          tap(() => {
            this.notificationService.success('Deleted', `<strong>${project.name}</strong> was successfully deleted`);
          }),
          catchError(error => {
            if (error instanceof ServerRequestError && error.errors.length) {
              this.notificationService.error(
                'Error',
                `Deleting <strong>${project.name}</strong> failed: ${error.errors[0]}`
              );
            } else {
              this.notificationService.error('Error', errorToString(error));
            }

            return throwError(error);
          })
        );
      })
    );
  }
}
