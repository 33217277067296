import { CustomViewSource } from '@modules/custom-views';
import { AlignHorizontal, ElementType } from '@modules/customize';

export const changeCustomizeBarComponentsAdvanced = [
  {
    title: 'File Viewer',
    image: 'file_viewer',
    action: 'Add File Viewer',
    type: ElementType.FileViewer,
    defaultParams: { height: 480 }
  },
  {
    title: 'QR code',
    image: 'qr_code',
    action: 'Add QR code',
    type: ElementType.QrCode,
    defaultParams: { background_color: 'white', width: 200, height: 200, align_horizontal: AlignHorizontal.Left }
  },
  {
    title: 'Bar code',
    image: 'bar_code',
    action: 'Add Bar code',
    type: ElementType.BarCode,
    defaultParams: { background_color: 'white', height: 150, align_horizontal: AlignHorizontal.Left }
  },
  {
    title: 'Scanner',
    image: 'scanner',
    action: 'Add Scanner',
    type: ElementType.Scanner,
    defaultParams: { width: 400, height: 400, align_horizontal: AlignHorizontal.Left }
  },
  {
    title: 'iFrame',
    image: 'iframe',
    action: 'Add iFrame',
    type: ElementType.IFrame,
    defaultParams: { height: 480 }
  },
  {
    title: 'HTML',
    image: 'html',
    action: 'Add HTML',
    type: ElementType.Custom,
    defaultParams: {
      height: 65,
      source: CustomViewSource.HTML,
      height_fluid: true
    }
  },
  {
    title: 'JavaScript',
    image: 'javascript',
    action: 'Add HTML',
    type: ElementType.Custom,
    defaultParams: {
      height: 200,
      source: CustomViewSource.CustomElement,
      height_fluid: true
    }
  }
];
