import { Injectable, Injector } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import { combineLatest, Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

import { applyModelElementStyles, getModelElementStyles, ModelElementItem } from '@modules/customize';
import { ElementConfigurationService } from '@modules/customize-configuration';
import { Input } from '@modules/fields';
import { ModelDescriptionStore } from '@modules/model-queries';
import { FieldInputControl } from '@modules/parameters';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { ResourceControllerService } from '@modules/resources';
import { controlValid, isSet } from '@shared';

import { FieldActionsArray } from '../display-fields-edit/field-actions.array';
import { ModelDescriptionDataSourceControl } from '../model-description-data-source-edit/model-description-data-source';
import { ModelElementStylesControl } from '../styles-model-element-edit/model-element-styles.control';

@Injectable()
export class CustomizeBarModelEditForm extends FormGroup {
  element: ModelElementItem;

  controls: {
    name: FormControl;
    title: FieldInputControl;
    data_source: ModelDescriptionDataSourceControl;
    columns_actions: FieldActionsArray;
    visible_input: FieldInputControl;
    tooltip: FormControl;
    card_wrap: FormControl;

    element_styles: ModelElementStylesControl;
  };

  constructor(
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    protected resourceControllerService: ResourceControllerService,
    private modelDescriptionStore: ModelDescriptionStore,
    public elementConfigurationService: ElementConfigurationService,
    private injector: Injector,
    dataSourceControl: ModelDescriptionDataSourceControl
  ) {
    super({
      name: new FormControl(''),
      title: new FieldInputControl({ path: ['value'] }),
      data_source: dataSourceControl,
      columns_actions: new FieldActionsArray([]),
      visible_input: new FieldInputControl({ path: ['value'] }),
      tooltip: new FormControl(''),
      card_wrap: new FormControl(true),

      element_styles: new ModelElementStylesControl(injector)
    });
    dataSourceControl.setRequired(true);
  }

  init(element: ModelElementItem, firstInit = false) {
    this.element = element;

    const value = {
      name: element.name ? element.name : 'Detail',
      title: element.titleInput ? element.titleInput.serializeWithoutPath() : {},
      columns_actions: element.columnActions,
      visible_input: element.visibleInput ? element.visibleInput.serializeWithoutPath() : {},
      tooltip: element.tooltip,
      card_wrap: element.cardWrap
    };

    this.patchValue(value, { emitEvent: false });

    this.controls.data_source.deserialize(element.dataSource);

    const elementStyles = getModelElementStyles(element);
    this.controls.element_styles.deserialize(elementStyles);

    if (!firstInit) {
      this.markAsDirty();
    }
  }

  controlsValid$(controls: string[]): Observable<boolean> {
    return combineLatest(controls.map(item => controlValid(this.controls[item]))).pipe(
      map(result => result.every(item => item)),
      debounceTime(60)
    );
  }

  isConfigured(instance: ModelElementItem): boolean {
    return this.elementConfigurationService.isModelConfigured(instance, { restrictDemo: true });
  }

  submit(): ModelElementItem {
    const instance = cloneDeep(this.element) as ModelElementItem;

    instance.name = this.controls.name.value;
    instance.titleInput = this.controls.title.value ? new Input().deserialize(this.controls.title.value) : undefined;
    instance.dataSource = this.controls.data_source.serialize();
    instance.columnActions = this.controls.columns_actions.value;

    if (this.controls.visible_input.value) {
      instance.visibleInput = new Input().deserialize(this.controls.visible_input.value);
    } else {
      instance.visibleInput = undefined;
    }

    instance.tooltip = isSet(this.controls.tooltip.value) ? this.controls.tooltip.value.trim() : undefined;
    instance.cardWrap = this.controls.card_wrap.value;

    const elementStyles = this.controls.element_styles.serialize();
    applyModelElementStyles(instance, elementStyles);

    return instance;
  }
}
