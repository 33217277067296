var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, OnChanges, OnDestroy, OnInit } from '@angular/core';
import isEqual from 'lodash/isEqual';
import pickBy from 'lodash/pickBy';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { CustomViewService, CustomViewSource, CustomViewsStore } from '@modules/custom-views';
import { CustomElementItem, CustomizeService, ElementType, registerElementComponent, ViewContextElement } from '@modules/customize';
import { BaseElementComponent } from '@modules/customize-elements';
import { applyParamInputs$, LOADING_VALUE, NOT_SET_VALUE } from '@modules/fields';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { isSet } from '@shared';
import { CustomPagePopupComponent } from '../custom-page-popup/custom-page-popup.component';
var CustomElementComponent = /** @class */ (function (_super) {
    __extends(CustomElementComponent, _super);
    function CustomElementComponent(currentProjectStore, currentEnvironmentStore, customizeService, viewContextElement, customViewService, customViewsStore, cd, popup) {
        var _this = _super.call(this) || this;
        _this.currentProjectStore = currentProjectStore;
        _this.currentEnvironmentStore = currentEnvironmentStore;
        _this.customizeService = customizeService;
        _this.viewContextElement = viewContextElement;
        _this.customViewService = customViewService;
        _this.customViewsStore = customViewsStore;
        _this.cd = cd;
        _this.popup = popup;
        _this.loading = true;
        _this.element$ = new BehaviorSubject(undefined);
        _this.customView$ = new BehaviorSubject(undefined);
        _this.featureSources = [CustomViewSource.HTML, CustomViewSource.CustomElement];
        _this.params = {};
        _this.firstVisible = false;
        return _this;
    }
    CustomElementComponent.prototype.ngOnInit = function () {
        this.element$.next(this.element);
        this.initCustomView();
        this.initContext(this.element);
    };
    CustomElementComponent.prototype.ngOnDestroy = function () { };
    CustomElementComponent.prototype.ngOnChanges = function (changes) {
        if (changes.element && !changes.element.firstChange) {
            this.element$.next(this.element);
            this.updateContextInfo(this.element);
        }
    };
    CustomElementComponent.prototype.onFirstVisible = function () {
        this.firstVisible = true;
        this.initParams();
    };
    CustomElementComponent.prototype.initCustomView = function () {
        var _this = this;
        this.element$
            .pipe(switchMap(function (element) {
            if (element && isSet(element.customView)) {
                return _this.customViewsStore
                    .getDetail(element.customView)
                    .pipe(map(function (customView) { return ({ element: element, customView: customView }); }));
            }
            else {
                return of({
                    element: element,
                    customView: undefined
                });
            }
        }), untilDestroyed(this))
            .subscribe(function (_a) {
            var element = _a.element, customView = _a.customView;
            _this.source = _this.getSourceEffective(element, customView);
            _this.customView$.next(customView);
            _this.loading = false;
            _this.cd.markForCheck();
        });
    };
    CustomElementComponent.prototype.initParams = function () {
        var _this = this;
        combineLatest(this.element$, this.customView$)
            .pipe(switchMap(function (_a) {
            var element = _a[0], customView = _a[1];
            if (!element || !customView) {
                return of({});
            }
            return applyParamInputs$({}, element.inputs, {
                context: _this.context,
                parameters: customView.parameters,
                handleLoading: true
            });
        }), map(function (params) { return pickBy(params, function (v, k) { return v !== LOADING_VALUE && v !== NOT_SET_VALUE; }); }), debounceTime(10), distinctUntilChanged(function (lhs, rhs) { return isEqual(lhs, rhs); }), untilDestroyed(this))
            .subscribe(function (params) {
            _this.params = params;
            _this.cd.markForCheck();
        });
    };
    CustomElementComponent.prototype.getSourceEffective = function (element, customView) {
        return customView && isSet(customView.source) ? customView.source : element.source;
    };
    CustomElementComponent.prototype.initContext = function (element) {
        this.viewContextElement.initElement({
            uniqueName: element.uid,
            name: element.name,
            element: element,
            popup: this.popup ? this.popup.popup : undefined
        });
    };
    CustomElementComponent.prototype.updateContextInfo = function (element) {
        this.viewContextElement.initInfo({ name: element.name, element: this.element }, true);
    };
    return CustomElementComponent;
}(BaseElementComponent));
export { CustomElementComponent };
registerElementComponent({
    type: ElementType.Custom,
    component: CustomElementComponent,
    label: 'Custom Component',
    actions: []
});
