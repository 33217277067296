<app-field-wrapper
  *ngIf="field.field != fieldTypes.Boolean"
  [field]="field"
  [readonly]="readonly"
  [requiredAsterisk]="requiredAsterisk"
  [label]="label"
  [labelStyle]="elementStyles?.labelStyle"
  [labelStyleGlobal]="labelStyleGlobal"
  [labelAdditional]="labelAdditional"
  [labelAdditionalStyle]="elementStyles?.labelAdditionalStyle"
  [labelAdditionalStyleGlobal]="labelAdditionalStyleGlobal"
  [errors]="errors"
  [truncate]="truncate"
  [manualMargin]="manualMargin"
  [labelButtons]="labelButtons"
  [theme]="field.params['theme']"
>
  <input
    class="input input_stub"
    [class.input_theme]="field.params['theme']"
    [class.input_styles]="field.params['theme']"
    [class.input_fill]="true"
    [class.loading-animation]="true"
    [appFieldElementStyles]="elementStyles"
    [appFieldElementStylesEnabled]="field.params['theme']"
    type="text"
    value="Value"
    [disabled]="true"
  />
</app-field-wrapper>

<div
  *ngIf="field.field == fieldTypes.Boolean"
  class="checkbox-control"
  [class.checkbox-control_theme]="field.params['theme']"
>
  <app-checkbox
    class="checkbox-control__checkbox"
    [disabled]="true"
    [accentColor]="accentColor"
    [theme]="field.params['theme']"
  ></app-checkbox>

  <label class="checkbox-control__label checkbox-label">
    <span
      class="checkbox-control__label-text"
      [appTextStyle]="elementStyles?.labelStyle"
      [appTextStyleGlobal]="labelStyleGlobal"
    >
      {{ field.label }}
    </span>

    <span
      *ngIf="labelAdditional | appIsSet"
      class="checkbox-control__label-optional"
      [appTextStyle]="elementStyles?.labelAdditionalStyle"
      [appTextStyleGlobal]="labelAdditionalStyleGlobal"
    >
      {{ labelAdditional }}
    </span>
  </label>
</div>
