var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { HttpClient, HttpHeaders } from '@angular/common/http';
import cloneDeep from 'lodash/cloneDeep';
import isPlainObject from 'lodash/isPlainObject';
import toPairs from 'lodash/toPairs';
import { filter, map, publishLast, refCount, switchMap } from 'rxjs/operators';
import { ApiService } from '@modules/api';
import { CentrifugoService } from '@modules/centrifugo';
import { CustomView } from '../../data/custom-view';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../api/services/api/api.service";
import * as i3 from "../../../centrifugo/services/centrifugo/centrifugo.service";
var CustomViewService = /** @class */ (function () {
    function CustomViewService(http, apiService, centrifugoService) {
        this.http = http;
        this.apiService = apiService;
        this.centrifugoService = centrifugoService;
    }
    CustomViewService.prototype.get = function (projectName, environmentName, params, draft) {
        var _this = this;
        if (params === void 0) { params = {}; }
        if (draft === void 0) { draft = false; }
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.environmentMethodURL(projectName, environmentName, 'custom_views/');
            var headers = new HttpHeaders();
            var httpParams = __assign({}, params, (draft && { draft: '1' }));
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.get(url, { headers: headers, params: httpParams });
        }), map(function (result) { return result.map(function (item) { return new CustomView().deserialize(item); }); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    CustomViewService.prototype.getDetail = function (projectName, environmentName, uniqueName, params, draft) {
        var _this = this;
        if (params === void 0) { params = {}; }
        if (draft === void 0) { draft = false; }
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.environmentMethodURL(projectName, environmentName, "custom_views/" + uniqueName + "/");
            var headers = new HttpHeaders();
            var httpParams = __assign({}, params, (draft && { draft: '1' }));
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.get(url, { headers: headers, params: httpParams });
        }), map(function (result) { return new CustomView().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    CustomViewService.prototype.createBulk = function (projectName, environmentName, actionDescriptions, fields) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.environmentMethodURL(projectName, environmentName, 'custom_views/');
            var headers = new HttpHeaders();
            var params = {
                draft: '1'
            };
            var data = actionDescriptions.map(function (item) { return item.serialize(fields); });
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.post(url, data, { headers: headers, params: params });
        }), map(function (result) { return result.map(function (item) { return new CustomView().deserialize(item); }); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    CustomViewService.prototype.create = function (projectName, environmentName, instance, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        if (options.draft && options.fields && !options.fields.includes('dist')) {
            return this.createBulk(projectName, environmentName, [instance], options.fields).pipe(map(function (result) { return result[0]; }));
        }
        else {
            return this.apiService.refreshToken().pipe(switchMap(function () {
                var url = _this.apiService.environmentMethodURL(projectName, environmentName, 'custom_views/');
                var formData = new FormData();
                var data = instance.serialize(options.fields);
                var params = {
                // ...(options.draft && { draft: '1' })
                };
                var headers = new HttpHeaders();
                headers = _this.apiService.setHeadersToken(headers);
                toPairs(data)
                    .filter(function (_a) {
                    var _ = _a[0], value = _a[1];
                    return value !== undefined;
                })
                    .forEach(function (_a) {
                    var key = _a[0], value = _a[1];
                    if (key == 'view' && isPlainObject(value)) {
                        value = JSON.stringify(value);
                    }
                    formData.append(key, value);
                });
                return _this.http.post(url, formData, { headers: headers, params: params });
            }), map(function (result) { return new CustomView().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
        }
    };
    CustomViewService.prototype.update = function (projectName, environmentName, instance, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        if (options.draft && options.fields && !options.fields.includes('dist')) {
            return this.createBulk(projectName, environmentName, [instance], options.fields).pipe(map(function (result) { return result[0]; }));
        }
        else {
            return this.apiService.refreshToken().pipe(switchMap(function () {
                var url = _this.apiService.environmentMethodURL(projectName, environmentName, "custom_views/" + instance.uniqueName + "/");
                var formData = new FormData();
                var data = instance.serialize(options.fields);
                var params = {
                // ...(options.draft && { draft: '1' })
                };
                var headers = new HttpHeaders();
                headers = _this.apiService.setHeadersToken(headers);
                toPairs(data)
                    .filter(function (_a) {
                    var _ = _a[0], value = _a[1];
                    return value !== undefined;
                })
                    .forEach(function (_a) {
                    var key = _a[0], value = _a[1];
                    if (key == 'view' && isPlainObject(value)) {
                        value = JSON.stringify(value);
                    }
                    formData.append(key, value);
                });
                return _this.http.patch(url, formData, { headers: headers, params: params });
            }), map(function (result) { return new CustomView().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
        }
    };
    CustomViewService.prototype.delete = function (projectName, environmentName, instance) {
        instance = cloneDeep(instance);
        instance.deleted = true;
        return this.createBulk(projectName, environmentName, [instance], ['unique_name', 'deleted']).pipe(map(function () { return true; }));
        // return this.apiService.refreshToken().pipe(
        //   switchMap(() => {
        //     const url = this.apiService.environmentMethodURL(
        //       projectName,
        //       environmentName,
        //       `custom_views/${instance.uniqueName}/`
        //     );
        //     let headers = new HttpHeaders();
        //
        //     headers = this.apiService.setHeadersToken(headers);
        //
        //     return this.http.delete(url, { headers: headers });
        //   }),
        //   map(result => true),
        //   this.apiService.catchApiError(),
        //   publishLast(),
        //   refCount()
        // );
    };
    CustomViewService.prototype.downloadDist = function (url) {
        return this.http.get(url, { responseType: 'arraybuffer' }).pipe(publishLast(), refCount());
    };
    CustomViewService.prototype.subscribeCustomViews = function (projectName, environmentName, unique_name) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var channel = ['$custom_view', projectName, environmentName, unique_name].join('/');
            return _this.centrifugoService.subscribe(channel).pipe(filter(function (msg) { return msg.data['type'] == 'custom_view'; }), map(function (msg) { return new CustomView().deserialize(msg.data['custom_view']); }));
        }));
    };
    CustomViewService.ngInjectableDef = i0.defineInjectable({ factory: function CustomViewService_Factory() { return new CustomViewService(i0.inject(i1.HttpClient), i0.inject(i2.ApiService), i0.inject(i3.CentrifugoService)); }, token: CustomViewService, providedIn: "root" });
    return CustomViewService;
}());
export { CustomViewService };
