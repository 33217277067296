import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { DropListService } from '@common/drag-drop2';
import { ElementType, isElementTypeGroupable, isElementTypeWrappable } from '@modules/customize';
import { DRAG_DROP_SIBLING_ACTIVE_CLASS } from '@modules/customize-elements';
import { addClass, removeClass } from '@shared';

@Component({
  selector: 'app-element-placeholder-stub',
  templateUrl: './element-placeholder-stub.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ElementPlaceholderStubComponent implements OnInit, OnDestroy, OnChanges {
  @Input() elementType: ElementType;

  wrappable = false;
  groupable = false;
  hover$ = new BehaviorSubject<boolean>(false);
  draggingOver$ = combineLatest(this.hover$, this.dropListService.dragging$).pipe(
    map(([hover, dragging]) => hover && dragging)
  );
  siblingLeftEntered$ = new BehaviorSubject<boolean>(false);
  siblingRightEntered$ = new BehaviorSubject<boolean>(false);

  constructor(private dropListService: DropListService, private el: ElementRef, private cd: ChangeDetectorRef) {}

  ngOnInit() {
    combineLatest(this.siblingLeftEntered$, this.siblingRightEntered$)
      .pipe(untilDestroyed(this))
      .subscribe(([siblingLeftEntered, siblingRightEntered]) => {
        const dropList = this.dropListService.draggingDropList$.value;

        if (dropList) {
          dropList.mergeDraggingStateData({
            siblingLeftEntered: siblingLeftEntered,
            siblingRightEntered: siblingRightEntered,
            siblingSelf: true
          });
        }

        if (siblingLeftEntered || siblingRightEntered) {
          addClass(document.body, DRAG_DROP_SIBLING_ACTIVE_CLASS);
        } else {
          removeClass(document.body, DRAG_DROP_SIBLING_ACTIVE_CLASS);
        }
      });
  }

  ngOnDestroy(): void {
    if (this.siblingLeftEntered$.value || this.siblingRightEntered$.value) {
      removeClass(document.body, DRAG_DROP_SIBLING_ACTIVE_CLASS);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['elementType']) {
      this.wrappable = isElementTypeWrappable(this.elementType);
      this.groupable = this.wrappable && isElementTypeGroupable(this.elementType);
    }
  }

  isCreateColumnsAllowed() {
    return true;
    // return this.el.nativeElement.getBoundingClientRect().width >= 200;
  }
}
