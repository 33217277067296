<div
  cdkDropList
  [cdkDropListDisabled]="!configurable.sortable"
  [cdkDropListLockAxis]="'y'"
  class="sidebar-list"
  (cdkDropListDropped)="dragDrop($event)"
>
  <app-parameters-edit-item
    *ngFor="let item of control.controls; let i = index"
    cdkDrag
    class="sidebar-list__item"
    [control]="item"
    [configurable]="configurable"
    [valueEditable]="parametersValueEditable"
    [value]="parametersValue[item.controls.name.value]"
    [placeholder]="placeholder"
    [openedInitial]="item === firstForm || item === lastAddedForm"
    [collapseContext]="collapseContext"
    (deleteRequested)="removeItem(item)"
    (valueUpdated)="parameterValueUpdated.emit({ name: item.controls.name.value, value: $event })"
  ></app-parameters-edit-item>

  <div *ngIf="configurable.add && !control.controls.length && !emptyBanner" class="sidebar-list__item">
    <div class="sidebar-list-item sidebar-list-item_clickable sidebar-list-item_disabled" (click)="addItem()">
      <div class="sidebar-list-item__drag"></div>
      <div class="sidebar-list-item__left">
        <span class="button button_icon button_orange button_extra-small" style="margin: 0;">
          <span class="icon-text button__icon"></span>
        </span>
      </div>
      <div class="sidebar-list-item__main">
        <div class="sidebar-list-item__title">Parameter</div>
        <div class="sidebar-list-item__description">Not specified</div>
      </div>
    </div>
  </div>

  <div *ngIf="configurable.add && (control.controls.length || !emptyBanner)" class="sidebar-list__buttons">
    <a
      href="javascript:void(0)"
      class="button button_orange-transparent button_small sidebar-list__buttons-item"
      (click)="addItem()"
    >
      <span class="icon icon-plus button__icon button__icon_left"></span>
      <span class="button__label">{{ addLabel }}</span>
    </a>
  </div>
</div>

<div *ngIf="!control.controls.length && emptyBanner" class="menu3-section__banner">
  <div class="menu3-section-banner menu3-section-banner_orange">
    <div class="menu3-section-banner__icon icon-input"></div>
    <div class="menu3-section-banner__title">{{ bannerTitle }}</div>
    <div class="menu3-section-banner__description">{{ bannerDescription }}</div>

    <div class="menu3-section-banner__buttons">
      <div class="menu3-section-banner__button" (click)="addItem()">
        <div class="menu3-section-banner__button-icon icon-plus"></div>
        <div class="menu3-section-banner__button-label">{{ addLabel }}</div>
      </div>
    </div>
  </div>
</div>
