import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { filter, switchMap } from 'rxjs/operators';

import { DialogService } from '@common/dialogs';
import { NotificationService } from '@common/notifications';
import { BasePopupComponent } from '@common/popups';
import { ServerRequestError } from '@modules/api';
import { ViewSettingsStore } from '@modules/customize';
import { DashboardStore } from '@modules/dashboard';
import { ModelDescriptionStore } from '@modules/model-queries';
import { CurrentProjectStore } from '@modules/projects';
import { errorToString } from '@shared';

import { ProjectResetInterfaceForm } from './project-reset-interface.form';

@Component({
  selector: 'app-project-reset-interface',
  templateUrl: './project-reset-interface.component.html',
  providers: [ProjectResetInterfaceForm],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectResetInterfaceComponent implements OnInit, OnDestroy {
  constructor(
    public form: ProjectResetInterfaceForm,
    public currentProjectStore: CurrentProjectStore,
    private notificationService: NotificationService,
    private dashboardStore: DashboardStore,
    private modelDescriptionStore: ModelDescriptionStore,
    private viewSettingsStore: ViewSettingsStore,
    private popupComponent: BasePopupComponent,
    private dialogService: DialogService
  ) {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  submit() {
    this.dialogService
      .warning({
        title: 'Deleting',
        description: 'Are you sure want to delete current App interface settings?'
      })
      .pipe(
        filter(result => result),
        switchMap(() => this.form.submit()),
        untilDestroyed(this)
      )
      .subscribe(
        () => {
          // this.dashboardStore.getFirst(true);
          // this.modelDescriptionStore.getFirst(true);
          // this.viewSettingsStore.getFirst(true);

          this.close();

          this.notificationService.success('Reset', `<strong>Current project</strong> settings was successfully reset`);

          setTimeout(() => {
            window.location.reload();
          }, 1000);
        },
        error => {
          if (error instanceof ServerRequestError && error.errors.length) {
            this.notificationService.error(
              'Error',
              `Saving <strong>Current project</strong> failed: ${error.errors[0]}`
            );
          } else {
            this.notificationService.error('Error', errorToString(error));
          }
        }
      );
  }

  cancel() {
    this.close();
  }

  close() {
    this.popupComponent.close();
  }
}
