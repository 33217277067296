import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';
import { ApiService } from '@modules/api';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../api/services/api/api.service";
var IDEService = /** @class */ (function () {
    function IDEService(http, apiService) {
        this.http = http;
        this.apiService = apiService;
    }
    IDEService.prototype.initSession = function (projectName, environmentName, customView, token) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.nodeEnvironmentMethodURL(projectName, environmentName, "custom_views/" + customView + "/init_session");
            var headers = new HttpHeaders();
            var data = {
                token: token
            };
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.post(url, data, { headers: headers });
        }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    IDEService.prototype.clone = function (projectName, environmentName, customViewName, toCustomViewName) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.nodeEnvironmentMethodURL(projectName, environmentName, "custom_views/" + customViewName + "/clone");
            var headers = new HttpHeaders();
            var data = {
                to_custom_view: toCustomViewName
            };
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.post(url, data, { headers: headers });
        }), map(function (result) { return result.result; }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    IDEService.ngInjectableDef = i0.defineInjectable({ factory: function IDEService_Factory() { return new IDEService(i0.inject(i1.HttpClient), i0.inject(i2.ApiService)); }, token: IDEService, providedIn: "root" });
    return IDEService;
}());
export { IDEService };
