import { HttpClient, HttpEventType, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import fromPairs from 'lodash/fromPairs';
import isArray from 'lodash/isArray';
import keys from 'lodash/keys';
import pickBy from 'lodash/pickBy';
import toPairs from 'lodash/toPairs';
import * as moment from 'moment';
import { combineLatest, EMPTY, Observable, of, throwError } from 'rxjs';
import { catchError, map, publishLast, refCount, switchMap, tap } from 'rxjs/operators';
import * as gql from 'typed-graphqlify';

import { CustomHttpParameterCodec } from '@core';
import { ActionResponse } from '@modules/actions';
import { AdminMode, ROUTE_ADMIN_MODE } from '@modules/admin-mode';
import { ApiInfo, DEMO_RESOURCES_PROJECT, ServerRequestError } from '@modules/api';
import { AggregateFunc, DataGroup, DatasetGroupLookup } from '@modules/charts';
import { modelFieldItemToDisplayField, RawListViewSettingsColumn } from '@modules/customize';
import {
  AggregateDisplayField,
  DisplayFieldType,
  FieldType,
  getFieldDescriptionByType,
  LookupDisplayField,
  ParameterField,
  ValueFormat
} from '@modules/fields';
import { FilterItem2 } from '@modules/filters';
import {
  forceModelId,
  Model,
  ModelDbField,
  ModelDescription,
  ModelFieldType,
  ModelRelation,
  PAGE_PARAM,
  processLegacyModelDescriptions,
  SEARCH_PARAM
} from '@modules/models';
import { ProjectApiService } from '@modules/project-api';
import { Resource, ResourceName, ResourceType, ResourceTypeItem } from '@modules/projects';
import {
  ActionQuery,
  HttpMethod,
  ListModelDescriptionQuery,
  ModelDescriptionQuery,
  QueryPagination,
  QueryService,
  QueryType,
  SqlQueryService,
  StorageQuery
} from '@modules/queries';
import { QueryTokensService } from '@modules/queries-tokens';
import { Storage } from '@modules/storages';
import { AppError, getTimezoneOffset, interpolateSqlContext, isAbsoluteUrl, isSet } from '@shared';

// TODO: Refactor import
import { modelFieldToRawListViewSettingsColumn } from '../../../customize/utils/common';

import { ModelResponse } from '../../data/model-response';
import {
  ActionExecuteParams,
  GetQueryOptions,
  getQueryOptionsToParams,
  ResourceController,
  SqlQueryAggregate,
  SqlQueryGroup,
  SqlQueryOptions
} from '../../data/resource-controller';
import { aggregateGetResponse } from '../../utils/aggregate';
import { isResourceQueryCustom, prepareDataSourceColumnForGet } from '../../utils/common';
import { groupGetResponse } from '../../utils/group';

interface GraphQLLookup {
  [k: string]: {
    value: any | any[];
    related?: GraphQLLookup;
    aggregated?: number;
  };
}

interface GraphQLGetResponseItem {
  data: {
    attrs?: Record<string, any>;
    allAttrs?: Record<string, any>;
    lookups?: GraphQLLookup[];
  }[];
  pagination: {
    count: number;
    limit: number;
    offset?: boolean;
    page?: boolean;
    hasMore?: boolean;
  };
}

interface GraphQLGetResponse {
  [k: string]: GraphQLGetResponseItem;
}

interface GraphQLResponse<T = Record<string, any>> {
  data?: T;
  errors?: string[];
}

function mapGqlValue(value: any) {
  if (typeof value === 'string') {
    return gql.rawString(value);
  } else if (isArray(value)) {
    return value.map(item => mapGqlValue(item));
  } else if (value instanceof Date) {
    return gql.rawString(value.toISOString());
  } else if (value instanceof moment) {
    return gql.rawString(value.toISOString());
  } else if (typeof value === 'object') {
    return gql.rawString(JSON.stringify(value));
  } else {
    return value;
  }
}

function cleanGqlName(name: string) {
  if (name == '_meta') {
    return '__meta';
  }

  return name.replace(/[^_a-zA-Z0-9]/gu, '_').replace(/^(\d)/, '_$1');
}

function mapGqlFilterItem(filter: FilterItem2): Object {
  const result = {};
  const fieldItem = filter.field.reduce((acc, item, i) => {
    const name = cleanGqlName(item);

    if (i == filter.field.length - 1) {
      acc[name] = {};
      return acc[name];
    } else {
      acc[name] = { ['relation']: {} };
      return acc[name]['relation'];
    }
  }, result);
  const lookup = filter.lookup && isSet(filter.lookup.lookup) ? filter.lookup.param : 'eq';

  if (lookup == 'eq' && filter.value === null) {
    fieldItem['isNull'] = true;
  } else if (isSet(filter.value, true)) {
    fieldItem[lookup] = mapGqlValue(filter.value);
  }

  return filter.exclude ? { _not_: result } : result;
}

function lookupDisplayFieldToGql(lookup: LookupDisplayField) {
  const root = {};
  return lookup.path.reduce((acc, item, i) => {
    const name = cleanGqlName(item);

    if (i == lookup.path.length - 1) {
      acc[name] = {
        return: true
      };
      return root;
    } else {
      const next = {};
      acc[name] = {
        relation: next
      };
      return next;
    }
  }, root);
}

function aggregateDisplayFieldToGql(aggregate: AggregateDisplayField) {
  const root = {};

  return aggregate.path.reduce((acc, item, i) => {
    const name = cleanGqlName(item);

    if (i == aggregate.path.length - 1) {
      acc[name] = {
        aggregate: {
          func: aggregate.func,
          ...(isSet(aggregate.column) && {
            attr: gql.rawString(aggregate.column)
          })
        }
      };
      return root;
    } else {
      const next = {};
      acc[name] = {
        relation: next
      };
      return next;
    }
  }, root);
}

function getLookupValue(lookup: GraphQLLookup, path: string[]): any {
  return path.reduce((acc, item, i) => {
    const name = cleanGqlName(item);

    if (!acc || !acc[name]) {
      return;
    }

    if (i == path.length - 1) {
      return acc[name].value;
    } else {
      return acc[name].related;
    }
  }, lookup);
}

function getLookupAggregated(lookup: GraphQLLookup, path: string[]): any {
  return path.reduce((acc, item, i) => {
    const name = cleanGqlName(item);

    if (!acc || !acc[name]) {
      return;
    }

    if (i == path.length - 1) {
      return acc[name].aggregated;
    } else {
      return acc[name].related;
    }
  }, lookup);
}

export interface JetBridgeTablesResponse {
  tables: string[];
  max_tables?: number;
}

@Injectable()
export class JetBridgeResourceController extends ResourceController {
  filtersExcludable = true;
  filtersLookups = true;
  relationFilter = true;

  private mode: AdminMode;
  private http: HttpClient;
  private apiService: ProjectApiService;
  private queryService: QueryService;
  private sqlQueryService: SqlQueryService;
  private queryTokensService: QueryTokensService;
  private parameterCodec: CustomHttpParameterCodec;

  init() {
    this.mode = this.initService<AdminMode>(ROUTE_ADMIN_MODE);
    this.http = this.initService<HttpClient>(HttpClient);
    this.apiService = this.initService<ProjectApiService>(ProjectApiService);
    this.queryService = this.initService<QueryService>(QueryService);
    this.sqlQueryService = this.initService<SqlQueryService>(SqlQueryService);
    this.queryTokensService = this.initService<QueryTokensService>(QueryTokensService);
    this.parameterCodec = this.initService<CustomHttpParameterCodec>(CustomHttpParameterCodec);
  }

  supportedQueryTypes(resource: ResourceTypeItem, queryClass: any): QueryType[] {
    if (resource.name == ResourceName.MongoDB) {
      return [QueryType.Simple];
    } else {
      return [QueryType.Simple, QueryType.SQL];
    }
  }

  getHeaders(resource: Resource): HttpHeaders {
    let headers = new HttpHeaders();
    const childProjectName = window['project_has_parent'] ? window['project'] : undefined;

    headers = this.apiService.setHeadersToken(headers, childProjectName);
    headers = this.apiService.setHeadersBridgeSettings(headers, resource);
    headers = this.setStickSessionHeader(headers, resource);

    return headers;
  }

  getResourceUID(resource: Resource, separator: string) {
    return [resource.demo ? DEMO_RESOURCES_PROJECT : window['project'], resource.environment, resource.uniqueName].join(
      separator
    );
  }

  setStickSessionHeader(headers: HttpHeaders, resource: Resource): HttpHeaders {
    const stickSession = resource.params['bridge_settings'] ? this.getResourceUID(resource, '|') : undefined;
    if (stickSession) {
      headers = headers.set('X-Stick-Session', stickSession);
    }

    return headers;
  }

  getApiInfo(resource: Resource): Observable<ApiInfo> {
    const url = resource.params['url'];

    if (!isSet(url)) {
      return of(undefined);
    }

    let headers = new HttpHeaders();

    headers = this.setStickSessionHeader(headers, resource);

    return this.http.get(url, { headers: headers }).pipe(
      map(result => new ApiInfo().deserialize(result)),
      publishLast(),
      refCount()
    );
  }

  checkApiInfo(resource: Resource): Observable<Resource> {
    if (
      resource.apiInfo ||
      !(resource.type == ResourceType.JetBridge || resource.isSynced() || resource.hasCollectionSync())
    ) {
      return of(resource);
    }

    return this.getApiInfo(resource).pipe(
      map(apiInfo => {
        if (apiInfo) {
          resource.apiInfo = apiInfo;
        }
        return resource;
      })
    );
  }

  checkResource(typeItem: ResourceTypeItem, params: Object): Observable<boolean> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        let headers = new HttpHeaders();
        let url = params['url'];

        if (params && params['bridge_settings']) {
          url += 'model_descriptions/';
          const resource = new Resource();
          resource.params = params;
          headers = this.getHeaders(resource);
        }

        return this.http.get(url, { headers: headers });
      }),
      map(result => {
        if (result['version'] == undefined) {
          throwError(false);
          return;
        }

        return true;
      }),
      catchError(e => {
        let serverError = new ServerRequestError(e);

        if (serverError.status == 0) {
          serverError = new ServerRequestError({
            non_field_errors: undefined,
            url: ['API is not reachable. Is it running? If so, you might have a CORS configuration issue.']
          });
        }

        return throwError(serverError);
      }),
      publishLast(),
      refCount()
    );
  }

  // getModelDescriptionGetParameters(modelDescription: ModelDescription): ParameterField[] {
  //   return modelDescription.dbFields
  //     .reduce((acc, field) => {
  //       const fieldDescription = getFieldDescriptionByType(field.field);
  //       const params = { ...fieldDescription.defaultParams, ...fieldDescription.forceParams };
  //
  //       fieldDescription.lookups.forEach(lookup => {
  //         const filterName = serializeFieldParamName(field.name, lookup.type.lookup);
  //
  //         acc.push({
  //           group: field.verboseName || field.name,
  //           name: filterName,
  //           verbose_name: [field.verboseName || field.name, lookup.type.verboseName].join(' '),
  //           field: lookup.field,
  //           required: false,
  //           params: params
  //         });
  //
  //         // Exclude parameters
  //         const excludeName = serializeFieldParamName(field.name, lookup.type.lookup, true);
  //
  //         acc.push({
  //           group: field.verboseName || field.name,
  //           name: excludeName,
  //           verbose_name: ['exclude', field.verboseName || field.name, lookup.type.verboseName].join(' '),
  //           field: lookup.field,
  //           required: false,
  //           params: params
  //         });
  //       });
  //
  //       return acc;
  //     }, [])
  //     .map(item => new ParameterField().deserialize(item));
  // }

  supportModelDescriptionManagement(resource: Resource): boolean {
    return resource.typeItem && [ResourceName.JetDatabase].includes(resource.typeItem.name);
  }

  discoverConnection(resource: Resource): Observable<boolean> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const projectUrl = this.apiService.methodURLForProjectResource('discover/connection/', resource);
        const headers = this.getHeaders(resource);

        return this.http.get<JetBridgeTablesResponse>(projectUrl, {
          headers: headers,
          observe: 'response'
        });
      }),
      this.apiService.processApiResponse<JetBridgeTablesResponse>(),
      map(() => true),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  discoverTables(resource: Resource): Observable<JetBridgeTablesResponse> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const projectUrl = this.apiService.methodURLForProjectResource('discover/tables/', resource);
        const headers = this.getHeaders(resource);

        return this.http.get<JetBridgeTablesResponse>(projectUrl, {
          headers: headers,
          observe: 'response'
        });
      }),
      this.apiService.processApiResponse<JetBridgeTablesResponse>(),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  modelDescriptionGet(resource: Resource, draft = false): Observable<ModelDescription[]> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const projectUrl = this.apiService.methodURLForProjectResource('model_descriptions/', resource);
        const headers = this.getHeaders(resource);
        const params = {
          cid: this.getResourceUID(resource, '/'),
          ...(draft && { draft: '1' })
        };

        return this.http.get<Object[]>(projectUrl, { headers: headers, params: params, observe: 'response' });
      }),
      this.apiService.processApiResponse<Object[]>(),
      map(result => {
        return processLegacyModelDescriptions(result);
      }),
      map(result =>
        result.map(item => {
          const schemas = resource.typeItem.name == ResourceName.BigQuery;
          const instance = new ModelDescription().deserialize(item, schemas);

          instance.resource = resource.uniqueName;

          instance.fields
            .filter(field => {
              return (
                [FieldType.Select, FieldType.MultipleSelect, FieldType.RadioButton].includes(field.item.field) &&
                field.item.params['resource'] == '{{resource}}'
              );
            })
            .forEach(field => {
              field.item.params['resource'] = resource.uniqueName;
            });

          instance.fields
            .filter(field => {
              return (
                field.item.field == FieldType.RelatedModel &&
                field.item.params['related_model'] &&
                isSet(field.item.params['related_model']['model']) &&
                !field.item.params['related_model']['model'].includes('.')
              );
            })
            .forEach(field => {
              field.item.params['related_model']['model'] = [
                resource.uniqueName,
                field.item.params['related_model']['model']
              ].join('.');
            });

          instance.getQuery = new ListModelDescriptionQuery();
          instance.getQuery.queryType = QueryType.Simple;
          instance.getQuery.simpleQuery = new instance.getQuery.simpleQueryClass();
          instance.getQuery.simpleQuery.model = instance.model;
          // instance.getParameters = this.getModelDescriptionGetParameters(instance);

          instance.searchQuery = new ListModelDescriptionQuery();
          instance.searchQuery.queryType = QueryType.Simple;
          instance.searchQuery.simpleQuery = new instance.searchQuery.simpleQueryClass();
          instance.searchQuery.simpleQuery.model = instance.model;

          instance.getDetailQuery = new ModelDescriptionQuery();
          instance.getDetailQuery.queryType = QueryType.Simple;
          instance.getDetailQuery.simpleQuery = new instance.getDetailQuery.simpleQueryClass();
          instance.getDetailQuery.simpleQuery.model = instance.model;
          // instance.getDetailParameters = this.getModelDescriptionGetParameters(instance);
          instance.getDetailParametersUseDefaults = true;

          if (resource.type == ResourceType.JetBridge && !instance.isView) {
            instance.createQuery = new ModelDescriptionQuery();
            instance.createQuery.queryType = QueryType.Simple;
            instance.createQuery.simpleQuery = new instance.createQuery.simpleQueryClass();
            instance.createQuery.simpleQuery.model = instance.model;
            instance.createParametersUseDefaults = true;

            instance.updateQuery = new ModelDescriptionQuery();
            instance.updateQuery.queryType = QueryType.Simple;
            instance.updateQuery.simpleQuery = new instance.updateQuery.simpleQueryClass();
            instance.updateQuery.simpleQuery.model = instance.model;
            instance.updateParametersUseDefaults = true;

            instance.deleteQuery = new ModelDescriptionQuery();
            instance.deleteQuery.queryType = QueryType.Simple;
            instance.deleteQuery.simpleQuery = new instance.deleteQuery.simpleQueryClass();
            instance.deleteQuery.simpleQuery.model = instance.model;
            instance.deleteParametersUseDefaults = true;
          } else {
            instance.createQuery = undefined;
            instance.updateQuery = undefined;
            instance.deleteQuery = undefined;
          }

          instance.siblingsQuery = new ModelDescriptionQuery();
          instance.siblingsQuery.queryType = QueryType.Simple;
          instance.siblingsQuery.simpleQuery = new instance.siblingsQuery.simpleQueryClass();
          instance.siblingsQuery.simpleQuery.model = instance.model;

          instance.aggregateQuery = new ModelDescriptionQuery();
          instance.aggregateQuery.queryType = QueryType.Simple;
          instance.aggregateQuery.simpleQuery = new instance.aggregateQuery.simpleQueryClass();
          instance.aggregateQuery.simpleQuery.model = instance.model;
          // instance.aggregateParameters = this.getModelDescriptionGetParameters(instance);

          instance.groupQuery = new ModelDescriptionQuery();
          instance.groupQuery.queryType = QueryType.Simple;
          instance.groupQuery.simpleQuery = new instance.groupQuery.simpleQueryClass();
          instance.groupQuery.simpleQuery.model = instance.model;
          // instance.groupParameters = this.getModelDescriptionGetParameters(instance);

          return instance;
        })
      ),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  setModelDescriptionRelationOverrides(
    resource: Resource,
    items: { modelDescription: ModelDescription; relations: ModelRelation[] }[],
    draft = false
  ): Observable<ModelRelation[]> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const projectUrl = this.apiService.methodURLForProjectResource(
          'model_descriptions/relationship_overrides/',
          resource
        );
        const headers = this.getHeaders(resource);
        const params = {
          ...(draft && { draft: '1' })
        };
        const data = items.map(item => {
          return {
            model: item.modelDescription.model,
            relations: item.relations.map(relation => {
              return {
                direction: relation.direction,
                local_field: relation.localField,
                related_model: relation.relatedModel,
                related_field: relation.relatedField
              };
            })
          };
        });

        return this.http.post<Object[]>(projectUrl, data, { headers: headers, params: params, observe: 'response' });
      }),
      this.apiService.processApiResponse<Object[]>(),
      map(result => result.map(item => new ModelRelation().deserialize(item))),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  serializeModelDescriptionField(modelDescription: ModelDescription, field: ModelDbField) {
    const fieldDescription = getFieldDescriptionByType(field.field);

    if (!field.dbField) {
      if (fieldDescription.name == FieldType.Number) {
        const valueFormat =
          field.params && field.params['value_format']
            ? new ValueFormat().deserialize(field.params['value_format'])
            : undefined;

        if (valueFormat && isSet(valueFormat.numberFraction) && valueFormat.numberFraction != 0) {
          field.dbField = 'FloatField';
        }
      } else if (fieldDescription.name == FieldType.Select) {
        field.dbField = 'CharField';
      }
    }

    if (field.params && field.params['related_model'] && field.field != FieldType.RelatedModel) {
      delete field.params['related_model'];
    }

    return {
      name: field.name,
      field: fieldDescription.jetBridgeTypes[0],
      db_field: field.dbField,
      primary_key: modelDescription.primaryKeyField == field.name,
      null: field.null,
      default_type: field.defaultType,
      default_value: field.defaultValue,
      params: field.params,
      data_source_field: field.field,
      data_source_name: field.verboseName,
      data_source_params: field.params
    };
  }

  modelDescriptionCreate(resource: Resource, modelDescription: ModelDescription): Observable<Object> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const projectUrl = this.apiService.methodURLForProjectResource('tables/', resource);
        const headers = this.getHeaders(resource);
        const data = {
          name: modelDescription.dbTable,
          columns: modelDescription.dbFields.map(field => this.serializeModelDescriptionField(modelDescription, field)),
          data_source_name: modelDescription.verboseName,
          data_source_name_plural: modelDescription.verboseNamePlural
        };

        return this.http.post<Object>(projectUrl, data, { headers: headers, observe: 'response' });
      }),
      this.apiService.processApiResponse(),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  modelDescriptionDelete(resource: Resource, modelDescription: ModelDescription): Observable<Object> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const projectUrl = this.apiService.methodURLForProjectResource(`tables/${modelDescription.dbTable}/`, resource);
        const headers = this.getHeaders(resource);
        return this.http.delete<Object>(projectUrl, { headers: headers, observe: 'response' });
      }),
      this.apiService.processApiResponse(),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  modelDescriptionFieldCreate(
    resource: Resource,
    modelDescription: ModelDescription,
    field: ModelDbField
  ): Observable<Object> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const projectUrl = this.apiService.methodURLForProjectResource(
          `tables/${modelDescription.dbTable}/columns/`,
          resource
        );
        const headers = this.getHeaders(resource);
        const data = this.serializeModelDescriptionField(modelDescription, field);

        return this.http.post<Object>(projectUrl, data, { headers: headers, observe: 'response' });
      }),
      this.apiService.processApiResponse(),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  modelDescriptionFieldUpdate(
    resource: Resource,
    modelDescription: ModelDescription,
    name: string,
    field: ModelDbField
  ): Observable<Object> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const projectUrl = this.apiService.methodURLForProjectResource(
          `tables/${modelDescription.dbTable}/columns/${name}/`,
          resource
        );
        const headers = this.getHeaders(resource);
        const data = this.serializeModelDescriptionField(modelDescription, field);

        return this.http.patch<Object>(projectUrl, data, { headers: headers, observe: 'response' });
      }),
      this.apiService.processApiResponse(),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  modelDescriptionFieldDelete(
    resource: Resource,
    modelDescription: ModelDescription,
    name: string
  ): Observable<Object> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const projectUrl = this.apiService.methodURLForProjectResource(
          `tables/${modelDescription.dbTable}/columns/${name}/`,
          resource
        );
        const headers = this.getHeaders(resource);
        return this.http.delete<Object>(projectUrl, { headers: headers, observe: 'response' });
      }),
      this.apiService.processApiResponse(),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  getSql(
    resource: Resource,
    query: ListModelDescriptionQuery,
    parameters: ParameterField[] = [],
    params?: {},
    body?: {},
    columns: RawListViewSettingsColumn[] = [],
    paginate = true,
    aggregate?: SqlQueryAggregate,
    group?: SqlQueryGroup
  ): Observable<ModelResponse.GetResponse> {
    if (!query.sqlQuery) {
      return of(undefined);
    }

    return this.checkApiInfo(resource).pipe(
      switchMap(() => {
        const userQuery = isResourceQueryCustom(resource, query.queryType);
        const tokens = this.queryTokensService.mergeTokens(
          this.queryTokensService.generalTokens(),
          this.queryTokensService.modelGetTokens(params, parameters, userQuery),
          this.queryTokensService.paginationTokens(QueryPagination.Page, params)
        );
        const sql = query.sqlQuery.query.trim().replace(/;+$/, '');
        const newQuery =
          query.sqlQuery.version &&
          query.sqlQuery.version >= 2 &&
          resource.apiInfo &&
          resource.apiInfo.isCompatibleJetBridge({ jetBridge: '1.0.0', jetDjango: '1.1.5' });

        if (newQuery) {
          const sorting =
            tokens.sorting && tokens.sorting.field !== undefined
              ? [(tokens.sorting.asc ? '' : '-') + tokens.sorting.field]
              : undefined;
          const queryColumns = columns.map(item => {
            const fieldDescription = getFieldDescriptionByType(item.field);
            return {
              name: item.name,
              data_type: fieldDescription.jetBridgeTypes[0]
            };
          });
          const filters = toPairs(tokens.params).map(([k, v]) => {
            return {
              name: k,
              value: v
            };
          });

          if (isSet(tokens.search)) {
            filters.push({
              name: SEARCH_PARAM,
              value: tokens.search
            });
          }

          return this.sql(resource, sql, {
            tokens: tokens,
            ...(paginate &&
              tokens.paging && {
                offset: tokens.paging.offset,
                limit: tokens.paging.limit,
                count: true
              }),
            orderBy: sorting,
            columns: queryColumns,
            filters: filters,
            aggregate: aggregate,
            groups: group,
            version: query.sqlQuery.version
          }).pipe(
            map(result => {
              const data = {
                results: result.toObject()
              };

              const response = this.createGetResponse().deserialize({
                ...data,
                per_page: data['limit']
              });

              response.results.forEach(item => {
                item.deserializeAttributes(columns);
              });

              response.count = result.count;
              response.hasMore =
                response.count !== undefined
                  ? tokens.paging.offset + result.data.length < response.count
                  : !!result.data.length;

              if (
                !isSet(response.perPage) &&
                response.hasMore &&
                isSet(tokens.paging.limit) &&
                result.data.length < tokens.paging.limit
              ) {
                response.perPage = result.data.length;
              }

              return response;
            }),
            this.apiService.catchApiError(),
            publishLast(),
            refCount()
          );
        } else {
          let wrapperSql = `SELECT * FROM (${sql}) __jet_q`;
          const countSql = `SELECT count(*) FROM (${sql}) __jet_q`;

          if (tokens.sorting && tokens.sorting.field !== undefined) {
            if (resource.typeItem.name == ResourceName.PostgreSQL) {
              wrapperSql += ` ORDER BY "${tokens.sorting.field}" ${tokens.sorting.asc ? 'ASC' : 'DESC'}`;
            } else {
              wrapperSql += ` ORDER BY ${tokens.sorting.field} ${tokens.sorting.asc ? 'ASC' : 'DESC'}`;
            }
          }

          if (paginate && tokens.paging) {
            // Reference: https://stackoverflow.com/a/24046664
            if (resource.typeItem.name == ResourceName.MicrosoftSQL) {
              wrapperSql += ` ORDER BY (SELECT NULL) OFFSET ${tokens.paging.offset} ROWS FETCH NEXT ${tokens.paging.limit} ROWS ONLY`;
            } else if (resource.typeItem.name == ResourceName.Oracle) {
              wrapperSql += ` OFFSET ${tokens.paging.offset} ROWS FETCH NEXT ${tokens.paging.limit} ROWS ONLY`;
            } else {
              wrapperSql += ` LIMIT ${tokens.paging.limit} OFFSET ${tokens.paging.offset}`;
            }
          }

          return combineLatest(
            this.sql(resource, wrapperSql, { tokens: tokens, version: query.sqlQuery.version }),
            this.sql(resource, countSql, { tokens: tokens, version: query.sqlQuery.version }).pipe(
              catchError(() => of(undefined))
            )
          ).pipe(
            map(([result, countResult]) => {
              const data = {
                results: result.toObject()
              };

              const response = this.createGetResponse().deserialize({
                ...data,
                per_page: data['limit']
              });

              response.results.forEach(item => {
                item.deserializeAttributes(columns);
              });

              response.count = countResult ? countResult.data[0][0] : undefined;
              response.hasMore =
                response.count !== undefined
                  ? tokens.paging.offset + response.results.length < response.count
                  : !!result.data.length;

              if (
                !isSet(response.perPage) &&
                response.hasMore &&
                isSet(tokens.paging.limit) &&
                result.data.length < tokens.paging.limit
              ) {
                response.perPage = result.data.length;
              }

              return response;
            }),
            this.apiService.catchApiError(),
            publishLast(),
            refCount()
          );
        }
      })
    );
  }

  getSimple(
    resource: Resource,
    query: ListModelDescriptionQuery,
    params?: {},
    body?: {},
    columns: RawListViewSettingsColumn[] = [],
    paginate = true
  ): Observable<ModelResponse.GetResponse> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.modelUrlForProjectResource(query.simpleQuery.model, resource);
        let headers = this.getHeaders(resource);
        const page = params[PAGE_PARAM] || 1;
        const queryParams = pickBy(params, (v, k) => v !== null && v !== undefined);

        const httpParams = new HttpParams({
          fromObject: {
            ...queryParams
            // tz: getTimezoneOffset()
          },
          encoder: this.parameterCodec
        });

        if (body) {
          headers = headers.set('X-HTTP-Method-Override', HttpMethod.GET);

          return this.http
            .request('post', url, {
              headers: headers,
              params: httpParams,
              body: body,
              observe: 'response'
            })
            .pipe(
              this.apiService.processApiResponse<Object[]>(),
              map(result => {
                let data = result as Object;

                if (result['results'] === undefined) {
                  data = {
                    results: data as Object[]
                  };
                } else if (isSet(data['num_pages'])) {
                  data['has_more'] = page < data['num_pages'];
                }

                return this.createGetResponse().deserialize(data, query.simpleQuery.model, undefined);
              }),
              tap(response => {
                response.results.forEach(item => {
                  item.deserializeAttributes(columns);
                });

                return response;
              }),
              this.apiService.catchApiError(),
              publishLast(),
              refCount()
            );
        } else {
          return this.http.get(url, { headers: headers, params: httpParams, observe: 'response' }).pipe(
            this.apiService.processApiResponse<Object[]>(),
            map(result => {
              let data = result as Object;

              if (data['results'] === undefined) {
                data = {
                  results: data as Object[]
                };
              } else if (isSet(data['num_pages'])) {
                data['has_more'] = page < data['num_pages'];
              }

              return this.createGetResponse().deserialize(data, query.simpleQuery.model, undefined);
            }),
            tap(response => {
              response.results.forEach(item => {
                item.deserializeAttributes(columns);
              });
            }),
            this.apiService.catchApiError(),
            publishLast(),
            refCount()
          );
        }
      })
    );
  }

  isGetAdvSupported(resource: Resource): boolean {
    return (
      resource &&
      resource.apiInfo &&
      resource.apiInfo.isCompatibleJetBridge({ jetBridge: '1.2.8', jetDjango: '1.4.3' }) &&
      resource.apiInfo.storeAvailable
    );
  }

  getSimpleAdv(
    resource: Resource,
    query: ListModelDescriptionQuery,
    options: GetQueryOptions = {}
  ): Observable<ModelResponse.GetResponse> {
    const draft = this.mode == AdminMode.Builder;
    const model = cleanGqlName(query.simpleQuery.model);
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.methodURLForProjectResource('graphql/', resource);
        const headers = this.getHeaders(resource);
        const queryParams = {
          ...(draft && { draft: '1' })
        };
        const params = {};
        const dataColumns = (options.columns || []).filter(item => item.type == DisplayFieldType.Base);
        const lookupColumns = (options.columns || []).filter(
          item => item.type == DisplayFieldType.Lookup
        ) as LookupDisplayField[];
        const aggregateColumns = (options.columns || []).filter(
          item => item.type == DisplayFieldType.Aggregate
        ) as AggregateDisplayField[];

        if (options.paging) {
          params['pagination'] = {
            page: options.paging.page || 1,
            ...(isSet(options.paging.limit) && { limit: options.paging.limit })
          };
        }

        if (options.filters && options.filters.length) {
          params['filters'] = options.filters.map(item => mapGqlFilterItem(item));
        }

        if (isSet(options.search)) {
          params['search'] = { query: gql.rawString(options.search) };
        }

        if (options.sort && options.sort.length) {
          params['sort'] = options.sort.map(item => {
            const name = cleanGqlName(item.field);
            return {
              [name]: {
                descending: !!item.desc
              }
            };
          });
        }

        const relatedColumns = [];

        dataColumns
          .filter(item => item.field == FieldType.RelatedModel)
          .filter(item => isSet(item.params['related_model']) && isSet(item.params['related_model']['model']))
          .forEach(relatedColumn => {
            const relatedModelId = forceModelId(relatedColumn.params['related_model']['model'], resource.uniqueName);
            const relatedModelDescription =
              isSet(relatedModelId) && options.modelDescriptions
                ? options.modelDescriptions.find(item => item.isSame(relatedModelId))
                : undefined;

            if (relatedColumn.params['custom_display_field_input']) {
              relatedModelDescription.dbFields.forEach(relatedField => {
                relatedColumns.push({
                  column: relatedColumn,
                  nameField: relatedField.name
                });
              });
            } else {
              const nameField =
                relatedColumn.params['custom_display_field'] ||
                (relatedModelDescription ? relatedModelDescription.displayField : undefined);

              if (isSet(nameField)) {
                relatedColumns.push({
                  column: relatedColumn,
                  nameField: nameField
                });
              }
            }
          });

        const lookups = [
          ...relatedColumns
            .map(item => new LookupDisplayField({ path: [item.column.name, item.nameField] }))
            .map(item => lookupDisplayFieldToGql(item)),
          ...lookupColumns.map(item => lookupDisplayFieldToGql(item)),
          ...aggregateColumns.map(item => aggregateDisplayFieldToGql(item))
        ];

        if (lookups.length) {
          params['lookups'] = lookups;
        }

        const modelData = {};
        const modelAttrs = dataColumns.reduce((acc, item) => {
          const name = cleanGqlName(item.name);
          acc[name] = gql.types.string;
          return acc;
        }, {});

        if (keys(modelAttrs).length) {
          modelData['attrs'] = modelAttrs;
        } else {
          modelData['allAttrs'] = gql.types.custom<Object>();
        }

        if (lookups.length) {
          modelData['lookups'] = gql.types.custom<Object>();
        }

        const modelFields = {
          data: modelData,
          pagination: {
            limit: gql.types.number,
            offset: gql.types.number,
            page: gql.types.number,
            ...(options.paging && options.paging.disableCount
              ? undefined
              : {
                  count: gql.types.number,
                  hasMore: gql.types.boolean
                })
          }
        };
        const getRecordsQuery = gql.query('GetRecords', {
          [model]: keys(params).length ? gql.params(params, modelFields) : modelFields
        });
        const data = { query: getRecordsQuery.toString(), validate: false };

        return this.http
          .post<GraphQLResponse<GraphQLGetResponse>>(url, data, {
            headers: headers,
            params: queryParams,
            observe: 'response'
          })
          .pipe(
            this.apiService.processApiResponse<GraphQLResponse<GraphQLGetResponse>>(),
            map(result => {
              if (result.errors) {
                throw new AppError(result.errors[0]);
              }

              if (!result.data[model]) {
                throw new AppError(`Collection "${model}" not found`);
              }

              const results = result.data[model].data.map(item => {
                let attrs: Object;

                if (dataColumns.length) {
                  attrs = fromPairs(
                    dataColumns.map(column => {
                      const value = item.attrs[cleanGqlName(column.name)];
                      return [column.name, value];
                    })
                  );
                } else {
                  attrs = item.allAttrs;
                }

                (item.lookups || [])
                  .slice(relatedColumns.length, relatedColumns.length + lookupColumns.length)
                  .forEach((lookup, i) => {
                    const lookupColumn = lookupColumns[i];
                    attrs[lookupColumn.name] = getLookupValue(lookup, lookupColumn.path);
                  });

                (item.lookups || [])
                  .slice(
                    relatedColumns.length + lookupColumns.length,
                    relatedColumns.length + lookupColumns.length + aggregateColumns.length
                  )
                  .forEach((aggregate, i) => {
                    const aggregateColumn = aggregateColumns[i];
                    attrs[aggregateColumn.name] = getLookupAggregated(aggregate, aggregateColumn.path);
                  });

                return attrs;
              });
              const relations = result.data[model].data.map(item => {
                return (item.lookups || [])
                  .slice(0, relatedColumns.length)
                  .map((lookup, i) => {
                    const column = relatedColumns[i].column;
                    const nameField = relatedColumns[i].nameField;
                    const lookupResult = lookup[cleanGqlName(column.name)];
                    const lookupResultValue =
                      lookupResult && lookupResult.related ? lookupResult.related[cleanGqlName(nameField)] : undefined;

                    if (!lookupResultValue) {
                      return;
                    }

                    return {
                      path: [column.name],
                      field: nameField,
                      value: lookupResultValue.value
                    };
                  })
                  .filter(relation => relation);
              });
              const pagination = result.data[model].pagination;

              return this.createGetResponse().deserialize(
                {
                  results: results,
                  relations: relations,
                  count: pagination.count,
                  per_page: pagination.limit,
                  has_more: pagination.hasMore
                },
                query.simpleQuery.model,
                undefined
              );
            }),
            tap(response => {
              const columns = [...dataColumns, ...lookupColumns, ...aggregateColumns];
              if (columns.length) {
                response.results.forEach(item => {
                  item.deserializeAttributes(columns);
                });
              }
            }),
            this.apiService.catchApiError(),
            publishLast(),
            refCount()
          );
      })
    );
  }

  modelGetSimple(
    resource: Resource,
    modelDescription: ModelDescription,
    params?: {},
    body?: {},
    paginate = true
  ): Observable<ModelResponse.GetResponse> {
    return this.getSimple(resource, modelDescription.getQuery, params, body, modelDescription.dbFields, paginate).pipe(
      map(response => {
        if (!response) {
          return;
        }

        response.results.forEach(item => {
          item.setUp(modelDescription);
          item.deserializeAttributes(modelDescription.dbFields);
        });

        return response;
      })
    );
  }

  modelGetSimpleAdv(
    resource: Resource,
    modelDescription: ModelDescription,
    options: GetQueryOptions = {}
  ): Observable<ModelResponse.GetResponse> {
    return this.getSimpleAdv(resource, modelDescription.getQuery, {
      ...options,
      columns:
        options.columns && options.columns.length
          ? options.columns
          : modelDescription.dbFields
              .map(item => modelFieldItemToDisplayField(item))
              .map(field => prepareDataSourceColumnForGet(resource, modelDescription, field))
    }).pipe(
      map(response => {
        if (!response) {
          return;
        }

        response.results.forEach(item => {
          item.setUp(modelDescription);
          item.deserializeAttributes(modelDescription.dbFields);
        });

        return response;
      })
    );
  }

  modelGetSql(
    resource: Resource,
    modelDescription: ModelDescription,
    params?: {},
    body?: {}
  ): Observable<ModelResponse.GetResponse> {
    return this.getSql(
      resource,
      modelDescription.getQuery,
      modelDescription.getParameters,
      params,
      body,
      modelDescription.dbFields
    ).pipe(
      map(response => {
        if (!response) {
          return;
        }

        response.results.forEach(item => {
          item.setUp(modelDescription);
          item.deserializeAttributes(modelDescription.dbFields);
        });

        return response;
      })
    );
  }

  modelGet(
    resource: Resource,
    modelDescription: ModelDescription,
    params?: {},
    body?: {}
  ): Observable<ModelResponse.GetResponse> {
    params = params || {};

    if (modelDescription.getQuery.queryType == QueryType.SQL) {
      return this.modelGetSql(resource, modelDescription, params, body);
    } else {
      return this.modelGetSimple(resource, modelDescription, params, body);
    }
  }

  modelGetAdv(
    resource: Resource,
    modelDescription: ModelDescription,
    options: GetQueryOptions = {}
  ): Observable<ModelResponse.GetResponse> {
    if (modelDescription.getQuery.queryType == QueryType.SQL) {
      const params = getQueryOptionsToParams(options);
      return this.modelGetSql(resource, modelDescription, params);
    } else {
      return this.modelGetSimpleAdv(resource, modelDescription, options);
    }
  }

  getDetailSql(
    resource: Resource,
    query: ModelDescriptionQuery,
    parameters: ParameterField[] = [],
    params?: {},
    columns: RawListViewSettingsColumn[] = []
  ): Observable<Model> {
    if (!query.sqlQuery) {
      return of(undefined);
    }

    const userQuery = isResourceQueryCustom(resource, query.queryType);
    const tokens = this.queryTokensService.mergeTokens(
      this.queryTokensService.generalTokens(),
      this.queryTokensService.modelGetDetailTokens(params, parameters, userQuery),
      params
    );

    return this.sql(resource, query.sqlQuery.query, { tokens: tokens, version: query.sqlQuery.version }).pipe(
      map((response: ModelResponse.SqlResponse) => {
        if (!response || !response.data.length) {
          return;
        }

        const model = this.createModel().deserialize(undefined, response.toObject()[0]);
        model.deserializeAttributes(columns);
        return model;
      }),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  modelGetDetailSimple(
    resource: Resource,
    modelDescription: ModelDescription,
    idField: string,
    id: number,
    params?: {}
  ): Observable<Model> {
    if (modelDescription && modelDescription.resource == 'messages_api') {
      params = { ...params, [idField]: id };
    } else {
      if (isSet(id)) {
        params = { ...params, [idField]: id };
      }
    }

    return this.modelGetSimple(resource, modelDescription, params).pipe(map(result => result.results[0]));
  }

  modelGetDetailSql(
    resource: Resource,
    modelDescription: ModelDescription,
    idField: string,
    id: number,
    params?: {}
  ): Observable<Model> {
    const modelParameters = this.getDetailParametersOrDefaults(resource, modelDescription);

    return this.getDetailSql(
      resource,
      modelDescription.getDetailQuery,
      modelParameters,
      params,
      modelDescription.dbFields
    ).pipe(
      map(result => {
        if (!result) {
          return;
        }

        result.setUp(modelDescription);
        result.deserializeAttributes(modelDescription.dbFields);

        return result;
      })
    );
  }

  modelGetDetail(
    resource: Resource,
    modelDescription: ModelDescription,
    idField: string,
    id: number,
    params?: {}
  ): Observable<Model> {
    params = params || {};

    if (modelDescription.getDetailQuery) {
      if (modelDescription.getDetailQuery.queryType == QueryType.SQL) {
        return this.modelGetDetailSql(resource, modelDescription, idField, id, params);
      } else {
        return this.modelGetDetailSimple(resource, modelDescription, idField, id, params);
      }
    } else if (modelDescription.getQuery) {
      params = {
        ...params
      };

      if (isSet(modelDescription.primaryKeyField)) {
        params[idField] = id;
      }

      return (modelDescription.getQuery.queryType == QueryType.SQL
        ? this.modelGetSql(resource, modelDescription, params)
        : this.modelGetSimple(resource, modelDescription, params)
      ).pipe(
        map(result => {
          if (!result || !result.results.length) {
            return;
          }

          return result.results[0];
        })
      );
    }
  }

  modelGetDetailAdv(
    resource: Resource,
    modelDescription: ModelDescription,
    idField: string,
    id: number,
    options: GetQueryOptions = {}
  ): Observable<Model> {
    const params = getQueryOptionsToParams(options);

    if (modelDescription.getDetailQuery && !this.isGetAdvSupported(resource)) {
      if (modelDescription.getDetailQuery.queryType == QueryType.SQL) {
        return this.modelGetDetailSql(resource, modelDescription, idField, id, params);
      } else {
        return this.modelGetDetailSimple(resource, modelDescription, idField, id, params);
      }
    } else if (modelDescription.getQuery) {
      if (isSet(modelDescription.primaryKeyField)) {
        params[idField] = id;
      }

      return (modelDescription.getQuery.queryType == QueryType.SQL
        ? this.modelGetSql(resource, modelDescription, params)
        : this.modelGetSimpleAdv(resource, modelDescription, options)
      ).pipe(
        map(result => {
          if (!result || !result.results.length) {
            return;
          }

          return result.results[0];
        })
      );
    }
  }

  modelCreate(
    resource: Resource,
    modelDescription: ModelDescription,
    modelInstance: Model,
    fields?: string[]
  ): Observable<Model> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.modelUrlForProjectResource(modelDescription.model, resource);
        const headers = this.getHeaders(resource);
        const data = modelInstance.serialize(fields);

        return this.http.post(url, data, { headers: headers, observe: 'response' });
      }),
      this.apiService.processApiResponse(),
      map(result => {
        const instance = this.createModel().deserialize(modelDescription.model, result);
        instance.setUp(modelDescription);
        instance.deserializeAttributes(modelDescription.dbFields);
        return instance;
      }),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  modelCreateBulk(
    resource: Resource,
    modelDescription: ModelDescription,
    modelInstances: Model[],
    fields?: string[]
  ): Observable<{ success: boolean; errors?: Object }[]> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.actionUrlForProjectResource(modelDescription.model, 'bulk_create', resource);
        const headers = this.getHeaders(resource);
        const data = modelInstances.map(item => item.serialize(fields));

        return this.http.post(url, data, { headers: headers, observe: 'response' });
      }),
      this.apiService.processApiResponse(),
      map(result => {
        const instance = this.createModel().deserialize(modelDescription.model, result);
        instance.setUp(modelDescription);
        instance.deserializeAttributes(modelDescription.dbFields);
        return instance;
      }),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  modelUpdate(
    resource: Resource,
    modelDescription: ModelDescription,
    modelInstance: Model,
    fields?: string[]
  ): Observable<Model> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.detailModelUrlForProjectResource(
          modelDescription.model,
          modelInstance.initialPrimaryKey,
          resource
        );
        const headers = this.getHeaders(resource);

        return this.http.patch(url, modelInstance.serialize(fields), { headers: headers, observe: 'response' });
      }),
      this.apiService.processApiResponse(),
      map(result => {
        const instance = this.createModel().deserialize(modelDescription.model, result);
        instance.setUp(modelDescription);
        instance.deserializeAttributes(modelDescription.dbFields);
        return instance;
      }),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  modelDelete(resource: Resource, modelDescription: ModelDescription, modelInstance: Model): Observable<Object> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.detailModelUrlForProjectResource(
          modelDescription.model,
          modelInstance.initialPrimaryKey,
          resource
        );
        const headers = this.getHeaders(resource);

        return this.http.delete(url, { headers: headers, observe: 'response' });
      }),
      this.apiService.processApiResponse(),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  modelReorder(
    resource: Resource,
    modelDescription: ModelDescription,
    forward: boolean,
    segmentFrom: number,
    segmentTo: number,
    item: number,
    segmentByOrderingField?: boolean
  ) {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.actionUrlForProjectResource(modelDescription.model, 'reorder', resource);
        const headers = this.getHeaders(resource);
        const data = {
          ordering_field: modelDescription.orderingField,
          forward: forward,
          segment_from: segmentFrom,
          segment_to: segmentTo,
          item: item,
          segment_by_ordering_field: segmentByOrderingField
        };

        return this.http.post(url, data, { headers: headers, observe: 'response' });
      }),
      this.apiService.processApiResponse(),
      map(result => {
        const instance = this.createModel().deserialize(modelDescription.model, result);
        instance.setUp(modelDescription);
        instance.deserializeAttributes(modelDescription.dbFields);
        return instance;
      }),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  modelResetOrder(
    resource: Resource,
    modelDescription: ModelDescription,
    ordering?: string,
    valueOrdering?: string
  ): Observable<Object> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.actionUrlForProjectResource(modelDescription.model, 'reset_order', resource);
        const headers = this.getHeaders(resource);
        const data = {
          ordering_field: modelDescription.orderingField,
          ordering: ordering,
          value_ordering: valueOrdering
        };

        return this.http.post(url, data, { headers: headers, observe: 'response' });
      }),
      this.apiService.processApiResponse(),
      map(result => {
        const instance = this.createModel().deserialize(modelDescription.model, result);
        instance.setUp(modelDescription);
        instance.deserializeAttributes(modelDescription.dbFields);
        return instance;
      }),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  aggregateSimple(
    resource: Resource,
    model: string,
    yFunc: AggregateFunc,
    yColumn: string,
    params?: {}
  ): Observable<any> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.actionUrlForProjectResource(model, 'aggregate', resource);
        const headers = this.getHeaders(resource);

        const httpParams = new HttpParams({
          fromObject: {
            ...(params || {}),
            ...(isSet(yFunc) ? { _y_func: yFunc } : {}),
            ...(isSet(yColumn) ? { _y_column: yColumn } : {})
            // tz: getTimezoneOffset()
          },
          encoder: this.parameterCodec
        });

        return this.http.get(url, { headers: headers, params: httpParams, observe: 'response' });
      }),
      this.apiService.processApiResponse(),
      map(result => result['y_func']),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  getSimpleAggregate(
    resource: Resource,
    query: ListModelDescriptionQuery,
    yFunc: AggregateFunc,
    yColumn: string,
    params?: {},
    columns: RawListViewSettingsColumn[] = []
  ): Observable<number> {
    return this.getSimple(resource, query, params, undefined, columns, false).pipe(
      map(response => aggregateGetResponse(response, yFunc, yColumn))
    );
  }

  getSqlAggregate(
    resource: Resource,
    query: ListModelDescriptionQuery,
    yFunc: AggregateFunc,
    yColumn: string,
    parameters: ParameterField[] = [],
    params?: {},
    columns: RawListViewSettingsColumn[] = []
  ): Observable<number> {
    return this.checkApiInfo(resource).pipe(
      switchMap(() => {
        const apiInfo = resource ? resource.apiInfo : undefined;
        const supported = apiInfo && apiInfo.isCompatibleJetBridge({ jetBridge: '1.0.3', jetDjango: '1.1.8' });
        const aggregate = yFunc
          ? {
              func: yFunc,
              column: yColumn
            }
          : undefined;

        return this.getSql(resource, query, parameters, params, undefined, columns, false, aggregate).pipe(
          map(response => aggregateGetResponse(response, yFunc, yColumn, !supported))
        );
      })
    );
  }

  modelAggregate(
    resource: Resource,
    modelDescription: ModelDescription,
    yFunc: AggregateFunc,
    yColumn: string,
    params?: {}
  ): Observable<number> {
    if (modelDescription.aggregateQuery && modelDescription.aggregateQuery.simpleQuery && isSet(yFunc)) {
      return this.aggregateSimple(resource, modelDescription.model, yFunc, yColumn, params);
      // TODO if (modelDescription.aggregateQuery && modelDescription.aggregateQuery.sqlQuery)
    } else if (modelDescription.aggregateQuery && modelDescription.aggregateQuery.simpleQuery && !isSet(yFunc)) {
      const columns = modelDescription.fields
        .filter(item => item.type == ModelFieldType.Db)
        .map(item => modelFieldToRawListViewSettingsColumn(item));

      return this.getSimpleAggregate(resource, modelDescription.getQuery, yFunc, yColumn, params, columns);
    } else if (modelDescription.getQuery && modelDescription.getQuery.simpleQuery) {
      const columns = modelDescription.fields
        .filter(item => item.type == ModelFieldType.Db)
        .map(item => modelFieldToRawListViewSettingsColumn(item));

      return this.getSimpleAggregate(resource, modelDescription.getQuery, yFunc, yColumn, params, columns);
    } else if (modelDescription.getQuery && modelDescription.getQuery.sqlQuery) {
      const columns = modelDescription.fields
        .filter(item => item.type == ModelFieldType.Db)
        .map(item => modelFieldToRawListViewSettingsColumn(item));

      return this.getSqlAggregate(
        resource,
        modelDescription.getQuery,
        yFunc,
        yColumn,
        modelDescription.getParameters,
        params,
        columns
      );
    } else {
      return of(undefined);
    }
  }

  groupSimple(
    resource: Resource,
    model: string,
    xColumns: { xColumn: string; xLookup?: DatasetGroupLookup }[],
    yFunc: AggregateFunc,
    yColumn: string,
    params?: {}
  ): Observable<DataGroup[]> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.actionUrlForProjectResource(model, 'group', resource);
        const headers = this.getHeaders(resource);

        let httpParams = new HttpParams({
          fromObject: {
            ...(params || {}),
            ...(isSet(yFunc) ? { _y_func: yFunc } : {}),
            ...(isSet(yColumn) ? { _y_column: yColumn } : {}),
            tz: getTimezoneOffset()
          },
          encoder: this.parameterCodec
        });

        xColumns.forEach(item => {
          httpParams = httpParams.append('_x_column', item.xColumn);
          httpParams = httpParams.append('_x_lookup', item.xLookup || '');
        });

        return this.http.get<Object[]>(url, { headers: headers, params: httpParams, observe: 'response' });
      }),
      this.apiService.processApiResponse<Object[]>(),
      map(result => result.map(item => new DataGroup().deserialize(item))),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  getSimpleGroup(
    resource: Resource,
    query: ListModelDescriptionQuery,
    xColumns: { xColumn: string; xLookup?: DatasetGroupLookup }[],
    yFunc: AggregateFunc,
    yColumn: string,
    params?: {},
    columns: RawListViewSettingsColumn[] = []
  ): Observable<DataGroup[]> {
    return this.getSimple(resource, query, params, undefined, columns, false).pipe(
      map(response => groupGetResponse(response, xColumns, yFunc, yColumn))
    );
  }

  getSqlGroup(
    resource: Resource,
    query: ListModelDescriptionQuery,
    xColumns: { xColumn: string; xLookup?: DatasetGroupLookup }[],
    yFunc: AggregateFunc,
    yColumn: string,
    parameters: ParameterField[] = [],
    params?: {},
    columns: RawListViewSettingsColumn[] = []
  ): Observable<DataGroup[]> {
    return this.checkApiInfo(resource).pipe(
      switchMap(() => {
        const apiInfo = resource ? resource.apiInfo : undefined;
        const supported = apiInfo && apiInfo.isCompatibleJetBridge({ jetBridge: '1.0.3', jetDjango: '1.1.8' });
        const group = yFunc
          ? {
              xColumns: xColumns,
              yColumn: yColumn,
              yFunc: yFunc
            }
          : undefined;

        return this.getSql(resource, query, parameters, params, undefined, columns, false, undefined, group).pipe(
          map(response => groupGetResponse(response, xColumns, yFunc, yColumn, !supported))
        );
      })
    );
  }

  modelGroup(
    resource: Resource,
    modelDescription: ModelDescription,
    xColumns: { xColumn: string; xLookup?: DatasetGroupLookup }[],
    yFunc: AggregateFunc,
    yColumn: string,
    params?: {}
  ): Observable<DataGroup[]> {
    if (modelDescription.groupQuery && modelDescription.groupQuery.simpleQuery && isSet(yFunc)) {
      return this.groupSimple(resource, modelDescription.model, xColumns, yFunc, yColumn, params);
      // TODO if (modelDescription.aggregateQuery && modelDescription.aggregateQuery.sqlQuery)
    } else if (modelDescription.aggregateQuery && modelDescription.aggregateQuery.simpleQuery && !isSet(yFunc)) {
      const columns = modelDescription.fields
        .filter(item => item.type == ModelFieldType.Db)
        .map(item => modelFieldToRawListViewSettingsColumn(item));

      return this.getSimpleGroup(resource, modelDescription.getQuery, xColumns, yFunc, yColumn, params, columns);
    } else if (modelDescription.getQuery && modelDescription.getQuery.simpleQuery) {
      const columns = modelDescription.fields
        .filter(item => item.type == ModelFieldType.Db)
        .map(item => modelFieldToRawListViewSettingsColumn(item));

      return this.getSimpleGroup(resource, modelDescription.getQuery, xColumns, yFunc, yColumn, params, columns);
    } else if (modelDescription.getQuery && modelDescription.getQuery.sqlQuery) {
      const columns = modelDescription.fields
        .filter(item => item.type == ModelFieldType.Db)
        .map(item => modelFieldToRawListViewSettingsColumn(item));

      return this.getSqlGroup(
        resource,
        modelDescription.getQuery,
        xColumns,
        yFunc,
        yColumn,
        modelDescription.getParameters,
        params,
        columns
      );
    } else {
      return of(undefined);
    }
  }

  modelGetSiblings(
    resource: Resource,
    modelDescription: ModelDescription,
    id: string,
    params?: {},
    body?: {}
  ): Observable<ModelResponse.SiblingsResponse> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.detailActionUrlForProjectResource(
          modelDescription.model,
          id,
          'get_siblings',
          resource
        );
        let headers = this.getHeaders(resource);

        params = params || {};

        if (body) {
          headers = headers.set('X-HTTP-Method-Override', HttpMethod.GET);

          return this.http
            .request('post', url, {
              headers: headers,
              params: params,
              body: body,
              observe: 'response'
            })
            .pipe(
              this.apiService.processApiResponse(),
              map(result => result || {}),
              map(result => {
                const siblings = {};

                if (result['prev']) {
                  siblings['prev'] = this.createModel().deserialize(modelDescription.model, result['prev']);
                  siblings['prev'].setUp(modelDescription);
                  siblings['prev'].deserializeAttributes(modelDescription.dbFields);
                }

                if (result['next']) {
                  siblings['next'] = this.createModel().deserialize(modelDescription.model, result['next']);
                  siblings['next'].setUp(modelDescription);
                  siblings['next'].deserializeAttributes(modelDescription.dbFields);
                }

                return siblings;
              })
            );
        } else {
          return this.http.get(url, { headers: headers, params: params, observe: 'response' }).pipe(
            this.apiService.processApiResponse(),
            map(result => result || {}),
            map(result => {
              const siblings = {};

              if (result['prev']) {
                siblings['prev'] = this.createModel().deserialize(modelDescription.model, result['prev']);
                siblings['prev'].setUp(modelDescription);
                siblings['prev'].deserializeAttributes(modelDescription.dbFields);
              }

              if (result['next']) {
                siblings['next'] = this.createModel().deserialize(modelDescription.model, result['next']);
                siblings['next'].setUp(modelDescription);
                siblings['next'].deserializeAttributes(modelDescription.dbFields);
              }

              return siblings;
            })
          );
        }
      }),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  prepareSqlQueryData(resource: Resource, query: string, options: SqlQueryOptions = {}) {
    const newQuery =
      options.version &&
      options.version >= 2 &&
      resource.apiInfo &&
      resource.apiInfo.isCompatibleJetBridge({ jetBridge: '1.0.0', jetDjango: '1.1.5' }) &&
      String(query).indexOf('-- @JET_QUERY_VERSION=1') == -1;

    if (newQuery) {
      const sql = this.sqlQueryService.applyTokensSql(query, options.tokens || {}, true);
      const multipleGroupsSupported = resource.apiInfo.isCompatibleJetBridge({
        jetBridge: '1.0.9',
        jetDjango: '1.2.4'
      });

      if (!sql) {
        return {};
      }

      return {
        query: sql.query,
        offset: options.offset,
        limit: options.limit,
        order_by: options.orderBy,
        count: options.count,
        columns: options.columns,
        filters: options.filters,
        aggregate: options.aggregate,
        ...(multipleGroupsSupported
          ? {
              groups: options.groups,
              timezone: getTimezoneOffset()
            }
          : {
              group:
                options.groups && options.groups.xColumns.length
                  ? {
                      xColumn: options.groups.xColumns[0].xColumn,
                      xLookup: options.groups.xColumns[0].xLookup,
                      yFunc: options.groups.yFunc,
                      yColumn: options.groups.yColumn
                    }
                  : undefined,
              timezone: getTimezoneOffset()
            }),
        ...(isSet(resource.params['schema']) ? { schema: resource.params['schema'] } : {}),
        params_obj: sql.params,
        v: options.version
      };
    } else {
      query = this.queryService.applyTokens(query, options.tokens, true);

      const sql = interpolateSqlContext(query, options.tokens || {});

      if (!sql) {
        return {};
      }

      return {
        query: sql.str,
        params: sql.params.length ? sql.params.join(',') : undefined
      };
    }
  }

  sql(resource: Resource, query: string, options: SqlQueryOptions = {}): Observable<ModelResponse.SqlResponse> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => this.checkApiInfo(resource)),
      switchMap(() => {
        const url = this.apiService.methodURLForProjectResource('sql/', resource);
        const headers = this.getHeaders(resource);
        const data = this.prepareSqlQueryData(resource, query, options);

        return this.http.post<any[] | Object>(url, data, { headers: headers, observe: 'response' });
      }),
      this.apiService.processApiResponse<any[] | Object>(),
      map(result => {
        if (result instanceof Array) {
          return new ModelResponse.SqlResponse().deserialize({
            data: result
          });
        }

        return new ModelResponse.SqlResponse().deserialize(result);
      }),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  sqls(
    resource: Resource,
    queries: { query: string; options?: SqlQueryOptions }[]
  ): Observable<ModelResponse.SqlResponse[]> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => this.checkApiInfo(resource)),
      switchMap(() => {
        const url = this.apiService.methodURLForProjectResource('sql/', resource);
        const headers = this.getHeaders(resource);
        const data = {
          queries: queries.map(item => this.prepareSqlQueryData(resource, item.query, item.options))
        };

        return this.http.post<Object[]>(url, data, { headers: headers, observe: 'response' });
      }),
      this.apiService.processApiResponse<Object[]>(),
      map(result => {
        return result.map(item => {
          if (item instanceof Array) {
            return new ModelResponse.SqlResponse().deserialize({
              data: item
            });
          }

          return new ModelResponse.SqlResponse().deserialize(item);
        });
      }),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  uploadFile(
    resource: Resource,
    storage: Storage,
    query: StorageQuery,
    file: File,
    path?: string,
    fileName?: string
  ): Observable<ModelResponse.UploadFileResponse> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.methodURLForProjectResource('file_upload/', resource);
        const headers = this.getHeaders(resource);
        const data = new FormData();

        data.append('file', file);
        data.append('path', path);

        if (isSet(fileName)) {
          data.append('filename', fileName);
        } else if (isSet(file.name)) {
          data.append('filename', file.name);
        }

        return this.http.post(url, data, { headers: headers, observe: 'events', reportProgress: true });
      }),
      switchMap(event => {
        if (event.type == HttpEventType.Response) {
          return of(event).pipe(
            this.apiService.processApiResponse(),
            map<Object, ModelResponse.UploadFileResponse>(result => {
              return {
                result: {
                  uploadedPath: result['uploaded_path'],
                  uploadedUrl: result['uploaded_url']
                },
                state: {
                  downloadProgress: 1,
                  uploadProgress: 1
                }
              };
            })
          );
        } else if (event.type == HttpEventType.UploadProgress) {
          return of({
            state: {
              uploadProgress: event.loaded / event.total,
              downloadProgress: 0,
              uploadLoaded: event.loaded,
              uploadTotal: event.total
            }
          });
        } else if (event.type == HttpEventType.DownloadProgress) {
          return of({
            state: {
              uploadProgress: 1,
              downloadProgress: event.loaded / event.total,
              downloadLoaded: event.loaded,
              downloadTotal: event.total
            }
          });
        } else {
          return EMPTY;
        }
      }),
      this.apiService.catchApiError() as any
    );
  }

  fileUrl(resource: Resource, value: any): string {
    if (isAbsoluteUrl(value)) {
      return value;
    } else if (resource && resource.mediaUrlTemplate) {
      return resource.mediaUrl(value);
    } else if (resource.params['url']) {
      const base = resource.params['url'].replace(/(api|jet_api)\//, '');
      return `${base}media/${value}`;
    } else {
      return value;
    }
  }

  actionExecuteSql(
    resource: Resource,
    query: ActionQuery,
    parameters: ParameterField[] = [],
    params?: ActionExecuteParams,
    rawErrors?: boolean
  ): Observable<ActionResponse> {
    if (!query || !query.sqlQuery) {
      return of(undefined);
    }

    const userQuery = isResourceQueryCustom(resource, query.queryType);
    const tokens = this.queryTokensService.mergeTokens(
      this.queryTokensService.generalTokens(),
      this.queryTokensService.actionExecuteTokens(params, parameters, userQuery)
    );

    return this.sql(resource, query.sqlQuery.query, { tokens: tokens, version: query.sqlQuery.version }).pipe(
      map(result => {
        return {
          json: result.toObject()
        };
      }),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  reload(resource: Resource): Observable<boolean> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.methodURLForProjectResource('reload/', resource);
        const headers = this.getHeaders(resource);

        return this.http.post<Object>(url, {}, { headers: headers, observe: 'response' });
      }),
      this.apiService.processApiResponse<Object[]>(),
      map(result => {
        return result['result'];
      }),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }
}
