import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import fromPairs from 'lodash/fromPairs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { merge, Observable, of, Subject } from 'rxjs';
import { delayWhen, filter, map, switchMap, tap } from 'rxjs/operators';

import { NotificationService } from '@common/notifications';
import { PopoverService } from '@common/popover';
import { ViewSettingsAction } from '@modules/actions';
import { UniversalAnalyticsService } from '@modules/analytics';
import { ServerRequestError } from '@modules/api';
import { AggregateFunc } from '@modules/charts';
import {
  CustomView,
  CustomViewData,
  CustomViewDataView,
  customViewInstanceToData,
  CustomViewService,
  CustomViewSource,
  CustomViewsStore,
  CustomViewType
} from '@modules/custom-views';
import { ElementItem, ViewContext, ViewContextElement } from '@modules/customize';
import {
  AggregateDisplayField,
  ComputedDisplayField,
  CustomViewDisplayField,
  DisplayFieldType,
  FieldType,
  LookupDisplayField
} from '@modules/fields';
import { ModelOptionSelectedEvent } from '@modules/filters-components';
import { IDEController, IDEService } from '@modules/ide-components';
import { ModelService } from '@modules/model-queries';
import { ModelDescription } from '@modules/models';
import { ViewContextTokenProvider } from '@modules/parameters-components';
import { CurrentEnvironmentStore, CurrentProjectStore, Resource } from '@modules/projects';
import { QueryType } from '@modules/queries';
import { CustomViewTemplateType, Frame, View, ViewMapping } from '@modules/views';
import { AutofocusDirective, controlValue, errorToString, generateAlphanumeric, isSet, TypedChanges } from '@shared';

// TODO: Refactor import
import { CustomViewMapParametersController } from '../../../custom-elements-components/services/custom-view-map-parameters-controller/custom-view-map-parameters.controller';
import { CustomViewTemplatesController } from '../../../views-components/services/custom-view-templates-controller/custom-view-templates.controller';
import { ViewEditorController } from '../../../views-components/services/view-editor-controller/view-editor.controller';

import { CustomViewDefaults } from '../../data/custom-view-defaults';
import { CustomizeBarEditEventType } from '../../data/customize-bar-edit-event-type';
import { CustomizeBarContext } from '../../services/customize-bar-context/customize-bar.context';
import { CustomizeBarService } from '../../services/customize-bar/customize-bar.service';
import { DisplayFieldArray } from './display-field.array';
import { DisplayFieldControl } from './display-field.control';
import { FieldActionsArray } from './field-actions.array';

@Component({
  selector: 'app-display-fields-edit',
  templateUrl: './display-fields-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DisplayFieldsEditComponent implements OnInit, OnDestroy, OnChanges {
  @Input() form: DisplayFieldArray;
  @Input() fieldActionsControl: FieldActionsArray;
  @Input() resource: Resource;
  @Input() modelDescription: ModelDescription;
  @Input() itemName = 'field';
  @Input() componentName = 'component';
  @Input() element: ElementItem;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() contextElementPath: (string | number)[];
  @Input() contextElementPaths: (string | number)[][];
  @Input() collapsible = true;
  @Input() searchEnabled = false;
  @Input() searchFocus = false;
  @Input() visibleEditable = true;
  @Input() customViewEnabled = false;
  @Input() customViewStateSelectedEnabled = false;
  @Input() actionsLabels: {
    title?: string;
    emptyAction?: string;
    actionLabel?: string;
  };
  @Input() firstInit = false;
  @Input() analyticsSource: string;
  @Output() searchCleared = new EventEmitter<void>();

  @ViewChild('search_autofocus', { read: AutofocusDirective }) searchAutoFocus: AutofocusDirective;

  isLookupsSupported = false;
  displayItems: DisplayFieldControl[] = [];
  maxDisplayInitial = 8;
  collapsed = true;
  search = '';
  searchUpdated = new Subject<string>();
  submitLoading = false;
  submitControlLoading: DisplayFieldControl;
  customViewsShared: CustomView[] = [];
  displayFieldTypes = DisplayFieldType;
  sources = CustomViewSource;

  constructor(
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private customizeBarService: CustomizeBarService,
    private customizeBarContext: CustomizeBarContext,
    private modelService: ModelService,
    private viewEditorController: ViewEditorController,
    private ideService: IDEService,
    private ideController: IDEController,
    private customViewService: CustomViewService,
    private customViewsStore: CustomViewsStore,
    private customViewTemplatesController: CustomViewTemplatesController,
    private customViewMapParametersController: CustomViewMapParametersController,
    private contextTokenProvider: ViewContextTokenProvider,
    private notificationService: NotificationService,
    private analyticsService: UniversalAnalyticsService,
    private popoverService: PopoverService,
    private injector: Injector,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    merge(controlValue(this.form), this.searchUpdated)
      .pipe(untilDestroyed(this))
      .subscribe(() => this.updateDisplayItems());

    this.customViewsStore
      .get()
      .pipe(untilDestroyed(this))
      .subscribe(customViews => {
        this.customViewsShared = customViews.filter(item => item.shared);
        this.cd.markForCheck();
      });
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<DisplayFieldsEditComponent>): void {
    if (changes.resource || changes.modelDescription) {
      this.isLookupsSupported =
        this.modelService.isGetAdvSupported(this.resource, this.modelDescription) &&
        this.modelDescription &&
        this.modelDescription.queryType != QueryType.SQL;
    }

    if (changes.searchEnabled && !this.searchEnabled) {
      this.clearSearch();
    }
  }

  dragDrop(event: CdkDragDrop<DisplayFieldControl[]>) {
    if (event.previousIndex !== event.currentIndex) {
      moveItemInArray(this.form.controls, event.previousIndex, event.currentIndex);
      this.form.updateValueAndValidity();
    }
  }

  getDistinctName(baseName: string, template = (n, i) => `${n}_${i}`, startIndex = 1) {
    const names = this.form.controls.map(item => {
      const value = item.controls.name.value;
      return isSet(value) ? value : '';
    });
    let name: string;
    let index = startIndex;

    do {
      name = template(baseName, index);
      ++index;
    } while (names.find(item => item.toLowerCase() == name.toLowerCase()));

    return name;
  }

  getCustomViewDefaults(): CustomViewDefaults {
    return {
      uniqueName: CustomView.generateUniqueName(),
      pageUid: this.context && this.context.viewSettings ? this.context.viewSettings.uid : undefined,
      elementUid: this.element.uid
    };
  }

  customize(control: DisplayFieldControl) {
    if (!control.controls.visible.value) {
      control.controls.visible.patchValue(true);
    }

    const column = control.serialize();
    const initialElement = cloneDeep(control.serialize());
    const valueEditable = control.instance && control.instance.type == DisplayFieldType.Computed;
    const lookupEditable = control.instance && control.instance.type == DisplayFieldType.Lookup;
    const aggregateEditable = control.instance && control.instance.type == DisplayFieldType.Aggregate;
    const actionsValue = this.fieldActionsControl
      ? this.fieldActionsControl.getColumnActions(control.controls.name.value)
      : [];

    this.customizeBarService
      .customizeColumn({
        context: this.customizeBarContext,
        column: column,
        modelDescription: this.modelDescription,
        actions: actionsValue,
        configurable: {
          verboseName: true,
          value: valueEditable,
          lookup: lookupEditable,
          aggregate: aggregateEditable,
          action: !!this.fieldActionsControl
        },
        viewContext: this.context,
        viewContextElement: this.contextElement,
        viewContextElementPath: this.contextElementPath,
        viewContextElementPaths: this.contextElementPaths,
        actionsLabels: this.actionsLabels,
        append: true,
        firstInit: this.firstInit
      })
      .pipe(untilDestroyed(this))
      .subscribe(e => {
        if (e.type == CustomizeBarEditEventType.Updated) {
          control.deserialize(e.args['result']);
          control.markAsDirty();

          if (this.fieldActionsControl) {
            const actionsControl = this.fieldActionsControl.getColumnControl(control.controls.name.value);
            const actions = (e.args['actions'] as ViewSettingsAction[]) || [];

            if (actionsControl) {
              actionsControl.controls.actions.setValue(actions);
            } else {
              this.fieldActionsControl.appendControl({
                name: control.controls.name.value,
                actions: actions
              });
            }
          }
        } else if (e.type == CustomizeBarEditEventType.Canceled) {
          control.deserialize(initialElement);
          control.markAsDirty();
        }
      });

    this.clearSearch();
  }

  getControlUniqueName() {
    return `_jet_${generateAlphanumeric(4)}`;
  }

  getControlUniqueField(
    fieldValueGetter: (control: DisplayFieldControl) => any,
    prefix: string,
    separator = '_',
    skipFirst = false
  ) {
    let nextNumber = 1;
    let result: string;

    do {
      result = skipFirst && nextNumber == 1 ? prefix : `${prefix}${separator}${nextNumber}`;
      ++nextNumber;
    } while (this.form.controls.find(group => fieldValueGetter(group) == result));

    return result;
  }

  addComputedItem(type: string = FieldType.Text) {
    const uniqueName = this.getControlUniqueName();
    const defaultVerboseName = 'Computed Field';
    const verboseName = this.getControlUniqueField(
      item => item.controls.verboseName.value,
      defaultVerboseName,
      ' ',
      true
    );
    const instance = new ComputedDisplayField({
      name: uniqueName,
      verboseName: verboseName,
      field: type as FieldType,
      visible: true
    });

    const control = this.form.appendControl(instance);

    this.customize(control);
    this.clearSearch();
  }

  createView(): View {
    const result = new View();

    result.generateId();
    result.frame = new Frame({ width: 110, height: 40 });

    return result;
  }

  updateOrCreateCustomView(options: {
    source: CustomViewSource;
    uniqueName?: string;
    customView?: CustomView;
    columnUniqueName?: string;
    beforeSave?: (customView: CustomView, fields: string[]) => void;
  }): Observable<CustomView> {
    const existingInstance = options.customView;
    const instance = existingInstance || new CustomView();
    const defaults = this.getCustomViewDefaults();

    if (isSet(options.uniqueName)) {
      instance.uniqueName = options.uniqueName;
    } else {
      instance.uniqueName = defaults.uniqueName;
    }

    instance.viewType = CustomViewType.ItemColumn;
    instance.source = options.source;
    instance.pageUid = defaults.pageUid;
    instance.elementUid = defaults.elementUid;
    instance.columnUniqueName = options.columnUniqueName;

    if (!existingInstance && options.source == CustomViewSource.CustomElement) {
      instance.filesIde = true;
    }

    const fields = ['unique_name', 'view_type', 'source', 'params'];

    if (options.beforeSave) {
      options.beforeSave(instance, fields);
    }

    const obs$ = existingInstance
      ? this.customViewService.update(
          this.currentProjectStore.instance.uniqueName,
          this.currentEnvironmentStore.instance.uniqueName,
          instance,
          { draft: true, fields: fields }
        )
      : this.customViewService.create(
          this.currentProjectStore.instance.uniqueName,
          this.currentEnvironmentStore.instance.uniqueName,
          instance,
          { draft: true, fields: fields }
        );

    return obs$.pipe(tap(customView => this.customViewsStore.updateOrAddItem(customView)));
  }

  addCustomViewField(options: { data?: CustomViewData; useShared?: CustomView; mapping?: ViewMapping[] } = {}) {
    const columnUniqueName = this.getControlUniqueName();
    const customView$ = options.useShared
      ? of(options.useShared)
      : this.updateOrCreateCustomView({
          source: CustomViewSource.View,
          columnUniqueName: columnUniqueName,
          beforeSave: (instance, fields) => {
            instance.name = options.data.name;
            instance.view = options.data.view;
            instance.parameters = options.data.parameters;
            instance.actions = options.data.actions;
            instance.testParameters = options.data.testParameters;

            fields.push('name', 'view');
          }
        });

    this.submitLoading = true;
    this.cd.markForCheck();

    customView$.pipe(untilDestroyed(this)).subscribe(
      customView => {
        this.submitLoading = false;
        this.cd.markForCheck();

        if (!customView) {
          return;
        }

        const instance = new CustomViewDisplayField({
          name: columnUniqueName,
          verboseName: isSet(customView.name) ? customView.name : 'Custom Field',
          visible: true
        });

        instance.customView = customView.uniqueName;

        if (options.mapping) {
          instance.customViewMappings = options.mapping;
        }

        const control = this.form.appendControl(instance);

        if (!options.mapping) {
          this.changeMapping(control, customView);
        }

        this.clearSearch();
      },
      error => {
        this.submitLoading = false;
        this.cd.markForCheck();

        if (error instanceof ServerRequestError && error.errors.length) {
          this.notificationService.error('Error', error.errors[0]);
        } else {
          this.notificationService.error('Error', errorToString(error));
        }
      }
    );
  }

  updateCustomViewField(
    control: DisplayFieldControl,
    options: {
      data?: CustomViewData;
      useShared?: CustomView;
      mapping?: ViewMapping[];
      updateName?: boolean;
      customViewUniqueName?: string;
    } = {}
  ) {
    const uniqueName = isSet(options.customViewUniqueName)
      ? options.customViewUniqueName
      : control.controls.customViewUniqueName.value;
    const columnUniqueName = control.instance ? control.instance.name : uniqueName;

    this.submitControlLoading = control;
    this.cd.markForCheck();

    const customView$ = options.useShared
      ? of(options.useShared)
      : this.updateOrCreateCustomView({
          source: CustomViewSource.View,
          uniqueName: uniqueName,
          columnUniqueName: columnUniqueName,
          beforeSave: (instance, fields) => {
            instance.name = options.data.name;
            instance.view = options.data.view;
            instance.parameters = options.data.parameters;
            instance.actions = options.data.actions;
            instance.testParameters = options.data.testParameters;

            fields.push('name', 'view');
          }
        });

    customView$.pipe(untilDestroyed(this)).subscribe(
      customView => {
        this.submitControlLoading = undefined;
        this.cd.markForCheck();

        if (!customView) {
          return;
        }

        control.controls.customViewUniqueName.patchValue(customView.uniqueName);

        if (options.mapping) {
          control.controls.customViewMappings.patchValue(options.mapping);
        } else {
          this.changeMapping(control, customView);
        }

        if (options.updateName && isSet(customView.name)) {
          control.controls.verboseName.patchValue(customView.name);
        }
      },
      error => {
        this.submitControlLoading = undefined;
        this.cd.markForCheck();

        if (error instanceof ServerRequestError && error.errors.length) {
          this.notificationService.error('Error', error.errors[0]);
        } else {
          this.notificationService.error('Error', errorToString(error));
        }
      }
    );
  }

  openViewEditor(control?: DisplayFieldControl, customView?: CustomView) {
    let view = customView ? customView.view : undefined;
    const create = !view;

    if (!view) {
      view = this.createView();
    }

    const data$ = customView
      ? of<CustomViewDataView>({
          source: CustomViewSource.View,
          view: view,
          name: customView.name,
          parameters: customView.parameters,
          actions: customView.actions,
          testParameters: customView.testParameters
        })
      : this.customViewsStore.generateName('Custom Field').pipe(
          map<string, CustomViewDataView>(name => ({
            source: CustomViewSource.View,
            view: view,
            name: name
          }))
        );

    data$
      .pipe(
        switchMap(data => {
          const sourceParameters = this.form.getParameters();

          return this.viewEditorController.open({
            create: create,
            data: {
              ...data,
              ...(sourceParameters && {
                parameters: sourceParameters,
                testParameters: fromPairs(
                  sourceParameters
                    .map(parameter => {
                      const testValue = this.contextElement.getFieldValue(parameter.name);
                      return [parameter.name, testValue];
                    })
                    .filter(item => isSet(item))
                )
              })
            },
            componentLabel: this.componentName,
            submitLabel: create ? 'Create cell' : 'Save changes',
            stateSelectedEnabled: this.customViewStateSelectedEnabled,
            analyticsSource: this.analyticsSource
          });
        }),
        filter(result => !result.cancelled),
        untilDestroyed(this)
      )
      .subscribe(result => {
        if (create) {
          this.addCustomViewField({ data: result.data, useShared: result.useShared });
        } else {
          this.updateCustomViewField(control, {
            data: result.data,
            useShared: result.useShared,
            updateName: true,
            customViewUniqueName: result.useShared ? result.useShared.uniqueName : undefined
          });
        }
      });
  }

  openIDE(control?: DisplayFieldControl, customView?: CustomView) {
    if (customView) {
      this.submitLoading = true;
      this.cd.markForCheck();

      return this.ideController
        .open({
          projectName: this.currentProjectStore.instance.uniqueName,
          environmentName: this.currentEnvironmentStore.instance.uniqueName,
          customViewName: customView.uniqueName
        })
        .pipe(untilDestroyed(this))
        .subscribe(
          () => {
            this.submitLoading = false;
            this.cd.markForCheck();
          },
          error => {
            console.error(error);
            this.submitLoading = false;
            this.cd.markForCheck();
          }
        );
    } else {
      const columnUniqueName = this.getControlUniqueName();

      this.submitLoading = true;
      this.cd.markForCheck();

      this.customViewsStore
        .generateName('Custom Field')
        .pipe(
          switchMap(name => {
            return this.updateOrCreateCustomView({
              source: CustomViewSource.CustomElement,
              uniqueName: customView ? customView.uniqueName : undefined,
              customView: customView,
              columnUniqueName: columnUniqueName,
              beforeSave: (instance, fields) => {
                instance.name = name;

                fields.push('name', 'view');
              }
            });
          }),
          tap(result => {
            const defaultVerboseName = result.name || 'Custom Field';
            const verboseName = this.getControlUniqueField(
              item => item.controls.verboseName.value,
              defaultVerboseName,
              ' ',
              true
            );

            const instance = new CustomViewDisplayField({
              name: columnUniqueName,
              verboseName: verboseName,
              visible: true
            });

            instance.customView = result.uniqueName;

            this.form.appendControl(instance);

            this.clearSearch();
          }),
          switchMap(result => {
            return this.ideController.open({
              projectName: this.currentProjectStore.instance.uniqueName,
              environmentName: this.currentEnvironmentStore.instance.uniqueName,
              customViewName: result.uniqueName
            });
          }),
          untilDestroyed(this)
        )
        .subscribe(
          () => {
            this.submitLoading = false;
            this.cd.markForCheck();
          },
          error => {
            console.error(error);
            this.submitLoading = false;
            this.cd.markForCheck();
          }
        );
    }
  }

  openCustomViewTemplates(control?: DisplayFieldControl) {
    const create = !control;

    this.customViewTemplatesController
      .chooseTemplate({
        initialFilter: { type: CustomViewTemplateType.ItemColumn },
        nameEditingEnabled: create,
        viewCreateEnabled: true,
        stateSelectedEnabled: this.customViewStateSelectedEnabled,
        componentLabel: this.componentName,
        analyticsSource: this.analyticsSource
      })
      .pipe(
        filter(result => !result.cancelled),
        untilDestroyed(this)
      )
      .subscribe(viewResult => {
        const sourceParameters = this.form.getParameters();

        this.customViewMapParametersController
          .open({
            sourceParameters: sourceParameters,
            data: viewResult.data,
            context: this.context,
            contextElement: this.contextElement,
            contextElementPath: this.contextElementPath,
            contextElementPaths: this.contextElementPaths,
            contextTokenProvider: this.contextTokenProvider,
            analyticsSource: this.analyticsSource
          })
          .pipe(
            filter(mappingResult => !mappingResult.cancelled),
            untilDestroyed(this)
          )
          .subscribe(mappingResult => {
            if (create) {
              this.addCustomViewField({
                data: viewResult.data,
                mapping: mappingResult.mappings,
                useShared: viewResult.useShared
              });
            } else {
              this.updateCustomViewField(control, {
                data: viewResult.data,
                useShared: viewResult.useShared,
                mapping: mappingResult.mappings,
                updateName: true,
                customViewUniqueName: viewResult.useShared ? viewResult.useShared.uniqueName : undefined
              });
            }
          });
      });
  }

  changeMapping(control: DisplayFieldControl, customView: CustomView) {
    const mappings: ViewMapping[] = control.controls.customViewMappings.value;
    const sourceParameters = this.form.getParameters();

    if (!sourceParameters.length) {
      return;
    }

    return this.customViewMapParametersController
      .open({
        sourceParameters: sourceParameters,
        data: customViewInstanceToData(customView),
        mappings: mappings,
        context: this.context,
        contextElement: this.contextElement,
        contextElementPath: this.contextElementPath,
        contextElementPaths: this.contextElementPaths,
        contextTokenProvider: this.contextTokenProvider,
        analyticsSource: this.analyticsSource
      })
      .pipe(
        filter(mappingResult => !mappingResult.cancelled),
        untilDestroyed(this)
      )
      .subscribe(mappingResult => {
        control.controls.customViewMappings.patchValue(mappingResult.mappings);
      });
  }

  updateCustomViewTemplate(control: DisplayFieldControl, view: View) {
    this.customViewTemplatesController
      .setTemplateView(view, {
        stateSelectedEnabled: this.customViewStateSelectedEnabled,
        componentLabel: this.componentName
      })
      .pipe(
        filter(result => !result.cancelled),
        untilDestroyed(this)
      )
      .subscribe();
  }

  renameCustomView(control: DisplayFieldControl, name: string) {
    control.controls.verboseName.patchValue(name);
  }

  cloneCustomView(customView: CustomView, options: { shared?: boolean }) {
    const instance = new CustomView();

    instance.uniqueName = CustomView.generateUniqueName();
    instance.name = customView.name;
    instance.viewType = CustomViewType.ItemColumn;
    instance.source = customView.source;
    instance.parameters = cloneDeep(customView.parameters);
    instance.actions = cloneDeep(customView.actions);
    instance.testParameters = cloneDeep(customView.testParameters);
    instance.shared = options.shared;

    if (customView.source == CustomViewSource.View) {
      instance.view = customView.view;
    } else if (customView.source == CustomViewSource.HTML) {
      instance.html = customView.html;
    } else if (customView.source == CustomViewSource.CustomElement) {
      instance.buildId = customView.buildId;
      instance.tagName = customView.tagName;
      instance.baseTagName = customView.baseTagName;
      instance.filesJs = cloneDeep(customView.filesJs);
      instance.filesCss = cloneDeep(customView.filesCss);
    }

    return this.customViewService
      .create(
        this.currentProjectStore.instance.uniqueName,
        this.currentEnvironmentStore.instance.uniqueName,
        instance,
        { draft: true }
      )
      .pipe(
        delayWhen(result => {
          if (customView.source == CustomViewSource.CustomElement) {
            return this.ideService.clone(
              this.currentProjectStore.instance.uniqueName,
              this.currentEnvironmentStore.instance.uniqueName,
              customView.uniqueName,
              result.uniqueName
            );
          } else {
            return of(undefined);
          }
        }),
        tap(result => this.customViewsStore.addItem(result))
      );
  }

  createCustomViewShared(control: DisplayFieldControl, customView: CustomView) {
    this.submitControlLoading = control;
    this.cd.markForCheck();

    this.cloneCustomView(customView, { shared: true })
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          this.submitControlLoading = undefined;
          this.cd.markForCheck();

          control.controls.customViewUniqueName.patchValue(result.uniqueName);
        },
        () => {
          this.submitControlLoading = undefined;
          this.cd.markForCheck();
        }
      );
  }

  detachCustomViewShared(control: DisplayFieldControl, customView: CustomView) {
    this.submitControlLoading = control;
    this.cd.markForCheck();

    this.cloneCustomView(customView, { shared: false })
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          this.submitControlLoading = undefined;
          this.cd.markForCheck();

          control.controls.customViewUniqueName.patchValue(result.uniqueName);
        },
        () => {
          this.submitControlLoading = undefined;
          this.cd.markForCheck();
        }
      );
  }

  onSelectCustomView(control: DisplayFieldControl, customView?: CustomView) {
    const currentUniqueName = control.controls.customViewUniqueName.value;
    const newUniqueName = customView ? customView.uniqueName : undefined;

    control.controls.customViewUniqueName.patchValue(customView.uniqueName);

    if (isSet(newUniqueName) && newUniqueName != currentUniqueName) {
      this.changeMapping(control, customView);
    }
  }

  addLookupItem(e: ModelOptionSelectedEvent) {
    if (!e.field) {
      return;
    }

    const uniqueName = this.getControlUniqueName();
    const defaultVerboseName = e.path.map(item => item.verboseName).join(' ');
    const verboseName = this.getControlUniqueField(
      item => item.controls.verboseName.value,
      defaultVerboseName,
      ' ',
      true
    );
    const instance = new LookupDisplayField({
      name: uniqueName,
      verboseName: verboseName,
      ...(e.field && {
        field: e.field.field,
        params: e.field.params
      }),
      visible: true,
      path: e.path.map(item => item.name)
    });

    const control = this.form.appendControl(instance);

    this.customize(control);
    this.clearSearch();
  }

  addAggregateItem(e: ModelOptionSelectedEvent) {
    if (!e.relation) {
      return;
    }

    const uniqueName = this.getControlUniqueName();
    const defaultVerboseName = `${e.path.map(item => item.verboseName).join(' ')} ${
      e.aggregation ? e.aggregation.func.toLowerCase() : 'Count'
    }`;
    const verboseName = this.getControlUniqueField(
      item => item.controls.verboseName.value,
      defaultVerboseName,
      ' ',
      true
    );
    const instance = new AggregateDisplayField({
      name: uniqueName,
      verboseName: verboseName,
      ...(e.field && {
        field: e.field.field,
        params: e.field.params
      }),
      ...(e.relation && {
        field: FieldType.Number
      }),
      path: e.path.map(item => item.name),
      ...(e.aggregation
        ? {
            func: e.aggregation.func,
            column: e.aggregation.field
          }
        : { func: AggregateFunc.Count, column: undefined }),
      visible: true
    });

    const control = this.form.appendControl(instance);

    this.customize(control);
    this.clearSearch();
  }

  updateDisplayItems() {
    const processSearch = str => (str || '').trim().toLowerCase();
    const search = processSearch(this.search);

    if (isSet(search)) {
      this.displayItems = this.form.controls.filter(item => {
        return (
          processSearch(item.controls.verboseName.value).indexOf(search) !== -1 ||
          processSearch(item.controls.name.value).indexOf(search) !== -1
        );
      });
    } else if (this.collapsible && this.collapsed) {
      this.displayItems = this.form.controls.slice(0, this.maxDisplayInitial);
    } else {
      this.displayItems = this.form.controls;
    }

    this.cd.markForCheck();
  }

  setCollapsed(value: boolean) {
    this.collapsed = value;
    this.cd.markForCheck();
    this.updateDisplayItems();
  }

  public isToggledAll(): boolean {
    return this.form.isToggledAll();
  }

  public toggleAll() {
    this.form.toggleAll();
  }

  public isEmpty() {
    return this.displayItems.length == 0;
  }

  public hasMultipleItems() {
    return this.displayItems.length > 1;
  }

  clearSearch() {
    if (!isSet(this.search)) {
      return;
    }

    this.search = '';
    this.searchUpdated.next();
    this.cd.markForCheck();
    this.searchCleared.emit();
  }

  onSearchBlur() {
    if (!isSet(this.search)) {
      this.searchCleared.emit();
    }
  }

  focusSearch() {
    if (this.searchAutoFocus) {
      this.searchAutoFocus.focus();
    }
  }
}
