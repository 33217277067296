import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { AdminMode, ROUTE_ADMIN_MODE$ } from '@modules/admin-mode';
import { CurrentEnvironmentStore } from '@modules/projects';
import { isSet, SingletonStore } from '@shared';

import { CurrentProjectStore } from '../../projects/stores/current-project.store';
import { CustomView, CustomViewType } from '../data/custom-view';
import { CustomViewService } from '../services/custom-view/custom-view.service';

@Injectable()
export class CustomViewsStore extends SingletonStore<CustomView[]> {
  constructor(
    @Inject(ROUTE_ADMIN_MODE$) private mode$: Observable<AdminMode>,
    private customViewService: CustomViewService,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore
  ) {
    super();
  }

  protected fetch(): Observable<CustomView[]> {
    return this.mode$.pipe(
      switchMap(mode =>
        this.currentProjectStore.getFirst().pipe(
          filter(project => project != undefined),
          switchMap(project =>
            this.customViewService.get(
              this.currentProjectStore.instance.uniqueName,
              this.currentEnvironmentStore.instance.uniqueName,
              {},
              mode == AdminMode.Builder
            )
          ),
          map(result => {
            if (!result) {
              return [];
            }

            return result;
          })
        )
      )
    );
  }

  get(forceUpdate: boolean = false, options: { includeDeleted?: boolean } = {}): Observable<CustomView[]> {
    return super.get(forceUpdate, options).pipe(
      map(result => {
        if (!result) {
          return result;
        }

        return result.filter(item => options.includeDeleted || !item.deleted);
      })
    );
  }

  getFirst(forceUpdate: boolean = false, options: { includeDeleted?: boolean } = {}): Observable<CustomView[]> {
    return super.getFirst(forceUpdate, options).pipe(
      map(result => {
        if (!result) {
          return result;
        }

        return result.filter(item => options.includeDeleted || !item.deleted);
      })
    );
  }

  public getDetail(uniqueName: string, forceUpdate = false, options: { includeDeleted?: boolean } = {}) {
    return this.get(forceUpdate).pipe(
      map(result => {
        if (!result) {
          return;
        }

        return result.find(item => item.uniqueName == uniqueName);
      })
    );
  }

  public getCommon(forceUpdate = false, options: { includeDeleted?: boolean } = {}) {
    return this.get(forceUpdate, options).pipe(
      map(result => {
        if (!result) {
          return [];
        }

        return result.filter(item => item.viewType == CustomViewType.Common);
      })
    );
  }

  public getModelDescription(
    modelDescription: string,
    forceUpdate = false,
    options: { includeDeleted?: boolean } = {}
  ) {
    return this.get(forceUpdate, options).pipe(
      map(result => {
        if (!result) {
          return [];
        }

        return result.filter(
          item =>
            item.viewType == CustomViewType.ModelDescription &&
            (!item.params['model_description'] || item.params['model_description'] == modelDescription)
        );
      })
    );
  }

  public getModel(modelDescription: string, forceUpdate = false, options: { includeDeleted?: boolean } = {}) {
    return this.get(forceUpdate, options).pipe(
      map(result => {
        if (!result) {
          return [];
        }

        return result.filter(
          item =>
            item.viewType == CustomViewType.Model &&
            (!item.params['model_description'] || item.params['model_description'] == modelDescription)
        );
      })
    );
  }

  public getDetailFirst(
    uniqueName: string,
    forceUpdate = false,
    options: { includeDeleted?: boolean } = {}
  ): Observable<CustomView> {
    return this.getFirst(forceUpdate, options).pipe(
      map(result => {
        if (!result) {
          return;
        }

        return result.find(item => item.uniqueName == uniqueName);
      })
    );
  }

  public addItem(customView: CustomView) {
    this.instance = [...this.instance, customView];
  }

  public updateItem(customView: CustomView) {
    const index = this.instance.findIndex(item => item.uniqueName == customView.uniqueName);

    if (index !== -1) {
      this.instance = [...this.instance.slice(0, index), customView, ...this.instance.slice(index + 1)];
    }
  }

  public updateOrAddItem(customView: CustomView) {
    const index = this.instance.findIndex(item => item.uniqueName == customView.uniqueName);

    if (index !== -1) {
      this.instance = [...this.instance.slice(0, index), customView, ...this.instance.slice(index + 1)];
    } else {
      this.addItem(customView);
    }
  }

  public deleteItem(customView: CustomView) {
    const index = this.instance.findIndex(item => item.uniqueName == customView.uniqueName);

    if (index !== -1) {
      this.instance = this.instance.filter((item, i) => i !== index);
    }
  }

  public generateName(defaultName = 'New Component'): Observable<string> {
    return this.getFirst().pipe(
      map(customViews => {
        const names = (customViews || []).reduce((acc, item) => {
          if (isSet(item.name)) {
            acc[item.name.toLowerCase()] = true;
          }
          return acc;
        }, {});

        let i = 1;
        let newName: string;

        do {
          newName = i > 1 ? [defaultName, i].join(' ') : defaultName;
          ++i;
        } while (names.hasOwnProperty(newName.toLowerCase()));

        return newName;
      })
    );
  }
}
