var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormControl, FormGroup } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import { ColumnsStyle, MarginControl } from '@modules/customize';
import { Input } from '@modules/fields';
import { FieldInputControl } from '@modules/parameters';
var CustomizeBarColumnsEditForm = /** @class */ (function (_super) {
    __extends(CustomizeBarColumnsEditForm, _super);
    function CustomizeBarColumnsEditForm() {
        var _this = _super.call(this, {
            title: new FormControl(''),
            style: new FormControl(ColumnsStyle.Wrap),
            visible_input: new FieldInputControl({ path: ['value'] }),
            margin: new MarginControl(),
            load_invisible: new FormControl(false)
        }) || this;
        _this.styleOptions = [
            {
                value: ColumnsStyle.Wrap,
                name: 'Wrap',
                image: 'columns-style-wrap'
            },
            {
                value: ColumnsStyle.Background,
                name: 'Background',
                image: 'columns-style-background'
            }
        ];
        return _this;
    }
    CustomizeBarColumnsEditForm.prototype.init = function (element) {
        this.element = element;
        this.controls.title.patchValue(element.name ? element.name : 'Columns');
        this.controls.style.patchValue(element.style || ColumnsStyle.Wrap);
        this.controls.visible_input.patchValue(element.visibleInput ? element.visibleInput.serializeWithoutPath() : {});
        this.controls.margin.patchValue(element.margin);
        this.controls.load_invisible.patchValue(element.loadInvisible);
    };
    CustomizeBarColumnsEditForm.prototype.submit = function () {
        var instance = cloneDeep(this.element);
        instance.name = this.controls.title.value;
        instance.style = this.controls.style.value;
        if (this.controls.visible_input.value) {
            instance.visibleInput = new Input().deserialize(this.controls.visible_input.value);
        }
        else {
            instance.visibleInput = undefined;
        }
        instance.loadInvisible = this.controls.load_invisible.value;
        instance.margin = this.controls.margin.value;
        return instance;
    };
    return CustomizeBarColumnsEditForm;
}(FormGroup));
export { CustomizeBarColumnsEditForm };
