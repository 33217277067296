import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material';

import { DragDrop2Module } from '@common/drag-drop2';
import { ResizableModule } from '@common/resizable';
import { TipsModule } from '@common/tips';
import { SharedModule } from '@shared';

import { AutoElementBorderComponent } from './components/auto-element-border/auto-element-border.component';
import { AutoElementToolbarComponent } from './components/auto-element-toolbar/auto-element-toolbar.component';
import { ChoosePageDropdownComponent } from './components/choose-page-dropdown/choose-page-dropdown.component';
import { CustomViewDropdownItemComponent } from './components/custom-view-dropdown-item/custom-view-dropdown-item.component';
import { CustomViewDropdownComponent } from './components/custom-view-dropdown/custom-view-dropdown.component';
import { ElementWrapperComponent } from './components/element-wrapper/element-wrapper.component';
import { WorkflowButtonComponent } from './components/workflow-item-edit/workflow-button.component';

@NgModule({
  imports: [
    CommonModule,
    ResizableModule,
    TipsModule,
    OverlayModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    DragDrop2Module,
    MatMenuModule,
    PortalModule
  ],
  declarations: [
    AutoElementBorderComponent,
    AutoElementToolbarComponent,
    ChoosePageDropdownComponent,
    ElementWrapperComponent,
    WorkflowButtonComponent,
    CustomViewDropdownComponent,
    CustomViewDropdownItemComponent
  ],
  exports: [
    AutoElementBorderComponent,
    AutoElementToolbarComponent,
    ChoosePageDropdownComponent,
    ElementWrapperComponent,
    WorkflowButtonComponent,
    CustomViewDropdownComponent,
    CustomViewDropdownItemComponent
  ]
})
export class CustomizeUiModule {}
