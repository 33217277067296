/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../list-components/components/model-card-action/model-card-action.component.ngfactory";
import * as i2 from "../../../list-components/components/model-card-action/model-card-action.component";
import * as i3 from "../../../customize/services/customize/customize.service";
import * as i4 from "../../../action-queries/services/action/action.service";
import * as i5 from "../../../action-queries/services/action-controller/action-controller.service";
import * as i6 from "../../../../common/notifications/services/notification/notification.service";
import * as i7 from "@angular/common";
import * as i8 from "../../../list-components/components/model-card/model-card.component.ngfactory";
import * as i9 from "../../../list-components/components/model-card/model-card.component";
import * as i10 from "../../../custom-elements-components/components/custom-element-auto/custom-element-auto.component.ngfactory";
import * as i11 from "../../../custom-elements-components/components/custom-element-auto/custom-element-auto.component";
import * as i12 from "./map-popup.component";
import * as i13 from "../../../projects/stores/current-project.store";
import * as i14 from "../../../custom-views/stores/custom-views.store";
var styles_MapPopupComponent = [];
var RenderType_MapPopupComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MapPopupComponent, data: {} });
export { RenderType_MapPopupComponent as RenderType_MapPopupComponent };
function View_MapPopupComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "span", [["class", "map-popup__buttons-item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, [["button_element", 1]], null, 3, "a", [["class", "button button_small"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.open($event, i0.ɵnov(_v, 1)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "span", [["class", "icon icon-eye button__icon button__icon_left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "span", [["class", "button__label"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Open"]))], null, null); }
function View_MapPopupComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-model-card-action", [["class", "map-popup__buttons-item"]], [[2, "element__indicator-wrapper", null], [2, "hidden", null]], null, null, i1.View_ModelCardActionComponent_0, i1.RenderType_ModelCardActionComponent)), i0.ɵdid(2, 770048, null, 0, i2.ModelCardActionComponent, [i3.CustomizeService, i4.ActionService, i5.ActionControllerService, i6.NotificationService, i0.Injector, i0.ChangeDetectorRef], { model: [0, "model"], action: [1, "action"], context: [2, "context"], contextElement: [3, "contextElement"], accentColor: [4, "accentColor"], theme: [5, "theme"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.item.model; var currVal_3 = _v.context.$implicit; var currVal_4 = _co.context; var currVal_5 = _co.contextElement; var currVal_6 = _co.accentColor; var currVal_7 = _co.theme; _ck(_v, 2, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2).indicatorWrapper; var currVal_1 = i0.ɵnov(_v, 2).hidden; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_MapPopupComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "map-popup__buttons"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MapPopupComponent_3)), i0.ɵdid(2, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MapPopupComponent_4)), i0.ɵdid(4, 278528, null, 0, i7.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.settings.cardClickAction; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.settings.modelActions; _ck(_v, 4, 0, currVal_1); }, null); }
function View_MapPopupComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "map-popup"]], [[2, "map-popup_clickable", null], [2, "map-popup_theme", null]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-model-card", [], null, [[null, "modelUpdated"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("modelUpdated" === en)) {
        var pd_0 = (_co.modelUpdated.next($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_ModelCardComponent_0, i8.RenderType_ModelCardComponent)), i0.ɵdid(2, 245760, null, 0, i9.ModelCardComponent, [i4.ActionService, i0.ChangeDetectorRef], { item: [0, "item"], modelDescription: [1, "modelDescription"], visibleColumns: [2, "visibleColumns"], columns: [3, "columns"], columnActions: [4, "columnActions"], context: [5, "context"], contextElement: [6, "contextElement"], theme: [7, "theme"] }, { modelUpdated: "modelUpdated" }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MapPopupComponent_2)), i0.ɵdid(4, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.item; var currVal_3 = _co.modelDescription; var currVal_4 = _co.visibleColumns; var currVal_5 = (((_co.settings.dataSource == null) ? null : _co.settings.dataSource.columns) || i0.ɵEMPTY_ARRAY); var currVal_6 = _co.settings.columnActions; var currVal_7 = _co.context; var currVal_8 = _co.contextElement; var currVal_9 = _co.theme; _ck(_v, 2, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); var currVal_10 = (_co.settings.cardClickAction || _co.settings.modelActions.length); _ck(_v, 4, 0, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.settings.cardClickAction; var currVal_1 = _co.theme; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_MapPopupComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-custom-element-auto", [["class", "map-popup"]], [[2, "map-popup_clickable", null], [2, "map-popup_theme", null]], null, null, i10.View_CustomElementAutoComponent_0, i10.RenderType_CustomElementAutoComponent)), i0.ɵdid(1, 245760, null, 0, i11.CustomElementAutoComponent, [i5.ActionControllerService, i0.Injector], { source: [0, "source"], customView: [1, "customView"], actions: [2, "actions"], params: [3, "params"], context: [4, "context"], contextElement: [5, "contextElement"], localContext: [6, "localContext"] }, null), i0.ɵpid(131072, i7.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.customView.source; var currVal_3 = _co.customView; var currVal_4 = _co.settings.customActions; var currVal_5 = i0.ɵunv(_v, 1, 3, i0.ɵnov(_v, 2).transform(_co.viewParams$)); var currVal_6 = _co.context; var currVal_7 = _co.contextElement; var currVal_8 = _co.localContext; _ck(_v, 1, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.settings.cardClickAction; var currVal_1 = _co.theme; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_MapPopupComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_MapPopupComponent_1)), i0.ɵdid(1, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MapPopupComponent_5)), i0.ɵdid(3, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.customView; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.customView; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_MapPopupComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-map-popup", [], null, null, null, View_MapPopupComponent_0, RenderType_MapPopupComponent)), i0.ɵdid(1, 770048, null, 0, i12.MapPopupComponent, [i13.CurrentProjectStore, i5.ActionControllerService, i14.CustomViewsStore, i6.NotificationService, i0.Injector, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MapPopupComponentNgFactory = i0.ɵccf("app-map-popup", i12.MapPopupComponent, View_MapPopupComponent_Host_0, { item: "item", modelDescription: "modelDescription", settings: "settings", visibleColumns: "visibleColumns", context: "context", contextElement: "contextElement", accentColor: "accentColor", theme: "theme" }, { modelUpdated: "modelUpdated", close: "close" }, []);
export { MapPopupComponentNgFactory as MapPopupComponentNgFactory };
