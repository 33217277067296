var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, OnChanges, OnDestroy, OnInit } from '@angular/core';
import pickBy from 'lodash/pickBy';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { CustomViewsStore } from '@modules/custom-views';
import { applyParamInputs$, NOT_SET_VALUE } from '@modules/fields';
import { CustomMenuItem, MenuItemActionService } from '@modules/menu';
import { isSet } from '@shared';
import { BaseMenuItemComponent } from '../base-menu-item/base-menu-item.component';
var CustomMenuItemComponent = /** @class */ (function (_super) {
    __extends(CustomMenuItemComponent, _super);
    function CustomMenuItemComponent(menuItemActionService, customViewsStore, cd) {
        var _this = _super.call(this) || this;
        _this.menuItemActionService = menuItemActionService;
        _this.customViewsStore = customViewsStore;
        _this.cd = cd;
        _this.menuItem$ = new BehaviorSubject(undefined);
        _this.viewParams$ = of({});
        return _this;
    }
    CustomMenuItemComponent.prototype.ngOnInit = function () {
        this.menuItem$.next(this.menuItem);
        this.initView();
    };
    CustomMenuItemComponent.prototype.ngOnDestroy = function () { };
    CustomMenuItemComponent.prototype.ngOnChanges = function (changes) {
        if (changes.menuItem && !changes.menuItem.firstChange) {
            this.menuItem$.next(this.menuItem);
        }
    };
    CustomMenuItemComponent.prototype.initView = function () {
        var _this = this;
        this.menuItem$
            .pipe(switchMap(function (menuItem) {
            if (!menuItem || !isSet(menuItem.customView)) {
                return of(undefined);
            }
            return _this.customViewsStore.getDetail(menuItem.customView);
        }), untilDestroyed(this))
            .subscribe(function (customView) {
            _this.customView = customView;
            _this.view = customView ? customView.view : undefined;
            _this.cd.markForCheck();
            _this.updateViewParams(customView);
        });
    };
    CustomMenuItemComponent.prototype.updateViewParams = function (customView) {
        if (!customView) {
            this.viewParams$ = of({});
            this.cd.markForCheck();
            return;
        }
        this.viewParams$ = applyParamInputs$({}, this.menuItem.inputs, {
            context: this.context,
            parameters: customView.parameters
        }).pipe(map(function (params) {
            return pickBy(params, function (v) { return v !== NOT_SET_VALUE; });
        }));
    };
    return CustomMenuItemComponent;
}(BaseMenuItemComponent));
export { CustomMenuItemComponent };
