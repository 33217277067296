<form [formGroup]="form.form" (ngSubmit)="submit()">
  <app-customize-bar-header
    [title]="form.form.value['title'] || (titleEditable ? 'text' : 'Text')"
    [titleEnabled]="titleEnabled"
    [titleEditable]="titleEditable"
    [titleCleanValue]="titleCleanValue"
    [backLabel]="'All components'"
    [deleteEnabled]="deleteEnabled"
    [parentLabel]="parentElement?.displayName || parentPopup?.displayName"
    (parentClick)="parentElement?.customize({ event: $event }) || parentPopup?.customize({ event: $event })"
    (deleteClick)="delete()"
    (titleChanged)="onTitleChanged($event)"
  >
  </app-customize-bar-header>

  <div class="sidebar__content">
    <app-sidebar-tabs>
      <app-sidebar-tab [label]="'General'">
        <div class="sidebar__element">
          <app-sidebar-field [label]="'Text preview'">
            <div
              class="input input_textarea input_disabled"
              style="height: 100px; padding: 16px 15px 0 16px; line-height: 1.4; cursor: not-allowed;"
              [appTip]="'Edit text directly in the component on the left'"
            >
              <app-text-editor
                [value]="element.quillDelta"
                [context]="context"
                [editable]="false"
                [textParagraphs]="true"
                [schema]="(themeService.isDarkTheme$ | async) ? 'light' : 'dark'"
                [analyticsSource]="element.analyticsGenericName"
                style="pointer-events: none;"
              ></app-text-editor>
            </div>
          </app-sidebar-field>
        </div>
      </app-sidebar-tab>

      <app-sidebar-tab [label]="'Display'">
        <div class="sidebar__element">
          <app-sidebar-field
            [label]="'Conditional Visibility'"
            [documentation]="'conditional-visibility'"
            [documentationLabel]="'See how it works'"
            [documentationSource]="element.analyticsGenericName + '_visible'"
          >
            <app-input-edit
              [itemForm]="form.form.controls['visible_input']"
              [context]="context"
              [staticValueDisabled]="true"
              [placeholder]="'Value'"
              [fill]="true"
              [analyticsSource]="element.analyticsGenericName + '_visible'"
            ></app-input-edit>

            <ng-container description>
              Example: elements.field.value >= 10
            </ng-container>
          </app-sidebar-field>
        </div>

        <div class="sidebar__element">
          <app-element-wrap-edit [control]="form.form.controls['card_wrap']"></app-element-wrap-edit>
        </div>

        <div class="sidebar__element">
          <app-sidebar-field [label]="'Spacing'">
            <app-margin-control [control]="form.form.controls['margin']"></app-margin-control>
          </app-sidebar-field>
        </div>
      </app-sidebar-tab>
    </app-sidebar-tabs>
  </div>
</form>
