var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, of, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { CustomView, CustomViewService, CustomViewSource, CustomViewsStore, CustomViewType, defaultCustomViewHtml } from '@modules/custom-views';
import { MarginControl } from '@modules/customize';
import { Input, isRequiredInputsSet, ParameterArray } from '@modules/fields';
import { FieldInputControl, InputFieldProvider, parametersToProviderItems } from '@modules/parameters';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { controlValue, isSet } from '@shared';
export function validateInputs() {
    return function (control) {
        var parent = control.parent;
        if (!parent) {
            return;
        }
        var fields = parent.inputFieldProvider.getFields();
        var inputs = control.value;
        if (!isRequiredInputsSet(fields, inputs)) {
            return { required: true };
        }
    };
}
var CustomizeBarCustomHTMLEditForm = /** @class */ (function (_super) {
    __extends(CustomizeBarCustomHTMLEditForm, _super);
    function CustomizeBarCustomHTMLEditForm(currentProjectStore, currentEnvironmentStore, customViewService, customViewsStore) {
        var _this = _super.call(this, {
            name: new FormControl(''),
            source: new FormControl(CustomViewSource.HTML),
            custom_view: new FormControl(''),
            parameters: new ParameterArray([]),
            inputs: new FormControl([], validateInputs()),
            html: new FormControl(defaultCustomViewHtml),
            width_fluid: new FormControl(false),
            height_fluid: new FormControl(false),
            visible_input: new FieldInputControl({ path: ['value'] }),
            card_wrap: new FormControl(true),
            margin: new MarginControl()
        }) || this;
        _this.currentProjectStore = currentProjectStore;
        _this.currentEnvironmentStore = currentEnvironmentStore;
        _this.customViewService = customViewService;
        _this.customViewsStore = customViewsStore;
        _this.submitLoading$ = new BehaviorSubject(false);
        _this.inputFieldProvider = new InputFieldProvider();
        return _this;
    }
    CustomizeBarCustomHTMLEditForm.prototype.ngOnDestroy = function () {
        this.inputFieldProvider.clearProvider();
    };
    CustomizeBarCustomHTMLEditForm.prototype.init = function (element, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        this.element = element;
        this.context = options.context;
        var customView$ = isSet(element.customView)
            ? this.customViewsStore.getDetailFirst(element.customView)
            : of(undefined);
        return customView$.pipe(map(function (customView) {
            var html = customView ? customView.html : undefined;
            _this.controls.name.patchValue(element.name ? element.name : 'HTML');
            _this.controls.source.patchValue(element.source ? element.source : CustomViewSource.HTML);
            _this.controls.custom_view.patchValue(element.customView);
            _this.controls.html.patchValue(html || defaultCustomViewHtml);
            // Backward compatibility
            _this.controls.parameters.patchValue(customView && customView.parameters.length ? customView.parameters : element.parameters);
            _this.controls.inputs.patchValue(element.inputs);
            _this.controls.width_fluid.patchValue(element.widthFluid);
            _this.controls.height_fluid.patchValue(element.heightFluid);
            _this.controls.visible_input.patchValue(element.visibleInput ? element.visibleInput.serializeWithoutPath() : {});
            _this.controls.card_wrap.patchValue(element.cardWrap);
            _this.controls.margin.patchValue(element.margin);
            _this.updateInputFieldProvider();
            _this.trackChanges();
            _this.markAsPristine();
        }));
    };
    CustomizeBarCustomHTMLEditForm.prototype.updateInputFieldProvider = function () {
        this.inputFieldProvider.setProvider(controlValue(this.controls.parameters).pipe(map(function (parameters) {
            return parameters ? parametersToProviderItems(parameters) : [];
        })));
    };
    CustomizeBarCustomHTMLEditForm.prototype.trackChanges = function () {
        var _this = this;
        this.inputFieldProvider
            .getFields$()
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            _this.controls.inputs.updateValueAndValidity();
        });
    };
    CustomizeBarCustomHTMLEditForm.prototype.getCustomViewDefaults = function () {
        return {
            uniqueName: CustomView.generateUniqueName(),
            pageUid: this.context && this.context.viewSettings ? this.context.viewSettings.uid : undefined,
            elementUid: this.element.uid
        };
    };
    CustomizeBarCustomHTMLEditForm.prototype.updateOrCreateCustomView = function () {
        var _this = this;
        var uniqueName = this.controls.custom_view.value;
        var customView$ = isSet(uniqueName) ? this.customViewsStore.getDetailFirst(uniqueName) : of(undefined);
        return customView$.pipe(switchMap(function (existingInstance) {
            var instance = existingInstance || new CustomView();
            var defaults = _this.getCustomViewDefaults();
            if (isSet(uniqueName)) {
                instance.uniqueName = uniqueName;
            }
            else {
                instance.uniqueName = defaults.uniqueName;
            }
            instance.viewType = CustomViewType.Component;
            instance.source = CustomViewSource.HTML;
            instance.html = _this.controls.html.value;
            instance.pageUid = defaults.pageUid;
            instance.elementUid = defaults.elementUid;
            var fields = ['unique_name', 'view_type', 'source', 'html', 'params'];
            return existingInstance
                ? _this.customViewService.update(_this.currentProjectStore.instance.uniqueName, _this.currentEnvironmentStore.instance.uniqueName, instance, { draft: true, fields: fields })
                : _this.customViewService.create(_this.currentProjectStore.instance.uniqueName, _this.currentEnvironmentStore.instance.uniqueName, instance, { draft: true, fields: fields });
        }), tap(function (customView) { return _this.customViewsStore.updateOrAddItem(customView); }));
    };
    CustomizeBarCustomHTMLEditForm.prototype.submit = function () {
        var _this = this;
        this.submitLoading$.next(true);
        return this.updateOrCreateCustomView().pipe(map(function (customView) {
            var instance = cloneDeep(_this.element);
            instance.name = _this.controls.name.value;
            instance.source = _this.controls.source.value;
            instance.customView = customView.uniqueName;
            // instance.parameters = this.controls.parameters.value;
            instance.inputs = _this.controls.inputs.value;
            instance.widthFluid = _this.controls.width_fluid.value;
            instance.heightFluid = _this.controls.height_fluid.value;
            if (_this.controls.visible_input.value) {
                instance.visibleInput = new Input().deserialize(_this.controls.visible_input.value);
            }
            else {
                instance.visibleInput = undefined;
            }
            instance.cardWrap = _this.controls.card_wrap.value;
            instance.margin = _this.controls.margin.value;
            return instance;
        }), tap(function () {
            _this.submitLoading$.next(false);
        }), catchError(function (error) {
            _this.submitLoading$.next(false);
            return throwError(error);
        }));
    };
    return CustomizeBarCustomHTMLEditForm;
}(FormGroup));
export { CustomizeBarCustomHTMLEditForm };
