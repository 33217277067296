import { ChangeDetectionStrategy, Component, Inject, OnInit, Optional, Provider } from '@angular/core';

import { CUSTOM_VIEW_COMPONENT, CUSTOM_VIEW_COMPONENT_INPUTS, CustomViewComponentInputs } from '@modules/customize-ui';

export const CUSTOM_VIEW_COMPONENT_PROVIDER: Provider = {
  provide: CUSTOM_VIEW_COMPONENT,
  useFactory: customViewComponentFactory
};

export function customViewComponentFactory(): any {
  return CustomViewInjectComponent;
}

@Component({
  selector: 'app-custom-view-inject',
  templateUrl: './custom-view-inject.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomViewInjectComponent implements OnInit {
  constructor(
    @Inject(CUSTOM_VIEW_COMPONENT_INPUTS)
    @Optional()
    public readonly inputs: CustomViewComponentInputs = {}
  ) {}

  ngOnInit() {}
}
