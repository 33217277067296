<app-popup2 [size]="'s'" [theme]="theme">
  <app-popup2-header>
    <app-popup2-title>{{ 'Records Export' | localize }}</app-popup2-title>
    <app-popup2-description>
      {{ 'Downloading records data as a file' | localize }}
    </app-popup2-description>
  </app-popup2-header>

  <ng-container *ngIf="!executeActionStarted">
    <app-loader *ngIf="loading"></app-loader>

    <ng-container *ngIf="!loading">
      <app-page-block>
        <div class="field" [class.field_theme]="theme">
          <label class="field__label field__label_bright">
            {{ 'Choose format' | localize }}
          </label>
          <app-select [control]="formatTypeControl" [options]="exportFormats" [theme]="theme"></app-select>
        </div>
      </app-page-block>

      <app-page-block *ngIf="items">
        <app-page-description *ngIf="count !== undefined" [theme]="theme">
          <span [innerHTML]="'Number of exporting records' | localize"></span>: {{ count }}
        </app-page-description>

        <div *ngIf="false" class="card">
          <div class="card__inner">
            <ul>
              <li>The following items will be exported:</li>
              <li>---</li>
              <li *ngFor="let item of items">
                <a [appLink]="item.getLink()" [innerHTML]="item | appModelStr | appStub | async" (click)="close()"></a>
              </li>
              <li *ngIf="items.length < count">...{{ count - items.length }} more</li>
            </ul>
          </div>
        </div>
      </app-page-block>
    </ng-container>
  </ng-container>

  <app-page-block *ngIf="executeActionStarted">
    <app-circle-progress
      [progress]="executeActionProgress"
      [label]="executeActionStatus"
      [accentColor]="theme ? ('accentColor' | appThemeColorHexOption: true | async) : undefined"
      [theme]="theme"
    ></app-circle-progress>
  </app-page-block>

  <app-popup2-footer *ngIf="!loading">
    <app-page-block>
      <app-stack [align]="'right'">
        <a
          href="javascript:void(0)"
          class="button popup2__footer-item"
          [class.button_theme]="theme"
          [appButtonTintColor]="theme ? ('accentColor' | appThemeColorHexOption: true | async) : undefined"
          [appButtonTintColorStyle]="tintStyles.Default"
          (click)="close()"
        >
          {{ 'Cancel' | localize }}
        </a>

        <button
          *ngIf="!executeActionSucceeded"
          type="submit"
          class="button button_primary popup2__footer-item"
          [class.button_theme]="theme"
          [class.button_disabled]="formatTypeControl.invalid || executeActionStarted"
          [appButtonTintColor]="theme ? ('accentColor' | appThemeColorHexOption: true | async) : undefined"
          [appButtonTintColorStyle]="tintStyles.Primary"
          (click)="exportData()"
        >
          {{ 'Export' | localizeAdv: { context: 'submit' } }}
        </button>
        <button
          *ngIf="executeActionSucceeded"
          type="button"
          class="button button_primary popup2__footer-item"
          [class.button_theme]="theme"
          [appButtonTintColor]="theme ? ('accentColor' | appThemeColorHexOption: true | async) : undefined"
          [appButtonTintColorStyle]="tintStyles.Primary"
          (click)="close()"
        >
          {{ 'Continue' | localize }}
        </button>
      </app-stack>
    </app-page-block>
  </app-popup2-footer>
</app-popup2>
