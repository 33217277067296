import max from 'lodash/max';
import mean from 'lodash/mean';
import min from 'lodash/min';
import sum from 'lodash/sum';
import toPairs from 'lodash/toPairs';
import * as moment from 'moment';

import { getTimezoneOffset, isValidNumber } from '@shared';

import { AggregateFunc } from '../data/aggregate-func';
import { DataGroup } from '../data/data-group';
import { DatasetGroupLookup } from '../data/dataset';

export function parseDate(data: any): moment.Moment {
  if (data instanceof moment) {
    return data;
  } else if (isValidNumber(data) && parseFloat(data) > 1000000) {
    return moment.unix(data);
  } else if (typeof data == 'string') {
    return moment(data, [moment.ISO_8601, 'YYYY-MM-DD HH:mm:ss', 'YYYY-MM-DD', 'MM-DD-YYYY', 'DD-MM', 'YYYY']);
  } else {
    return moment.invalid();
  }
}

export function datasetGroupLookupUnitOfTime(lookup: DatasetGroupLookup): moment.unitOfTime.StartOf {
  if (lookup == DatasetGroupLookup.DateDay) {
    return 'day';
  } else if (lookup == DatasetGroupLookup.DateWeek) {
    return 'week';
  } else if (lookup == DatasetGroupLookup.DateMonth) {
    return 'month';
  } else if (lookup == DatasetGroupLookup.DateQuarter) {
    return 'quarter';
  } else if (lookup == DatasetGroupLookup.DateYear) {
    return 'year';
  } else if (lookup == DatasetGroupLookup.DateHour) {
    return 'hour';
  } else if (lookup == DatasetGroupLookup.DateMinute) {
    return 'minute';
  } else if (lookup == DatasetGroupLookup.DateSecond) {
    return 'second';
  }
}

export function groupDatasetByLookup(
  data: DataGroup<any, moment.Moment>[],
  lookup: DatasetGroupLookup,
  valueFunc?: AggregateFunc
): DataGroup<any, moment.Moment>[] {
  const unit = datasetGroupLookupUnitOfTime(lookup);
  return groupDatasetByUnitOfTime(data, unit, valueFunc);
}

export function groupDatasetByUnitOfTime(
  data: DataGroup<any, moment.Moment>[],
  unit: moment.unitOfTime.StartOf,
  valueFunc?: AggregateFunc
): DataGroup<any, moment.Moment>[] {
  const grouped = data.reduce<{ [k: string]: { values: number[]; color?: string } }>((result, item) => {
    const key = item.group.clone().utcOffset(getTimezoneOffset()).startOf(unit).toISOString();

    if (!result.hasOwnProperty(key)) {
      result[key] = { values: [], color: item.color };
    }

    const number = parseFloat(item.value);

    if (!isNaN(number)) {
      result[key].values.push(number);
    }

    return result;
  }, {});

  return toPairs(grouped).map(([key, item]) => {
    const result = new DataGroup();

    result.group = moment(key).utcOffset(getTimezoneOffset());
    result.color = item.color;

    if (valueFunc == AggregateFunc.Min) {
      result.value = item.values.length > 0 ? min(item.values) : 0;
    } else if (valueFunc == AggregateFunc.Max) {
      result.value = item.values.length > 0 ? max(item.values) : 0;
    } else if (valueFunc == AggregateFunc.Avg) {
      result.value = item.values.length > 0 ? mean(item.values) : 0;
    } else {
      result.value = item.values.length > 0 ? sum(item.values) : 0;
    }

    return result;
  });
}

export const dateFormatByUnitOfTime = {
  millisecond: 'HH:mm:ss.SSS',
  second: 'HH:mm:ss MMM D YYYY',
  minute: 'HH:mm MMM D YYYY',
  hour: 'hA MMM D YYYY',
  day: 'MMM D YYYY',
  week: '[Week from] MMM D YYYY',
  month: 'MMM YYYY',
  quarter: '[Q]Q - YYYY',
  year: 'YYYY'
};

export function getDateFormatByLookup(lookup: DatasetGroupLookup): string {
  const unit = datasetGroupLookupUnitOfTime(lookup);
  return dateFormatByUnitOfTime[unit];
}
