var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { HttpClient, HttpEventType } from '@angular/common/http';
import cloneDeep from 'lodash/cloneDeep';
import extend from 'lodash/extend';
import fromPairs from 'lodash/fromPairs';
import isArray from 'lodash/isArray';
import isPlainObject from 'lodash/isPlainObject';
import { of, throwError } from 'rxjs';
import { filter, map, publishLast, refCount, switchMap } from 'rxjs/operators';
import { ActionDescription, ActionType } from '@modules/actions';
import { ServerRequestError } from '@modules/api';
import { DataGroup } from '@modules/charts';
import { getFieldDescriptionByType, InputValueType } from '@modules/fields';
import { modelDbFieldToParameterField, ModelDescription, ModelFieldType, PER_PAGE_PARAM } from '@modules/models';
import { ProjectApiService } from '@modules/project-api';
import { ResourceName } from '@modules/projects';
import { HttpQueryService, HttpResponseType, QueryPagination, QueryService, QueryType } from '@modules/queries';
import { QueryTokensService } from '@modules/queries-tokens';
import { StorageObject, StorageObjectsResponse } from '@modules/storages';
import { defaultComparator, getHttpHeadersAsArray, getHttpHeadersAsObject, isSet, objectsSortPredicate } from '@shared';
// TODO: Refactor import
import { modelFieldToRawListViewSettingsColumn } from '../../../customize/utils/common';
import { FIREBASE_CREATED_TIME, FIREBASE_ITEM_PRIMARY_KEY, FIREBASE_PARENT, FIREBASE_PRIMARY_KEY, FIREBASE_UPDATED_TIME, FirebaseDatabaseType } from '../../data/firebase';
import { ResourceController } from '../../data/resource-controller';
import { RestAPIResourceParams } from '../../data/rest-api-resource-params';
import { aggregateGetResponse } from '../../utils/aggregate';
import { isResourceQueryCustom } from '../../utils/common';
import { applyFrontendFiltering, applyFrontendPagination, applyFrontendSorting } from '../../utils/filters';
import { groupGetResponse } from '../../utils/group';
var RestApiResourceControllerService = /** @class */ (function (_super) {
    __extends(RestApiResourceControllerService, _super);
    function RestApiResourceControllerService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    RestApiResourceControllerService.prototype.init = function () {
        this.http = this.initService(HttpClient);
        this.queryService = this.initService(QueryService);
        this.queryTokensService = this.initService(QueryTokensService);
        this.httpQueryService = this.initService(HttpQueryService);
        this.apiService = this.initService(ProjectApiService);
    };
    RestApiResourceControllerService.prototype.supportedQueryTypes = function (resource, queryClass) {
        return [QueryType.Http];
    };
    RestApiResourceControllerService.prototype.checkResource = function (typeItem, params) {
        // if (typeItem.name == 'stripe') {
        //   const restApiParams = new RestAPIResourceParams().deserialize(params);
        //   const query = restApiParams.modelDescriptions[0].getQuery;
        //   const tokens = this.queryTokensService.mergeTokens(
        //     this.queryTokensService.generalTokens()
        //   );
        //
        //   return this.httpQueryService.request<any>(undefined, query, undefined, tokens).pipe(
        //     catchError(error => {
        //       return throwError(new ServerRequestError({
        //         non_field_errors: undefined,
        //         secret_key: ['Failed to get data from Stripe API. Please check if Secret Token is correct']
        //       }));
        //     }),
        //     publishLast(),
        //     refCount()
        //   );
        // } else {
        return of(true);
        // }
    };
    RestApiResourceControllerService.prototype.getStorageObjects = function (resource, storage, query, path) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var resourceParams = resource.parseParams(RestAPIResourceParams);
            if (!query || !query.httpQuery) {
                return of(undefined);
            }
            var tokens = _this.queryTokensService.mergeTokens(_this.queryTokensService.generalTokens(), _this.queryTokensService.storageGetObjectsTokens(path));
            return _this.httpQueryService.requestBody(query.httpQuery, {
                resource: resource.uniqueName,
                baseQuery: resourceParams.baseHttpQuery,
                tokens: tokens,
                customProxy: resourceParams ? resourceParams.customProxy : undefined
            });
        }), map(function (result) {
            var response = new StorageObjectsResponse();
            response.objects = result.map(function (item) { return new StorageObject().deserialize(item); });
            return response;
        }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    RestApiResourceControllerService.prototype.deleteStorageObject = function (resource, storage, query, path) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var resourceParams = resource.parseParams(RestAPIResourceParams);
            if (!query || !query.httpQuery) {
                return of(undefined);
            }
            var tokens = _this.queryTokensService.mergeTokens(_this.queryTokensService.generalTokens(), _this.queryTokensService.storageDeleteObjectTokens(path));
            return _this.httpQueryService.requestBody(query.httpQuery, {
                resource: resource.uniqueName,
                baseQuery: resourceParams.baseHttpQuery,
                tokens: tokens,
                customProxy: resourceParams ? resourceParams.customProxy : undefined
            });
        }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    RestApiResourceControllerService.prototype.getObjectUrl = function (resource, storage, query, path, expiresInSec) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var resourceParams = resource.parseParams(RestAPIResourceParams);
            if (!query || !query.httpQuery) {
                return of(undefined);
            }
            var tokens = _this.queryTokensService.mergeTokens(_this.queryTokensService.generalTokens(), _this.queryTokensService.storageGetObjectUrlTokens(path, expiresInSec));
            return _this.httpQueryService.requestBody(query.httpQuery, {
                resource: resource.uniqueName,
                baseQuery: resourceParams.baseHttpQuery,
                tokens: tokens,
                customProxy: resourceParams ? resourceParams.customProxy : undefined
            });
        }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    RestApiResourceControllerService.prototype.createStorageFolder = function (resource, storage, query, path) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var resourceParams = resource.parseParams(RestAPIResourceParams);
            if (!query || !query.httpQuery) {
                return of(undefined);
            }
            var tokens = _this.queryTokensService.mergeTokens(_this.queryTokensService.generalTokens(), _this.queryTokensService.storageCreateFolderTokens(path));
            return _this.httpQueryService.requestBody(query.httpQuery, {
                resource: resource.uniqueName,
                baseQuery: resourceParams.baseHttpQuery,
                tokens: tokens,
                customProxy: resourceParams ? resourceParams.customProxy : undefined
            });
        }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    // TODO: Remove deprecated model descriptions
    RestApiResourceControllerService.prototype.modelDescriptionGet = function (resource, draft) {
        if (draft === void 0) { draft = false; }
        if (!resource.params['model_descriptions']) {
            return of([]);
        }
        var result = resource.params['model_descriptions']
            .map(function (item) {
            return new ModelDescription().deserialize(__assign({}, item['model_description'], { get_query: item['get_query']
                    ? {
                        query_type: QueryType.Http,
                        http_query: item['get_query'],
                        pagination: item['get_query']['pagination'],
                        pagination_has_more_function: item['get_query']['pagination_has_more_function'],
                        pagination_total_function: item['get_query']['pagination_total_function'],
                        pagination_cursor_field: item['get_query']['pagination_cursor_field']
                    }
                    : undefined, get_parameters: item['model_description']['fields']
                    .filter(function (field) { return field['filterable']; })
                    .map(function (field) {
                    return {
                        name: field['name']
                    };
                }), get_inputs: item['model_description']['fields']
                    .filter(function (field) { return field['filterable']; })
                    .map(function (field) {
                    return {
                        name: field['name'],
                        value_type: InputValueType.Filter,
                        filter_field: field['name']
                    };
                }), get_detail_query: item['get_detail_query']
                    ? {
                        query_type: QueryType.Http,
                        http_query: item['get_detail_query']
                    }
                    : undefined, create_query: item['create_query']
                    ? {
                        query_type: QueryType.Http,
                        http_query: item['create_query']
                    }
                    : undefined, update_query: item['update_query']
                    ? {
                        query_type: QueryType.Http,
                        http_query: item['update_query']
                    }
                    : undefined, delete_query: item['delete_query']
                    ? {
                        query_type: QueryType.Http,
                        http_query: item['delete_query']
                    }
                    : undefined }));
        })
            .map(function (item) {
            item.resource = resource.uniqueName;
            return item;
        });
        return of(result);
    };
    RestApiResourceControllerService.prototype.getDefaultCreateParameters = function (resource, modelDescription) {
        var database = resource.params['database_option'];
        var isFirebaseRealtime = database && database.type == FirebaseDatabaseType.Realtime;
        if (resource.typeItem.name == ResourceName.Firebase && !isFirebaseRealtime) {
            var fields = modelDescription.dbDefaultFields || modelDescription.dbFields;
            var parentParameter = fields
                .filter(function (item) { return item.name == FIREBASE_PARENT; })
                .map(function (item) {
                var parameter = modelDbFieldToParameterField(item);
                parameter.required = true;
                return parameter;
            })[0];
            return (isSet(parentParameter) ? [parentParameter] : []).concat(fields
                .filter(function (item) {
                return ![
                    FIREBASE_PARENT,
                    FIREBASE_PRIMARY_KEY,
                    FIREBASE_ITEM_PRIMARY_KEY,
                    FIREBASE_CREATED_TIME,
                    FIREBASE_UPDATED_TIME
                ].includes(item.name);
            })
                .filter(function (item) { return item.editable; })
                .map(function (item) { return modelDbFieldToParameterField(item); })
                .sort(objectsSortPredicate(function (lhs, rhs) {
                return defaultComparator(lhs.name == modelDescription.primaryKeyField, rhs.name == modelDescription.primaryKeyField) * -1;
            }, '-required')));
        }
        else {
            return _super.prototype.getDefaultCreateParameters.call(this, resource, modelDescription);
        }
    };
    RestApiResourceControllerService.prototype.getUpdateParametersOrDefaults = function (resource, modelDescription) {
        var database = resource.params['database_option'];
        var isFirebaseRealtime = database && database.type == FirebaseDatabaseType.Realtime;
        if (resource.typeItem.name == ResourceName.Firebase && !isFirebaseRealtime) {
            var fields = modelDescription.dbDefaultFields || modelDescription.dbFields;
            var parentParameter = fields
                .filter(function (item) { return item.name == FIREBASE_PARENT; })
                .map(function (item) {
                var parameter = modelDbFieldToParameterField(item);
                parameter.required = true;
                return parameter;
            })[0];
            var documentIdRequiredParameter = modelDescription.dbFields
                .filter(function (item) { return item.name == FIREBASE_ITEM_PRIMARY_KEY; })
                .map(function (item) {
                var parameter = modelDbFieldToParameterField(item);
                parameter.required = true;
                return parameter;
            })[0];
            return (isSet(parentParameter) ? [parentParameter] : []).concat([
                documentIdRequiredParameter
            ], fields
                .filter(function (item) {
                return ![
                    FIREBASE_PARENT,
                    FIREBASE_PRIMARY_KEY,
                    FIREBASE_ITEM_PRIMARY_KEY,
                    FIREBASE_CREATED_TIME,
                    FIREBASE_UPDATED_TIME
                ].includes(item.name);
            })
                .filter(function (item) { return item.editable; })
                .map(function (item) { return modelDbFieldToParameterField(item); })
                .sort(objectsSortPredicate('-required')));
        }
        else {
            return _super.prototype.getUpdateParametersOrDefaults.call(this, resource, modelDescription);
        }
    };
    RestApiResourceControllerService.prototype.getDefaultDeleteParameters = function (resource, modelDescription) {
        var database = resource.params['database_option'];
        var isFirebaseRealtime = database && database.type == FirebaseDatabaseType.Realtime;
        if (resource.typeItem.name == ResourceName.Firebase && !isFirebaseRealtime) {
            var fields = modelDescription.dbDefaultFields || modelDescription.dbFields;
            var parentParameter = fields
                .filter(function (item) { return item.name == FIREBASE_PARENT; })
                .map(function (item) {
                var parameter = modelDbFieldToParameterField(item);
                parameter.required = true;
                return parameter;
            })[0];
            var documentIdRequiredParameter = modelDescription.dbFields
                .filter(function (item) { return item.name == FIREBASE_ITEM_PRIMARY_KEY; })
                .map(function (item) {
                var parameter = modelDbFieldToParameterField(item);
                parameter.required = true;
                return parameter;
            })[0];
            return (isSet(parentParameter) ? [parentParameter] : []).concat([documentIdRequiredParameter]);
        }
        else {
            return _super.prototype.getDefaultDeleteParameters.call(this, resource, modelDescription);
        }
    };
    RestApiResourceControllerService.prototype.getHttp = function (resource, query, parameters, params, body, columns, paginate) {
        var _this = this;
        if (parameters === void 0) { parameters = []; }
        if (columns === void 0) { columns = []; }
        if (paginate === void 0) { paginate = true; }
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var resourceParams = resource.parseParams(RestAPIResourceParams);
            if (!query.httpQuery) {
                return of(undefined);
            }
            var userQuery = isResourceQueryCustom(resource, query.queryType);
            var tokens = _this.queryTokensService.mergeTokens(_this.queryTokensService.generalTokens(), _this.queryTokensService.modelGetTokens(params, parameters, userQuery), _this.queryTokensService.paginationTokens(query.pagination, params));
            return _this.httpQueryService
                .request(query.httpQuery, {
                resource: resource.uniqueName,
                baseQuery: resourceParams.baseHttpQuery,
                tokens: tokens,
                customProxy: resourceParams ? resourceParams.customProxy : undefined
            })
                .pipe(map(function (result) {
                var resultBody = result.body;
                var tokensAfterRequest = __assign({}, tokens, { headers: getHttpHeadersAsObject(result.headers), headers_array: getHttpHeadersAsArray(result.headers) });
                resultBody = _this.queryService.applyTransformer(resultBody, query.httpQuery.responseTransformer, query.httpQuery.url, false, tokensAfterRequest);
                resultBody = _this.queryService.getPath(resultBody, query.httpQuery.responsePath);
                if (!isArray(resultBody)) {
                    resultBody = [resultBody];
                }
                // TODO: Move filtering after deserialization
                if (query.frontendFiltering && resultBody && isArray(resultBody)) {
                    resultBody = applyFrontendFiltering(resultBody, params, columns);
                }
                var data = {
                    results: resultBody
                };
                var response = _this.createGetResponse().deserialize(data, undefined, undefined);
                var tokensWithResponse = __assign({}, tokensAfterRequest, { results: resultBody });
                response.results.forEach(function (item) {
                    item.deserializeAttributes(columns);
                });
                if (query.frontendFiltering) {
                    applyFrontendSorting(response, params);
                    applyFrontendPagination(response, params, paginate);
                }
                else if (query.pagination == QueryPagination.Page ||
                    query.pagination == QueryPagination.Offset ||
                    query.pagination == QueryPagination.Cursor) {
                    if (query.paginationHasMoreFunction) {
                        response.hasMore = _this.queryService.applyTransformer(result.body, query.paginationHasMoreFunction, query.httpQuery.url, false, tokensWithResponse);
                    }
                    else {
                        if (query.paginationHasMorePagesPath.length) {
                            response.hasMore = _this.queryService.getPath(result.body, query.paginationHasMorePagesPath.join('.'));
                        }
                        else if (query.paginationHasMoreTotalPagesPath.length) {
                            var totalPages = _this.queryService.getPath(result.body, query.paginationHasMoreTotalPagesPath.join('.'));
                            response.hasMore = tokens['paging']['page'] < totalPages;
                        }
                        else if (query.paginationHasMoreTotalRecordsPath.length) {
                            var totalRecords = _this.queryService.getPath(result.body, query.paginationHasMoreTotalRecordsPath.join('.'));
                            var limit = query.paginationPerPage;
                            var totalPages = void 0;
                            if (limit && totalRecords) {
                                totalPages = Math.ceil(totalRecords / limit);
                                response.hasMore = tokens['paging']['page'] < totalPages;
                            }
                        }
                    }
                    if (query.paginationTotalFunction) {
                        response.count = _this.queryService.applyTransformer(result.body, query.paginationTotalFunction, query.httpQuery.url, false, tokensWithResponse);
                    }
                    else if (query.paginationTotalPath && query.paginationTotalPath.length) {
                        response.count = _this.queryService.getPath(result.body, query.paginationTotalPath.join('.'));
                    }
                    if (query.pagination == QueryPagination.Cursor) {
                        if (query.paginationCursorPrevFunction) {
                            response.cursorPrev = _this.queryService.applyTransformer(result.body, query.paginationCursorPrevFunction, query.httpQuery.url, false, tokensWithResponse);
                        }
                        else if (query.paginationCursorPrevPath) {
                            response.cursorPrev = _this.queryService.getPath(result.body, query.paginationCursorPrevPath.join('.'));
                        }
                        if (query.paginationCursorNextFunction) {
                            response.cursorNext = _this.queryService.applyTransformer(result.body, query.paginationCursorNextFunction, query.httpQuery.url, false, tokensWithResponse);
                        }
                        else if (query.paginationCursorNextPath) {
                            response.cursorNext = _this.queryService.getPath(result.body, query.paginationCursorNextPath.join('.'));
                        }
                        response.hasMore = !!response.cursorNext;
                    }
                }
                return response;
            }));
        }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    RestApiResourceControllerService.prototype.modelGet = function (resource, modelDescription, params, body) {
        var query = modelDescription.getQuery;
        if (!query) {
            return throwError(new ServerRequestError("No get query specified for collection " + modelDescription.verboseNamePlural));
        }
        return this.getHttp(resource, query, modelDescription.getParameters, params, body, modelDescription.dbFields).pipe(map(function (response) {
            if (!response) {
                return;
            }
            response.results.forEach(function (item) {
                item.setUp(modelDescription);
            });
            return response;
        }));
    };
    RestApiResourceControllerService.prototype.getDetail = function (resource, query, parameters, params, columns) {
        var _this = this;
        if (parameters === void 0) { parameters = []; }
        if (columns === void 0) { columns = []; }
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var resourceParams = resource.parseParams(RestAPIResourceParams);
            var userQuery = isResourceQueryCustom(resource, query.queryType);
            var tokens = _this.queryTokensService.mergeTokens(_this.queryTokensService.generalTokens(), _this.queryTokensService.modelGetDetailTokens(params, parameters, userQuery));
            return _this.httpQueryService.requestBody(query.httpQuery, {
                resource: resource.uniqueName,
                baseQuery: resourceParams.baseHttpQuery,
                tokens: tokens,
                customProxy: resourceParams ? resourceParams.customProxy : undefined
            });
        }), map(function (result) {
            if (!result) {
                return result;
            }
            var object = isArray(result) ? result[0] : result;
            var model = _this.createModel().deserialize(undefined, object);
            model.deserializeAttributes(columns);
            return model;
        }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    RestApiResourceControllerService.prototype.modelGetDetail = function (resource, modelDescription, idField, id, params) {
        if (modelDescription.getDetailQuery) {
            params = __assign({}, params, { PK: id // TODO: Remove deprecated tokens
             });
            if (isSet(modelDescription.primaryKeyField)) {
                params[idField] = id;
            }
            var detailQueryParameters = this.getDetailParametersOrDefaults(resource, modelDescription);
            return this.getDetail(resource, modelDescription.getDetailQuery, detailQueryParameters, params, modelDescription.dbFields).pipe(map(function (result) {
                if (!result) {
                    return result;
                }
                result.setUp(modelDescription);
                return result;
            }));
        }
        else if (modelDescription.getQuery) {
            params = __assign({}, params, { PK: id // TODO: Remove deprecated tokens
             });
            if (isSet(modelDescription.primaryKeyField)) {
                params[idField] = id;
            }
            return this.getHttp(resource, modelDescription.getQuery, modelDescription.getParameters, params, undefined, modelDescription.dbFields).pipe(map(function (result) {
                if (!result || !result.results.length) {
                    return;
                }
                var model = result.results[0];
                model.setUp(modelDescription);
                return model;
            }));
        }
    };
    RestApiResourceControllerService.prototype.serializeModelField = function (modelInstance, name) {
        if (!isSet(name) || !modelInstance.modelDescription) {
            return;
        }
        var field = modelInstance.modelDescription.dbFields.find(function (item) { return item.name == name; });
        if (!field) {
            return;
        }
        var value = modelInstance.getAttribute(name);
        var fieldDescription = getFieldDescriptionByType(field.field);
        if (fieldDescription.serializeValue) {
            value = fieldDescription.serializeValue(value, field);
        }
        return value;
    };
    RestApiResourceControllerService.prototype.serializeModelParameters = function (modelInstance, parameters) {
        return fromPairs(parameters.map(function (parameter) {
            var value = modelInstance.getAttribute(parameter.name);
            var fieldDescription = getFieldDescriptionByType(parameter.field);
            if (fieldDescription.serializeValue) {
                value = fieldDescription.serializeValue(value, parameter);
            }
            return [parameter.name, value];
        }));
    };
    RestApiResourceControllerService.prototype.modelCreate = function (resource, modelDescription, modelInstance, fields) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var resourceParams = resource.parseParams(RestAPIResourceParams);
            var query = cloneDeep(modelDescription.createQuery.httpQuery);
            if (!query) {
                throw new ServerRequestError("No create query specified for collection " + modelDescription.verboseNamePlural);
            }
            var modelParameters = _this.getCreateParametersOrDefaults(resource, modelDescription);
            var data = _this.serializeModelParameters(modelInstance, modelParameters);
            // TODO: Legacy default body
            if (!query.body) {
                query.body = data;
            }
            var tokens = _this.queryTokensService.mergeTokens(_this.queryTokensService.generalTokens(), _this.queryTokensService.modelCreateTokens(data, modelParameters));
            return _this.httpQueryService.requestBody(query, {
                resource: resource.uniqueName,
                baseQuery: resourceParams.baseHttpQuery,
                tokens: tokens,
                customProxy: resourceParams ? resourceParams.customProxy : undefined
            });
        }), map(function (result) {
            var instanceData = extend(modelInstance.serialize(), result);
            var instance = _this.createModel().deserialize(modelDescription.model, instanceData);
            instance.setUp(modelDescription);
            instance.deserializeAttributes(modelDescription.dbFields);
            return instance;
        }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    RestApiResourceControllerService.prototype.modelUpdate = function (resource, modelDescription, modelInstance, fields) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var _a;
            var resourceParams = resource.parseParams(RestAPIResourceParams);
            var query = cloneDeep(modelDescription.updateQuery.httpQuery);
            if (!query) {
                throw new ServerRequestError("No update query specified for collection " + modelDescription.verboseNamePlural);
            }
            var modelParameters = _this.getUpdateParametersOrDefaults(resource, modelDescription);
            var primaryKeyValue = isSet(modelDescription.primaryKeyField)
                ? _this.serializeModelField(modelInstance, modelDescription.primaryKeyField)
                : undefined;
            var data = __assign({}, _this.serializeModelParameters(modelInstance, modelParameters), (isSet(primaryKeyValue) ? (_a = {}, _a[modelDescription.primaryKeyField] = primaryKeyValue, _a) : undefined));
            // TODO: Legacy default body
            if (!query.body) {
                query.body = data;
            }
            var tokens = _this.queryTokensService.mergeTokens(_this.queryTokensService.generalTokens(), _this.queryTokensService.modelUpdateTokens(data, modelParameters, primaryKeyValue, fields));
            return _this.httpQueryService.requestBody(query, {
                resource: resource.uniqueName,
                baseQuery: resourceParams.baseHttpQuery,
                tokens: tokens,
                customProxy: resourceParams ? resourceParams.customProxy : undefined
            });
        }), map(function (result) {
            var instanceData = extend(modelInstance.serialize(), result);
            var instance = _this.createModel().deserialize(modelDescription.model, instanceData);
            instance.setUp(modelDescription);
            instance.deserializeAttributes(modelDescription.dbFields);
            return instance;
        }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    RestApiResourceControllerService.prototype.modelDelete = function (resource, modelDescription, modelInstance) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var _a;
            var resourceParams = resource.parseParams(RestAPIResourceParams);
            var query = modelDescription.deleteQuery.httpQuery;
            if (!query) {
                throw new ServerRequestError("No delete query specified for collection " + modelDescription.verboseNamePlural);
            }
            var modelParameters = _this.getDeleteParametersOrDefaults(resource, modelDescription);
            var primaryKeyValue = isSet(modelDescription.primaryKeyField)
                ? _this.serializeModelField(modelInstance, modelDescription.primaryKeyField)
                : undefined;
            var data = __assign({}, _this.serializeModelParameters(modelInstance, modelParameters), (isSet(primaryKeyValue) ? (_a = {}, _a[modelDescription.primaryKeyField] = primaryKeyValue, _a) : undefined));
            var tokens = _this.queryTokensService.mergeTokens(_this.queryTokensService.generalTokens(), _this.queryTokensService.modelDeleteTokens(data, primaryKeyValue));
            return _this.httpQueryService.requestBody(query, {
                resource: resource.uniqueName,
                baseQuery: resourceParams.baseHttpQuery,
                tokens: tokens,
                customProxy: resourceParams ? resourceParams.customProxy : undefined
            });
        }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    RestApiResourceControllerService.prototype.query = function (resource, query, params) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var resourceParams = resource.parseParams(RestAPIResourceParams);
            if (!query) {
                return of(undefined);
            }
            var tokens = _this.queryTokensService.mergeTokens(_this.queryTokensService.generalTokens());
            return _this.httpQueryService
                .request(query, {
                resource: resource.uniqueName,
                baseQuery: resourceParams.baseHttpQuery,
                tokens: tokens,
                customProxy: resourceParams ? resourceParams.customProxy : undefined
            })
                .pipe(map(function (response) {
                var resultBody = response.body;
                resultBody = _this.queryService.applyTransformer(resultBody, query.responseTransformer, query.url, false, tokens);
                resultBody = _this.queryService.getPath(resultBody, query.responsePath);
                return resultBody;
            }));
        }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    RestApiResourceControllerService.prototype.aggregate = function (resource, query, yFunc, yColumn, parameters, params) {
        var _this = this;
        if (parameters === void 0) { parameters = []; }
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var _a;
            var resourceParams = resource.parseParams(RestAPIResourceParams);
            if (!query) {
                return of(undefined);
            }
            var userQuery = isResourceQueryCustom(resource, QueryType.Http);
            var tokens = _this.queryTokensService.mergeTokens(_this.queryTokensService.generalTokens(), _this.queryTokensService.modelGetTokens(params, parameters, userQuery), _this.queryTokensService.modelAggregateTokens(yFunc, yColumn, parameters, params, userQuery), _this.queryTokensService.paginationTokens(QueryPagination.Page, (_a = {}, _a[PER_PAGE_PARAM] = 1000, _a)));
            return _this.httpQueryService
                .request(query, {
                resource: resource.uniqueName,
                baseQuery: resourceParams.baseHttpQuery,
                tokens: tokens,
                customProxy: resourceParams ? resourceParams.customProxy : undefined
            })
                .pipe(map(function (response) {
                var resultBody = response.body;
                resultBody = _this.queryService.applyTransformer(resultBody, query.responseTransformer, query.url, false, tokens);
                resultBody = _this.queryService.getPath(resultBody, query.responsePath);
                return resultBody;
            }));
        }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    RestApiResourceControllerService.prototype.getHttpAggregate = function (resource, query, yFunc, yColumn, parameters, params, columns) {
        if (parameters === void 0) { parameters = []; }
        if (columns === void 0) { columns = []; }
        return this.getHttp(resource, query, parameters, params, undefined, columns, false).pipe(map(function (response) { return aggregateGetResponse(response, yFunc, yColumn); }));
    };
    RestApiResourceControllerService.prototype.modelAggregate = function (resource, modelDescription, yFunc, yColumn, params) {
        if (modelDescription.aggregateQuery && modelDescription.aggregateQuery.httpQuery) {
            var query = modelDescription.aggregateQuery.httpQuery;
            return this.aggregate(resource, query, yFunc, yColumn, modelDescription.aggregateParameters, params);
        }
        else if (modelDescription.getQuery && modelDescription.getQuery.httpQuery) {
            var columns = modelDescription.fields
                .filter(function (item) { return item.type == ModelFieldType.Db; })
                .map(function (item) { return modelFieldToRawListViewSettingsColumn(item); });
            return this.getHttpAggregate(resource, modelDescription.getQuery, yFunc, yColumn, modelDescription.getParameters, params, columns);
        }
        else {
            return of(undefined);
        }
    };
    RestApiResourceControllerService.prototype.group = function (resource, query, xColumns, yFunc, yColumn, parameters, params) {
        var _this = this;
        if (parameters === void 0) { parameters = []; }
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var _a;
            var resourceParams = resource.parseParams(RestAPIResourceParams);
            if (!query) {
                return of(undefined);
            }
            var userQuery = isResourceQueryCustom(resource, QueryType.Http);
            var tokens = _this.queryTokensService.mergeTokens(_this.queryTokensService.generalTokens(), _this.queryTokensService.modelGetTokens(params, parameters, userQuery), _this.queryTokensService.modelGroupTokens(xColumns, yFunc, yColumn, parameters, params, userQuery), _this.queryTokensService.paginationTokens(QueryPagination.Page, (_a = {}, _a[PER_PAGE_PARAM] = 1000, _a)));
            return _this.httpQueryService
                .request(query, {
                resource: resource.uniqueName,
                baseQuery: resourceParams.baseHttpQuery,
                tokens: tokens,
                customProxy: resourceParams ? resourceParams.customProxy : undefined
            })
                .pipe(map(function (response) {
                var resultBody = response.body;
                resultBody = _this.queryService.applyTransformer(resultBody, query.responseTransformer, query.url, false, tokens);
                resultBody = _this.queryService.getPath(resultBody, query.responsePath);
                return resultBody;
            }), map(function (response) {
                if (!response.length) {
                    return [];
                }
                return response.map(function (item) {
                    var result = new DataGroup();
                    if (xColumns[0]) {
                        result.group = item[xColumns[0].xColumn];
                    }
                    if (xColumns[1]) {
                        result.group = item[xColumns[1].xLookup];
                    }
                    result.value = item[yColumn];
                    return result;
                });
            }));
        }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    RestApiResourceControllerService.prototype.getGroup = function (resource, query, xColumns, yFunc, yColumn, parameters, params, columns) {
        if (parameters === void 0) { parameters = []; }
        if (columns === void 0) { columns = []; }
        return this.getHttp(resource, query, parameters, params, undefined, columns, false).pipe(map(function (response) { return groupGetResponse(response, xColumns, yFunc, yColumn); }));
    };
    RestApiResourceControllerService.prototype.modelGroup = function (resource, modelDescription, xColumns, yFunc, yColumn, params) {
        if (modelDescription.groupQuery && modelDescription.groupQuery.httpQuery) {
            var query = modelDescription.groupQuery.httpQuery;
            return this.group(resource, query, xColumns, yFunc, yColumn, modelDescription.groupParameters, params);
        }
        else if (modelDescription.getQuery && modelDescription.getQuery.httpQuery) {
            var columns = modelDescription.fields
                .filter(function (item) { return item.type == ModelFieldType.Db; })
                .map(function (item) { return modelFieldToRawListViewSettingsColumn(item); });
            return this.getGroup(resource, modelDescription.getQuery, xColumns, yFunc, yColumn, modelDescription.getParameters, params, columns);
        }
        else {
            return of(undefined);
        }
    };
    RestApiResourceControllerService.prototype.uploadFile = function (resource, storage, query, file, path, fileName) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var resourceParams = resource.parseParams(RestAPIResourceParams);
            if (!query || !query.httpQuery) {
                return of(undefined);
            }
            var tokens = _this.queryTokensService.mergeTokens(_this.queryTokensService.generalTokens(), _this.queryTokensService.uploadFileTokens(file, path, fileName));
            return _this.httpQueryService
                .executeRequest(query.httpQuery, {
                resource: resource.uniqueName,
                baseQuery: resourceParams.baseHttpQuery,
                tokens: tokens,
                customProxy: resourceParams ? resourceParams.customProxy : undefined
            })
                .pipe(map(function (event) {
                if (event.type == HttpEventType.Response) {
                    var resultBody = event.body;
                    resultBody = _this.queryService.applyTransformer(resultBody, query.httpQuery.responseTransformer, query.httpQuery.url, false, tokens);
                    resultBody = _this.queryService.getPath(resultBody, query.httpQuery.responsePath);
                    return {
                        result: {
                            uploadedPath: resultBody,
                            uploadedUrl: resultBody,
                            response: event
                        },
                        state: {
                            downloadProgress: 1,
                            uploadProgress: 1
                        }
                    };
                }
                else if (event.type == HttpEventType.UploadProgress) {
                    return {
                        state: {
                            uploadProgress: event.loaded / event.total,
                            downloadProgress: 0,
                            uploadLoaded: event.loaded,
                            uploadTotal: event.total
                        }
                    };
                }
                else if (event.type == HttpEventType.DownloadProgress) {
                    return {
                        state: {
                            uploadProgress: 1,
                            downloadProgress: event.loaded / event.total,
                            downloadLoaded: event.loaded,
                            downloadTotal: event.total
                        }
                    };
                }
            }), filter(function (item) { return item !== undefined; }));
        }), this.apiService.catchApiError());
    };
    // TODO: Remove deprecated action descriptions
    RestApiResourceControllerService.prototype.actionDescriptionGet = function (resource) {
        if (!resource.params['action_descriptions']) {
            return of([]);
        }
        var result = resource.params['action_descriptions']
            .map(function (item) {
            var data = item['action_description'];
            data['params']['type'] = ActionType.Query;
            data['params']['query_action'] = item['query']
                ? {
                    query: {
                        query_type: QueryType.Http,
                        http_query: item['query']
                    }
                }
                : undefined;
            return new ActionDescription().deserialize(data);
        })
            .map(function (item) {
            item.resource = resource.uniqueName;
            return item;
        });
        return of(result);
    };
    RestApiResourceControllerService.prototype.actionExecute = function (resource, query, parameters, params, rawErrors) {
        var _this = this;
        if (parameters === void 0) { parameters = []; }
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var resourceParams = resource.parseParams(RestAPIResourceParams);
            if (!query || !query.httpQuery) {
                return of(undefined);
            }
            var userQuery = isResourceQueryCustom(resource, query.queryType);
            var tokens = _this.queryTokensService.mergeTokens(_this.queryTokensService.generalTokens(), _this.queryTokensService.actionExecuteTokens(params, parameters, userQuery));
            return _this.httpQueryService
                .request(query.httpQuery, {
                resource: resource.uniqueName,
                baseQuery: resourceParams.baseHttpQuery,
                tokens: tokens,
                customProxy: resourceParams ? resourceParams.customProxy : undefined
            })
                .pipe(map(function (result) {
                if (query.httpQuery.responseType == HttpResponseType.Blob) {
                    return {
                        response: result,
                        blob: result.body
                    };
                }
                else {
                    var resultBody = result.body;
                    resultBody = _this.queryService.applyTransformer(resultBody, query.httpQuery.responseTransformer, query.httpQuery.url, false, tokens);
                    resultBody = _this.queryService.getPath(resultBody, query.httpQuery.responsePath);
                    if (isPlainObject(resultBody) || isArray(resultBody)) {
                        return {
                            response: result,
                            json: resultBody
                        };
                    }
                    else {
                        return {
                            response: result,
                            text: resultBody
                        };
                    }
                }
            }));
        }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    return RestApiResourceControllerService;
}(ResourceController));
export { RestApiResourceControllerService };
