import { ChangeDetectorRef, EventEmitter, OnDestroy, OnInit, QueryList } from '@angular/core';
import isEqual from 'lodash/isEqual';
import toPairs from 'lodash/toPairs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, merge } from 'rxjs';
import { debounceTime, delayWhen, filter, first, map, pairwise } from 'rxjs/operators';
import { DialogService } from '@common/dialogs';
import { localize } from '@common/localize';
import { NotificationService } from '@common/notifications';
import { BasePopupComponent } from '@common/popups';
import { AdminMode } from '@modules/admin-mode';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ServerRequestError } from '@modules/api';
import { createFormFieldFactory } from '@modules/fields';
import { CurrentEnvironmentStore, CurrentProjectStore, ProjectGroup, ProjectGroupService, ProjectGroupStore, ProjectInviteService, ProjectPermissionType, ProjectUser, ProjectUserService } from '@modules/projects';
import { RoutingService } from '@modules/routing';
import { GuideService } from '@modules/tutorial';
import { CurrentUserStore } from '@modules/users';
import { controlValue, errorToString } from '@shared';
import { ProjectUserForm } from '../../forms/project-user.form';
import { ProjectGroupChangeForm } from './project-group-change.form';
var Tab;
(function (Tab) {
    Tab["Members"] = "members";
    Tab["PagePermissions"] = "page_permissions";
    Tab["ProjectPermissions"] = "project_permissions";
    Tab["Properties"] = "properties";
})(Tab || (Tab = {}));
var ProjectGroupChangeComponent = /** @class */ (function () {
    function ProjectGroupChangeComponent(mode, projectGroupService, projectGroupStore, currentProjectStore, currentEnvironmentStore, popupComponent, notificationService, formGroupChange, routing, guideService, cd, projectUserService, projectInviteService, dialogService, analyticsService, currentUserStore, formUser) {
        this.mode = mode;
        this.projectGroupService = projectGroupService;
        this.projectGroupStore = projectGroupStore;
        this.currentProjectStore = currentProjectStore;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.popupComponent = popupComponent;
        this.notificationService = notificationService;
        this.formGroupChange = formGroupChange;
        this.routing = routing;
        this.guideService = guideService;
        this.cd = cd;
        this.projectUserService = projectUserService;
        this.projectInviteService = projectInviteService;
        this.dialogService = dialogService;
        this.analyticsService = analyticsService;
        this.currentUserStore = currentUserStore;
        this.formUser = formUser;
        this.members = true;
        this.autoSave = false;
        this.propertiesEnabled = true;
        this.deleteEnabled = true;
        this.saved = new EventEmitter();
        this.deleted = new EventEmitter();
        this.pagePermissionActions = new QueryList();
        this.allTabItems = [
            {
                name: Tab.Members,
                title: localize('Members')
            },
            {
                name: Tab.PagePermissions,
                title: localize('Page Permissions')
            },
            {
                name: Tab.ProjectPermissions,
                title: localize('App Permissions')
            },
            {
                name: Tab.Properties,
                title: localize('Properties')
            }
        ];
        this.tabItems = [];
        this.tabs = Tab;
        this.users = [];
        this.invites = [];
        this.loading = false;
        this.loadingUsers = false;
        this.editProperties = false;
        this.createField = createFormFieldFactory();
        this.deleteUserLoading = [];
    }
    ProjectGroupChangeComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.initTabs();
        if (this.group) {
            this.getUsers();
        }
        this.initForm();
        this.formUser.init(new ProjectUser().deserialize({
            user: null,
            user_email: null,
            group: this.group,
            properties: {}
        }));
        this.formGroupChange.statusChanges.pipe(untilDestroyed(this)).subscribe(function () {
            _this.cd.markForCheck();
        });
    };
    ProjectGroupChangeComponent.prototype.ngOnDestroy = function () { };
    ProjectGroupChangeComponent.prototype.initTabs = function () {
        var _this = this;
        this.tabItems = this.allTabItems.filter(function (item) {
            if (item.name == Tab.Members) {
                return _this.members;
            }
            else if ([Tab.PagePermissions, Tab.ProjectPermissions].includes(item.name)) {
                return !_this.group || !_this.group.protected;
            }
            else if (item.name == Tab.Properties) {
                return _this.propertiesEnabled;
            }
            else {
                return true;
            }
        });
        this.activeTabName = this.tabItems[0].name;
    };
    ProjectGroupChangeComponent.prototype.setActiveTabName = function (name) {
        this.activeTabName = name;
        this.cd.markForCheck();
    };
    ProjectGroupChangeComponent.prototype.initForm = function () {
        var _this = this;
        this.formGroupChange.init(this.group);
        this.formGroupChange.controls.model_permissions.initialized$.pipe(untilDestroyed(this)).subscribe(function () {
            _this.cd.markForCheck();
            merge(_this.formGroupChange.controls.permissions.valueChanges, _this.formGroupChange.controls.page_permissions.valueChanges, _this.formGroupChange.controls.model_permissions.valueChanges)
                .pipe(debounceTime(2000), untilDestroyed(_this))
                .subscribe(function () { return _this.guideService.completeTaskProgress('create_group', 1, 'set_permissions'); });
            if (_this.group && _this.autoSave) {
                _this.formGroupChange.valueChanges.pipe(debounceTime(700), untilDestroyed(_this)).subscribe(function () {
                    _this.submit();
                });
            }
            _this.initAnalytics();
        });
    };
    ProjectGroupChangeComponent.prototype.getUsers = function () {
        var _this = this;
        if (!this.members) {
            return;
        }
        this.loadingUsers = true;
        this.cd.markForCheck();
        combineLatest(this.projectUserService.get(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName), this.projectInviteService.get(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName))
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var users = _a[0], invites = _a[1];
            _this.users = users.filter(function (item) { return item.group && item.group.uid == _this.group.uid; });
            _this.invites = invites.filter(function (item) { return item.group && item.group.uid == _this.group.uid; });
            _this.loadingUsers = false;
            _this.cd.markForCheck();
        }, function () {
            _this.loadingUsers = false;
            _this.cd.markForCheck();
        });
    };
    ProjectGroupChangeComponent.prototype.submit = function () {
        var _this = this;
        this.loading = true;
        this.cd.markForCheck();
        this.formGroupChange
            .submit()
            .pipe(untilDestroyed(this))
            .subscribe(function (group) {
            if (_this.group) {
                _this.notificationService.success('Saved', 'Team was successfully updated');
                _this.analyticsService.sendSimpleEvent(AnalyticsEvent.Team.Updated, {
                    TeamID: _this.group ? _this.group.name : undefined,
                    ProjectPermissionsChanged: !isEqual(_this.group.getPermissions(ProjectPermissionType.Project), group.getPermissions(ProjectPermissionType.Project)),
                    CollectionPermissionsChanged: !isEqual(_this.group.getPermissions(ProjectPermissionType.Model), group.getPermissions(ProjectPermissionType.Model)),
                    PropertiesChanged: !isEqual(_this.group.properties, group.properties),
                    Mode: _this.mode
                });
            }
            else {
                _this.notificationService.success('Created', 'Team was successfully added to current App');
                _this.analyticsService.sendSimpleEvent(AnalyticsEvent.Team.Created, {
                    TeamID: _this.group ? _this.group.name : undefined,
                    Mode: _this.mode
                });
            }
            _this.saved.emit(group);
            _this.cd.markForCheck();
        }, function (error) {
            if (error instanceof ServerRequestError && error.errors.length) {
                _this.notificationService.error('Saving team failed', error.errors[0]);
            }
            else {
                _this.notificationService.error('Saving team failed', errorToString(error));
            }
            _this.loading = false;
            _this.cd.markForCheck();
        });
    };
    ProjectGroupChangeComponent.prototype.back = function () {
        if (this.popupComponent) {
            this.popupComponent.close();
        }
        else {
            this.routing.navigateApp(this.currentProjectStore.instance.settingsGroupsLink);
        }
    };
    ProjectGroupChangeComponent.prototype.setPagePermissionsFullAccess = function () {
        var wasNoAccess = this.formGroupChange.controls.page_permissions.controls.some(function (item) { return !item.controls.actions.isSelectedAny(); });
        this.formGroupChange.controls.page_permissions.setEveryControlAllActions(true);
        this.pagePermissionActions.forEach(function (item) { return item.setCustom(false); });
        if (wasNoAccess) {
            this.analyticsService.sendSimpleEvent(AnalyticsEvent.Permission.PageAdded, {
                Mode: this.mode,
                All: true
            });
        }
    };
    ProjectGroupChangeComponent.prototype.setPagePermissionsReadOnly = function () {
        var wasNoAccess = this.formGroupChange.controls.page_permissions.controls.some(function (item) { return !item.controls.actions.isSelectedAny(); });
        this.formGroupChange.controls.page_permissions.setEveryControlAction('read', true);
        this.formGroupChange.controls.page_permissions.setEveryControlAction('write', false);
        this.formGroupChange.controls.page_permissions.setEveryControlAction('delete', false);
        this.pagePermissionActions.forEach(function (item) { return item.setCustom(false); });
        if (wasNoAccess) {
            this.analyticsService.sendSimpleEvent(AnalyticsEvent.Permission.PageAdded, {
                Mode: this.mode,
                All: true
            });
        }
    };
    ProjectGroupChangeComponent.prototype.setPagePermissionsNoAccess = function () {
        var wasAccess = this.formGroupChange.controls.page_permissions.controls.some(function (item) {
            return item.controls.actions.isSelectedAny();
        });
        this.formGroupChange.controls.page_permissions.setEveryControlAllActions(false);
        this.pagePermissionActions.forEach(function (item) { return item.setCustom(false); });
        if (wasAccess) {
            this.analyticsService.sendSimpleEvent(AnalyticsEvent.Permission.PageRemoved, {
                Mode: this.mode,
                All: true
            });
        }
    };
    ProjectGroupChangeComponent.prototype.toggleEditProperties = function () {
        this.editProperties = !this.editProperties;
        this.cd.markForCheck();
        if (this.editProperties && !this.formGroupChange.isPropertiesSet()) {
            this.formGroupChange.controls.properties.patchValue({ property1: 'value1' });
        }
    };
    ProjectGroupChangeComponent.prototype.requestDelete = function () {
        var _this = this;
        this.dialogService
            .warning({
            title: 'Deleting',
            description: 'Are you sure want to delete this team from App?'
        })
            .pipe(filter(function (result) { return result == true; }), untilDestroyed(this))
            .subscribe(function () {
            _this.projectGroupService
                .delete(_this.currentProjectStore.instance.uniqueName, _this.currentEnvironmentStore.instance.uniqueName, _this.group)
                .pipe(delayWhen(function () { return _this.projectGroupStore.getFirst(true); }), untilDestroyed(_this))
                .subscribe(function () {
                _this.deleted.emit(_this.group);
                _this.notificationService.success('Deleted', 'Team was successfully deleted from App');
                _this.analyticsService.sendSimpleEvent(AnalyticsEvent.Team.Deleted, {
                    TeamID: _this.group ? _this.group.name : undefined,
                    Mode: _this.mode
                });
                _this.back();
            });
        });
    };
    ProjectGroupChangeComponent.prototype.submitUser = function () {
        var _this = this;
        var group = this.formUser.form.value['group'];
        this.formUser
            .submit()
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            _this.notificationService.success('Created', 'User was successfully added to current App');
            _this.formUser.form.controls['user_email'].reset();
            _this.cd.markForCheck();
            _this.getUsers();
            _this.analyticsService.sendSimpleEvent(AnalyticsEvent.Share.MemberInvited, {
                Email: result.getEmail(),
                Mode: _this.mode,
                CustomizationPermission: _this.currentProjectStore.instance.hasGroupCustomizationPermission(group),
                Source: 'project_team'
            });
        }, function (error) {
            _this.loading = false;
            _this.cd.markForCheck();
            if (error instanceof ServerRequestError && error.errors.length) {
                _this.notificationService.error('Update Failed', error.errors[0]);
            }
            else {
                _this.notificationService.error('Update Failed', errorToString(error));
            }
        });
    };
    ProjectGroupChangeComponent.prototype.onGroupNameChange = function (value) {
        this.formGroupChange.controls.name.patchValue(value);
        this.cd.markForCheck();
    };
    ProjectGroupChangeComponent.prototype.initAnalytics = function () {
        var _this = this;
        merge(this.formGroupChange.controls.permissions.valueChanges, this.formGroupChange.controls.page_permissions.valueChanges, this.formGroupChange.controls.model_permissions.valueChanges)
            .pipe(first(), untilDestroyed(this))
            .subscribe(function () {
            _this.analyticsService.sendSimpleEvent(AnalyticsEvent.Permission.StartedToSetUp, {
                TeamID: _this.group ? _this.group.name : undefined,
                Mode: _this.mode
            });
        });
        merge.apply(void 0, [
            this.formGroupChange.controls.permissions.controls.project_settings,
            this.formGroupChange.controls.permissions.controls.project_billing,
            this.formGroupChange.controls.permissions.controls.project_access,
            this.formGroupChange.controls.permissions.controls.project_customization
        ].map(function (control) {
            var name = toPairs(_this.formGroupChange.controls.permissions.controls)
                .filter(function (_a) {
                var k = _a[0], v = _a[1];
                return v === control;
            })
                .map(function (_a) {
                var k = _a[0], v = _a[1];
                return k;
            })[0];
            return controlValue(control.controls.enabled).pipe(pairwise(), map(function (_a) {
                var prev = _a[0], current = _a[1];
                if (!prev && current) {
                    return { enabled: true, name: name };
                }
                else if (prev && !current) {
                    return { enabled: false, name: name };
                }
            }));
        })).pipe(untilDestroyed(this))
            .subscribe(function (result) {
            if (result && result.enabled) {
                _this.analyticsService.sendSimpleEvent(AnalyticsEvent.Permission.ProjectAdded, {
                    Name: result.name,
                    TeamID: _this.group ? _this.group.name : undefined,
                    Mode: _this.mode
                });
            }
            else if (result && !result.enabled) {
                _this.analyticsService.sendSimpleEvent(AnalyticsEvent.Permission.ProjectRemoved, {
                    Name: result.name,
                    TeamID: _this.group ? _this.group.name : undefined,
                    Mode: _this.mode
                });
            }
        });
    };
    return ProjectGroupChangeComponent;
}());
export { ProjectGroupChangeComponent };
