<div class="calendar__header">
  <div class="calendar-tabs">
    <a
      href="javascript:void(0)"
      (click)="setParams(typeParams(calendarTypes.Day))"
      class="calendar-tabs__item"
      [class.calendar-tabs__item--active]="type == calendarTypes.Day"
      [appTintColor]="'accentColor' | appThemeColorHexOption: true | async"
      [appTintColorStyle]="tintStyles.Primary"
    >
      {{ 'Day' | localize }}
    </a>
    <a
      href="javascript:void(0)"
      (click)="setParams(typeParams(calendarTypes.Week))"
      class="calendar-tabs__item"
      [class.calendar-tabs__item--active]="type == calendarTypes.Week"
      [appTintColor]="'accentColor' | appThemeColorHexOption: true | async"
      [appTintColorStyle]="tintStyles.Primary"
    >
      {{ 'Week' | localize }}
    </a>
    <a
      href="javascript:void(0)"
      (click)="setParams(typeParams(calendarTypes.Month))"
      class="calendar-tabs__item"
      [class.calendar-tabs__item--active]="type == calendarTypes.Month"
      [appTintColor]="'accentColor' | appThemeColorHexOption: true | async"
      [appTintColorStyle]="tintStyles.Primary"
    >
      {{ 'Month' | localize }}
    </a>
    <a
      href="javascript:void(0)"
      (click)="setParams(typeParams(calendarTypes.Year))"
      class="calendar-tabs__item"
      [class.calendar-tabs__item--active]="type == calendarTypes.Year"
      [appTintColor]="'accentColor' | appThemeColorHexOption: true | async"
      [appTintColorStyle]="tintStyles.Primary"
    >
      {{ 'Year' | localize }}
    </a>
  </div>
  <h2 class="calendar__title">
    {{ title }}
  </h2>
  <div class="calendar-pagination">
    <a
      href="javascript:void(0)"
      [appTintColor]="'accentColor' | appThemeColorHexOption: true | async"
      [appTintColorStyle]="tintStyles.Primary"
      (click)="setParams(prevParams)"
      class="calendar-pagination__arrow icon-arrow_backward_2"
    ></a>
    <a href="javascript:void(0)" class="calendar-pagination__current" (click)="setParams(nowParams)">
      {{ 'Today' | localize }}
    </a>
    <a
      href="javascript:void(0)"
      [appTintColor]="'accentColor' | appThemeColorHexOption: true | async"
      [appTintColorStyle]="tintStyles.Primary"
      (click)="setParams(nextParams)"
      class="calendar-pagination__arrow icon-arrow_forward_2"
    ></a>
  </div>
</div>
