var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { HttpClient, HttpHeaders } from '@angular/common/http';
import cloneDeep from 'lodash/cloneDeep';
import flatten from 'lodash/flatten';
import { combineLatest, of } from 'rxjs';
import { map, publishLast, refCount, share, switchMap } from 'rxjs/operators';
import { ActionDescription, ActionType, QueryAction, SegueType } from '@modules/actions';
import { ApiService } from '@modules/api';
import { modelFieldToRawListViewSettingsColumn } from '@modules/customize';
import { FieldType } from '@modules/fields';
import { ModelDescriptionStore } from '@modules/model-queries';
import { ModelFieldType, PER_PAGE_PARAM } from '@modules/models';
import { fieldsToProviderItems, inputFieldProviderItemsFromModelGet, parametersToProviderItems, parametersToProviderItemsFlat } from '@modules/parameters';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { ActionQuery, QueryType, StorageQuery } from '@modules/queries';
import { ResourceControllerService } from '@modules/resources';
import { isSet } from '@shared';
var ActionDescriptionService = /** @class */ (function () {
    function ActionDescriptionService(apiService, http, currentProjectStore, currentEnvironmentStore, modelDescriptionStore, resourceControllerService) {
        this.apiService = apiService;
        this.http = http;
        this.currentProjectStore = currentProjectStore;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.modelDescriptionStore = modelDescriptionStore;
        this.resourceControllerService = resourceControllerService;
    }
    ActionDescriptionService.prototype.getFromResources = function (project, environment) {
        var _this = this;
        var controllers = project
            .getEnvironmentResources(environment.uniqueName)
            .map(function (resource) {
            var controller = _this.resourceControllerService.get(resource.type);
            return controller && controller.actionDescriptionGet ? controller.actionDescriptionGet(resource) : undefined;
        })
            .filter(function (item) { return item != undefined; });
        if (!controllers.length) {
            return of([]);
        }
        return combineLatest.apply(void 0, controllers).pipe(map(function (result) { return flatten(result.filter(function (item) { return item != undefined; })); }), map(function (result) {
            result.forEach(function (projectModel) {
                projectModel.project = project.uniqueName;
            });
            return result;
        }), share());
    };
    ActionDescriptionService.prototype.getModelAutoActions = function (resource, modelDescription) {
        if (!resource) {
            return [];
        }
        var controller = this.resourceControllerService.get(resource.type);
        return modelDescription
            .autoActions()
            .filter(function (action) {
            return modelDescription[action.query];
        })
            .filter(function (action) {
            if (resource.demo) {
                return !['create', 'update', 'delete'].includes(action.name);
            }
            else {
                return true;
            }
        })
            .map(function (action) {
            var instance = new ActionDescription();
            var queryAction = new QueryAction();
            var query = new ActionQuery();
            query.queryType = QueryType.Simple;
            query.simpleQuery = new query.simpleQueryClass();
            query.simpleQuery.name = action.uniqueName;
            queryAction.query = query;
            instance.project = modelDescription.project;
            instance.resource = modelDescription.resource;
            instance.model = modelDescription.model;
            instance.modelAction = action.name;
            instance.name = action.uniqueName;
            instance.type = ActionType.Query;
            instance.protected = true;
            instance.queryAction = queryAction;
            var settings = controller.getModelDescriptionQuerySettings(resource, modelDescription, action.name);
            if (settings) {
                instance.actionParams = settings.parameters;
            }
            if (action.name == 'get') {
                instance.verboseName = "Get " + modelDescription.verboseName;
            }
            else if (action.name == 'get_detail') {
                instance.verboseName = "Get Detail " + modelDescription.verboseName;
            }
            else if (action.name == 'create') {
                instance.verboseName = "Create " + modelDescription.verboseName;
            }
            else if (action.name == 'update') {
                instance.verboseName = "Update " + modelDescription.verboseName;
            }
            else if (action.name == 'delete') {
                instance.verboseName = "Delete " + modelDescription.verboseName;
            }
            return instance;
        });
    };
    ActionDescriptionService.prototype.getStorageAutoActions = function (project, resource, storage) {
        return storage
            .autoActions()
            .filter(function (action) {
            return storage[action.query];
        })
            .map(function (action) {
            var instance = new ActionDescription();
            var queryAction = new QueryAction();
            var query = new StorageQuery();
            query.queryType = QueryType.Simple;
            query.simpleQuery = new query.simpleQueryClass();
            query.simpleQuery.name = action.uniqueName;
            queryAction.query = query;
            instance.project = project.uniqueName;
            instance.resource = resource.uniqueName;
            instance.storage = storage.uniqueName;
            instance.storageAction = action.name;
            instance.name = action.uniqueName;
            instance.type = ActionType.Query;
            instance.protected = true;
            instance.queryAction = queryAction;
            if (action.name == 'get_object_url') {
                instance.verboseName = "Get " + storage.name + " object URL";
                instance.description = 'get public accessible file URL with expiration date';
                instance.actionParams = storage.getObjectUrlParameters();
            }
            else if (action.name == 'upload') {
                instance.verboseName = "Upload file to " + storage.name;
                instance.actionParams = storage.uploadParameters();
            }
            else if (action.name == 'get') {
                instance.verboseName = "Get " + storage.name + " objects";
                instance.actionParams = storage.getParameters();
            }
            else if (action.name == 'create_directory') {
                instance.verboseName = "Create " + storage.name + " directory";
                instance.actionParams = storage.createDirectoryParameters();
            }
            else if (action.name == 'remove') {
                instance.verboseName = "Delete " + storage.name + " file";
                instance.actionParams = storage.removeParameters();
            }
            return instance;
        });
    };
    ActionDescriptionService.prototype.getAutoActions = function (options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        return combineLatest(this.currentProjectStore.getFirst(), this.modelDescriptionStore.getFirst()).pipe(map(function (_a) {
            var project = _a[0], modelDescriptions = _a[1];
            var modelActions = modelDescriptions
                .filter(function (modelDescription) {
                if (options.modelDescription) {
                    return modelDescription.isSame(options.modelDescription);
                }
                else if (options.modelDescriptions) {
                    return options.modelDescriptions.some(function (item) { return modelDescription.isSame(item); });
                }
                else {
                    return true;
                }
            })
                .sort(function (lhs, rhs) {
                var lhsName = String(lhs.verboseNamePlural || lhs.model).toLowerCase();
                var rhsName = String(rhs.verboseNamePlural || rhs.model).toLowerCase();
                if (lhsName < rhsName) {
                    return -1;
                }
                else if (lhsName > rhsName) {
                    return 1;
                }
                else {
                    return 0;
                }
            })
                .map(function (modelDescription) {
                var resource = _this.currentEnvironmentStore.resources.find(function (item) {
                    return item.uniqueName == modelDescription.resource;
                });
                return _this.getModelAutoActions(resource, modelDescription);
            });
            var storageActions = project
                .getStorages(_this.currentEnvironmentStore.instance.uniqueName)
                .filter(function (item) { return item.storage.isConfigured(); })
                .filter(function () {
                if (options.modelDescription || options.modelDescriptions) {
                    return false;
                }
                else {
                    return true;
                }
            })
                .sort(function (lhs, rhs) {
                var lhsName = String(lhs.storage.name || lhs.storage.uniqueName).toLowerCase();
                var rhsName = String(rhs.storage.name || rhs.storage.uniqueName).toLowerCase();
                if (lhsName < rhsName) {
                    return -1;
                }
                else if (lhsName > rhsName) {
                    return 1;
                }
                else {
                    return 0;
                }
            })
                .map(function (storage) { return _this.getStorageAutoActions(project, storage.resource, storage.storage); });
            return flatten(modelActions.concat(storageActions));
        }));
    };
    ActionDescriptionService.prototype.getOverrides = function (project, environment, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.environmentMethodURL(project.uniqueName, environment.uniqueName, 'action_descriptions/');
            var headers = new HttpHeaders();
            var params = __assign({}, (options.draft && { draft: '1' }));
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.get(url, { headers: headers, params: params });
        }), map(function (result) {
            return result.map(function (item) {
                var itemResult = new ActionDescription().deserialize(item);
                itemResult.project = project.uniqueName;
                return itemResult;
            });
        }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    ActionDescriptionService.prototype.applyOverrides = function (originalActions, overrideActions, options) {
        if (options === void 0) { options = {}; }
        overrideActions.forEach(function (overrideAction) {
            var originalAction = originalActions.find(function (item) { return item.resource == overrideAction.resource && item.name == overrideAction.name; });
            if (!originalAction) {
                if (!options.onlyExistent) {
                    originalActions.push(overrideAction);
                }
                return;
            }
            ['type', 'verboseName', 'icon', 'link', 'dynamicStatus', 'modelIds', 'bulk', 'query', 'orderAfter']
                .filter(function (item) { return overrideAction[item] !== undefined; })
                .forEach(function (item) { return (originalAction[item] = overrideAction[item]); });
            overrideAction.actionParams.forEach(function (overrideActionParam) {
                var originalActionParam = originalAction.actionParams.find(function (item) { return item.name == overrideAction.name; });
                if (!originalActionParam) {
                    return;
                }
                ['verboseName', 'field', 'required', 'defaultType', 'defaultValue']
                    .filter(function (item) { return originalActionParam.hasOwnProperty(item) && overrideActionParam[item] !== undefined; })
                    .forEach(function (item) { return (originalActionParam[item] = overrideActionParam[item]); });
            });
        });
        return originalActions;
    };
    ActionDescriptionService.prototype.getLinkActionParameters = function (link, pageParameters) {
        if (!link) {
            return [];
        }
        if (link.type == SegueType.Page) {
            return parametersToProviderItemsFlat((pageParameters || []).filter(function (item) { return item.name; }));
        }
        else if ([SegueType.ModelMassEdit, SegueType.ModelDelete, SegueType.ModelDelete].includes(link.type)) {
            return fieldsToProviderItems([
                {
                    name: 'ids',
                    verboseName: 'Records',
                    field: FieldType.Text,
                    required: true,
                    params: {}
                }
            ]);
        }
        else if ([SegueType.ModelChange].includes(link.type)) {
            return fieldsToProviderItems([
                {
                    name: 'id',
                    verboseName: 'Record',
                    field: FieldType.Text,
                    required: true,
                    params: {}
                }
            ]);
        }
        else if ([SegueType.ModelCreate].includes(link.type)) {
            return fieldsToProviderItems([
                {
                    name: '_duplicate',
                    verboseName: 'Duplicate Record',
                    field: FieldType.Text,
                    required: false,
                    params: {}
                }
            ]);
        }
        else {
            return [];
        }
    };
    ActionDescriptionService.prototype.getExternalLinkActionParameters = function () {
        return [
            {
                name: 'href',
                verboseName: 'URL',
                field: FieldType.Text,
                required: true,
                params: {}
            },
            {
                name: 'new_tab',
                verboseName: 'Open In New Tab',
                field: FieldType.Text,
                required: false,
                params: {}
            }
        ];
    };
    ActionDescriptionService.prototype.getExportActionParameters = function (resource, modelDescription, parameters) {
        if (parameters === void 0) { parameters = []; }
        if (!modelDescription) {
            return [];
        }
        var columns = modelDescription.fields
            .filter(function (item) { return item.type == ModelFieldType.Db; })
            .map(function (item) { return modelFieldToRawListViewSettingsColumn(item); });
        var result = parametersToProviderItems(parameters).concat(inputFieldProviderItemsFromModelGet(resource, modelDescription, modelDescription.getQuery, columns));
        result.push({
            label: 'Records',
            field: {
                name: 'ids',
                verboseName: 'Records',
                field: FieldType.Text,
                required: false,
                params: {}
            }
        });
        if (modelDescription.getQuery) {
            result.push({
                label: 'Records per request',
                warning: "\n          When using Records per request make sure export <strong>results are ordered</strong> because batch requests\n          from unordered list can lead to incorrect total result\n        ",
                field: {
                    name: PER_PAGE_PARAM,
                    verboseName: 'Records per request',
                    field: FieldType.Number,
                    required: false,
                    params: {}
                }
            });
        }
        return result;
    };
    ActionDescriptionService.prototype.getOpenPopupActionParameters = function (popupParameters) {
        return parametersToProviderItemsFlat((popupParameters || []).filter(function (item) { return item.name; }));
    };
    ActionDescriptionService.prototype.getElementActionParameters = function (path, context) {
        if (!context || !isSet(path)) {
            return [];
        }
        var action = context.getElementAction(path);
        if (!action) {
            return [];
        }
        return parametersToProviderItemsFlat(action.parameters);
    };
    ActionDescriptionService.prototype.createBulk = function (projectName, environmentName, actionDescriptions) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.environmentMethodURL(projectName, environmentName, 'action_descriptions/');
            var headers = new HttpHeaders();
            var params = {
                draft: '1'
            };
            var data = actionDescriptions.map(function (item) { return item.serialize(); });
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.post(url, data, { headers: headers, params: params });
        }), map(function (result) { return result.map(function (item) { return new ActionDescription().deserialize(item); }); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    ActionDescriptionService.prototype.create = function (projectName, environmentName, instance) {
        return this.createBulk(projectName, environmentName, [instance]).pipe(map(function (result) { return result[0]; }));
        // return this.apiService.refreshToken().pipe(
        //   switchMap(() => {
        //     const url = this.apiService.environmentMethodURL(projectName, environmentName, 'action_descriptions/');
        //     let headers = new HttpHeaders();
        //     const data = {
        //       project: actionDescription.project,
        //       ...actionDescription.serialize()
        //     };
        //
        //     headers = this.apiService.setHeadersToken(headers);
        //
        //     return this.http.post(url, data, { headers: headers });
        //   }),
        //   map(result => new ActionDescription().deserialize(result)),
        //   this.apiService.catchApiError(),
        //   publishLast(),
        //   refCount()
        // );
    };
    ActionDescriptionService.prototype.update = function (projectName, environmentName, instance) {
        return this.createBulk(projectName, environmentName, [instance]).pipe(map(function (result) { return result[0]; }));
        // return this.apiService.refreshToken().pipe(
        //   switchMap(() => {
        //     const url = this.apiService.environmentMethodURL(projectName, environmentName, `action_descriptions/${id}/`);
        //     let headers = new HttpHeaders();
        //     const data = {
        //       project: actionDescriptions.project,
        //       ...actionDescriptions.serialize()
        //     };
        //
        //     headers = this.apiService.setHeadersToken(headers);
        //
        //     return this.http.patch(url, data, { headers: headers, params: { resource: resource } });
        //   }),
        //   map(result => new ActionDescription().deserialize(result)),
        //   this.apiService.catchApiError(),
        //   publishLast(),
        //   refCount()
        // );
    };
    ActionDescriptionService.prototype.delete = function (projectName, environmentName, instance) {
        instance = cloneDeep(instance);
        instance.deleted = true;
        return this.createBulk(projectName, environmentName, [instance]).pipe(map(function () { return true; }));
        // return this.apiService.refreshToken().pipe(
        //   switchMap(() => {
        //     const url = this.apiService.environmentMethodURL(projectName, environmentName, `action_descriptions/${id}/`);
        //     let headers = new HttpHeaders();
        //
        //     headers = this.apiService.setHeadersToken(headers);
        //
        //     return this.http.delete(url, { headers: headers, params: { resource: resource } });
        //   }),
        //   map(result => true),
        //   this.apiService.catchApiError(),
        //   publishLast(),
        //   refCount()
        // );
    };
    return ActionDescriptionService;
}());
export { ActionDescriptionService };
