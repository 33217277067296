<div>
  <div class="tabs__separator">
    <div class="tabs__separator-top"></div>
    <div class="tabs__separator-bottom"></div>
    <div class="tabs__separator-shadow"></div>
    <div class="tabs__separator-shadow-left"></div>
  </div>

  <!--<div class="section">-->
  <!--<div class="section__inner">-->
  <!--<div class="section__body">-->
  <div
    class="placeholder"
    [class.placeholder_active]="customizeService.layoutEnabled$ | async"
    appDropList
    [appDropListDisabled]="!(customizeService.layoutEnabled$ | async)"
    [appDropListEnterPredicate]="canEnter"
    [appDropListData]="element.children"
    [appDropListPreviewMissPointerStartDelta]="{ x: 20, y: -20 }"
    [appDropListOutsideIndicator]="true"
    (appDropListDragStarted)="updateElementStates()"
    (appDropListEntered)="updateElementStates()"
    (appDropListExited)="updateElementStates()"
    (appDropListSorted)="updateElementStates()"
    (appDropListDragged)="updateElementStatesOnStable()"
    (appDropListDropped)="dragDrop($event)"
    appElementGroupsContainer
  >
    <app-auto-element
      *ngFor="let item of element.children; let i = index; trackBy: trackElement"
      appDrag
      [appDragData]="item"
      [element]="item"
      [context]="context"
      [customizing]="customizeService.layoutEnabled$ | async"
      [accentColor]="'accentColor' | appThemeColorHexOption: true | async"
      (duplicatedRequested)="duplicateItem(i)"
      (replaceRequested)="replaceItem(i, $event)"
      (deleteRequested)="deleteItem(item)"
      (moveToRequested)="moveItemTo(item, $event)"
      (wrapperUpdated)="updateElementStates()"
      (visibleUpdated)="updateElementStates()"
    >
      <app-element-placeholder-stub
        *appDragPlaceholder
        [elementType]="item.type"
        class="element-placeholder"
      ></app-element-placeholder-stub>

      <app-element-drag-preview *appDragPreview [element]="item"></app-element-drag-preview>
    </app-auto-element>
  </div>
  <!--</div>-->
  <!--</div>-->
  <!--</div>-->
</div>
