import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';

import { FileViewerElementItem, FileViewerType, MarginControl } from '@modules/customize';
import { ElementConfigurationService } from '@modules/customize-configuration';
import { Option } from '@modules/field-components';
import { Input, Input as FieldInput } from '@modules/fields';
import { FieldInputControl } from '@modules/parameters';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';

@Injectable()
export class CustomizeBarFileViewerEditForm extends FormGroup {
  element: FileViewerElementItem;

  controls: {
    url: FieldInputControl;
    viewer_type: FormControl;
    toolbar: FormControl;
    border: FormControl;
    visible_input: FieldInputControl;
    card_wrap: FormControl;
    margin: MarginControl;
  };

  viewerTypeOptions: Option<FileViewerType>[] = [
    {
      value: FileViewerType.Native,
      name: 'Browser built-in',
      subtitle: 'Faster, works with non-public URLs'
    },
    {
      value: FileViewerType.Google,
      name: 'Google File Viewer',
      subtitle: 'More supported formats'
    }
  ];

  constructor(
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private elementConfigurationService: ElementConfigurationService
  ) {
    super({
      url: new FieldInputControl({ path: ['value'] }, { validators: Validators.required, updateOn: 'blur' }),
      viewer_type: new FormControl(FileViewerType.Native),
      toolbar: new FormControl(false),
      border: new FormControl(false),
      visible_input: new FieldInputControl({ path: ['value'] }),
      card_wrap: new FormControl(true),
      margin: new MarginControl()
    });
  }

  init(element: FileViewerElementItem, firstInit = false) {
    this.element = element;

    const value = {
      url: element.url ? element.url.serializeWithoutPath() : {},
      viewer_type: element.viewerType,
      toolbar: element.toolbar,
      border: element.border,
      visible_input: element.visibleInput ? element.visibleInput.serializeWithoutPath() : {},
      card_wrap: element.cardWrap,
      margin: element.margin
    };

    this.patchValue(value, { emitEvent: false });

    if (!firstInit) {
      this.markAsDirty();
    }
  }

  isConfigured(instance: FileViewerElementItem): boolean {
    return this.elementConfigurationService.isFileViewerConfigured(instance);
  }

  submit(): FileViewerElementItem {
    const value = this.value;
    const instance = cloneDeep(this.element) as FileViewerElementItem;

    instance.url = value['url'] ? new FieldInput().deserialize(value['url']) : undefined;
    instance.viewerType = value['viewer_type'];
    instance.toolbar = value['toolbar'];
    instance.border = value['border'];
    instance.cardWrap = value['card_wrap'];
    instance.margin = value['margin'];

    if (value['visible_input']) {
      instance.visibleInput = new Input().deserialize(value['visible_input']);
    } else {
      instance.visibleInput = undefined;
    }

    return instance;
  }
}
