var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { gteFieldLookup, lteFieldLookup } from '../base-lookups';
import { FieldLookup } from './base';
var BetweenFieldLookup = /** @class */ (function (_super) {
    __extends(BetweenFieldLookup, _super);
    function BetweenFieldLookup() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.excludable = false;
        return _this;
    }
    BetweenFieldLookup.prototype.compute = function (field, value, exclude) {
        return exclude
            ? [] // TODO: OR filters needed
            : [
                {
                    field: field,
                    lookup: gteFieldLookup,
                    value: this.from(value)
                },
                {
                    field: field,
                    lookup: lteFieldLookup,
                    value: this.to(value)
                }
            ];
    };
    return BetweenFieldLookup;
}(FieldLookup));
export { BetweenFieldLookup };
