<app-field-wrapper
  *ngIf="!readonly"
  [formGroup]="form"
  [form]="form"
  [field]="field"
  [readonly]="readonly"
  [requiredAsterisk]="requiredAsterisk"
  [label]="label"
  [labelStyle]="elementStyles?.labelStyle"
  [labelStyleGlobal]="labelStyleGlobal"
  [labelAdditional]="labelAdditional"
  [labelAdditionalStyle]="elementStyles?.labelAdditionalStyle"
  [labelAdditionalStyleGlobal]="labelAdditionalStyleGlobal"
  [errors]="errors"
  [truncate]="truncate"
  [manualMargin]="manualMargin"
  [labelButtons]="labelButtons"
  [tooltip]="tooltip"
  [idToken]="idToken"
  [theme]="field.params['theme']"
>
  <div class="input-icon-wrapper">
    <input
      class="input input_icon-right"
      type="text"
      [class.input_error]="(form | appFormFieldErrors: field.name)?.length"
      [class.input_theme]="field.params['theme']"
      [class.input_styles]="field.params['theme']"
      [appFieldElementStyles]="elementStyles"
      [appFieldElementStylesEnabled]="field.params['theme']"
      [ngClass]="field.params['classes']"
      [appDateValue]="{ format: editFormat, date: false, time: true }"
      [appAutofocus]="autofocus"
      [formControlName]="field.name"
      [id]="field.name | appUniqueId: idToken"
      [placeholder]="field.placeholder || ''"
      autocomplete="off"
      #dp
      cdkOverlayOrigin
      #dp_origin="cdkOverlayOrigin"
    />

    <span
      *ngIf="!field.resetEnabled || !(control.value | appIsSet)"
      class="input-icon input-icon_right icon-time"
      [class.input-icon_small]="inputSmall"
      [class.input-icon_theme]="field.params['theme']"
    >
    </span>

    <span
      *ngIf="field.resetEnabled && (control.value | appIsSet)"
      class="input-icon input-icon_interactive input-icon_right icon-close"
      [class.input-icon_small]="inputSmall"
      [class.input-icon_theme]="field.params['theme']"
      (click)="resetCurrentValue()"
    ></span>

    <gxd-datepicker
      [input]="dp"
      [origin]="dp_origin"
      [options]="{
        theme: 'jet',
        date: false,
        time: true,
        clock12: editFormatClock12,
        format: editFormat,
        datepickerClasses: datepickerClasses,
        locale: {} | localizeDatepicker
      }"
    ></gxd-datepicker>
  </div>
</app-field-wrapper>
<app-field-wrapper
  *ngIf="readonly"
  [form]="form"
  [field]="field"
  [readonly]="readonly"
  [requiredAsterisk]="requiredAsterisk"
  [label]="label"
  [labelStyle]="elementStyles?.labelStyle"
  [labelStyleGlobal]="labelStyleGlobal"
  [labelAdditional]="labelAdditional"
  [labelAdditionalStyle]="elementStyles?.labelAdditionalStyle"
  [labelAdditionalStyleGlobal]="labelAdditionalStyleGlobal"
  [errors]="errors"
  [truncate]="truncate"
  [manualMargin]="manualMargin"
  [labelButtons]="labelButtons"
  [tooltip]="tooltip"
  [idToken]="idToken"
  [theme]="field.params['theme']"
>
  {{ valueStr }}
</app-field-wrapper>
