<div
  *ngIf="!customView"
  class="map-popup"
  [class.map-popup_clickable]="settings.cardClickAction"
  [class.map-popup_theme]="theme"
>
  <!--<a href="javascript:void(0)"-->
  <!--class="map-popup__close icon icon-close"-->
  <!--(click)="close.emit()">-->
  <!--</a>-->
  <app-model-card
    [item]="item"
    [modelDescription]="modelDescription"
    [visibleColumns]="visibleColumns"
    [columns]="settings.dataSource?.columns || []"
    [columnActions]="settings.columnActions"
    [context]="context"
    [contextElement]="contextElement"
    [theme]="theme"
    (modelUpdated)="modelUpdated.next($event)"
  ></app-model-card>

  <div *ngIf="settings.cardClickAction || settings.modelActions.length" class="map-popup__buttons">
    <span *ngIf="settings.cardClickAction" class="map-popup__buttons-item">
      <a href="javascript:void(0)" class="button button_small" (click)="open($event, button_element)" #button_element>
        <span class="icon icon-eye button__icon button__icon_left"></span>
        <span class="button__label">Open</span>
      </a>
    </span>

    <ng-container *ngFor="let action of settings.modelActions">
      <app-model-card-action
        class="map-popup__buttons-item"
        [model]="item.model"
        [action]="action"
        [context]="context"
        [contextElement]="contextElement"
        [accentColor]="accentColor"
        [theme]="theme"
      ></app-model-card-action>
    </ng-container>
  </div>
</div>

<app-custom-element-auto
  *ngIf="customView"
  class="map-popup"
  [class.map-popup_clickable]="settings.cardClickAction"
  [class.map-popup_theme]="theme"
  [source]="customView.source"
  [customView]="customView"
  [actions]="settings.customActions"
  [params]="viewParams$ | async"
  [context]="context"
  [contextElement]="contextElement"
  [localContext]="localContext"
></app-custom-element-auto>
