var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ModelDescriptionQuery, QueryType } from '@modules/queries';
import { DataSource, DataSourceType } from './data-source';
var ModelDescriptionDataSource = /** @class */ (function (_super) {
    __extends(ModelDescriptionDataSource, _super);
    function ModelDescriptionDataSource() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ModelDescriptionDataSource.prototype.getModelId = function () {
        return this.type == DataSourceType.Query &&
            this.query &&
            this.query.queryType == QueryType.Simple &&
            this.query.simpleQuery
            ? [this.queryResource, this.query.simpleQuery.model].join('.')
            : undefined;
    };
    ModelDescriptionDataSource.queryCls = ModelDescriptionQuery;
    return ModelDescriptionDataSource;
}(DataSource));
export { ModelDescriptionDataSource };
