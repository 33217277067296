import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  InjectionToken,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  ViewChild
} from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable, Subscription } from 'rxjs';

import { CLOSE_BY_BACKGROUND_CLICK } from '@common/dialog-popup';
import { NotificationService } from '@common/notifications';
import { BasePopupComponent, PopupService } from '@common/popups';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { CustomView, CustomViewDataView, CustomViewSource } from '@modules/custom-views';
import { DARK_THEME_OUTPUT, ViewContext, ViewContextElement } from '@modules/customize';
import { ActionOutput, FieldType, getFieldDescriptionByType, ParameterField } from '@modules/fields';
import { SELECTED_OUTPUT } from '@modules/list';
import { ViewContextTokenProvider } from '@modules/parameters-components';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { View } from '@modules/views';
import { capitalize, isSet, KeyboardEventKeyCode } from '@shared';

import { ViewEditorViewportOverlayService } from '../../services/customize-toolbar-bottom/view-editor-viewport-overlay.service';
import { ImportFigmaNodeController } from '../../services/import-figma-node-controller/import-figma-node.controller';
import { ImportSketchFileController } from '../../services/import-sketch-file-controller/import-sketch-file.controller';
import { ViewEditorContext, ViewEditorCustomizeSource } from '../../services/view-editor-context/view-editor.context';
import { ViewEditorMultipleLayers } from '../../services/view-editor-multiple-layers/view-editor-multiple-layers';
import { ViewSaveEvent } from './view-save-event';

enum ViewMenu {
  Parameters = 'parameters',
  Actions = 'actions'
}

export const parametersToken = new InjectionToken<ViewContextElement>('parametersToken');
export const actionsToken = new InjectionToken<ViewContextElement>('actionsToken');
export const stateToken = new InjectionToken<ViewContextElement>('stateToken');

@Component({
  selector: 'app-view-editor',
  templateUrl: './view-editor.component.html',
  providers: [
    ViewEditorContext,
    ViewEditorViewportOverlayService,
    ViewEditorMultipleLayers,
    ViewContext,
    ViewContextTokenProvider,
    { provide: parametersToken, useClass: ViewContextElement },
    { provide: actionsToken, useClass: ViewContextElement },
    { provide: stateToken, useClass: ViewContextElement }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ViewEditorComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() data: CustomViewDataView;
  @Input() componentLabel = 'component';
  @Input() submitLabel = 'Save';
  @Input() nameEditingEnabled = true;
  @Input() stateSelectedEnabled = false;
  @Input() templatesEnabled = true;
  @Input() analyticsSource: string;
  @Output() result = new EventEmitter<ViewSaveEvent>();
  @Output() cancel = new EventEmitter<void>();

  @ViewChild('viewport_element') viewportElement: ElementRef;

  currentView: View;
  currentName: string;
  currentParameters: ParameterField[];
  currentActions: ActionOutput[];
  currentTestParameters: Record<string, unknown>;
  nameEditing = false;
  isUndoAvailable$: Observable<boolean>;
  isRedoAvailable$: Observable<boolean>;
  testParametersSubscription: Subscription;
  viewMenuItems: { type: ViewMenu; label: string; icon?: string; iconSize?: number }[] = [
    {
      type: ViewMenu.Parameters,
      label: 'Parameters',
      icon: 'input',
      iconSize: 17
    },
    {
      type: ViewMenu.Actions,
      label: 'Actions',
      icon: 'power',
      iconSize: 15
    }
  ];
  currentViewMenu: ViewMenu;
  viewMenus = ViewMenu;
  DARK_THEME_OUTPUT = DARK_THEME_OUTPUT;

  constructor(
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    public editorContext: ViewEditorContext,
    public viewContext: ViewContext,
    @Inject(parametersToken) private parametersContextElement: ViewContextElement,
    @Inject(actionsToken) private actionsContextElement: ViewContextElement,
    @Inject(stateToken) private stateContextElement: ViewContextElement,
    private importFigmaNodeController: ImportFigmaNodeController,
    private importSketchFileController: ImportSketchFileController,
    private zone: NgZone,
    private popupService: PopupService,
    private notificationService: NotificationService,
    @Optional() private popupComponent: BasePopupComponent,
    private cd: ChangeDetectorRef,
    private analyticsService: UniversalAnalyticsService
  ) {}

  ngOnInit() {
    this.editorContext.init(this.data.view);
    this.isUndoAvailable$ = this.editorContext.isUndoAvailable$();
    this.isRedoAvailable$ = this.editorContext.isRedoAvailable$();

    this.editorContext.view$.pipe(untilDestroyed(this)).subscribe(value => {
      this.currentView = value;
      this.cd.markForCheck();
    });

    this.currentName = isSet(this.data.name) ? this.data.name : '';
    this.currentParameters = this.data.parameters ? cloneDeep(this.data.parameters) : [];
    this.currentActions = this.data.actions ? cloneDeep(this.data.actions) : [];
    this.currentTestParameters = this.data.testParameters ? cloneDeep(this.data.testParameters) : {};

    this.updateComponentContextOutputs();
    this.updateComponentContextActions();
    this.updateStateContextOutputs();

    this.initHotkeys();
  }

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (!this.editorContext.isViewInsideViewport(this.viewportElement.nativeElement)) {
        this.editorContext.fitToContent(this.viewportElement.nativeElement);
      }
    }, 0);
  }

  updateComponentContextOutputs() {
    if (this.testParametersSubscription) {
      this.testParametersSubscription.unsubscribe();
      this.testParametersSubscription = undefined;
    }

    this.parametersContextElement.initGlobal({
      uniqueName: 'component',
      name: 'Component parameters'
    });

    this.parametersContextElement.setOutputs(
      this.currentParameters.map(item => {
        const fieldDescription = getFieldDescriptionByType(item.field);
        const icon = fieldDescription ? fieldDescription.icon : undefined;

        return {
          uniqueName: item.name,
          name: item.verboseName,
          icon: icon,
          fieldType: item.field,
          fieldParams: item.params,
          external: true
        };
      })
    );

    this.parametersContextElement.setOutputValues(this.currentTestParameters);

    this.testParametersSubscription = this.editorContext
      .viewChanged$()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.parametersContextElement.setOutputValues(this.currentParameters);
      });
  }

  updateComponentContextActions() {
    this.actionsContextElement.initGlobal({
      uniqueName: 'component_actions',
      name: 'Component actions'
    });

    this.actionsContextElement.setActions(
      this.currentActions.map(item => {
        return {
          uniqueName: item.name,
          name: item.verboseName,
          parameters: item.parameters,
          handler: params => this.onContextAction(item, params),
          icon: item.icon
        };
      })
    );
  }

  onContextAction(action: ActionOutput, params: Object) {
    let description: string;

    if (action.parameters.length) {
      description = [
        `Action called with the following parameters:`,
        ...action.parameters.map(item => `${item.verboseName || item.name}: ${params[item.name]}`)
      ].join('<br>');
    } else {
      description = `Action called without parameters`;
    }

    this.notificationService.success(`Action "${action.name}"`, description);
  }

  updateStateContextOutputs() {
    this.stateContextElement.initGlobal({
      uniqueName: 'state',
      name: 'Component state'
    });

    this.stateContextElement.setOutputs([
      ...(this.stateSelectedEnabled
        ? [
            {
              uniqueName: SELECTED_OUTPUT,
              name: `${capitalize(this.componentLabel)} is selected`,
              icon: 'select_all',
              fieldType: FieldType.Boolean,
              external: true
            }
          ]
        : []),
      {
        uniqueName: DARK_THEME_OUTPUT,
        name: 'Dark theme',
        icon: 'toggle_theme',
        fieldType: FieldType.Boolean,
        external: true
      }
    ]);

    const defaultState = {
      ...(this.stateSelectedEnabled && {
        [SELECTED_OUTPUT]: true
      }),
      [DARK_THEME_OUTPUT]: false
    };

    this.stateContextElement.setOutputValues({ ...defaultState, ...this.currentView.testState });

    this.editorContext
      .viewChanged$()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.stateContextElement.setOutputValues({ ...defaultState, ...this.currentView.testState });
      });
  }

  openViewMenu(value: ViewMenu) {
    this.currentViewMenu = value;
    this.cd.markForCheck();
  }

  closeCurrentViewMenu() {
    this.currentViewMenu = undefined;
    this.cd.markForCheck();
  }

  closeViewMenu(value: ViewMenu) {
    if (this.currentViewMenu == value) {
      this.closeCurrentViewMenu();
    }
  }

  onViewMenuClick(value: ViewMenu) {
    if (this.currentViewMenu == value) {
      this.closeCurrentViewMenu();
    } else {
      this.openViewMenu(value);
    }
  }

  initHotkeys() {
    this.editorContext
      .trackKeydown()
      .pipe(untilDestroyed(this))
      .subscribe(e => {
        if (e.keyCode == KeyboardEventKeyCode.Minus && (e.metaKey || e.ctrlKey)) {
          e.preventDefault();
          this.editorContext.zoomOut();
        } else if (e.keyCode == KeyboardEventKeyCode.Equals && (e.metaKey || e.ctrlKey)) {
          e.preventDefault();
          this.editorContext.zoomIn();
        } else if (e.keyCode == KeyboardEventKeyCode.Number0 && (e.metaKey || e.ctrlKey)) {
          e.preventDefault();
          this.editorContext.viewportScale$.next(1);
        } else if (e.keyCode == KeyboardEventKeyCode.Number1 && e.shiftKey) {
          e.preventDefault();
          this.editorContext.fitToContent(this.viewportElement.nativeElement);
        } else if (e.keyCode == KeyboardEventKeyCode.Dot && (e.metaKey || e.ctrlKey)) {
          e.preventDefault();
          this.editorContext.showInterface$.next(!this.editorContext.showInterface$.value);
        } else if (e.keyCode == KeyboardEventKeyCode.Z && (e.metaKey || e.ctrlKey) && !e.shiftKey) {
          if (this.editorContext.isUndoAvailable()) {
            this.editorContext.undo();
          }
        } else if (e.keyCode == KeyboardEventKeyCode.Z && (e.metaKey || e.ctrlKey) && e.shiftKey) {
          if (this.editorContext.isRedoAvailable()) {
            this.editorContext.redo();
          }
        }
      });
  }

  updateParameters(result: ParameterField[]) {
    this.currentParameters = result;
    this.cd.markForCheck();
    this.updateComponentContextOutputs();
  }

  updateActions(result: ActionOutput[]) {
    this.currentActions = result;
    this.cd.markForCheck();
    this.updateComponentContextActions();
  }

  updateTestParameters(result: Object) {
    this.currentTestParameters = result;
    this.editorContext.markViewChanged(this.currentView, ViewEditorCustomizeSource.ViewParameters);
  }

  updateTestState(result: Object) {
    this.currentView.testState = result;
    this.editorContext.markViewChanged(this.currentView, ViewEditorCustomizeSource.ViewParameters);
  }

  submit() {
    this.result.emit({
      data: {
        source: CustomViewSource.View,
        view: this.currentView,
        name: this.currentName,
        parameters: this.currentParameters,
        actions: this.currentActions,
        testParameters: this.currentTestParameters
      }
    });
    this.close();
  }

  onCloseClick() {
    if (this.popupComponent) {
      this.popupComponent.confirmClose(CLOSE_BY_BACKGROUND_CLICK);
    }
  }

  close() {
    if (this.popupComponent) {
      this.popupComponent.close();
    }
  }

  setNameEditing(value: boolean) {
    this.nameEditing = value;
    this.cd.markForCheck();
  }

  cleanName() {
    this.currentName = isSet(this.currentName) ? this.currentName.trim() : '';
    this.cd.markForCheck();
  }

  importFigmaNode() {
    this.analyticsService.sendSimpleEvent(AnalyticsEvent.ViewEditor.ImportFigmaOpened, {
      Source: this.analyticsSource
    });

    this.importFigmaNodeController
      .importNode()
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        if (result.view) {
          this.editorContext.updateView(result.view);

          this.analyticsService.sendSimpleEvent(AnalyticsEvent.ViewEditor.ImportFigmaApplied, {
            Source: this.analyticsSource
          });
        } else if (result.cancelled) {
          this.analyticsService.sendSimpleEvent(AnalyticsEvent.ViewEditor.ImportFigmaCancelled, {
            Source: this.analyticsSource
          });
        }
      });
  }

  importSketchFile() {
    this.analyticsService.sendSimpleEvent(AnalyticsEvent.ViewEditor.ImportSketchOpened, {
      Source: this.analyticsSource
    });

    this.importSketchFileController
      .importFile()
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        if (result.view) {
          this.editorContext.updateView(result.view);

          this.analyticsService.sendSimpleEvent(AnalyticsEvent.ViewEditor.ImportSketchApplied, {
            Source: this.analyticsSource
          });
        } else if (result.cancelled) {
          this.analyticsService.sendSimpleEvent(AnalyticsEvent.ViewEditor.ImportSketchCancelled, {
            Source: this.analyticsSource
          });
        }
      });
  }

  useShared(customView: CustomView) {
    this.analyticsService.sendSimpleEvent(AnalyticsEvent.ViewEditor.UseSharedApplied, {
      Source: this.analyticsSource
    });

    this.result.emit({ useShared: customView });
    this.close();
  }
}
