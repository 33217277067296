import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit
} from '@angular/core';
import fromPairs from 'lodash/fromPairs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { ViewSettingsAction } from '@modules/actions';
import { CustomView, CustomViewsStore } from '@modules/custom-views';
import { FieldActions, getModelAttributesByColumns, ViewContext, ViewContextElement } from '@modules/customize';
import {
  applyParamInput$,
  createFormFieldFactory,
  CustomViewDisplayField,
  DisplayField,
  ParameterField
} from '@modules/fields';
import { ITEM_OUTPUT } from '@modules/list';
import { Model } from '@modules/models';
import { EMPTY, TypedChanges } from '@shared';

@Component({
  selector: 'app-model-card-item',
  templateUrl: './model-card-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModelCardItemComponent implements OnInit, OnDestroy, OnChanges {
  @Input() item: DisplayField;
  @Input() model: Model;
  @Input() columns: DisplayField[] = [];
  @Input() valueStr: string | Observable<string>;
  @Input() label = true;
  @Input() columnActions: FieldActions[] = [];
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;

  createField = createFormFieldFactory();
  item$ = new BehaviorSubject<DisplayField>(undefined);
  customView: CustomView;
  viewParameters: ParameterField[] = [];
  viewParams$: Observable<Object> = of({});
  actions: ViewSettingsAction[] = [];

  constructor(private customViewsStore: CustomViewsStore, private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.item$
      .pipe(
        switchMap(item => {
          if (item instanceof CustomViewDisplayField) {
            return this.customViewsStore.getDetail(item.customView);
          } else {
            return of(undefined);
          }
        }),
        untilDestroyed(this)
      )
      .subscribe(customView => {
        this.customView = customView;
        this.cd.markForCheck();
        this.updateViewParameters();
        this.updateViewParams();
      });
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<ModelCardItemComponent>): void {
    if (changes.item) {
      this.item$.next(this.item);
    }

    if (changes.item || changes.columnActions) {
      const columnActions = this.columnActions.find(item => item.name == this.item.name);
      this.actions = columnActions ? columnActions.actions : [];
    }
  }

  updateViewParameters() {
    if (!this.customView) {
      this.viewParameters = [];
      this.cd.markForCheck();
      return;
    }

    this.viewParameters = this.columns.map(item => {
      const parameter = new ParameterField();

      parameter.name = item.name;
      parameter.verboseName = item.verboseName;
      parameter.field = item.field;
      parameter.params = item.params;

      return parameter;
    });
    this.cd.markForCheck();
  }

  updateViewParams() {
    if (!this.customView) {
      this.viewParams$ = of({});
      this.cd.markForCheck();
      return;
    }

    const modelParams = getModelAttributesByColumns(this.model, this.columns);
    const customViewField = this.item instanceof CustomViewDisplayField ? this.item : undefined;
    const mappings = customViewField ? customViewField.customViewMappings : [];

    this.viewParams$ = combineLatest(
      mappings.map(mapping => {
        if (mapping.sourceParameterInput) {
          return applyParamInput$(mapping.sourceParameterInput, {
            context: this.context,
            contextElement: this.contextElement,
            localContext: {
              [ITEM_OUTPUT]: modelParams
            }
          }).pipe(
            map(value => {
              return {
                name: mapping.targetParameter,
                value: value !== EMPTY ? value : undefined
              };
            })
          );
        } else {
          const value = modelParams[mapping.sourceParameter];
          return of({
            name: mapping.targetParameter,
            value: value
          });
        }
      })
    ).pipe(
      map(values => {
        return fromPairs(values.map(item => [item.name, item.value]));
      })
    );
    this.cd.markForCheck();
  }
}
