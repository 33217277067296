import { ComponentFactoryResolver, Injectable, Injector } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

import { ThinDialogPopupComponent } from '@common/dialog-popup';
import { PopupService } from '@common/popups';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { CustomView, CustomViewData } from '@modules/custom-views';
import { CustomViewTemplate, View } from '@modules/views';
import { isSet } from '@shared';

import { CustomViewTemplateFilter } from '../../components/custom-view-templates-list/custom-view-templates-list.component';
import { CustomViewTemplatesComponent } from '../../components/custom-view-templates/custom-view-templates.component';

export interface CustomViewTemplatesUseViewEvent {
  data?: CustomViewData;
  template?: CustomViewTemplate;
  useShared?: CustomView;
  cancelled?: boolean;
}

export interface CustomViewTemplatesSetTemplateViewEvent {
  template?: CustomViewTemplate;
  cancelled?: boolean;
}

@Injectable()
export class CustomViewTemplatesController {
  constructor(
    private popupService: PopupService,
    private injector: Injector,
    private resolver: ComponentFactoryResolver,
    private analyticsService: UniversalAnalyticsService
  ) {}

  chooseTemplate(
    options: {
      initialFilter?: CustomViewTemplateFilter;
      nameEditingEnabled?: boolean;
      viewCustomizeEnabled?: boolean;
      viewCreateEnabled?: boolean;
      stateSelectedEnabled?: boolean;
      componentLabel?: string;
      analyticsSource?: string;
    } = {}
  ): Observable<CustomViewTemplatesUseViewEvent> {
    const obs$ = new ReplaySubject<CustomViewTemplatesUseViewEvent>();

    this.analyticsService.sendSimpleEvent(AnalyticsEvent.ViewTemplates.Opened, {
      Source: options.analyticsSource
    });

    this.popupService.push({
      component: CustomViewTemplatesComponent,
      popupComponent: ThinDialogPopupComponent,
      popupComponentOrange: true,
      inputs: {
        ...(options.initialFilter && { initialFilter: options.initialFilter }),
        ...(isSet(options.nameEditingEnabled) && { nameEditingEnabled: options.nameEditingEnabled }),
        ...(isSet(options.viewCustomizeEnabled) && { viewCustomizeEnabled: options.viewCustomizeEnabled }),
        ...(isSet(options.viewCreateEnabled) && { viewCreateEnabled: options.viewCreateEnabled }),
        stateSelectedEnabled: options.stateSelectedEnabled,
        ...(isSet(options.componentLabel) && { componentLabel: options.componentLabel }),
        analyticsSource: options.analyticsSource
      },
      outputs: {
        selectView: [
          result => {
            obs$.next({ data: result.data, template: result.template });

            this.analyticsService.sendSimpleEvent(AnalyticsEvent.ViewTemplates.ViewApplied, {
              Name: result.template ? result.template.name : undefined,
              New: !result.template,
              Source: options.analyticsSource
            });
          }
        ],
        useShared: [
          result => {
            obs$.next({ useShared: result });

            this.analyticsService.sendSimpleEvent(AnalyticsEvent.ViewTemplates.SharedApplied, {
              Name: result.uniqueName,
              Source: options.analyticsSource
            });
          }
        ],
        cancelled: [
          () => {
            obs$.next({ cancelled: true });

            this.analyticsService.sendSimpleEvent(AnalyticsEvent.ViewTemplates.Cancelled, {
              Source: options.analyticsSource
            });
          }
        ]
      },
      resolver: this.resolver,
      injector: this.injector
    });

    return obs$;
  }

  setTemplateView(
    view: View,
    options: { stateSelectedEnabled?: boolean; componentLabel?: string } = {}
  ): Observable<CustomViewTemplatesSetTemplateViewEvent> {
    const obs$ = new ReplaySubject<CustomViewTemplatesSetTemplateViewEvent>();

    this.popupService.push({
      component: CustomViewTemplatesComponent,
      popupComponent: ThinDialogPopupComponent,
      popupComponentOrange: true,
      inputs: {
        updateOnSelect: true,
        setTemplateView: view,
        stateSelectedEnabled: options.stateSelectedEnabled,
        ...(isSet(options.componentLabel) && { componentLabel: options.componentLabel })
      },
      outputs: {
        templateUpdated: [
          result => {
            obs$.next({ template: result });
          }
        ],
        cancelled: [
          () => {
            obs$.next({ cancelled: true });
          }
        ]
      },
      resolver: this.resolver,
      injector: this.injector
    });

    return obs$;
  }
}
