/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../custom-view-templates-detail/custom-view-templates-detail.component.ngfactory";
import * as i2 from "../custom-view-templates-detail/custom-view-templates-detail.component";
import * as i3 from "../../../users/stores/current-user.store";
import * as i4 from "../../../views/services/custom-view-template/custom-view-template.service";
import * as i5 from "../../services/view-editor-controller/view-editor.controller";
import * as i6 from "../../../analytics/services/universal-analytics/universal-analytics.service";
import * as i7 from "../custom-view-templates-change/custom-view-templates-change.component.ngfactory";
import * as i8 from "../custom-view-templates-change/custom-view-templates-change.form";
import * as i9 from "../../../projects/stores/current-project.store";
import * as i10 from "../../../projects/stores/current-environment.store";
import * as i11 from "../../../../common/form-utils/services/form-utils";
import * as i12 from "../custom-view-templates-change/custom-view-templates-change.component";
import * as i13 from "../../../../core/services/session-storage/session.storage";
import * as i14 from "../../../customize/stores/view-settings.store";
import * as i15 from "../../../menu/services/menu/menu.service";
import * as i16 from "../../../../common/notifications/services/notification/notification.service";
import * as i17 from "../custom-view-templates-list/custom-view-templates-list.component.ngfactory";
import * as i18 from "../custom-view-templates-list/custom-view-templates-list.component";
import * as i19 from "../../../views/stores/custom-view-template.list-store";
import * as i20 from "../../../custom-views/stores/custom-views.store";
import * as i21 from "@angular/common";
import * as i22 from "./custom-view-templates.component";
import * as i23 from "../../../../common/popups/components/base-popup/base-popup.component";
import * as i24 from "../../../routing/services/routing/routing.service";
var styles_CustomViewTemplatesComponent = [];
var RenderType_CustomViewTemplatesComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CustomViewTemplatesComponent, data: {} });
export { RenderType_CustomViewTemplatesComponent as RenderType_CustomViewTemplatesComponent };
function View_CustomViewTemplatesComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-custom-view-templates-detail", [], null, [[null, "selectView"], [null, "useShared"], [null, "showTemplateType"], [null, "customizeTemplate"], [null, "back"], [null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectView" === en)) {
        var pd_0 = (_co.onViewSelected($event.data, $event.template) !== false);
        ad = (pd_0 && ad);
    } if (("useShared" === en)) {
        var pd_1 = (_co.onUseSharedSelected($event) !== false);
        ad = (pd_1 && ad);
    } if (("showTemplateType" === en)) {
        var pd_2 = (_co.openList({ type: $event }) !== false);
        ad = (pd_2 && ad);
    } if (("customizeTemplate" === en)) {
        var pd_3 = (_co.openUpdate($event.id) !== false);
        ad = (pd_3 && ad);
    } if (("back" === en)) {
        var pd_4 = (_co.openList() !== false);
        ad = (pd_4 && ad);
    } if (("close" === en)) {
        var pd_5 = (_co.cancel() !== false);
        ad = (pd_5 && ad);
    } return ad; }, i1.View_CustomViewTemplatesDetailComponent_0, i1.RenderType_CustomViewTemplatesDetailComponent)), i0.ɵdid(1, 245760, null, 0, i2.CustomViewTemplatesDetailComponent, [i3.CurrentUserStore, i4.CustomViewTemplateService, i5.ViewEditorController, i6.UniversalAnalyticsService, i0.ChangeDetectorRef], { id: [0, "id"], nameEditingEnabled: [1, "nameEditingEnabled"], viewCustomizeEnabled: [2, "viewCustomizeEnabled"], stateSelectedEnabled: [3, "stateSelectedEnabled"], componentLabel: [4, "componentLabel"], analyticsSource: [5, "analyticsSource"] }, { selectView: "selectView", useShared: "useShared", showTemplateType: "showTemplateType", customizeTemplate: "customizeTemplate", back: "back", close: "close" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.detailRoute.id; var currVal_1 = _co.nameEditingEnabled; var currVal_2 = _co.viewCustomizeEnabled; var currVal_3 = _co.stateSelectedEnabled; var currVal_4 = _co.componentLabel; var currVal_5 = _co.analyticsSource; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
function View_CustomViewTemplatesComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-custom-view-templates-change", [], null, [[null, "openList"], [null, "openDetail"], [null, "saved"], [null, "back"], [null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("openList" === en)) {
        var pd_0 = (_co.openList() !== false);
        ad = (pd_0 && ad);
    } if (("openDetail" === en)) {
        var pd_1 = (_co.openDetail($event) !== false);
        ad = (pd_1 && ad);
    } if (("saved" === en)) {
        var pd_2 = (_co.onTemplateSaved($event) !== false);
        ad = (pd_2 && ad);
    } if (("back" === en)) {
        var pd_3 = (_co.openDetail(_co.updateRoute.id) !== false);
        ad = (pd_3 && ad);
    } if (("close" === en)) {
        var pd_4 = (_co.cancel() !== false);
        ad = (pd_4 && ad);
    } return ad; }, i7.View_CustomViewTemplatesChangeComponent_0, i7.RenderType_CustomViewTemplatesChangeComponent)), i0.ɵprd(131584, null, i8.CustomViewTemplatesChangeForm, i8.CustomViewTemplatesChangeForm, [i4.CustomViewTemplateService, i9.CurrentProjectStore, i10.CurrentEnvironmentStore, i11.FormUtils]), i0.ɵdid(2, 770048, null, 0, i12.CustomViewTemplatesChangeComponent, [i8.CustomViewTemplatesChangeForm, i9.CurrentProjectStore, i13.SessionStorage, i4.CustomViewTemplateService, i5.ViewEditorController, i14.ViewSettingsStore, i15.MenuService, i16.NotificationService, i0.ChangeDetectorRef], { id: [0, "id"], setView: [1, "setView"], stateSelectedEnabled: [2, "stateSelectedEnabled"], componentLabel: [3, "componentLabel"] }, { openList: "openList", openDetail: "openDetail", saved: "saved", back: "back", close: "close" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.updateRoute.id; var currVal_1 = _co.setTemplateView; var currVal_2 = _co.stateSelectedEnabled; var currVal_3 = _co.componentLabel; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_CustomViewTemplatesComponent_0(_l) { return i0.ɵvid(2, [i0.ɵqud(402653184, 1, { listComponent: 0 }), (_l()(), i0.ɵeld(1, 0, null, null, 3, "app-custom-view-templates-list", [], [[8, "hidden", 0]], [[null, "selectTemplate"], [null, "createdView"], [null, "useShared"], [null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectTemplate" === en)) {
        var pd_0 = (_co.onTemplateSelected($event) !== false);
        ad = (pd_0 && ad);
    } if (("createdView" === en)) {
        var pd_1 = (_co.onViewSelected($event.data, $event.template) !== false);
        ad = (pd_1 && ad);
    } if (("useShared" === en)) {
        var pd_2 = (_co.onUseSharedSelected($event) !== false);
        ad = (pd_2 && ad);
    } if (("close" === en)) {
        var pd_3 = (_co.cancel() !== false);
        ad = (pd_3 && ad);
    } return ad; }, i17.View_CustomViewTemplatesListComponent_0, i17.RenderType_CustomViewTemplatesListComponent)), i0.ɵprd(512, null, i18.FEATURED_TOKEN, i19.CustomViewTemplateListStore, [i4.CustomViewTemplateService]), i0.ɵprd(512, null, i18.LIST_TOKEN, i19.CustomViewTemplateListStore, [i4.CustomViewTemplateService]), i0.ɵdid(4, 245760, [[1, 4]], 0, i18.CustomViewTemplatesListComponent, [i18.FEATURED_TOKEN, i18.LIST_TOKEN, i3.CurrentUserStore, i5.ViewEditorController, i20.CustomViewsStore, i0.ChangeDetectorRef], { initialFilter: [0, "initialFilter"], nameEditingEnabled: [1, "nameEditingEnabled"], viewCreateEnabled: [2, "viewCreateEnabled"], stateSelectedEnabled: [3, "stateSelectedEnabled"], componentLabel: [4, "componentLabel"] }, { selectTemplate: "selectTemplate", createdView: "createdView", useShared: "useShared", close: "close" }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomViewTemplatesComponent_1)), i0.ɵdid(6, 16384, null, 0, i21.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomViewTemplatesComponent_2)), i0.ɵdid(8, 16384, null, 0, i21.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.initialFilter; var currVal_2 = _co.nameEditingEnabled; var currVal_3 = _co.viewCreateEnabled; var currVal_4 = _co.stateSelectedEnabled; var currVal_5 = _co.componentLabel; _ck(_v, 4, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_6 = (_co.route.type == _co.routeTypes.Detail); _ck(_v, 6, 0, currVal_6); var currVal_7 = (_co.route.type == _co.routeTypes.Update); _ck(_v, 8, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.route.type != _co.routeTypes.List); _ck(_v, 1, 0, currVal_0); }); }
export function View_CustomViewTemplatesComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-custom-view-templates", [], null, null, null, View_CustomViewTemplatesComponent_0, RenderType_CustomViewTemplatesComponent)), i0.ɵdid(1, 114688, null, 0, i22.CustomViewTemplatesComponent, [[2, i23.BasePopupComponent], i24.RoutingService, i6.UniversalAnalyticsService, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CustomViewTemplatesComponentNgFactory = i0.ɵccf("app-custom-view-templates", i22.CustomViewTemplatesComponent, View_CustomViewTemplatesComponent_Host_0, { initialFilter: "initialFilter", updateOnSelect: "updateOnSelect", setTemplateView: "setTemplateView", nameEditingEnabled: "nameEditingEnabled", viewCustomizeEnabled: "viewCustomizeEnabled", viewCreateEnabled: "viewCreateEnabled", stateSelectedEnabled: "stateSelectedEnabled", componentLabel: "componentLabel", analyticsSource: "analyticsSource" }, { selectView: "selectView", useShared: "useShared", templateUpdated: "templateUpdated", cancelled: "cancelled" }, []);
export { CustomViewTemplatesComponentNgFactory as CustomViewTemplatesComponentNgFactory };
