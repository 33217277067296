/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../views-components/components/custom-view/custom-view.component.ngfactory";
import * as i2 from "../../../customize/data/view-context";
import * as i3 from "../../../projects/stores/current-environment.store";
import * as i4 from "../../../views-components/components/custom-view/custom-view.component";
import * as i5 from "../../../customize/data/view-context-element";
import * as i6 from "../../../action-queries/services/action-controller/action-controller.service";
import * as i7 from "../../../theme/services/theme/theme.service";
import * as i8 from "../../../../common/notifications/services/notification/notification.service";
import * as i9 from "@angular/platform-browser";
import * as i10 from "@angular/common";
import * as i11 from "./custom-element-view.component";
var styles_CustomElementViewComponent = [];
var RenderType_CustomElementViewComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CustomElementViewComponent, data: {} });
export { RenderType_CustomElementViewComponent as RenderType_CustomElementViewComponent };
function View_CustomElementViewComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "app-custom-view", [], null, null, null, i1.View_CustomViewComponent_0, i1.RenderType_CustomViewComponent)), i0.ɵprd(131584, null, i2.ViewContext, i2.ViewContext, [i3.CurrentEnvironmentStore]), i0.ɵprd(512, null, i4.parametersToken, i5.ViewContextElement, [i2.ViewContext]), i0.ɵprd(512, null, i4.actionsToken, i5.ViewContextElement, [i2.ViewContext]), i0.ɵprd(512, null, i4.stateToken, i5.ViewContextElement, [i2.ViewContext]), i0.ɵprd(512, null, i4.layerToken, i5.ViewContextElement, [i2.ViewContext]), i0.ɵdid(6, 770048, null, 0, i4.CustomViewComponent, [i2.ViewContext, i4.parametersToken, i4.actionsToken, i4.stateToken, i4.layerToken, i6.ActionControllerService, i7.ThemeService, i8.NotificationService, i9.DomSanitizer, i0.Injector, i0.ChangeDetectorRef], { view: [0, "view"], parameters: [1, "parameters"], actionOutputs: [2, "actionOutputs"], actions: [3, "actions"], params: [4, "params"], parentContext: [5, "parentContext"], localContext: [6, "localContext"], stateSelectedEnabled: [7, "stateSelectedEnabled"], stateSelected: [8, "stateSelected"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.view; var currVal_1 = _co.parameters; var currVal_2 = _co.actionOutputs; var currVal_3 = _co.actions; var currVal_4 = _co.params; var currVal_5 = _co.parentContext; var currVal_6 = _co.localContext; var currVal_7 = _co.stateSelectedEnabled; var currVal_8 = _co.stateSelected; _ck(_v, 6, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }, null); }
function View_CustomElementViewComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" This component is not configured yet\n"]))], null, null); }
export function View_CustomElementViewComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomElementViewComponent_1)), i0.ɵdid(1, 16384, null, 0, i10.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomElementViewComponent_2)), i0.ɵdid(3, 16384, null, 0, i10.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.view; _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.view; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_CustomElementViewComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-custom-element-view", [], null, null, null, View_CustomElementViewComponent_0, RenderType_CustomElementViewComponent)), i0.ɵdid(1, 114688, null, 0, i11.CustomElementViewComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CustomElementViewComponentNgFactory = i0.ɵccf("app-custom-element-view", i11.CustomElementViewComponent, View_CustomElementViewComponent_Host_0, { view: "view", parameters: "parameters", actionOutputs: "actionOutputs", actions: "actions", params: "params", parentContext: "parentContext", parentContextElement: "parentContextElement", localContext: "localContext", stateSelectedEnabled: "stateSelectedEnabled", stateSelected: "stateSelected" }, {}, []);
export { CustomElementViewComponentNgFactory as CustomElementViewComponentNgFactory };
