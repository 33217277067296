/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../list-components/components/model-card-action/model-card-action.component.ngfactory";
import * as i2 from "../../../list-components/components/model-card-action/model-card-action.component";
import * as i3 from "../../../customize/services/customize/customize.service";
import * as i4 from "../../../action-queries/services/action/action.service";
import * as i5 from "../../../action-queries/services/action-controller/action-controller.service";
import * as i6 from "../../../../common/notifications/services/notification/notification.service";
import * as i7 from "@angular/common";
import * as i8 from "../../../list-components/components/model-card/model-card.component.ngfactory";
import * as i9 from "../../../list-components/components/model-card/model-card.component";
import * as i10 from "../../../custom-elements-components/components/custom-element-auto/custom-element-auto.component.ngfactory";
import * as i11 from "../../../custom-elements-components/components/custom-element-auto/custom-element-auto.component";
import * as i12 from "../../../customize/data/view-context";
import * as i13 from "../../../projects/stores/current-environment.store";
import * as i14 from "./kanban-board-item.component";
import * as i15 from "../../../projects/stores/current-project.store";
import * as i16 from "../../../custom-views/stores/custom-views.store";
import * as i17 from "../../../activities-components/services/timeline-controller/timeline.controller";
import * as i18 from "../../../activities/services/user-activity-count/user-activity-count.service";
var styles_KanbanBoardItemComponent = [];
var RenderType_KanbanBoardItemComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_KanbanBoardItemComponent, data: {} });
export { RenderType_KanbanBoardItemComponent as RenderType_KanbanBoardItemComponent };
function View_KanbanBoardItemComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-model-card-action", [["class", "kanban-board-item__buttons-item"]], [[2, "element__indicator-wrapper", null], [2, "hidden", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = ($event.stopPropagation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_ModelCardActionComponent_0, i1.RenderType_ModelCardActionComponent)), i0.ɵdid(2, 770048, null, 0, i2.ModelCardActionComponent, [i3.CustomizeService, i4.ActionService, i5.ActionControllerService, i6.NotificationService, i0.Injector, i0.ChangeDetectorRef], { model: [0, "model"], action: [1, "action"], context: [2, "context"], contextElement: [3, "contextElement"], accentColor: [4, "accentColor"], theme: [5, "theme"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.item.model; var currVal_3 = _v.context.$implicit; var currVal_4 = _co.context; var currVal_5 = _co.contextElement; var currVal_6 = _co.accentColor; var currVal_7 = _co.theme; _ck(_v, 2, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2).indicatorWrapper; var currVal_1 = i0.ɵnov(_v, 2).hidden; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_KanbanBoardItemComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "kanban-board-item__buttons"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_KanbanBoardItemComponent_3)), i0.ɵdid(2, 278528, null, 0, i7.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.settings.modelActions; _ck(_v, 2, 0, currVal_0); }, null); }
function View_KanbanBoardItemComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "kanban-board-item-widget__label"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.commentsCount; _ck(_v, 1, 0, currVal_0); }); }
function View_KanbanBoardItemComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "kanban-board-item__widget"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "a", [["class", "kanban-board-item-widget"], ["href", "javascript:void(0)"]], [[2, "kanban-board-item-widget_disabled", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        $event.stopPropagation();
        var pd_0 = (_co.openTimeline() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "div", [["class", "kanban-board-item-widget__icon icon-comments"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_KanbanBoardItemComponent_5)), i0.ɵdid(4, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.commentsCount; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.commentsConfigured; _ck(_v, 1, 0, currVal_0); }); }
function View_KanbanBoardItemComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, [["root_element", 1]], null, 6, "div", [["class", "kanban-board-item"]], [[2, "kanban-board-item_draggable", null], [2, "kanban-board-item_clickable", null], [2, "kanban-board-item_selected", null], [2, "kanban-board-item_theme", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.open($event, i0.ɵnov(_v, 0)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-model-card", [], null, [[null, "modelUpdated"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("modelUpdated" === en)) {
        var pd_0 = (_co.modelUpdated.emit($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_ModelCardComponent_0, i8.RenderType_ModelCardComponent)), i0.ɵdid(2, 245760, null, 0, i9.ModelCardComponent, [i4.ActionService, i0.ChangeDetectorRef], { item: [0, "item"], modelDescription: [1, "modelDescription"], visibleColumns: [2, "visibleColumns"], columns: [3, "columns"], columnActions: [4, "columnActions"], vertical: [5, "vertical"], context: [6, "context"], contextElement: [7, "contextElement"], theme: [8, "theme"] }, { modelUpdated: "modelUpdated" }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_KanbanBoardItemComponent_2)), i0.ɵdid(4, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_KanbanBoardItemComponent_4)), i0.ɵdid(6, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.item; var currVal_5 = _co.modelDescription; var currVal_6 = _co.visibleColumns; var currVal_7 = (((_co.settings.dataSource == null) ? null : _co.settings.dataSource.columns) || i0.ɵEMPTY_ARRAY); var currVal_8 = _co.settings.columnActions; var currVal_9 = true; var currVal_10 = _co.context; var currVal_11 = _co.contextElement; var currVal_12 = _co.theme; _ck(_v, 2, 0, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12); var currVal_13 = _co.settings.modelActions.length; _ck(_v, 4, 0, currVal_13); var currVal_14 = _co.settings.commentsEnabled; _ck(_v, 6, 0, currVal_14); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.draggable; var currVal_1 = true; var currVal_2 = _co.selected; var currVal_3 = _co.theme; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
function View_KanbanBoardItemComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, [["root_element", 1]], null, 3, "div", [["class", "kanban-board-item-view"]], [[2, "kanban-board-item-view_draggable", null], [2, "kanban-board-item-view_clickable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.open($event, i0.ɵnov(_v, 0)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "app-custom-element-auto", [], null, null, null, i10.View_CustomElementAutoComponent_0, i10.RenderType_CustomElementAutoComponent)), i0.ɵdid(2, 245760, null, 0, i11.CustomElementAutoComponent, [i5.ActionControllerService, i0.Injector], { source: [0, "source"], customView: [1, "customView"], actions: [2, "actions"], params: [3, "params"], context: [4, "context"], contextElement: [5, "contextElement"], localContext: [6, "localContext"], stateSelectedEnabled: [7, "stateSelectedEnabled"], stateSelected: [8, "stateSelected"] }, null), i0.ɵpid(131072, i7.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.customView.source; var currVal_3 = _co.customView; var currVal_4 = _co.settings.customActions; var currVal_5 = i0.ɵunv(_v, 2, 3, i0.ɵnov(_v, 3).transform(_co.viewParams$)); var currVal_6 = _co.context; var currVal_7 = _co.contextElement; var currVal_8 = _co.localContext; var currVal_9 = true; var currVal_10 = _co.selected; _ck(_v, 2, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.draggable; var currVal_1 = true; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_KanbanBoardItemComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_KanbanBoardItemComponent_1)), i0.ɵdid(1, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_KanbanBoardItemComponent_6)), i0.ɵdid(3, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.customView; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.customView; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_KanbanBoardItemComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-kanban-board-item", [], null, null, null, View_KanbanBoardItemComponent_0, RenderType_KanbanBoardItemComponent)), i0.ɵprd(131584, null, i12.ListItemViewContext, i12.ListItemViewContext, [i13.CurrentEnvironmentStore, i12.ViewContext]), i0.ɵdid(2, 770048, null, 0, i14.KanbanBoardItemComponent, [i15.CurrentProjectStore, i13.CurrentEnvironmentStore, i12.ListItemViewContext, i5.ActionControllerService, i16.CustomViewsStore, i17.TimelineController, i18.UserActivityCountService, i6.NotificationService, i0.Injector, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var KanbanBoardItemComponentNgFactory = i0.ɵccf("app-kanban-board-item", i14.KanbanBoardItemComponent, View_KanbanBoardItemComponent_Host_0, { item: "item", modelDescription: "modelDescription", settings: "settings", dataSource: "dataSource", visibleColumns: "visibleColumns", draggable: "draggable", context: "context", contextElement: "contextElement", selected: "selected", accentColor: "accentColor", theme: "theme" }, { select: "select", modelUpdated: "modelUpdated" }, []);
export { KanbanBoardItemComponentNgFactory as KanbanBoardItemComponentNgFactory };
