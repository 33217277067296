import { Injectable, Injector } from '@angular/core';
import isArray from 'lodash/isArray';
import { combineLatest, Observable, of } from 'rxjs';
import { delay, delayWhen, filter, map } from 'rxjs/operators';

import { ActionService, WorkflowExecuteEventType, WorkflowExecuteWorkflowFinishedEvent } from '@modules/action-queries';
import { ServerRequestError } from '@modules/api';
import { ViewContext, ViewContextElement } from '@modules/customize';
import { DataSourceType, ListModelDescriptionDataSource, ModelDescriptionDataSource } from '@modules/data-sources';
import { applyParamInput, ComputedDisplayField, DisplayFieldType } from '@modules/fields';
import { ModelService } from '@modules/model-queries';
import { getDefaultValue, Model } from '@modules/models';
import { Environment, Project } from '@modules/projects';
import {
  applyFrontendFiltering,
  applyFrontendPagination,
  applyFrontendSorting,
  GetQueryOptions,
  getQueryOptionsToParams,
  ModelResponse,
  paramsToGetQueryOptions
} from '@modules/resources';
import { EMPTY } from '@shared';

// TODO: Refactor imports
import { ITEM_OUTPUT } from '../../../list/data/outputs';

@Injectable()
export class ModelDescriptionDataSourceService {
  constructor(private modelService: ModelService, private actionService: ActionService, private injector: Injector) {}

  createModel(): Model {
    return Injector.create({
      providers: [{ provide: Model, deps: [Injector] }],
      parent: this.injector
    }).get<Model>(Model);
  }

  createGetResponse(): ModelResponse.GetResponse {
    return Injector.create({
      providers: [{ provide: ModelResponse.GetResponse, deps: [Injector] }],
      parent: this.injector
    }).get<ModelResponse.GetResponse>(ModelResponse.GetResponse);
  }

  get(options: {
    project: Project;
    environment: Environment;
    dataSource: ListModelDescriptionDataSource;
    params?: Object;
    staticData?: Object[];
    context?: ViewContext;
    contextElement?: ViewContextElement;
    localContext?: Object;
  }): Observable<ModelResponse.GetResponse> {
    const queryOptions = options.params ? paramsToGetQueryOptions(options.params) : {};
    return this.getAdv({
      ...options,
      queryOptions: queryOptions
    });
  }

  getAdv(options: {
    project: Project;
    environment: Environment;
    dataSource: ListModelDescriptionDataSource;
    staticData?: Object[];
    queryOptions?: GetQueryOptions;
    context?: ViewContext;
    contextElement?: ViewContextElement;
    localContext?: Object;
  }): Observable<ModelResponse.GetResponse> {
    const params = getQueryOptionsToParams(options.queryOptions);

    if (options.dataSource.type == DataSourceType.Query) {
      const resource = options.project
        .getEnvironmentResources(options.environment.uniqueName)
        .find(item => item.uniqueName == options.dataSource.queryResource);

      return this.modelService.getQueryAdv(
        options.project,
        options.environment,
        resource,
        options.dataSource.query,
        options.dataSource.queryParameters,
        options.queryOptions,
        (options.dataSource.columns || []).filter(item => item.type != DisplayFieldType.Computed)
      );
    } else if (options.dataSource.type == DataSourceType.Input) {
      let result: Object[] = isArray(options.staticData) ? options.staticData : [options.staticData];

      result = applyFrontendFiltering(result, params, options.dataSource.columns);

      const data = {
        results: result,
        count: result.length
      };
      const response = this.createGetResponse().deserialize(data, undefined, undefined);

      response.results.forEach(item => {
        item.deserializeAttributes(options.dataSource.columns);
      });

      applyFrontendSorting(response, params);
      applyFrontendPagination(response, params, true);

      // TODO: No delay breaks pages
      return of(response).pipe(delay(0));
    } else if (options.dataSource.type == DataSourceType.Workflow) {
      return this.actionService
        .executeWorkflow(options.dataSource.workflow, params, {
          context: options.context,
          contextElement: options.contextElement,
          localContext: options.localContext
        })
        .pipe(
          filter(event => event.type == WorkflowExecuteEventType.WorkflowFinished),
          map((event: WorkflowExecuteWorkflowFinishedEvent) => {
            if (event.error) {
              throw new ServerRequestError(event.error);
            }

            return event.result;
          }),
          map(workflowResult => {
            let result: Object[] = isArray(workflowResult) ? workflowResult : [workflowResult];

            result = applyFrontendFiltering(result, params, options.dataSource.columns);

            const data = {
              results: result,
              count: result.length
            };
            const response = this.createGetResponse().deserialize(data, undefined, undefined);

            response.results.forEach(item => {
              item.deserializeAttributes(options.dataSource.columns);
            });

            applyFrontendSorting(response, params);
            applyFrontendPagination(response, params, true);

            return response;
          })
        );
    } else {
      return of(undefined);
    }
  }

  getDetail(options: {
    project: Project;
    environment: Environment;
    dataSource: ModelDescriptionDataSource;
    params?: Object;
    staticData?: Object;
    context?: ViewContext;
    contextElement?: ViewContextElement;
    localContext?: Object;
  }): Observable<Model> {
    const queryOptions = options.params ? paramsToGetQueryOptions(options.params) : {};
    return this.getDetailAdv({
      ...options,
      queryOptions: queryOptions
    });
  }

  getDetailAdv(options: {
    project: Project;
    environment: Environment;
    dataSource: ModelDescriptionDataSource;
    queryOptions?: GetQueryOptions;
    staticData?: Object;
    context?: ViewContext;
    contextElement?: ViewContextElement;
    localContext?: Object;
  }): Observable<Model> {
    const params = getQueryOptionsToParams(options.queryOptions);

    if (options.dataSource.type == DataSourceType.Query) {
      const resource = options.project
        .getEnvironmentResources(options.environment.uniqueName)
        .find(item => item.uniqueName == options.dataSource.queryResource);

      return this.modelService
        .getDetailQueryAdv(
          options.project,
          options.environment,
          resource,
          options.dataSource.query,
          options.dataSource.queryParameters,
          options.queryOptions,
          options.dataSource.columns || []
        )
        .pipe(
          delayWhen(result => {
            if (result) {
              return this.resolveItemColumns(options.dataSource, result, {
                context: options.context,
                contextElement: options.contextElement
              });
            } else {
              return of(undefined);
            }
          })
        );
    } else if (options.dataSource.type == DataSourceType.Input) {
      let data: Object[] = isArray(options.staticData) ? options.staticData : [options.staticData];

      data = applyFrontendFiltering(data, params, options.dataSource.columns);

      const object = data[0];
      const model = this.createModel().deserialize(undefined, object);
      model.deserializeAttributes(options.dataSource.columns);

      // TODO: No delay breaks pages
      return of(model).pipe(
        delay(0),
        delayWhen(result => {
          if (result) {
            return this.resolveItemColumns(options.dataSource, result, {
              context: options.context,
              contextElement: options.contextElement
            });
          } else {
            return of(undefined);
          }
        })
      );
    } else if (options.dataSource.type == DataSourceType.Workflow) {
      return this.actionService
        .executeWorkflow(options.dataSource.workflow, params, {
          context: options.context,
          contextElement: options.contextElement,
          localContext: options.localContext
        })
        .pipe(
          filter(event => event.type == WorkflowExecuteEventType.WorkflowFinished),
          map((event: WorkflowExecuteWorkflowFinishedEvent) => {
            if (event.error) {
              throw new ServerRequestError(event.error);
            }

            return event.result;
          }),
          map(workflowResult => {
            let result: Object[] = isArray(workflowResult) ? workflowResult : [workflowResult];

            result = applyFrontendFiltering(result, params, options.dataSource.columns);

            const object = result[0];
            const model = this.createModel().deserialize(undefined, object);
            model.deserializeAttributes(options.dataSource.columns);

            return model;
          }),
          delayWhen(result => {
            if (result) {
              return this.resolveItemColumns(options.dataSource, result, {
                context: options.context,
                contextElement: options.contextElement
              });
            } else {
              return of(undefined);
            }
          })
        );
    } else {
      return of(undefined);
    }
  }

  resolveItemColumns(
    dataSource: ModelDescriptionDataSource,
    model: Model,
    options: {
      context?: ViewContext;
      contextElement?: ViewContextElement;
    } = {}
  ): Observable<Model> {
    const obs$ = dataSource.columns.reduce((acc, column) => {
      if (column instanceof ComputedDisplayField) {
        const value = this.resolveFlexItemValue(column, model, options);
        model.setAttribute(column.name, value);
      }

      return acc;
    }, []);

    if (!obs$.length) {
      return of(model);
    }

    return combineLatest(obs$).pipe(map(() => model));
  }

  resolveFlexItemValue(
    column: ComputedDisplayField,
    model: Model,
    options: {
      context?: ViewContext;
      contextElement?: ViewContextElement;
    } = {}
  ) {
    if (column.valueInput) {
      try {
        const value = applyParamInput(column.valueInput, {
          context: options.context,
          contextElement: options.contextElement,
          localContext: {
            [ITEM_OUTPUT]: model.getAttributes()
          }
          // field: { field: column.field, params: column.params }
        });

        if (value !== EMPTY) {
          return value;
        }
      } catch (e) {}
    }

    return getDefaultValue(column);
  }
}
