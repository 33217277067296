import { Injectable, Injector, OnDestroy } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import { Observable, of, throwError } from 'rxjs';
import { catchError, delayWhen, map, switchMap } from 'rxjs/operators';

import { AppFormGroup, FormUtils } from '@common/form-utils';
import { PopupService } from '@common/popups';
import { DEMO_RESOURCES_PROJECT } from '@modules/api';
import { MenuGeneratorService } from '@modules/menu';
import { ProjectSettingsService } from '@modules/project-settings';
import {
  Environment,
  Project,
  ProjectTokenService,
  Resource,
  ResourceTypeItem,
  SecretTokenService
} from '@modules/projects';
import { FirebaseParamsOptions, IsOptionsValidResult } from '@modules/resource-generators';
import { ResourceParamsResult } from '@modules/resources';
import { forceObservable } from '@shared';

@Injectable()
export abstract class BaseResourceSettingsForm<O = any, D = any> implements OnDestroy {
  project: Project;
  environment: Environment;
  resource: Resource;
  typeItem: ResourceTypeItem;
  resourceNameEditing = false;
  optionsHidden = false;
  modelDescriptionNameEditing = false;
  deploy = '';
  params: D;

  resourceForm = new FormGroup({
    name: new FormControl('', [Validators.required, control => this.checkResourceName(control)])
  });

  form: AppFormGroup;

  constructor(
    protected secretTokenService: SecretTokenService,
    protected formUtils: FormUtils,
    protected projectSettingsService: ProjectSettingsService,
    protected projectTokenService: ProjectTokenService,
    protected popupService: PopupService,
    protected menuGeneratorService: MenuGeneratorService,
    protected injector: Injector
  ) {}

  ngOnDestroy(): void {}

  checkResourceUniqueName(control: AbstractControl) {
    if (
      this.project &&
      this.environment &&
      this.project
        .getEnvironmentResources(this.environment.uniqueName)
        .find(item => item !== this.resource && item.uniqueName == control.value)
    ) {
      return {
        local: ['Resource with such Unique Name already exists']
      };
    }

    return null;
  }

  checkResourceName(control: AbstractControl) {
    if (
      this.project &&
      this.environment &&
      this.project
        .getEnvironmentResources(this.environment.uniqueName)
        .filter(item => !item.demo)
        .filter(item => !this.resource || item.uniqueName != this.resource.uniqueName)
        .find(item => item.name == control.value)
    ) {
      return {
        local: ['Resource with such Name already exists']
      };
    }

    return null;
  }

  generateResourceName(project: Project, environment: Environment) {
    const resourceNames = project
      .getEnvironmentResources(environment.uniqueName)
      .filter(item => !item.demo)
      .reduce((acc, item) => {
        acc[item.name.toLowerCase()] = item;
        return acc;
      }, {});
    const defaultName = this.typeItem.label;
    let i = 1;
    let newName: string;

    do {
      newName = i > 1 ? [defaultName, i].join(' ') : defaultName;
      ++i;
    } while (resourceNames.hasOwnProperty(newName.toLowerCase()));

    return newName;
  }

  init(
    project: Project,
    environment: Environment,
    resource: Resource,
    typeItem: ResourceTypeItem,
    resourceNameEditing: boolean,
    optionsHidden = false,
    params?: D
  ): Observable<void> {
    this.project = project;
    this.environment = environment;
    this.resource = resource;
    this.typeItem = typeItem;
    this.resourceNameEditing = resourceNameEditing;
    this.optionsHidden = optionsHidden;
    this.params = params;

    if (this.resource) {
      this.resourceForm.patchValue({
        unique_name: this.resource.uniqueName,
        name: this.resource.name
      });
    } else {
      this.resourceForm.patchValue({
        unique_name: this.typeItem.name,
        name: this.generateResourceName(project, environment)
      });
    }

    this.resourceForm.updateValueAndValidity();

    if (this.optionsHidden) {
      return of(undefined);
    }

    if (this.resource) {
      return this.initResourceValue();
    } else {
      return this.initDefaultValue();
    }
  }

  initResourceValue(): Observable<void> {
    return of(undefined);
  }

  initDefaultValue(): Observable<void> {
    return of(undefined);
  }

  isResourceFormValid() {
    return !this.resourceNameEditing || this.resourceForm.valid;
  }

  isValid() {
    return this.form.valid && this.isResourceFormValid();
  }

  getOptions?(): O | Observable<O>;

  isOptionsValid?(): Observable<IsOptionsValidResult>;

  abstract getParams(overrideOptions?: O): ResourceParamsResult | Observable<ResourceParamsResult>;

  getParamsSyncModelDescriptions(result: ResourceParamsResult): string[] {
    if (!result.modelDescriptions) {
      return;
    }

    const syncModelDescriptions = result.modelDescriptions.filter(
      item => !item['get_parameters'].filter(parameter => parameter['required']).length
    );

    if (syncModelDescriptions.length < result.modelDescriptions.length) {
      return syncModelDescriptions.map(item => item['model']);
    }
  }

  submit(paramsOptions?: O): Observable<Resource> {
    this.form.clearServerErrors();

    const isValidObs = this.isOptionsValid ? this.isOptionsValid() : of({});

    return isValidObs.pipe(
      switchMap(() => forceObservable(this.getParams(paramsOptions))),
      switchMap(params => {
        return this.projectSettingsService
          .applySyncParams({
            project: this.project,
            environment: this.environment,
            params: params,
            existingResource: this.resource,
            typeItem: this.typeItem
          })
          .pipe(
            map(result => {
              if (result.createdResource) {
                this.resource = result.createdResource;
              }
              return result.params;
            })
          );
      }),
      switchMap(params => {
        const instance = this.resource ? cloneDeep(this.resource) : new Resource();

        if (params.resourceName != undefined) {
          instance.name = params.resourceName;
        }

        if (params.resourceToken != undefined) {
          instance.token = params.resourceToken;
        }

        if (this.project.uniqueName == DEMO_RESOURCES_PROJECT) {
          instance.uniqueName = ['demo', this.typeItem.name].join('_');
        } else if (!this.resource) {
          instance.uniqueName = Resource.generateUniqueName(this.typeItem);
        }

        instance.type = this.typeItem.resourceType;
        instance.typeItem = this.typeItem;

        const saveParams: ResourceParamsResult = {
          resourceParams: params.resourceParams,
          modelDescriptions: params.modelDescriptions,
          resourceModelDescriptions: params.resourceModelDescriptions,
          actionDescriptions: params.actionDescriptions,
          secretTokens: params.secretTokens,
          storages: params.storages,
          extraTokens: params.extraTokens,
          sync: params.sync,
          syncModelDescriptions: params.syncModelDescriptions
        };

        return this.projectSettingsService
          .saveResource(this.project, this.environment, instance, !this.resource, {
            ...saveParams,
            mergeExisting: true,
            mergeExistingRename: this.modelDescriptionNameEditing,
            deleteNonExisting: true
          })
          .pipe(
            delayWhen(result => {
              return this.projectSettingsService.applySyncJob({
                project: this.project,
                environment: this.environment,
                resource: result,
                modelDescriptions: params.syncModelDescriptions,
                runInterval: params.syncRunInterval || this.typeItem.syncRunInterval
              });
            })
          );
      }),
      catchError(error => {
        console.error(error);
        this.formUtils.showFormErrors(this.form, error);
        return throwError(error);
      })
    );
  }
}
