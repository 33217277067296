import pickBy from 'lodash/pickBy';

import { isSet } from '@shared';

import { UserParams } from './user-params';

export class User {
  public id: number;
  public uid: string;
  public username: string;
  public email: string;
  public firstName: string;
  public lastName: string;
  public password: string;
  public photo: string;
  public isStaff = false;
  public emailSubscription = false;
  public emailVerified = true;
  public language: string;
  public timezone: string;
  public hasPassword = true;
  public params = new UserParams();
  public currentPassword: string;

  deserialize(data: Object): User {
    this.id = data['id'];
    this.uid = data['uid'];
    this.username = data['username'];
    this.email = data['email'];
    this.firstName = data['first_name'];
    this.lastName = data['last_name'];
    this.password = data['password'];
    this.photo = data['photo'];
    this.isStaff = data['is_staff'];
    this.emailSubscription = data['email_subscription'];
    this.emailVerified = data['email_verified'];
    this.language = data['language'];
    this.timezone = data['timezone'];

    if (data.hasOwnProperty('has_password')) {
      this.hasPassword = data['has_password'];
    }

    if (data['params']) {
      try {
        this.params = new UserParams().deserialize(JSON.parse(data['params']));
      } catch (e) {}
    }

    return this;
  }

  serialize(fields?: string[]): Object {
    let data: Object = {
      id: this.id,
      username: this.username,
      email: this.email,
      first_name: this.firstName,
      last_name: this.lastName,
      password: this.password,
      email_subscription: this.emailSubscription,
      language: this.language,
      timezone: this.timezone,
      current_password: this.currentPassword,
      params: JSON.stringify(this.params.serialize())
    };
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }

  get strShort() {
    return this.firstName || this.username;
  }

  get strFull() {
    return this.firstName || this.lastName ? `${this.firstName} ${this.lastName}` : this.username;
  }

  get strFullWithEmail(): string {
    return `${this.strFull} (${this.email})`;
  }

  isAnonymous() {
    return !isSet(this.uid);
  }
}
