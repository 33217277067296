<div class="sidebar-image-radio">
  <div
    class="sidebar-image-radio__item"
    [class.sidebar-image-radio__item_active]="control.value"
    (click)="control.patchValue(true)"
  >
    <div
      class="sidebar-image-radio__item-image"
      [style.width.px]="86"
      [style.height.px]="48"
      [style.background-image]="'/assets/images/element-wrap/card-wrap.svg' | appDeployCssUrl"
    ></div>

    <div class="sidebar-image-radio__item-footer">
      <div class="sidebar-image-radio__item-radio"></div>
      <div class="sidebar-image-radio__item-title">Inside card</div>
    </div>
  </div>

  <div
    class="sidebar-image-radio__item"
    [class.sidebar-image-radio__item_active]="!control.value"
    (click)="control.patchValue(false)"
  >
    <div
      class="sidebar-image-radio__item-image"
      [style.width.px]="86"
      [style.height.px]="48"
      [style.background-image]="'/assets/images/element-wrap/no-wrap.svg' | appDeployCssUrl"
    ></div>

    <div class="sidebar-image-radio__item-footer">
      <div class="sidebar-image-radio__item-radio"></div>
      <div class="sidebar-image-radio__item-title">Do not wrap</div>
    </div>
  </div>
</div>
