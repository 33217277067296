/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../list-components/components/model-card-action/model-card-action.component.ngfactory";
import * as i2 from "../../../list-components/components/model-card-action/model-card-action.component";
import * as i3 from "../../../customize/services/customize/customize.service";
import * as i4 from "../../../action-queries/services/action/action.service";
import * as i5 from "../../../action-queries/services/action-controller/action-controller.service";
import * as i6 from "../../../../common/notifications/services/notification/notification.service";
import * as i7 from "@angular/common";
import * as i8 from "../../../list-components/components/model-card/model-card.component.ngfactory";
import * as i9 from "../../../list-components/components/model-card/model-card.component";
import * as i10 from "../../../custom-elements-components/components/custom-element-auto/custom-element-auto.component.ngfactory";
import * as i11 from "../../../custom-elements-components/components/custom-element-auto/custom-element-auto.component";
import * as i12 from "../../../fields/components/auto-field/auto-field.component.ngfactory";
import * as i13 from "../../../fields/components/auto-field/auto-field.component";
import * as i14 from "../../../../shared/pipes/is-set/is-set.pipe";
import * as i15 from "./timeline-item.component";
import * as i16 from "../../../custom-views/stores/custom-views.store";
var styles_TimelineItemComponent = [];
var RenderType_TimelineItemComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TimelineItemComponent, data: {} });
export { RenderType_TimelineItemComponent as RenderType_TimelineItemComponent };
function View_TimelineItemComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-model-card-action", [["class", "timeline__item-buttons-item"]], [[2, "element__indicator-wrapper", null], [2, "hidden", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = ($event.stopPropagation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_ModelCardActionComponent_0, i1.RenderType_ModelCardActionComponent)), i0.ɵdid(2, 770048, null, 0, i2.ModelCardActionComponent, [i3.CustomizeService, i4.ActionService, i5.ActionControllerService, i6.NotificationService, i0.Injector, i0.ChangeDetectorRef], { model: [0, "model"], action: [1, "action"], context: [2, "context"], contextElement: [3, "contextElement"], accentColor: [4, "accentColor"], theme: [5, "theme"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.item.model; var currVal_3 = _v.context.$implicit; var currVal_4 = _co.context; var currVal_5 = _co.contextElement; var currVal_6 = _co.accentColor; var currVal_7 = _co.theme; _ck(_v, 2, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2).indicatorWrapper; var currVal_1 = i0.ɵnov(_v, 2).hidden; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_TimelineItemComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "timeline__item-buttons"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TimelineItemComponent_3)), i0.ɵdid(2, 278528, null, 0, i7.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.settings.modelActions; _ck(_v, 2, 0, currVal_0); }, null); }
function View_TimelineItemComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "timeline__item-inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-model-card", [], null, null, null, i8.View_ModelCardComponent_0, i8.RenderType_ModelCardComponent)), i0.ɵdid(2, 245760, null, 0, i9.ModelCardComponent, [i4.ActionService, i0.ChangeDetectorRef], { item: [0, "item"], modelDescription: [1, "modelDescription"], visibleColumns: [2, "visibleColumns"], columns: [3, "columns"], columnActions: [4, "columnActions"], labels: [5, "labels"], compact: [6, "compact"], context: [7, "context"], contextElement: [8, "contextElement"], theme: [9, "theme"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TimelineItemComponent_2)), i0.ɵdid(4, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.item; var currVal_1 = _co.modelDescription; var currVal_2 = _co.visibleColumns; var currVal_3 = (((_co.settings.dataSource == null) ? null : _co.settings.dataSource.columns) || i0.ɵEMPTY_ARRAY); var currVal_4 = _co.settings.columnActions; var currVal_5 = !_co.settings.hideLabels; var currVal_6 = true; var currVal_7 = _co.context; var currVal_8 = _co.contextElement; var currVal_9 = _co.theme; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); var currVal_10 = _co.settings.modelActions.length; _ck(_v, 4, 0, currVal_10); }, null); }
function View_TimelineItemComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-custom-element-auto", [["class", "timeline__item-view"]], null, null, null, i10.View_CustomElementAutoComponent_0, i10.RenderType_CustomElementAutoComponent)), i0.ɵdid(1, 245760, null, 0, i11.CustomElementAutoComponent, [i5.ActionControllerService, i0.Injector], { source: [0, "source"], customView: [1, "customView"], actions: [2, "actions"], params: [3, "params"], context: [4, "context"], contextElement: [5, "contextElement"], localContext: [6, "localContext"], stateSelectedEnabled: [7, "stateSelectedEnabled"], stateSelected: [8, "stateSelected"] }, null), i0.ɵpid(131072, i7.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.customView.source; var currVal_1 = _co.customView; var currVal_2 = _co.settings.customActions; var currVal_3 = i0.ɵunv(_v, 1, 3, i0.ɵnov(_v, 2).transform(_co.viewParams$)); var currVal_4 = _co.context; var currVal_5 = _co.contextElement; var currVal_6 = _co.localContext; var currVal_7 = true; var currVal_8 = _co.selected; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }, null); }
function View_TimelineItemComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-auto-field", [], null, null, null, i12.View_AutoFieldComponent_0, i12.RenderType_AutoFieldComponent)), i0.ɵdid(1, 770048, null, 0, i13.AutoFieldComponent, [i0.ChangeDetectorRef], { field: [0, "field"], readonly: [1, "readonly"], value: [2, "value"], label: [3, "label"], context: [4, "context"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.createField(_co.dateColumnListItem.column); var currVal_1 = true; var currVal_2 = _co.item.model.getAttribute(_co.dateColumnListItem.column.name); var currVal_3 = false; var currVal_4 = ((_co.contextElement == null) ? null : _co.contextElement.context); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
function View_TimelineItemComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00A0"]))], null, null); }
export function View_TimelineItemComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i14.IsSetPipe, []), (_l()(), i0.ɵeld(1, 0, [["root_element", 1]], null, 11, "div", [["class", "timeline__item"]], [[2, "timeline__item_clickable", null], [2, "timeline__item_selected", null], [4, "margin-bottom", "px"]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick($event, i0.ɵnov(_v, 1)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵppd(2, 1), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TimelineItemComponent_1)), i0.ɵdid(4, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TimelineItemComponent_4)), i0.ɵdid(6, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(7, 0, null, null, 4, "div", [["class", "timeline__item-title"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TimelineItemComponent_5)), i0.ɵdid(9, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TimelineItemComponent_6)), i0.ɵdid(11, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(12, 0, null, null, 0, "div", [["class", "timeline__item-dot"]], [[2, "timeline__item-dot_icon", null], [2, "icon-time", null]], null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_3 = !_co.customView; _ck(_v, 4, 0, currVal_3); var currVal_4 = _co.customView; _ck(_v, 6, 0, currVal_4); var currVal_5 = _co.dateColumnListItem; _ck(_v, 9, 0, currVal_5); var currVal_6 = !_co.dateColumnListItem; _ck(_v, 11, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = true; var currVal_1 = _co.selected; var currVal_2 = (i0.ɵunv(_v, 1, 2, _ck(_v, 2, 0, i0.ɵnov(_v, 0), _co.settings.gapVertical)) ? _co.settings.gapVertical : null); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_7 = _co.first; var currVal_8 = _co.first; _ck(_v, 12, 0, currVal_7, currVal_8); }); }
export function View_TimelineItemComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-timeline-item", [], null, null, null, View_TimelineItemComponent_0, RenderType_TimelineItemComponent)), i0.ɵdid(1, 770048, null, 0, i15.TimelineItemComponent, [i0.ElementRef, i4.ActionService, i16.CustomViewsStore, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TimelineItemComponentNgFactory = i0.ɵccf("app-timeline-item", i15.TimelineItemComponent, View_TimelineItemComponent_Host_0, { first: "first", item: "item", modelDescription: "modelDescription", settings: "settings", dataSource: "dataSource", visibleColumns: "visibleColumns", inverse: "inverse", context: "context", contextElement: "contextElement", selected: "selected", accentColor: "accentColor", theme: "theme" }, { select: "select", modelUpdated: "modelUpdated" }, []);
export { TimelineItemComponentNgFactory as TimelineItemComponentNgFactory };
