import { lookups } from '@modules/field-lookups';
import { isSet } from '@shared';

export function parseFilterPath(name: string): string[] {
  name = name.replace(/__jet_pk__/, '%5F%5Fjet_pk%5F%5F');
  name = name.replace(/__jet_item_pk__/, '%5F%5Fjet_item_pk%5F%5F');
  name = name.replace(/^__/, '%5F%5F');
  name = name.replace(/__$/, '%5F%5F');

  return name.split('__').map(item => item.replace(/%5F%5F/g, '__'));
}

export function parseFilterName(
  name: string
): {
  field: string;
  path: string[];
  lookup?: string;
  exclude?: boolean;
} {
  let field: string;
  let lookup: string;
  let exclude = false;

  const lookupsRule = lookups
    .filter(item => isSet(item.lookup))
    .map(item => item.lookup)
    .join('|');

  const excludeLookupMatch = name.match(new RegExp(`^exclude__([\\s\\S]+)__(${lookupsRule})$`));
  const excludeMatch = name.match(/^exclude__([\s\S]+)$/);
  const lookupMatch = name.match(new RegExp(`^([\\s\\S]+)__(${lookupsRule})$`));

  if (excludeLookupMatch) {
    field = excludeLookupMatch[1];
    lookup = excludeLookupMatch[2];
    exclude = true;
  } else if (excludeMatch) {
    field = excludeMatch[1];
    exclude = true;
  } else if (lookupMatch) {
    field = lookupMatch[1];
    lookup = lookupMatch[2];
  } else {
    field = name;
  }

  return {
    field: field.replace(/%5F%5F/g, '__'),
    path: parseFilterPath(field).map(item => item.replace(/%5F%5F/g, '__')),
    lookup: lookup,
    exclude: exclude
  };
}
