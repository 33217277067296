/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../custom-elements-components/components/custom-element-auto/custom-element-auto.component.ngfactory";
import * as i2 from "../../../custom-elements-components/components/custom-element-auto/custom-element-auto.component";
import * as i3 from "../../../action-queries/services/action-controller/action-controller.service";
import * as i4 from "@angular/common";
import * as i5 from "../../../features/components/feature-overview-line/feature-overview-line.component.ngfactory";
import * as i6 from "../../../features/components/feature-overview-line/feature-overview-line.component";
import * as i7 from "../../../projects/stores/current-project.store";
import * as i8 from "../../../../shared/directives/element/element.directive";
import * as i9 from "../../../../core/services/document/document.service";
import * as i10 from "../../../customize/data/view-context-element";
import * as i11 from "../../../customize/data/view-context";
import * as i12 from "./custom-element.component";
import * as i13 from "../../../projects/stores/current-environment.store";
import * as i14 from "../../../customize/services/customize/customize.service";
import * as i15 from "../../../custom-views/services/custom-view/custom-view.service";
import * as i16 from "../../../custom-views/stores/custom-views.store";
import * as i17 from "../custom-page-popup/custom-page-popup.component";
var styles_CustomElementComponent = [];
var RenderType_CustomElementComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CustomElementComponent, data: {} });
export { RenderType_CustomElementComponent as RenderType_CustomElementComponent };
function View_CustomElementComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_CustomElementComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-custom-element-auto", [], null, null, null, i1.View_CustomElementAutoComponent_0, i1.RenderType_CustomElementAutoComponent)), i0.ɵdid(1, 245760, null, 0, i2.CustomElementAutoComponent, [i3.ActionControllerService, i0.Injector], { source: [0, "source"], customView: [1, "customView"], actions: [2, "actions"], params: [3, "params"], context: [4, "context"], contextElement: [5, "contextElement"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.source; var currVal_1 = _co.customView$.value; var currVal_2 = _co.element.actions; var currVal_3 = _co.params; var currVal_4 = _co.context; var currVal_5 = _co.viewContextElement; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
function View_CustomElementComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" This component is not configured yet "]))], null, null); }
function View_CustomElementComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomElementComponent_5)), i0.ɵdid(2, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomElementComponent_6)), i0.ɵdid(4, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.customView$.value; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.customView$.value; _ck(_v, 4, 0, currVal_1); }, null); }
function View_CustomElementComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomElementComponent_4)), i0.ɵdid(1, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.loading; _ck(_v, 1, 0, currVal_0); }, null); }
function View_CustomElementComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "app-feature-overview-line", [], null, null, null, i5.View_FeatureOverviewLineComponent_0, i5.RenderType_FeatureOverviewLineComponent)), i0.ɵdid(1, 245760, null, 0, i6.FeatureOverviewLineComponent, [i7.CurrentProjectStore], null, null), (_l()(), i0.ɵeld(2, 0, null, 0, 1, "div", [["data-subtitle", ""]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Paid Feature"])), (_l()(), i0.ɵeld(4, 0, null, 1, 3, "div", [["data-title", ""]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Create any component with "])), (_l()(), i0.ɵeld(6, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Custom Components"])), (_l()(), i0.ɵeld(8, 0, null, 2, 1, "div", [["data-description", ""]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Write HTML or upload JS-based component built with React/Vue/Angular or JS Vanilla "]))], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_CustomElementComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["appElement", ""], ["style", "width: 100%; height: 100%;"]], null, [[null, "appElementFirstVisible"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("appElementFirstVisible" === en)) {
        var pd_0 = (_co.onFirstVisible() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 8601600, null, 0, i8.ElementDirective, [i0.ElementRef, i9.DocumentService], { appElementDetectVisible: [0, "appElementDetectVisible"] }, { appElementFirstVisible: "appElementFirstVisible" }), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_CustomElementComponent_2)), i0.ɵdid(3, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfThen: [1, "ngIfThen"], ngIfElse: [2, "ngIfElse"] }, null), i0.ɵpid(131072, i4.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(0, [["content", 2]], null, 0, null, View_CustomElementComponent_3)), (_l()(), i0.ɵand(0, [["not_enabled", 2]], null, 0, null, View_CustomElementComponent_7))], function (_ck, _v) { var _co = _v.component; var currVal_0 = true; _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.featureSources.includes(_co.source) && !i0.ɵunv(_v, 3, 0, i0.ɵnov(_v, 4).transform(_co.currentProjectStore.instance$)).features.isCustomViewsEnabled()); var currVal_2 = i0.ɵnov(_v, 6); var currVal_3 = i0.ɵnov(_v, 5); _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_CustomElementComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 2, null, View_CustomElementComponent_1)), i0.ɵdid(1, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i4.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.visible || i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.customizeService.enabled$))); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_CustomElementComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-custom-element", [], null, null, null, View_CustomElementComponent_0, RenderType_CustomElementComponent)), i0.ɵprd(131584, null, i10.ViewContextElement, i10.ViewContextElement, [i11.ViewContext]), i0.ɵdid(2, 770048, null, 0, i12.CustomElementComponent, [i7.CurrentProjectStore, i13.CurrentEnvironmentStore, i14.CustomizeService, i10.ViewContextElement, i15.CustomViewService, i16.CustomViewsStore, i0.ChangeDetectorRef, [2, i17.CustomPagePopupComponent]], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var CustomElementComponentNgFactory = i0.ɵccf("app-custom-element", i12.CustomElementComponent, View_CustomElementComponent_Host_0, { element: "element", elementActive: "elementActive", context: "context", visible: "visible", actionClicked: "actionClicked", accentColor: "accentColor" }, { updated: "updated", replaceRequested: "replaceRequested", deleteRequested: "deleteRequested" }, []);
export { CustomElementComponentNgFactory as CustomElementComponentNgFactory };
