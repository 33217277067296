import { Injectable, Injector, OnDestroy } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, Observable, of } from 'rxjs';
import { distinctUntilChanged, map, skip } from 'rxjs/operators';

import { localize } from '@common/localize';
import {
  applyRangeSliderElementStyles,
  getRangeSliderElementStyles,
  MarginControl,
  RangeSliderElementItem
} from '@modules/customize';
import { ElementConfigurationService } from '@modules/customize-configuration';
import { Input, Input as FieldInput, InputValueType } from '@modules/fields';
import { FieldInputControl } from '@modules/parameters';
import { controlValid, controlValue, isSet } from '@shared';

import { RangeSliderElementStylesControl } from '../styles-range-slider-element-edit/range-slider-element-styles.control';

const defaultMinValueInput = {
  path: ['value'],
  value_type: InputValueType.StaticValue,
  static_value: 1
};

const defaultMaxValueInput = {
  path: ['value'],
  value_type: InputValueType.StaticValue,
  static_value: 10
};

const defaultStepSizeInput = {
  path: ['value'],
  value_type: InputValueType.StaticValue,
  static_value: 1
};

export function validateActions(): AsyncValidatorFn {
  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    const parent = control.parent as CustomizeBarRangeSliderEditForm;

    if (!parent) {
      return of(null);
    }

    if (!control.value || !control.value.length) {
      return of(null);
    }

    return combineLatest(control.value.map(item => parent.elementConfigurationService.isActionConfigured(item))).pipe(
      map(result => {
        if (result.some(configured => !configured)) {
          return { required: true };
        }
      })
    );
  };
}

@Injectable()
export class CustomizeBarRangeSliderEditForm extends FormGroup implements OnDestroy {
  element: RangeSliderElementItem;

  controls: {
    name: FormControl;
    verbose_name: FormControl;
    label_additional: FormControl;
    from: FieldInputControl;
    to: FieldInputControl;
    min_value_input: FieldInputControl;
    max_value_input: FieldInputControl;
    step_size_input: FieldInputControl;
    required: FormControl;
    disable_input: FieldInputControl;
    tint: FormControl;
    tooltip: FormControl;
    visible_input: FieldInputControl;
    on_change_actions: FormControl;
    card_wrap: FormControl;

    element_styles: RangeSliderElementStylesControl;
  };

  constructor(public elementConfigurationService: ElementConfigurationService, private injector: Injector) {
    super({
      name: new FormControl(''),
      verbose_name: new FormControl(''),
      label_additional: new FormControl(''),
      from: new FieldInputControl({ path: ['value'] }),
      to: new FieldInputControl({ path: ['value'] }),
      min_value_input: new FieldInputControl(defaultMinValueInput),
      max_value_input: new FieldInputControl(defaultMaxValueInput),
      step_size_input: new FieldInputControl(defaultStepSizeInput),
      required: new FormControl(false),
      disable_input: new FieldInputControl({ path: ['value'] }),
      tint: new FormControl(null),
      tooltip: new FormControl(''),
      visible_input: new FieldInputControl({ path: ['value'] }),
      margin: new MarginControl(),
      on_change_actions: new FormControl([], undefined, validateActions()),
      card_wrap: new FormControl(true),

      element_styles: new RangeSliderElementStylesControl(injector)
    });
  }

  ngOnDestroy(): void {}

  init(element: RangeSliderElementItem, firstInit = false) {
    this.element = element;

    const value = {
      name: element.name ? element.name : 'Range',
      verbose_name: element.verboseName,
      label_additional: element.labelAdditional,
      from: element.from ? element.from.serializeWithoutPath() : {},
      to: element.to ? element.to.serializeWithoutPath() : {},
      min_value_input: element.minValueInput ? element.minValueInput.serializeWithoutPath() : defaultMinValueInput,
      max_value_input: element.maxValueInput ? element.maxValueInput.serializeWithoutPath() : defaultMaxValueInput,
      step_size_input: element.stepSizeInput ? element.stepSizeInput.serializeWithoutPath() : defaultStepSizeInput,
      required: element.required,
      disable_input: element.disableInput ? element.disableInput.serializeWithoutPath() : {},
      tint: element.tint,
      tooltip: element.tooltip,
      visible_input: element.visibleInput ? element.visibleInput.serializeWithoutPath() : {},
      on_change_actions: element.onChangeActions,
      card_wrap: element.cardWrap
    };

    this.patchValue(value, { emitEvent: false });

    const elementStyles = getRangeSliderElementStyles(element);
    this.controls.element_styles.deserialize(elementStyles);

    if (!firstInit) {
      this.markAsDirty();
    }

    controlValue(this.controls.required)
      .pipe(distinctUntilChanged(), skip(1), untilDestroyed(this))
      .pipe(untilDestroyed(this))
      .subscribe(required => {
        const optionalLabel = `(${localize('optional')})`;

        if (!required && !isSet(this.controls.label_additional.value)) {
          this.controls.label_additional.patchValue(optionalLabel);
        } else if (required && this.controls.label_additional.value == optionalLabel) {
          this.controls.label_additional.patchValue('');
        }
      });
  }

  isConfigured(instance: RangeSliderElementItem): boolean {
    return this.elementConfigurationService.isRangeSliderConfigured(instance);
  }

  controlsValid$(controls: AbstractControl[]): Observable<boolean> {
    if (!controls.length) {
      return of(true);
    }

    return combineLatest(controls.map(control => controlValid(control))).pipe(
      map(result => result.every(item => item))
      // debounceTime(60) TODO: Too long wait with debounceTime
    );
  }

  actionsValid$(): Observable<boolean> {
    return this.controlsValid$([this.controls.on_change_actions]);
  }

  submit(): RangeSliderElementItem {
    const instance = cloneDeep(this.element) as RangeSliderElementItem;

    instance.name = this.controls.name.value;
    instance.verboseName = this.controls.verbose_name.value;
    instance.labelAdditional = this.controls.label_additional.value;
    instance.from = this.controls.from.value ? new FieldInput().deserialize(this.controls.from.value) : undefined;
    instance.to = this.controls.to.value ? new FieldInput().deserialize(this.controls.to.value) : undefined;
    instance.minValueInput = this.controls.min_value_input.value
      ? new FieldInput().deserialize(this.controls.min_value_input.value)
      : undefined;
    instance.maxValueInput = this.controls.max_value_input.value
      ? new FieldInput().deserialize(this.controls.max_value_input.value)
      : undefined;
    instance.stepSizeInput = this.controls.step_size_input.value
      ? new FieldInput().deserialize(this.controls.step_size_input.value)
      : undefined;
    instance.required = this.controls.required.value;
    instance.disableInput = this.controls.disable_input.value
      ? new FieldInput().deserialize(this.controls.disable_input.value)
      : undefined;
    instance.tint = this.controls.tint.value;
    instance.tooltip = this.controls.tooltip.value;

    if (this.controls.visible_input.value) {
      instance.visibleInput = new Input().deserialize(this.controls.visible_input.value);
    } else {
      instance.visibleInput = undefined;
    }

    instance.onChangeActions = this.controls.on_change_actions.value;
    instance.cardWrap = this.controls.card_wrap.value;

    const elementStyles = this.controls.element_styles.serialize();
    applyRangeSliderElementStyles(instance, elementStyles);

    return instance;
  }
}
