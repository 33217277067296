import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { TweenMax } from 'gsap';
import clamp from 'lodash/clamp';
import cloneDeep from 'lodash/cloneDeep';
import round from 'lodash/round';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, fromEvent, Observable, of } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

import { PickColorService } from '@modules/colors';
import { CustomView } from '@modules/custom-views';
import { ViewContext } from '@modules/customize';
import { Frame, Layer, View } from '@modules/views';
import { MouseButton } from '@shared';

import {
  CustomizeGradient,
  ViewEditorContext,
  ViewEditorGuideSymbol
} from '../../services/view-editor-context/view-editor.context';
import { ViewEditorMultipleLayers } from '../../services/view-editor-multiple-layers/view-editor-multiple-layers';
import { isViewLayerClick } from '../auto-layer/auto-layer.component';
import { isViewBoundsMouseDown } from '../layers/base/layer-resizable.directive';
import { isCustomizeGradientMouseDown } from '../ui/gradient-position/gradient-position.component';
import { isViewViewClick } from '../view-editor-view/view-editor-view.component';

@Component({
  selector: 'app-view-editor-viewport',
  templateUrl: './view-editor-viewport.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ViewEditorViewportComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() view: View;
  @Input() viewContext: ViewContext;
  @Input() componentLabel = 'component';
  @Input() stateSelectedEnabled = false;
  @Input() templatesEnabled = true;
  @Input() dark = false;
  @Output() importFigmaNode = new EventEmitter<void>();
  @Output() importSketchFile = new EventEmitter<void>();
  @Output() useShared = new EventEmitter<CustomView>();

  @ViewChild('viewport_element') viewportElement: ElementRef;
  @ViewChild('canvas_scale_element') canvasScaleElement: ElementRef;
  @ViewChild('canvas_element') canvasElement: ElementRef;
  @ViewChild('overlay_canvas_element') overlayCanvasElement: ElementRef;

  navigate = false;
  navigating = false;
  interactionMouseDown = false;
  multipleLayersFrame: Frame;
  originalMultipleLayers: Layer[];
  originalMultipleLayersFrame: Frame;
  customizeGradient: CustomizeGradient;
  pickingColor$: Observable<boolean>;
  guideSymbols = ViewEditorGuideSymbol;

  constructor(
    public editorContext: ViewEditorContext,
    public multipleLayers: ViewEditorMultipleLayers,
    private pickColorService: PickColorService,
    private zone: NgZone,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.pickingColor$ = this.pickColorService.isPicking$();

    combineLatest(this.editorContext.viewportX$, this.editorContext.viewportY$, this.editorContext.viewportScale$)
      .pipe(untilDestroyed(this))
      .subscribe(([x, y, scale]) => {
        TweenMax.set(this.canvasScaleElement.nativeElement, {
          scale: scale,
          fontSize: 10 / scale,
          roundProps: { fontSize: 0.01 }
        });

        TweenMax.set(this.canvasElement.nativeElement, {
          x: -x,
          y: -y,
          xPercent: -50,
          yPercent: -50
        });

        TweenMax.set(this.overlayCanvasElement.nativeElement, {
          x: -x * scale,
          y: -y * scale,
          xPercent: -50,
          yPercent: -50,
          fontSize: 10 * scale,
          roundProps: { fontSize: 0.01 }
        });
      });

    this.multipleLayers.frame$.pipe(untilDestroyed(this)).subscribe(frame => {
      this.multipleLayersFrame = frame;
      this.cd.markForCheck();
    });

    this.editorContext.customizingGradient$.pipe(untilDestroyed(this)).subscribe(value => {
      this.customizeGradient = value;
      this.cd.markForCheck();
    });

    this.editorContext.navigateMode$
      .pipe(
        switchMap(navigate => {
          this.navigate = navigate;
          this.cd.markForCheck();

          if (navigate) {
            return fromEvent<MouseEvent>(this.viewportElement.nativeElement, 'mousedown').pipe(
              filter(e => e.button == MouseButton.Main)
            );
          } else {
            return of(undefined);
          }
        }),
        untilDestroyed(this)
      )
      .subscribe(downEvent => {
        if (!downEvent) {
          return;
        }

        const subscriptions = [];
        const originalX = this.editorContext.viewportX$.value;
        const originalY = this.editorContext.viewportY$.value;

        this.navigating = true;
        this.cd.markForCheck();

        subscriptions.push(
          fromEvent<MouseEvent>(document, 'mousemove')
            .pipe(untilDestroyed(this))
            .subscribe(moveEvent => {
              moveEvent.preventDefault();

              const scale = this.editorContext.viewportScale$.value;
              const deltaX = (moveEvent.clientX - downEvent.clientX) / scale;
              const deltaY = (moveEvent.clientY - downEvent.clientY) / scale;

              const x = round(originalX - deltaX, 2);
              const y = round(originalY - deltaY, 2);

              this.editorContext.viewportX$.next(x);
              this.editorContext.viewportY$.next(y);
            })
        );

        subscriptions.push(
          fromEvent<MouseEvent>(document, 'mouseup')
            .pipe(
              filter(e => e.button == MouseButton.Main),
              untilDestroyed(this)
            )
            .subscribe(upEvent => {
              upEvent.preventDefault();
              subscriptions.forEach(item => item.unsubscribe());

              this.navigating = false;
              this.cd.markForCheck();
            })
        );
      });
  }

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {
    this.zone.runOutsideAngular(() => {
      fromEvent<WheelEvent>(this.viewportElement.nativeElement, 'mousewheel')
        .pipe(untilDestroyed(this))
        .subscribe(e => this.onMouseWheel(e));
    });
  }

  onMouseWheel(event: WheelEvent) {
    if (event.metaKey || event.ctrlKey) {
      event.preventDefault();
      event.stopPropagation();

      const currentScale = this.editorContext.viewportScale$.value;
      const scaleSpeed = currentScale * 0.005;
      const scale = clamp(
        currentScale + event.deltaY * scaleSpeed * -1,
        this.editorContext.viewportScaleMin,
        this.editorContext.viewportScaleMax
      );

      const bounds = this.viewportElement.nativeElement.getBoundingClientRect();
      const deltaXMultiplier = (event.clientX - (bounds.left + bounds.width * 0.5)) / (bounds.width * 0.5);
      const deltaYMultiplier = (event.clientY - (bounds.top + bounds.height * 0.5)) / (bounds.height * 0.5);
      const x = this.editorContext.viewportX$.value;
      const y = this.editorContext.viewportY$.value;
      const newX = x + (bounds.width / currentScale - bounds.width / scale) * 0.5 * deltaXMultiplier;
      const newY = y + (bounds.height / currentScale - bounds.height / scale) * 0.5 * deltaYMultiplier;

      this.editorContext.viewportX$.next(newX);
      this.editorContext.viewportY$.next(newY);
      this.editorContext.viewportScale$.next(scale);
    } else {
      event.preventDefault();
      event.stopPropagation();

      const currentScale = this.editorContext.viewportScale$.value;
      const x = round(this.editorContext.viewportX$.value + event.deltaX / currentScale, 2);
      const y = round(this.editorContext.viewportY$.value + event.deltaY / currentScale, 2);

      this.editorContext.viewportX$.next(x);
      this.editorContext.viewportY$.next(y);
    }
  }

  onMouseDown(e: MouseEvent) {
    this.interactionMouseDown =
      this.editorContext.isCreateTool() ||
      isViewBoundsMouseDown(e) ||
      isCustomizeGradientMouseDown(e) ||
      this.editorContext.navigateMode$.value;
  }

  onClick(event: MouseEvent) {
    if (!isViewLayerClick(event) && !isViewViewClick(event) && !this.interactionMouseDown) {
      this.editorContext.resetCustomizingLayers();
      this.editorContext.resetCustomizeView();
    }
  }

  onMultipleLayersResizeStarted() {
    this.originalMultipleLayers = cloneDeep(this.editorContext.customizingLayers$.value);
    this.originalMultipleLayersFrame = this.multipleLayers.frame$.value;

    this.editorContext.resizingLayer$.next(this.originalMultipleLayers[0]);
  }

  onMultipleLayersResizeFinished() {
    this.originalMultipleLayers = undefined;
    this.originalMultipleLayersFrame = undefined;

    this.editorContext.resizingLayer$.next(undefined);
  }

  onMultipleLayersResize(frame: Partial<Frame>) {
    this.multipleLayers.patchFrame(frame, {
      originalFrame: this.originalMultipleLayersFrame,
      originalLayers: this.originalMultipleLayers
    });
  }
}
