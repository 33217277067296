import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { MarginControl, ScannerElementItem } from '@modules/customize';
import { ElementConfigurationService } from '@modules/customize-configuration';
import { Input } from '@modules/fields';
import { FieldInputControl } from '@modules/parameters';
import { controlValid } from '@shared';

export function validateActions(): AsyncValidatorFn {
  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    const parent = control.parent as CustomizeBarScannerEditForm;

    if (!parent) {
      return of(null);
    }

    if (!control.value || !control.value.length) {
      return of(null);
    }

    return combineLatest(control.value.map(item => parent.elementConfigurationService.isActionConfigured(item))).pipe(
      map(result => {
        if (result.some(configured => !configured)) {
          return { required: true };
        }
      })
    );
  };
}

@Injectable()
export class CustomizeBarScannerEditForm extends FormGroup {
  element: ScannerElementItem;

  controls: {
    scanning_initial: FormControl;
    ignore_duplicate_scans: FormControl;
    scan_confirm: FormControl;
    on_scan_actions: FormControl;
    visible_input: FieldInputControl;
    card_wrap: FormControl;
    margin: MarginControl;
  };

  constructor(public elementConfigurationService: ElementConfigurationService) {
    super({
      scanning_initial: new FormControl(false),
      ignore_duplicate_scans: new FormControl(true),
      scan_confirm: new FormControl(false),
      on_scan_actions: new FormControl([], undefined, validateActions()),
      visible_input: new FieldInputControl({ path: ['value'] }),
      card_wrap: new FormControl(true),
      margin: new MarginControl()
    });
  }

  init(element: ScannerElementItem, firstInit = false) {
    this.element = element;

    const value = {
      scanning_initial: element.scanningInitial,
      ignore_duplicate_scans: element.ignoreDuplicateScans,
      scan_confirm: element.scanConfirm,
      on_scan_actions: element.onScanActions,
      visible_input: element.visibleInput ? element.visibleInput.serializeWithoutPath() : {},
      card_wrap: element.cardWrap,
      margin: element.margin
    };

    this.patchValue(value, { emitEvent: false });

    if (!firstInit) {
      this.markAsDirty();
    }
  }

  isConfigured(instance: ScannerElementItem): boolean {
    return this.elementConfigurationService.isScannerConfigured(instance);
  }

  controlsValid$(controls: AbstractControl[]): Observable<boolean> {
    if (!controls.length) {
      return of(true);
    }

    return combineLatest(controls.map(control => controlValid(control))).pipe(
      map(result => result.every(item => item))
      // debounceTime(60) TODO: Too long wait with debounceTime
    );
  }

  actionsValid$(): Observable<boolean> {
    return this.controlsValid$([this.controls.on_scan_actions]);
  }

  submit(): ScannerElementItem {
    const instance = cloneDeep(this.element) as ScannerElementItem;

    instance.scanningInitial = this.controls.scanning_initial.value;
    instance.ignoreDuplicateScans = this.controls.ignore_duplicate_scans.value;
    instance.scanConfirm = this.controls.scan_confirm.value;
    instance.onScanActions = this.controls.on_scan_actions.value;
    instance.cardWrap = this.controls.card_wrap.value;
    instance.margin = this.controls.margin.value;

    if (this.controls.visible_input.value) {
      instance.visibleInput = new Input().deserialize(this.controls.visible_input.value);
    } else {
      instance.visibleInput = undefined;
    }

    return instance;
  }
}
