import uniqWith from 'lodash/uniqWith';
import * as moment from 'moment';

import { ascComparator, getCircleIndex, getTimezoneOffset, isSet } from '@shared';

import { DataGroup } from '../data/data-group';
import { Dataset, datasetGroupDateLookups, DatasetGroupLookup } from '../data/dataset';
import { groupDatasetByLookup, parseDate } from './date';

export function cleanDatasetDataGroup(group: any, groupLookup?: DatasetGroupLookup): any {
  const isDateLookup = datasetGroupDateLookups.includes(groupLookup);

  if (isDateLookup || groupLookup == DatasetGroupLookup.Auto) {
    const groupDate = parseDate(group);

    if (groupDate.isValid()) {
      return groupDate.clone().utcOffset(getTimezoneOffset());
    } else if (!groupDate.isValid() && isDateLookup) {
      return;
    }
  }

  if (group === null) {
    return 'null';
  } else if (group === undefined) {
    return 'undefined';
  } else if (group === '') {
    return 'empty';
  }

  if (typeof group == 'number') {
    return group;
  }

  return String(group);
}

export function cleanDatasetData(
  data: DataGroup<any, any, any, any>[],
  groupLookup?: DatasetGroupLookup
): DataGroup<number, string | moment.Moment>[] {
  return data
    .map(item => {
      const valueNumber = parseFloat(item.value);

      if (isNaN(valueNumber)) {
        return;
      }

      const result = new DataGroup();

      result.group = cleanDatasetDataGroup(item.group, groupLookup);
      result.group2 = cleanDatasetDataGroup(item.group2, groupLookup);

      if (!result.group) {
        return;
      }

      result.value = item.value;
      result.color = item.color;
      result.value = valueNumber;

      return result;
    })
    .filter(item => item !== undefined);
}

export function sortDatasetDataComparator(lhs, rhs): number {
  if (lhs instanceof moment && rhs instanceof moment) {
    return lhs.valueOf() - rhs.valueOf();
  } else if (lhs < rhs) {
    return -1;
  } else if (lhs > rhs) {
    return 1;
  } else {
    return 0;
  }
}

export function sortDatasetData(data: DataGroup<any, any, any, any>[]): DataGroup<any, any, any, any>[] {
  return data.sort((lhs, rhs) => sortDatasetDataComparator(lhs.group, rhs.group));
}

export function prepareDataset(
  dataset: Dataset,
  options: { cumulative?: boolean } = {}
): Dataset<number, string | moment.Moment> {
  let groupLookup = dataset.groupLookup;
  let data = cleanDatasetData(dataset.dataset, groupLookup);

  if (
    dataset.groupLookup == DatasetGroupLookup.Auto &&
    data.length &&
    data.every(item => item.group instanceof moment)
  ) {
    groupLookup = DatasetGroupLookup.DateDay;
  }

  data = sortDatasetData(data);

  if (datasetGroupDateLookups.includes(groupLookup)) {
    data = groupDatasetByLookup(data as DataGroup<number, moment.Moment>[], groupLookup, dataset.valueFunc);
  }

  if (options.cumulative) {
    data = data.map((item, i) => {
      const prevItem = data[i - 1];
      item.value += prevItem ? prevItem.value : 0;
      return item;
    });
  }

  return {
    name: dataset.name,
    color: dataset.color,
    format: dataset.format,
    groupLookup: groupLookup,
    dataset: data
  };
}

export function getDatasetsGroupLookup(datasets: Dataset[]): DatasetGroupLookup {
  return datasets.length ? datasets[0].groupLookup : undefined;
}

export function getDatasetsUniqueGroups<T>(datasets: Dataset<any, T>[]): T[] {
  return uniqWith(
    datasets.reduce((acc, dataset) => {
      acc.push(...dataset.dataset.map(item => item.group));
      return acc;
    }, []),
    (lhs, rhs) => groupEquals(lhs, rhs)
  ).sort(sortDatasetDataComparator);
}

export function getDatasetsEffectiveColors(
  datasetColors: string[],
  colors: string[],
  ignoreOverrideIndex?: number
): string[] {
  const unusedColors = colors.filter(color => datasetColors.every(datasetColor => datasetColor != color));
  return datasetColors.map((datasetColor, i) => {
    if (isSet(datasetColor) && (ignoreOverrideIndex === undefined || i !== ignoreOverrideIndex)) {
      return datasetColor;
    } else {
      return getCircleIndex(unusedColors, i);
    }
  });
}

export function applyDatasetsDefaultColors(datasets: Dataset[], colors: string[]) {
  getDatasetsEffectiveColors(
    datasets.map(item => item.color),
    colors
  ).forEach((color, i) => {
    datasets[i].color = color;
  });
}

export function sortDatasetsByValue(datasets: Dataset<number, string | moment.Moment>[]) {
  datasets.forEach(dataset => {
    dataset.dataset = dataset.dataset.sort((lhs, rhs) => ascComparator(lhs.value, rhs.value) * -1);
  });
}

export function syncSortedDatasetsGroups(
  datasets: Dataset<number, string | moment.Moment>[],
  dataGroups: (string | moment.Moment)[]
) {
  dataGroups.forEach((group, i) => {
    datasets.forEach(dataset => {
      if (!dataset.dataset[i] || !groupEquals(dataset.dataset[i].group, group)) {
        const emptyGroup = new DataGroup();
        emptyGroup.group = group;
        emptyGroup.value = 0;
        dataset.dataset.splice(i, 0, emptyGroup);
      }
    });
  });
}

export function normalizeDatasetsGroupValues(
  datasets: Dataset<number, string | moment.Moment>[],
  dataGroups: (string | moment.Moment)[]
) {
  dataGroups.forEach((group, i) => {
    const total = datasets.reduce((acc, dataset) => {
      return acc + dataset.dataset[i].value;
    }, 0);

    datasets.forEach(dataset => {
      dataset.dataset[i].value =
        total !== 0 ? dataset.dataset[i].value / total : dataset.dataset[i].value / datasets.length;
    });
  });
}

export interface DataTotalItem<V = any, G1 = any, G2 = any, G3 = any> {
  dataset: Dataset<V, G1, G2, G3>;
  datasetIndex: number;
  item: DataGroup<V, G1, G2, G3>;
  itemIndex: number;
}

export function getDatasetsGroups<V = any, G1 = any, G2 = any, G3 = any>(
  datasets: Dataset<V, G1, G2, G3>[]
): DataTotalItem<V, G1, G2, G3>[] {
  return datasets.reduce((acc, dataset, datasetIndex) => {
    acc.push(
      ...dataset.dataset.map((item, itemIndex) => {
        return {
          dataset: dataset,
          datasetIndex: datasetIndex,
          item: item,
          itemIndex: itemIndex
        };
      })
    );
    return acc;
  }, []);
}

export function groupEquals(lhs: number | string | moment.Moment, rhs: number | string | moment.Moment) {
  if (lhs instanceof moment && rhs instanceof moment) {
    return (lhs as moment.Moment).isSame(rhs);
  } else {
    return lhs == rhs;
  }
}
