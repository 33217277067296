import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, Optional } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { switchMap } from 'rxjs/operators';

import { NotificationService } from '@common/notifications';
import { BasePopupComponent } from '@common/popups';

import { IntercomService, UniversalAnalyticsService } from '@modules/analytics';
import { ServerRequestError } from '@modules/api';
import { BooleanFieldStyle } from '@modules/field-components';
import { ModelDescriptionService } from '@modules/model-queries';
import { ProjectTokenService, Resource, ResourceName } from '@modules/projects';
import { ResourceParamsResult } from '@modules/resources';
import { errorToString, forceObservable, generateUUID, isSet } from '@shared';

import { registerResourceSettingsComponent } from '../../../data/resource-settings-components';
import { BaseResourceSettingsComponent } from '../base-resource-settings/base-resource-settings.component';
import { DatabaseResourceParams } from '../databases-resource-settings/databases-resource-settings.form';
import { DjangoResourceSettingsForm } from './django-resource-settings.form';

@Component({
  selector: 'app-django-resource-settings',
  templateUrl: './django-resource-settings.component.html',
  providers: [DjangoResourceSettingsForm],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DjangoResourceSettingsComponent extends BaseResourceSettingsComponent implements OnInit, OnDestroy {
  booleanFieldStyle = BooleanFieldStyle;
  installToken: string;
  testConnectionLoading = false;

  constructor(
    private modelDescriptionService: ModelDescriptionService,
    private notificationService: NotificationService,
    public form: DjangoResourceSettingsForm,
    @Optional() popupComponent: BasePopupComponent,
    projectTokenService: ProjectTokenService,
    intercomService: IntercomService,
    analyticsService: UniversalAnalyticsService,
    cd: ChangeDetectorRef
  ) {
    super(form, popupComponent, projectTokenService, intercomService, analyticsService, cd);
  }

  ngOnInit() {
    super.ngOnInit();
    const params = this.params as DatabaseResourceParams;
    this.installToken = this.form.form.value['token'] || params.defaultToken || generateUUID();
  }

  isParamsStep() {
    return isSet(this.params['defaultToken']) || isSet(this.params['defaultApiBaseUrl']);
  }

  testConnection() {
    this.testConnectionLoading = true;
    this.cd.markForCheck();

    forceObservable<ResourceParamsResult>(this.form.getParams())
      .pipe(
        switchMap(result => {
          const instance = this.resource ? cloneDeep(this.resource) : new Resource();

          instance.type = this.typeItem.resourceType;
          instance.typeItem = this.typeItem;
          instance.token = result.resourceToken;
          instance.params = result.resourceParams;

          return this.modelDescriptionService.getFromResource(instance);
        }),
        untilDestroyed(this)
      )
      .subscribe(
        () => {
          this.testConnectionLoading = false;
          this.cd.markForCheck();

          this.notificationService.success('Connected successfully!', 'Database settings are correct');
        },
        error => {
          this.testConnectionLoading = false;
          this.cd.markForCheck();

          if (error instanceof ServerRequestError && error.errors.length) {
            this.notificationService.error('Unable to connect', error.errors[0]);
          } else {
            this.notificationService.error('Unable to connect', errorToString(error));
          }
        }
      );
  }
}

registerResourceSettingsComponent(ResourceName.Django, DjangoResourceSettingsComponent);
