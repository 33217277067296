import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from '@angular/core';
import clamp from 'lodash/clamp';

import { TypedChanges } from '@shared';

@Component({
  selector: 'app-bar-progress',
  templateUrl: './bar-progress.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BarProgressComponent implements OnInit, OnChanges {
  @Input() label: string;
  @Input() labelWrap = false;
  @Input() progress = 0;
  @Input() processed: string | number;
  @Input() total: string | number;
  @Input() errors = 0;
  @Input() error: string;
  @Input() showPercentage = true;
  @Input() secondary = false;
  @Input() contrast = false;
  @Input() border = false;
  @Input() accentColor: string;

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: TypedChanges<BarProgressComponent>): void {
    this.progress = clamp(this.progress, 0, 1);
  }

  get progressRound() {
    return Math.round(this.progress * 100);
  }
}
