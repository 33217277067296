import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  AggregateDisplayField,
  ComputedDisplayField,
  CustomViewDisplayField,
  DisplayField,
  DisplayFieldType,
  FieldDescription,
  FieldType,
  getFieldDescriptionByType,
  Input,
  LookupDisplayField
} from '@modules/fields';
import { FieldInputControl } from '@modules/parameters';
import { controlValue } from '@shared';

export interface DisplayFieldControls {
  name: FormControl;
  verboseName: FormControl;
  description: FormControl;
  field: FormControl;
  params: FormControl;
  visible: FormControl;
  valueInput: FieldInputControl;
  lookupPath: FormControl;
  aggregatePath: FormControl;
  aggregateFunc: FormControl;
  aggregateColumn: FormControl;
  customViewUniqueName: FormControl;
  customViewMappings: FormControl;
  [key: string]: AbstractControl;
}

export class DisplayFieldControl extends FormGroup {
  instance: DisplayField;
  type: DisplayFieldType = DisplayFieldType.Base;
  controls: DisplayFieldControls;

  constructor() {
    super({
      name: new FormControl(''),
      verboseName: new FormControl(''),
      description: new FormControl(''),
      field: new FormControl(FieldType.Text),
      params: new FormControl({}),
      visible: new FormControl(true),
      valueInput: new FieldInputControl({ path: ['value'] }),
      lookupPath: new FormControl(null),
      aggregatePath: new FormControl(null),
      aggregateFunc: new FormControl(null),
      aggregateColumn: new FormControl(null),
      customViewUniqueName: new FormControl(null),
      customViewMappings: new FormControl([])
    });
  }

  deserialize(item: DisplayField) {
    this.instance = item;
    this.type = item ? item.type : DisplayFieldType.Base;

    this.patchValue({
      name: item.name,
      verboseName: item.verboseName,
      description: item.description,
      field: item.field,
      params: item.params,
      visible: item.visible,
      ...(item instanceof ComputedDisplayField
        ? {
            valueInput: item.valueInput ? item.valueInput.serializeWithoutPath() : {}
          }
        : undefined),
      ...(item instanceof LookupDisplayField
        ? {
            lookupPath: item.path
          }
        : undefined),
      ...(item instanceof AggregateDisplayField
        ? {
            aggregatePath: item.path,
            aggregateFunc: item.func,
            aggregateColumn: item.column
          }
        : undefined),
      ...(item instanceof CustomViewDisplayField
        ? {
            customViewUniqueName: item.customView,
            customViewMappings: item.customViewMappings
          }
        : undefined)
    });
    this.markAsPristine();
  }

  createInstance() {
    if (this.type == DisplayFieldType.Computed) {
      return new ComputedDisplayField();
    } else if (this.type == DisplayFieldType.CustomView) {
      return new CustomViewDisplayField();
    } else if (this.type == DisplayFieldType.Lookup) {
      return new LookupDisplayField();
    } else if (this.type == DisplayFieldType.Aggregate) {
      return new AggregateDisplayField();
    } else {
      return new DisplayField();
    }
  }

  serialize(reuseInstance = true): DisplayField {
    const result = (reuseInstance ? this.instance : undefined) || this.createInstance();

    result.name = this.controls.name.value;
    result.verboseName = this.controls.verboseName.value;
    result.description = this.controls.description.value;
    result.field = this.controls.field.value;
    result.params = this.controls.params.value;
    result.visible = this.controls.visible.value;
    result.updateFieldDescription();

    if (result instanceof ComputedDisplayField) {
      result.valueInput = this.controls.valueInput.value
        ? new Input().deserialize(this.controls.valueInput.value)
        : undefined;
    } else if (result instanceof LookupDisplayField) {
      result.path = this.controls.lookupPath.value;
    } else if (result instanceof AggregateDisplayField) {
      result.path = this.controls.aggregatePath.value;
      result.func = this.controls.aggregateFunc.value;
      result.column = this.controls.aggregateColumn.value;
    } else if (result instanceof CustomViewDisplayField) {
      result.customView = this.controls.customViewUniqueName.value;
      result.customViewMappings = this.controls.customViewMappings.value;
    }

    return result;
  }

  toggleVisible() {
    this.controls.visible.patchValue(!this.controls.visible.value);
  }

  getFieldDescription(): FieldDescription {
    return getFieldDescriptionByType(this.controls.field.value);
  }

  getIcon(): string {
    return this.getFieldDescription().icon;
  }

  getIcon$(): Observable<string> {
    return controlValue<FieldType>(this.controls.field).pipe(
      map(field => {
        if (this.instance instanceof ComputedDisplayField) {
          return 'function';
        } else if (this.instance instanceof LookupDisplayField) {
          return 'lookup';
        } else if (this.instance instanceof AggregateDisplayField) {
          return 'spiral';
        } else {
          return getFieldDescriptionByType(field).icon;
        }
      })
    );
  }
}
