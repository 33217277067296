import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormControl } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import uniq from 'lodash/uniq';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { of } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';

import { DialogService } from '@common/dialogs';
import { NotificationService } from '@common/notifications';
import { AdminMode, ROUTE_ADMIN_MODE } from '@modules/admin-mode';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ServerRequestError } from '@modules/api';
import {
  CurrentEnvironmentStore,
  CurrentProjectStore,
  ProjectGroup,
  ProjectUser,
  ProjectUserService
} from '@modules/projects';
import { CurrentUserStore } from '@modules/users';
import { errorToString } from '@shared';

@Component({
  selector: 'app-project-users-item, [app-project-users-item]',
  templateUrl: './project-users-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectUsersItemComponent implements OnInit, OnDestroy, OnChanges {
  @Input() item: ProjectUser;
  @Input() group = true;
  @Output() deleted = new EventEmitter<void>();

  selectedGroup = new FormControl();
  deleteLoading = false;

  constructor(
    @Inject(ROUTE_ADMIN_MODE) private mode: AdminMode,
    public currentProjectStore: CurrentProjectStore,
    public currentEnvironmentStore: CurrentEnvironmentStore,
    public currentUserStore: CurrentUserStore,
    private projectUserService: ProjectUserService,
    private dialogService: DialogService,
    private notificationService: NotificationService,
    private analyticsService: UniversalAnalyticsService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.selectedGroup.valueChanges.pipe(untilDestroyed(this)).subscribe(group => {
      if (group) {
        this.update(group);
      }
    });
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    this.selectedGroup.setValue(this.item ? this.item.group : undefined);
  }

  delete(user: ProjectUser) {
    this.dialogService
      .warning({
        title: 'Deleting',
        description: 'Are you sure want to delete this user from App?'
      })
      .pipe(
        filter(result => result == true),
        switchMap(() => {
          this.deleteLoading = true;
          this.cd.markForCheck();
          return this.projectUserService.delete(
            this.currentProjectStore.instance.uniqueName,
            this.currentEnvironmentStore.instance.uniqueName,
            user
          );
        }),
        untilDestroyed(this)
      )
      .subscribe(
        () => {
          this.notificationService.success('Deleted', 'User was successfully deleted from App');
          this.deleteLoading = false;
          this.cd.markForCheck();
          this.deleted.next();

          this.analyticsService.sendSimpleEvent(AnalyticsEvent.Share.MemberDeleted, {
            Email: user.getEmail(),
            Mode: this.mode,
            Source: 'project_users'
          });

          this.projectUserService.getProject(this.currentProjectStore.instance.uniqueName).subscribe(projectUsers => {
            this.analyticsService.sendSimpleEvent(AnalyticsEvent.Project.UsersChanged, {
              ProjectID: this.currentProjectStore.instance.uniqueName,
              UsersCount: uniq(projectUsers.map(item => item.getEmail())).length + 1
            });
          });
        },
        () => {
          this.deleteLoading = false;
          this.cd.markForCheck();
        }
      );
  }

  update(group: ProjectGroup) {
    if (group.uid == this.item.group.uid) {
      return;
    }

    const item = cloneDeep(this.item) as ProjectUser;

    item.group.uid = group.uid;

    this.projectUserService
      .update(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, item)
      .pipe(
        tap(result => {
          if (result.uid == this.currentEnvironmentStore.instance.user.uid) {
            this.currentEnvironmentStore.updateUser(result);
          }
        }),
        untilDestroyed(this)
      )
      .subscribe(
        () => {
          this.item = item;
          this.selectedGroup.setValue(group);
          this.cd.markForCheck();

          // this.notificationService.success('Saved', 'User was successfully updated');
        },
        error => {
          if (error instanceof ServerRequestError && error.errors.length) {
            this.notificationService.error('Update Failed', error.errors[0]);
          } else {
            this.notificationService.error('Update Failed', errorToString(error));
          }
        }
      );
  }
}
