import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import range from 'lodash/range';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

import { NotificationService } from '@common/notifications';
import { BasePopupComponent } from '@common/popups';
import { Resource } from '@modules/projects';
import { Storage } from '@modules/storages';
import { StorageService } from '@modules/storages-queries';
import { getFilenameWithExtension } from '@shared';

export interface StorageRemoveStateFile {
  name: string;
  path: string;
  result?: boolean;
  error?: string;
}

export interface StorageRemoveState {
  files: StorageRemoveStateFile[];
  processed: number;
  succeeded: number;
  failed: number;
  cancelled?: boolean;
}

@Component({
  selector: 'app-storage-remove-popup',
  templateUrl: './storage-remove-popup.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StorageRemovePopupComponent implements OnInit, OnDestroy {
  @Input() paths: string[] = [];
  @Input() resource: Resource;
  @Input() storage: Storage;
  @Output() finished = new EventEmitter<StorageRemoveState>();
  @Output() cancelled = new EventEmitter<StorageRemoveState>();

  threads = 1;
  state: StorageRemoveState;
  removeLoading = false;

  constructor(
    private popupComponent: BasePopupComponent,
    private storageService: StorageService,
    private notificationService: NotificationService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.upload();
  }

  ngOnDestroy(): void {}

  upload() {
    const files = this.paths.map(item => {
      return {
        name: getFilenameWithExtension(item),
        path: item
      };
    });

    this.state = {
      files: files,
      processed: 0,
      succeeded: 0,
      failed: 0
    };
    this.removeLoading = true;
    this.cd.markForCheck();

    const upload: StorageRemoveStateFile[] = [...files];
    const uploadThreads = range(this.threads).map(() => this.startRemove(upload));

    combineLatest(uploadThreads)
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          this.removeLoading = false;
          this.cd.markForCheck();

          if (this.state.succeeded == this.state.processed) {
            this.finish();
          }
        },
        () => {
          this.removeLoading = false;
          this.cd.markForCheck();
        }
      );
  }

  startRemove(remove: StorageRemoveStateFile[]): Observable<any> {
    const file = remove.splice(0, 1)[0];
    if (!file) {
      return of(undefined);
    }

    return this.storageService
      .deleteStorageObject(this.resource, this.storage, this.storage.removeQuery, file.path)
      .pipe(
        tap(() => {
          file.result = true;
          ++this.state.processed;
          ++this.state.succeeded;
          this.cd.markForCheck();
        }),
        catchError(error => {
          file.error = String(error);
          ++this.state.processed;
          ++this.state.failed;
          this.cd.markForCheck();

          return of(undefined);
        }),
        switchMap(() => this.startRemove(remove))
      );
  }

  finish() {
    this.finished.emit(this.state);
    this.close();
  }

  cancel() {
    this.cancelled.emit(this.state);
    this.close();
  }

  close() {
    this.popupComponent.close();
  }
}
