import { Injectable, Injector } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import clone from 'lodash/clone';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { TintStyle } from '@modules/actions';
import { applyActionElementStyles, FormSubmitElementItem, getActionElementStyles } from '@modules/customize';
import { ElementConfigurationService } from '@modules/customize-configuration';
import { ActionTextStyleGlobalParams } from '@modules/customize-shared';
import { Input, Input as FieldInput, InputValueType } from '@modules/fields';
import { FieldInputControl } from '@modules/parameters';
import { controlValue } from '@shared';

import { ActionElementStylesControl } from '../styles-action-element-edit/action-element-styles.control';

const defaultVerboseName = {
  path: ['value'],
  value_type: InputValueType.StaticValue,
  static_value: 'Button'
};

@Injectable()
export class CustomizeBarFormSubmitEditForm extends FormGroup {
  controls: {
    icon: FormControl;
    style: FormControl;
    tint: FormControl;
    verbose_name: FieldInputControl;
    disabled_input: FieldInputControl;
    visible_input: FieldInputControl;
    card_wrap: FormControl;

    element_styles: ActionElementStylesControl;
  };

  element: FormSubmitElementItem;

  styleOptions = [
    {
      value: TintStyle.Primary,
      image: 'button-primary'
    },
    {
      value: TintStyle.Default,
      image: 'button-default'
    },
    {
      value: TintStyle.Transparent,
      image: 'button-transparent'
    }
  ];

  constructor(private elementConfigurationService: ElementConfigurationService, private injector: Injector) {
    super(
      (() => {
        const controls = {
          icon: new FormControl(null),
          style: new FormControl(TintStyle.Primary),
          tint: new FormControl(null),
          verbose_name: new FieldInputControl(defaultVerboseName),
          disabled_input: new FieldInputControl({ path: ['value'] }),
          visible_input: new FieldInputControl({ path: ['value'] }),
          card_wrap: new FormControl(true)
        };

        controls['element_styles'] = new ActionElementStylesControl(injector, {
          textStyleGlobalParams: controlValue(controls.style).pipe(
            map(style => {
              return { tint: style } as ActionTextStyleGlobalParams;
            })
          )
        });

        return controls;
      })()
    );
  }

  init(element: FormSubmitElementItem, firstInit = false) {
    this.element = element;

    const value = {
      icon: element.icon,
      style: element.style,
      tint: element.tint,
      verbose_name: element.verboseNameInput ? element.verboseNameInput.serializeWithoutPath() : defaultVerboseName,
      disabled_input: element.disabledInput ? element.disabledInput.serializeWithoutPath() : {},
      visible_input: element.visibleInput ? element.visibleInput.serializeWithoutPath() : {},
      card_wrap: element.cardWrap,
      margin: element.margin
    };

    this.patchValue(value, { emitEvent: false });

    const elementStyles = getActionElementStyles(element);
    this.controls.element_styles.deserialize(elementStyles);

    if (!firstInit) {
      this.markAsDirty();
    }
  }

  isConfigured(instance: FormSubmitElementItem): Observable<boolean> {
    //   return this.elementConfigurationService.isActionDropdownConfigured(instance, { restrictDemo: true });
    return of(true);
  }

  submit(): FormSubmitElementItem {
    const instance = clone(this.element);

    instance.icon = this.controls.icon.value;
    instance.style = this.controls.style.value;
    instance.tint = this.controls.tint.value;
    instance.verboseNameInput = this.controls.verbose_name.value
      ? new FieldInput().deserialize(this.controls.verbose_name.value)
      : undefined;

    if (this.controls.disabled_input.value) {
      instance.disabledInput = new Input().deserialize(this.controls.disabled_input.value);
    } else {
      instance.disabledInput = undefined;
    }

    if (this.controls.visible_input.value) {
      instance.visibleInput = new Input().deserialize(this.controls.visible_input.value);
    } else {
      instance.visibleInput = undefined;
    }

    instance.cardWrap = this.controls.card_wrap.value;

    const elementStyles = this.controls.element_styles.serialize();
    applyActionElementStyles(instance, elementStyles);

    return instance;
  }
}
