var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { switchMap } from 'rxjs/operators';
import { NotificationService } from '@common/notifications';
import { BasePopupComponent } from '@common/popups';
import { IntercomService, UniversalAnalyticsService } from '@modules/analytics';
import { ServerRequestError } from '@modules/api';
import { BooleanFieldStyle } from '@modules/field-components';
import { ModelDescriptionService } from '@modules/model-queries';
import { ProjectTokenService, Resource, ResourceName } from '@modules/projects';
import { errorToString, forceObservable, generateUUID, isSet } from '@shared';
import { registerResourceSettingsComponent } from '../../../data/resource-settings-components';
import { BaseResourceSettingsComponent } from '../base-resource-settings/base-resource-settings.component';
import { DjangoResourceSettingsForm } from './django-resource-settings.form';
var DjangoResourceSettingsComponent = /** @class */ (function (_super) {
    __extends(DjangoResourceSettingsComponent, _super);
    function DjangoResourceSettingsComponent(modelDescriptionService, notificationService, form, popupComponent, projectTokenService, intercomService, analyticsService, cd) {
        var _this = _super.call(this, form, popupComponent, projectTokenService, intercomService, analyticsService, cd) || this;
        _this.modelDescriptionService = modelDescriptionService;
        _this.notificationService = notificationService;
        _this.form = form;
        _this.booleanFieldStyle = BooleanFieldStyle;
        _this.testConnectionLoading = false;
        return _this;
    }
    DjangoResourceSettingsComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        var params = this.params;
        this.installToken = this.form.form.value['token'] || params.defaultToken || generateUUID();
    };
    DjangoResourceSettingsComponent.prototype.isParamsStep = function () {
        return isSet(this.params['defaultToken']) || isSet(this.params['defaultApiBaseUrl']);
    };
    DjangoResourceSettingsComponent.prototype.testConnection = function () {
        var _this = this;
        this.testConnectionLoading = true;
        this.cd.markForCheck();
        forceObservable(this.form.getParams())
            .pipe(switchMap(function (result) {
            var instance = _this.resource ? cloneDeep(_this.resource) : new Resource();
            instance.type = _this.typeItem.resourceType;
            instance.typeItem = _this.typeItem;
            instance.token = result.resourceToken;
            instance.params = result.resourceParams;
            return _this.modelDescriptionService.getFromResource(instance);
        }), untilDestroyed(this))
            .subscribe(function () {
            _this.testConnectionLoading = false;
            _this.cd.markForCheck();
            _this.notificationService.success('Connected successfully!', 'Database settings are correct');
        }, function (error) {
            _this.testConnectionLoading = false;
            _this.cd.markForCheck();
            if (error instanceof ServerRequestError && error.errors.length) {
                _this.notificationService.error('Unable to connect', error.errors[0]);
            }
            else {
                _this.notificationService.error('Unable to connect', errorToString(error));
            }
        });
    };
    return DjangoResourceSettingsComponent;
}(BaseResourceSettingsComponent));
export { DjangoResourceSettingsComponent };
registerResourceSettingsComponent(ResourceName.Django, DjangoResourceSettingsComponent);
