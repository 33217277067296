<div class="collaboration-messages">
  <div class="collaboration__header">
    <h2 class="collaboration__title">Comments</h2>
    <p class="collaboration__description">
      See what your teammates are doing and investigate changes in your workspace.
    </p>
    <div class="collaboration__filter">
      <span class="collaboration__filter-title">Filter by</span>
      <div [formGroup]="form" class="collaboration__form">
        <div class="collaboration__form-input-wrap">
          <gxs-select
            [options]="{
              theme: 'jet',
              search: true,
              searchMinimumLength: 1,
              searchDebounce: 0,
              labels: {} | localizeSelect,
              classes: ['input_fill']
            }"
            formControlName="read"
          >
            <gxs-option *ngFor="let item of readOptions" [name]="item.name" [value]="item.value"></gxs-option>
          </gxs-select>
        </div>
        <div class="collaboration__form-date-range">
          <div class="input-icon-wrapper">
            <input
              #dp_gte
              cdkOverlayOrigin
              #dp_gte_origin="cdkOverlayOrigin"
              [appDateValue]="{ format: formatDate }"
              autocomplete="off"
              class="input input_icon-right"
              formControlName="date_add_gte"
              style="width: 150px;"
              type="text"
            />
            <span class="input-icon input-icon_right icon-calendar"></span>
            <gxd-datepicker
              [input]="dp_gte"
              [origin]="dp_gte_origin"
              [options]="{ theme: 'jet', format: formatDate, time: false, locale: {} | localizeDatepicker }"
            ></gxd-datepicker>
          </div>

          <div class="collaboration__form-date-range-separator"></div>

          <div class="input-icon-wrapper">
            <input
              style="width: 150px;"
              #dp_lte
              cdkOverlayOrigin
              #dp_lte_origin="cdkOverlayOrigin"
              [appDateValue]="{ format: formatDate }"
              autocomplete="off"
              class="input input_icon-right"
              formControlName="date_add_lte"
              type="text"
            />
            <span class="input-icon input-icon_right icon-calendar"></span>
            <gxd-datepicker
              [input]="dp_lte"
              [origin]="dp_lte_origin"
              [options]="{ theme: 'jet', format: formatDate, time: false, locale: {} | localizeDatepicker }"
            ></gxd-datepicker>
          </div>
        </div>
      </div>
      <input
        type="button"
        class="input input_button collaboration__read-all"
        value="Mark All As Read"
        (click)="readAll()"
      />
    </div>
  </div>

  <div class="collaboration-messages__list">
    <ul class="card-list">
      <li
        *ngFor="let item of feedItems$ | async; trackBy: trackByFn; let i = index"
        (click)="activeToggle(i, item)"
        class="card-list__item"
        [class.card-list__item_active]="i === selectedItemIndex"
        [class.card-list__item_disabled]="item.read"
        app-collaboration-messages-item
        #feedItem
        [item]="item"
      ></li>

      <ng-container *ngIf="(feedItemsLoading$ | async) && (feedItems$ | async) !== undefined">
        <li *ngFor="let item of [1, 2, 3]" class="card-list__item">
          <div class="card-row">
            <div class="card-row__inner">
              <div class="card-row__col collaboration-messages__list-col-avatar">
                <div class="card-row__col-inner"></div>
              </div>

              <div class="card-row__col collaboration-messages__list-col-message">
                <div class="card-row__col-inner">
                  <div class="collaboration-messages__list-message-wrap">
                    <span class="collaboration-messages__list-name">
                      <span [class.loading-animation]="true">
                        <span class="stub-text">Some Name</span>
                      </span>
                      <span class="collaboration-messages__list-dot"></span>
                      <span class="collaboration-messages__list-date">
                        <span [class.loading-animation]="true">
                          <span class="stub-text">aaa aaaaaaaa</span>
                        </span>
                      </span>
                    </span>
                    <p class="collaboration-messages__list-message">
                      <span [class.loading-animation]="true">
                        <span class="stub-text"
                          >Aaaaaaaaaaaaaa <br />
                          aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa <br />
                          aaaaaaaaaaaaaaa</span
                        >
                      </span>
                    </p>
                  </div>
                </div>
              </div>

              <div class="card-row__col collaboration-messages__list-col-description">
                <div class="card-row__col-inner">
                  <p class="collaboration-messages__list-description">
                    <span [class.loading-animation]="true">
                      <span class="stub-text">Aaaaaaaaa aaaaaaaa</span>
                    </span>
                  </p>
                </div>
              </div>

              <div class="card-row__col collaboration-messages__list-col-counter">
                <div class="card-row__col-inner">
                  <span [class.loading-animation]="true">
                    <span class="stub-text">Aaaa</span>
                  </span>
                </div>
              </div>

              <div class="card-row__col collaboration-messages__list-col-menu">
                <div class="card-row__col-inner">
                  <span [class.loading-animation]="true">
                    <span class="stub-text">Aaaa</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ng-container>
    </ul>
  </div>
  <div *ngIf="(feedItems$ | async) === undefined" class="user-activities__list user-activities__list-stub">
    <div class="card-list__wrap">
      <ul class="card-list">
        <li *ngFor="let item of [1, 2, 3, 4, 5, 6, 7, 8]" class="card-list__item">
          <div class="card-row">
            <div class="card-row__inner">
              <div class="card-row__col collaboration-messages__list-col-avatar">
                <div class="card-row__col-inner"></div>
              </div>

              <div class="card-row__col collaboration-messages__list-col-message">
                <div class="card-row__col-inner">
                  <div class="collaboration-messages__list-message-wrap">
                    <span class="collaboration-messages__list-name">
                      <span [class.loading-animation]="true">
                        <span class="stub-text">Some Name</span>
                      </span>
                      <span class="collaboration-messages__list-dot"></span>
                      <span class="collaboration-messages__list-date">
                        <span [class.loading-animation]="true">
                          <span class="stub-text">aaa aaaaaaaa</span>
                        </span>
                      </span>
                    </span>
                    <p class="collaboration-messages__list-message">
                      <span [class.loading-animation]="true">
                        <span class="stub-text"
                          >Aaaaaaaaaaaaaa <br />
                          aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa <br />
                          aaaaaaaaaaaaaaa</span
                        >
                      </span>
                    </p>
                  </div>
                </div>
              </div>

              <div class="card-row__col collaboration-messages__list-col-description">
                <div class="card-row__col-inner">
                  <p class="collaboration-messages__list-description">
                    <span [class.loading-animation]="true">
                      <span class="stub-text">Aaaaaaaaa aaaaaaaa</span>
                    </span>
                  </p>
                </div>
              </div>

              <div class="card-row__col collaboration-messages__list-col-counter">
                <div class="card-row__col-inner">
                  <span [class.loading-animation]="true">
                    <span class="stub-text">Aaaa</span>
                  </span>
                </div>
              </div>

              <div class="card-row__col collaboration-messages__list-col-menu">
                <div class="card-row__col-inner">
                  <span [class.loading-animation]="true">
                    <span class="stub-text">Aaaa</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>

<div *ngIf="(feedItems$ | async)?.length === 0" class="table__empty">
  <app-error [title]="'Nothing found'">
    Unfortunately, no user activities found
  </app-error>
</div>
