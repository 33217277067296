import { FocusMonitor } from '@angular/cdk/a11y';
import { ElementRef, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
var FocusMonitorDirective = /** @class */ (function () {
    function FocusMonitorDirective(el, focusMonitor) {
        this.el = el;
        this.focusMonitor = focusMonitor;
        this._focused$ = new BehaviorSubject(false);
        this.focused$ = this._focused$.asObservable();
    }
    FocusMonitorDirective.prototype.ngOnInit = function () {
        var _this = this;
        this.focusMonitor
            .monitor(this.el.nativeElement)
            .pipe(map(function (origin) { return !!origin; }), untilDestroyed(this))
            .subscribe(function (focused) {
            _this._focused$.next(focused);
        });
    };
    FocusMonitorDirective.prototype.ngOnDestroy = function () { };
    Object.defineProperty(FocusMonitorDirective.prototype, "focused", {
        get: function () {
            return this._focused$.value;
        },
        enumerable: true,
        configurable: true
    });
    return FocusMonitorDirective;
}());
export { FocusMonitorDirective };
