import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, filter, switchMap } from 'rxjs/operators';

import { CustomView, CustomViewLoaderService, CustomViewService, CustomViewsStore } from '@modules/custom-views';
import { CustomizeService } from '@modules/customize';
import { ParameterField } from '@modules/fields';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { isSet, TypedChanges } from '@shared';

@Component({
  selector: 'app-custom-element-js',
  templateUrl: './custom-element-js.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomElementJsComponent implements OnInit, OnDestroy, OnChanges {
  @Input() customView: CustomView;
  @Input() parameters: ParameterField[] = [];
  @Input() params: Object = {};
  @Output() actionEmitted = new EventEmitter<{ name: string; data: any }>();

  customView$ = new BehaviorSubject<CustomView>(undefined);
  customElementBuildIdCheckHidden = false;

  constructor(
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    public customizeService: CustomizeService,
    private customViewService: CustomViewService,
    private customViewsStore: CustomViewsStore,
    public customViewLoaderService: CustomViewLoaderService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    if (this.customizeService.enabled) {
      this.customView$
        .pipe(
          distinctUntilChanged((lhs, rhs) => {
            const lhsUniqueName = lhs ? lhs.uniqueName : undefined;
            const rhsUniqueName = rhs ? rhs.uniqueName : undefined;
            return lhsUniqueName === rhsUniqueName;
          }),
          filter(customView => !!customView),
          switchMap(customView => {
            return this.customViewService.subscribeCustomViews(
              this.currentProjectStore.instance.uniqueName,
              this.currentEnvironmentStore.instance.uniqueName,
              customView.uniqueName
            );
          })
        )
        .pipe(untilDestroyed(this))
        .subscribe(customView => {
          const existing = this.customViewsStore.instance
            ? this.customViewsStore.instance.find(item => item.uniqueName == customView.uniqueName)
            : undefined;

          if (existing && existing.buildId != customView.buildId) {
            this.customViewsStore.updateItem(customView);
          }
        });
    }
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<CustomElementJsComponent>): void {
    if (changes.customView) {
      this.customView$.next(this.customView);
      this.loadFiles();
      this.customElementBuildIdCheckHidden = false;
      this.cd.markForCheck();
    }
  }

  loadFiles() {
    this.customViewLoaderService.load(this.customView).pipe(untilDestroyed(this)).subscribe();
  }

  hideCustomElementBuildIdCheck() {
    this.customElementBuildIdCheckHidden = true;
    this.cd.markForCheck();
  }
}
