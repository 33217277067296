import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { NotificationService } from '@common/notifications';
import { BasePopupComponent } from '@common/popups';
import { ActionDescription } from '@modules/actions';
import { errorToString } from '@shared';

import { ActionEditPopupForm } from './action-edit-popup.form';

@Component({
  selector: 'app-action-edit-popup',
  templateUrl: './action-edit-popup.component.html',
  providers: [ActionEditPopupForm],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionEditPopupComponent implements OnInit, OnDestroy {
  @Input() action: ActionDescription;

  submitLoading = false;

  constructor(
    public form: ActionEditPopupForm,
    private popupComponent: BasePopupComponent,
    private notificationService: NotificationService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.form.setInstance(this.action);
  }

  ngOnDestroy(): void {}

  submit() {
    this.submitLoading = true;
    this.cd.markForCheck();

    this.form
      .submit()
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          this.close();

          this.notificationService.success(
            'Saved',
            `Action <strong>${this.action.verboseName}</strong> was successfully updated`
          );
        },
        error => {
          this.notificationService.error('Error', errorToString(error));
          this.submitLoading = false;
          this.cd.markForCheck();
        }
      );
  }

  close() {
    this.popupComponent.close();
  }
}
