import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from '@angular/core';

import { defaultCustomViewHtml } from '@modules/custom-views';
import { QueryService } from '@modules/queries';
import { isSet, TypedChanges } from '@shared';

@Component({
  selector: 'app-custom-element-html',
  templateUrl: './custom-element-html.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomElementHtmlComponent implements OnInit, OnChanges {
  @Input() html = '';
  @Input() params: Object = {};

  htmlEffective = '';

  constructor(private queryService: QueryService) {}

  ngOnInit() {}

  ngOnChanges(changes: TypedChanges<CustomElementHtmlComponent>): void {
    if (changes.html || changes.params) {
      this.htmlEffective = this.getHtmlEffective(this.html, this.params);
    }
  }

  getHtmlEffective(html: string, params: Object) {
    if (!isSet(html)) {
      return defaultCustomViewHtml;
    }

    return this.queryService.applyTokens(html, {
      params: params
    });
  }
}
