import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { LocalStorage } from '@core';
import { ApiService } from '@modules/api';
import { isSet, SingletonStore } from '@shared';

import { User } from '../data/user';
import { UserService } from '../services/user/user.service';

@Injectable({
  providedIn: 'root'
})
export class CurrentUserStore extends SingletonStore<User> {
  private _original$ = new BehaviorSubject<User>(undefined);
  private _override$ = new BehaviorSubject<User>(undefined);

  constructor(private userService: UserService, private apiService: ApiService, private localStorage: LocalStorage) {
    super();
    this.get();
  }

  protected fetchUser(): Observable<User> {
    if (!this.apiService.getAuthorization()) {
      return of(undefined);
    }

    return this.userService.getCurrent(true).pipe(
      tap(user => {
        if (!user) {
          return;
        }

        if (isSet(user.language)) {
          this.localStorage.setLanguage(user.language);
        }

        if (isSet(user.timezone)) {
          this.localStorage.setTimezone(user.timezone);
        }
      })
    );
  }

  protected fetch(): Observable<User> {
    return combineLatest(this.fetchUser(), this._override$).pipe(
      map(([user, userOverride]) => {
        this._original$.next(user);

        if (isSet(userOverride)) {
          return userOverride;
        } else {
          return user;
        }
      })
    );
  }

  public update(fields: string[]): Observable<User> {
    return this.userService.update(this.instance, fields).pipe(map(result => result.user));
  }

  get original(): User {
    return this._original$.value;
  }

  get original$(): Observable<User> {
    return this._original$.asObservable();
  }

  get override(): User {
    return this._override$.value;
  }

  get override$(): Observable<User> {
    return this._override$.asObservable();
  }

  setOverride(value: User) {
    this._override$.next(value);
    this.instance = value ? value : this.original;
  }

  clearOverride() {
    this.setOverride(undefined);
  }
}
