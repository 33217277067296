import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable } from 'rxjs';

import { AppDragDrop, DragAxis, moveItemInFormArray, transferFormArrayItem } from '@common/drag-drop2';
import { AnalyticsEvent, AnalyticsEventAction, UniversalAnalyticsService } from '@modules/analytics';
import { CustomizeService } from '@modules/customize';
import { MenuBlockLayout, MenuBlockLayouts } from '@modules/menu';
import { CurrentProjectStore } from '@modules/projects';
import { RoutingService } from '@modules/routing';
import { TypedChanges } from '@shared';

import { AddMenuItemOptions } from '../add-menu-item-menu/add-menu-item-menu.component';
import { MenuBlockControl } from '../project-settings/menu-block.control';
import { ProjectAppearanceContext } from '../project-settings/project-appearance.context';
import {
  CustomizeBarPagesEditMenuItemArray,
  CustomizeBarPagesEditMenuItemControl
} from './customize-bar-pages-edit.form';

@Component({
  selector: 'app-customize-bar-pages-edit',
  templateUrl: './customize-bar-pages-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeBarPagesEditComponent implements OnInit, OnDestroy, OnChanges {
  @Input() uid: string;
  @Input() context: ProjectAppearanceContext;

  blockControl: MenuBlockControl;
  startItemsControl: CustomizeBarPagesEditMenuItemArray;
  centerItemsControl: CustomizeBarPagesEditMenuItemArray;
  endItemsControl: CustomizeBarPagesEditMenuItemArray;
  layout: MenuBlockLayout;

  horizontal = false;
  createdMenuItemControl: CustomizeBarPagesEditMenuItemControl;
  createdMenuItemControlOptions: AddMenuItemOptions;
  hasChanges$: Observable<boolean>;
  submitLoading = false;
  dragAxis = DragAxis;

  trackMenuItemFn(i, item: CustomizeBarPagesEditMenuItemControl) {
    return item.menuItem.id;
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private currentProjectStore: CurrentProjectStore,
    private customizeService: CustomizeService,
    private routing: RoutingService,
    private analyticsService: UniversalAnalyticsService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    const blockControl = this.context.controls.menu.controls.blocks.controls.find(
      item => item.instance.uid == this.uid
    );

    if (blockControl) {
      this.blockControl = blockControl;
      this.startItemsControl = blockControl.controls.start_items;
      this.centerItemsControl = blockControl.controls.center_items;
      this.endItemsControl = blockControl.controls.end_items;
      this.layout = blockControl.controls.layout.value;
    } else {
      this.back({ replaceUrl: true });
    }

    this.hasChanges$ = this.context.getHasChanges$();
    this.updateHorizontal();

    this.customizeService.addContentfulRightBarHandler(this);
  }

  ngOnDestroy(): void {
    this.customizeService.removeContentfulRightBarHandler(this);
  }

  ngOnChanges(changes: TypedChanges<CustomizeBarPagesEditComponent>): void {
    if (changes.layout) {
      this.updateHorizontal();
    }
  }

  updateHorizontal() {
    this.horizontal = MenuBlockLayouts.isHorizontal(this.layout);
  }

  addMenuItem(container: CustomizeBarPagesEditMenuItemArray, options: AddMenuItemOptions) {
    this.createdMenuItemControl = container.addControl(options.item, options.prepend);
    this.createdMenuItemControlOptions = options;
    this.cd.markForCheck();

    this.analyticsService.sendEvent(AnalyticsEvent.GA.MenuItem, AnalyticsEventAction.Created);
    this.analyticsService.sendSimpleEvent(AnalyticsEvent.Menu.MenuItemAdded);
  }

  dragDrop(event: AppDragDrop<CustomizeBarPagesEditMenuItemArray>) {
    if (event.previousContainer === event.container) {
      moveItemInFormArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferFormArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }

  back(options: { replaceUrl?: boolean } = {}) {
    const link = this.currentProjectStore.instance.settingsLayoutLink('appearance', 'menu');
    this.routing.navigateApp(link, { replaceUrl: options.replaceUrl });
  }

  cancel() {
    this.customizeService.stopTrackChanges();
    this.context.resetSavedState();
    this.customizeService.startTrackChanges();
  }

  submit() {
    if (this.submitLoading) {
      return;
    }

    this.submitLoading = true;
    this.cd.markForCheck();

    this.context
      .submit()
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          this.customizeService.stopTrackChanges();
          this.context.saveCurrentState();
          this.customizeService.startTrackChanges();
          this.submitLoading = false;
          this.cd.markForCheck();

          this.analyticsService.sendSimpleEvent(AnalyticsEvent.Appearance.AppearanceSaved, {
            Section: 'menu_pages',
            ProjectSettingsChanges: result.changes.projectSettingsChanges,
            MenuSettingsChanged: result.changes.menuSettings
          });
        },
        () => {
          this.submitLoading = false;
          this.cd.markForCheck();
        }
      );
  }
}
