import { Injectable, Injector, OnDestroy } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

import { CLOSE_BY_BACKGROUND_CLICK, TransparentDialogPopupComponent } from '@common/dialog-popup';
import { CLOSE_BY_DEACTIVATE, PopupService } from '@common/popups';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { CustomViewDataView } from '@modules/custom-views';
import { isSet } from '@shared';

import { ViewEditorComponent } from '../../components/view-editor/view-editor.component';
import { ViewSaveEvent } from '../../components/view-editor/view-save-event';

@Injectable()
export class ViewEditorController implements OnDestroy {
  constructor(
    private popupService: PopupService,
    private injector: Injector,
    private analyticsService: UniversalAnalyticsService
  ) {}

  ngOnDestroy(): void {}

  open(options: {
    create?: boolean;
    data: CustomViewDataView;
    componentLabel?: string;
    submitLabel?: string;
    nameEditingEnabled?: boolean;
    stateSelectedEnabled?: boolean;
    templatesEnabled?: boolean;
    analyticsSource?: string;
  }): Observable<ViewSaveEvent> {
    const result = new ReplaySubject<ViewSaveEvent>();
    const injector = Injector.create([], this.injector);

    this.analyticsService.sendSimpleEvent(AnalyticsEvent.ViewEditor.StartedToSetUp, {
      Create: options.create,
      Source: options.analyticsSource
    });

    this.popupService.push({
      component: ViewEditorComponent,
      popupComponent: TransparentDialogPopupComponent,
      popupComponentCloseTitle: 'Unsaved changes',
      popupComponentCloseDescription: `
        Are you sure want to close Designer?<br>
        Current changes will be lost.
      `,
      popupComponentOrange: true,
      inputs: {
        data: options.data,
        ...(isSet(options.componentLabel) && { componentLabel: options.componentLabel }),
        ...(isSet(options.submitLabel) && { submitLabel: options.submitLabel }),
        ...(isSet(options.nameEditingEnabled) && { nameEditingEnabled: options.nameEditingEnabled }),
        stateSelectedEnabled: options.stateSelectedEnabled,
        ...(isSet(options.templatesEnabled) && { templatesEnabled: options.templatesEnabled }),
        analyticsSource: options.analyticsSource
      },
      outputs: {
        result: [
          event => {
            result.next(event);

            this.analyticsService.sendSimpleEvent(AnalyticsEvent.ViewEditor.Saved, {
              Create: options.create,
              Source: options.analyticsSource
            });
          }
        ]
      },
      popupClosed: data => {
        if (data == CLOSE_BY_BACKGROUND_CLICK || data == CLOSE_BY_DEACTIVATE) {
          result.next({ cancelled: true });

          this.analyticsService.sendSimpleEvent(AnalyticsEvent.ViewEditor.SetUpCancelled, {
            Create: options.create,
            Source: options.analyticsSource
          });
        }
      },
      injector: injector
    });

    return result.asObservable();
  }
}
