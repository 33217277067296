import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import clone from 'lodash/clone';
import { Observable } from 'rxjs';

import { TintStyle } from '@modules/actions';
import { ActionDropdownElementItem, MarginControl } from '@modules/customize';
import { ElementConfigurationService } from '@modules/customize-configuration';
import { Input, Input as FieldInput, InputValueType } from '@modules/fields';
import { FieldInputControl } from '@modules/parameters';
import { isSet } from '@shared';

export interface CustomizeActionDropdownOptions {
  element: ActionDropdownElementItem;
  nameEditable?: boolean;
  nameCleanValue?: (value: string) => string;
  firstInit?: boolean;
}

@Injectable()
export class CustomizeBarActionDropdownEditForm extends FormGroup {
  options: CustomizeActionDropdownOptions;
  controls: {
    name: FormControl;
    actions: FormControl;
    icon: FormControl;
    style: FormControl;
    tint: FormControl;
    verbose_name: FieldInputControl;
    disabled_input: FieldInputControl;
    visible_input: FieldInputControl;
    tooltip: FormControl;
    card_wrap: FormControl;
    margin: MarginControl;
  };

  styleOptions = [
    {
      value: TintStyle.Primary,
      image: 'button-primary'
    },
    {
      value: TintStyle.Default,
      image: 'button-default'
    },
    {
      value: TintStyle.Transparent,
      image: 'button-transparent'
    }
  ];

  constructor(private elementConfigurationService: ElementConfigurationService) {
    super({
      name: new FormControl(''),
      actions: new FormControl([]),
      icon: new FormControl(null),
      style: new FormControl(TintStyle.Primary),
      tint: new FormControl(null),
      verbose_name: new FieldInputControl({ path: ['value'] }),
      disabled_input: new FieldInputControl({ path: ['value'] }),
      visible_input: new FieldInputControl({ path: ['value'] }),
      tooltip: new FormControl(''),
      card_wrap: new FormControl(true),
      margin: new MarginControl()
    });
  }

  init(options: CustomizeActionDropdownOptions) {
    this.options = options;

    const value = {
      actions: options.element.actionItems,
      icon: options.element.icon,
      style: options.element.style,
      tint: options.element.tint,
      verbose_name: options.element.verboseNameInput ? options.element.verboseNameInput.serializeWithoutPath() : {},
      disabled_input: options.element.disabledInput ? options.element.disabledInput.serializeWithoutPath() : {},
      visible_input: options.element.visibleInput ? options.element.visibleInput.serializeWithoutPath() : {},
      tooltip: options.element.tooltip,
      card_wrap: options.element.cardWrap,
      margin: options.element.margin
    };

    this.patchValue(value, { emitEvent: false });

    if (options.nameEditable) {
      this.controls.verbose_name.valueChanges.subscribe(inputValue => {
        const input = inputValue ? new FieldInput().deserialize(inputValue) : undefined;

        if (!input || input.valueType != InputValueType.StaticValue) {
          return;
        }

        let name = input.staticValue;

        if (options.nameCleanValue) {
          name = options.nameCleanValue(name);
        }

        this.controls.name.patchValue(name);
      });
    }

    if (!options.firstInit) {
      this.markAsDirty();
    }
  }

  isConfigured(instance: ActionDropdownElementItem): Observable<boolean> {
    return this.elementConfigurationService.isActionDropdownConfigured(instance, { restrictDemo: true });
  }

  submit(): ActionDropdownElementItem {
    const value = this.value;
    const instance: ActionDropdownElementItem = clone(this.options.element);

    if (this.options.nameEditable) {
      instance.name = value['name'];
    }

    instance.actionItems = value['actions'];
    instance.icon = value['icon'];
    instance.style = value['style'];
    instance.tint = value['tint'];
    instance.verboseNameInput = value['verbose_name'] ? new FieldInput().deserialize(value['verbose_name']) : undefined;

    if (value['disabled_input']) {
      instance.disabledInput = new Input().deserialize(value['disabled_input']);
    } else {
      instance.disabledInput = undefined;
    }

    if (value['visible_input']) {
      instance.visibleInput = new Input().deserialize(value['visible_input']);
    } else {
      instance.visibleInput = undefined;
    }

    instance.tooltip = isSet(value['tooltip']) ? value['tooltip'].trim() : undefined;
    instance.cardWrap = value['card_wrap'];
    instance.margin = value['margin'];

    return instance;
  }
}
