/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../customize-ui/components/element-wrapper/element-wrapper.component.ngfactory";
import * as i2 from "../../../customize-ui/components/element-wrapper/element-wrapper.component";
import * as i3 from "@angular/common";
import * as i4 from "./element-placeholder-stub.component";
import * as i5 from "../../../../common/drag-drop2/services/drop-list/drop-list.service";
var styles_ElementPlaceholderStubComponent = [];
var RenderType_ElementPlaceholderStubComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ElementPlaceholderStubComponent, data: {} });
export { RenderType_ElementPlaceholderStubComponent as RenderType_ElementPlaceholderStubComponent };
export function View_ElementPlaceholderStubComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "app-element-wrapper", [], [[1, "element-groupable", 0], [2, "element-wrapper", null], [2, "element-wrapper_wrap", null], [2, "element-wrapper_styles", null], [2, "element-wrapper_fill-vertical", null]], [[null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseenter" === en)) {
        var pd_0 = (_co.hover$.next(true) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (_co.hover$.next(false) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_ElementWrapperComponent_0, i1.RenderType_ElementWrapperComponent)), i0.ɵdid(1, 114688, null, 0, i2.ElementWrapperComponent, [], { wrap: [0, "wrap"] }, null), (_l()(), i0.ɵeld(2, 0, null, 0, 4, "div", [["class", "element-placeholder-stub"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 3, "div", [["class", "element-placeholder-stub-wrapper"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 2, "div", [["class", "element-placeholder-stub__inner"]], [[2, "element-placeholder-stub__inner_secondary", null]], null, null, null, null)), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_5 = _co.wrappable; _ck(_v, 1, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.groupable ? true : null); var currVal_1 = i0.ɵnov(_v, 1).cls; var currVal_2 = i0.ɵnov(_v, 1).wrap; var currVal_3 = i0.ɵnov(_v, 1).styles; var currVal_4 = i0.ɵnov(_v, 1).fillVertical; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_6 = (i0.ɵunv(_v, 4, 0, i0.ɵnov(_v, 5).transform(_co.siblingLeftEntered$)) || i0.ɵunv(_v, 4, 0, i0.ɵnov(_v, 6).transform(_co.siblingRightEntered$))); _ck(_v, 4, 0, currVal_6); }); }
export function View_ElementPlaceholderStubComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-element-placeholder-stub", [], null, null, null, View_ElementPlaceholderStubComponent_0, RenderType_ElementPlaceholderStubComponent)), i0.ɵdid(1, 770048, null, 0, i4.ElementPlaceholderStubComponent, [i5.DropListService, i0.ElementRef, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ElementPlaceholderStubComponentNgFactory = i0.ɵccf("app-element-placeholder-stub", i4.ElementPlaceholderStubComponent, View_ElementPlaceholderStubComponent_Host_0, { elementType: "elementType" }, {}, []);
export { ElementPlaceholderStubComponentNgFactory as ElementPlaceholderStubComponentNgFactory };
