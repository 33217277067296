import isArray from 'lodash/isArray';
import pickBy from 'lodash/pickBy';

import { NotificationType } from '@common/notifications';
import { DataSourceType, ListModelDescriptionDataSource } from '@modules/data-sources';
import { FieldOutput, Input, InputValueType, ParameterField } from '@modules/fields';
import { FilterItem2, Sort } from '@modules/filters';
import { PER_PAGE_PARAM } from '@modules/models';
import { ActionQuery, ListModelDescriptionQuery, QueryType } from '@modules/queries';
import { ascComparator, generateAlphanumeric, isSet, sortUsingAfterItem, splitmax } from '@shared';

// TODO: Refactor import
import { BorderSettings } from '../../customize/data/border-settings';
import { Corners } from '../../customize/data/corners';
import { Margin } from '../../customize/data/elements/items/base';
import { FillSettings } from '../../customize/data/fill-settings';
import { IconSettings } from '../../customize/data/icon-settings';
import { Shadow } from '../../customize/data/shadow';
import { TextStyle } from '../../customize/data/text-style';
import { Workflow } from '../../workflow/data/workflow';

import { ActionType } from './action-type';
import { Confirmation } from './confirmation';

export function sortActionDescriptions(items: ActionDescription[]): ActionDescription[] {
  if (items.length <= 1) {
    return items;
  }

  return sortUsingAfterItem<ActionDescription>({
    items: items,
    getAfterItem: item => item.orderAfter,
    getItemId: item => item.name,
    defaultSort: (lhs, rhs) => {
      return ascComparator(lhs.verboseName.toLowerCase(), rhs.verboseName.toLowerCase());
    }
  });
}

export class ActionItem {
  public uid: string;
  public name: string;
  public verboseNameInput: Input;
  public sharedActionDescription: string;
  public actionDescription: ActionDescription;
  public inputs: Input[] = [];
  public protected = false;
  public approve: Approve;
  public confirmation: Confirmation;
  public onSuccessNotification = false;
  public onSuccessActions: ActionItem[] = [];
  public onErrorNotification = false;
  public onErrorActions: ActionItem[] = [];

  deserialize(data: Object) {
    this.uid = data['uid'];
    this.name = data['name'];
    this.sharedActionDescription = data['shared_action_description'];

    if (data['verbose_name_input']) {
      this.verboseNameInput = new Input().deserialize(data['verbose_name_input']);
    } else if (data['verbose_name']) {
      // Backward compatibility
      this.verboseNameInput = new Input().deserializeFromStatic('value', data['verbose_name']);
    }

    if (data['action_description']) {
      this.actionDescription = new ActionDescription().deserialize(data['action_description']);
    }

    if (data['inputs']) {
      this.inputs = data['inputs'].map(item => new Input().deserialize(item));
    }

    if (
      this.actionDescription &&
      this.actionDescription.type === ActionType.ExternalLink &&
      !this.inputs.find(item => item.isName('new_tab'))
    ) {
      const input = new Input();
      input.path = ['new_tab'];
      input.valueType = InputValueType.StaticValue;
      input.staticValue = '1';
      this.inputs.push(input);
    }

    // Backward compatibility
    if (
      data['action_description'] &&
      data['action_description']['params'] &&
      data['action_description']['params']['export_action'] &&
      data['action_description']['params']['export_action']['model'] &&
      this.actionDescription &&
      this.actionDescription.type === ActionType.Export &&
      this.actionDescription.exportAction &&
      this.actionDescription.exportAction.dataSource &&
      this.actionDescription.exportAction.dataSource.type == DataSourceType.Query
    ) {
      this.actionDescription.exportAction.dataSource.queryInputs = this.inputs.filter(
        item => ![PER_PAGE_PARAM].some(param => item.isName(param))
      );
      this.inputs = this.inputs.filter(item => [PER_PAGE_PARAM].some(param => item.isName(param)));
    }

    if (data['protected'] != undefined) {
      this.protected = data['protected'];
    }

    if (data['approve'] != undefined) {
      this.approve = new Approve().deserialize(data['approve']);
    }

    if (data['confirmation'] != undefined) {
      this.confirmation = new Confirmation().deserialize(data['confirmation']);
    }

    if (data['on_success_actions']) {
      this.onSuccessActions = data['on_success_actions'].map(item => new ActionItem().deserialize(item));
    }

    if (data['on_error_actions']) {
      this.onErrorActions = data['on_error_actions'].map(item => new ActionItem().deserialize(item));
    }

    const isQueryType =
      isSet(this.sharedActionDescription) ||
      (this.actionDescription && this.actionDescription.type == ActionType.Query);

    if (isSet(data['on_success_notification'])) {
      this.onSuccessNotification = data['on_success_notification'];
    } else {
      // Backward compatibility
      const notificationActionExists = !this.onSuccessActions.find(
        item => item.actionDescription && item.actionDescription.type == ActionType.ShowNotification
      );
      this.onSuccessNotification = isQueryType && notificationActionExists;
    }

    if (isSet(data['on_success_notification'])) {
      this.onErrorNotification = data['on_error_notification'];
    } else {
      // Backward compatibility
      const notificationActionExists = !this.onErrorActions.find(
        item => item.actionDescription && item.actionDescription.type == ActionType.ShowNotification
      );
      this.onErrorNotification = isQueryType && notificationActionExists;
    }

    return this;
  }

  serialize(fields?: string[]) {
    let data: Object = {
      uid: this.uid,
      name: this.name,
      verbose_name_input: this.verboseNameInput ? this.verboseNameInput.serialize() : null,
      shared_action_description: this.sharedActionDescription,
      action_description: this.actionDescription ? this.actionDescription.serialize() : undefined,
      inputs: this.inputs.map(item => item.serialize()),
      protected: this.protected,
      approve: this.approve ? this.approve.serialize() : undefined,
      confirmation: this.confirmation ? this.confirmation.serialize() : undefined,
      on_success_notification: this.onSuccessNotification,
      on_success_actions: this.onSuccessActions.map(item => item.serialize()),
      on_error_notification: this.onErrorNotification,
      on_error_actions: this.onErrorActions.map(item => item.serialize())
    };
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }

  get queryLink() {
    return ['execute_action'];
  }

  generateUid() {
    this.uid = generateAlphanumeric(8, { letterFirst: true });
  }
}

export class Approve {
  public taskQueue: string;
  public taskName: Input;
  public taskInputs: Input[] = [];
  public taskAssignee: string;
  public taskCreateStatus: string;
  public taskApproveStatus: string;
  public taskRejectStatus: string;
  public onTaskCreateActions: ActionItem[] = [];
  public onRejectActions: ActionItem[] = [];

  deserialize(data: Object) {
    this.taskQueue = data['task_queue'];
    this.taskName = new Input().deserialize(data['task_name']);
    this.taskAssignee = data['task_assignee'];
    this.taskCreateStatus = data['task_create_status'];
    this.taskApproveStatus = data['task_approve_status'];
    this.taskRejectStatus = data['task_reject_status'];

    if (data['task_inputs']) {
      this.taskInputs = data['task_inputs'].map(item => new Input().deserialize(item));
    }

    if (data['on_task_create_actions']) {
      this.onTaskCreateActions = data['on_task_create_actions'].map(item => new ActionItem().deserialize(item));
    }

    if (data['on_reject_actions']) {
      this.onRejectActions = data['on_reject_actions'].map(item => new ActionItem().deserialize(item));
    }

    return this;
  }

  serialize(fields?: string[]) {
    let data: Object = {
      task_queue: this.taskQueue,
      task_name: this.taskName ? this.taskName.serialize() : undefined,
      task_inputs: this.taskInputs.map(item => item.serialize()),
      task_assignee: this.taskAssignee,
      task_create_status: this.taskCreateStatus,
      task_approve_status: this.taskApproveStatus,
      task_reject_status: this.taskRejectStatus,
      on_task_create_actions: this.onTaskCreateActions.map(item => item.serialize()),
      on_reject_actions: this.onRejectActions.map(item => item.serialize())
    };
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }
}

export enum TintStyle {
  Default = 'default',
  Primary = 'primary',
  Transparent = 'transparent'
}

export class ViewSettingsAction extends ActionItem {
  public visible = true;
  public pinned = false;
  public style: TintStyle = TintStyle.Primary;
  public tint: string;
  public icon: string;
  public order: number;
  public disabledInput: Input;
  public visibleInput: Input;

  public textStyle: TextStyle;
  public iconSettings: IconSettings;
  public fillSettings: FillSettings;
  public borderSettings: BorderSettings;
  public borderRadius: Corners;
  public shadow: Shadow;
  public padding: Margin;

  public hoverTextStyle: TextStyle;
  public hoverIconSettings: IconSettings;
  public hoverFillSettings: FillSettings;
  public hoverBorderSettings: BorderSettings;
  public hoverShadow: Shadow;

  public activeTextStyle: TextStyle;
  public activeIconSettings: IconSettings;
  public activeFillSettings: FillSettings;
  public activeBorderSettings: BorderSettings;
  public activeShadow: Shadow;

  deserialize(data: Object) {
    super.deserialize(data);
    this.icon = data['icon'];
    this.order = data['order'];

    if (data['style'] != undefined) {
      this.style = data['style'];
    }

    if (this.style == TintStyle.Default && data['color_text']) {
      // Backward compatibility
      this.tint = data['color_text'];
    } else if (this.style == TintStyle.Primary && data['color']) {
      // Backward compatibility
      this.tint = data['color'];
    } else if (this.style == TintStyle.Transparent && data['color_text']) {
      // Backward compatibility
      this.tint = data['color_text'];
    } else {
      this.tint = data['tint'];
    }

    if (data['visible'] != undefined) {
      this.visible = data['visible'];
    }

    if (data['pinned'] != undefined) {
      this.pinned = data['pinned'];
    }

    if (data['disabled_input']) {
      this.disabledInput = new Input().deserialize(data['disabled_input']);
    }

    if (data['visible_input']) {
      this.visibleInput = new Input().deserialize(data['visible_input']);
    }

    if (data['text_style']) {
      this.textStyle = new TextStyle().deserialize(data['text_style']);
    } else {
      this.textStyle = undefined;
    }

    if (data['icon_settings']) {
      this.iconSettings = new IconSettings().deserialize(data['icon_settings']);
    } else {
      this.iconSettings = undefined;
    }

    if (data['fill_settings']) {
      this.fillSettings = new FillSettings().deserialize(data['fill_settings']);
    } else {
      this.fillSettings = undefined;
    }

    if (data['border_settings']) {
      this.borderSettings = new BorderSettings().deserialize(data['border_settings']);
    } else {
      this.borderSettings = undefined;
    }

    if (data['border_radius']) {
      this.borderRadius = data['border_radius'];
    } else {
      this.borderRadius = undefined;
    }

    if (data['shadow']) {
      this.shadow = new Shadow().deserialize(data['shadow']);
    } else {
      this.shadow = undefined;
    }

    this.padding = data['padding'];

    if (data['hover_text_style']) {
      this.hoverTextStyle = new TextStyle().deserialize(data['hover_text_style']);
    } else {
      this.hoverTextStyle = undefined;
    }

    if (data['hover_icon_settings']) {
      this.hoverIconSettings = new IconSettings().deserialize(data['hover_icon_settings']);
    } else {
      this.hoverIconSettings = undefined;
    }

    if (data['hover_fill_settings']) {
      this.hoverFillSettings = new FillSettings().deserialize(data['hover_fill_settings']);
    } else {
      this.hoverFillSettings = undefined;
    }

    if (data['hover_border_settings']) {
      this.hoverBorderSettings = new BorderSettings().deserialize(data['hover_border_settings']);
    } else {
      this.hoverBorderSettings = undefined;
    }

    if (data['hover_shadow']) {
      this.hoverShadow = new Shadow().deserialize(data['hover_shadow']);
    } else {
      this.hoverShadow = undefined;
    }

    if (data['active_text_style']) {
      this.activeTextStyle = new TextStyle().deserialize(data['active_text_style']);
    } else {
      this.activeTextStyle = undefined;
    }

    if (data['active_icon_settings']) {
      this.activeIconSettings = new IconSettings().deserialize(data['active_icon_settings']);
    } else {
      this.activeIconSettings = undefined;
    }

    if (data['active_fill_settings']) {
      this.activeFillSettings = new FillSettings().deserialize(data['active_fill_settings']);
    } else {
      this.activeFillSettings = undefined;
    }

    if (data['active_border_settings']) {
      this.activeBorderSettings = new BorderSettings().deserialize(data['active_border_settings']);
    } else {
      this.activeBorderSettings = undefined;
    }

    if (data['active_shadow']) {
      this.activeShadow = new Shadow().deserialize(data['active_shadow']);
    } else {
      this.activeShadow = undefined;
    }

    return this;
  }

  serialize(fields?: string[]): Object {
    let data: Object = {
      ...super.serialize(fields),
      style: this.style,
      visible: this.visible,
      pinned: this.pinned,
      tint: this.tint,
      icon: this.icon,
      order: this.order,
      disabled_input: this.disabledInput ? this.disabledInput.serialize() : null,
      visible_input: this.visibleInput ? this.visibleInput.serialize() : null,

      text_style: this.textStyle ? this.textStyle.serialize() : undefined,
      icon_settings: this.iconSettings ? this.iconSettings.serialize() : undefined,
      fill_settings: this.fillSettings ? this.fillSettings.serialize() : undefined,
      border_settings: this.borderSettings ? this.borderSettings.serialize() : undefined,
      border_radius: this.borderRadius,
      shadow: this.shadow ? this.shadow.serialize() : undefined,
      padding: this.padding,

      hover_text_style: this.hoverTextStyle ? this.hoverTextStyle.serialize() : undefined,
      hover_icon_settings: this.hoverIconSettings ? this.hoverIconSettings.serialize() : undefined,
      hover_fill_settings: this.hoverFillSettings ? this.hoverFillSettings.serialize() : undefined,
      hover_border_settings: this.hoverBorderSettings ? this.hoverBorderSettings.serialize() : undefined,
      hover_shadow: this.hoverShadow ? this.hoverShadow.serialize() : undefined,

      active_text_style: this.activeTextStyle ? this.activeTextStyle.serialize() : undefined,
      active_icon_settings: this.activeIconSettings ? this.activeIconSettings.serialize() : undefined,
      active_fill_settings: this.activeFillSettings ? this.activeFillSettings.serialize() : undefined,
      active_border_settings: this.activeBorderSettings ? this.activeBorderSettings.serialize() : undefined,
      active_shadow: this.activeShadow ? this.activeShadow.serialize() : undefined
    };
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }
}

export class QueryAction {
  public query: ActionQuery;

  deserialize(data: Object): QueryAction {
    if (data['query']) {
      this.query = new ActionQuery().deserialize(data['query']);
    }

    return this;
  }

  serialize() {
    return {
      query: this.query ? this.query.serialize() : undefined
    };
  }
}

export enum DownloadActionType {
  Input = 'input',
  Query = 'query'
}

export class DownloadAction {
  public type: DownloadActionType;
  public fileColumn: string;
  public query: ActionQuery;
  public input: Input;

  deserialize(data: Object): DownloadAction {
    this.type = data['type'] || DownloadActionType.Query;
    this.fileColumn = data['file_column'];

    if (data['query']) {
      this.query = new ActionQuery().deserialize(data['query']);
    }

    if (data['input']) {
      this.input = new Input().deserialize(data['input']);
    }

    return this;
  }

  serialize() {
    return {
      type: this.type,
      query: this.query ? this.query.serialize() : undefined,
      file_column: this.fileColumn,
      input: this.input ? this.input.serialize() : undefined
    };
  }
}

export enum SegueType {
  Page = 'page',
  PreviousPage = 'previous_page',
  ModelCreate = 'model_create',
  ModelChange = 'model_change',
  ModelMassEdit = 'model_mass_edit',
  ModelActivityLog = 'model_activity_log',
  ModelDelete = 'model_delete'
}

export const modelSegueTypes = [
  SegueType.ModelCreate,
  SegueType.ModelChange,
  SegueType.ModelMassEdit,
  SegueType.ModelActivityLog,
  SegueType.ModelDelete
];

export interface Segue {
  type: SegueType;
  page?: string;
  model?: string;
}

export class LinkAction implements Segue {
  type: SegueType;
  page?: string;
  model?: string;

  deserialize(data: Object): LinkAction {
    this.type = data['type'];
    this.page = data['page'];
    this.model = data['model'];

    return this;
  }

  serialize() {
    return {
      type: this.type,
      page: this.page,
      model: this.model
    };
  }
}

export class NotificationAction {
  public title: Input;
  public description: Input;
  public icon: string;
  public color: string;
  public type: NotificationType;
  public closeTimeoutEnabled = true;
  public closeTimeout: number;

  deserialize(data: Object): NotificationAction {
    this.type = data['type'];
    this.icon = data['icon'];
    this.color = data['color'];

    if (data['title']) {
      // Backward compatibility
      if (typeof data['title'] == 'string') {
        this.title = new Input().deserializeFromStatic('value', data['title']);
      } else {
        this.title = new Input().deserialize(data['title']);
      }
    }

    if (data['description']) {
      // Backward compatibility
      if (typeof data['description'] == 'string') {
        this.description = new Input().deserializeFromStatic('value', data['description']);
      } else {
        this.description = new Input().deserialize(data['description']);
      }
    }

    if (data['close_timeout_enabled'] !== undefined) {
      this.closeTimeoutEnabled = data['close_timeout_enabled'];
    }

    this.closeTimeout = data['close_timeout'];

    return this;
  }

  serialize() {
    return {
      title: this.title ? this.title.serialize() : null,
      description: this.description ? this.description.serialize() : null,
      icon: this.icon,
      color: this.color,
      type: this.type,
      close_timeout_enabled: this.closeTimeoutEnabled,
      close_timeout: this.closeTimeout
    };
  }
}

export class SetPropertyAction {
  public property: string;
  public value: Input;

  deserialize(data: Object): SetPropertyAction {
    this.property = data['property'];

    if (data['value']) {
      this.value = new Input().deserialize(data['value']);
    }

    return this;
  }

  serialize() {
    return {
      property: this.property,
      value: this.value ? this.value.serialize() : null
    };
  }
}

export class RunJavaScriptAction {
  public js: string;

  deserialize(data: Object): RunJavaScriptAction {
    this.js = data['js'];

    return this;
  }

  serialize() {
    return {
      js: this.js
    };
  }
}

export class CopyToClipboardAction {
  public value: Input;

  deserialize(data: Object): CopyToClipboardAction {
    if (data['value']) {
      this.value = new Input().deserialize(data['value']);
    }

    return this;
  }

  serialize() {
    return {
      value: this.value ? this.value.serialize() : null
    };
  }
}

export enum ExportDataType {
  CurrentComponent = 'current_component',
  DataSource = 'data_source'
}

export class ExportAction {
  public dataType: ExportDataType = ExportDataType.DataSource;
  public dataSource: ListModelDescriptionDataSource;
  public filters: FilterItem2[];
  public search: string;
  public sort: Sort[];

  deserialize(data: Object): ExportAction {
    if (data['data_type'] !== undefined) {
      this.dataType = data['data_type'];
    }

    if (data['data_source']) {
      this.dataSource = new ListModelDescriptionDataSource().deserialize(data['data_source']);
    }

    if (data['filters']) {
      this.filters = data['filters'].map(item => new FilterItem2().deserialize(item));
    }

    if (isSet(data['search'])) {
      this.search = data['search'];
    }

    if (data['sort']) {
      this.sort = data['sort'];
    }

    return this;
  }

  serialize() {
    return {
      data_type: this.dataType,
      data_source: this.dataSource ? this.dataSource.serialize() : undefined,
      filters: this.filters ? this.filters.map(item => item.serialize()) : undefined,
      search: this.search,
      sort: this.sort
    };
  }

  getModelId() {
    return this.dataSource ? this.dataSource.getModelId() : undefined;
  }
}

export class ImportAction {
  resource: string;
  model: string;

  deserialize(data: Object): ImportAction {
    this.resource = data['resource'];
    this.model = data['model'];

    return this;
  }

  serialize() {
    return {
      resource: this.resource,
      model: this.model
    };
  }

  getModelId(): string {
    if (!isSet(this.resource) || !isSet(this.model)) {
      return;
    }

    return [this.resource, this.model].join('.');
  }
}

export class OpenPopupAction {
  public popup: string;
  public closeOther = true;
  public togglePopup = false;

  deserialize(data: Object): OpenPopupAction {
    this.popup = data['popup'];

    if (isSet(data['close_other'])) {
      this.closeOther = data['close_other'];
    }

    if (isSet(data['toggle_popup'])) {
      this.togglePopup = data['toggle_popup'];
    }

    return this;
  }

  serialize() {
    return {
      popup: this.popup,
      close_other: this.closeOther,
      toggle_popup: this.togglePopup
    };
  }
}

export class ClosePopupAction {
  public popup: string;

  deserialize(data: Object): ClosePopupAction {
    this.popup = data['popup'];

    return this;
  }

  serialize() {
    return {
      popup: this.popup
    };
  }
}

export class OpenActionMenuAction {
  public actions: ViewSettingsAction[] = [];

  deserialize(data: Object): OpenActionMenuAction {
    if (data['actions']) {
      this.actions = data['actions'].map(item => new ViewSettingsAction().deserialize(item));
    }

    return this;
  }

  serialize() {
    return {
      actions: this.actions.map(item => item.serialize())
    };
  }
}

export class WorkflowAction {
  public workflow: Workflow;

  deserialize(data: Object): WorkflowAction {
    if (data['workflow']) {
      this.workflow = new Workflow().deserialize(data['workflow']);
    }

    return this;
  }

  serialize() {
    return {
      workflow: this.workflow ? this.workflow.serialize() : undefined
    };
  }
}

export class ActionDescription {
  public project: string;
  public resource: string;
  public model: string;
  public modelAction: string;
  public storage: string;
  public storageAction: string;
  public name: string;
  public params: Object;
  public type: ActionType;
  public verboseName: string;
  public icon: string;
  public actionParams: ParameterField[] = [];
  public outputs: FieldOutput[] = [];
  public arrayOutput = false;
  public description: string;
  public protected = false;
  public queryAction: QueryAction;
  public downloadAction: DownloadAction;
  public linkAction: LinkAction;
  public elementAction: (string | number)[];
  public notificationAction: NotificationAction;
  public exportAction: ExportAction;
  public importAction: ImportAction;
  public openPopupAction: OpenPopupAction;
  public closePopupAction: ClosePopupAction;
  public openActionMenuAction: OpenActionMenuAction;
  public workflowAction: WorkflowAction;
  public setPropertyAction: SetPropertyAction;
  public runJavaScriptAction: RunJavaScriptAction;
  public copyToClipboardAction: CopyToClipboardAction;
  public virtual = false;
  public featured = false;
  public orderAfter: string = undefined;
  public draft = false;
  public deleted = false;

  public deprecatedDynamicStatus = false;
  public deprecatedModelAction: {
    model: string;
    bulk: boolean;
    for_instance: boolean;
  };

  static generateName(): string {
    return generateAlphanumeric(8);
  }

  deserialize(data: Object): ActionDescription {
    this.resource = data['resource'];
    this.name = data['name'];
    this.verboseName = data['verbose_name'] || this.name;

    if (data['icon']) {
      this.icon = data['icon'];
    }

    if (data['params']) {
      if (isArray(data['params'])) {
        this.actionParams = data['params'].map(item => new ParameterField().deserialize(item));
        this.params = {};
      } else {
        this.params = data['params'];
        this.type = this.params['type'];
        this.verboseName = this.params['verbose_name'] || this.name;
        this.icon = this.params['icon'];
        this.arrayOutput = this.params['array_output'];

        if (this.params['protected'] != undefined) {
          this.protected = this.params['protected'];
        }

        if (this.params['action_params']) {
          this.actionParams = this.params['action_params'].map(item => new ParameterField().deserialize(item));
        }

        if (this.params['outputs']) {
          this.outputs = this.params['outputs'].map(item => new FieldOutput().deserialize(item));
        }

        this.description = this.params['description'];

        if (this.params['query_action']) {
          this.queryAction = new QueryAction().deserialize(this.params['query_action']);
        } else if (this.params['query']) {
          this.queryAction = new QueryAction().deserialize({
            query: this.params['query']
          });
        }

        if (this.params['download_action']) {
          this.downloadAction = new DownloadAction().deserialize(this.params['download_action']);
        }

        if (this.params['link_action']) {
          this.linkAction = new LinkAction().deserialize(this.params['link_action']);
        }

        if (this.params['element_action']) {
          if (isArray(this.params['element_action'])) {
            this.elementAction = this.params['element_action'];
          } else if (typeof this.params['element_action'] == 'string') {
            this.elementAction = this.params['element_action'].split('.');
          }
        }

        if (this.params['notification_action']) {
          this.notificationAction = new NotificationAction().deserialize(this.params['notification_action']);
        }

        if (this.params['export_action']) {
          // Backward compatibility
          if (this.params['export_action']['model']) {
            this.exportAction = new ExportAction();
            this.exportAction.dataSource = new ListModelDescriptionDataSource();
            this.exportAction.dataSource.type = DataSourceType.Query;
            this.exportAction.dataSource.queryResource = data['resource'];
            this.exportAction.dataSource.query = new ListModelDescriptionQuery();
            this.exportAction.dataSource.query.queryType = QueryType.Simple;
            this.exportAction.dataSource.query.simpleQuery = new this.exportAction.dataSource.query.simpleQueryClass();
            this.exportAction.dataSource.query.simpleQuery.model = this.params['export_action']['model'];
          } else {
            this.exportAction = new ExportAction().deserialize(this.params['export_action']);
          }
        }

        if (this.params['import_action']) {
          this.importAction = new ImportAction().deserialize(this.params['import_action']);
        }

        if (this.params['open_popup_action']) {
          this.openPopupAction = new OpenPopupAction().deserialize(this.params['open_popup_action']);
        }

        if (this.params['close_popup_action']) {
          this.closePopupAction = new ClosePopupAction().deserialize(this.params['close_popup_action']);
        }

        if (this.params['open_action_menu_action']) {
          this.openActionMenuAction = new OpenActionMenuAction().deserialize(this.params['open_action_menu_action']);
        }

        if (this.params['workflow_action']) {
          this.workflowAction = new WorkflowAction().deserialize(this.params['workflow_action']);
        }

        if (this.params['set_property_action']) {
          this.setPropertyAction = new SetPropertyAction().deserialize(this.params['set_property_action']);
        }

        if (this.params['run_javascript_action']) {
          this.runJavaScriptAction = new RunJavaScriptAction().deserialize(this.params['run_javascript_action']);
        }

        if (this.params['copy_to_clipboard_action']) {
          this.copyToClipboardAction = new CopyToClipboardAction().deserialize(this.params['copy_to_clipboard_action']);
        }

        if (this.params['virtual']) {
          this.virtual = this.params['virtual'];
        }

        if (this.params['featured']) {
          this.featured = this.params['featured'];
        }

        if (this.params['order_after'] !== undefined) {
          this.orderAfter = this.params['order_after'];
        }
      }
    }

    // Backward compatibility
    if (!this.type) {
      if (this.queryAction) {
        this.type = ActionType.Query;
      } else if (this.linkAction) {
        this.type = ActionType.Link;
      } else if (this.linkAction && this.linkAction.type == ('model_export' as SegueType)) {
        // Backward compatibility (<2.2.6)
        const model = this.linkAction.model ? splitmax(this.linkAction.model, '.', 2) : undefined;
        this.type = ActionType.Export;

        if (model && model.length == 2) {
          this.exportAction = new ExportAction();
          this.exportAction.dataSource = new ListModelDescriptionDataSource();
          this.exportAction.dataSource.type = DataSourceType.Query;
          this.exportAction.dataSource.query = new ListModelDescriptionQuery();
          this.exportAction.dataSource.query.queryType = QueryType.Simple;
          this.exportAction.dataSource.query.simpleQuery = new this.exportAction.dataSource.query.simpleQueryClass();

          [this.exportAction.dataSource.queryResource, this.exportAction.dataSource.query.simpleQuery.model] = model;
        }
      }
    }

    if (data['draft'] !== undefined) {
      this.draft = data['draft'];
    }

    if (data['deleted'] !== undefined) {
      this.deleted = data['deleted'];
    }

    // Backward compatibility

    if (data['dynamic_status'] != undefined) {
      this.deprecatedDynamicStatus = data['dynamic_status'];
    }

    if (data['model_action'] != undefined) {
      this.deprecatedModelAction = data['model_action'];
    }

    return this;
  }

  get id() {
    return [this.resource, this.name].join('.');
  }

  isSame(action: string | ActionDescription) {
    if (!action) {
      return false;
    }

    if (action instanceof ActionDescription) {
      return action.resource == this.resource && action.name == this.name;
    } else {
      const id = action as string;
      const params = splitmax(id, '.', 2);

      return params.length == 2
        ? (!this.resource || this.resource == params[0]) && this.name == params[1]
        : this.name == params[0];
    }
  }

  serialize() {
    return {
      resource: this.resource,
      name: this.name,
      params: {
        type: this.type,
        verbose_name: this.verboseName,
        icon: this.icon,
        action_params: this.actionParams.map(item => item.serialize()),
        outputs: this.outputs.map(item => item.serialize()),
        array_output: this.arrayOutput,
        description: this.description,
        protected: this.protected,
        query_action: this.queryAction ? this.queryAction.serialize() : undefined,
        download_action: this.downloadAction ? this.downloadAction.serialize() : undefined,
        link_action: this.linkAction ? this.linkAction.serialize() : undefined,
        element_action: this.elementAction,
        notification_action: this.notificationAction ? this.notificationAction.serialize() : undefined,
        export_action: this.exportAction ? this.exportAction.serialize() : undefined,
        import_action: this.importAction ? this.importAction.serialize() : undefined,
        open_popup_action: this.openPopupAction ? this.openPopupAction.serialize() : undefined,
        close_popup_action: this.closePopupAction ? this.closePopupAction.serialize() : undefined,
        open_action_menu_action: this.openActionMenuAction ? this.openActionMenuAction.serialize() : undefined,
        workflow_action: this.workflowAction ? this.workflowAction.serialize() : undefined,
        set_property_action: this.setPropertyAction ? this.setPropertyAction.serialize() : undefined,
        run_javascript_action: this.runJavaScriptAction ? this.runJavaScriptAction.serialize() : undefined,
        copy_to_clipboard_action: this.copyToClipboardAction ? this.copyToClipboardAction.serialize() : undefined,
        virtual: this.virtual,
        featured: this.featured,
        order_after: this.orderAfter
      },
      draft: this.draft,
      deleted: this.deleted
    };
  }

  getLink(modelId?: string) {
    if (this.queryAction) {
      return ['action', this.id];
    } else if (this.linkAction) {
      // return [this.linkAction.link];
    }
  }

  get queryLink() {
    return ['action', this.id];
  }

  clone() {
    return new ActionDescription().deserialize(this.serialize());
  }

  get typeStr() {
    if (this.type == ActionType.Query) {
      return 'Run Operation';
    } else if (this.type == ActionType.Download) {
      return 'Download File';
    } else if (this.type == ActionType.Link) {
      return 'Navigate to Page';
    } else if (this.type == ActionType.ExternalLink) {
      return 'Open URL';
    } else if (this.type == ActionType.ElementAction) {
      return 'Component Action';
    } else if (this.type == ActionType.ShowNotification) {
      return 'Show Notification';
    } else if (this.type == ActionType.SetProperty) {
      return 'Set Variable';
    } else if (this.type == ActionType.RunJavaScript) {
      return 'Run JavaScript';
    } else if (this.type == ActionType.CopyToClipboard) {
      return 'Copy to Clipboard';
    } else if (this.type == ActionType.Export) {
      return 'Export Data';
    } else if (this.type == ActionType.Import) {
      return 'Import Data';
    } else if (this.type == ActionType.OpenPopup) {
      return 'Open Overlay';
    } else if (this.type == ActionType.ClosePopup) {
      return 'Close Overlay';
    } else if (this.type == ActionType.OpenActionMenu) {
      return 'Open Actions dropdown';
    } else if (this.type == ActionType.ScanCode) {
      return 'Scan QR/Bar code';
    } else if (this.type == ActionType.Workflow) {
      return 'Run Workflow';
    }
  }

  get typeIcon() {
    if (this.type == ActionType.Query) {
      return 'cloud_upload';
    } else if (this.type == ActionType.Download) {
      return 'save';
    } else if (this.type == ActionType.Link) {
      return 'redo';
    } else if (this.type == ActionType.ExternalLink) {
      return 'model_link';
    } else if (this.type == ActionType.ElementAction) {
      return 'components';
    } else if (this.type == ActionType.ShowNotification) {
      return 'notification';
    } else if (this.type == ActionType.SetProperty) {
      return 'variable';
    } else if (this.type == ActionType.RunJavaScript) {
      return 'console';
    } else if (this.type == ActionType.CopyToClipboard) {
      return 'documents';
    } else if (this.type == ActionType.Export) {
      return 'download';
    } else if (this.type == ActionType.Import) {
      return 'upload';
    } else if (this.type == ActionType.OpenPopup) {
      return 'copy';
    } else if (this.type == ActionType.ClosePopup) {
      return 'windows';
    } else if (this.type == ActionType.OpenActionMenu) {
      return 'fileds';
    } else if (this.type == ActionType.Workflow) {
      return 'workflow';
    } else if (this.type == ActionType.ScanCode) {
      return 'qr_code';
    } else {
      return 'power';
    }
  }

  get editLink() {
    return ['resources', this.resource, 'actions', this.name];
  }

  get anyName(): string {
    return isSet(this.verboseName) ? this.verboseName : this.name;
  }
}
