import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material';
import { NgGxScrollableModule } from 'ng-gxscrollable';
import { NgGxSelectModule } from 'ng-gxselect';

import { DialogsModule } from '@common/dialogs';
import { DragDropModule } from '@common/drag-drop';
import { FixedComponentModule } from '@common/fixed-component';
import { LocalizeModule } from '@common/localize';
import { ResizableModule } from '@common/resizable';
import { TipsModule } from '@common/tips';
import { AnalyticsModule } from '@modules/analytics';
import { ColorsSharedModule } from '@modules/colors-shared';
import { CustomElementsComponentsModule } from '@modules/custom-elements-components';
import { CustomizeSharedModule } from '@modules/customize-shared';
import { DemoModule } from '@modules/demo';
import { ExportModule } from '@modules/export';
import { FieldComponentsModule } from '@modules/field-components';
import { FieldsModule } from '@modules/fields';
import { FiltersComponentsModule } from '@modules/filters-components';
import { IconsModule } from '@modules/icons';
import { ListComponentsModule } from '@modules/list-components';
import { ModelQueriesModule } from '@modules/model-queries';
import { RoutingModule } from '@modules/routing';
import { ThemeComponentsModule } from '@modules/theme-components';
import { ViewsComponentsModule } from '@modules/views-components';
import { SharedModule } from '@shared';
import { UiModule } from '@ui';

import { MoveComponent } from './components/move/move.component';
import { TableActionsItemComponent } from './components/table-actions/table-actions-item/table-actions-item.component';
import { TableActionsComponent } from './components/table-actions/table-actions.component';
import { TableEditableCellComponent } from './components/table-editable-cell/table-editable-cell.component';
import { TableGroupComponent } from './components/table-group/table-group.component';
import { TableHeaderComponent } from './components/table-header/table-header.component';
import { TableItemActionComponent } from './components/table-item-action/table-item-action.component';
import { TableItemColumnActionComponent } from './components/table-item-column-action/table-item-column-action.component';
import { TableItemColumnComponent } from './components/table-item-column/table-item-column.component';
import { TableItemComponent } from './components/table-item/table-item.component';
import { TableStubContentBodyComponent } from './components/table-stub-content-body/table-stub-content-body.component';
import { TableStubContentHeaderComponent } from './components/table-stub-content-header/table-stub-content-header.component';
import { TableStubContentComponent } from './components/table-stub-content/table-stub-content.component';
import { TableStubComponent } from './components/table-stub/table-stub.component';
import { TableComponent } from './components/table/table.component';

@NgModule({
  imports: [
    CommonModule,
    RoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgGxScrollableModule,
    NgGxSelectModule,
    SharedModule,
    FixedComponentModule,
    DemoModule,
    ListComponentsModule,
    FieldsModule,
    FieldComponentsModule,
    LocalizeModule,
    TipsModule,
    DragDropModule,
    DialogsModule,
    ModelQueriesModule,
    AnalyticsModule,
    ExportModule,
    UiModule,
    OverlayModule,
    ResizableModule,
    FiltersComponentsModule,
    MatMenuModule,
    ThemeComponentsModule,
    ViewsComponentsModule,
    ColorsSharedModule,
    IconsModule,
    CustomizeSharedModule,
    CustomElementsComponentsModule
  ],
  declarations: [
    TableActionsComponent,
    TableComponent,
    TableItemComponent,
    MoveComponent,
    TableActionsItemComponent,
    TableStubComponent,
    TableStubContentComponent,
    TableStubContentHeaderComponent,
    TableStubContentBodyComponent,
    TableHeaderComponent,
    TableItemColumnComponent,
    TableItemActionComponent,
    TableItemColumnActionComponent,
    TableEditableCellComponent,
    TableGroupComponent
  ],
  exports: [
    TableComponent,
    TableItemComponent,
    TableStubComponent,
    TableStubContentComponent,
    TableStubContentHeaderComponent,
    TableStubContentBodyComponent,
    TableEditableCellComponent
  ],
  entryComponents: [MoveComponent]
})
export class TableModule {}
