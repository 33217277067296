import { Injectable, Injector, OnDestroy } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import range from 'lodash/range';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, merge, Observable, of } from 'rxjs';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';

import {
  applyFieldElementStyles,
  CustomizeService,
  FilterElementInput,
  FilterElementItem,
  FilterStyle,
  getFieldElementStyles,
  ListElementItem,
  MarginControl,
  ViewContext
} from '@modules/customize';
import { ElementConfigurationService } from '@modules/customize-configuration';
import { EditableFlexField, FieldDescription, getFieldDescriptionByType, Input } from '@modules/fields';
import { ModelDescriptionStore } from '@modules/model-queries';
import { FieldInputControl, InputFieldProvider, listModelDescriptionInputFieldProvider } from '@modules/parameters';
import { CurrentEnvironmentStore } from '@modules/projects';
import { controlValue, isSet } from '@shared';

import { FieldElementStylesControl } from '../styles-field-element-edit/field-element-styles.control';

export class CustomizeBarFilterElementInputControl extends FormGroup {
  instance: FilterElementInput;
  field: EditableFlexField;
  fieldDescription: FieldDescription;

  controls: {
    settings: FormControl;
    label_additional: FormControl;
    tooltip: FormControl;
    card_wrap: FormControl;

    element_styles: FieldElementStylesControl;
  };

  constructor(private injector: Injector) {
    super({
      settings: new FormControl(undefined),
      label_additional: new FormControl(''),
      tooltip: new FormControl(''),
      card_wrap: new FormControl(true),

      element_styles: new FieldElementStylesControl(injector)
    });
  }

  serialize(): FilterElementInput {
    const result = cloneDeep(this.instance) || new FilterElementInput();

    result.name = this.field.name;
    result.settings = this.controls.settings.value;
    result.labelAdditional = this.controls.label_additional.value;
    result.tooltip = isSet(this.controls.tooltip.value) ? this.controls.tooltip.value.trim() : undefined;
    result.cardWrap = this.controls.card_wrap.value;

    const elementStyles = this.controls.element_styles.serialize();
    applyFieldElementStyles(result, elementStyles);

    return result;
  }
}

export class CustomizeBarFilterElementInputArray extends FormArray {
  controls: CustomizeBarFilterElementInputControl[];

  serialize(): FilterElementInput[] {
    return this.controls.map(item => item.serialize());
  }

  set(controls: CustomizeBarFilterElementInputControl[]) {
    range(this.controls.length).forEach(() => this.removeAt(0));
    controls.forEach(item => this.push(item));
    this.updateValueAndValidity();
  }

  append(control: CustomizeBarFilterElementInputControl) {
    this.push(control);
    this.updateValueAndValidity();
  }

  remove(control: CustomizeBarFilterElementInputControl) {
    const index = this.controls.findIndex(item => item === control);

    if (index == -1) {
      return;
    }

    this.removeAt(index);
    this.updateValueAndValidity();
  }
}

@Injectable()
export class CustomizeBarFilterEditForm extends FormGroup implements OnDestroy {
  element: FilterElementItem;
  context: ViewContext;
  inputFieldProvider = new InputFieldProvider();

  controls: {
    name: FormControl;
    element: FormControl;
    element_inputs: CustomizeBarFilterElementInputArray;
    style: FormControl;
    visible_input: FieldInputControl;
    margin: MarginControl;
  };

  styleOptions = [
    {
      value: FilterStyle.Background,
      name: 'Background',
      image: 'filter-style-background'
    },
    {
      value: FilterStyle.Wrap,
      name: 'Wrap',
      image: 'filter-style-wrap'
    }
  ];

  constructor(
    private customizeService: CustomizeService,
    private elementConfigurationService: ElementConfigurationService,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private modelDescriptionStore: ModelDescriptionStore,
    private injector: Injector
  ) {
    super({
      url: new FieldInputControl({ path: ['value'] }, { validators: Validators.required, updateOn: 'blur' }),
      name: new FormControl(''),
      element: new FormControl(''),
      element_inputs: new CustomizeBarFilterElementInputArray([]),
      style: new FormControl(FilterStyle.Wrap),
      visible_input: new FieldInputControl({ path: ['value'] }),
      margin: new MarginControl()
    });
  }

  ngOnDestroy(): void {
    this.inputFieldProvider.clearProvider();
  }

  init(element: FilterElementItem, viewContext: ViewContext, firstInit = false) {
    this.element = element;
    this.context = viewContext;

    this.controls.name.patchValue(element.name ? element.name : 'Filter');
    this.controls.element.patchValue(element.elements[0]);
    this.controls.style.patchValue(element.style);
    this.controls.visible_input.patchValue(element.visibleInput ? element.visibleInput.serializeWithoutPath() : {});
    this.controls.margin.patchValue(element.margin);

    this.filterElement$()
      .pipe(
        switchMap(filterElement => {
          const dataSource =
            filterElement && filterElement.layouts[0] ? filterElement.layouts[0].dataSource : undefined;
          const resource = dataSource
            ? this.currentEnvironmentStore.resources.find(item => item.uniqueName == dataSource.queryResource)
            : undefined;
          const modelId =
            dataSource && dataSource.query && dataSource.query.simpleQuery
              ? [dataSource.queryResource, dataSource.query.simpleQuery.model].join('.')
              : undefined;

          return combineLatest(of(dataSource), of(resource), this.modelDescriptionStore.getDetailFirst(modelId));
        }),
        untilDestroyed(this)
      )
      .subscribe(([dataSource, resource, modelDescription]) => {
        const provider = dataSource
          ? listModelDescriptionInputFieldProvider(
              dataSource.type,
              resource,
              modelDescription,
              dataSource.queryParameters,
              dataSource.query,
              dataSource.columns
            )
          : [];

        this.inputFieldProvider.setProvider(provider, true);
      });

    combineLatest(this.filterElement$(), this.inputFieldProvider.getFields$())
      .pipe(
        map(([listElement, fields]) => {
          if (!listElement || !listElement.layouts[0] || !listElement.layouts[0].dataSource) {
            return [];
          }

          return listElement.layouts[0].dataSource.queryInputs
            .map(input => {
              return {
                input: input,
                field: fields.find(item => input.getName() == item.name)
              };
            })
            .filter(item => item.field && item.input.contextValueStartsWith(['elements', this.element.uid]))
            .map(item => item.field);
        }),
        untilDestroyed(this)
      )
      .subscribe(fields => {
        const controls = fields
          .map(field => {
            let control = this.controls.element_inputs.controls.find(item => item.field.name == field.name);
            const controlIndex = element.elementInputs.findIndex(item => item.name == field.name);

            if (!control) {
              const settings = element.elementInputs.find(item => item.name == field.name);

              control = new CustomizeBarFilterElementInputControl(this.injector);
              control.field = field;
              control.fieldDescription = getFieldDescriptionByType(field.field);
              control.controls.settings.patchValue(settings ? settings.settings : undefined);
              control.controls.label_additional.patchValue(settings ? settings.labelAdditional : undefined);
              control.controls.tooltip.patchValue(settings ? settings.tooltip : '');
              control.controls.card_wrap.patchValue(settings ? settings.cardWrap : true);

              const styles = settings ? getFieldElementStyles(settings) : undefined;
              control.controls.element_styles.deserialize(styles);
            }

            return {
              control: control,
              index: controlIndex
            };
          })
          .sort((lhs, rhs) => {
            if (lhs.index !== -1 && rhs.index !== -1) {
              return lhs.index - rhs.index;
            } else if (lhs.index !== -1 && rhs.index === -1) {
              return -1;
            } else if (lhs.index === -1 && rhs.index !== -1) {
              return 1;
            } else {
              return 0;
            }
          })
          .map(item => item.control);

        this.controls.element_inputs.set(controls);
      });

    if (!firstInit) {
      this.markAsDirty();
    } else {
      this.markAsPristine();
    }
  }

  isConfigured(instance: FilterElementItem): boolean {
    return this.elementConfigurationService.isFilterConfigured(instance);
  }

  filterElement$(): Observable<ListElementItem> {
    return combineLatest(controlValue(this.controls.element), this.context.elements$).pipe(
      switchMap(([uniqueName]) => {
        const contextElement = this.context.getElement(uniqueName);
        const element =
          contextElement && contextElement.element instanceof ListElementItem ? contextElement.element : undefined;

        if (!element) {
          return of({
            element: undefined,
            serialized: undefined
          });
        }

        return merge(of({}), this.customizeService.changed$).pipe(
          map(() => {
            return {
              element: element,
              serialized: element ? element.serialize() : undefined
            };
          })
        );
      }),
      distinctUntilChanged((lhs, rhs) => isEqual(lhs.serialized, rhs.serialized)),
      map(item => item.element)
    );
  }

  submit(): FilterElementItem {
    const instance = cloneDeep(this.element) as FilterElementItem;

    instance.name = this.controls.name.value;
    instance.elements = isSet(this.controls.element.value) ? [this.controls.element.value] : [];
    instance.elementInputs = this.controls.element_inputs.serialize();
    instance.style = this.controls.style.value;
    instance.margin = this.controls.margin.value;

    if (this.controls.visible_input.value) {
      instance.visibleInput = new Input().deserialize(this.controls.visible_input.value);
    } else {
      instance.visibleInput = undefined;
    }

    return instance;
  }
}
