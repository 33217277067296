var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Injector } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, of } from 'rxjs';
import { debounceTime, delay, switchMap, tap } from 'rxjs/operators';
import { NotificationService } from '@common/notifications';
import { ActionService } from '@modules/action-queries';
import { CustomView, CustomViewService, CustomViewsStore } from '@modules/custom-views';
import { MapLocationStorage } from '@modules/customize';
import { ElementConfigurationService } from '@modules/customize-configuration';
import { Input, Input as FieldInput } from '@modules/fields';
import { ModelOptionsSource } from '@modules/filters-components';
import { ModelDescriptionStore } from '@modules/model-queries';
import { FieldInputControl } from '@modules/parameters';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { ResourceControllerService } from '@modules/resources';
import { controlValue, isSet } from '@shared';
import { ActionOutputFormGroup } from '../../../forms/action-output.form-group';
import { FieldActionsArray } from '../../display-fields-edit/field-actions.array';
import { ListModelDescriptionDataSourceControl } from '../../model-description-data-source-edit/list-model-description-data-source';
import { OptionEnabledArray } from '../../option-enabled-edit/option-enabled.array';
import { ListElementStylesControl } from '../../styles-list-element-edit/list-element-styles.control';
import { CustomizeBarListLayoutSettingsForm, validateAction, validateActions } from '../customize-bar-list-layout-settings/customize-bar-list-layout-settings.form';
export var validateMapLocationField = function (control) {
    var parent = control.parent;
    if (!parent) {
        return;
    }
    var storage = parent.controls.map_location_storage.value;
    if ([MapLocationStorage.PostgreSQL, MapLocationStorage.Object].includes(storage)) {
        if (!isSet(control.value)) {
            return { required: true };
        }
    }
};
export var validateMapTwoFieldsStorage = function (control) {
    var parent = control.parent;
    if (!parent) {
        return;
    }
    var storage = parent.controls.map_location_storage.value;
    if ([MapLocationStorage.TwoFields].includes(storage)) {
        if (!isSet(control.value)) {
            return { required: true };
        }
    }
};
var CustomizeBarMapSettingsForm = /** @class */ (function (_super) {
    __extends(CustomizeBarMapSettingsForm, _super);
    function CustomizeBarMapSettingsForm(modelOptionsSource, customViewService, customViewsStore, notificationService, injector, currentProjectStore, currentEnvironmentStore, resourceControllerService, modelDescriptionStore, actionService, elementConfigurationService, dataSourceControl) {
        var _this = _super.call(this, currentProjectStore, currentEnvironmentStore, resourceControllerService, modelDescriptionStore, actionService, elementConfigurationService, {
            header: new FormControl(true),
            title: new FieldInputControl({ path: ['value'] }),
            map_location_storage: new FormControl('', Validators.required),
            map_location_field: new FormControl('', validateMapLocationField),
            map_location_latitude_field: new FormControl('', validateMapTwoFieldsStorage),
            map_location_longitude_field: new FormControl('', validateMapTwoFieldsStorage),
            marker_color: new FormControl(''),
            marker_color_input_enabled: new FormControl(false),
            marker_color_input: new FieldInputControl({ path: ['value'] }),
            location_input: new FieldInputControl({ path: ['value'] }),
            zoom_input: new FieldInputControl({ path: ['value'] }),
            marker_size: new FormControl(26),
            marker_size_input_enabled: new FormControl(false),
            marker_size_input: new FieldInputControl({ path: ['value'] }),
            markers_fit_on_change: new FormControl(false),
            data_source: dataSourceControl,
            card_view_unique_name: new FormControl(null),
            card_view_mappings: new FormControl([]),
            search_enabled: new FormControl(true),
            search_live: new FormControl(true),
            card_click_action: new FormControl(undefined, undefined, validateAction),
            actions: new FormControl([], undefined, validateActions),
            model_actions: new FormControl([], undefined, validateActions),
            columns_actions: new FieldActionsArray([]),
            custom_actions: new ActionOutputFormGroup(elementConfigurationService),
            sorting_field: new FormControl(undefined),
            sorting_asc: new FormControl(true),
            per_page: new FormControl(undefined),
            display_filters: new FormControl(true),
            filter_fields: new OptionEnabledArray([]),
            visible_input: new FieldInputControl({ path: ['value'] }),
            name: new FormControl(''),
            tooltip: new FormControl(''),
            card_wrap: new FormControl(true),
            element_styles: new ListElementStylesControl(injector)
        }) || this;
        _this.modelOptionsSource = modelOptionsSource;
        _this.customViewService = customViewService;
        _this.customViewsStore = customViewsStore;
        _this.notificationService = notificationService;
        _this.injector = injector;
        _this.locationStorageOptions = [
            {
                name: 'Latitude/Longitude fields',
                value: MapLocationStorage.TwoFields
            },
            {
                name: 'Latitude & Longitude object',
                value: MapLocationStorage.Object
            },
            {
                name: 'PostgreSQL format',
                value: MapLocationStorage.PostgreSQL
            }
        ];
        dataSourceControl.setRequired(true);
        _this.controls.map_location_storage.valueChanges.pipe(delay(0)).subscribe(function () {
            _this.controls.map_location_field.updateValueAndValidity();
            _this.controls.map_location_latitude_field.updateValueAndValidity();
            _this.controls.map_location_longitude_field.updateValueAndValidity();
        });
        return _this;
    }
    CustomizeBarMapSettingsForm.prototype.init = function (options) {
        var _this = this;
        this.settings = options.settings;
        this.pageUid = options.pageUid;
        this.elementUid = options.elementUid;
        this.options = options;
        var customView$ = isSet(options.settings.cardCustomView)
            ? this.customViewsStore.getDetailFirst(options.settings.cardCustomView)
            : of(undefined);
        return customView$.pipe(tap(function (customView) {
            var value = {
                header: options.settings.header,
                title: options.settings.titleInput ? options.settings.titleInput.serializeWithoutPath() : {},
                card_view_unique_name: options.settings.cardCustomView,
                card_view_mappings: options.settings.cardCustomViewMappings,
                map_location_storage: options.settings.locationStorage,
                map_location_field: options.settings.locationField,
                map_location_latitude_field: options.settings.locationLatitudeField,
                map_location_longitude_field: options.settings.locationLongitudeField,
                location_input: options.settings.locationInput ? options.settings.locationInput.serializeWithoutPath() : {},
                zoom_input: options.settings.zoomInput ? options.settings.zoomInput.serializeWithoutPath() : {},
                marker_color: options.settings.markerColor,
                marker_color_input_enabled: !!options.settings.markerColorInput,
                marker_color_input: options.settings.markerColorInput
                    ? options.settings.markerColorInput.serializeWithoutPath()
                    : {},
                marker_size: options.settings.markerSize || 26,
                marker_size_input_enabled: !!options.settings.markerSizeInput,
                marker_size_input: options.settings.markerSizeInput
                    ? options.settings.markerSizeInput.serializeWithoutPath()
                    : {},
                markers_fit_on_change: options.settings.markersFitOnChange,
                search_enabled: options.settings.searchEnabled,
                search_live: options.settings.searchLive,
                card_click_action: options.settings.cardClickAction,
                actions: options.settings.actions,
                model_actions: options.settings.modelActions,
                columns_actions: options.settings.columnActions,
                sorting_field: options.settings.sortingField || null,
                sorting_asc: options.settings.sortingAsc,
                per_page: options.settings.perPage,
                display_filters: options.settings.displayFilters,
                filter_fields: options.settings.filterFields.map(function (item) {
                    return {
                        name: item.name,
                        enabled: true
                    };
                }),
                visible_input: options.visibleInput ? options.visibleInput.serializeWithoutPath() : {},
                tooltip: options.settings.tooltip
            };
            if (options.nameEditable) {
                value['name'] = options.name;
            }
            _this.patchValue(value, { emitEvent: false });
            _this.controls.data_source.deserialize(options.settings.dataSource);
            _this.controls.custom_actions.deserialize(customView ? customView.actions : [], options.settings.customActions);
            if (options.cardWrapEditable) {
                _this.controls.card_wrap.patchValue(options.cardWrap);
            }
            if (options.elementStylesEditable && options.elementStyles) {
                _this.controls.element_styles.deserialize(options.elementStyles);
            }
            _this.trackChanges(options);
            _this.initPerPageClean(_this.controls.per_page);
            if (!options.firstInit) {
                _this.markAsDirty();
            }
        }));
    };
    CustomizeBarMapSettingsForm.prototype.trackChanges = function (options) {
        var _this = this;
        _super.prototype.trackChanges.call(this, options);
        combineLatest(controlValue(this.controls.data_source.controls.columns), this.controls.data_source.getModelDescription$(), this.controls.data_source.getModelDescription$().pipe(switchMap(function (modelDescription) {
            if (!modelDescription) {
                return of([]);
            }
            return _this.modelOptionsSource.getOptions$(modelDescription, {
                relationsEnabled: true
            });
        })))
            .pipe(debounceTime(60), untilDestroyed(this))
            .subscribe(function (_a) {
            var columns = _a[0], modelDescription = _a[1], modelOptions = _a[2];
            var columnNames = modelDescription ? modelOptions.map(function (item) { return item.name; }) : columns.map(function (item) { return item.name; });
            var modelId = modelDescription ? modelDescription.modelId : null;
            var filterFieldsSourceChanged = _this.controls.filter_fields.isSourceSet()
                ? !_this.controls.filter_fields.isSource(modelId)
                : false;
            _this.controls.filter_fields.syncControls(columnNames, { source: modelId });
            if (_this.controls.display_filters.value && _this.controls.filter_fields.isAllDisabled()) {
                _this.controls.filter_fields.enableDefault();
            }
            else if (_this.controls.display_filters.value && filterFieldsSourceChanged) {
                _this.controls.filter_fields.enableDefault();
            }
        });
    };
    CustomizeBarMapSettingsForm.prototype.getCardCustomView$ = function () {
        var _this = this;
        return controlValue(this.controls.card_view_unique_name).pipe(switchMap(function (customView) {
            return isSet(customView) ? _this.customViewsStore.getDetail(customView) : of(undefined);
        }));
    };
    CustomizeBarMapSettingsForm.prototype.getCustomViewDefaults = function () {
        return {
            uniqueName: CustomView.generateUniqueName(),
            pageUid: this.pageUid,
            elementUid: this.elementUid
        };
    };
    CustomizeBarMapSettingsForm.prototype.isConfigured = function (instance) {
        return this.elementConfigurationService.isListMapConfigured(instance, { restrictDemo: true });
    };
    CustomizeBarMapSettingsForm.prototype.submit = function () {
        var instance = cloneDeep(this.settings);
        instance.locationStorage = this.controls.map_location_storage.value;
        instance.locationField = this.controls.map_location_field.value;
        instance.locationLatitudeField = this.controls.map_location_latitude_field.value;
        instance.locationLongitudeField = this.controls.map_location_longitude_field.value;
        instance.locationInput = this.controls.location_input.value
            ? new Input().deserialize(this.controls.location_input.value)
            : undefined;
        instance.zoomInput = this.controls.zoom_input.value
            ? new Input().deserialize(this.controls.zoom_input.value)
            : undefined;
        if (this.controls.marker_color_input_enabled.value) {
            instance.markerColor = undefined;
            instance.markerColorInput = this.controls.marker_color_input.value
                ? new Input().deserialize(this.controls.marker_color_input.value)
                : undefined;
        }
        else {
            instance.markerColor = this.controls.marker_color.value;
            instance.markerColorInput = undefined;
        }
        if (this.controls.marker_size_input_enabled.value) {
            instance.markerSize = undefined;
            instance.markerSizeInput = this.controls.marker_size_input.value
                ? new Input().deserialize(this.controls.marker_size_input.value)
                : undefined;
        }
        else {
            instance.markerSize = this.controls.marker_size.value;
            instance.markerSizeInput = undefined;
        }
        instance.markersFitOnChange = this.controls.markers_fit_on_change.value;
        instance.titleInput = this.controls.title ? new FieldInput().deserialize(this.controls.title.value) : undefined;
        instance.dataSource = this.controls.data_source.serialize();
        instance.cardCustomView = this.controls.card_view_unique_name.value;
        instance.cardCustomViewMappings = this.controls.card_view_mappings.value;
        instance.searchEnabled = this.controls.search_enabled.value;
        instance.searchLive = this.controls.search_live.value;
        if (this.controls.card_click_action.value) {
            instance.cardClickAction = this.controls.card_click_action.value;
        }
        else {
            instance.cardClickAction = undefined;
        }
        instance.actions = this.controls.actions.value;
        instance.modelActions = this.controls.model_actions.value;
        instance.columnActions = this.controls.columns_actions.value;
        instance.customActions = this.controls.custom_actions.serialize();
        if (isSet(this.controls.sorting_field.value)) {
            instance.sortingField = this.controls.sorting_field.value;
        }
        else {
            instance.sortingField = undefined;
        }
        instance.sortingAsc = this.controls.sorting_asc.value;
        instance.perPage = this.controls.per_page.value;
        instance.filterFields = this.controls.filter_fields.value
            .filter(function (item) { return item.enabled; })
            .map(function (item) {
            return {
                name: item.name
            };
        });
        instance.displayFilters = instance.filterFields.length && this.controls.display_filters.value;
        instance.header =
            (instance.titleInput && instance.titleInput.isSet()) ||
                !!instance.searchEnabled ||
                !!instance.displayFilters ||
                (instance.actions && instance.actions.length > 0);
        instance.tooltip = isSet(this.controls.tooltip.value) ? this.controls.tooltip.value.trim() : undefined;
        return __assign({ settings: instance, visibleInput: this.controls.visible_input.value
                ? new Input().deserialize(this.controls.visible_input.value)
                : undefined, name: this.controls.name.value }, (this.options.cardWrapEditable && {
            cardWrap: this.controls.card_wrap.value
        }), (this.options.elementStylesEditable && {
            elementStyles: this.controls.element_styles.serialize()
        }));
    };
    return CustomizeBarMapSettingsForm;
}(CustomizeBarListLayoutSettingsForm));
export { CustomizeBarMapSettingsForm };
