import cloneDeep from 'lodash/cloneDeep';

import { ActionOutput, ParameterField } from '@modules/fields';
import { View } from '@modules/views';

import { CustomView, CustomViewFile, CustomViewSource } from './custom-view';

export interface CustomViewDataView {
  source: CustomViewSource.View;
  view?: View;
  name?: string;
  parameters?: ParameterField[];
  actions?: ActionOutput[];
  testParameters?: Record<string, unknown>;
}

export interface CustomViewDataHTML {
  source: CustomViewSource.HTML;
  html?: string;
  name?: string;
  parameters?: ParameterField[];
  testParameters?: Record<string, unknown>;
}

export interface CustomViewDataCustomElement {
  source: CustomViewSource.CustomElement;
  buildId?: string;
  tagName?: string;
  baseTagName?: string;
  filesJs?: CustomViewFile[];
  filesCss?: CustomViewFile[];
  name?: string;
  parameters?: ParameterField[];
  actions?: ActionOutput[];
  testParameters?: Record<string, unknown>;
}

export interface CustomViewData {
  source: CustomViewSource;
  view?: View;
  html?: string;
  buildId?: string;
  tagName?: string;
  baseTagName?: string;
  filesJs?: CustomViewFile[];
  filesCss?: CustomViewFile[];
  name?: string;
  parameters?: ParameterField[];
  actions?: ActionOutput[];
  testParameters?: Record<string, unknown>;
}

export function customViewInstanceToData(customView: CustomView): CustomViewData {
  return {
    source: customView.source,
    view: customView.view,
    html: customView.html,
    buildId: customView.buildId,
    tagName: customView.tagName,
    baseTagName: customView.baseTagName,
    filesJs: cloneDeep(customView.filesJs),
    filesCss: cloneDeep(customView.filesCss),
    name: customView.name,
    parameters: customView.parameters,
    actions: customView.actions,
    testParameters: customView.testParameters
  };
}

export function customViewDataToInstance(data: CustomViewData): CustomView {
  const customView = new CustomView();

  customView.name = data.name;
  customView.source = data.source;
  customView.parameters = data.parameters ? cloneDeep(data.parameters) : [];
  customView.actions = data.actions ? cloneDeep(data.actions) : [];
  customView.testParameters = data.testParameters ? cloneDeep(data.testParameters) : {};

  if (data.source == CustomViewSource.View) {
    customView.view = data.view;
  } else if (data.source == CustomViewSource.HTML) {
    customView.html = data.html;
  } else if (data.source == CustomViewSource.CustomElement) {
    customView.buildId = data.buildId;
    customView.tagName = data.tagName;
    customView.baseTagName = data.baseTagName;
    customView.filesJs = data.filesJs ? cloneDeep(data.filesJs) : [];
    customView.filesCss = data.filesCss ? cloneDeep(data.filesCss) : [];
  }

  return customView;
}
