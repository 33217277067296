import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, Optional } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, Observable } from 'rxjs';
import { delay, map, tap } from 'rxjs/operators';

import { copyTextToClipboard } from '@common/code';
import { NotificationService } from '@common/notifications';
import { BasePopupComponent } from '@common/popups';
import { AppConfigService } from '@core';
import { AnalyticsEvent, IntercomService, UniversalAnalyticsService } from '@modules/analytics';
import { ServerRequestError } from '@modules/api';
import { BooleanFieldStyle, Option } from '@modules/field-components';
import { NumberFieldType } from '@modules/fields';
import { ModelDescriptionService } from '@modules/model-queries';
import { databaseResourcesEngines, ProjectTokenService, ResourceDeploy, ResourceName } from '@modules/projects';
import { Region, RegionService } from '@modules/regions';
import { ResourceControllerService } from '@modules/resources';
import {
  capitalize,
  controlValue,
  errorToString,
  generateUUID,
  isSet,
  KeyboardEventKeyCode,
  readFileText
} from '@shared';

import { registerResourceSettingsComponent } from '../../../data/resource-settings-components';
import { BaseResourceSettingsComponent } from '../base-resource-settings/base-resource-settings.component';
import {
  DatabaseResourceParams,
  DatabasesResourceSettingsForm,
  DatabaseTableControl
} from './databases-resource-settings.form';

@Component({
  selector: 'app-databases-resource-settings',
  templateUrl: './databases-resource-settings.component.html',
  providers: [DatabasesResourceSettingsForm],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatabasesResourceSettingsComponent extends BaseResourceSettingsComponent implements OnInit, OnDestroy {
  jetBridgeDeploys = ResourceDeploy;
  deploysWithInstructions = [ResourceDeploy.Python, ResourceDeploy.Docker];
  resourceNames = ResourceName;
  customSettingsResourceNames: ResourceName[] = [
    ResourceName.BigQuery,
    ResourceName.Snowflake,
    ResourceName.Databricks,
    ResourceName.AmazonAthena,
    ResourceName.MongoDB
  ];
  SSLResourceNames: ResourceName[] = [ResourceName.MySQL, ResourceName.MariaDB];
  postgreSQLBasedResourceNames: ResourceName[] = [
    ResourceName.PostgreSQL,
    ResourceName.Redshift,
    ResourceName.AlloyDB,
    ResourceName.Supabase
  ];
  mongoDatabaseUrlEditing = false;
  mongoDatabaseUrlClean: string;
  numberFieldTypes = NumberFieldType;
  booleanFieldStyle = BooleanFieldStyle;
  installToken: string;
  testConnectionLoading = false;
  chooseTablesLoading = false;
  regionsEnabled = false;
  regions: Region[];
  regionsLoading = false;
  regionDelaysLoading = false;
  regionOptions: Option<string>[];
  currentRegion$: Observable<Region>;
  defaultPort: number;
  defaultSSHPort = 22;
  showPassword = false;
  searchControl = new FormControl('');
  filteredDatabaseTables: DatabaseTableControl[] = [];

  constructor(
    private modelDescriptionService: ModelDescriptionService,
    private regionService: RegionService,
    private notificationService: NotificationService,
    public form: DatabasesResourceSettingsForm,
    public appConfigService: AppConfigService,
    private resourceControllerService: ResourceControllerService,
    @Optional() popupComponent: BasePopupComponent,
    projectTokenService: ProjectTokenService,
    intercomService: IntercomService,
    analyticsService: UniversalAnalyticsService,
    cd: ChangeDetectorRef
  ) {
    super(form, popupComponent, projectTokenService, intercomService, analyticsService, cd);
  }

  ngOnInit() {
    this.regionsEnabled = this.appConfigService.jetBridgeRegions;
    super.ngOnInit();

    this.defaultPort = databaseResourcesEngines
      .filter(item => item.name == this.typeItem.name)
      .map(item => item.defaultPort)[0];

    const params = this.params as DatabaseResourceParams;
    this.installToken = this.form.jetBridgeForm.value['token'] || params.defaultToken || generateUUID();

    controlValue(this.form.deployDirectMongoForm.controls['database_url'])
      .pipe(untilDestroyed(this))
      .subscribe(databaseUrl => {
        this.mongoDatabaseUrlClean = isSet(databaseUrl)
          ? databaseUrl.replace(/^([\w+]+):\/\/([^:]+):[^@/]+@/, '$1://$2:********@')
          : undefined;
        this.cd.markForCheck();
      });

    controlValue(this.form.form.controls['deploy'])
      .pipe(
        map((value, i) => [value, i]),
        untilDestroyed(this)
      )
      .subscribe(([deploy, i]) => {
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.Resource.ResourceMethodSelected, {
          ResourceEditing: !!this.resource,
          ResourceTypeID: this.typeItem.name,
          MethodID: deploy,
          Source: this.analyticsSource,
          Initial: i == 0
        });
      });

    combineLatest(controlValue(this.form.databaseTables), controlValue(this.searchControl))
      .pipe(delay(0), untilDestroyed(this))
      .subscribe(([_, search]) => this.updateFilteredDatabaseTables(search));

    this.form.deployDirectBigQueryForm.valueChanges.pipe(untilDestroyed(this)).subscribe(() => this.cd.markForCheck());
  }

  onFormInit() {
    super.onFormInit();

    if (this.regionsEnabled) {
      this.updateRegions();
    }
  }

  updateRegions() {
    this.regionsLoading = true;
    this.cd.markForCheck();

    this.regionService
      .get()
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          this.regions = result;
          this.regionOptions = this.regions.map(item => {
            return {
              value: item.uid,
              name: item.name
            };
          });
          this.regionsLoading = false;
          this.currentRegion$ = controlValue(this.form.regionControl).pipe(
            map(value => {
              return value ? this.regions.find(item => item.uid == value) : undefined;
            })
          );
          this.cd.markForCheck();

          this.updateRegionDelays();
        },
        () => {
          this.regionsLoading = false;
          this.cd.markForCheck();
        }
      );
  }

  updateRegionDelays() {
    let defaultSet = false;

    this.regionDelaysLoading = true;
    this.cd.markForCheck();

    combineLatest(
      ...this.regions.map(region =>
        this.regionService.getLatency(region).pipe(
          tap(latency => {
            this.regionOptions = this.regionOptions.map(item => {
              if (item.value == region.uid && latency) {
                return {
                  value: item.value,
                  name: `${item.name} (${latency}ms delay)`
                };
              } else {
                return item;
              }
            });
            this.cd.markForCheck();

            if (!defaultSet) {
              defaultSet = true;

              this.form.setDefaultRegion(region);
            }
          })
        )
      )
    )
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          this.regionDelaysLoading = false;
          this.cd.markForCheck();

          if (!defaultSet) {
            defaultSet = true;

            const defaultRegion = this.regions.find(item => item.default);
            this.form.setDefaultRegion(defaultRegion);
          }
        },
        () => {
          this.regionDelaysLoading = false;
          this.cd.markForCheck();
        }
      );
  }

  setMongoDatabaseUrlEditing(value: boolean) {
    this.mongoDatabaseUrlEditing = value;
    this.cd.markForCheck();
  }

  isParamsStep() {
    return isSet(this.params['defaultToken']) || isSet(this.params['defaultApiBaseUrl']);
  }

  testConnection() {
    this.testConnectionLoading = true;
    this.cd.markForCheck();

    this.form
      .discoverConnection()
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          this.testConnectionLoading = false;
          this.cd.markForCheck();

          this.notificationService.success('Connected successfully!', 'Database settings are correct');
        },
        error => {
          this.testConnectionLoading = false;
          this.cd.markForCheck();

          if (error instanceof ServerRequestError && error.errors.length) {
            this.notificationService.error('Unable to connect', error.errors[0]);
          } else {
            this.notificationService.error('Unable to connect', errorToString(error));
          }
        }
      );
  }

  chooseTables() {
    this.chooseTablesLoading = true;
    this.cd.markForCheck();

    this.form
      .discoverTables()
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          this.form.form.patchValue({ choose_tables: true });
          this.form.databaseTables.init(result.tables, result.max_tables);
          this.chooseTablesLoading = false;
          this.cd.markForCheck();
        },
        error => {
          this.chooseTablesLoading = false;
          this.cd.markForCheck();

          if (error instanceof ServerRequestError && error.errors.length) {
            this.notificationService.error('Unable to connect', error.errors[0]);
          } else {
            this.notificationService.error('Unable to connect', errorToString(error));
          }
        }
      );
  }

  updateFilteredDatabaseTables(search = '') {
    if (!isSet(search)) {
      this.filteredDatabaseTables = this.form.databaseTables.controls;
      this.cd.markForCheck();
    }

    const searchClean = search.toLowerCase().trim();

    this.filteredDatabaseTables = this.form.databaseTables.controls.filter(control => {
      return control.controls.name.value.toLowerCase().includes(searchClean);
    });
    this.cd.markForCheck();
  }

  resetSearch() {
    this.searchControl.patchValue('');
    this.updateFilteredDatabaseTables('');
  }

  onSearchKey(e) {
    if (e.keyCode == KeyboardEventKeyCode.Escape) {
      this.resetSearch();
    }
  }

  copy(text: string, contentLabel?: string) {
    copyTextToClipboard(text)
      .pipe(untilDestroyed(this))
      .subscribe(success => {
        if (!success) {
          return;
        }

        const description = isSet(contentLabel) ? `${capitalize(contentLabel)} was copied to clipboard` : undefined;
        this.notificationService.info('Copied', description);
      });
  }

  setShowPassword(value: boolean) {
    this.showPassword = value;
    this.cd.markForCheck();
  }

  toggleShowPassword() {
    this.setShowPassword(!this.showPassword);
  }

  onFileChange(control: AbstractControl, el: HTMLInputElement) {
    if (!el.files.length) {
      return;
    }

    const file = el.files[0];

    el.value = null;

    readFileText(file)
      .pipe(untilDestroyed(this))
      .subscribe(content => {
        control.patchValue(content);
        control.markAsDirty();
      });
  }
}

registerResourceSettingsComponent(ResourceName.PostgreSQL, DatabasesResourceSettingsComponent);
registerResourceSettingsComponent(ResourceName.MySQL, DatabasesResourceSettingsComponent);
registerResourceSettingsComponent(ResourceName.MariaDB, DatabasesResourceSettingsComponent);
registerResourceSettingsComponent(ResourceName.MicrosoftSQL, DatabasesResourceSettingsComponent);
registerResourceSettingsComponent(ResourceName.Oracle, DatabasesResourceSettingsComponent);
registerResourceSettingsComponent(ResourceName.BigQuery, DatabasesResourceSettingsComponent);
registerResourceSettingsComponent(ResourceName.Snowflake, DatabasesResourceSettingsComponent);
registerResourceSettingsComponent(ResourceName.Redshift, DatabasesResourceSettingsComponent);
registerResourceSettingsComponent(ResourceName.Databricks, DatabasesResourceSettingsComponent);
registerResourceSettingsComponent(ResourceName.ClickHouse, DatabasesResourceSettingsComponent);
registerResourceSettingsComponent(ResourceName.AlloyDB, DatabasesResourceSettingsComponent);
registerResourceSettingsComponent(ResourceName.CockroachDB, DatabasesResourceSettingsComponent);
registerResourceSettingsComponent(ResourceName.AmazonAthena, DatabasesResourceSettingsComponent);
registerResourceSettingsComponent(ResourceName.Supabase, DatabasesResourceSettingsComponent);
registerResourceSettingsComponent(ResourceName.SQLite, DatabasesResourceSettingsComponent);
registerResourceSettingsComponent(ResourceName.MongoDB, DatabasesResourceSettingsComponent);
