var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import isArray from 'lodash/isArray';
import { combineLatest, of } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { ActionService } from '@modules/action-queries';
import { ActionType, DownloadActionType, ExportDataType, SegueType } from '@modules/actions';
import { CustomViewSource, CustomViewsStore, isCustomViewConfigured } from '@modules/custom-views';
import { ActionDropdownElementItem, ActionElementItem, ElementType, FormElementItem, ListElementItem, ModelElementItem, WidgetElementItem } from '@modules/customize';
import { WidgetType } from '@modules/dashboard';
import { DataSourceType } from '@modules/data-sources';
import { FieldType, Input, isRequiredInputsSet, OptionsType, ParameterField, validateValidatorParams } from '@modules/fields';
import { ListLayoutType } from '@modules/layouts';
import { ModelDescriptionStore } from '@modules/model-queries';
import { CurrentEnvironmentStore } from '@modules/projects';
import { ListModelDescriptionQuery } from '@modules/queries';
import { instanceOf, isSet } from '@shared';
var ElementConfigurationService = /** @class */ (function () {
    function ElementConfigurationService(actionService, customViewsStore, modelDescriptionStore, currentEnvironmentStore) {
        this.actionService = actionService;
        this.customViewsStore = customViewsStore;
        this.modelDescriptionStore = modelDescriptionStore;
        this.currentEnvironmentStore = currentEnvironmentStore;
    }
    ElementConfigurationService.prototype.isConfigured = function (element, options) {
        if (options === void 0) { options = {}; }
        if (element.type == ElementType.Action) {
            return this.isActionElementConfigured(element, options);
        }
        else if (element.type == ElementType.ActionGroup) {
            return this.isActionGroupConfigured(element, options);
        }
        else if (element.type == ElementType.ActionDropdown) {
            return this.isActionDropdownConfigured(element, options);
        }
        else if (element.type == ElementType.Back) {
            return this.isBackConfigured(element, options);
        }
        else if (element.type == ElementType.Steps) {
            return of(this.isStepsConfigured(element, options));
        }
        else if (element.type == ElementType.Model) {
            return of(this.isModelConfigured(element, options));
        }
        else if (element.type == ElementType.List) {
            return this.isListConfigured(element, options);
        }
        else if (element.type == ElementType.Widget) {
            return this.isWidgetConfigured(element, options);
        }
        else if (element.type == ElementType.Image) {
            return this.isImageConfigured(element, options);
        }
        else if (element.type == ElementType.IFrame) {
            return of(this.isIFrameConfigured(element, options));
        }
        else if (element.type == ElementType.QrCode) {
            return of(this.isQrCodeConfigured(element, options));
        }
        else if (element.type == ElementType.BarCode) {
            return of(this.isBarCodeConfigured(element, options));
        }
        else if (element.type == ElementType.Scanner) {
            return of(this.isScannerConfigured(element, options));
        }
        else if (element.type == ElementType.Alert) {
            return of(this.isAlertConfigured(element, options));
        }
        else if (element.type == ElementType.FileViewer) {
            return of(this.isFileViewerConfigured(element, options));
        }
        else if (element.type == ElementType.RangeSlider) {
            return of(this.isRangeSliderConfigured(element, options));
        }
        else if (element.type == ElementType.DateRange) {
            return of(this.isDateRangeConfigured(element, options));
        }
        else if (element.type == ElementType.Field) {
            return this.isFieldConfigured(element, options);
        }
        else if (element.type == ElementType.Custom) {
            return this.isCustomConfigured(element, options);
        }
        else if (element.type == ElementType.Form) {
            return this.isFormConfigured(element, options);
        }
        else {
            return of(true);
        }
    };
    ElementConfigurationService.prototype.isActionElementConfigured = function (element, options) {
        if (options === void 0) { options = {}; }
        return this.isActionConfigured(element.actionItem, options);
    };
    ElementConfigurationService.prototype.isActionGroupConfigured = function (element, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        if (!element.actions.length) {
            return of(false);
        }
        return combineLatest(element.actions.map(function (item) { return _this.isActionConfigured(item, options); })).pipe(map(function (result) { return result.every(function (item) { return item; }); }));
    };
    ElementConfigurationService.prototype.isActionDropdownConfigured = function (element, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        if (!element.actionItems.length) {
            return of(false);
        }
        return combineLatest(element.actionItems.map(function (item) { return _this.isActionConfigured(item, options); })).pipe(map(function (result) { return result.every(function (item) { return item; }); }));
    };
    ElementConfigurationService.prototype.isBackConfigured = function (element, options) {
        if (options === void 0) { options = {}; }
        if (!isSet(element.titleInput) || !element.titleInput.isSet()) {
            return of(false);
        }
        return this.isActionConfigured(element.previousPageAction, options);
    };
    ElementConfigurationService.prototype.isStepsConfigured = function (element, options) {
        if (options === void 0) { options = {}; }
        if (!isSet(element.currentItem) || !element.currentItem.isSet()) {
            return false;
        }
        return element.items.every(function (item) { return isSet(item.name); });
    };
    ElementConfigurationService.prototype.isFilterConfigured = function (element, options) {
        if (options === void 0) { options = {}; }
        return element.elements.length && element.elements.some(function (item) { return isSet(item); });
    };
    ElementConfigurationService.prototype.isModelConfigured = function (element, options) {
        if (options === void 0) { options = {}; }
        if (!isSet(element.dataSource)) {
            return false;
        }
        var result = [];
        result.push(isSet(element.dataSource) && element.dataSource.isConfigured());
        if (element.dataSource && element.dataSource.type == DataSourceType.Query && options.restrictDemo) {
            var resource = this.currentEnvironmentStore.resources.find(function (item) { return item.uniqueName == element.dataSource.queryResource; });
            result.push(isSet(resource) && !resource.demo);
        }
        return result.every(function (item) { return item; });
    };
    ElementConfigurationService.prototype.isListCalendarConfigured = function (instance, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        var obs = [];
        obs.push(of(isSet(instance.dataSource) && instance.dataSource.isConfigured()));
        if (instance.dataSource && instance.dataSource.type == DataSourceType.Query && options.restrictDemo) {
            var resource = this.currentEnvironmentStore.resources.find(function (item) { return item.uniqueName == instance.dataSource.queryResource; });
            obs.push(of(isSet(resource) && !resource.demo));
        }
        if (instance.cardClickAction) {
            obs.push(this.isActionConfigured(instance.cardClickAction, options));
        }
        instance.actions.forEach(function (item) {
            obs.push(_this.isActionConfigured(item, options));
        });
        instance.modelActions.forEach(function (item) {
            obs.push(_this.isActionConfigured(item, options));
        });
        if (!obs.length) {
            return of(true);
        }
        return combineLatest(obs).pipe(map(function (result) { return result.every(function (item) { return item; }); }));
    };
    ElementConfigurationService.prototype.isListGridConfigured = function (instance, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        var obs = [];
        obs.push(of(isSet(instance.dataSource) && instance.dataSource.isConfigured()));
        if (instance.dataSource && instance.dataSource.type == DataSourceType.Query && options.restrictDemo) {
            var resource = this.currentEnvironmentStore.resources.find(function (item) { return item.uniqueName == instance.dataSource.queryResource; });
            obs.push(of(isSet(resource) && !resource.demo));
        }
        if (instance.cardClickAction) {
            obs.push(this.isActionConfigured(instance.cardClickAction, options));
        }
        instance.actions.forEach(function (item) {
            obs.push(_this.isActionConfigured(item, options));
        });
        instance.modelActions.forEach(function (item) {
            obs.push(_this.isActionConfigured(item, options));
        });
        if (!obs.length) {
            return of(true);
        }
        return combineLatest(obs).pipe(map(function (result) { return result.every(function (item) { return item; }); }));
    };
    ElementConfigurationService.prototype.isListCarouselConfigured = function (instance, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        var obs = [];
        obs.push(of(isSet(instance.dataSource) && instance.dataSource.isConfigured()));
        if (instance.dataSource && instance.dataSource.type == DataSourceType.Query && options.restrictDemo) {
            var resource = this.currentEnvironmentStore.resources.find(function (item) { return item.uniqueName == instance.dataSource.queryResource; });
            obs.push(of(isSet(resource) && !resource.demo));
        }
        if (instance.cardClickAction) {
            obs.push(this.isActionConfigured(instance.cardClickAction, options));
        }
        instance.actions.forEach(function (item) {
            obs.push(_this.isActionConfigured(item, options));
        });
        instance.modelActions.forEach(function (item) {
            obs.push(_this.isActionConfigured(item, options));
        });
        if (!obs.length) {
            return of(true);
        }
        return combineLatest(obs).pipe(map(function (result) { return result.every(function (item) { return item; }); }));
    };
    ElementConfigurationService.prototype.isListKanbanConfigured = function (instance, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        var obs = [];
        obs.push(of(isSet(instance.dataSource) && instance.dataSource.isConfigured()));
        if (instance.dataSource && instance.dataSource.type == DataSourceType.Query && options.restrictDemo) {
            var resource = this.currentEnvironmentStore.resources.find(function (item) { return item.uniqueName == instance.dataSource.queryResource; });
            obs.push(of(isSet(resource) && !resource.demo));
        }
        if (instance.cardClickAction) {
            obs.push(this.isActionConfigured(instance.cardClickAction, options));
        }
        if (instance.cardColumnChangeAction) {
            obs.push(this.isActionConfigured(instance.cardColumnChangeAction, options));
        }
        if (instance.cardOrderChangeAction) {
            obs.push(this.isActionConfigured(instance.cardOrderChangeAction, options));
        }
        instance.actions.forEach(function (item) {
            obs.push(_this.isActionConfigured(item, options));
        });
        instance.modelActions.forEach(function (item) {
            obs.push(_this.isActionConfigured(item, options));
        });
        if (!obs.length) {
            return of(true);
        }
        return combineLatest(obs).pipe(map(function (result) { return result.every(function (item) { return item; }); }));
    };
    ElementConfigurationService.prototype.isListMapConfigured = function (instance, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        var obs = [];
        obs.push(of(isSet(instance.dataSource) && instance.dataSource.isConfigured()));
        if (instance.dataSource && instance.dataSource.type == DataSourceType.Query && options.restrictDemo) {
            var resource = this.currentEnvironmentStore.resources.find(function (item) { return item.uniqueName == instance.dataSource.queryResource; });
            obs.push(of(isSet(resource) && !resource.demo));
        }
        if (instance.cardClickAction) {
            obs.push(this.isActionConfigured(instance.cardClickAction, options));
        }
        instance.actions.forEach(function (item) {
            obs.push(_this.isActionConfigured(item, options));
        });
        instance.modelActions.forEach(function (item) {
            obs.push(_this.isActionConfigured(item, options));
        });
        if (!obs.length) {
            return of(true);
        }
        return combineLatest(obs).pipe(map(function (result) { return result.every(function (item) { return item; }); }));
    };
    ElementConfigurationService.prototype.isListTableConfigured = function (instance, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        var obs = [];
        obs.push(of(isSet(instance.dataSource) && instance.dataSource.isConfigured()));
        if (instance.dataSource && instance.dataSource.type == DataSourceType.Query && options.restrictDemo) {
            var resource = this.currentEnvironmentStore.resources.find(function (item) { return item.uniqueName == instance.dataSource.queryResource; });
            obs.push(of(isSet(resource) && !resource.demo));
        }
        if (instance.rowClickAction) {
            obs.push(this.isActionConfigured(instance.rowClickAction, options));
        }
        instance.actions.forEach(function (item) {
            obs.push(_this.isActionConfigured(item, options));
        });
        instance.rowActions.forEach(function (item) {
            obs.push(_this.isActionConfigured(item, options));
        });
        instance.modelActions.forEach(function (item) {
            obs.push(_this.isActionConfigured(item, options));
        });
        if (!obs.length) {
            return of(true);
        }
        return combineLatest(obs).pipe(map(function (result) { return result.every(function (item) { return item; }); }));
    };
    ElementConfigurationService.prototype.isListTimelineConfigured = function (instance, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        var obs = [];
        obs.push(of(isSet(instance.dataSource) && instance.dataSource.isConfigured()));
        if (instance.dataSource && instance.dataSource.type == DataSourceType.Query && options.restrictDemo) {
            var resource = this.currentEnvironmentStore.resources.find(function (item) { return item.uniqueName == instance.dataSource.queryResource; });
            obs.push(of(isSet(resource) && !resource.demo));
        }
        if (instance.cardClickAction) {
            obs.push(this.isActionConfigured(instance.cardClickAction, options));
        }
        instance.actions.forEach(function (item) {
            obs.push(_this.isActionConfigured(item, options));
        });
        instance.modelActions.forEach(function (item) {
            obs.push(_this.isActionConfigured(item, options));
        });
        if (!obs.length) {
            return of(true);
        }
        return combineLatest(obs).pipe(map(function (result) { return result.every(function (item) { return item; }); }));
    };
    ElementConfigurationService.prototype.isListConfigured = function (element, options) {
        if (options === void 0) { options = {}; }
        var layout = element.layouts[0];
        if (!layout) {
            return of(false);
        }
        if (layout.type == ListLayoutType.Table) {
            return this.isListTableConfigured(layout, options);
        }
        else if (layout.type == ListLayoutType.Map) {
            return this.isListMapConfigured(layout, options);
        }
        else if (layout.type == ListLayoutType.KanbanBoard) {
            return this.isListKanbanConfigured(layout, options);
        }
        else if (layout.type == ListLayoutType.Calendar) {
            return this.isListCalendarConfigured(layout, options);
        }
        else if (layout.type == ListLayoutType.Grid) {
            return this.isListGridConfigured(layout, options);
        }
        else if (layout.type == ListLayoutType.Carousel) {
            return this.isListCarouselConfigured(layout, options);
        }
        else if (layout.type == ListLayoutType.Timeline) {
            return this.isListTimelineConfigured(layout, options);
        }
        else {
            return of(false);
        }
    };
    ElementConfigurationService.prototype.isWidgetChartConfigured = function (instance, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        var obs = [];
        var datasets = instance.datasets;
        if (instance.datasetColumn) {
            datasets = datasets.slice(0, 1);
        }
        datasets.forEach(function (dataset) {
            obs.push(of(isSet(dataset.dataSource) && dataset.dataSource.isConfigured()));
            if (dataset.dataSource && dataset.dataSource.type == DataSourceType.Query && options.restrictDemo) {
                var resource = _this.currentEnvironmentStore.resources.find(function (item) { return item.uniqueName == dataset.dataSource.queryResource; });
                obs.push(of(isSet(resource) && !resource.demo));
            }
        });
        if (!obs.length) {
            return of(false);
        }
        return combineLatest(obs).pipe(map(function (result) { return result.every(function (item) { return item; }); }));
    };
    ElementConfigurationService.prototype.isWidgetValueConfigured = function (instance, options) {
        if (options === void 0) { options = {}; }
        var obs = [];
        obs.push(of(isSet(instance.dataSource) && instance.dataSource.isConfigured()));
        if (instance.dataSource && instance.dataSource.type == DataSourceType.Query && options.restrictDemo) {
            var resource = this.currentEnvironmentStore.resources.find(function (item) { return item.uniqueName == instance.dataSource.queryResource; });
            obs.push(of(isSet(resource) && !resource.demo));
        }
        if (instance.clickAction) {
            obs.push(this.isActionConfigured(instance.clickAction, options));
        }
        return combineLatest(obs).pipe(map(function (result) { return result.every(function (item) { return item; }); }));
        if (!obs.length) {
            return of(true);
        }
        return combineLatest(obs).pipe(map(function (result) { return result.every(function (item) { return item; }); }));
    };
    ElementConfigurationService.prototype.isWidgetConfigured = function (element, options) {
        if (options === void 0) { options = {}; }
        if (!element.widget) {
            return of(false);
        }
        if (element.widget.type == WidgetType.Chart) {
            return this.isWidgetChartConfigured(element.widget, options);
        }
        else if (element.widget.type == WidgetType.Value) {
            return this.isWidgetValueConfigured(element.widget, options);
        }
        else {
            return of(false);
        }
    };
    ElementConfigurationService.prototype.isImageConfigured = function (element, options) {
        if (options === void 0) { options = {}; }
        var obs = [
            of(isSet(element.storageResource) &&
                isSet(element.storageName) &&
                isSet(element.outputFormat) &&
                isSet(element.valueInput) &&
                element.valueInput.isSet())
        ];
        if (element.clickAction) {
            obs.push(this.isActionConfigured(element.clickAction, options));
        }
        return combineLatest(obs).pipe(map(function (result) { return result.every(function (item) { return item; }); }));
    };
    ElementConfigurationService.prototype.isIFrameConfigured = function (element, options) {
        if (options === void 0) { options = {}; }
        return isSet(element.url) && element.url.isSet();
    };
    ElementConfigurationService.prototype.isQrCodeConfigured = function (element, options) {
        if (options === void 0) { options = {}; }
        return isSet(element.value) && element.value.isSet();
    };
    ElementConfigurationService.prototype.isBarCodeConfigured = function (element, options) {
        if (options === void 0) { options = {}; }
        return isSet(element.value) && element.value.isSet();
    };
    ElementConfigurationService.prototype.isScannerConfigured = function (element, options) {
        if (options === void 0) { options = {}; }
        return true;
    };
    ElementConfigurationService.prototype.isAlertConfigured = function (element, options) {
        if (options === void 0) { options = {}; }
        return (isSet(element.title) && element.title.isSet()) || (isSet(element.message) && element.message.isSet());
    };
    ElementConfigurationService.prototype.isFileViewerConfigured = function (element, options) {
        if (options === void 0) { options = {}; }
        return isSet(element.url) && element.url.isSet();
    };
    ElementConfigurationService.prototype.isRangeSliderConfigured = function (element, options) {
        if (options === void 0) { options = {}; }
        return true;
    };
    ElementConfigurationService.prototype.isDateRangeConfigured = function (element, options) {
        if (options === void 0) { options = {}; }
        return true;
    };
    ElementConfigurationService.prototype.isColumnConfigured = function (instance, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        var obs = [of(isSet(instance.field))];
        if (options.editable) {
            var editableField = instance;
            obs.push(of(validateValidatorParams(editableField.validatorType, editableField.validatorParams)));
        }
        if (instance.field == FieldType.RelatedModel) {
            var modelId = instance.params && instance.params['related_model'] ? instance.params['related_model']['model'] : undefined;
            obs.push(this.modelDescriptionStore.getDetail(modelId).pipe(map(function (modelDescription) {
                if (!modelDescription) {
                    return false;
                }
                var resource = _this.currentEnvironmentStore.resources.find(function (item) { return item.uniqueName == modelDescription.resource; });
                return (isSet(resource) &&
                    (!options.restrictDemo || !resource.demo) &&
                    (isSet(modelDescription.primaryKeyField) || isSet(instance.params['custom_primary_key'])) &&
                    (isSet(modelDescription.displayField) ||
                        isSet(instance.params['custom_display_field']) ||
                        isSet(instance.params['custom_display_field_input'])));
            })));
        }
        else if ([FieldType.Select, FieldType.RadioButton, FieldType.MultipleSelect].includes(instance.field)) {
            if (instance.params && instance.params['options_type'] == OptionsType.Static) {
                if (!instance.params['create_enabled']) {
                    obs.push(of(isSet(instance.params['options']) &&
                        instance.params['options'].length > 0 &&
                        instance.params['options'].every(function (item) { return isSet(item['name']); })));
                }
            }
            else if (instance.params && instance.params['options_type'] == OptionsType.Query) {
                var resource = this.currentEnvironmentStore.resources.find(function (item) { return item.uniqueName == instance.params['resource']; });
                var query = instance.params['query']
                    ? new ListModelDescriptionQuery().deserialize(instance.params['query'])
                    : undefined;
                var parameters = instance.params['parameters']
                    ? instance.params['parameters'].map(function (item) { return new ParameterField().deserialize(item); })
                    : [];
                var inputs = instance.params['inputs']
                    ? instance.params['inputs'].map(function (item) { return new Input().deserialize(item); })
                    : [];
                obs.push(of(isSet(resource) &&
                    (!options.restrictDemo || !resource.demo) &&
                    query &&
                    query.isConfigured() &&
                    isSet(instance.params['value_field']) &&
                    (isSet(instance.params['label_field']) || isSet(instance.params['label_field_input'])) &&
                    isRequiredInputsSet(parameters, inputs)));
            }
            else {
                obs.push(of(false));
            }
        }
        if (!obs.length) {
            return of(true);
        }
        return combineLatest(obs).pipe(map(function (result) { return result.every(function (item) { return item; }); }));
    };
    ElementConfigurationService.prototype.isFieldConfigured = function (element, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        var obs = [];
        obs.push(this.isColumnConfigured(element.settings, __assign({ editable: true }, options)));
        element.elementActions.forEach(function (elementActions) {
            elementActions.actions.forEach(function (item) {
                obs.push(_this.isActionConfigured(item, options));
            });
        });
        element.onChangeActions.forEach(function (item) {
            obs.push(_this.isActionConfigured(item, options));
        });
        if (!obs.length) {
            return of(true);
        }
        return combineLatest(obs).pipe(map(function (result) { return result.every(function (item) { return item; }); }));
    };
    ElementConfigurationService.prototype.isCustomConfigured = function (element, options) {
        if (options === void 0) { options = {}; }
        return this.customViewsStore.getDetail(element.customView).pipe(map(function (customView) {
            var source = element.source || (customView ? customView.source : undefined) || CustomViewSource.View;
            var isInputsSet = function (parameters) { return isRequiredInputsSet(parameters, element.inputs); };
            return customView && isCustomViewConfigured(customView, source) && isInputsSet(customView.parameters);
        }));
    };
    ElementConfigurationService.prototype.isFormConfigured = function (element, options) {
        if (options === void 0) { options = {}; }
        var obs = [];
        obs.push(of(element.generated));
        if (element.getDataSource) {
            obs.push(of(element.getDataSource.isConfigured()));
        }
        if (element.getDataSource && element.getDataSource.type == DataSourceType.Query && options.restrictDemo) {
            var resource = this.currentEnvironmentStore.resources.find(function (item) { return item.uniqueName == element.getDataSource.queryResource; });
            obs.push(of(isSet(resource) && !resource.demo));
        }
        if (!obs.length) {
            return of(true);
        }
        obs.push(this.isActionConfigured(element.submitAction, options));
        return combineLatest(obs).pipe(map(function (result) { return result.every(function (item) { return item; }); }));
    };
    ElementConfigurationService.prototype.isActionConfigured = function (instance, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        return this.actionService.getActionDescription(instance).pipe(switchMap(function (actionDescription) {
            if (!actionDescription) {
                return of(false);
            }
            var isInputsSet = function () {
                return options.ignoreInputs || isRequiredInputsSet(actionDescription.actionParams, instance.inputs);
            };
            if (actionDescription.type == ActionType.Query ||
                (actionDescription.type == ActionType.Download &&
                    actionDescription.downloadAction &&
                    actionDescription.downloadAction.type == DownloadActionType.Query)) {
                var action = actionDescription.type == ActionType.Download
                    ? actionDescription.downloadAction
                    : actionDescription.queryAction;
                var resource = _this.currentEnvironmentStore.resources.find(function (item) { return item.uniqueName == actionDescription.resource; });
                return of(isSet(resource) &&
                    (!options.restrictDemo || !resource.demo) &&
                    action &&
                    isSet(action.query) &&
                    action.query.isConfigured() &&
                    isInputsSet());
            }
            else if (actionDescription.type == ActionType.Download &&
                actionDescription.downloadAction &&
                actionDescription.downloadAction.type == DownloadActionType.Input) {
                return of(actionDescription.downloadAction.input && actionDescription.downloadAction.input.isSet());
            }
            else if (actionDescription.type == ActionType.Link) {
                if (!actionDescription.linkAction) {
                    return of(false);
                }
                if (actionDescription.linkAction.type == SegueType.PreviousPage) {
                    return of(true);
                }
                else if (actionDescription.linkAction.type == SegueType.Page) {
                    return of(isSet(actionDescription.linkAction.page) && isInputsSet());
                }
                else if (actionDescription.linkAction.type == SegueType.ModelChange) {
                    return of(isSet(actionDescription.linkAction.model) && isInputsSet());
                }
                else {
                    return of(false);
                }
            }
            else if (actionDescription.type == ActionType.ExternalLink) {
                return of(isInputsSet());
            }
            else if (actionDescription.type == ActionType.ElementAction) {
                return of(isSet(actionDescription.elementAction) &&
                    isArray(actionDescription.elementAction) &&
                    !!actionDescription.elementAction.length);
            }
            else if (actionDescription.type == ActionType.ShowNotification) {
                return of(actionDescription.notificationAction &&
                    isSet(actionDescription.notificationAction.title) &&
                    actionDescription.notificationAction.title.isSet());
            }
            else if (actionDescription.type == ActionType.SetProperty) {
                return of(actionDescription.setPropertyAction &&
                    isSet(actionDescription.setPropertyAction.property) &&
                    isSet(actionDescription.setPropertyAction.value));
            }
            else if (actionDescription.type == ActionType.RunJavaScript) {
                return of(actionDescription.runJavaScriptAction && isSet(actionDescription.runJavaScriptAction.js));
            }
            else if (actionDescription.type == ActionType.CopyToClipboard) {
                return of(actionDescription.copyToClipboardAction &&
                    isSet(actionDescription.copyToClipboardAction.value) &&
                    actionDescription.copyToClipboardAction.value.isSet());
            }
            else if (actionDescription.type == ActionType.Export) {
                if (!actionDescription.exportAction) {
                    return of(false);
                }
                if (actionDescription.exportAction.dataType == ExportDataType.CurrentComponent) {
                    return of(true);
                }
                else {
                    return of(isSet(actionDescription.exportAction.dataSource) &&
                        actionDescription.exportAction.dataSource.isConfigured({ columnsOptional: true }));
                }
            }
            else if (actionDescription.type == ActionType.Import) {
                return of(actionDescription.importAction &&
                    isSet(actionDescription.importAction.resource) &&
                    isSet(actionDescription.importAction.model));
            }
            else if (actionDescription.type == ActionType.OpenPopup) {
                return of(actionDescription.openPopupAction && isSet(actionDescription.openPopupAction.popup));
            }
            else if (actionDescription.type == ActionType.ClosePopup) {
                return of(!!actionDescription.closePopupAction);
            }
            else if (actionDescription.type == ActionType.OpenActionMenu) {
                if (!actionDescription.openActionMenuAction || !actionDescription.openActionMenuAction.actions.length) {
                    return of(false);
                }
                return combineLatest(actionDescription.openActionMenuAction.actions.map(function (item) {
                    return _this.isActionConfigured(item, options);
                })).pipe(map(function (result) { return result.every(function (item) { return item; }); }));
            }
            else if (actionDescription.type == ActionType.ScanCode) {
                return of(true);
            }
            else if (actionDescription.type == ActionType.Workflow) {
                return of(!!actionDescription.workflowAction &&
                    !!actionDescription.workflowAction.workflow &&
                    actionDescription.workflowAction.workflow.steps.length > 0);
            }
            else {
                return of(false);
            }
        }));
    };
    ElementConfigurationService.prototype.getElementItemsMeta = function (elementItems) {
        var _this = this;
        var dataClasses = [ListElementItem, ModelElementItem, WidgetElementItem];
        var actionClasses = [ActionElementItem, ActionDropdownElementItem, FormElementItem];
        return (elementItems.length
            ? combineLatest(elementItems.map(function (item) {
                return _this.isConfigured(item, { restrictDemo: true }).pipe(map(function (configured) {
                    return {
                        element: item,
                        configured: configured
                    };
                }));
            }))
            : of([])).pipe(map(function (items) {
            var configured = items.filter(function (item) { return item.configured; });
            return {
                configured: configured.length,
                configuredModel: configured.filter(function (item) { return instanceOf(item.element, dataClasses); }).length,
                configuredAction: configured.filter(function (item) { return instanceOf(item.element, actionClasses); }).length
            };
        }), first());
    };
    return ElementConfigurationService;
}());
export { ElementConfigurationService };
