import { CdkConnectedOverlay, CdkOverlayOrigin } from '@angular/cdk/overlay';
import { AfterViewInit, ChangeDetectorRef, ElementRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { asyncScheduler, combineLatest } from 'rxjs';
import { distinctUntilChanged, map, throttleTime } from 'rxjs/operators';
import { CustomViewSource, CustomViewsStore } from '@modules/custom-views';
import { ascComparator, controlValue, isSet, KeyboardEventKeyCode, scrollTo } from '@shared';
var CustomViewDropdownComponent = /** @class */ (function () {
    function CustomViewDropdownComponent(customViewsStore, cd) {
        this.customViewsStore = customViewsStore;
        this.cd = cd;
        this.selectItem = new EventEmitter();
        this.customViews = [];
        this.customViewsFiltered = [];
        this.thumbWidth = 100;
        this.thumbHeight = 83;
        this.thumbPadding = 10;
        this.thumbMargin = 4;
        this.thumbsPerRow = 4;
        this.thumbsPerPage = this.thumbsPerRow * 4;
        this.searchControl = new FormControl('');
        this.dropdownOpened = false;
        this.popoverPositions = [
            {
                panelClass: ['overlay_position_left-top'],
                originX: 'start',
                overlayX: 'end',
                originY: 'top',
                overlayY: 'top',
                offsetY: -8,
                weight: 6
            },
            {
                panelClass: ['overlay_position_left-center'],
                originX: 'start',
                overlayX: 'end',
                originY: 'center',
                overlayY: 'center',
                weight: 5
            },
            {
                panelClass: ['overlay_position_left-bottom'],
                originX: 'start',
                overlayX: 'end',
                originY: 'bottom',
                overlayY: 'bottom',
                offsetY: 8,
                weight: 4
            },
            {
                panelClass: ['overlay_position_right-top'],
                originX: 'end',
                overlayX: 'start',
                originY: 'top',
                overlayY: 'top',
                offsetY: -8,
                weight: 3
            },
            {
                panelClass: ['overlay_position_right-center'],
                originX: 'end',
                overlayX: 'start',
                originY: 'center',
                overlayY: 'center',
                weight: 2
            },
            {
                panelClass: ['overlay_position_right-bottom'],
                originX: 'end',
                overlayX: 'start',
                originY: 'bottom',
                overlayY: 'bottom',
                offsetY: 8,
                weight: 1
            }
        ];
    }
    CustomViewDropdownComponent.prototype.trackCustomViewFn = function (i, item) {
        return item.uniqueName;
    };
    CustomViewDropdownComponent.prototype.ngOnInit = function () {
        var _this = this;
        combineLatest(this.customViewsStore.get(), controlValue(this.searchControl).pipe(map(function (search) { return search.toLowerCase().trim(); }), distinctUntilChanged(), throttleTime(100, asyncScheduler, { leading: true, trailing: true })))
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var customViews = _a[0], search = _a[1];
            _this.customViews = customViews
                .filter(function (item) { return item.shared && (!isSet(_this.source) || item.source == _this.source); })
                .sort(function (lhs, rhs) { return ascComparator(lhs.name.toLowerCase(), rhs.name.toLowerCase()); });
            _this.customViewsFiltered = isSet(search)
                ? _this.customViews.filter(function (item) { return item.name.toLowerCase().includes(search); })
                : _this.customViews;
            _this.cd.markForCheck();
        });
    };
    CustomViewDropdownComponent.prototype.ngOnDestroy = function () { };
    CustomViewDropdownComponent.prototype.ngAfterViewInit = function () {
        this.setPositionObserver();
    };
    CustomViewDropdownComponent.prototype.isOpened = function () {
        return this.dropdownOpened;
    };
    CustomViewDropdownComponent.prototype.open = function () {
        this.setDropdownOpened(true);
    };
    CustomViewDropdownComponent.prototype.close = function () {
        this.setDropdownOpened(false);
    };
    CustomViewDropdownComponent.prototype.setDropdownOpened = function (value) {
        this.dropdownOpened = value;
        this.cd.markForCheck();
    };
    CustomViewDropdownComponent.prototype.setPositionObserver = function () {
        var _this = this;
        if (this.popoverPositionsSubscription) {
            this.popoverPositionsSubscription.unsubscribe();
        }
        if (!this.cdkConnectedOverlay) {
            return;
        }
        this.popoverPositionsSubscription = this.cdkConnectedOverlay.positionChange
            .pipe(untilDestroyed(this))
            .subscribe(function (e) {
            var propsEqual = ['offsetX', 'offsetY', 'originX', 'originY', 'overlayX', 'overlayY'];
            var position = _this.popoverPositions.find(function (item) {
                return propsEqual.every(function (prop) { return (item[prop] || undefined) == e.connectionPair[prop]; });
            });
            var otherPosition = _this.popoverPositions.filter(function (item) { return item !== position; });
            if (position) {
                _this.cdkConnectedOverlay.overlayRef.addPanelClass(position.panelClass);
            }
            otherPosition.forEach(function (item) { return _this.cdkConnectedOverlay.overlayRef.removePanelClass(item.panelClass); });
        });
    };
    CustomViewDropdownComponent.prototype.clearSearch = function () {
        this.searchControl.patchValue('');
        this.onSearch();
    };
    CustomViewDropdownComponent.prototype.onSearch = function () {
        scrollTo(this.scrollableElement.nativeElement, 0);
    };
    CustomViewDropdownComponent.prototype.onSearchKey = function (e) {
        if (e.keyCode == KeyboardEventKeyCode.Escape) {
            this.clearSearch();
        }
    };
    return CustomViewDropdownComponent;
}());
export { CustomViewDropdownComponent };
