import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import clamp from 'lodash/clamp';
import isEqual from 'lodash/isEqual';
import pickBy from 'lodash/pickBy';
import range from 'lodash/range';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { fromEvent, merge, Observable, of, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';

import {
  CustomizeService,
  ElementType,
  FieldElementStyles,
  FilterElementInput,
  FilterElementItem,
  FilterStyle,
  getFieldElementStyles,
  ListElementItem,
  registerElementComponent,
  ViewContextElement
} from '@modules/customize';
import { BaseElementComponent } from '@modules/customize-elements';
import { EditableField, EditableFlexField } from '@modules/fields';
import { ModelDescriptionStore } from '@modules/model-queries';
import { InputFieldProviderItem, listModelDescriptionInputFieldProvider } from '@modules/parameters';
import { CurrentEnvironmentStore } from '@modules/projects';
import { addClass, isSet, MouseButton, removeClass, TypedChanges } from '@shared';

import { COLUMN_RESIZING_CLASS, StickPosition } from '../columns-layout/columns-layout.component';
import { CustomPagePopupComponent } from '../custom-page-popup/custom-page-popup.component';
import { FilterElementItemComponent } from './filter-element-item/filter-element-item.component';

interface ElementState {
  element?: string;
  elementInputs?: FilterElementInput[];
  name?: string;
}

function getElementStateList(state: ElementState): Object {
  return {
    element: state.element,
    elementInputs: state.elementInputs ? state.elementInputs.map(item => item.serialize()) : []
  };
}

function getElementStateName(state: ElementState): Object {
  return {
    name: state.name
  };
}

@Component({
  selector: 'app-filter-element',
  templateUrl: './filter-element.component.html',
  providers: [ViewContextElement],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterElementComponent extends BaseElementComponent<FilterElementItem>
  implements OnInit, OnDestroy, OnChanges {
  @ViewChild('columns_element') columnsElement: ElementRef;
  @ViewChildren('column_element') columnElements = new QueryList<ElementRef>();
  @ViewChildren(FilterElementItemComponent) itemComponents = new QueryList<FilterElementItemComponent>();

  state: ElementState = {};
  customizeEnabled$: Observable<boolean>;
  filterStyles = FilterStyle;

  filters: {
    field: EditableFlexField;
    labelAdditional?: string;
    cardWrap?: boolean;
    elementStyles?: FieldElementStyles;
    tooltip?: string;
    weight?: number;
  }[] = [];
  filtersSubscription: Subscription;

  draggingIndex: number;
  draggingSubscriptions: Subscription[] = [];
  stick: StickPosition;
  stickDistance = 8;
  wrapperPadding = 15;

  trackColumn = (() => {
    return (i, item: { field: EditableFlexField; weight?: number }) => {
      return isSet(item.field.name) ? `field_${item.field.name}` : i;
    };
  })();

  constructor(
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private modelDescriptionStore: ModelDescriptionStore,
    private customizeService: CustomizeService,
    public viewContextElement: ViewContextElement,
    @Optional() private popup: CustomPagePopupComponent,
    private cd: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    this.customizeEnabled$ = this.customizeService.enabled$.pipe(map(item => !!item));

    this.initContext();

    this.elementOnChange(this.element);
    this.trackChanges();
  }

  ngOnDestroy(): void {
    this.deinitHandles();
  }

  ngOnChanges(changes: TypedChanges<FilterElementComponent>): void {
    if (changes.element && !changes.element.firstChange) {
      this.viewContextElement.initInfo({ name: this.element.name, element: this.element }, true);
    }

    if (changes.element) {
      this.elementOnChange(this.element);
    }
  }

  trackChanges() {
    this.element$
      .pipe(
        map(element => this.getElementState(element)),
        untilDestroyed(this)
      )
      .subscribe(state => {
        this.onStateUpdated(state);
        this.state = state;
      });
  }

  getElementState(element: FilterElementItem): ElementState {
    return {
      element: element.elements[0],
      elementInputs: element.elementInputs,
      name: element.name
    };
  }

  onStateUpdated(state: ElementState) {
    if (!isEqual(getElementStateList(state), getElementStateList(this.state))) {
      this.initFilters(state);
    }

    if (!isEqual(getElementStateName(state), getElementStateName(this.state))) {
      this.updateContextInfo(state);
    }
  }

  initFilters(state: ElementState) {
    if (this.filtersSubscription) {
      this.filtersSubscription.unsubscribe();
      this.filtersSubscription = undefined;
    }

    this.filtersSubscription = this.filterElement$(state)
      .pipe(
        switchMap(element => {
          if (!element) {
            return of([]);
          }

          const dataSource = element.layouts[0] ? element.layouts[0].dataSource : undefined;
          const resource = this.currentEnvironmentStore.resources.find(
            item => item.uniqueName == dataSource.queryResource
          );
          const modelId =
            dataSource.query && dataSource.query.simpleQuery
              ? [dataSource.queryResource, dataSource.query.simpleQuery.model].join('.')
              : undefined;

          return this.modelDescriptionStore.getDetailFirst(modelId).pipe(
            map(modelDescription => {
              return listModelDescriptionInputFieldProvider(
                dataSource.type,
                resource,
                modelDescription,
                dataSource.queryParameters,
                dataSource.query,
                dataSource.columns
              );
            }),
            map((value: InputFieldProviderItem[]) => {
              const getFields = (items: InputFieldProviderItem[]): EditableField[] => {
                return items.reduce((prev, item) => {
                  if (item.field) {
                    prev.push(item.field);
                  }

                  if (item.children) {
                    prev.push(...getFields(item.children));
                  }

                  return prev;
                }, []);
              };

              return getFields(value);
            }),
            map((fields: EditableFlexField[]) => {
              return dataSource.queryInputs
                .map(input => {
                  return {
                    input: input,
                    field: fields.find(item => item.name == input.getName())
                  };
                })
                .filter(item => item.field && item.input.contextValueStartsWith(['elements', this.element.uid]))
                .map(item => item.field);
            }),
            map((fields: EditableFlexField[]) => {
              return fields
                .map(field => {
                  const elementInputIndex = state.elementInputs.findIndex(item => item.name == field.name);
                  const elementInput = elementInputIndex !== -1 ? state.elementInputs[elementInputIndex] : undefined;
                  const overrides =
                    elementInput && elementInput.settings
                      ? {
                          verboseName: elementInput.settings.verboseName,
                          params: elementInput.settings.params,
                          resetEnabled: elementInput.settings.resetEnabled,
                          placeholder: elementInput.settings.placeholder,
                          validatorType: elementInput.settings.validatorType,
                          validatorParams: elementInput.settings.validatorParams,
                          valueInput: elementInput.settings.valueInput
                        }
                      : {};

                  return {
                    field: {
                      ...field,
                      ...pickBy(overrides, (v, k) => isSet(v, true))
                    },
                    elementInput: elementInput,
                    elementInputIndex: elementInputIndex
                  };
                })
                .sort((lhs, rhs) => {
                  if (lhs.elementInputIndex !== -1 && rhs.elementInputIndex !== -1) {
                    return lhs.elementInputIndex - rhs.elementInputIndex;
                  } else if (lhs.elementInputIndex !== -1 && rhs.elementInputIndex === -1) {
                    return -1;
                  } else if (lhs.elementInputIndex === -1 && rhs.elementInputIndex !== -1) {
                    return 1;
                  } else {
                    return 0;
                  }
                })
                .map(filterItem => {
                  return {
                    field: filterItem.field,
                    labelAdditional: filterItem.elementInput ? filterItem.elementInput.labelAdditional : undefined,
                    cardWrap: filterItem.elementInput ? filterItem.elementInput.cardWrap : true,
                    elementStyles: filterItem.elementInput ? getFieldElementStyles(filterItem.elementInput) : undefined,
                    tooltip: filterItem.elementInput ? filterItem.elementInput.tooltip : undefined,
                    weight: filterItem.elementInput ? filterItem.elementInput.weight : undefined
                  };
                });
            })
          );
        }),
        untilDestroyed(this)
      )
      .subscribe(result => {
        this.filters = result;
        this.cd.markForCheck();
      });
  }

  filterElement$(state: ElementState): Observable<ListElementItem> {
    return this.context.elements$.pipe(
      switchMap(() => {
        const contextElement = this.context.getElement(state.element);
        const element =
          contextElement && contextElement.element instanceof ListElementItem ? contextElement.element : undefined;

        if (!element) {
          return of({
            element: undefined,
            serialized: undefined
          });
        }

        return merge(of({}), this.customizeService.changed$).pipe(
          map(() => {
            return {
              element: element,
              serialized: element ? element.serialize() : undefined
            };
          })
        );
      }),
      distinctUntilChanged((lhs, rhs) => isEqual(lhs.serialized, rhs.serialized)),
      map(item => item.element)
    );
  }

  clearFilters() {
    this.itemComponents.forEach(item => item.control.setValue(undefined));
  }

  initContext() {
    this.viewContextElement.initElement({
      uniqueName: this.element.uid,
      name: this.element.name,
      icon: 'windows',
      element: this.element,
      popup: this.popup ? this.popup.popup : undefined
    });

    this.viewContextElement.setActions([
      {
        uniqueName: 'clear_filters',
        name: 'Reset All filters',
        icon: 'delete',
        parameters: [],
        handler: () => this.clearFilters()
      }
    ]);
  }

  updateContextInfo(state: ElementState) {
    this.viewContextElement.initInfo({
      name: state.name
    });
  }

  getStickPositions(bounds: ClientRect) {
    return [1 / 2, 1 / 3, 1 / 4, 1 / 5, 1 / 6, 1 / 8]
      .filter(multiplier => bounds.width * multiplier >= 120)
      .reduce((acc, multiplier) => {
        const parts = 1 / multiplier;

        acc.push(
          ...range(parts)
            .slice(1)
            .map(i => {
              const pos = bounds.left + multiplier * bounds.width * i;

              return {
                label: `${i}/${parts}`,
                position: pos
              };
            })
        );
        return acc;
      }, []);
  }

  getFluidTotalWidth() {
    return this.columnElements.reduce((sum, current, i) => {
      return sum + current.nativeElement.getBoundingClientRect().width;
    }, 0);
  }

  onHandleDragStarted(e: MouseEvent, index: number) {
    event.preventDefault();
    event.stopPropagation();

    this.draggingIndex = index;
    this.cd.markForCheck();

    addClass(document.body, COLUMN_RESIZING_CLASS);

    this.deinitHandles();
    this.initHandles();
  }

  initHandles() {
    this.draggingSubscriptions.push(
      fromEvent<MouseEvent>(window.document, 'mousemove')
        .pipe(untilDestroyed(this))
        .subscribe(e => {
          const index = this.draggingIndex;
          const bounds = this.columnsElement.nativeElement.getBoundingClientRect();
          const lhs = this.columnElements.toArray()[index].nativeElement;
          const lhsBounds = lhs.getBoundingClientRect();
          const rhs = this.columnElements.toArray()[index + 1].nativeElement;
          const rhsBounds = rhs.getBoundingClientRect();
          const minWidth = 40;
          let position = clamp(
            e.clientX,
            lhsBounds.left + this.wrapperPadding + minWidth,
            rhsBounds.right - this.wrapperPadding - minWidth
          );
          const stickPosition = this.getStickPositions(bounds).find(item => {
            return position >= item.position - this.stickDistance && position <= item.position + this.stickDistance;
          });

          if (stickPosition) {
            this.stick = stickPosition;
            position = stickPosition.position;
          } else {
            this.stick = undefined;
          }

          const totalWidth = this.getFluidTotalWidth();
          const leftWidth = position - lhsBounds.left + this.wrapperPadding * 0.5;
          const rightWidth = rhsBounds.right - (position - this.wrapperPadding) - this.wrapperPadding * 0.5;
          const lhsWeight = leftWidth / totalWidth;
          const rhsWeight = rightWidth / totalWidth;

          this.columnElements.forEach((columnElement, i) => {
            let weight: number;

            if (i === index) {
              weight = lhsWeight;
            } else if (i === index + 1) {
              weight = rhsWeight;
            } else {
              weight = columnElement.nativeElement.getBoundingClientRect().width / totalWidth;
            }

            this.filters[i].weight = weight;

            const elementInput = this.element.elementInputs.find(item => item.name == this.filters[i].field.name);

            if (elementInput) {
              elementInput.weight = weight;
            }
          });

          this.cd.markForCheck();
        })
    );

    this.draggingSubscriptions.push(
      fromEvent<MouseEvent>(window.document, 'mouseup')
        .pipe(
          filter(e => e.button == MouseButton.Main),
          untilDestroyed(this)
        )
        .subscribe(() => {
          this.deinitHandles();

          this.draggingIndex = undefined;
          this.stick = undefined;
          removeClass(document.body, COLUMN_RESIZING_CLASS);
          this.customizeService.markChanged();
          this.cd.markForCheck();
        })
    );
  }

  deinitHandles() {
    this.draggingSubscriptions.forEach(item => item.unsubscribe());
    this.draggingSubscriptions = [];
  }
}

registerElementComponent({
  type: ElementType.Filter,
  component: FilterElementComponent,
  label: 'Image',
  actions: []
});
