import { Injectable, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { CustomView, CustomViewService, CustomViewSource, CustomViewsStore } from '@modules/custom-views';
import { CustomElementItem, MarginControl, ViewContext } from '@modules/customize';
import { ElementConfigurationService } from '@modules/customize-configuration';
import { Input, isRequiredInputsSet, ParameterArray, ParameterField } from '@modules/fields';
import { FieldInputControl, InputFieldProvider, parametersToProviderItems } from '@modules/parameters';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { controlValue, isSet } from '@shared';

import { CustomViewDefaults } from '../../data/custom-view-defaults';
import { ActionOutputFormGroup } from '../../forms/action-output.form-group';

export function validateInputs(): ValidatorFn {
  return control => {
    const parent = control.parent as CustomizeBarCustomViewEditForm;

    if (!parent) {
      return;
    }

    const fields = parent.inputFieldProvider.getFields();
    const inputs: Input[] = control.value;

    if (!isRequiredInputsSet(fields, inputs)) {
      return { required: true };
    }
  };
}

@Injectable()
export class CustomizeBarCustomViewEditForm extends FormGroup implements OnDestroy {
  controls: {
    name: FormControl;
    source: FormControl;
    custom_view: FormControl;
    parameters: ParameterArray;
    inputs: FormControl;
    actions: ActionOutputFormGroup;
    visible_input: FieldInputControl;
    card_wrap: FormControl;
    margin: MarginControl;
  };

  element: CustomElementItem;
  context: ViewContext;

  inputFieldProvider = new InputFieldProvider();

  constructor(
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private customViewService: CustomViewService,
    private customViewsStore: CustomViewsStore,
    private elementConfigurationService: ElementConfigurationService
  ) {
    super({
      name: new FormControl(''),
      source: new FormControl(CustomViewSource.View),
      custom_view: new FormControl(''),
      parameters: new ParameterArray([]),
      inputs: new FormControl([], validateInputs()),
      actions: new ActionOutputFormGroup(elementConfigurationService),
      visible_input: new FieldInputControl({ path: ['value'] }),
      card_wrap: new FormControl(true),
      margin: new MarginControl()
    });
  }

  ngOnDestroy(): void {
    this.inputFieldProvider.clearProvider();
  }

  init(element: CustomElementItem, options: { context?: ViewContext } = {}): Observable<void> {
    this.element = element;
    this.context = options.context;

    const customView$ = isSet(element.customView)
      ? this.customViewsStore.getDetailFirst(element.customView)
      : of(undefined);

    return customView$.pipe(
      map(customView => {
        this.controls.name.patchValue(element.name ? element.name : 'Custom');
        this.controls.source.patchValue(element.source ? element.source : CustomViewSource.View);
        this.controls.custom_view.patchValue(element.customView);
        this.controls.parameters.patchValue(customView ? customView.parameters : []);
        this.controls.inputs.patchValue(element.inputs);
        this.controls.actions.deserialize(customView ? customView.actions : [], element.actions);
        this.controls.visible_input.patchValue(element.visibleInput ? element.visibleInput.serializeWithoutPath() : {});
        this.controls.card_wrap.patchValue(element.cardWrap);
        this.controls.margin.patchValue(element.margin);

        this.updateInputFieldProvider();
        this.trackChanges();

        this.markAsPristine();
      })
    );
  }

  updateInputFieldProvider() {
    this.inputFieldProvider.setProvider(
      controlValue<ParameterField[]>(this.controls.parameters).pipe(
        map(parameters => {
          return parameters ? parametersToProviderItems(parameters) : [];
        })
      )
    );
  }

  trackChanges() {
    this.inputFieldProvider
      .getFields$()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.controls.inputs.updateValueAndValidity();
      });
  }

  getCustomView$(): Observable<CustomView> {
    return controlValue<string>(this.controls.custom_view).pipe(
      switchMap(customView => {
        return isSet(customView) ? this.customViewsStore.getDetail(customView) : of(undefined);
      })
    );
  }

  getCustomViewDefaults(): CustomViewDefaults {
    return {
      uniqueName: CustomView.generateUniqueName(),
      pageUid: this.context && this.context.viewSettings ? this.context.viewSettings.uid : undefined,
      elementUid: this.element.uid
    };
  }

  submit(): CustomElementItem {
    const instance = cloneDeep(this.element) as CustomElementItem;

    instance.name = this.controls.name.value;
    instance.source = this.controls.source.value;
    instance.customView = this.controls.custom_view.value;
    instance.inputs = this.controls.inputs.value;
    instance.actions = this.controls.actions.serialize();

    if (this.controls.visible_input.value) {
      instance.visibleInput = new Input().deserialize(this.controls.visible_input.value);
    } else {
      instance.visibleInput = undefined;
    }

    instance.cardWrap = this.controls.card_wrap.value;
    instance.margin = this.controls.margin.value;

    return instance;
  }
}
