<form [formGroup]="form" (ngSubmit)="submit()">
  <app-customize-bar-header
    [title]="form.controls.name.value || (titleEditable ? 'detail' : 'Detail')"
    [titleEnabled]="titleEnabled"
    [titleEditable]="titleEditable"
    [titleCleanValue]="titleCleanValue"
    [backLabel]="'All components'"
    [deleteEnabled]="deleteEnabled"
    [parentLabel]="parentElement?.displayName || parentPopup?.displayName"
    (titleChanged)="onTitleChanged($event)"
    (parentClick)="parentElement?.customize({ event: $event }) || parentPopup?.customize({ event: $event })"
    (deleteClick)="delete()"
  >
  </app-customize-bar-header>

  <div class="sidebar__content">
    <app-sidebar-tabs>
      <app-sidebar-tab [label]="'General'">
        <app-sidebar-section>
          <div class="sidebar__element">
            <app-model-description-data-source-button
              [control]="form.controls.data_source"
              [context]="context"
              [contextElement]="contextElement"
              [error]="(dataConfigured$ | async) !== false ? undefined : 'Not configured'"
              [classes]="'component-data-button'"
              (editClick)="openGetQuery()"
              (addInputClick)="openGetQuery({ addInput: true })"
            >
            </app-model-description-data-source-button>
          </div>
        </app-sidebar-section>

        <app-display-fields-edit-section
          *ngIf="queryConfigured$ | async"
          [resetEnabled]="true"
          [form]="form.controls.data_source.controls.columns"
          [fieldActionsControl]="form.controls.columns_actions"
          [resource]="resource"
          [modelDescription]="modelDescription"
          [context]="context"
          [contextElement]="contextElement"
          [contextElementPaths]="columnsContextElementPaths"
          [firstInit]="firstInit"
          [analyticsSource]="element.analyticsGenericName"
          (resetClick)="resetColumns()"
        >
        </app-display-fields-edit-section>

        <app-related-model-components
          *ngIf="modelDescription"
          [modelDescription]="modelDescription"
          [context]="context"
          [contextElement]="contextElement"
          [contextElementModelOutput]="itemContextElementPath"
          [collapseContext]="collapseContext"
        ></app-related-model-components>
      </app-sidebar-tab>

      <app-sidebar-tab [label]="'Display'">
        <div class="sidebar__element">
          <app-customize-bar-input-edit
            [label]="'detail title'"
            [required]="true"
            [itemForm]="form.controls.title"
            [textStyleControl]="form.controls.element_styles.controls.title_style"
            [textStyleTip]="'Customize Title style'"
            [context]="context"
            [contextElement]="contextElement"
            [contextElementPath]="contextElementPath"
            [contextElementPaths]="contextElementPaths"
            [fill]="true"
            [analyticsSource]="element.analyticsGenericName + '_title'"
          ></app-customize-bar-input-edit>
        </div>

        <div class="sidebar__element">
          <app-sidebar-field
            [label]="'Conditional Visibility'"
            [documentation]="'conditional-visibility'"
            [documentationLabel]="'See how it works'"
            [documentationSource]="element.analyticsGenericName + '_visible'"
          >
            <app-input-edit
              [itemForm]="form.controls.visible_input"
              [context]="context"
              [contextElement]="contextElement"
              [contextElementPaths]="visibleContextElementPaths"
              [staticValueDisabled]="true"
              [placeholder]="'Value'"
              [fill]="true"
              [analyticsSource]="element.analyticsGenericName + '_visible'"
            ></app-input-edit>

            <ng-container description>
              Example: elements.field.value >= 10
            </ng-container>
          </app-sidebar-field>
        </div>

        <div class="sidebar__element">
          <app-sidebar-field [label]="'tooltip'">
            <app-auto-field
              [form]="form"
              [label]="false"
              [field]="
                createField({
                  name: 'tooltip',
                  field: 'CharField',
                  placeholder: 'Show information for users',
                  reset_enabled: true,
                  params: { classes: ['input_fill'] }
                })
              "
            ></app-auto-field>
          </app-sidebar-field>
        </div>

        <div class="sidebar__element">
          <app-element-wrap-edit [control]="form.controls.card_wrap"></app-element-wrap-edit>
        </div>

        <div class="sidebar__element">
          <app-styles-model-element [control]="form.controls.element_styles"></app-styles-model-element>
        </div>
      </app-sidebar-tab>
    </app-sidebar-tabs>
  </div>
</form>
