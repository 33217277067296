var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Injector } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, of } from 'rxjs';
import { debounceTime, switchMap, tap } from 'rxjs/operators';
import { NotificationService } from '@common/notifications';
import { ActionService } from '@modules/action-queries';
import { CustomView, CustomViewService, CustomViewsStore } from '@modules/custom-views';
import { ListDefaultSelection } from '@modules/customize';
import { ElementConfigurationService } from '@modules/customize-configuration';
import { Input, Input as FieldInput } from '@modules/fields';
import { ModelOptionsSource } from '@modules/filters-components';
import { ModelDescriptionStore } from '@modules/model-queries';
import { FieldInputControl } from '@modules/parameters';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { ResourceControllerService } from '@modules/resources';
import { controlValue, isSet } from '@shared';
import { ActionOutputFormGroup } from '../../../forms/action-output.form-group';
import { FieldActionsArray } from '../../display-fields-edit/field-actions.array';
import { ListModelDescriptionDataSourceControl } from '../../model-description-data-source-edit/list-model-description-data-source';
import { OptionEnabledArray } from '../../option-enabled-edit/option-enabled.array';
import { ListElementStylesControl } from '../../styles-list-element-edit/list-element-styles.control';
import { CustomizeBarListLayoutSettingsForm, validateAction, validateActions } from '../customize-bar-list-layout-settings/customize-bar-list-layout-settings.form';
var CustomizeBarTimelineSettingsForm = /** @class */ (function (_super) {
    __extends(CustomizeBarTimelineSettingsForm, _super);
    function CustomizeBarTimelineSettingsForm(modelOptionsSource, customViewService, customViewsStore, notificationService, injector, currentProjectStore, currentEnvironmentStore, resourceControllerService, modelDescriptionStore, actionService, elementConfigurationService, dataSourceControl) {
        var _this = _super.call(this, currentProjectStore, currentEnvironmentStore, resourceControllerService, modelDescriptionStore, actionService, elementConfigurationService, {
            header: new FormControl(true),
            title: new FieldInputControl({ path: ['value'] }),
            data_source: dataSourceControl,
            card_view_unique_name: new FormControl(null),
            card_view_mappings: new FormControl([]),
            search_enabled: new FormControl(true),
            search_live: new FormControl(true),
            hide_labels: new FormControl(true),
            card_click_action: new FormControl(undefined, undefined, validateAction),
            actions: new FormControl([], undefined, validateActions),
            model_actions: new FormControl([], undefined, validateActions),
            columns_actions: new FieldActionsArray([]),
            custom_actions: new ActionOutputFormGroup(elementConfigurationService),
            sorting_field: new FormControl(undefined, Validators.required),
            sorting_asc: new FormControl(true),
            per_page: new FormControl(undefined),
            multiple_selection: new FormControl(false),
            first_item_default_selection: new FormControl(false),
            display_filters: new FormControl(true),
            filter_fields: new OptionEnabledArray([]),
            display_footer: new FormControl(true),
            visible_input: new FieldInputControl({ path: ['value'] }),
            name: new FormControl(''),
            tooltip: new FormControl(''),
            gap_vertical: new FormControl(null),
            card_wrap: new FormControl(true),
            element_styles: new ListElementStylesControl(injector)
        }) || this;
        _this.modelOptionsSource = modelOptionsSource;
        _this.customViewService = customViewService;
        _this.customViewsStore = customViewsStore;
        _this.notificationService = notificationService;
        _this.injector = injector;
        dataSourceControl.setRequired(true);
        return _this;
    }
    CustomizeBarTimelineSettingsForm.prototype.init = function (options) {
        var _this = this;
        this.settings = options.settings;
        this.pageUid = options.pageUid;
        this.elementUid = options.elementUid;
        this.options = options;
        var customView$ = isSet(options.settings.cardCustomView)
            ? this.customViewsStore.getDetailFirst(options.settings.cardCustomView)
            : of(undefined);
        return customView$.pipe(tap(function (customView) {
            var value = {
                header: options.settings.header,
                title: options.settings.titleInput ? options.settings.titleInput.serializeWithoutPath() : {},
                card_view_unique_name: options.settings.cardCustomView,
                card_view_mappings: options.settings.cardCustomViewMappings,
                search_enabled: options.settings.searchEnabled,
                search_live: options.settings.searchLive,
                hide_labels: options.settings.hideLabels,
                card_click_action: options.settings.cardClickAction,
                actions: options.settings.actions,
                model_actions: options.settings.modelActions,
                columns_actions: options.settings.columnActions,
                sorting_field: options.settings.dateField || null,
                sorting_asc: options.settings.sortingAsc,
                per_page: options.settings.perPage,
                multiple_selection: options.settings.multipleSelection,
                first_item_default_selection: options.settings.defaultSelection == ListDefaultSelection.First,
                display_filters: options.settings.displayFilters,
                filter_fields: options.settings.filterFields.map(function (item) {
                    return {
                        name: item.name,
                        enabled: true
                    };
                }),
                display_footer: options.settings.displayFooter,
                visible_input: options.visibleInput ? options.visibleInput.serializeWithoutPath() : {},
                tooltip: options.settings.tooltip,
                gap_vertical: options.settings.gapVertical
            };
            if (options.nameEditable) {
                value['name'] = options.name;
            }
            _this.patchValue(value, { emitEvent: false });
            _this.controls.data_source.deserialize(options.settings.dataSource);
            _this.controls.custom_actions.deserialize(customView ? customView.actions : [], options.settings.customActions);
            if (options.cardWrapEditable) {
                _this.controls.card_wrap.patchValue(options.cardWrap);
            }
            if (options.elementStylesEditable && options.elementStyles) {
                _this.controls.element_styles.deserialize(options.elementStyles);
            }
            _this.trackChanges(options);
            _this.initPerPageClean(_this.controls.per_page);
            if (!options.firstInit) {
                _this.markAsDirty();
            }
        }));
    };
    CustomizeBarTimelineSettingsForm.prototype.trackChanges = function (options) {
        var _this = this;
        _super.prototype.trackChanges.call(this, options);
        combineLatest(controlValue(this.controls.data_source.controls.columns), this.controls.data_source.getModelDescription$(), this.controls.data_source.getModelDescription$().pipe(switchMap(function (modelDescription) {
            if (!modelDescription) {
                return of([]);
            }
            return _this.modelOptionsSource.getOptions$(modelDescription, {
                relationsEnabled: true
            });
        })))
            .pipe(debounceTime(60), untilDestroyed(this))
            .subscribe(function (_a) {
            var columns = _a[0], modelDescription = _a[1], modelOptions = _a[2];
            var columnNames = modelDescription ? modelOptions.map(function (item) { return item.name; }) : columns.map(function (item) { return item.name; });
            var modelId = modelDescription ? modelDescription.modelId : null;
            var filterFieldsSourceChanged = _this.controls.filter_fields.isSourceSet()
                ? !_this.controls.filter_fields.isSource(modelId)
                : false;
            _this.controls.filter_fields.syncControls(columnNames, { source: modelId });
            if (_this.controls.display_filters.value && _this.controls.filter_fields.isAllDisabled()) {
                _this.controls.filter_fields.enableDefault();
            }
            else if (_this.controls.display_filters.value && filterFieldsSourceChanged) {
                _this.controls.filter_fields.enableDefault();
            }
        });
    };
    CustomizeBarTimelineSettingsForm.prototype.getCardCustomView$ = function () {
        var _this = this;
        return controlValue(this.controls.card_view_unique_name).pipe(switchMap(function (customView) {
            return isSet(customView) ? _this.customViewsStore.getDetail(customView) : of(undefined);
        }));
    };
    CustomizeBarTimelineSettingsForm.prototype.getCustomViewDefaults = function () {
        return {
            uniqueName: CustomView.generateUniqueName(),
            pageUid: this.pageUid,
            elementUid: this.elementUid
        };
    };
    CustomizeBarTimelineSettingsForm.prototype.isConfigured = function (instance) {
        return this.elementConfigurationService.isListTimelineConfigured(instance, { restrictDemo: true });
    };
    CustomizeBarTimelineSettingsForm.prototype.submit = function () {
        var instance = cloneDeep(this.settings);
        instance.dateField = this.controls.sorting_field.value;
        instance.titleInput = this.controls.title ? new FieldInput().deserialize(this.controls.title.value) : undefined;
        instance.dataSource = this.controls.data_source.serialize();
        instance.cardCustomView = this.controls.card_view_unique_name.value;
        instance.cardCustomViewMappings = this.controls.card_view_mappings.value;
        instance.searchEnabled = this.controls.search_enabled.value;
        instance.searchLive = this.controls.search_live.value;
        instance.hideLabels = this.controls.hide_labels.value;
        if (this.controls.card_click_action.value) {
            instance.cardClickAction = this.controls.card_click_action.value;
        }
        else {
            instance.cardClickAction = undefined;
        }
        instance.actions = this.controls.actions.value;
        instance.modelActions = this.controls.model_actions.value;
        instance.columnActions = this.controls.columns_actions.value;
        instance.customActions = this.controls.custom_actions.serialize();
        if (isSet(this.controls.sorting_field.value)) {
            instance.sortingField = this.controls.sorting_field.value;
        }
        else {
            instance.sortingField = undefined;
        }
        instance.sortingAsc = this.controls.sorting_asc.value;
        instance.perPage = this.controls.per_page.value;
        instance.multipleSelection = this.controls.multiple_selection.value;
        instance.defaultSelection = this.controls.first_item_default_selection.value
            ? ListDefaultSelection.First
            : undefined;
        instance.filterFields = this.controls.filter_fields.value
            .filter(function (item) { return item.enabled; })
            .map(function (item) {
            return {
                name: item.name
            };
        });
        instance.displayFilters = instance.filterFields.length && this.controls.display_filters.value;
        instance.displayFooter = this.controls.display_footer.value;
        instance.header =
            (instance.titleInput && instance.titleInput.isSet()) ||
                !!instance.searchEnabled ||
                !!instance.displayFilters ||
                (instance.actions && instance.actions.length > 0);
        instance.tooltip = isSet(this.controls.tooltip.value) ? this.controls.tooltip.value.trim() : undefined;
        instance.gapVertical = this.controls.gap_vertical.value;
        return __assign({ settings: instance, visibleInput: this.controls.visible_input.value
                ? new Input().deserialize(this.controls.visible_input.value)
                : undefined, name: this.controls.name.value }, (this.options.cardWrapEditable && {
            cardWrap: this.controls.card_wrap.value
        }), (this.options.elementStylesEditable && {
            elementStyles: this.controls.element_styles.serialize()
        }));
    };
    return CustomizeBarTimelineSettingsForm;
}(CustomizeBarListLayoutSettingsForm));
export { CustomizeBarTimelineSettingsForm };
