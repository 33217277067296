import { Injectable, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { map, pairwise } from 'rxjs/operators';

import { ParameterArray, ParameterField } from '@modules/fields';
import { Resource, ResourceType } from '@modules/projects';
import {
  DefaultHttpQueryBodyTransformer,
  DefaultHttpQueryErrorTransformer,
  HttpContentType,
  HttpMethod,
  HttpQuery,
  HttpResponseType
} from '@modules/queries';
import { controlValue, isSet } from '@shared';

import { HttpParameterArray } from './http-parameter.array';
import { QueryBuilderHttpOptions } from './query-builder-http.component';

@Injectable()
export class QueryBuilderHttpForm extends FormGroup implements OnDestroy {
  controls: {
    method: FormControl;
    base_url_enabled: FormControl;
    url: FormControl;
    url_path: FormControl;
    query_params: HttpParameterArray;
    headers: HttpParameterArray;
    body_type: FormControl;
    form_data: HttpParameterArray;
    body_json: FormControl;
    body_raw: FormControl;
    body_transformer: FormControl;
    body_transformer_visible: FormControl;
    response_type: FormControl;
    response_transformer: FormControl;
    response_transformer_enabled: FormControl;
    response_path: FormControl;
    error_transformer: FormControl;
    error_transformer_visible: FormControl;
    request_response: FormControl;
    request_response_headers: FormControl;
    request_tokens: FormControl;
  };

  responseTypeOptions = [
    { value: HttpResponseType.JSON, name: 'JSON' },
    { value: HttpResponseType.XML, name: 'XML' },
    { value: HttpResponseType.Text, name: 'Text' },
    { value: HttpResponseType.Blob, name: 'Blob' }
  ];
  parametersControl: ParameterArray;
  baseUrl: string;
  baseUrlAllowed = false;
  isBodyJsonDefault$ = new BehaviorSubject<boolean>(false);

  constructor() {
    super({
      method: new FormControl(HttpMethod.GET),
      base_url_enabled: new FormControl(false),
      url: new FormControl(''),
      url_path: new FormControl(''),
      query_params: new HttpParameterArray([]),
      headers: new HttpParameterArray([]),
      body_type: new FormControl(''),
      form_data: new HttpParameterArray([]),
      body_json: new FormControl(''),
      body_raw: new FormControl(''),
      body_transformer: new FormControl(DefaultHttpQueryBodyTransformer),
      body_transformer_visible: new FormControl(false),
      response_type: new FormControl(''),
      response_transformer: new FormControl(DefaultHttpQueryBodyTransformer),
      response_transformer_enabled: new FormControl(false),
      response_path: new FormControl([]),
      error_transformer: new FormControl(DefaultHttpQueryErrorTransformer),
      error_transformer_visible: new FormControl(false),
      request_response: new FormControl(''),
      request_response_headers: new FormControl([]),
      request_tokens: new FormControl('')
    });
  }

  ngOnDestroy(): void {
    this.controls.query_params.ngOnDestroy();
    this.controls.headers.ngOnDestroy();
    this.controls.form_data.ngOnDestroy();
  }

  init(
    resource: Resource,
    query?: HttpQuery,
    options?: QueryBuilderHttpOptions,
    parametersControl?: ParameterArray,
    useFileObjects?: boolean
  ) {
    this.parametersControl = parametersControl;

    let instanceValue = {};
    const overrides = {};
    const defaults = options && options.defaults ? options.defaults : {};
    const enabledBodyTypes = options && options.enabledBodyTypes ? options.enabledBodyTypes : undefined;
    const createQuery = !query;

    if (!query) {
      query = new HttpQuery();

      if (options.defaults && options.defaults['method']) {
        query.method = options.defaults['method'];
      }
    }

    if (options && options.baseQuery) {
      query.merge(options.baseQuery, createQuery);
    }

    if (resource.type == ResourceType.GraphQL) {
      overrides['body_type'] = HttpContentType.GraphQL;
    }

    const baseUrl = options && options.baseQuery && isSet(options.baseQuery.url) ? options.baseQuery.url : undefined;

    this.baseUrl = baseUrl;
    this.baseUrlAllowed = isSet(baseUrl);

    let baseUrlEnabled = this.baseUrlAllowed;
    this.controls.base_url_enabled.patchValue(this.baseUrlAllowed);

    if (query) {
      let url = query.url;
      let urlPath = query.urlPath;
      const formData = query.bodyType == HttpContentType.FormData || query.bodyType == HttpContentType.FormUrlEncoded;

      if (this.baseUrlAllowed && isSet(query.url) && !isSet(query.urlPath)) {
        if (String(url).toLowerCase().startsWith(String(baseUrl).toLowerCase())) {
          url = '';
          urlPath = url.substring(options.baseQuery.url.length);
        } else {
          baseUrlEnabled = false;
        }
      }

      instanceValue = {
        method: query.method,
        base_url_enabled: baseUrlEnabled,
        url: url,
        url_path: urlPath,
        query_params: query.queryParams,
        form_data: formData ? query.body || [] : [],
        body_json: query.bodyType == HttpContentType.JSON ? query.body : '',
        body_raw: query.bodyType == HttpContentType.Raw || query.bodyType == HttpContentType.GraphQL ? query.body : '',
        response_type: query.responseType,
        response_path: query.responsePath ? query.responsePath.split('.') : [],
        request_response: query.requestResponse,
        request_response_headers: query.requestResponseHeaders,
        request_tokens: query.requestTokens
      };

      if (query.headers !== undefined) {
        instanceValue['headers'] = query.headers;
      }

      if (query.bodyType && (!enabledBodyTypes || (enabledBodyTypes && enabledBodyTypes.includes(query.bodyType)))) {
        instanceValue['body_type'] = query.bodyType;
      }

      if (query.errorTransformer) {
        instanceValue['error_transformer'] = query.errorTransformer;
        instanceValue['error_transformer_visible'] = true;
      } else {
        instanceValue['error_transformer_visible'] = false;
      }

      if (query.bodyTransformer) {
        instanceValue['body_transformer'] = query.bodyTransformer;
        instanceValue['body_transformer_visible'] = true;
      } else {
        instanceValue['body_transformer_visible'] = false;
      }

      if (query.responseTransformer) {
        instanceValue['response_transformer'] = query.responseTransformer;
        instanceValue['response_transformer_enabled'] = true;
      } else {
        instanceValue['response_transformer_enabled'] = false;
      }
    }

    const value = {
      body_type: HttpContentType.JSON,
      ...defaults,
      ...instanceValue,
      ...overrides
    };

    this.patchValue(value);

    combineLatest(
      controlValue(this.controls.method),
      controlValue<HttpContentType>(this.controls.body_type),
      this.parametersControl
        ? controlValue(this.parametersControl).pipe(
            map(() => this.parametersControl.serialize()),
            pairwise()
          )
        : of([[], []])
    )
      .pipe(untilDestroyed(this))
      .subscribe(([method, bodyType, [parametersBefore, parametersNew]]) => {
        if (method != HttpMethod.GET && bodyType == HttpContentType.JSON) {
          const jsonBefore = this.getDefaultBodyJson(parametersBefore);

          if (this.controls.body_json.value == jsonBefore) {
            const jsonNew = this.getDefaultBodyJson(parametersNew);
            this.controls.body_json.patchValue(jsonNew);
          }
        }
      });

    combineLatest(
      controlValue<HttpContentType>(this.controls.body_type),
      controlValue(this.controls.body_json),
      this.parametersControl
        ? controlValue(this.parametersControl).pipe(map(() => this.parametersControl.serialize()))
        : of([])
    )
      .pipe(untilDestroyed(this))
      .subscribe(([bodyType, bodyJson, parameters]) => {
        const json = this.getDefaultBodyJson(parameters);
        this.isBodyJsonDefault$.next(bodyType == HttpContentType.JSON && bodyJson == json);
      });

    controlValue<HttpContentType>(this.controls.body_type)
      .pipe(untilDestroyed(this))
      .subscribe(bodyType => {
        this.controls.form_data.typeEnabled = bodyType == HttpContentType.FormData && !useFileObjects;
      });
  }

  toggleBodyTransformerVisible() {
    this.controls.body_transformer_visible.patchValue(!this.controls.body_transformer_visible.value);
  }

  toggleErrorTransformerVisible() {
    this.controls.error_transformer_visible.patchValue(!this.controls.error_transformer_visible.value);
  }

  isResponseTransformerChanged() {
    return this.controls.response_transformer.value != DefaultHttpQueryBodyTransformer;
  }

  isErrorTransformerChanged() {
    return this.controls.error_transformer.value != DefaultHttpQueryErrorTransformer;
  }

  isBodyTransformerChanged() {
    return this.controls.body_transformer.value != DefaultHttpQueryBodyTransformer;
  }

  getDefaultBodyJson(parameters: ParameterField[]): string {
    if (!parameters.length) {
      return '';
    }

    return `{\n${parameters
      .map((item, i) => {
        let result = `  "${item.name}": params.${item.name}`;
        if (i < parameters.length - 1) {
          result += ',';
        }
        return result;
      })
      .join('\n')}\n}`;
  }

  setDefaultBodyJson() {
    const json = this.getDefaultBodyJson(this.parametersControl.serialize());
    this.controls.body_json.patchValue(json);
  }

  urlPathToUrl(): string {
    const baseUrl = isSet(this.baseUrl) ? this.baseUrl : '';
    const urlPath = isSet(this.controls.url_path.value) ? this.controls.url_path.value : '';

    return baseUrl + urlPath;
  }

  enableBaseURL() {
    this.controls.base_url_enabled.patchValue(true);
  }

  disableBaseURL() {
    this.controls.base_url_enabled.patchValue(false);
    this.controls.url.patchValue(this.urlPathToUrl());
  }

  getInstance(): HttpQuery {
    const instance = new HttpQuery();

    instance.method = this.controls.method.value;

    if (this.controls.base_url_enabled.value) {
      instance.url = undefined;
      instance.urlPath = this.controls.url_path.value;
    } else {
      instance.url = this.controls.url.value;
      instance.urlPath = undefined;
    }

    instance.queryParams = this.controls.query_params.serialize();
    instance.headers = this.controls.headers.serialize();
    instance.bodyType = this.controls.body_type.value;
    instance.responseType = this.controls.response_type.value;
    instance.responsePath = this.controls.response_path.value.join('.');
    instance.requestResponse = this.controls.request_response.value;
    instance.requestResponseHeaders = this.controls.request_response_headers.value;
    instance.requestTokens = this.controls.request_tokens.value;

    const formData =
      instance.bodyType == HttpContentType.FormData || instance.bodyType == HttpContentType.FormUrlEncoded;

    if (formData) {
      instance.body = this.controls.form_data.serialize();
    } else if (instance.bodyType == HttpContentType.JSON) {
      instance.body = this.controls.body_json.value;
    } else if (instance.bodyType == HttpContentType.Raw || instance.bodyType == HttpContentType.GraphQL) {
      instance.body = this.controls.body_raw.value;
    }

    if (this.controls.response_transformer_enabled.value) {
      if (this.isResponseTransformerChanged()) {
        instance.responseTransformer = this.controls.response_transformer.value;
      } else {
        instance.responseTransformer = undefined;
      }

      instance.responsePath = undefined;
    } else {
      instance.responseTransformer = undefined;
      instance.responsePath = this.controls.response_path.value.join('.');
    }

    if (this.controls.error_transformer_visible.value) {
      instance.errorTransformer = this.controls.error_transformer.value;
    } else {
      instance.errorTransformer = undefined;
    }

    if (this.isBodyTransformerChanged()) {
      instance.bodyTransformer = this.controls.body_transformer.value;
    }

    return instance;
  }
}
