<app-element-wrapper *ngIf="visible || (customizeEnabled$ | async)" [wrap]="element.style == filterStyles.Wrap">
  <div class="filters-element" #columns_element>
    <div
      *ngFor="let item of filters; let i = index; let last = last; trackBy: trackColumn"
      class="filters-element__item"
      [style.flex-grow]="item.weight ? item.weight * 100 : null"
      #column_element
    >
      <app-element-wrapper [wrap]="item.cardWrap">
        <app-filter-element-item
          class="filters-element__item-inner"
          [class.filters-element__item-inner_wrap]="item.cardWrap"
          [name]="item.field.name"
          [field]="item.field"
          [labelAdditional]="item.labelAdditional"
          [elementStyles]="item.elementStyles"
          [tooltip]="item.tooltip"
          [context]="context"
          [parent]="viewContextElement"
          [accentColor]="accentColor"
          [style.padding-top.px]="(element.margin.top | appIsSet) ? element.margin.top : null"
          [style.padding-right.px]="(element.margin.right | appIsSet) ? element.margin.right : null"
          [style.padding-bottom.px]="(element.margin.bottom | appIsSet) ? element.margin.bottom : null"
          [style.padding-left.px]="(element.margin.left | appIsSet) ? element.margin.left : null"
        ></app-filter-element-item>
      </app-element-wrapper>

      <div
        *ngIf="!last"
        class="filters-element__item-handle"
        [class.filters-element__item-handle_active]="draggingIndex === i"
        (mousedown)="onHandleDragStarted($event, i)"
      >
        <div *ngIf="stick" class="filters-element__item-handle-label">{{ stick.label }}</div>
      </div>
    </div>

    <ng-container *ngIf="!filters.length">
      <div *ngFor="let item of [1, 2]; let i = index" class="filters-element__item" [style.flex-grow]="i + 1">
        <app-element-wrapper [wrap]="element.style == filterStyles.Background">
          <div class="filters-element__item-inner">
            <div class="field">
              <label class="field__label">
                <span class="stub-text">Field</span>
              </label>
              <input
                class="input input_stub"
                [class.input_fill]="true"
                [class.loading-animation]="false"
                type="text"
                value="Value"
                [disabled]="true"
              />
            </div>
          </div>
        </app-element-wrapper>
      </div>
    </ng-container>
  </div>
</app-element-wrapper>
