<form [formGroup]="form" (ngSubmit)="submit()">
  <app-customize-bar-header
    [title]="form.controls.name.value || (titleEditable ? 'HTML' : 'HTML')"
    [titleEnabled]="titleEnabled"
    [titleEditable]="titleEditable"
    [titleCleanValue]="titleCleanValue"
    [backLabel]="'All components'"
    [deleteEnabled]="deleteEnabled"
    [parentLabel]="parentElement?.displayName || parentPopup?.displayName"
    (parentClick)="parentElement?.customize({ event: $event }) || parentPopup?.customize({ event: $event })"
    (deleteClick)="delete()"
    (titleChanged)="onTitleChanged($event)"
  >
  </app-customize-bar-header>

  <div class="sidebar__content">
    <app-sidebar-tabs>
      <app-sidebar-tab [label]="'General'">
        <app-sidebar-section>
          <div class="sidebar__element">
            <app-sidebar-field>
              <app-auto-field
                style="display: block; width: 100%; overflow: auto;"
                [form]="form"
                [label]="false"
                [field]="
                  createField({
                    name: 'html',
                    field: 'CodeField',
                    params: {
                      language: 'html',
                      autogrow: true,
                      field: true,
                      field_contrast: true,
                      wrap: true,
                      classes: ['input_fill']
                    }
                  })
                "
              ></app-auto-field>
            </app-sidebar-field>
          </div>
        </app-sidebar-section>

        <app-sidebar-section [title]="'Parameters'" [documentation]="'inputs'">
          <div class="sidebar__list">
            <app-parameters-edit-with-inputs
              [control]="form.controls.parameters"
              [inputsControl]="form.controls.inputs"
              [parameterProvider]="form.inputFieldProvider"
              [context]="context"
              [contextElement]="contextElement"
              [configurable]="{
                name: true,
                field: true,
                required: true,
                add: true,
                sortable: true
              }"
              [analyticsSource]="element.analyticsGenericName"
            >
            </app-parameters-edit-with-inputs>
          </div>
        </app-sidebar-section>
      </app-sidebar-tab>

      <app-sidebar-tab [label]="'Display'">
        <div class="sidebar__element">
          <app-sidebar-field [label]="'Width'" [layoutRight]="true">
            <div class="sidebar-icon-buttons sidebar-icon-buttons_border">
              <div
                class="sidebar-icon-button"
                [class.sidebar-icon-button_active]="!form.controls.width_fluid.value"
                (click)="form.controls.width_fluid.setValue(false)"
              >
                <div class="sidebar-icon-button__label">Fixed</div>
              </div>

              <div
                class="sidebar-icon-button"
                [class.sidebar-icon-button_active]="form.controls.width_fluid.value"
                (click)="form.controls.width_fluid.setValue(true)"
              >
                <div class="sidebar-icon-button__label">Fluid</div>
              </div>
            </div>
          </app-sidebar-field>
        </div>

        <div class="sidebar__element">
          <app-sidebar-field [label]="'Height'" [layoutRight]="true">
            <div class="sidebar-icon-buttons sidebar-icon-buttons_border">
              <div
                class="sidebar-icon-button"
                [class.sidebar-icon-button_active]="!form.controls.height_fluid.value"
                (click)="form.controls.height_fluid.setValue(false)"
              >
                <div class="sidebar-icon-button__label">Fixed</div>
              </div>

              <div
                class="sidebar-icon-button"
                [class.sidebar-icon-button_active]="form.controls.height_fluid.value"
                (click)="form.controls.height_fluid.setValue(true)"
              >
                <div class="sidebar-icon-button__label">Fluid</div>
              </div>
            </div>
          </app-sidebar-field>
        </div>

        <div class="sidebar__element">
          <app-sidebar-field
            [label]="'Conditional Visibility'"
            [documentation]="'conditional-visibility'"
            [documentationLabel]="'See how it works'"
            [documentationSource]="element.analyticsGenericName + '_visible'"
          >
            <app-input-edit
              [itemForm]="form.controls.visible_input"
              [context]="context"
              [staticValueDisabled]="true"
              [placeholder]="'Value'"
              [fill]="true"
              [analyticsSource]="element.analyticsGenericName + '_visible'"
            ></app-input-edit>

            <ng-container description>
              Example: elements.field.value >= 10
            </ng-container>
          </app-sidebar-field>
        </div>

        <div class="sidebar__element">
          <app-element-wrap-edit [control]="form.controls.card_wrap"></app-element-wrap-edit>
        </div>

        <div class="sidebar__element">
          <app-sidebar-field [label]="'Spacing'">
            <app-margin-control [control]="form.controls.margin"></app-margin-control>
          </app-sidebar-field>
        </div>
      </app-sidebar-tab>
    </app-sidebar-tabs>
  </div>

  <!--  <app-sidebar-submit-->
  <!--    [saveDisabled]="form.form.invalid || form.form.disabled"-->
  <!--    [errors]="form.form | appFormErrors"-->
  <!--    (cancelClick)="cancel()"-->
  <!--    (deleteClick)="delete()"-->
  <!--  >-->
  <!--  </app-sidebar-submit>-->
</form>
