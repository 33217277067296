import { AfterViewInit, ChangeDetectorRef, ElementRef, EventEmitter, NgZone, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { Power2, TweenMax } from 'gsap';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { interval } from 'rxjs';
import { delay, take } from 'rxjs/operators';
export var defaultTipOptions = {
    hoverable: false,
    stick: false,
    style: 'default'
};
export var TipSide;
(function (TipSide) {
    TipSide["Top"] = "top";
    TipSide["Right"] = "right";
    TipSide["Bottom"] = "bottom";
    TipSide["Left"] = "left";
    TipSide["RightBottom"] = "right-bottom";
})(TipSide || (TipSide = {}));
var TipComponent = /** @class */ (function () {
    function TipComponent(zone, cd) {
        this.zone = zone;
        this.cd = cd;
        this.closed = new EventEmitter();
        this.moved = new EventEmitter();
        this.visible = true;
        this.positioned = false;
        this.side = TipSide.Top;
    }
    TipComponent.prototype.ngOnInit = function () {
        this.updateSide();
    };
    TipComponent.prototype.ngOnDestroy = function () { };
    TipComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.updatePosition();
        if (this.options.stick) {
            interval(1000 / 30)
                .pipe(untilDestroyed(this))
                .subscribe(function () {
                _this.updatePosition();
            });
        }
    };
    TipComponent.prototype.setContent = function (content) {
        this.content = content;
        this.cd.markForCheck();
    };
    TipComponent.prototype.updatePosition = function () {
        var anchorRect = this.origin.getBoundingClientRect();
        var margin = this.options.margin || 0;
        var x, y, xPercent, yPercent;
        if (this.side == TipSide.Right) {
            x = anchorRect.left + anchorRect.width + margin;
            y = anchorRect.top + anchorRect.height / 2;
            xPercent = 0;
            yPercent = -50;
        }
        else if (this.side == TipSide.Left) {
            x = anchorRect.left - margin;
            y = anchorRect.top + anchorRect.height / 2;
            xPercent = -100;
            yPercent = -50;
        }
        else if (this.side == TipSide.Top) {
            x = anchorRect.left + anchorRect.width / 2;
            y = anchorRect.top - margin;
            xPercent = -50;
            yPercent = -100;
        }
        else if (this.side == TipSide.Bottom) {
            x = anchorRect.left + anchorRect.width / 2;
            y = anchorRect.bottom + margin;
            xPercent = -50;
            yPercent = 0;
        }
        else if (this.side == TipSide.RightBottom) {
            x = anchorRect.left + anchorRect.width / 2;
            y = anchorRect.bottom + margin;
            xPercent = 25;
            yPercent = 0;
        }
        if (!this.positioned) {
            this.positioned = true;
            TweenMax.set(this.root.nativeElement, {
                x: x,
                y: y,
                xPercent: xPercent,
                yPercent: yPercent
            });
        }
        else {
            TweenMax.to(this.root.nativeElement, 0.6, {
                x: x,
                y: y,
                xPercent: xPercent,
                yPercent: yPercent,
                ease: Power2.easeOut
            });
        }
        this.moved.emit({ x: x, y: y });
    };
    TipComponent.prototype.updateSide = function () {
        if (this.options.side) {
            this.side = this.options.side;
            this.cd.markForCheck();
            return;
        }
        var anchorRect = this.origin.getBoundingClientRect();
        var x = anchorRect.left + anchorRect.width / 2;
        var leftSide = x < window.innerWidth / 2;
        var side;
        if (leftSide) {
            side = TipSide.Right;
        }
        else {
            side = TipSide.Left;
        }
        this.side = side;
        this.cd.markForCheck();
    };
    Object.defineProperty(TipComponent.prototype, "template", {
        get: function () {
            if (this.content instanceof TemplateRef) {
                return this.content;
            }
        },
        enumerable: true,
        configurable: true
    });
    TipComponent.prototype.show = function () {
        if (this.visible) {
            return;
        }
        if (this.closeSubscription) {
            this.closeSubscription.unsubscribe();
            this.closeSubscription = undefined;
        }
        this.visible = true;
        this.cd.markForCheck();
    };
    TipComponent.prototype.close = function () {
        var _this = this;
        if (this.closeSubscription) {
            return;
        }
        if (!this.visible) {
            this.closed.emit();
            return;
        }
        this.visible = false;
        this.cd.markForCheck();
        this.closeSubscription = this.zone.onStable
            .pipe(take(1), delay(150), untilDestroyed(this))
            .pipe()
            .subscribe(function () {
            _this.closed.emit();
        });
    };
    return TipComponent;
}());
export { TipComponent };
