import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { FormUtils } from '@common/form-utils';
import { getCurrentLanguage } from '@common/localize';
import { ApiService } from '@modules/api';
import { AuthService } from '@modules/auth';
import { SSOSettings } from '@modules/sso';
import { trimAll } from '@shared';

@Injectable()
export class LoginForm {
  form: FormGroup;
  username = new FormControl('', Validators.required);
  password = new FormControl('', Validators.required);

  constructor(
    private formUtils: FormUtils,
    private fb: FormBuilder,
    private authService: AuthService,
    private apiService: ApiService
  ) {
    this.form = this.fb.group({
      username: this.username,
      password: this.password
    });
    this.markAsSubmit(false);
  }

  markAsSubmit(submit) {
    this.form['submit'] = submit;

    if (submit) {
      this.form.markAsDirty();
    }
  }

  submitJetAuth(projectName?: string, transferDemoProjects?: string[]) {
    const projectToken = this.apiService.getProjectToken();
    const obs = this.authService.login(trimAll(this.form.value['username']), this.form.value['password'], {
      projectName: projectName,
      projectToken: projectToken,
      transferDemoProjects: transferDemoProjects
    });

    obs.subscribe(
      () => {},
      error => this.formUtils.showFormErrors(this.form, error)
    );

    return obs;
  }

  submitSSOAuth(sso: SSOSettings, projectName?: string, transferDemoProjects?: string[]) {
    const projectToken = this.apiService.getProjectToken();
    const language = getCurrentLanguage();
    const obs = this.authService.customLogin(sso, trimAll(this.form.value['username']), this.form.value['password'], {
      projectName: projectName,
      projectToken: projectToken,
      transferDemoProjects: transferDemoProjects,
      language: language
    });

    obs.subscribe(
      () => {},
      error => this.formUtils.showFormErrors(this.form, error)
    );

    return obs;
  }
}
