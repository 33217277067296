<div class="collaboration-tasks">
  <div class="collaboration__header">
    <h2 class="collaboration__title">
      <ng-container *ngIf="currentQueue">{{ currentQueue.name }} Tasks </ng-container>
      <ng-container *ngIf="!currentQueue">Tasks</ng-container>
      <a
        *ngIf="customizeEnabled$ | async"
        href="javascript:void(0)"
        class="button button_icon button_orange button_primary button_extra-small"
        (click)="editQueue(currentQueue)"
      >
        <span class="button__icon icon-gear"></span>
      </a>
    </h2>
    <p class="collaboration__description">
      See what your teammates are doing and investigate changes in your workspace.
    </p>
    <div *ngIf="customizeEnabled$ | async" class="list__links">
      <!--      <a-->
      <!--        [appDocumentation]="'collaboration'"-->
      <!--        class="list__links-item"-->
      <!--        target="_blank"-->
      <!--      >-->
      <!--        <span class="list__links-item-icon icon-book"></span>-->
      <!--        <span class="list__links-item-label">How to get started</span>-->
      <!--      </a>-->
      <a href="javascript:void(0)" class="list__links-item" [appShowVideo]="{ youtubeId: '-DMkubFxhDs' }">
        <span class="list__links-item-icon icon-play_2"></span>
        <span class="list__links-item-label">Watch a video</span>
      </a>
      <a href="javascript:void(0)" class="list__links-item collaboration-tasks-tour-link">
        <span class="list__links-item-icon icon-cursor"></span>
        <span class="list__links-item-label">Take a tour</span>
      </a>
    </div>
    <div class="collaboration__filter">
      <span class="collaboration__filter-title">Filter by</span>
      <div [formGroup]="form" class="collaboration__form">
        <div class="collaboration__form-input-wrap">
          <gxs-select
            formControlName="assigned"
            [source]="projectUserSelectSource"
            [options]="{
              theme: 'jet',
              search: true,
              searchMinimumLength: 1,
              searchDebounce: 200,
              classes: ['input_fill'],
              labels: {} | localizeSelect
            }"
          >
          </gxs-select>
        </div>
        <div class="collaboration__form-input-wrap">
          <gxs-select
            [options]="{
              theme: 'jet',
              search: true,
              searchMinimumLength: 1,
              searchDebounce: 0,
              labels: {} | localizeSelect,
              classes: ['input_fill']
            }"
            formControlName="priority"
          >
            <gxs-option *ngFor="let item of priorityOptions" [name]="item.name" [value]="item.value"></gxs-option>
          </gxs-select>
        </div>
        <div class="collaboration__form-date-range">
          <div class="input-icon-wrapper">
            <input
              #dp_gte
              cdkOverlayOrigin
              #dp_gte_origin="cdkOverlayOrigin"
              [appDateValue]="{ format: formatDate }"
              autocomplete="off"
              class="input input_icon-right"
              formControlName="date_add_gte"
              style="width: 150px;"
              type="text"
            />
            <span class="input-icon input-icon_right icon-calendar"></span>
            <gxd-datepicker
              [input]="dp_gte"
              [origin]="dp_gte_origin"
              [options]="{ theme: 'jet', format: formatDate, time: false, locale: {} | localizeDatepicker }"
            ></gxd-datepicker>
          </div>

          <div class="collaboration__form-date-range-separator"></div>

          <div class="input-icon-wrapper">
            <input
              #dp_lte
              cdkOverlayOrigin
              #dp_lte_origin="cdkOverlayOrigin"
              [appDateValue]="{ format: formatDate }"
              autocomplete="off"
              class="input input_icon-right"
              formControlName="date_add_lte"
              style="width: 150px;"
              type="text"
            />
            <span class="input-icon input-icon_right icon-calendar"></span>
            <gxd-datepicker
              [input]="dp_lte"
              [origin]="dp_lte_origin"
              [options]="{ theme: 'jet', format: formatDate, time: false, locale: {} | localizeDatepicker }"
            ></gxd-datepicker>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="collaboration-tasks__list">
    <div class="card-list__wrap">
      <ng-container *ngIf="(tasks$ | async)?.length !== 0">
        <div class="card-list__header">
          <div class="card-list__header-col">
            <div class="card-list__header-col-inner">
              <span class="card-list__header-col-title">Assignee</span>
            </div>
          </div>
          <div class="card-list__header-col">
            <div class="card-list__header-col-inner">
              <span class="card-list__header-col-title">Name</span>
            </div>
          </div>
          <div class="card-list__header-col">
            <div class="card-list__header-col-inner">
              <span class="card-list__header-col-title">Priority</span>
            </div>
          </div>
          <div class="card-list__header-col">
            <div class="card-list__header-col-inner">
              <span class="card-list__header-col-title">Status</span>
            </div>
          </div>
          <!--          <div class="card-list__header-col">-->
          <!--            <div class="card-list__header-col-inner">-->
          <!--              <span class="card-list__header-col-title">Page</span>-->
          <!--            </div>-->
          <!--          </div>-->
          <!--          <div class="card-list__header-col">-->
          <!--            <div class="card-list__header-col-inner">-->
          <!--              <span class="card-list__header-col-title">Comments</span>-->
          <!--            </div>-->
          <!--          </div>-->
          <div class="card-list__header-col">
            <div class="card-list__header-col-inner">
              <span class="card-list__header-col-title"></span>
            </div>
          </div>
        </div>
        <ul class="card-list">
          <li
            *ngFor="let task of tasks$ | async; let i = index"
            class="card-list__item"
            [class.card-list__item_active]="i === selectedItemIndex"
            (click)="activeToggle(task, i)"
          >
            <app-collaboration-tasks-item
              [task]="task"
              [clickable]="true"
              [context]="context"
              (updated)="updateItem(i, $event)"
            ></app-collaboration-tasks-item>
          </li>

          <ng-container *ngIf="(tasksLoading$ | async) && (tasks$ | async) !== undefined">
            <li *ngFor="let item of [1, 2, 3]" class="card-list__item">
              <app-collaboration-tasks-stub></app-collaboration-tasks-stub>
            </li>
          </ng-container>
        </ul>
      </ng-container>

      <ng-container *ngIf="(tasks$ | async) === undefined">
        <ul class="card-list">
          <li *ngFor="let item of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]" class="card-list__item">
            <app-collaboration-tasks-stub></app-collaboration-tasks-stub>
          </li>
        </ul>
      </ng-container>
    </div>
  </div>

  <div *ngIf="(tasks$ | async)?.length === 0" class="table__empty">
    <app-error [title]="'Nothing found'">
      Unfortunately, no tasks found
    </app-error>
  </div>
</div>
