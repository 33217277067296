var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { isSet } from '../common/common';
var AppError = /** @class */ (function (_super) {
    __extends(AppError, _super);
    function AppError(message) {
        var _this = _super.call(this, message) || this;
        Object.setPrototypeOf(_this, AppError.prototype);
        return _this;
    }
    return AppError;
}(Error));
export { AppError };
var UnexpectedError = /** @class */ (function (_super) {
    __extends(UnexpectedError, _super);
    function UnexpectedError(message) {
        var _this = _super.call(this, message) || this;
        Object.setPrototypeOf(_this, UnexpectedError.prototype);
        return _this;
    }
    return UnexpectedError;
}(Error));
export { UnexpectedError };
export function errorToString(error) {
    if (!isSet(error)) {
        return error;
    }
    try {
        var message = error.message;
        if (isSet(message)) {
            return message;
        }
    }
    catch (e) { }
    return error.toString();
}
