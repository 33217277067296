/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../custom-view/custom-view.component.ngfactory";
import * as i2 from "../../../customize/data/view-context";
import * as i3 from "../../../projects/stores/current-environment.store";
import * as i4 from "../custom-view/custom-view.component";
import * as i5 from "../../../customize/data/view-context-element";
import * as i6 from "../../../action-queries/services/action-controller/action-controller.service";
import * as i7 from "../../../theme/services/theme/theme.service";
import * as i8 from "../../../../common/notifications/services/notification/notification.service";
import * as i9 from "@angular/platform-browser";
import * as i10 from "./custom-view-inject.component";
import * as i11 from "../../../customize-ui/data/injection-tokens";
var styles_CustomViewInjectComponent = [];
var RenderType_CustomViewInjectComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CustomViewInjectComponent, data: {} });
export { RenderType_CustomViewInjectComponent as RenderType_CustomViewInjectComponent };
export function View_CustomViewInjectComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "app-custom-view", [], null, null, null, i1.View_CustomViewComponent_0, i1.RenderType_CustomViewComponent)), i0.ɵprd(131584, null, i2.ViewContext, i2.ViewContext, [i3.CurrentEnvironmentStore]), i0.ɵprd(512, null, i4.parametersToken, i5.ViewContextElement, [i2.ViewContext]), i0.ɵprd(512, null, i4.actionsToken, i5.ViewContextElement, [i2.ViewContext]), i0.ɵprd(512, null, i4.stateToken, i5.ViewContextElement, [i2.ViewContext]), i0.ɵprd(512, null, i4.layerToken, i5.ViewContextElement, [i2.ViewContext]), i0.ɵdid(6, 770048, null, 0, i4.CustomViewComponent, [i2.ViewContext, i4.parametersToken, i4.actionsToken, i4.stateToken, i4.layerToken, i6.ActionControllerService, i7.ThemeService, i8.NotificationService, i9.DomSanitizer, i0.Injector, i0.ChangeDetectorRef], { view: [0, "view"], parameters: [1, "parameters"], actionOutputs: [2, "actionOutputs"], params: [3, "params"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.inputs.view; var currVal_1 = (_co.inputs.parameters || i0.ɵEMPTY_ARRAY); var currVal_2 = (_co.inputs.actionOutputs || i0.ɵEMPTY_ARRAY); var currVal_3 = (_co.inputs.params || i0.ɵEMPTY_MAP); _ck(_v, 6, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_CustomViewInjectComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-custom-view-inject", [], null, null, null, View_CustomViewInjectComponent_0, RenderType_CustomViewInjectComponent)), i0.ɵdid(1, 114688, null, 0, i10.CustomViewInjectComponent, [[2, i11.CUSTOM_VIEW_COMPONENT_INPUTS]], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CustomViewInjectComponentNgFactory = i0.ɵccf("app-custom-view-inject", i10.CustomViewInjectComponent, View_CustomViewInjectComponent_Host_0, {}, {}, []);
export { CustomViewInjectComponentNgFactory as CustomViewInjectComponentNgFactory };
