import { CdkConnectedOverlay } from '@angular/cdk/overlay';
import { AfterViewInit, ChangeDetectorRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';
import { copyTextToClipboard } from '@common/code';
import { localize } from '@common/localize';
import { NotificationService } from '@common/notifications';
import { BasePopupComponent } from '@common/popups';
import { AppConfigService } from '@core';
import { AdminMode } from '@modules/admin-mode';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ServerRequestError } from '@modules/api';
import { DraftChangesService } from '@modules/customize-utils';
import { FeatureService } from '@modules/features';
import { BooleanFieldStyle } from '@modules/field-components';
import { createFormFieldFactory } from '@modules/fields';
import { PER_PAGE_PARAM } from '@modules/models';
import { ProjectGroupEditController, ProjectUserForm } from '@modules/project-settings-components';
import { CurrentEnvironmentStore, CurrentProjectStore, ProjectGroupStore, ProjectInviteService, ProjectPropertyType, ProjectPublicInvite, ProjectPublicInviteService, ProjectUserService } from '@modules/projects';
import { RoutingService } from '@modules/routing';
import { errorToString } from '@shared';
export var ShareTab;
(function (ShareTab) {
    ShareTab["Share"] = "share";
    ShareTab["Public"] = "public";
    ShareTab["Members"] = "members";
})(ShareTab || (ShareTab = {}));
var ShareComponent = /** @class */ (function () {
    function ShareComponent(mode, currentProjectStore, currentEnvironmentStore, form, projectGroupStore, cd, projectUserService, projectInviteService, projectPublicInviteService, notificationService, routing, analyticsService, popupComponent, featureService, appConfigService, projectGroupEditController, draftChangesService) {
        this.mode = mode;
        this.currentProjectStore = currentProjectStore;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.form = form;
        this.projectGroupStore = projectGroupStore;
        this.cd = cd;
        this.projectUserService = projectUserService;
        this.projectInviteService = projectInviteService;
        this.projectPublicInviteService = projectPublicInviteService;
        this.notificationService = notificationService;
        this.routing = routing;
        this.analyticsService = analyticsService;
        this.popupComponent = popupComponent;
        this.featureService = featureService;
        this.appConfigService = appConfigService;
        this.projectGroupEditController = projectGroupEditController;
        this.draftChangesService = draftChangesService;
        this.orange = false;
        this.userDeleted = new EventEmitter();
        this.inviteAdded = new EventEmitter();
        this.inviteDeleted = new EventEmitter();
        this.publicInviteAdded = new EventEmitter();
        this.publicInviteDeleted = new EventEmitter();
        this.groupsLoading = true;
        this.membersLoading = true;
        this.tabs = ShareTab;
        this.createInviteLoading = false;
        this.publicInvitesLoading = false;
        this.createPublicInviteLoading = false;
        this.createPublicInviteGroupControl = new FormControl();
        this.inviteSettingsOpened = false;
        this.booleanFieldStyle = BooleanFieldStyle;
        this.projectPropertyTypes = ProjectPropertyType;
        this.hasDraftChanges = false;
        this.createField = createFormFieldFactory();
        this.itemOwner = { value: 'Owner', name: 'Owner' };
    }
    ShareComponent.prototype.ngOnInit = function () {
        var _this = this;
        var _a;
        this.currentTab = this.initialTab || ShareTab.Share;
        this.projectGroupStore
            .getFirst(true)
            .pipe(untilDestroyed(this))
            .subscribe(function (groups) {
            _this.groupsLoading = false;
            _this.cd.markForCheck();
            _this.form.init(undefined, groups);
        }, function () {
            _this.groupsLoading = false;
            _this.cd.markForCheck();
        });
        combineLatest(this.projectUserService.getPaginate(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName), this.projectInviteService.getPaginate(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, (_a = {}, _a[PER_PAGE_PARAM] = 5, _a)))
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var users = _a[0], invites = _a[1];
            _this.users = users.results;
            _this.usersCount = users.count;
            _this.invites = invites.results;
            _this.invitesCount = invites.count;
            _this.membersLoading = false;
            _this.cd.markForCheck();
        }, function () {
            _this.membersLoading = false;
            _this.cd.markForCheck();
        });
        this.loadPublicInvites();
        this.currentProjectStore.instance$.pipe(untilDestroyed(this)).subscribe(function (project) {
            _this.webBaseUrl = project.domain ? "https://" + project.domain.actualDomain : _this.appConfigService.webBaseUrl;
            _this.cd.markForCheck();
        });
        this.draftChangesService
            .getDraftChanges$()
            .pipe(untilDestroyed(this))
            .subscribe(function (changes) {
            _this.hasDraftChanges = changes.filter(function (item) { return item.count; }).length > 0;
            _this.cd.markForCheck();
        });
    };
    ShareComponent.prototype.ngOnDestroy = function () { };
    ShareComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        setTimeout(function () {
            _this.sendTabOpenAnalytics(true);
        }, 0);
    };
    ShareComponent.prototype.setCurrentTab = function (currentTab) {
        if (this.currentTab == currentTab) {
            return;
        }
        this.currentTab = currentTab;
        this.cd.markForCheck();
        this.sendTabOpenAnalytics();
    };
    ShareComponent.prototype.openSignUp = function () {
        this.routing.navigateApp(this.currentProjectStore.instance.settingsSignUpLink('sign_up'));
        this.close();
    };
    ShareComponent.prototype.sendTabOpenAnalytics = function (initial) {
        if (initial === void 0) { initial = false; }
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.Share.OpenTab, {
            Tab: this.currentTab,
            Initial: initial
        });
    };
    ShareComponent.prototype.loadPublicInvites = function () {
        var _this = this;
        this.publicInvitesLoading = true;
        this.cd.markForCheck();
        this.projectPublicInviteService
            .get(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName)
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            _this.publicInvites = result;
            _this.publicInvitesLoading = false;
            _this.cd.markForCheck();
        }, function () {
            _this.publicInvitesLoading = false;
            _this.cd.markForCheck();
        });
    };
    ShareComponent.prototype.getUsers = function () {
        var _this = this;
        var _a;
        this.membersLoading = true;
        this.cd.markForCheck();
        combineLatest(this.projectUserService.getPaginate(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName), this.projectInviteService.getPaginate(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, (_a = {}, _a[PER_PAGE_PARAM] = 5, _a)))
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var users = _a[0], invites = _a[1];
            _this.users = users.results;
            _this.usersCount = users.count;
            _this.invites = invites.results;
            _this.invitesCount = invites.count;
            _this.membersLoading = false;
            _this.cd.markForCheck();
        }, function () {
            _this.membersLoading = false;
            _this.cd.markForCheck();
        });
    };
    ShareComponent.prototype.setInviteSettingsOpened = function (value) {
        this.inviteSettingsOpened = value;
        this.cd.markForCheck();
    };
    ShareComponent.prototype.onPopoverContentChanged = function () {
        this.cdkConnectedOverlay.overlayRef.updatePosition();
    };
    ShareComponent.prototype.createGroup = function () {
        this.projectGroupEditController.create();
    };
    ShareComponent.prototype.onUserDeleted = function (item) {
        this.getUsers();
        this.userDeleted.emit(item);
    };
    ShareComponent.prototype.onInviteDeleted = function (item) {
        this.getUsers();
        this.inviteDeleted.emit(item);
    };
    ShareComponent.prototype.onPublicInviteDeleted = function (item) {
        this.loadPublicInvites();
        this.publicInviteDeleted.emit(item);
    };
    ShareComponent.prototype.checkFeatureEnabled = function () {
        if (!this.currentProjectStore.instance.features.isUsersEnabled() ||
            this.currentProjectStore.instance.isSubscriptionEnded()) {
            this.featureService.showFeatureOverview({
                subtitle: 'Paid Feature',
                title: 'Collaborate on a project with <strong>Users & Teams</strong>',
                description: "\n          Manage permissions for specific users and teams without any line of code. Add users to individual dashboards and\n          manage their access rights to specific contents directly from Jet\u2019s interface.\n        "
            });
            return false;
        }
        else {
            return true;
        }
    };
    ShareComponent.prototype.submit = function () {
        var _this = this;
        if (!this.checkFeatureEnabled()) {
            return;
        }
        if (this.createInviteLoading) {
            return;
        }
        this.createInviteResult = undefined;
        this.createInviteLoading = true;
        this.cd.markForCheck();
        var group = this.form.form.value['group'];
        this.form
            .create()
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            _this.createInviteResult = result;
            _this.createInviteLoading = false;
            _this.form.clean();
            _this.cd.markForCheck();
            _this.getUsers();
            _this.inviteAdded.emit(result);
            // this.notificationService.success('Created', 'User was successfully invited to current App');
            _this.analyticsService.sendSimpleEvent(AnalyticsEvent.Share.MemberInvited, {
                Email: result.getEmail(),
                Mode: _this.mode,
                CustomizationPermission: _this.currentProjectStore.instance.hasGroupCustomizationPermission(group),
                Source: 'share_popup'
            });
        }, function (error) {
            _this.createInviteLoading = false;
            _this.cd.markForCheck();
            if (error instanceof ServerRequestError && error.errors.length) {
                _this.notificationService.error('User Add Failed', error.errors[0]);
            }
            else {
                _this.notificationService.error('User Add Failed', errorToString(error));
            }
        });
    };
    ShareComponent.prototype.createPublicInvite = function () {
        var _this = this;
        if (!this.checkFeatureEnabled()) {
            return;
        }
        this.createPublicInviteLoading = true;
        this.cd.markForCheck();
        var instance = new ProjectPublicInvite();
        instance.group = this.createPublicInviteGroupControl.value;
        this.projectPublicInviteService
            .create(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, instance)
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            _this.loadPublicInvites();
            _this.createPublicInviteGroupControl.setValue(undefined);
            _this.createPublicInviteLoading = false;
            _this.cd.markForCheck();
            _this.publicInviteAdded.emit(result);
            _this.analyticsService.sendSimpleEvent(AnalyticsEvent.Share.PublicLinkCreated, {
                Code: result.code,
                Source: 'share_popup'
            });
        }, function () {
            _this.createPublicInviteLoading = false;
            _this.cd.markForCheck();
        });
    };
    ShareComponent.prototype.close = function () {
        this.popupComponent.close();
    };
    ShareComponent.prototype.getInviteLink = function () {
        return this.createInviteResult.link(this.webBaseUrl);
    };
    ShareComponent.prototype.copyInviteCode = function () {
        var _this = this;
        var text = this.getInviteLink();
        copyTextToClipboard(text)
            .pipe(untilDestroyed(this))
            .subscribe(function (success) {
            if (!success) {
                return;
            }
            _this.notificationService.info(localize('Copied'), localize('Link was copied to clipboard'));
        });
    };
    ShareComponent.prototype.getSignUpLink = function () {
        return this.webBaseUrl + "/register/" + this.currentProjectStore.instance.uniqueName;
    };
    ShareComponent.prototype.copySignUpCode = function () {
        var _this = this;
        var text = this.getSignUpLink();
        copyTextToClipboard(text)
            .pipe(untilDestroyed(this))
            .subscribe(function (success) {
            if (!success) {
                return;
            }
            _this.notificationService.info(localize('Copied'), localize('Link was copied to clipboard'));
        });
    };
    return ShareComponent;
}());
export { ShareComponent };
