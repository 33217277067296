import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { FormControl } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import fromPairs from 'lodash/fromPairs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, Observable, of } from 'rxjs';
import { delayWhen, filter, map, switchMap, tap } from 'rxjs/operators';

import {
  CustomView,
  CustomViewDataView,
  customViewInstanceToData,
  CustomViewService,
  CustomViewSource,
  CustomViewsStore,
  CustomViewType
} from '@modules/custom-views';
import { ViewContext, ViewContextElement } from '@modules/customize';
import { ParameterField } from '@modules/fields';
import { IDEController, IDEService } from '@modules/ide-components';
import { ViewContextTokenProvider } from '@modules/parameters-components';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { CurrentUserStore } from '@modules/users';
import { CustomViewTemplateCounterStore, Frame, View, ViewMapping } from '@modules/views';
import { controlValue, isSet } from '@shared';

// TODO: Refactor import
import { CustomViewMapParametersController } from '../../../custom-elements-components/services/custom-view-map-parameters-controller/custom-view-map-parameters.controller';
import { CustomViewTemplateFilter } from '../../../views-components/components/custom-view-templates-list/custom-view-templates-list.component';
import { CustomViewTemplatesController } from '../../../views-components/services/custom-view-templates-controller/custom-view-templates.controller';
import { ViewEditorController } from '../../../views-components/services/view-editor-controller/view-editor.controller';

import { CustomViewDefaults } from '../../data/custom-view-defaults';

@Component({
  selector: 'app-custom-view-item-edit',
  templateUrl: './custom-view-item-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomViewItemEditComponent implements OnInit, OnDestroy {
  @Input() customViewName: FormControl;
  @Input() customViewDefaults: CustomViewDefaults;
  @Input() mappingsControl: FormControl;
  @Input() sourceParameters: ParameterField[];
  @Input() stateSelectedEnabled = false;
  @Input() subtitle = 'Custom component';
  @Input() initialTemplatesFilter: CustomViewTemplateFilter = {};
  @Input() deleteEnabled = false;
  @Input() componentLabel = 'component';
  @Input() viewType: CustomViewType = CustomViewType.Component;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() contextElementPath: (string | number)[];
  @Input() contextElementPaths: (string | number)[][];
  @Input() analyticsSource: string;
  @Output() selectCustomView = new EventEmitter<CustomView | undefined>();

  loading = false;
  customView: CustomView;
  customViewsShared: CustomView[] = [];
  valueShared = false;
  templatesApprox: number;
  sources = CustomViewSource;

  constructor(
    public currentUserStore: CurrentUserStore,
    private viewEditorController: ViewEditorController,
    private ideService: IDEService,
    private ideController: IDEController,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private customViewService: CustomViewService,
    private customViewsStore: CustomViewsStore,
    private customViewTemplateCounterStore: CustomViewTemplateCounterStore,
    private customViewTemplatesController: CustomViewTemplatesController,
    private customViewMapParametersController: CustomViewMapParametersController,
    private contextTokenProvider: ViewContextTokenProvider,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    combineLatest(
      this.customViewsStore.get(),
      this.customViewName ? controlValue<string>(this.customViewName) : of(undefined)
    )
      .pipe(untilDestroyed(this))
      .subscribe(([customViews, customView]) => {
        this.customView = isSet(customView) ? customViews.find(item => item.uniqueName == customView) : undefined;
        this.customViewsShared = customViews.filter(item => item.shared);
        this.valueShared = isSet(customView)
          ? this.customViewsShared.some(item => item.uniqueName == customView)
          : undefined;
        this.cd.markForCheck();
      });

    this.customViewTemplateCounterStore
      .getApproxFirst$()
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        this.templatesApprox = value;
        this.cd.markForCheck();
      });
  }

  ngOnDestroy(): void {}

  createView(): View {
    const result = new View();

    result.generateId();
    result.frame = new Frame({ width: 300, height: 240 });

    return result;
  }

  updateOrCreateCustomView(options: {
    source: CustomViewSource;
    beforeSave?: (customView: CustomView, fields: string[]) => void;
  }): Observable<CustomView> {
    const existingInstance = this.customView;
    const uniqueName = this.customViewName.value;
    const instance = existingInstance || new CustomView();
    const defaults = this.customViewDefaults;

    if (isSet(uniqueName)) {
      instance.uniqueName = uniqueName;
    } else {
      instance.uniqueName = defaults.uniqueName;
    }

    instance.viewType = this.viewType;
    instance.source = options.source;
    instance.pageUid = defaults.pageUid;
    instance.elementUid = defaults.elementUid;

    if (!existingInstance && options.source == CustomViewSource.CustomElement) {
      instance.filesIde = true;
    }

    const fields = ['unique_name', 'view_type', 'source', 'params'];

    if (options.beforeSave) {
      options.beforeSave(instance, fields);
    }

    const obs$ = existingInstance
      ? this.customViewService.update(
          this.currentProjectStore.instance.uniqueName,
          this.currentEnvironmentStore.instance.uniqueName,
          instance,
          { draft: true, fields: fields }
        )
      : this.customViewService.create(
          this.currentProjectStore.instance.uniqueName,
          this.currentEnvironmentStore.instance.uniqueName,
          instance,
          { draft: true, fields: fields }
        );

    return obs$.pipe(tap(customView => this.customViewsStore.updateOrAddItem(customView)));
  }

  openViewEditor(customView?: CustomView) {
    let view = customView ? customView.view : undefined;
    const create = !view;

    if (!view) {
      view = this.createView();
    }

    const data$ = customView
      ? of<CustomViewDataView>({
          source: CustomViewSource.View,
          view: view,
          name: customView.name,
          parameters: customView.parameters,
          actions: customView.actions,
          testParameters: customView.testParameters
        })
      : this.customViewsStore.generateName().pipe(
          map<string, CustomViewDataView>(name => ({
            source: CustomViewSource.View,
            view: view,
            name: name
          }))
        );

    data$
      .pipe(
        switchMap(data => {
          return this.viewEditorController.open({
            create: create,
            data: {
              ...data,
              ...(this.sourceParameters && {
                parameters: this.sourceParameters,
                testParameters: fromPairs(
                  this.sourceParameters
                    .map(parameter => {
                      const testValue = this.contextElement.getFieldValue(parameter.name);
                      return [parameter.name, testValue];
                    })
                    .filter(item => isSet(item))
                )
              })
            },
            componentLabel: this.componentLabel,
            submitLabel: create ? 'Create component' : 'Save changes',
            stateSelectedEnabled: this.stateSelectedEnabled,
            analyticsSource: this.analyticsSource
          });
        }),
        filter(result => !result.cancelled),
        switchMap(result => {
          if (result.useShared) {
            return of(result.useShared);
          } else {
            this.loading = true;
            this.cd.markForCheck();

            return this.updateOrCreateCustomView({
              source: CustomViewSource.View,
              beforeSave: (instance, fields) => {
                instance.name = result.data.name;
                instance.view = result.data.view;
                instance.parameters = result.data.parameters;
                instance.actions = result.data.actions;
                instance.testParameters = result.data.testParameters;

                fields.push('name', 'view');
              }
            });
          }
        }),
        untilDestroyed(this)
      )
      .subscribe(
        result => {
          this.loading = false;
          this.cd.markForCheck();

          this.onSelectCustomView(result);
        },
        error => {
          console.error(error);

          this.loading = false;
          this.cd.markForCheck();
        }
      );
  }

  openIDE(customView?: CustomView) {
    if (customView) {
      this.loading = true;
      this.cd.markForCheck();

      return this.ideController
        .open({
          projectName: this.currentProjectStore.instance.uniqueName,
          environmentName: this.currentEnvironmentStore.instance.uniqueName,
          customViewName: customView.uniqueName
        })
        .pipe(untilDestroyed(this))
        .subscribe(
          () => {
            this.loading = false;
            this.cd.markForCheck();
          },
          error => {
            console.error(error);
            this.loading = false;
            this.cd.markForCheck();
          }
        );
    } else {
      this.loading = true;
      this.cd.markForCheck();

      this.updateOrCreateCustomView({
        source: CustomViewSource.CustomElement
      })
        .pipe(
          tap(result => {
            this.onSelectCustomView(result);
          }),
          switchMap(result => {
            return this.ideController.open({
              projectName: this.currentProjectStore.instance.uniqueName,
              environmentName: this.currentEnvironmentStore.instance.uniqueName,
              customViewName: result.uniqueName
            });
          }),
          untilDestroyed(this)
        )
        .subscribe(
          () => {
            this.loading = false;
            this.cd.markForCheck();
          },
          error => {
            console.error(error);
            this.loading = false;
            this.cd.markForCheck();
          }
        );
    }
  }

  openCustomViewTemplates() {
    this.customViewTemplatesController
      .chooseTemplate({
        initialFilter: this.initialTemplatesFilter,
        viewCreateEnabled: true,
        stateSelectedEnabled: this.stateSelectedEnabled,
        componentLabel: this.componentLabel,
        analyticsSource: this.analyticsSource
      })
      .pipe(
        filter(result => !result.cancelled),
        switchMap(result => {
          if (result.useShared) {
            return of(result.useShared);
          } else {
            this.loading = true;
            this.cd.markForCheck();

            return this.updateOrCreateCustomView({
              source: CustomViewSource.View,
              beforeSave: (instance, fields) => {
                instance.name = result.data.name;
                instance.view = result.data.view;
                instance.parameters = result.data.parameters;
                instance.actions = result.data.actions;
                instance.testParameters = result.data.testParameters;

                fields.push('name', 'view');
              }
            });
          }
        }),
        untilDestroyed(this)
      )
      .subscribe(
        customView => {
          this.loading = false;
          this.cd.markForCheck();

          if (this.mappingsControl) {
            this.customViewMapParametersController
              .open({
                sourceParameters: this.sourceParameters,
                data: {
                  source: customView.source,
                  view: customView.view,
                  name: customView.name,
                  parameters: customView.parameters,
                  actions: customView.actions,
                  testParameters: customView.testParameters
                },
                context: this.context,
                contextElement: this.contextElement,
                contextElementPath: this.contextElementPath,
                contextElementPaths: this.contextElementPaths,
                contextTokenProvider: this.contextTokenProvider,
                analyticsSource: this.analyticsSource
              })
              .pipe(
                filter(mappingResult => !mappingResult.cancelled),
                untilDestroyed(this)
              )
              .subscribe(mappingResult => {
                this.selectCustomView.emit(customView);
                this.setMappingValue(mappingResult.mappings);
              });
          } else {
            this.selectCustomView.emit(customView);
          }
        },
        () => {
          this.loading = false;
          this.cd.markForCheck();
        }
      );
  }

  updateCustomViewTemplate(customView: CustomView) {
    const view = customView ? customView.view : undefined;

    if (!view) {
      return;
    }

    this.customViewTemplatesController
      .setTemplateView(view, { stateSelectedEnabled: this.stateSelectedEnabled, componentLabel: this.componentLabel })
      .pipe(
        filter(result => !result.cancelled),
        untilDestroyed(this)
      )
      .subscribe();
  }

  resetCustomView() {
    this.onSelectCustomView(undefined);
    this.setMappingValue([]);
  }

  cloneCustomView(customView: CustomView, options: { shared?: boolean }) {
    const instance = new CustomView();

    instance.uniqueName = CustomView.generateUniqueName();
    instance.name = customView.name;
    instance.viewType = CustomViewType.MenuItem;
    instance.source = customView.source;
    instance.parameters = cloneDeep(customView.parameters);
    instance.actions = cloneDeep(customView.actions);
    instance.testParameters = cloneDeep(customView.testParameters);
    instance.shared = options.shared;

    if (customView.source == CustomViewSource.View) {
      instance.view = customView.view;
    } else if (customView.source == CustomViewSource.HTML) {
      instance.html = customView.html;
    } else if (customView.source == CustomViewSource.CustomElement) {
      instance.buildId = customView.buildId;
      instance.tagName = customView.tagName;
      instance.baseTagName = customView.baseTagName;
      instance.filesJs = cloneDeep(customView.filesJs);
      instance.filesCss = cloneDeep(customView.filesCss);
    }

    return this.customViewService
      .create(
        this.currentProjectStore.instance.uniqueName,
        this.currentEnvironmentStore.instance.uniqueName,
        instance,
        { draft: true }
      )
      .pipe(
        delayWhen(result => {
          if (customView.source == CustomViewSource.CustomElement) {
            return this.ideService.clone(
              this.currentProjectStore.instance.uniqueName,
              this.currentEnvironmentStore.instance.uniqueName,
              customView.uniqueName,
              result.uniqueName
            );
          } else {
            return of(undefined);
          }
        }),
        tap(result => this.customViewsStore.addItem(result))
      );
  }

  createCustomViewShared(customView: CustomView) {
    this.loading = true;
    this.cd.markForCheck();

    this.cloneCustomView(customView, { shared: true })
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          this.loading = false;
          this.cd.markForCheck();

          this.selectCustomView.emit(result);
        },
        () => {
          this.loading = false;
          this.cd.markForCheck();
        }
      );
  }

  detachCustomViewShared(customView: CustomView) {
    this.loading = true;
    this.cd.markForCheck();

    this.cloneCustomView(customView, { shared: false })
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          this.loading = false;
          this.cd.markForCheck();

          this.selectCustomView.emit(result);
        },
        () => {
          this.loading = false;
          this.cd.markForCheck();
        }
      );
  }

  changeMapping(customView: CustomView) {
    const mappings: ViewMapping[] = this.mappingsControl.value;

    if (!this.sourceParameters.length) {
      return;
    }

    this.customViewMapParametersController
      .open({
        sourceParameters: this.sourceParameters,
        data: customViewInstanceToData(customView),
        mappings: mappings,
        context: this.context,
        contextElement: this.contextElement,
        contextElementPath: this.contextElementPath,
        contextElementPaths: this.contextElementPaths,
        contextTokenProvider: this.contextTokenProvider,
        analyticsSource: this.analyticsSource
      })
      .pipe(
        filter(mappingResult => !mappingResult.cancelled),
        untilDestroyed(this)
      )
      .subscribe(mappingResult => {
        this.setMappingValue(mappingResult.mappings);
      });
  }

  setMappingValue(mappings: ViewMapping[]) {
    if (this.mappingsControl) {
      this.mappingsControl.patchValue(mappings ? mappings : []);
    }
  }

  onSelectCustomView(customView?: CustomView) {
    const currentUniqueName = this.customView ? this.customView.uniqueName : undefined;
    const newUniqueName = customView ? customView.uniqueName : undefined;

    this.selectCustomView.emit(customView);

    if (this.mappingsControl && isSet(newUniqueName) && newUniqueName != currentUniqueName) {
      this.changeMapping(customView);
    }
  }
}
