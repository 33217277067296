import { Injectable, Injector, OnDestroy } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, delayWhen, filter, map } from 'rxjs/operators';

import { DialogButtonHotkey, DialogButtonType, DialogService } from '@common/dialogs';
import { NotificationService } from '@common/notifications';
import { PopupService } from '@common/popups';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ServerRequestError } from '@modules/api';
import { EmailTemplate, EmailTemplateService } from '@modules/emails';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { errorToString } from '@shared';

import { EmailTemplateEditPopupComponent } from '../../components/email-template-edit-popup/email-template-edit-popup.component';

@Injectable()
export class EmailTemplateEditController implements OnDestroy {
  constructor(
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private emailTemplateService: EmailTemplateService,
    private dialogService: DialogService,
    private injector: Injector,
    private popupService: PopupService,
    private notificationService: NotificationService,
    private analyticsService: UniversalAnalyticsService
  ) {}

  ngOnDestroy(): void {}

  create(options: { baseEmailTemplate: EmailTemplate; analyticsSource?: string }): Observable<EmailTemplate> {
    const popup = this.popupService.showComponent<EmailTemplateEditPopupComponent>({
      component: EmailTemplateEditPopupComponent,
      injector: this.injector,
      inputs: {
        baseEmailTemplate: options.baseEmailTemplate,
        analyticsSource: options.analyticsSource
      },
      scrollable: true
    });

    return popup.instance.created.asObservable();
  }

  edit(options: {
    emailTemplate: EmailTemplate;
    baseEmailTemplate: EmailTemplate;
    analyticsSource?: string;
  }): Observable<EmailTemplate> {
    const popup = this.popupService.showComponent<EmailTemplateEditPopupComponent>({
      component: EmailTemplateEditPopupComponent,
      injector: this.injector,
      inputs: {
        emailTemplate: options.emailTemplate,
        baseEmailTemplate: options.baseEmailTemplate,
        analyticsSource: options.analyticsSource
      },
      scrollable: true
    });

    return popup.instance.updated.asObservable();
  }

  reset(options: { emailTemplate: EmailTemplate; analyticsSource?: string }): Observable<boolean> {
    this.analyticsService.sendSimpleEvent(AnalyticsEvent.EmailTemplate.DeleteStarted, {
      Name: options.emailTemplate.name,
      Source: options.analyticsSource
    });

    return this.dialogService
      .dialog({
        title: 'Reset',
        description: `Are you sure want to reset Email template <strong>${options.emailTemplate.info.label}</strong>?`,
        style: 'orange',
        buttons: [
          {
            name: 'cancel',
            label: 'Cancel',
            type: DialogButtonType.Default,
            hotkey: DialogButtonHotkey.Cancel
          },
          {
            name: 'ok',
            label: 'Reset template',
            type: DialogButtonType.Danger,
            hotkey: DialogButtonHotkey.Submit,
            executor: () => this.resetProcess(options)
          }
        ]
      })
      .pipe(
        filter(result => {
          const submit = result.button == 'ok';
          if (!submit) {
            this.analyticsService.sendSimpleEvent(AnalyticsEvent.EmailTemplate.DeleteCancelled, {
              Name: options.emailTemplate.name,
              Source: options.analyticsSource
            });
          }

          return submit;
        }),
        map(result => {
          return result.executorResult;
        })
      );
  }

  resetProcess(options: { emailTemplate: EmailTemplate; analyticsSource?: string }): Observable<boolean> {
    return this.emailTemplateService
      .delete(
        this.currentProjectStore.instance.uniqueName,
        this.currentEnvironmentStore.instance.uniqueName,
        options.emailTemplate
      )
      .pipe(
        delayWhen(() => this.currentProjectStore.getFirst(true)),
        map(() => {
          this.notificationService.success(
            'Reset',
            `Email template <strong>${options.emailTemplate.info.label}</strong> was successfully reset`
          );

          this.analyticsService.sendSimpleEvent(AnalyticsEvent.EmailTemplate.Deleted, {
            Name: options.emailTemplate.name,
            Source: options.analyticsSource
          });

          return true;
        }),
        catchError(error => {
          if (error instanceof ServerRequestError && error.errors.length) {
            this.notificationService.error('Reset Failed', error.errors[0]);
          } else {
            this.notificationService.error('Reset Failed', errorToString(error));
          }

          return throwError(error);
        })
      );
  }
}
