var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import toPairs from 'lodash/toPairs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { debounceTime, map } from 'rxjs/operators';
import { cleanElementName, CustomElementItem, ViewContext, ViewContextElement } from '@modules/customize';
import { createFormFieldFactory, Input as FieldInput, InputValueType } from '@modules/fields';
import { CustomViewTemplateType } from '@modules/views';
import { CustomizeBarEditEventType } from '../../data/customize-bar-edit-event-type';
import { CustomizeBarContext } from '../../services/customize-bar-context/customize-bar.context';
import { CustomizeBarCustomViewEditForm } from './customize-bar-custom-view-edit.form';
var CustomizeBarCustomViewEditComponent = /** @class */ (function () {
    function CustomizeBarCustomViewEditComponent(form, customizeBarContext, cd) {
        var _this = this;
        this.form = form;
        this.customizeBarContext = customizeBarContext;
        this.cd = cd;
        this.titleEnabled = true;
        this.deleteEnabled = false;
        this.firstInit = false;
        this.event = new EventEmitter();
        this.createField = createFormFieldFactory();
        this.customViewTemplateTypes = CustomViewTemplateType;
        this.titleCleanValue = (function () {
            return function (value) {
                return cleanElementName(value, _this.element, _this.context.getElementItems());
            };
        })();
    }
    CustomizeBarCustomViewEditComponent.prototype.trackActionOutput = function (i, item) {
        return item.name;
    };
    CustomizeBarCustomViewEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.form
            .init(this.element, { context: this.context })
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            _this.customViewDefaults = _this.form.getCustomViewDefaults();
            _this.form.valueChanges
                .pipe(debounceTime(200), map(function () { return _this.form.submit(); }), untilDestroyed(_this))
                .subscribe(function (result) {
                _this.emitUpdate(result);
            });
            _this.form
                .getCustomView$()
                .pipe(untilDestroyed(_this))
                .subscribe(function (customView) {
                _this.customView = customView;
                _this.cd.markForCheck();
            });
        });
    };
    CustomizeBarCustomViewEditComponent.prototype.ngOnDestroy = function () { };
    CustomizeBarCustomViewEditComponent.prototype.onTitleChanged = function (title) {
        this.form.controls.name.setValue(title);
    };
    CustomizeBarCustomViewEditComponent.prototype.close = function () {
        this.customizeBarContext.popSettingsComponent();
    };
    CustomizeBarCustomViewEditComponent.prototype.submit = function () {
        var result = this.form.submit();
        this.emitUpdate(result, true);
        this.close();
    };
    CustomizeBarCustomViewEditComponent.prototype.emitUpdate = function (result, submit) {
        if (submit === void 0) { submit = false; }
        if (this.element) {
            this.event.emit({ type: CustomizeBarEditEventType.Updated, args: { result: result, submit: submit } });
        }
        else {
            this.event.emit({ type: CustomizeBarEditEventType.Created, args: { result: result, submit: submit } });
        }
    };
    CustomizeBarCustomViewEditComponent.prototype.cancel = function () {
        this.event.emit({ type: CustomizeBarEditEventType.Canceled });
        this.close();
    };
    CustomizeBarCustomViewEditComponent.prototype.delete = function () {
        this.event.emit({ type: CustomizeBarEditEventType.Deleted });
        this.close();
    };
    CustomizeBarCustomViewEditComponent.prototype.getCustomViewTestParameters = function (customView) {
        return toPairs(customView.testParameters).map(function (_a) {
            var name = _a[0], value = _a[1];
            var result = new FieldInput();
            result.path = [name];
            result.valueType = InputValueType.StaticValue;
            result.staticValue = value;
            return result;
        });
    };
    CustomizeBarCustomViewEditComponent.prototype.onCustomViewSelected = function (customView) {
        if (customView) {
            this.form.patchValue(__assign({ source: customView.source, custom_view: customView.uniqueName, parameters: customView.parameters }, (customView.uniqueName != this.form.controls.custom_view.value && {
                inputs: this.getCustomViewTestParameters(customView)
            })));
        }
        else {
            this.form.patchValue({
                source: customView.source,
                custom_view: undefined,
                parameters: [],
                inputs: {}
            });
        }
    };
    CustomizeBarCustomViewEditComponent.prototype.asView = function (value) {
        return value;
    };
    return CustomizeBarCustomViewEditComponent;
}());
export { CustomizeBarCustomViewEditComponent };
