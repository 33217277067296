<app-external-fonts [fonts]="externalFonts"></app-external-fonts>

<app-layout
  *ngIf="!domainLoading"
  class="theme-enabled"
  appDomainThemeOptions
  [domain]="domain"
  [layout]="layout"
  [backgroundImage]="domain?.signUpBackgroundImage"
  [backgroundColor]="domain?.signUpBackgroundColor"
  [fillScreen]="true"
>
  <app-page-block [marginSize]="'l'" [paddingSize]="'none'">
    <app-page-block *ngIf="loading">
      <app-loader [title]="'Loading'">{{ 'Please wait...' | localize }}</app-loader>
    </app-page-block>

    <ng-container *ngIf="!loading && (error | appIsSet)">
      <app-page-header [marginSize]="'l'" [first]="layout.startsWith('poster_')">
        <app-page-logo [align]="'center'">
          <app-project-logo
            *ngIf="isWhiteLabel && domain && (domain.logo || domain.initials)"
            [color]="domain.logoColor ? domain.logoColor : ''"
            [logo]="domain.logo ? domain.logo : ''"
            [initials]="domain.initials ? domain.initials : ''"
          ></app-project-logo>

          <app-project-logo
            *ngIf="!isWhiteLabel"
            [logo]="'/assets/images/logo.svg' | appDeployUrl"
            [size]="'sm'"
          ></app-project-logo>
        </app-page-logo>

        <app-page-title [align]="'center'" [strong]="true">
          {{ 'Email change' | localize }}
        </app-page-title>
      </app-page-header>

      <app-page-block>
        <div class="error-notification">
          <div class="error-notification__background"></div>
          <div class="error-notification__content">
            <div class="error-notification__title">{{ 'Unable to Change Email' | localize }}</div>
            <div class="error-notification__description">
              {{ error }}
            </div>
          </div>
        </div>
      </app-page-block>
    </ng-container>
  </app-page-block>

  <ng-container *ngIf="layout == 'form'" data-layout-footer>
    <a [routerLink]="homeLink" queryParamsHandling="preserve" class="link-block form-layout__footer-item">
      {{ 'Home' | localize }}
    </a>
  </ng-container>

  <app-page-block *ngIf="layout != 'form'" [marginSize]="'l'" data-layout-footer>
    <a [routerLink]="homeLink" queryParamsHandling="preserve" class="link-block">
      {{ 'Home' | localize }}
    </a>
  </app-page-block>
</app-layout>

<app-language-selector></app-language-selector>

<app-custom-code
  *ngIf="domain"
  [appendScripts]="domain.appendScripts"
  [appendStyles]="domain.appendStyles"
></app-custom-code>
