import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from '@shared';
import { UiModule } from '@ui';

import { StorageRemovePopupComponent } from './components/storage-remove-popup/storage-remove-popup.component';
import { StorageUploadPopupComponent } from './components/storage-upload-popup/storage-upload-popup.component';

@NgModule({
  imports: [CommonModule, UiModule, SharedModule],
  declarations: [StorageUploadPopupComponent, StorageRemovePopupComponent],
  entryComponents: [StorageUploadPopupComponent, StorageRemovePopupComponent]
})
export class StoragesComponentsModule {}
