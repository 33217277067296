/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../directives/custom-element/custom-element.directive";
import * as i2 from "@angular/common";
import * as i3 from "../../../../shared/pipes/is-set/is-set.pipe";
import * as i4 from "./custom-element-js.component";
import * as i5 from "../../../projects/stores/current-project.store";
import * as i6 from "../../../projects/stores/current-environment.store";
import * as i7 from "../../../customize/services/customize/customize.service";
import * as i8 from "../../../custom-views/services/custom-view/custom-view.service";
import * as i9 from "../../../custom-views/stores/custom-views.store";
import * as i10 from "../../../custom-views/services/custom-view-loader/custom-view-loader.service";
var styles_CustomElementJsComponent = [];
var RenderType_CustomElementJsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CustomElementJsComponent, data: {} });
export { RenderType_CustomElementJsComponent as RenderType_CustomElementJsComponent };
function View_CustomElementJsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["appCustomElement", ""]], null, [[null, "actionEmitted"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("actionEmitted" === en)) {
        var pd_0 = (_co.actionEmitted.emit({ name: $event.name, data: $event.data }) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 737280, null, 0, i1.CustomElementDirective, [i0.ElementRef], { tagName: [0, "tagName"], parameters: [1, "parameters"], inputs: [2, "inputs"], actions: [3, "actions"] }, { actionEmitted: "actionEmitted" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.customView.tagNameEffective; var currVal_1 = _co.parameters; var currVal_2 = _co.params; var currVal_3 = _co.customView.actions; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_CustomElementJsComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" This component is not configured yet\n"]))], null, null); }
function View_CustomElementJsComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "div", [["class", "element__message-background"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 5, "div", [["class", "element__message"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 3, "div", [["class", "element-message"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" This component was updated."])), (_l()(), i0.ɵeld(5, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Reload the page to see updated version. "])), (_l()(), i0.ɵeld(7, 0, null, null, 0, "div", [["class", "element__message-close icon-close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.hideCustomElementBuildIdCheck() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
function View_CustomElementJsComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 3, null, View_CustomElementJsComponent_4)), i0.ɵdid(2, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i2.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵppd(4, 1), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((((i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform(_co.customizeService.enabled$)) && _v.context.ngIf.loaded) && i0.ɵunv(_v, 2, 0, _ck(_v, 4, 0, i0.ɵnov(_v.parent, 0), _co.customView.buildId))) && (_v.context.ngIf.buildId != _co.customView.buildId)) && !_co.customElementBuildIdCheckHidden); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_CustomElementJsComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i3.IsSetPipe, []), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomElementJsComponent_1)), i0.ɵdid(2, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomElementJsComponent_2)), i0.ɵdid(4, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomElementJsComponent_3)), i0.ɵdid(6, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.customView.filesJs.length; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.customView.filesJs.length; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.customViewLoaderService.isLoaded(_co.customView); _ck(_v, 6, 0, currVal_2); }, null); }
export function View_CustomElementJsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-custom-element-js", [], null, null, null, View_CustomElementJsComponent_0, RenderType_CustomElementJsComponent)), i0.ɵdid(1, 770048, null, 0, i4.CustomElementJsComponent, [i5.CurrentProjectStore, i6.CurrentEnvironmentStore, i7.CustomizeService, i8.CustomViewService, i9.CustomViewsStore, i10.CustomViewLoaderService, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CustomElementJsComponentNgFactory = i0.ɵccf("app-custom-element-js", i4.CustomElementJsComponent, View_CustomElementJsComponent_Host_0, { customView: "customView", parameters: "parameters", params: "params" }, { actionEmitted: "actionEmitted" }, []);
export { CustomElementJsComponentNgFactory as CustomElementJsComponentNgFactory };
