/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../model-card-item-action/model-card-item-action.component.ngfactory";
import * as i2 from "../../model-card-item-action/model-card-item-action.component";
import * as i3 from "../../../../customize/services/customize/customize.service";
import * as i4 from "../../../../action-queries/services/action/action.service";
import * as i5 from "../../../../action-queries/services/action-controller/action-controller.service";
import * as i6 from "../../../../../common/notifications/services/notification/notification.service";
import * as i7 from "@angular/common";
import * as i8 from "../../../../fields/components/auto-field/auto-field.component.ngfactory";
import * as i9 from "../../../../fields/components/auto-field/auto-field.component";
import * as i10 from "../../../../custom-elements-components/components/custom-element-auto/custom-element-auto.component.ngfactory";
import * as i11 from "../../../../custom-elements-components/components/custom-element-auto/custom-element-auto.component";
import * as i12 from "../../../../../shared/pipes/is-set/is-set.pipe";
import * as i13 from "../../../../../shared/pipes/safe-async/safe-async.pipe";
import * as i14 from "./model-card-item.component";
import * as i15 from "../../../../custom-views/stores/custom-views.store";
var styles_ModelCardItemComponent = [];
var RenderType_ModelCardItemComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ModelCardItemComponent, data: {} });
export { RenderType_ModelCardItemComponent as RenderType_ModelCardItemComponent };
function View_ModelCardItemComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-model-card-item-action", [], [[2, "element__indicator-wrapper", null], [2, "hidden", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = ($event.stopPropagation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_ModelCardItemActionComponent_0, i1.RenderType_ModelCardItemActionComponent)), i0.ɵdid(1, 770048, null, 0, i2.ModelCardItemActionComponent, [i3.CustomizeService, i4.ActionService, i5.ActionControllerService, i6.NotificationService, i0.Injector, i0.ChangeDetectorRef], { model: [0, "model"], action: [1, "action"], context: [2, "context"], contextElement: [3, "contextElement"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.model; var currVal_3 = _v.context.$implicit; var currVal_4 = _co.context; var currVal_5 = _co.contextElement; _ck(_v, 1, 0, currVal_2, currVal_3, currVal_4, currVal_5); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).indicatorWrapper; var currVal_1 = i0.ɵnov(_v, 1).hidden; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_ModelCardItemComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "label", [["class", "model-card__item-label"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ModelCardItemComponent_2)), i0.ɵdid(3, 278528, null, 0, i7.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.actions; _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.item.verboseName; _ck(_v, 1, 0, currVal_0); }); }
function View_ModelCardItemComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-auto-field", [], null, null, null, i8.View_AutoFieldComponent_0, i8.RenderType_AutoFieldComponent)), i0.ɵdid(1, 770048, null, 0, i9.AutoFieldComponent, [i0.ChangeDetectorRef], { field: [0, "field"], readonly: [1, "readonly"], value: [2, "value"], label: [3, "label"], context: [4, "context"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.createField(_co.item); var currVal_1 = true; var currVal_2 = _co.model.getAttribute(_co.item.name); var currVal_3 = false; var currVal_4 = _co.context; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
function View_ModelCardItemComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "])), i0.ɵppd(2, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i0.ɵnov(_v.parent.parent, 1), _co.valueStr)); _ck(_v, 1, 0, currVal_0); }); }
function View_ModelCardItemComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_ModelCardItemComponent_4)), i0.ɵdid(2, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵppd(3, 1), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_ModelCardItemComponent_5)), i0.ɵdid(5, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵppd(6, 1), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !i0.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i0.ɵnov(_v.parent, 0), _co.valueStr)); _ck(_v, 2, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i0.ɵnov(_v.parent, 0), _co.valueStr)); _ck(_v, 5, 0, currVal_1); }, null); }
function View_ModelCardItemComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-custom-element-auto", [], null, null, null, i10.View_CustomElementAutoComponent_0, i10.RenderType_CustomElementAutoComponent)), i0.ɵdid(1, 245760, null, 0, i11.CustomElementAutoComponent, [i5.ActionControllerService, i0.Injector], { source: [0, "source"], customView: [1, "customView"], params: [2, "params"], context: [3, "context"], contextElement: [4, "contextElement"] }, null), i0.ɵpid(131072, i7.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.customView.source; var currVal_1 = _co.customView; var currVal_2 = i0.ɵunv(_v, 1, 2, i0.ɵnov(_v, 2).transform(_co.viewParams$)); var currVal_3 = _co.context; var currVal_4 = _co.contextElement; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
export function View_ModelCardItemComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i12.IsSetPipe, []), i0.ɵpid(0, i13.SafeAsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(2, 0, null, null, 7, "div", [["class", "model-card__item"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ModelCardItemComponent_1)), i0.ɵdid(4, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(5, 0, null, null, 4, "div", [["class", "model-card__item-value"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ModelCardItemComponent_3)), i0.ɵdid(7, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ModelCardItemComponent_6)), i0.ɵdid(9, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.label; _ck(_v, 4, 0, currVal_0); var currVal_1 = !_co.customView; _ck(_v, 7, 0, currVal_1); var currVal_2 = _co.customView; _ck(_v, 9, 0, currVal_2); }, null); }
export function View_ModelCardItemComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-model-card-item", [], null, null, null, View_ModelCardItemComponent_0, RenderType_ModelCardItemComponent)), i0.ɵdid(1, 770048, null, 0, i14.ModelCardItemComponent, [i15.CustomViewsStore, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ModelCardItemComponentNgFactory = i0.ɵccf("app-model-card-item", i14.ModelCardItemComponent, View_ModelCardItemComponent_Host_0, { item: "item", model: "model", columns: "columns", valueStr: "valueStr", label: "label", columnActions: "columnActions", context: "context", contextElement: "contextElement" }, {}, []);
export { ModelCardItemComponentNgFactory as ModelCardItemComponentNgFactory };
