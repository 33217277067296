<app-element-wrapper
  [wrap]="wrapper && visibleDisplay"
  [alignHorizontal]="alignHorizontalSupported ? element.alignHorizontalOrDefault : undefined"
  [alignVertical]="alignVerticalSupported ? element.alignVertical : undefined"
  [fillVertical]="fillVertical"
  #element_wrapper
>
  <div
    class="element"
    [class.element_hidden]="!visibleDisplay"
    [class.element_inline]="alignHorizontalSupported ? element.isInline() : false"
    [class.element_inline-vertical]="alignVerticalSupported ? element.isInlineVertical() : false"
    [class.element_align-horizontal_left]="
      alignHorizontalSupported ? element.alignHorizontalOrDefault == alignsHorizontal.Left : false
    "
    [class.element_align-horizontal_center]="
      alignHorizontalSupported ? element.alignHorizontalOrDefault == alignsHorizontal.Center : false
    "
    [class.element_align-horizontal_right]="
      alignHorizontalSupported ? element.alignHorizontalOrDefault == alignsHorizontal.Right : false
    "
    [class.element_padding-h_normal]="paddingEnabled && padding.horizontal == paddingTypes.Normal"
    [class.element_padding-h_double-after]="paddingEnabled && padding.horizontal == paddingTypes.DoubleAfter"
    [class.element_padding-h_double-before]="paddingEnabled && padding.horizontal == paddingTypes.DoubleBefore"
    [class.element_padding-v_normal]="paddingEnabled && padding.vertical == paddingTypes.Normal"
    [class.element_padding-v_double-after]="paddingEnabled && padding.vertical == paddingTypes.DoubleAfter"
    [class.element_padding-v_double-before]="paddingEnabled && padding.vertical == paddingTypes.DoubleBefore"
    [class.element_hover]="hover"
    [class.element_hover-another]="hoverAnother"
    [class.element_dragging-over]="draggingOver"
    [class.element_active]="customizingElement || resizingElement || elementComponent?.alwaysActive"
    [class.element_container]="isContainer"
    [class.element_customizable]="customizeElementEnabled"
    [class.element_resizing]="resizingElement"
    [class.element_disable]="interactionsDisabled"
    [class.handle]="customizing && !customizingElement && !customizeElementAlways"
    [class.handle_disable]="customizing && customizingElement"
    [style.width]="resizeSupported.width ? (width ? width + 'px' : '100%') : null"
    [style.height.px]="resizeSupported.height && height ? height : null"
    [style.padding-top.px]="(element.margin.top | appIsSet) ? element.margin.top : null"
    [style.padding-right.px]="(element.margin.right | appIsSet) ? element.margin.right : null"
    [style.padding-bottom.px]="(element.margin.bottom | appIsSet) ? element.margin.bottom : null"
    [style.padding-left.px]="(element.margin.left | appIsSet) ? element.margin.left : null"
    appDragHandle
    appResizable
    (resizeStarted)="onResizeStarted()"
    (resizeFinished)="onResize($event)"
    [appDragHandleDisabled]="customizingElement || customizeElementAlways"
    (click)="customize({ event: $event }); markElementEvent($event)"
  >
    <app-auto-element-border
      *ngIf="customizing"
      [resizeSupported]="resizeSupported"
      [resizeEnabled]="resizeEnabled"
      [resizeStep]="resizeStep"
      [resizeMinSize]="resizeMinSize"
      [resizeMaxSize]="resizeMaxSize"
      [alignHorizontal]="alignHorizontalSupported ? element.alignHorizontalOrDefault : undefined"
      [alignVertical]="alignVerticalSupported ? element.alignVertical : undefined"
      [containerHorizontal]="containerHorizontal"
    ></app-auto-element-border>

    <app-auto-element-toolbar
      *ngIf="mode == adminModes.Builder"
      class="element__toolbar"
      [class.element__toolbar_bottom]="false"
      [displayName]="displayName"
      [alignmentHorizontal]="element.alignHorizontalOrDefault"
      [alignmentVertical]="element.alignVertical"
      [parentElement]="parentElement"
      [parentPopup]="parentPopup?.popup"
      [elementComponent]="elementComponent"
      [alignmentHorizontalEnabled]="alignHorizontalSupported"
      [alignmentVerticalEnabled]="alignVerticalSupported"
      [customizeEnabled]="customizeElementEnabled"
      [duplicateEnabled]="addElementEnabled"
      [moveEnabled]="addElementEnabled"
      [deleteEnabled]="deleteElementEnabled"
      [configured]="configured"
      [bottom]="toolbarBottom"
      [active]="customizingElement"
      [tipAddShowDelay]="hover && !customizingElement"
      (customize)="customize({ event: $event, highlight: true })"
      (customizeParent)="onHover(false); parentElement.customize({ event: $event, forceHover: true })"
      (customizePopup)="onHover(false); parentPopup.customize({ event: $event })"
      (alignHorizontal)="alignHorizontal($event)"
      (alignVertical)="alignVertical($event)"
      (action)="actionClicked.next($event)"
      (duplicate)="duplicateElement()"
      (moveTo)="moveElementToPage($event)"
      (delete)="deleteElement()"
      (setDefaultElement)="setDefaultElement()"
      (createTemplate)="createTemplate()"
    ></app-auto-element-toolbar>

    <div
      *ngIf="customizing && isCreateColumnsAllowed() && draggingOver"
      class="element__sibling element__sibling_left"
      [class.element__sibling_active]="siblingLeftEntered$ | async"
      [class.element__sibling_active-another]="siblingRightEntered$ | async"
      [style.margin-top.px]="(element.margin.top | appIsSet) ? element.margin.top * -1 : null"
      [style.margin-bottom.px]="(element.margin.bottom | appIsSet) ? element.margin.bottom * -1 : null"
      [style.margin-left.px]="(element.margin.left | appIsSet) ? element.margin.left * -1 : null"
      appDropList
      [appDropListEnterPredicate]="elementCanEnterSibling"
      [appDropListPrevent]="true"
      (mouseenter)="siblingLeftEntered$.next(true)"
      (mouseleave)="siblingLeftEntered$.next(false)"
    >
      <div *ngIf="siblingLeftEntered$ | async" class="element-placeholder-stub">
        <div class="element-placeholder-stub__inner"></div>
      </div>
    </div>

    <span
      class="element__content"
      [class.element__content_disabled]="!configured"
      [class.element__content_silings-enabled]="isCreateColumnsAllowed() && draggingOver"
    >
      <app-dynamic-component *ngIf="componentData" [componentData]="componentData"></app-dynamic-component>
    </span>

    <div
      *ngIf="customizing && isCreateColumnsAllowed() && draggingOver"
      class="element__sibling element__sibling_right"
      [class.element__sibling_active]="siblingRightEntered$ | async"
      [class.element__sibling_active-another]="siblingLeftEntered$ | async"
      [style.margin-top.px]="(element.margin.top | appIsSet) ? element.margin.top * -1 : null"
      [style.margin-right.px]="(element.margin.right | appIsSet) ? element.margin.right * -1 : null"
      [style.margin-bottom.px]="(element.margin.bottom | appIsSet) ? element.margin.bottom * -1 : null"
      appDropList
      [appDropListEnterPredicate]="elementCanEnterSibling"
      [appDropListPrevent]="true"
      (mouseenter)="siblingRightEntered$.next(true)"
      (mouseleave)="siblingRightEntered$.next(false)"
    >
      <div *ngIf="siblingRightEntered$ | async" class="element-placeholder-stub">
        <div class="element-placeholder-stub__inner"></div>
      </div>
    </div>

    <span
      *ngIf="customizing && !configured && (customizeService.enabled$ | async) && !customizingElementFirstInit"
      class="element__indicator element__indicator_error icon-warning_filled"
      [appTip]="'Component is not configured'"
    ></span>

    <span
      *ngIf="customizing && configured && !visible && (customizeService.enabled$ | async)"
      class="element__indicator icon-eye"
      [appTip]="'Component is hidden'"
    ></span>
  </div>
</app-element-wrapper>
