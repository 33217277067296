/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./standalone-custom-view.component";
import * as i2 from "../../../custom-views/services/custom-view-loader/custom-view-loader.service";
var styles_StandaloneCustomViewComponent = [];
var RenderType_StandaloneCustomViewComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_StandaloneCustomViewComponent, data: {} });
export { RenderType_StandaloneCustomViewComponent as RenderType_StandaloneCustomViewComponent };
export function View_StandaloneCustomViewComponent_0(_l) { return i0.ɵvid(2, [i0.ɵqud(402653184, 1, { container: 0 }), (_l()(), i0.ɵeld(1, 0, [[1, 0], ["container", 1]], null, 0, "div", [], null, null, null, null, null))], null, null); }
export function View_StandaloneCustomViewComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-standalone-custom-view", [], null, null, null, View_StandaloneCustomViewComponent_0, RenderType_StandaloneCustomViewComponent)), i0.ɵdid(1, 4898816, null, 0, i1.StandaloneCustomViewComponent, [i2.CustomViewLoaderService], null, null)], null, null); }
var StandaloneCustomViewComponentNgFactory = i0.ɵccf("app-standalone-custom-view", i1.StandaloneCustomViewComponent, View_StandaloneCustomViewComponent_Host_0, { customView: "customView", attrs: "attrs" }, {}, []);
export { StandaloneCustomViewComponentNgFactory as StandaloneCustomViewComponentNgFactory };
