var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import fromPairs from 'lodash/fromPairs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, of } from 'rxjs';
import { delayWhen, filter, map, switchMap, tap } from 'rxjs/operators';
import { CustomView, customViewInstanceToData, CustomViewService, CustomViewSource, CustomViewsStore, CustomViewType } from '@modules/custom-views';
import { ViewContext, ViewContextElement } from '@modules/customize';
import { IDEController, IDEService } from '@modules/ide-components';
import { ViewContextTokenProvider } from '@modules/parameters-components';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { CurrentUserStore } from '@modules/users';
import { CustomViewTemplateCounterStore, Frame, View } from '@modules/views';
import { controlValue, isSet } from '@shared';
// TODO: Refactor import
import { CustomViewMapParametersController } from '../../../custom-elements-components/services/custom-view-map-parameters-controller/custom-view-map-parameters.controller';
import { CustomViewTemplatesController } from '../../../views-components/services/custom-view-templates-controller/custom-view-templates.controller';
import { ViewEditorController } from '../../../views-components/services/view-editor-controller/view-editor.controller';
var CustomViewItemEditComponent = /** @class */ (function () {
    function CustomViewItemEditComponent(currentUserStore, viewEditorController, ideService, ideController, currentProjectStore, currentEnvironmentStore, customViewService, customViewsStore, customViewTemplateCounterStore, customViewTemplatesController, customViewMapParametersController, contextTokenProvider, cd) {
        this.currentUserStore = currentUserStore;
        this.viewEditorController = viewEditorController;
        this.ideService = ideService;
        this.ideController = ideController;
        this.currentProjectStore = currentProjectStore;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.customViewService = customViewService;
        this.customViewsStore = customViewsStore;
        this.customViewTemplateCounterStore = customViewTemplateCounterStore;
        this.customViewTemplatesController = customViewTemplatesController;
        this.customViewMapParametersController = customViewMapParametersController;
        this.contextTokenProvider = contextTokenProvider;
        this.cd = cd;
        this.stateSelectedEnabled = false;
        this.subtitle = 'Custom component';
        this.initialTemplatesFilter = {};
        this.deleteEnabled = false;
        this.componentLabel = 'component';
        this.viewType = CustomViewType.Component;
        this.selectCustomView = new EventEmitter();
        this.loading = false;
        this.customViewsShared = [];
        this.valueShared = false;
        this.sources = CustomViewSource;
    }
    CustomViewItemEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        combineLatest(this.customViewsStore.get(), this.customViewName ? controlValue(this.customViewName) : of(undefined))
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var customViews = _a[0], customView = _a[1];
            _this.customView = isSet(customView) ? customViews.find(function (item) { return item.uniqueName == customView; }) : undefined;
            _this.customViewsShared = customViews.filter(function (item) { return item.shared; });
            _this.valueShared = isSet(customView)
                ? _this.customViewsShared.some(function (item) { return item.uniqueName == customView; })
                : undefined;
            _this.cd.markForCheck();
        });
        this.customViewTemplateCounterStore
            .getApproxFirst$()
            .pipe(untilDestroyed(this))
            .subscribe(function (value) {
            _this.templatesApprox = value;
            _this.cd.markForCheck();
        });
    };
    CustomViewItemEditComponent.prototype.ngOnDestroy = function () { };
    CustomViewItemEditComponent.prototype.createView = function () {
        var result = new View();
        result.generateId();
        result.frame = new Frame({ width: 300, height: 240 });
        return result;
    };
    CustomViewItemEditComponent.prototype.updateOrCreateCustomView = function (options) {
        var _this = this;
        var existingInstance = this.customView;
        var uniqueName = this.customViewName.value;
        var instance = existingInstance || new CustomView();
        var defaults = this.customViewDefaults;
        if (isSet(uniqueName)) {
            instance.uniqueName = uniqueName;
        }
        else {
            instance.uniqueName = defaults.uniqueName;
        }
        instance.viewType = this.viewType;
        instance.source = options.source;
        instance.pageUid = defaults.pageUid;
        instance.elementUid = defaults.elementUid;
        if (!existingInstance && options.source == CustomViewSource.CustomElement) {
            instance.filesIde = true;
        }
        var fields = ['unique_name', 'view_type', 'source', 'params'];
        if (options.beforeSave) {
            options.beforeSave(instance, fields);
        }
        var obs$ = existingInstance
            ? this.customViewService.update(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, instance, { draft: true, fields: fields })
            : this.customViewService.create(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, instance, { draft: true, fields: fields });
        return obs$.pipe(tap(function (customView) { return _this.customViewsStore.updateOrAddItem(customView); }));
    };
    CustomViewItemEditComponent.prototype.openViewEditor = function (customView) {
        var _this = this;
        var view = customView ? customView.view : undefined;
        var create = !view;
        if (!view) {
            view = this.createView();
        }
        var data$ = customView
            ? of({
                source: CustomViewSource.View,
                view: view,
                name: customView.name,
                parameters: customView.parameters,
                actions: customView.actions,
                testParameters: customView.testParameters
            })
            : this.customViewsStore.generateName().pipe(map(function (name) { return ({
                source: CustomViewSource.View,
                view: view,
                name: name
            }); }));
        data$
            .pipe(switchMap(function (data) {
            return _this.viewEditorController.open({
                create: create,
                data: __assign({}, data, (_this.sourceParameters && {
                    parameters: _this.sourceParameters,
                    testParameters: fromPairs(_this.sourceParameters
                        .map(function (parameter) {
                        var testValue = _this.contextElement.getFieldValue(parameter.name);
                        return [parameter.name, testValue];
                    })
                        .filter(function (item) { return isSet(item); }))
                })),
                componentLabel: _this.componentLabel,
                submitLabel: create ? 'Create component' : 'Save changes',
                stateSelectedEnabled: _this.stateSelectedEnabled,
                analyticsSource: _this.analyticsSource
            });
        }), filter(function (result) { return !result.cancelled; }), switchMap(function (result) {
            if (result.useShared) {
                return of(result.useShared);
            }
            else {
                _this.loading = true;
                _this.cd.markForCheck();
                return _this.updateOrCreateCustomView({
                    source: CustomViewSource.View,
                    beforeSave: function (instance, fields) {
                        instance.name = result.data.name;
                        instance.view = result.data.view;
                        instance.parameters = result.data.parameters;
                        instance.actions = result.data.actions;
                        instance.testParameters = result.data.testParameters;
                        fields.push('name', 'view');
                    }
                });
            }
        }), untilDestroyed(this))
            .subscribe(function (result) {
            _this.loading = false;
            _this.cd.markForCheck();
            _this.onSelectCustomView(result);
        }, function (error) {
            console.error(error);
            _this.loading = false;
            _this.cd.markForCheck();
        });
    };
    CustomViewItemEditComponent.prototype.openIDE = function (customView) {
        var _this = this;
        if (customView) {
            this.loading = true;
            this.cd.markForCheck();
            return this.ideController
                .open({
                projectName: this.currentProjectStore.instance.uniqueName,
                environmentName: this.currentEnvironmentStore.instance.uniqueName,
                customViewName: customView.uniqueName
            })
                .pipe(untilDestroyed(this))
                .subscribe(function () {
                _this.loading = false;
                _this.cd.markForCheck();
            }, function (error) {
                console.error(error);
                _this.loading = false;
                _this.cd.markForCheck();
            });
        }
        else {
            this.loading = true;
            this.cd.markForCheck();
            this.updateOrCreateCustomView({
                source: CustomViewSource.CustomElement
            })
                .pipe(tap(function (result) {
                _this.onSelectCustomView(result);
            }), switchMap(function (result) {
                return _this.ideController.open({
                    projectName: _this.currentProjectStore.instance.uniqueName,
                    environmentName: _this.currentEnvironmentStore.instance.uniqueName,
                    customViewName: result.uniqueName
                });
            }), untilDestroyed(this))
                .subscribe(function () {
                _this.loading = false;
                _this.cd.markForCheck();
            }, function (error) {
                console.error(error);
                _this.loading = false;
                _this.cd.markForCheck();
            });
        }
    };
    CustomViewItemEditComponent.prototype.openCustomViewTemplates = function () {
        var _this = this;
        this.customViewTemplatesController
            .chooseTemplate({
            initialFilter: this.initialTemplatesFilter,
            viewCreateEnabled: true,
            stateSelectedEnabled: this.stateSelectedEnabled,
            componentLabel: this.componentLabel,
            analyticsSource: this.analyticsSource
        })
            .pipe(filter(function (result) { return !result.cancelled; }), switchMap(function (result) {
            if (result.useShared) {
                return of(result.useShared);
            }
            else {
                _this.loading = true;
                _this.cd.markForCheck();
                return _this.updateOrCreateCustomView({
                    source: CustomViewSource.View,
                    beforeSave: function (instance, fields) {
                        instance.name = result.data.name;
                        instance.view = result.data.view;
                        instance.parameters = result.data.parameters;
                        instance.actions = result.data.actions;
                        instance.testParameters = result.data.testParameters;
                        fields.push('name', 'view');
                    }
                });
            }
        }), untilDestroyed(this))
            .subscribe(function (customView) {
            _this.loading = false;
            _this.cd.markForCheck();
            if (_this.mappingsControl) {
                _this.customViewMapParametersController
                    .open({
                    sourceParameters: _this.sourceParameters,
                    data: {
                        source: customView.source,
                        view: customView.view,
                        name: customView.name,
                        parameters: customView.parameters,
                        actions: customView.actions,
                        testParameters: customView.testParameters
                    },
                    context: _this.context,
                    contextElement: _this.contextElement,
                    contextElementPath: _this.contextElementPath,
                    contextElementPaths: _this.contextElementPaths,
                    contextTokenProvider: _this.contextTokenProvider,
                    analyticsSource: _this.analyticsSource
                })
                    .pipe(filter(function (mappingResult) { return !mappingResult.cancelled; }), untilDestroyed(_this))
                    .subscribe(function (mappingResult) {
                    _this.selectCustomView.emit(customView);
                    _this.setMappingValue(mappingResult.mappings);
                });
            }
            else {
                _this.selectCustomView.emit(customView);
            }
        }, function () {
            _this.loading = false;
            _this.cd.markForCheck();
        });
    };
    CustomViewItemEditComponent.prototype.updateCustomViewTemplate = function (customView) {
        var view = customView ? customView.view : undefined;
        if (!view) {
            return;
        }
        this.customViewTemplatesController
            .setTemplateView(view, { stateSelectedEnabled: this.stateSelectedEnabled, componentLabel: this.componentLabel })
            .pipe(filter(function (result) { return !result.cancelled; }), untilDestroyed(this))
            .subscribe();
    };
    CustomViewItemEditComponent.prototype.resetCustomView = function () {
        this.onSelectCustomView(undefined);
        this.setMappingValue([]);
    };
    CustomViewItemEditComponent.prototype.cloneCustomView = function (customView, options) {
        var _this = this;
        var instance = new CustomView();
        instance.uniqueName = CustomView.generateUniqueName();
        instance.name = customView.name;
        instance.viewType = CustomViewType.MenuItem;
        instance.source = customView.source;
        instance.parameters = cloneDeep(customView.parameters);
        instance.actions = cloneDeep(customView.actions);
        instance.testParameters = cloneDeep(customView.testParameters);
        instance.shared = options.shared;
        if (customView.source == CustomViewSource.View) {
            instance.view = customView.view;
        }
        else if (customView.source == CustomViewSource.HTML) {
            instance.html = customView.html;
        }
        else if (customView.source == CustomViewSource.CustomElement) {
            instance.buildId = customView.buildId;
            instance.tagName = customView.tagName;
            instance.baseTagName = customView.baseTagName;
            instance.filesJs = cloneDeep(customView.filesJs);
            instance.filesCss = cloneDeep(customView.filesCss);
        }
        return this.customViewService
            .create(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, instance, { draft: true })
            .pipe(delayWhen(function (result) {
            if (customView.source == CustomViewSource.CustomElement) {
                return _this.ideService.clone(_this.currentProjectStore.instance.uniqueName, _this.currentEnvironmentStore.instance.uniqueName, customView.uniqueName, result.uniqueName);
            }
            else {
                return of(undefined);
            }
        }), tap(function (result) { return _this.customViewsStore.addItem(result); }));
    };
    CustomViewItemEditComponent.prototype.createCustomViewShared = function (customView) {
        var _this = this;
        this.loading = true;
        this.cd.markForCheck();
        this.cloneCustomView(customView, { shared: true })
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            _this.loading = false;
            _this.cd.markForCheck();
            _this.selectCustomView.emit(result);
        }, function () {
            _this.loading = false;
            _this.cd.markForCheck();
        });
    };
    CustomViewItemEditComponent.prototype.detachCustomViewShared = function (customView) {
        var _this = this;
        this.loading = true;
        this.cd.markForCheck();
        this.cloneCustomView(customView, { shared: false })
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            _this.loading = false;
            _this.cd.markForCheck();
            _this.selectCustomView.emit(result);
        }, function () {
            _this.loading = false;
            _this.cd.markForCheck();
        });
    };
    CustomViewItemEditComponent.prototype.changeMapping = function (customView) {
        var _this = this;
        var mappings = this.mappingsControl.value;
        if (!this.sourceParameters.length) {
            return;
        }
        this.customViewMapParametersController
            .open({
            sourceParameters: this.sourceParameters,
            data: customViewInstanceToData(customView),
            mappings: mappings,
            context: this.context,
            contextElement: this.contextElement,
            contextElementPath: this.contextElementPath,
            contextElementPaths: this.contextElementPaths,
            contextTokenProvider: this.contextTokenProvider,
            analyticsSource: this.analyticsSource
        })
            .pipe(filter(function (mappingResult) { return !mappingResult.cancelled; }), untilDestroyed(this))
            .subscribe(function (mappingResult) {
            _this.setMappingValue(mappingResult.mappings);
        });
    };
    CustomViewItemEditComponent.prototype.setMappingValue = function (mappings) {
        if (this.mappingsControl) {
            this.mappingsControl.patchValue(mappings ? mappings : []);
        }
    };
    CustomViewItemEditComponent.prototype.onSelectCustomView = function (customView) {
        var currentUniqueName = this.customView ? this.customView.uniqueName : undefined;
        var newUniqueName = customView ? customView.uniqueName : undefined;
        this.selectCustomView.emit(customView);
        if (this.mappingsControl && isSet(newUniqueName) && newUniqueName != currentUniqueName) {
            this.changeMapping(customView);
        }
    };
    return CustomViewItemEditComponent;
}());
export { CustomViewItemEditComponent };
