import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable, Subscription, timer } from 'rxjs';

import { ViewContext, ViewContextElement } from '@modules/customize';
import { createFormFieldFactory, FieldType, FormField } from '@modules/fields';
import { TypedChanges } from '@shared';

export const DBLCLICK_MAX_DELAY = 500;

@Component({
  selector: 'app-table-editable-cell, [app-table-editable-cell]',
  templateUrl: './table-editable-cell.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableEditableCellComponent implements OnInit, OnDestroy, OnChanges {
  @Input() fieldName: string;
  @Input() fieldType: FieldType;
  @Input() fieldParams = {};
  @Input() value: any;
  @Input() valueStr: string | Observable<string>;
  @Input() selectedRow = false;
  @Input() selectedCell: HTMLElement;
  @Input() editingEnabled = true;
  @Input() disableSelectOnEdit = false;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Output() startEditingClick = new EventEmitter<void>();
  @Output() selectCell = new EventEmitter<{ element: HTMLElement; event: MouseEvent }>();
  @Output() changeValue = new EventEmitter<any>();

  @HostBinding('class.table__column') cls = true;
  @HostBinding('class.table__column_clickable') clickable = true;
  @HostBinding('class.table__column_selected') get selected() {
    return this.selectedCell === this.el.nativeElement && !this.editing;
  }

  createField = createFormFieldFactory();
  editing = false;
  formField: FormField;
  formFieldEditable: FormField;
  overlayOrigin: CdkOverlayOrigin;
  editingControl: FormControl;
  editingForm: FormGroup;
  minWidth: number;
  minHeight: number;
  clickSubscription: Subscription;
  fieldTypes = FieldType;

  constructor(private el: ElementRef, private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.overlayOrigin = new CdkOverlayOrigin(this.el);
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<TableEditableCellComponent>): void {
    if (changes.fieldName || changes.fieldType || changes.fieldParams) {
      this.formField = new FormField();
      this.formField.name = this.fieldName;
      this.formField.field = this.fieldType;
      this.formField.resetEnabled = true;
      this.formField.params = { ...this.fieldParams };

      const classes = this.fieldParams && this.fieldParams['classes'] ? this.fieldParams['classes'] : [];

      this.formFieldEditable = cloneDeep(this.formField);
      this.formFieldEditable.params = {
        ...this.formFieldEditable.params,
        classes: ['select_fill', 'select_small', 'input_fill', 'input_small', ...classes],
        rows: 6,
        street_view: false
      };
    }
  }

  setEditing(value: boolean) {
    this.editing = value;
    this.cd.markForCheck();
  }

  startEditing() {
    this.startEditingClick.emit();

    if (!this.editingEnabled) {
      return;
    }

    this.editingControl = new FormControl(this.value);
    this.editingForm = new FormGroup({
      [this.formField.name]: this.editingControl
    });
    this.cd.markForCheck();

    this.setEditing(true);
  }

  finishEditing(save = false) {
    this.setEditing(false);

    if (this.editingControl) {
      const newValue = this.editingControl.value;

      this.editingControl = undefined;
      this.editingForm = undefined;
      this.cd.markForCheck();

      if (save && !isEqual(this.value, newValue)) {
        this.changeValue.emit(newValue);
      }
    }
  }

  @HostListener('click', ['$event']) onClick(e: MouseEvent) {
    if (this.disableSelectOnEdit && this.selectedRow) {
      if (this.clickSubscription) {
        this.clickSubscription.unsubscribe();
        this.clickSubscription = undefined;
      }

      const subscription = timer(DBLCLICK_MAX_DELAY)
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          if (this.clickSubscription === subscription) {
            this.clickSubscription.unsubscribe();
            this.clickSubscription = undefined;
          }

          this.selectCell.emit({ element: this.el.nativeElement, event: e });
        });

      this.clickSubscription = subscription;
    } else {
      this.selectCell.emit({ element: this.el.nativeElement, event: e });
    }
  }

  @HostListener('dblclick', ['$event']) onDblClick(e: MouseEvent) {
    if (this.clickSubscription) {
      this.clickSubscription.unsubscribe();
      this.clickSubscription = undefined;
    }

    if (!this.fieldType) {
      return;
    }

    const fieldMinWidth = this.getFieldMinWidth(this.fieldType);

    this.minWidth = fieldMinWidth
      ? Math.max(this.el.nativeElement.offsetWidth, fieldMinWidth)
      : this.el.nativeElement.offsetWidth;
    this.minHeight = this.el.nativeElement.offsetHeight;

    e.preventDefault();

    this.startEditing();
  }

  getFieldMinWidth(fieldType: FieldType): number {
    if (fieldType == FieldType.JSON) {
      return 400;
    } else if (fieldType == FieldType.Location) {
      return 600;
    }
  }
}
