var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { NgZone } from '@angular/core';
import { FormControl } from '@angular/forms';
import { combineLatest } from 'rxjs';
import { debounceTime, delay, map } from 'rxjs/operators';
import { NotificationService } from '@common/notifications';
import { ActionService } from '@modules/action-queries';
import { AggregateFunc, DatasetGroupLookup } from '@modules/charts';
import { ChartType } from '@modules/dashboard';
import { ChartWidgetDataSource } from '@modules/data-sources';
import { DisplayField, DisplayFieldType, FieldType, ParameterField } from '@modules/fields';
import { ModelDescriptionStore, ModelService } from '@modules/model-queries';
import { InputFieldProviderItem, inputFieldProviderItemsFromModelGet, parametersToProviderItems } from '@modules/parameters';
import { ProjectSettingsService } from '@modules/project-settings';
import { CurrentEnvironmentStore } from '@modules/projects';
import { QueryService } from '@modules/queries';
import { ResourceGeneratorResolver } from '@modules/resource-generators';
import { ResourceControllerService } from '@modules/resources';
import { controlValue, isSet } from '@shared';
import { ModelDescriptionDataSourceControl } from './model-description-data-source';
export var validateQueryXColumn = function (queryXColumnLookupControl, required) {
    if (required === void 0) { required = true; }
    return function (control) {
        if (!control.parent) {
            return;
        }
        if (!isSet(control.value)) {
            if (required) {
                return { required: true };
            }
            else {
                return;
            }
        }
        var lookup = control.parent.controls[queryXColumnLookupControl].value;
        if (!isSet(lookup)) {
            return { required: true };
        }
    };
};
export var validateQueryYFunc = function (queryColumnControl) {
    return function (control) {
        if (!control.parent) {
            return;
        }
        var column = control.parent.controls[queryColumnControl].value;
        if (isSet(control.value) && control.value != AggregateFunc.Count && !isSet(column)) {
            return { required: true };
        }
        else if (!isSet(control.value) && !isSet(column)) {
            return { required: true };
        }
    };
};
var ChartWidgetDataSourceControl = /** @class */ (function (_super) {
    __extends(ChartWidgetDataSourceControl, _super);
    // minMaxChartTypes = [ChartType.Bar, ChartType.StackedBar, ChartType.Line, ChartType.Scatter];
    //
    // percentageChartTypes = [ChartType.Bar, ChartType.StackedBar];
    //
    // smoothChartTypes = [ChartType.Line];
    //
    // datasetColorChartTypes = [
    //   ChartType.Bar,
    //   ChartType.StackedBar,
    //   ChartType.Line,
    //   ChartType.Radar,
    //   ChartType.PolarArea,
    //   ChartType.Scatter,
    //   ChartType.Bubble
    // ];
    function ChartWidgetDataSourceControl(modelDescriptionStore, currentEnvironmentStore, projectSettingsService, resourceControllerService, resourceGeneratorResolver, modelService, actionService, queryService, notificationService, zone) {
        var _this = _super.call(this, modelDescriptionStore, currentEnvironmentStore, projectSettingsService, resourceControllerService, resourceGeneratorResolver, modelService, actionService, queryService, notificationService, zone, {
            x_column: new FormControl('', validateQueryXColumn('x_column_lookup')),
            x_column_lookup: new FormControl(DatasetGroupLookup.Auto),
            x_column_2: new FormControl('', validateQueryXColumn('x_column_2_lookup', false)),
            x_column_2_lookup: new FormControl(DatasetGroupLookup.Auto),
            y_func: new FormControl('', validateQueryYFunc('y_column')),
            y_column: new FormControl(''),
            y_cumulative: new FormControl(false)
        }) || this;
        _this.resourceFieldParams = { charts_resources: true };
        _this.groupCommonOptions = [
            { value: DatasetGroupLookup.Auto, name: 'Auto', valueDisplay: function (field) { return field + " - Auto"; } },
            { value: DatasetGroupLookup.Plain, name: 'Plain value', valueDisplay: function (field) { return field + " - Plain"; } }
        ];
        _this.groupDateOptions = [
            { value: DatasetGroupLookup.DateDay, name: 'By Day', valueDisplay: function (field) { return field + " - Day"; } },
            { value: DatasetGroupLookup.DateWeek, name: 'By Week', valueDisplay: function (field) { return field + " - Week"; } },
            { value: DatasetGroupLookup.DateMonth, name: 'By Month', valueDisplay: function (field) { return field + " - Month"; } },
            { value: DatasetGroupLookup.DateQuarter, name: 'By Quarter', valueDisplay: function (field) { return field + " - Quarter"; } },
            { value: DatasetGroupLookup.DateYear, name: 'By Year', valueDisplay: function (field) { return field + " - Year"; } }
        ];
        _this.groupTimeOptions = [
            { value: DatasetGroupLookup.DateHour, name: 'By Hour', valueDisplay: function (field) { return field + " - Hour"; } },
            { value: DatasetGroupLookup.DateMinute, name: 'By Minute', valueDisplay: function (field) { return field + " - Minute"; } },
            { value: DatasetGroupLookup.DateSecond, name: 'By Second', valueDisplay: function (field) { return field + " - Second"; } }
        ];
        _this.xColumnLookupAllOptions = _this.groupCommonOptions.concat(_this.groupDateOptions, _this.groupTimeOptions);
        _this.xColumnLookupDefaultOptions = _this.groupCommonOptions.slice();
        _this.xColumnLookupDateOptions = _this.groupCommonOptions.concat(_this.groupDateOptions, _this.groupTimeOptions);
        _this.xColumnLookupTimeOptions = _this.groupCommonOptions.concat(_this.groupTimeOptions);
        _this.yColumnFuncOptions = [
            { value: AggregateFunc.Count, name: 'Number of records', valueDisplay: function () { return "Number of records"; } },
            { value: AggregateFunc.Sum, name: 'Sum of field', hasField: true, valueDisplay: function (field) { return "Sum of " + field; } },
            {
                value: AggregateFunc.Min,
                name: 'Minimum of field',
                hasField: true,
                valueDisplay: function (field) { return "Minimum of " + field; }
            },
            {
                value: AggregateFunc.Max,
                name: 'Maximum of field',
                hasField: true,
                valueDisplay: function (field) { return "Maximum of " + field; }
            },
            { value: AggregateFunc.Avg, name: 'Average of field', hasField: true, valueDisplay: function (field) { return "Average of " + field; } }
        ];
        // yFormatChartTypes = [
        //   ChartType.Bar,
        //   ChartType.StackedBar,
        //   ChartType.Line,
        //   ChartType.Radar,
        //   ChartType.PolarArea,
        //   ChartType.Scatter
        // ];
        _this.axisChartTypes = [ChartType.Line, ChartType.Bar, ChartType.StackedBar, ChartType.Scatter, ChartType.Bubble];
        _this.axisInverseChartTypes = [ChartType.BarHorizontal, ChartType.StackedBarHorizontal];
        _this.xColumn2ChartTypes = [ChartType.Bubble];
        _this.yCumulativeChartTypes = [ChartType.Line, ChartType.Bar];
        _this.controls.y_column.valueChanges.pipe(delay(0)).subscribe(function () {
            _this.controls.y_func.updateValueAndValidity();
        });
        _this.controls.y_func.updateValueAndValidity();
        _this.controls.x_column_lookup.valueChanges.pipe(delay(0)).subscribe(function () {
            _this.controls.x_column.updateValueAndValidity();
        });
        _this.controls.x_column_2_lookup.valueChanges.pipe(delay(0)).subscribe(function () {
            _this.controls.x_column_2.updateValueAndValidity();
        });
        _this.inputFieldProvider.getFields$().subscribe(function () {
            _this.controls.query_inputs.updateValueAndValidity();
        });
        return _this;
    }
    ChartWidgetDataSourceControl.prototype.clearExtraControls = function () {
        this.controls.x_column.patchValue('');
        this.controls.x_column_lookup.patchValue('');
        this.controls.x_column_2.patchValue('');
        this.controls.x_column_2_lookup.patchValue('');
        this.controls.y_func.patchValue('');
        this.controls.y_column.patchValue('');
        this.controls.y_cumulative.patchValue(false);
    };
    ChartWidgetDataSourceControl.prototype.deserializeExtraControls = function (instance) {
        if (instance) {
            this.controls.x_column.patchValue(instance.xColumn);
            this.controls.x_column_lookup.patchValue(instance.xLookup);
            this.controls.x_column_2.patchValue(instance.xColumn2);
            this.controls.x_column_2_lookup.patchValue(instance.xLookup2);
            this.controls.y_func.patchValue(instance.yFunc);
            this.controls.y_column.patchValue(instance.yColumn);
            this.controls.y_cumulative.patchValue(instance.yCumulative);
        }
        else {
            this.clearExtraControls();
        }
    };
    ChartWidgetDataSourceControl.prototype.onModelDescriptionChange = function (modelDescription) {
        _super.prototype.onModelDescriptionChange.call(this, modelDescription);
        this.clearExtraControls();
    };
    ChartWidgetDataSourceControl.prototype.serialize = function () {
        var result = _super.prototype.serialize.call(this);
        if (!result) {
            return;
        }
        result.xColumn = this.controls.x_column.value;
        result.xLookup = this.controls.x_column_lookup.value;
        result.xColumn2 = this.controls.x_column_2.value;
        result.xLookup2 = this.controls.x_column_2_lookup.value;
        result.yFunc = this.controls.y_func.value;
        result.yColumn = this.controls.y_column.value;
        result.yCumulative = this.controls.y_cumulative.value;
        return result;
    };
    ChartWidgetDataSourceControl.prototype.getInputFieldProvider$ = function () {
        return combineLatest(controlValue(this.controls.type), this.getResource$(), this.getModelDescription$(), controlValue(this.controls.query_parameters), controlValue(this.controls.query), controlValue(this.controls.columns)).pipe(debounceTime(60), map(function (_a) {
            var type = _a[0], resource = _a[1], modelDescription = _a[2], parameters = _a[3], getQuery = _a[4], columns = _a[5];
            return parametersToProviderItems(parameters).concat(inputFieldProviderItemsFromModelGet(resource, modelDescription, getQuery, columns, type));
        }));
    };
    ChartWidgetDataSourceControl.prototype.isListQuery = function () {
        return true;
    };
    ChartWidgetDataSourceControl.prototype.getChartColumnOptions$ = function () {
        return controlValue(this.controls.columns).pipe(map(function (columnsValue) {
            if (!columnsValue) {
                return [];
            }
            return columnsValue
                .filter(function (item) { return item.type == DisplayFieldType.Base; })
                .map(function (item) {
                return {
                    value: item.name,
                    name: item.verboseName || item.name,
                    date: item.field == FieldType.DateTime,
                    time: item.field == FieldType.Time
                };
            });
        }));
    };
    ChartWidgetDataSourceControl.instanceCls = ChartWidgetDataSource;
    return ChartWidgetDataSourceControl;
}(ModelDescriptionDataSourceControl));
export { ChartWidgetDataSourceControl };
