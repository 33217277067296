import { Injectable, Injector } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, Observable, of } from 'rxjs';
import { debounceTime, switchMap, tap } from 'rxjs/operators';

import { NotificationService } from '@common/notifications';
import { ActionService } from '@modules/action-queries';
import { CustomView, CustomViewService, CustomViewsStore } from '@modules/custom-views';
import { ListDefaultSelection, TimelineSettings } from '@modules/customize';
import { ElementConfigurationService } from '@modules/customize-configuration';
import { DisplayField, Input, Input as FieldInput } from '@modules/fields';
import { ModelOptionsSource } from '@modules/filters-components';
import { ModelDescriptionStore } from '@modules/model-queries';
import { FieldInputControl } from '@modules/parameters';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { ResourceControllerService } from '@modules/resources';
import { controlValue, isSet } from '@shared';

import { CustomViewDefaults } from '../../../data/custom-view-defaults';
import { ActionOutputFormGroup } from '../../../forms/action-output.form-group';
import { FieldActionsArray } from '../../display-fields-edit/field-actions.array';
import { ListModelDescriptionDataSourceControl } from '../../model-description-data-source-edit/list-model-description-data-source';
import { OptionEnabledArray } from '../../option-enabled-edit/option-enabled.array';
import { ListElementStylesControl } from '../../styles-list-element-edit/list-element-styles.control';
import {
  CustomizeBarListLayoutSettingsForm,
  CustomizeListOptions,
  CustomizeListResult,
  validateAction,
  validateActions
} from '../customize-bar-list-layout-settings/customize-bar-list-layout-settings.form';

@Injectable()
export class CustomizeBarTimelineSettingsForm extends CustomizeBarListLayoutSettingsForm<TimelineSettings> {
  controls: {
    header: FormControl;
    title: FieldInputControl;
    data_source: ListModelDescriptionDataSourceControl;
    card_view_unique_name: FormControl;
    card_view_mappings: FormControl;
    search_enabled: FormControl;
    search_live: FormControl;
    hide_labels: FormControl;
    card_click_action: FormControl;
    actions: FormControl;
    model_actions: FormControl;
    columns_actions: FieldActionsArray;
    custom_actions: ActionOutputFormGroup;
    sorting_field: FormControl;
    sorting_asc: FormControl;
    per_page: FormControl;
    multiple_selection: FormControl;
    first_item_default_selection: FormControl;
    display_filters: FormControl;
    filter_fields: OptionEnabledArray;
    display_footer: FormControl;
    visible_input: FieldInputControl;
    name: FormControl;
    tooltip: FormControl;
    gap_vertical: FormControl;
    card_wrap: FormControl;

    element_styles: ListElementStylesControl;
  };

  constructor(
    private modelOptionsSource: ModelOptionsSource,
    private customViewService: CustomViewService,
    private customViewsStore: CustomViewsStore,
    private notificationService: NotificationService,
    private injector: Injector,
    currentProjectStore: CurrentProjectStore,
    currentEnvironmentStore: CurrentEnvironmentStore,
    resourceControllerService: ResourceControllerService,
    modelDescriptionStore: ModelDescriptionStore,
    actionService: ActionService,
    elementConfigurationService: ElementConfigurationService,
    dataSourceControl: ListModelDescriptionDataSourceControl
  ) {
    super(
      currentProjectStore,
      currentEnvironmentStore,
      resourceControllerService,
      modelDescriptionStore,
      actionService,
      elementConfigurationService,
      {
        header: new FormControl(true),
        title: new FieldInputControl({ path: ['value'] }),
        data_source: dataSourceControl,
        card_view_unique_name: new FormControl(null),
        card_view_mappings: new FormControl([]),
        search_enabled: new FormControl(true),
        search_live: new FormControl(true),
        hide_labels: new FormControl(true),
        card_click_action: new FormControl(undefined, undefined, validateAction),
        actions: new FormControl([], undefined, validateActions),
        model_actions: new FormControl([], undefined, validateActions),
        columns_actions: new FieldActionsArray([]),
        custom_actions: new ActionOutputFormGroup(elementConfigurationService),
        sorting_field: new FormControl(undefined, Validators.required),
        sorting_asc: new FormControl(true),
        per_page: new FormControl(undefined),
        multiple_selection: new FormControl(false),
        first_item_default_selection: new FormControl(false),
        display_filters: new FormControl(true),
        filter_fields: new OptionEnabledArray([]),
        display_footer: new FormControl(true),
        visible_input: new FieldInputControl({ path: ['value'] }),
        name: new FormControl(''),
        tooltip: new FormControl(''),
        gap_vertical: new FormControl(null),
        card_wrap: new FormControl(true),

        element_styles: new ListElementStylesControl(injector)
      }
    );
    dataSourceControl.setRequired(true);
  }

  init(options: CustomizeListOptions<TimelineSettings>): Observable<void> {
    this.settings = options.settings;
    this.pageUid = options.pageUid;
    this.elementUid = options.elementUid;
    this.options = options;

    const customView$ = isSet(options.settings.cardCustomView)
      ? this.customViewsStore.getDetailFirst(options.settings.cardCustomView)
      : of(undefined);

    return customView$.pipe(
      tap(customView => {
        const value = {
          header: options.settings.header,
          title: options.settings.titleInput ? options.settings.titleInput.serializeWithoutPath() : {},
          card_view_unique_name: options.settings.cardCustomView,
          card_view_mappings: options.settings.cardCustomViewMappings,
          search_enabled: options.settings.searchEnabled,
          search_live: options.settings.searchLive,
          hide_labels: options.settings.hideLabels,
          card_click_action: options.settings.cardClickAction,
          actions: options.settings.actions,
          model_actions: options.settings.modelActions,
          columns_actions: options.settings.columnActions,
          sorting_field: options.settings.dateField || null,
          sorting_asc: options.settings.sortingAsc,
          per_page: options.settings.perPage,
          multiple_selection: options.settings.multipleSelection,
          first_item_default_selection: options.settings.defaultSelection == ListDefaultSelection.First,
          display_filters: options.settings.displayFilters,
          filter_fields: options.settings.filterFields.map(item => {
            return {
              name: item.name,
              enabled: true
            };
          }),
          display_footer: options.settings.displayFooter,
          visible_input: options.visibleInput ? options.visibleInput.serializeWithoutPath() : {},
          tooltip: options.settings.tooltip,
          gap_vertical: options.settings.gapVertical
        };

        if (options.nameEditable) {
          value['name'] = options.name;
        }

        this.patchValue(value, { emitEvent: false });

        this.controls.data_source.deserialize(options.settings.dataSource);
        this.controls.custom_actions.deserialize(customView ? customView.actions : [], options.settings.customActions);

        if (options.cardWrapEditable) {
          this.controls.card_wrap.patchValue(options.cardWrap);
        }

        if (options.elementStylesEditable && options.elementStyles) {
          this.controls.element_styles.deserialize(options.elementStyles);
        }

        this.trackChanges(options);
        this.initPerPageClean(this.controls.per_page);

        if (!options.firstInit) {
          this.markAsDirty();
        }
      })
    );
  }

  trackChanges(options: CustomizeListOptions<TimelineSettings>) {
    super.trackChanges(options);

    combineLatest(
      controlValue<DisplayField[]>(this.controls.data_source.controls.columns),
      this.controls.data_source.getModelDescription$(),
      this.controls.data_source.getModelDescription$().pipe(
        switchMap(modelDescription => {
          if (!modelDescription) {
            return of([]);
          }

          return this.modelOptionsSource.getOptions$(modelDescription, {
            relationsEnabled: true
          });
        })
      )
    )
      .pipe(debounceTime(60), untilDestroyed(this))
      .subscribe(([columns, modelDescription, modelOptions]) => {
        const columnNames = modelDescription ? modelOptions.map(item => item.name) : columns.map(item => item.name);
        const modelId = modelDescription ? modelDescription.modelId : null;
        const filterFieldsSourceChanged = this.controls.filter_fields.isSourceSet()
          ? !this.controls.filter_fields.isSource(modelId)
          : false;

        this.controls.filter_fields.syncControls(columnNames, { source: modelId });

        if (this.controls.display_filters.value && this.controls.filter_fields.isAllDisabled()) {
          this.controls.filter_fields.enableDefault();
        } else if (this.controls.display_filters.value && filterFieldsSourceChanged) {
          this.controls.filter_fields.enableDefault();
        }
      });
  }

  getCardCustomView$(): Observable<CustomView> {
    return controlValue<string>(this.controls.card_view_unique_name).pipe(
      switchMap(customView => {
        return isSet(customView) ? this.customViewsStore.getDetail(customView) : of(undefined);
      })
    );
  }

  getCustomViewDefaults(): CustomViewDefaults {
    return {
      uniqueName: CustomView.generateUniqueName(),
      pageUid: this.pageUid,
      elementUid: this.elementUid
    };
  }

  isConfigured(instance: TimelineSettings): Observable<boolean> {
    return this.elementConfigurationService.isListTimelineConfigured(instance, { restrictDemo: true });
  }

  submit(): CustomizeListResult<TimelineSettings> {
    const instance: TimelineSettings = cloneDeep(this.settings);

    instance.dateField = this.controls.sorting_field.value;

    instance.titleInput = this.controls.title ? new FieldInput().deserialize(this.controls.title.value) : undefined;
    instance.dataSource = this.controls.data_source.serialize();
    instance.cardCustomView = this.controls.card_view_unique_name.value;
    instance.cardCustomViewMappings = this.controls.card_view_mappings.value;
    instance.searchEnabled = this.controls.search_enabled.value;
    instance.searchLive = this.controls.search_live.value;
    instance.hideLabels = this.controls.hide_labels.value;

    if (this.controls.card_click_action.value) {
      instance.cardClickAction = this.controls.card_click_action.value;
    } else {
      instance.cardClickAction = undefined;
    }

    instance.actions = this.controls.actions.value;
    instance.modelActions = this.controls.model_actions.value;
    instance.columnActions = this.controls.columns_actions.value;
    instance.customActions = this.controls.custom_actions.serialize();

    if (isSet(this.controls.sorting_field.value)) {
      instance.sortingField = this.controls.sorting_field.value;
    } else {
      instance.sortingField = undefined;
    }

    instance.sortingAsc = this.controls.sorting_asc.value;
    instance.perPage = this.controls.per_page.value;
    instance.multipleSelection = this.controls.multiple_selection.value;
    instance.defaultSelection = this.controls.first_item_default_selection.value
      ? ListDefaultSelection.First
      : undefined;
    instance.filterFields = this.controls.filter_fields.value
      .filter(item => item.enabled)
      .map(item => {
        return {
          name: item.name
        };
      });
    instance.displayFilters = instance.filterFields.length && this.controls.display_filters.value;
    instance.displayFooter = this.controls.display_footer.value;
    instance.header =
      (instance.titleInput && instance.titleInput.isSet()) ||
      !!instance.searchEnabled ||
      !!instance.displayFilters ||
      (instance.actions && instance.actions.length > 0);
    instance.tooltip = isSet(this.controls.tooltip.value) ? this.controls.tooltip.value.trim() : undefined;
    instance.gapVertical = this.controls.gap_vertical.value;

    return {
      settings: instance,
      visibleInput: this.controls.visible_input.value
        ? new Input().deserialize(this.controls.visible_input.value)
        : undefined,
      name: this.controls.name.value,
      ...(this.options.cardWrapEditable && {
        cardWrap: this.controls.card_wrap.value
      }),
      ...(this.options.elementStylesEditable && {
        elementStyles: this.controls.element_styles.serialize()
      })
    };
  }
}
