<div
  class="view-editor-header-main-button icon-canvas"
  cdkOverlayOrigin
  #origin="cdkOverlayOrigin"
  (click)="menu_root.setDropdownOpened(true)"
>
  <span class="view-editor-header-main-button__arrow icon-arrow_down_2"></span>
</div>

<app-view-editor-menu-root
  [trigger]="origin"
  [componentLabel]="componentLabel"
  [submitLabel]="submitLabel"
  [stateSelectedEnabled]="stateSelectedEnabled"
  [templatesEnabled]="templatesEnabled"
  [analyticsSource]="analyticsSource"
  (saveEditor)="saveEditor.emit()"
  (closeEditor)="closeEditor.emit()"
  (rename)="rename.emit()"
  (importFigmaNode)="importFigmaNode.emit()"
  (importSketchFile)="importSketchFile.emit()"
  (useShared)="useShared.emit($event)"
  (selectShared)="custom_view_dropdown.open()"
  #menu_root
></app-view-editor-menu-root>

<app-custom-view-dropdown
  [origin]="origin"
  (selectItem)="useShared.emit($event)"
  #custom_view_dropdown
></app-custom-view-dropdown>
