import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import toPairs from 'lodash/toPairs';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { CustomView, CustomViewLoaderService } from '@modules/custom-views';
import { removeChildren } from '@shared';

@Component({
  selector: 'app-standalone-custom-view',
  templateUrl: './standalone-custom-view.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StandaloneCustomViewComponent implements OnChanges, OnDestroy, AfterViewInit {
  @Input() customView: CustomView;
  @Input() attrs: { [key: string]: string };
  @ViewChild('container') container: ElementRef;

  loading = false;

  constructor(private customViewLoaderService: CustomViewLoaderService) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.init();
  }

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {
    this.init();
  }

  init() {
    if (!this.customView) {
      this.clearElements();
      return;
    }

    if (this.loading) {
      return;
    }

    this.loading = true;

    this.createElement();
    this.customViewLoaderService
      .load(this.customView)
      .pipe(untilDestroyed(this))
      .subscribe(() => (this.loading = false));
  }

  clearElements() {
    removeChildren(this.container.nativeElement);
  }

  createElement() {
    this.clearElements();
    const dynamicEl = document.createElement(this.customView.uniqueName);

    if (this.attrs) {
      toPairs(this.attrs).forEach(([key, value]) => dynamicEl.setAttribute(key, value));
    }

    this.container.nativeElement.appendChild(dynamicEl);
  }
}
