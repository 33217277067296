var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormControl, FormGroup, Validators } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import { MarginControl } from '@modules/customize';
import { ElementConfigurationService } from '@modules/customize-configuration';
import { Input, Input as FieldInput } from '@modules/fields';
import { FieldInputControl } from '@modules/parameters';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
var CustomizeBarIFrameEditForm = /** @class */ (function (_super) {
    __extends(CustomizeBarIFrameEditForm, _super);
    function CustomizeBarIFrameEditForm(currentProjectStore, currentEnvironmentStore, elementConfigurationService) {
        var _this = _super.call(this, {
            url: new FieldInputControl({ path: ['value'] }, { validators: Validators.required, updateOn: 'blur' }),
            border: new FormControl(false),
            visible_input: new FieldInputControl({ path: ['value'] }),
            card_wrap: new FormControl(true),
            margin: new MarginControl()
        }) || this;
        _this.currentProjectStore = currentProjectStore;
        _this.currentEnvironmentStore = currentEnvironmentStore;
        _this.elementConfigurationService = elementConfigurationService;
        return _this;
    }
    CustomizeBarIFrameEditForm.prototype.init = function (element, firstInit) {
        if (firstInit === void 0) { firstInit = false; }
        this.element = element;
        var value = {
            url: element.url ? element.url.serializeWithoutPath() : {},
            border: element.border,
            visible_input: element.visibleInput ? element.visibleInput.serializeWithoutPath() : {},
            card_wrap: element.cardWrap,
            margin: element.margin
        };
        this.patchValue(value, { emitEvent: false });
        if (!firstInit) {
            this.markAsDirty();
        }
    };
    CustomizeBarIFrameEditForm.prototype.isConfigured = function (instance) {
        return this.elementConfigurationService.isIFrameConfigured(instance);
    };
    CustomizeBarIFrameEditForm.prototype.submit = function () {
        var value = this.value;
        var instance = cloneDeep(this.element);
        instance.url = value['url'] ? new FieldInput().deserialize(value['url']) : undefined;
        instance.border = value['border'];
        instance.cardWrap = value['card_wrap'];
        instance.margin = value['margin'];
        if (value['visible_input']) {
            instance.visibleInput = new Input().deserialize(value['visible_input']);
        }
        else {
            instance.visibleInput = undefined;
        }
        return instance;
    };
    return CustomizeBarIFrameEditForm;
}(FormGroup));
export { CustomizeBarIFrameEditForm };
