<form [formGroup]="form" (ngSubmit)="submit()">
  <app-customize-bar-header
    [title]="form.controls.name.value || (titleEditable ? 'single_value' : 'Single Value')"
    [titleEnabled]="titleEnabled"
    [titleEditable]="titleEditable"
    [titleCleanValue]="titleCleanValue"
    [backLabel]="'All components'"
    [deleteEnabled]="deleteEnabled"
    [parentLabel]="parentElement?.displayName || parentPopup?.displayName"
    (parentClick)="parentElement?.customize({ event: $event }) || parentPopup?.customize({ event: $event })"
    (deleteClick)="delete()"
    (titleChanged)="onTitleChanged($event)"
  >
  </app-customize-bar-header>

  <div class="sidebar__content">
    <app-sidebar-tabs>
      <app-sidebar-tab [label]="'General'">
        <app-sidebar-section>
          <div class="sidebar__element">
            <app-model-description-data-source-button
              [control]="form.controls.data_source"
              [context]="context"
              [contextElement]="contextElement"
              [error]="dataConfigured ? undefined : 'Not configured'"
              [classes]="'component-data-button'"
              (editClick)="editValueDataSource()"
              (addInputClick)="editValueDataSource({ addInput: true })"
            >
            </app-model-description-data-source-button>
          </div>

          <div class="sidebar__element">
            <app-customize-bar-input-edit
              [label]="'label'"
              [required]="true"
              [itemForm]="form.controls.title"
              [textStyleControl]="form.controls.element_styles.controls.title_style"
              [textStyleTip]="'Customize Label style'"
              [context]="context"
              [contextElement]="contextElement"
              [fill]="true"
              [placeholder]="'e.g. active users'"
              [analyticsSource]="widget.analyticsGenericName + '_name'"
            ></app-customize-bar-input-edit>
          </div>
        </app-sidebar-section>

        <app-sidebar-section
          [title]="'Comparison'"
          [description]="'Show how the above value changed comparing to the previous month, another segment, etc.'"
          [disabled]="form.controls.data_source.invalid"
        >
          <div class="sidebar__element">
            <app-model-description-data-source-button
              [control]="
                form.controls.compare_data_source_enabled.value ? form.controls.compare_data_source : undefined
              "
              [placeholder]="'Add comparison'"
              [placeholderIcon]="'percent'"
              [context]="context"
              [contextElement]="contextElement"
              [error]="
                form.controls.compare_data_source_enabled.value && form.controls.compare_data_source.invalid
                  ? 'Not configured'
                  : undefined
              "
              [remove]="form.controls.compare_data_source_enabled.value"
              [classes]="'component-compare-data-button'"
              (editClick)="form.setCompareDataSourceEnabled(true); editCompareValueDataSource()"
              (addInputClick)="form.setCompareDataSourceEnabled(true); editCompareValueDataSource({ addInput: true })"
              (removeClick)="form.setCompareDataSourceEnabled(false)"
            >
              <ng-container actions>
                <a
                  *ngIf="form.controls.compare_data_source_enabled.value"
                  href="javascript:void(0)"
                  class="sidebar-list-item__action icon-reflect"
                  (click)="$event.stopPropagation(); form.swapMainAndCompareDataSources()"
                  [appTip]="'Swap comparison and main data'"
                  [appTipOptions]="{ side: 'top' }"
                >
                </a>
              </ng-container>
            </app-model-description-data-source-button>
          </div>
        </app-sidebar-section>

        <app-sidebar-section
          [title]="'Trend Chart'"
          [description]="'How the above value changed during period'"
          [disabled]="form.controls.data_source.invalid"
        >
          <div class="sidebar__element">
            <app-model-description-data-source-button
              [control]="
                form.controls.chart_dataset_enabled.value ? form.controls.chart_dataset.controls.data_source : undefined
              "
              [placeholder]="'Add trend chart'"
              [placeholderIcon]="'chart'"
              [context]="context"
              [contextElement]="contextElement"
              [error]="
                form.controls.chart_dataset_enabled.value && form.controls.chart_dataset.controls.data_source.invalid
                  ? 'Not configured'
                  : undefined
              "
              [remove]="form.controls.chart_dataset_enabled.value"
              [classes]="'component-chart-data-button'"
              (editClick)="form.setChartDatasetEnabled(true); editChartDataset(form.controls.chart_dataset)"
              (addInputClick)="
                form.setChartDatasetEnabled(true); editChartDataset(form.controls.chart_dataset, { addInput: true })
              "
              (removeClick)="form.setChartDatasetEnabled(false)"
            >
            </app-model-description-data-source-button>
          </div>
        </app-sidebar-section>

        <app-related-model-components
          *ngIf="modelDescription"
          [modelDescription]="modelDescription"
          [context]="context"
          [collapseContext]="collapseContext"
        ></app-related-model-components>
      </app-sidebar-tab>

      <app-sidebar-tab [label]="'Display'">
        <app-sidebar-section>
          <div class="sidebar__element">
            <app-sidebar-field [label]="'Value format'">
              <app-auto-field
                [form]="form"
                [label]="false"
                [field]="
                  createField({
                    name: 'format',
                    field: 'CharField',
                    required: false,
                    placeholder: 'Format value, e.g. 0,0.0000',
                    params: { classes: ['input_fill'] }
                  })
                "
              ></app-auto-field>
              <ng-container description>
                Numbers formatting supported <a href="http://numeraljs.com/#format" target="_blank">Learn More</a>
              </ng-container>

              <ng-container actions>
                <app-text-style-edit-overlay
                  [control]="form.controls.element_styles.controls.value_style"
                  [origin]="origin"
                  #text_style_edit
                ></app-text-style-edit-overlay>

                <span
                  class="sidebar__field-action sidebar__field-action_button icon-text_2"
                  [class.sidebar__field-action_active]="!(valueTextStyleIsDefault | async)"
                  [class.sidebar__field-action_disabled]="text_style_edit.isOpened()"
                  [appTip]="'Customize Value style'"
                  [appTipOptions]="{ side: 'top' }"
                  cdkOverlayOrigin
                  #origin="cdkOverlayOrigin"
                ></span>
              </ng-container>
            </app-sidebar-field>
          </div>

          <div class="sidebar__element">
            <div class="sidebar-cols">
              <div class="sidebar-cols__item">
                <app-sidebar-field [label]="'prefix'">
                  <app-auto-field
                    [form]="form"
                    [label]="false"
                    [field]="
                      createField({
                        name: 'prefix',
                        field: 'CharField',
                        required: false,
                        placeholder: 'e.g. $',
                        params: { classes: ['input_fill'] }
                      })
                    "
                  ></app-auto-field>
                </app-sidebar-field>
              </div>

              <div class="sidebar-cols__item">
                <app-sidebar-field [label]="'postfix'">
                  <app-auto-field
                    [form]="form"
                    [label]="false"
                    [field]="
                      createField({
                        name: 'postfix',
                        field: 'CharField',
                        required: false,
                        placeholder: 'e.g. per month',
                        params: { classes: ['input_fill'] }
                      })
                    "
                  ></app-auto-field>
                </app-sidebar-field>
              </div>
            </div>
          </div>

          <div class="sidebar__element">
            <app-sidebar-field [label]="'icon'">
              <app-auto-field
                [form]="form"
                [label]="false"
                [field]="
                  createField({
                    name: 'icon',
                    label: 'Icon',
                    field: 'IconField',
                    params: { classes: ['select_fill'], allow_empty: true }
                  })
                "
              ></app-auto-field>
            </app-sidebar-field>
          </div>

          <div class="sidebar__element">
            <app-sidebar-field [label]="'show reload button'" [layoutLeft]="true">
              <app-auto-field
                [form]="form"
                [label]="false"
                [field]="
                  createField({
                    name: 'show_reload',
                    field: 'BooleanField',
                    params: { style: booleanFieldStyle.Toggle, orange: true }
                  })
                "
              ></app-auto-field>
            </app-sidebar-field>
          </div>

          <div *ngIf="visibleEditable" class="sidebar__element">
            <app-sidebar-field
              [label]="'Conditional Visibility'"
              [documentation]="'conditional-visibility'"
              [documentationLabel]="'See how it works'"
              [documentationSource]="widget.analyticsGenericName + '_visible'"
            >
              <app-input-edit
                [itemForm]="form.controls.visible_input"
                [context]="context"
                [staticValueDisabled]="true"
                [placeholder]="'Value'"
                [fill]="true"
                [analyticsSource]="widget.analyticsGenericName + '_visible'"
              ></app-input-edit>

              <ng-container description>
                Example: elements.field.value >= 10
              </ng-container>
            </app-sidebar-field>
          </div>

          <div class="sidebar__element">
            <app-sidebar-field [label]="'tooltip'">
              <app-auto-field
                [form]="form"
                [label]="false"
                [field]="
                  createField({
                    name: 'tooltip',
                    field: 'CharField',
                    placeholder: 'Show information for users',
                    reset_enabled: true,
                    params: { classes: ['input_fill'] }
                  })
                "
              ></app-auto-field>
            </app-sidebar-field>
          </div>

          <div *ngIf="cardWrapEditable" class="sidebar__element">
            <app-element-wrap-edit [control]="form.controls.card_wrap"></app-element-wrap-edit>
          </div>

          <div *ngIf="elementStylesEditable" class="sidebar__element">
            <app-styles-value-widget [control]="form.controls.element_styles"></app-styles-value-widget>
          </div>
        </app-sidebar-section>
      </app-sidebar-tab>

      <app-sidebar-tab [label]="'Actions'" [error]="(actionsValid$ | async) !== false ? undefined : 'Not configured'">
        <app-sidebar-section>
          <div class="sidebar__list">
            <div class="sidebar-list__separator">
              <app-sidebar-list-separator [title]="'When value clicked'"></app-sidebar-list-separator>
            </div>
            <div class="sidebar-list">
              <div class="sidebar-list__item">
                <app-action-single-edit
                  [options]="{ originEnabled: true }"
                  [title]="'Value click'"
                  [control]="form.controls.click_action"
                  [context]="context"
                  [defaultSubtitle]="'Not specified'"
                  [remove]="form.controls.click_action.value"
                  [icon]="'cursor'"
                  [object]="element.analyticsGenericName"
                  [firstInit]="firstInit"
                >
                </app-action-single-edit>
              </div>
            </div>
          </div>
        </app-sidebar-section>
      </app-sidebar-tab>
    </app-sidebar-tabs>
  </div>
</form>
