<app-field-wrapper
  *ngIf="!readonly"
  [form]="form"
  [field]="field"
  [readonly]="readonly"
  [requiredAsterisk]="requiredAsterisk"
  [label]="label"
  [labelStyle]="elementStyles?.labelStyle"
  [labelStyleGlobal]="labelStyleGlobal"
  [labelAdditional]="labelAdditional"
  [labelAdditionalStyle]="elementStyles?.labelAdditionalStyle"
  [labelAdditionalStyleGlobal]="labelAdditionalStyleGlobal"
  [errors]="errors"
  [truncate]="truncate"
  [manualMargin]="manualMargin"
  [labelButtons]="labelButtons"
  [tooltip]="tooltip"
  [idToken]="idToken"
  [theme]="field.params['theme']"
>
  <div
    class="field__map"
    [class.field__map_disabled]="control?.disabled"
    [style.height.px]="field.params['map_height'] || null"
  >
    <agm-map
      [latitude]="viewportCoordinates ? viewportCoordinates.lat : undefined"
      [longitude]="viewportCoordinates ? viewportCoordinates.lng : undefined"
      [zoom]="viewportZoom"
      [streetViewControl]="field.params.hasOwnProperty('street_view') ? field.params['street_view'] : true"
      [backgroundColor]="backgroundColor$ | async"
      [scrollwheel]="false"
      [styles]="mapStyles$ | async"
    >
      <agm-marker
        *ngIf="markerCoordinates"
        [title]="valueToStr | appSafeAsync"
        [latitude]="markerCoordinates.lat"
        [longitude]="markerCoordinates.lng"
        [markerDraggable]="!readonly"
        [iconUrl]="markerIcon$ | async"
        (drag)="onDrag($event)"
        (dragEnd)="onDragEnd($event)"
      >
        <agm-snazzy-info-window
          [isOpen]="true"
          [placement]="'top'"
          [maxWidth]="'none'"
          [maxHeight]="'none'"
          [pointer]="'8px'"
          [showCloseButton]="false"
          [wrapperClass]="'si-wrapper-small' + (field.params['theme'] ? ' si-wrapper-theme' : '')"
        >
          <ng-template>
            <div class="map-popup map-popup_small" [class.map-popup_theme]="field.params['theme']">
              <app-page-block [marginSize]="'xs'" [paddingSize]="'xxs'" [style.margin-bottom.px]="5">
                <div class="field" [class.field_theme]="field.params['theme']">
                  <label class="field__label field__label_center field__label_margin_s">
                    <span class="field__label-text">{{ 'Coordinates' | localize }}</span>
                  </label>

                  <input
                    type="text"
                    class="input input_small input_fill"
                    [class.input_theme]="field.params['theme']"
                    [class.input_styles]="field.params['theme']"
                    [placeholder]="'Latitude' | localize"
                    [appFieldElementStyles]="elementStyles"
                    [appFieldElementStylesEnabled]="field.params['theme']"
                    [(ngModel)]="currentCoordinates.lat"
                    (keyup)="updateValue()"
                  />
                </div>
              </app-page-block>

              <app-page-block [marginSize]="'xs'" [paddingSize]="'xxs'" [style.margin-top.px]="5">
                <div class="field" [class.field_theme]="field.params['theme']">
                  <input
                    type="text"
                    class="input input_small input_fill"
                    [class.input_theme]="field.params['theme']"
                    [class.input_styles]="field.params['theme']"
                    [placeholder]="'Longitude' | localize"
                    [appFieldElementStyles]="elementStyles"
                    [appFieldElementStylesEnabled]="field.params['theme']"
                    [(ngModel)]="currentCoordinates.lng"
                    (keyup)="updateValue()"
                  />
                </div>
              </app-page-block>
            </div>
          </ng-template>
        </agm-snazzy-info-window>
      </agm-marker>
    </agm-map>
  </div>
</app-field-wrapper>
<app-field-wrapper
  *ngIf="readonly"
  [form]="form"
  [field]="field"
  [readonly]="readonly"
  [requiredAsterisk]="requiredAsterisk"
  [label]="label"
  [labelStyle]="elementStyles?.labelStyle"
  [labelStyleGlobal]="labelStyleGlobal"
  [labelAdditional]="labelAdditional"
  [labelAdditionalStyle]="elementStyles?.labelAdditionalStyle"
  [labelAdditionalStyleGlobal]="labelAdditionalStyleGlobal"
  [errors]="errors"
  [truncate]="truncate"
  [manualMargin]="manualMargin"
  [labelButtons]="labelButtons"
  [tooltip]="tooltip"
  [idToken]="idToken"
  [theme]="field.params['theme']"
>
  {{ valueToStr | appSafeAsync }}
</app-field-wrapper>
