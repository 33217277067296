import { Injectable, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { FormUtils } from '@common/form-utils';
import { Option } from '@modules/field-components';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { CustomViewTemplate, CustomViewTemplateService, CustomViewTemplateType } from '@modules/views';
import { controlValue } from '@shared';

@Injectable()
export class CustomViewTemplatesChangeForm extends FormGroup implements OnDestroy {
  controls: {
    name: FormControl;
    short_description: FormControl;
    description: FormControl;
    types: FormControl;
    featured: FormControl;
    icon: FormControl;
    icon_color: FormControl;
    thumbnail: FormControl;
    view: FormControl;
    parameters: FormControl;
    actions: FormControl;
    test_parameters: FormControl;
    active: FormControl;
    ordering: FormControl;
  };

  template: CustomViewTemplate;
  typeOptions: Option[] = [
    {
      value: CustomViewTemplateType.ListItem,
      name: 'List cards'
    },
    {
      value: CustomViewTemplateType.ItemColumn,
      name: 'Record fields'
    },
    {
      value: CustomViewTemplateType.MenuItem,
      name: 'Menu components'
    },
    {
      value: CustomViewTemplateType.Custom,
      name: 'Standalone'
    }
  ];
  uploaderResourceName = 'jet_templates_uploader';
  uploaderResourceStorageName = 'jetadmin';

  constructor(
    private customViewTemplateService: CustomViewTemplateService,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private formUtils: FormUtils
  ) {
    super({
      name: new FormControl('', Validators.required),
      short_description: new FormControl(''),
      description: new FormControl(''),
      types: new FormControl([]),
      featured: new FormControl(false),
      icon: new FormControl(undefined),
      icon_color: new FormControl(undefined),
      thumbnail: new FormControl(undefined),
      view: new FormControl(null),
      parameters: new FormControl([]),
      actions: new FormControl([]),
      test_parameters: new FormControl({}),
      active: new FormControl(false),
      ordering: new FormControl(0)
    });
  }

  ngOnDestroy(): void {}

  init(value: CustomViewTemplate) {
    this.template = value;

    if (value) {
      this.controls.name.patchValue(value.name);
      this.controls.short_description.patchValue(value.shortDescription);
      this.controls.description.patchValue(value.description);
      this.controls.types.patchValue(value.types);
      this.controls.featured.patchValue(value.featured);
      this.controls.icon.patchValue(value.icon);
      this.controls.icon_color.patchValue(value.iconColor);
      this.controls.thumbnail.patchValue(value.thumbnail);
      this.controls.view.patchValue(value.view);
      this.controls.parameters.patchValue(value.parameters);
      this.controls.actions.patchValue(value.actions);
      this.controls.test_parameters.patchValue(value.testParameters);
      this.controls.active.patchValue(value.active);
      // this.controls.ordering.patchValue(value.ordering);
    }
  }

  getUploaderResource() {
    return this.currentEnvironmentStore.resources.find(item => item.name == this.uploaderResourceName);
  }

  getUploaderResourceStorage() {
    const result = this.getUploaderResource();
    if (!result) {
      return;
    }
    return result.storages.find(item => item.uniqueName == this.uploaderResourceStorageName);
  }

  getInstance(): CustomViewTemplate {
    const instance: CustomViewTemplate = this.template ? cloneDeep(this.template) : new CustomViewTemplate();

    instance.name = this.controls.name.value;
    instance.shortDescription = this.controls.short_description.value;
    instance.description = this.controls.description.value;
    instance.types = this.controls.types.value;
    instance.featured = this.controls.featured.value;
    instance.icon = this.controls.icon.value;
    instance.iconColor = this.controls.icon_color.value;
    instance.thumbnail = this.controls.thumbnail.value;
    instance.view = this.controls.view.value;
    instance.parameters = this.controls.parameters.value;
    instance.actions = this.controls.actions.value;
    instance.testParameters = this.controls.test_parameters.value;
    instance.active = this.controls.active.value;
    // instance.ordering = this.controls.ordering.value;

    return instance;
  }

  getInstance$(): Observable<CustomViewTemplate> {
    return controlValue(this).pipe(map(() => this.getInstance()));
  }

  submit(): Observable<CustomViewTemplate> {
    const instance = this.getInstance();
    const fields = ['name', 'short_description', 'description', 'types', 'featured', 'view', 'active', 'params'];

    if (this.template) {
      return this.customViewTemplateService.update(instance, fields).pipe(
        catchError(error => {
          this.formUtils.showFormErrors(this, error);
          return throwError(error);
        })
      );
    } else {
      return this.customViewTemplateService.create(instance, fields).pipe(
        catchError(error => {
          this.formUtils.showFormErrors(this, error);
          return throwError(error);
        })
      );
    }
  }
}
