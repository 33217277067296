import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  OnDestroy,
  Output
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { NotificationService } from '@common/notifications';
import { BasePopupComponent } from '@common/popups';
import { AdminMode, ROUTE_ADMIN_MODE } from '@modules/admin-mode';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ServerRequestError } from '@modules/api';
import { createFormFieldFactory } from '@modules/fields';
import { ModelDescriptionStore } from '@modules/model-queries';
import { CurrentEnvironmentStore, CurrentProjectStore, ProjectGroup } from '@modules/projects';
import { errorToString } from '@shared';

import { ProjectGroupChangeForm } from '../project-group-change/project-group-change.form';
import { ProjectModelPermissionsArray } from '../project-group-change/project-model-permissions.array';
import { ProjectPagePermissionsArray } from '../project-group-change/project-page-permissions.array';
import { ProjectGroupCreatePopupForm } from './project-group-create-popup.form';

@Component({
  selector: 'app-project-group-create-popup',
  templateUrl: './project-group-create-popup.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    ProjectGroupCreatePopupForm,
    ProjectGroupChangeForm,
    ProjectModelPermissionsArray,
    ProjectPagePermissionsArray
  ]
})
export class ProjectGroupCreatePopupComponent implements OnDestroy {
  @Output() created = new EventEmitter<ProjectGroup>();

  createField = createFormFieldFactory();
  loading = false;

  constructor(
    @Inject(ROUTE_ADMIN_MODE) private mode: AdminMode,
    public form: ProjectGroupCreatePopupForm,
    public formGroupChange: ProjectGroupChangeForm,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private popupComponent: BasePopupComponent,
    private cd: ChangeDetectorRef,
    private modelDescriptionStore: ModelDescriptionStore,
    private analyticsService: UniversalAnalyticsService,
    private notificationService: NotificationService
  ) {}

  ngOnDestroy(): void {}

  submit() {
    if (this.form.form.invalid) {
      return;
    }
    this.createGroup(this.form.form.value['name'].trim());
  }

  createGroup(groupName: string): void {
    this.loading = true;
    this.cd.markForCheck();

    this.formGroupChange.init();
    this.formGroupChange.patchValue({
      name: groupName
    });

    this.formGroupChange
      .submit()
      .pipe(untilDestroyed(this))
      .subscribe(
        (group: ProjectGroup) => {
          if (!group) {
            return;
          }
          this.notificationService.success('Created', 'Team was successfully added to current App');
          this.analyticsService.sendSimpleEvent(AnalyticsEvent.Team.Created, {
            TeamID: undefined,
            Mode: this.mode
          });
          this.loading = false;
          this.cd.markForCheck();

          this.onSaved(group);
        },
        error => {
          console.log(error);

          if (error instanceof ServerRequestError && error.errors.length) {
            this.notificationService.error('Saving team failed', error.errors[0]);
          } else {
            this.notificationService.error('Saving team failed', errorToString(error));
          }

          this.loading = false;
          this.cd.markForCheck();
        }
      );
  }

  close() {
    this.popupComponent.close();
  }

  onSaved(group: ProjectGroup) {
    this.created.emit(group);
    this.close();
  }
}
