var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import * as moment from 'moment';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';
import { AppConfigService } from '@core';
import { ApiService } from '@modules/api';
import { isSet } from '@shared';
import { User } from '../../data/user';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../api/services/api/api.service";
import * as i3 from "../../../../core/services/app-config/app-config.service";
var UserService = /** @class */ (function () {
    function UserService(http, apiService, appConfigService) {
        this.http = http;
        this.apiService = apiService;
        this.appConfigService = appConfigService;
    }
    UserService.prototype.get = function (search) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.methodURL('users/');
            var headers = new HttpHeaders();
            var httpParams = new HttpParams({ fromObject: { search: search } });
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.get(url, { headers: headers, params: httpParams });
        }), map(function (result) { return result.map(function (item) { return new User().deserialize(item); }); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    UserService.prototype.getCurrent = function (forceOriginal) {
        var _this = this;
        if (forceOriginal === void 0) { forceOriginal = false; }
        return this.apiService.refreshToken(forceOriginal).pipe(switchMap(function () {
            var url = _this.apiService.methodURL('current_user/');
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers, undefined, forceOriginal);
            return _this.http.get(url, { headers: headers });
        }), map(function (result) { return new User().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    UserService.prototype.create = function (user, options) {
        if (options === void 0) { options = {}; }
        var url = this.apiService.methodURL('users/');
        var data = user.serialize();
        if (options.projectName) {
            data['project'] = options.projectName;
        }
        if (options.projectToken) {
            data['project_token'] = options.projectToken;
        }
        if (options.transferDemoProjects) {
            data['transfer_projects'] = options.transferDemoProjects.join(',');
        }
        if (options.language) {
            data['language'] = options.language;
        }
        if (options.customFields) {
            data['custom_fields'] = options.customFields;
        }
        return this.http.post(url, data).pipe(map(function (result) {
            return {
                user: new User().deserialize(result),
                selfSignOn: result['self_sign_on']
            };
        }), this.apiService.catchApiError(false), publishLast(), refCount());
    };
    UserService.prototype.update = function (user, fields) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.methodURL('current_user/edit/');
            var headers = new HttpHeaders();
            var params = {
                v: _this.appConfigService.version
            };
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.patch(url, user.serialize(fields), { headers: headers, params: params });
        }), map(function (result) {
            var userUpdated = new User().deserialize(result);
            return __assign({ user: userUpdated }, (result['access_token'] && {
                token: __assign({ accessToken: result['access_token'], accessTokenExpires: moment(result['access_token_expires']), serverTime: moment(result['server_time']) }, (result['refresh_token'] && {
                    refreshToken: result['refresh_token']
                }), (result['refresh_token_expires'] && {
                    refreshTokenExpires: moment(result['refresh_token_expires'])
                }))
            }));
        }), this.apiService.catchApiError(false), publishLast(), refCount());
    };
    UserService.prototype.updatePhoto = function (file) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.methodURL('current_user/edit/');
            var headers = new HttpHeaders();
            var data;
            if (file) {
                data = new FormData();
                data.append('photo', file);
            }
            else {
                data = {
                    photo: null
                };
            }
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.patch(url, data, { headers: headers });
        }), map(function (result) { return new User().deserialize(result); }), this.apiService.catchApiError(false), publishLast(), refCount());
    };
    UserService.prototype.changeEmail = function (newEmail, currentPassword) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.nodeMethodURL('users/change_email');
            var headers = new HttpHeaders();
            var data = __assign({ email: newEmail }, (isSet(currentPassword) && {
                password: currentPassword
            }));
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.post(url, data, { headers: headers });
        }), map(function (result) { return new UserService.ChangeEmailResponse().deserialize(result); }), this.apiService.catchApiError(false), publishLast(), refCount());
    };
    UserService.prototype.changeEmailConfirm = function (code) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.nodeMethodURL('users/change_email_confirm');
            var headers = new HttpHeaders();
            var data = {
                code: code
            };
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.post(url, data, { headers: headers });
        }), map(function (result) { return new UserService.ChangeEmailConfirmResponse().deserialize(result); }), this.apiService.catchApiError(false), publishLast(), refCount());
    };
    UserService.prototype.restore = function (email) {
        var url = this.apiService.methodURL('users/restore/');
        var data = {
            email: email
        };
        return this.http.post(url, data).pipe(map(function (result) { return true; }), this.apiService.catchApiError(false), publishLast(), refCount());
    };
    UserService.prototype.restoreComplete = function (code, password) {
        var url = this.apiService.methodURL('users/restore_complete/');
        var data = {
            validation_code: code,
            password: password
        };
        return this.http.post(url, data).pipe(map(function (result) { return new UserService.RestoreCompleteResponse().deserialize(result); }), this.apiService.catchApiError(false), publishLast(), refCount());
    };
    UserService.prototype.resendVerification = function () {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.methodURL('resend_verification_email/');
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.post(url, null, { headers: headers });
        }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    UserService.prototype.verify = function (email, code) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.methodURL('verify_email/');
            var data = {
                email: email,
                code: code
            };
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.post(url, data, { headers: headers });
        }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    UserService.ngInjectableDef = i0.defineInjectable({ factory: function UserService_Factory() { return new UserService(i0.inject(i1.HttpClient), i0.inject(i2.ApiService), i0.inject(i3.AppConfigService)); }, token: UserService, providedIn: "root" });
    return UserService;
}());
export { UserService };
(function (UserService) {
    var RestoreCompleteResponse = /** @class */ (function () {
        function RestoreCompleteResponse() {
        }
        RestoreCompleteResponse.prototype.deserialize = function (data) {
            this.validationCode = data['validation_code'];
            this.token = data['token'];
            return this;
        };
        return RestoreCompleteResponse;
    }());
    UserService.RestoreCompleteResponse = RestoreCompleteResponse;
    var ChangeEmailResponse = /** @class */ (function () {
        function ChangeEmailResponse() {
        }
        ChangeEmailResponse.prototype.deserialize = function (data) {
            this.sentConfirmationTo = data['sent_confirmation_to'];
            return this;
        };
        return ChangeEmailResponse;
    }());
    UserService.ChangeEmailResponse = ChangeEmailResponse;
    var ChangeEmailConfirmResponse = /** @class */ (function () {
        function ChangeEmailConfirmResponse() {
        }
        ChangeEmailConfirmResponse.prototype.deserialize = function (data) {
            this.oldEmail = data['old_email'];
            this.newEmail = data['new_email'];
            return this;
        };
        return ChangeEmailConfirmResponse;
    }());
    UserService.ChangeEmailConfirmResponse = ChangeEmailConfirmResponse;
})(UserService || (UserService = {}));
