import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import { Observable, throwError } from 'rxjs';
import { catchError, delayWhen, switchMap, tap } from 'rxjs/operators';

import { NotificationService } from '@common/notifications';
import { ServerRequestError } from '@modules/api';
import { ViewContext, ViewContextElement } from '@modules/customize';
import {
  AggregateDisplayField,
  BaseField,
  ComputedDisplayField,
  DisplayField,
  LookupDisplayField
} from '@modules/fields';
import { ModelDescriptionService, ModelDescriptionStore } from '@modules/model-queries';
import {
  ModelDbField,
  ModelDescription,
  ModelField,
  ModelFieldType,
  ModelFlexField,
  sortModelFields
} from '@modules/models';
import { CurrentEnvironmentStore, CurrentProjectStore, Resource } from '@modules/projects';
import { errorToString } from '@shared';

import { CustomizeBarEditEvent } from '../../data/customize-bar-edit-event';
import { CustomizeBarEditEventType } from '../../data/customize-bar-edit-event-type';
import { CustomizeBarColumnEditController } from '../customize-bar-column-edit/customize-bar-column-edit.component';
import {
  CustomizeBarColumnEditForm,
  CustomizeColumnResult
} from '../customize-bar-column-edit/customize-bar-column-edit.form';

@Component({
  selector: 'app-customize-bar-model-field-edit',
  templateUrl: './customize-bar-model-field-edit.component.html',
  providers: [CustomizeBarColumnEditForm],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeBarModelFieldEditComponent
  implements OnInit, OnDestroy, CustomizeBarColumnEditController<ModelDescription> {
  @Input() resource: Resource;
  @Input() modelDescription: ModelDescription;
  @Input() modelField: ModelField;
  @Input() defaults: BaseField;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() contextElementPath: (string | number)[];
  @Input() contextElementPaths: (string | number)[][];
  @Input() backLabel = 'Back';
  @Input() deleteEnabled = false;
  @Input() analyticsSource: string;
  @Input() trackConfigured = false;
  @Input() firstInit = false;
  @Output() saved = new EventEmitter<ModelDescription>();
  @Output() cancelled = new EventEmitter<void>();

  field: BaseField;
  fieldEditable = false;
  valueEditable = false;
  lookupEditable = false;
  aggregateEditable = false;

  constructor(
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private modelDescriptionService: ModelDescriptionService,
    private modelDescriptionStore: ModelDescriptionStore,
    private notificationService: NotificationService
  ) {}

  ngOnInit() {
    this.field = this.modelField ? this.modelField.item : this.defaults;
    this.fieldEditable = !(this.field instanceof DisplayField);
    this.valueEditable = this.field instanceof ComputedDisplayField;
    this.lookupEditable = this.field instanceof LookupDisplayField;
    this.aggregateEditable = this.field instanceof AggregateDisplayField;
  }

  ngOnDestroy(): void {}

  submit(columnResult: CustomizeColumnResult): Observable<ModelDescription> {
    return this.modelDescriptionStore.getDetailFirst(this.modelDescription.modelId).pipe(
      switchMap(modelDescription => {
        const result = cloneDeep(modelDescription);

        if (this.modelField) {
          result.fields = result.fields.map(item => {
            if (item.name == this.modelField.name) {
              const newItem = cloneDeep(item) as ModelField;
              newItem.item = columnResult.field as ModelDbField | ModelFlexField;
              return newItem;
            } else {
              return item;
            }
          });
        } else {
          const newItem = new ModelField();
          const sortedFields = sortModelFields(modelDescription.fields);
          const lastField = sortedFields[sortedFields.length - 1];

          if (columnResult.field instanceof DisplayField) {
            newItem.type = ModelFieldType.Custom;
            newItem.item = columnResult.field;
            newItem.name = newItem.item.name;
            newItem.orderAfter = lastField ? lastField.name : undefined;
          }

          result.fields.push(newItem);
        }

        return this.modelDescriptionService.update(
          this.currentProjectStore.instance.uniqueName,
          this.currentEnvironmentStore.instance.uniqueName,
          result
        );
      }),
      delayWhen(() => this.modelDescriptionStore.getFirst(true)),
      tap(() => {
        this.notificationService.success('Updated', `Collection field was successfully updated`);
      }),
      catchError(error => {
        if (error instanceof ServerRequestError && error.errors.length) {
          this.notificationService.error('Field update failed', error.errors[0]);
        } else {
          this.notificationService.error('Field update failed', errorToString(error));
        }

        return throwError(error);
      })
    );
  }

  onEvent(e: CustomizeBarEditEvent) {
    if (e.type == CustomizeBarEditEventType.Updated && e.args['submit']) {
      const modelDescription = e.args['controller_result'] as ModelDescription;
      this.saved.emit(modelDescription);
    } else if (e.type == CustomizeBarEditEventType.Canceled) {
      this.cancelled.emit();
    }
  }
}
