var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { InjectionToken, Injector, StaticProvider } from '@angular/core';
import { ActivationEnd, Router } from '@angular/router';
import fromPairs from 'lodash/fromPairs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, merge, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { getCurrentLanguage } from '@common/localize';
import { DocumentService } from '@core';
import { ApiService } from '@modules/api';
import { ViewContext, ViewContextElement } from '@modules/customize';
import { FieldType } from '@modules/fields';
import { ProjectApiService } from '@modules/project-api';
import { CurrentEnvironmentStore, CurrentProjectStore, ProjectPropertyStore, ProjectPropertyType } from '@modules/projects';
import { ThemeService } from '@modules/theme';
import { CurrentUserStore } from '@modules/users';
import { ascComparator, getLocationAppPath, getLocationQueryParams } from '@shared';
// TODO: Refactor import
import { ProjectPropertyEditController } from '../../customize-bar/services/project-property-edit-controller/project-property-edit.controller';
export var teamToken = new InjectionToken('teamToken');
export var teamPropertiesToken = new InjectionToken('teamPropertiesToken');
export var userToken = new InjectionToken('userToken');
export var userPropertiesToken = new InjectionToken('userPropertiesToken');
export var globalPropertiesToken = new InjectionToken('globalPropertiesToken');
export var appPropertiesToken = new InjectionToken('appPropertiesToken');
export var devicePropertiesToken = new InjectionToken('appDeviceToken');
var GlobalContext = /** @class */ (function (_super) {
    __extends(GlobalContext, _super);
    function GlobalContext(router, parentInjector, currentUserStore, projectPropertyStore, projectPropertyEditController, apiService, projectApiService, currentProjectStore, documentService, themeService, currentEnvironmentStore) {
        var _this = _super.call(this, currentEnvironmentStore) || this;
        _this.router = router;
        _this.parentInjector = parentInjector;
        _this.currentUserStore = currentUserStore;
        _this.projectPropertyStore = projectPropertyStore;
        _this.projectPropertyEditController = projectPropertyEditController;
        _this.apiService = apiService;
        _this.projectApiService = projectApiService;
        _this.currentProjectStore = currentProjectStore;
        _this.documentService = documentService;
        _this.themeService = themeService;
        _this.providers = [
            { provide: teamToken, useFactory: function () { return _this.createContextElement(); }, deps: [] },
            { provide: teamPropertiesToken, useFactory: function () { return _this.createContextElement(); }, deps: [] },
            { provide: userToken, useFactory: function () { return _this.createContextElement(); }, deps: [] },
            { provide: userPropertiesToken, useFactory: function () { return _this.createContextElement(); }, deps: [] },
            { provide: globalPropertiesToken, useFactory: function () { return _this.createContextElement(); }, deps: [] },
            { provide: appPropertiesToken, useFactory: function () { return _this.createContextElement(); }, deps: [] },
            { provide: devicePropertiesToken, useFactory: function () { return _this.createContextElement(); }, deps: [] }
        ];
        _this.injector = Injector.create({
            providers: _this.providers,
            parent: _this.parentInjector
        });
        _this.viewContextElementTeam = _this.injector.get(teamToken);
        _this.viewContextElementTeamProperty = _this.injector.get(teamPropertiesToken);
        _this.viewContextElementUser = _this.injector.get(userToken);
        _this.viewContextElementUserProperty = _this.injector.get(userPropertiesToken);
        _this.viewContextElementGlobalProperty = _this.injector.get(globalPropertiesToken);
        _this.viewContextElementAppProperty = _this.injector.get(appPropertiesToken);
        _this.viewContextElementDeviceProperty = _this.injector.get(devicePropertiesToken);
        _this.init();
        return _this;
    }
    GlobalContext.prototype.createContextElement = function () {
        return new ViewContextElement(this);
    };
    GlobalContext.prototype.onBeforeDestroy = function () {
        this.pauseElements();
        this.clear();
    };
    GlobalContext.prototype.init = function () {
        var _this = this;
        this.initViewContextElements();
        this.projectPropertyStore
            .getGlobal()
            .pipe(untilDestroyed(this))
            .subscribe(function (properties) {
            properties = properties || [];
            var outputs = properties
                .map(function (item) {
                return __assign({ uniqueName: item.uid, name: item.name, icon: item.fieldDescription.icon }, (item.field
                    ? {
                        fieldType: item.field.field,
                        fieldParams: item.field.params
                    }
                    : {}));
            })
                .sort(function (lhs, rhs) { return ascComparator(lhs.name, rhs.name); });
            _this.viewContextElementGlobalProperty.setOutputs(outputs);
        });
        this.currentEnvironmentStore.globalStorage
            .getValues$()
            .pipe(untilDestroyed(this))
            .subscribe(function (value) {
            _this.viewContextElementGlobalProperty.setOutputValues(value);
        });
        combineLatest(this.currentUserStore.instance$, this.currentProjectStore.instance$, this.currentEnvironmentStore.instance$, this.projectPropertyStore.get(), this.currentEnvironmentStore.globalStorage.getValues$())
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var currentUser = _a[0], currentProject = _a[1], currentEnvironment = _a[2], projectProperties = _a[3], globalProjectProperties = _a[4];
            var userProperties = projectProperties
                ? projectProperties.filter(function (item) { return item.type == ProjectPropertyType.User; })
                : [];
            var groupProperties = projectProperties
                ? projectProperties.filter(function (item) { return item.type == ProjectPropertyType.Group; })
                : [];
            _this.updateContextUser();
            _this.updateContextUserProperties(userProperties);
            _this.updateContextTeam();
            _this.updateContextTeamProperties(groupProperties);
            if (currentEnvironment && currentEnvironment.user) {
                var value = fromPairs(userProperties.map(function (property) { return [property.uid, currentEnvironment.user.getPropertyValue(property)]; }));
                _this.viewContextElementUserProperty.setOutputValues(value);
            }
            if (currentEnvironment && currentEnvironment.group) {
                var value = fromPairs(groupProperties.map(function (property) { return [property.uid, currentEnvironment.group.getPropertyValue(property)]; }));
                _this.viewContextElementTeamProperty.setOutputValues(value);
            }
            _this.viewContextElementTeam.setOutputValues(__assign({}, (currentEnvironment && currentEnvironment.group
                ? {
                    uid: currentEnvironment.group.uid,
                    name: currentEnvironment.group.name
                }
                : {})));
            _this.viewContextElementUser.setOutputValues({
                email: currentUser ? currentUser.email : undefined,
                first_name: currentUser ? currentUser.firstName : undefined,
                last_name: currentUser ? currentUser.lastName : undefined,
                token: _this.apiService.getAccessToken() ? _this.apiService.getAccessToken() : undefined,
                project_token: _this.projectApiService.getAccessToken() ? _this.projectApiService.getAccessToken() : undefined,
                uid: currentEnvironment && currentEnvironment.user ? currentEnvironment.user.uid : undefined,
                language: getCurrentLanguage()
            });
            _this.viewContextElementAppProperty.patchOutputValues({
                name: currentProject.uniqueName,
                env_name: currentEnvironment ? currentEnvironment.uniqueName : undefined
            });
        });
        merge(of({}), this.router.events.pipe(filter(function (item) { return item instanceof ActivationEnd; })))
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            _this.viewContextElementAppProperty.patchOutputValues({
                app_path: getLocationAppPath(),
                query_params: getLocationQueryParams()
            });
        });
        this.documentService.viewportSize$.pipe(untilDestroyed(this)).subscribe(function (value) {
            var type = _this.documentService.getViewportType(value.width);
            _this.viewContextElementDeviceProperty.patchOutputValues({
                is_desktop: _this.documentService.isDesktopViewportType(type),
                is_mobile: _this.documentService.isMobileViewportType(type),
                is_phone: _this.documentService.isPhoneViewportType(type),
                is_tablet: _this.documentService.isTabletViewportType(type),
                screen_width: value.width,
                screen_height: value.height
            });
        });
        this.themeService.isDarkTheme$.pipe(untilDestroyed(this)).subscribe(function (value) {
            _this.viewContextElementDeviceProperty.patchOutputValues({
                dark_theme: value
            });
        });
    };
    GlobalContext.prototype.initViewContextElements = function () {
        var _this = this;
        this.viewContextElementTeam.unregister();
        this.viewContextElementTeam.initGlobal({ uniqueName: 'group', name: 'Team' });
        this.viewContextElementTeamProperty.unregister();
        this.viewContextElementTeamProperty.initGlobal({
            uniqueName: 'team_properties',
            name: 'Team Properties',
            action: {
                label: 'Add Property',
                icon: 'plus',
                handler: function () {
                    return _this.projectPropertyEditController
                        .create({
                        type: ProjectPropertyType.Group,
                        context: _this,
                        analyticsSource: 'component_input'
                    })
                        .pipe(map(function (item) {
                        return {
                            insertToken: ['team_properties', item.property.uid]
                        };
                    }));
                }
            },
            documentation: 'conditional-visibility/properties'
        });
        this.viewContextElementUser.unregister();
        this.viewContextElementUser.initGlobal({ uniqueName: 'user', name: 'User' });
        this.viewContextElementUserProperty.unregister();
        this.viewContextElementUserProperty.initGlobal({
            uniqueName: 'user_properties',
            name: 'User Properties',
            action: {
                label: 'Add Property',
                icon: 'plus',
                handler: function () {
                    return _this.projectPropertyEditController
                        .create({
                        type: ProjectPropertyType.User,
                        context: _this,
                        analyticsSource: 'component_input'
                    })
                        .pipe(map(function (item) {
                        return {
                            insertToken: ['user_properties', item.property.uid]
                        };
                    }));
                }
            },
            documentation: 'conditional-visibility/properties'
        });
        this.viewContextElementGlobalProperty.unregister();
        this.viewContextElementGlobalProperty.initGlobal({
            uniqueName: 'global_variables',
            name: 'Global variables',
            action: {
                label: 'Add Variable',
                icon: 'plus',
                handler: function () {
                    return _this.projectPropertyEditController
                        .create({
                        type: ProjectPropertyType.Global,
                        defaultName: 'variable',
                        defaultValueEnabled: true,
                        context: _this,
                        analyticsSource: 'component_input'
                    })
                        .pipe(map(function (item) {
                        return {
                            insertToken: ['global_variables', item.property.uid]
                        };
                    }));
                }
            },
            documentation: 'conditional-visibility/properties'
        });
        this.viewContextElementAppProperty.unregister();
        this.viewContextElementAppProperty.initGlobal({ uniqueName: 'app', name: 'Application' });
        this.viewContextElementAppProperty.setOutputs([
            {
                uniqueName: 'name',
                name: 'App name',
                icon: 'home'
            },
            {
                uniqueName: 'env_name',
                name: 'Environment name',
                icon: 'tag'
            },
            {
                uniqueName: 'app_path',
                name: 'App path',
                icon: 'console'
            },
            {
                uniqueName: 'query_params',
                name: 'URL query parameters',
                icon: 'components',
                fieldType: FieldType.JSON
            }
        ]);
        this.viewContextElementDeviceProperty.unregister();
        this.viewContextElementDeviceProperty.initGlobal({ uniqueName: 'device', name: 'Device' });
        this.viewContextElementDeviceProperty.setOutputs([
            {
                uniqueName: 'is_desktop',
                name: 'Is Desktop',
                icon: 'pages'
            },
            {
                uniqueName: 'is_mobile',
                name: 'Is Mobile',
                icon: 'pages'
            },
            {
                uniqueName: 'is_phone',
                name: 'Is Phone',
                icon: 'pages'
            },
            {
                uniqueName: 'is_tablet',
                name: 'Is Tablet',
                icon: 'pages'
            },
            {
                uniqueName: 'screen_width',
                name: 'Screen width',
                icon: 'align_horizontal_fill'
            },
            {
                uniqueName: 'screen_height',
                name: 'Screen height',
                icon: 'align_vertical_fill'
            },
            {
                uniqueName: 'dark_theme',
                name: 'Dark theme',
                icon: 'toggle_theme'
            }
        ]);
    };
    GlobalContext.prototype.updateContextUser = function () {
        this.viewContextElementUser.setOutputs([
            {
                uniqueName: 'email',
                name: 'Email',
                icon: 'email'
            },
            {
                uniqueName: 'first_name',
                name: 'First name',
                icon: 'user'
            },
            {
                uniqueName: 'last_name',
                name: 'Last name',
                icon: 'user'
            },
            {
                uniqueName: 'token',
                name: 'User token',
                icon: 'key'
            },
            {
                uniqueName: 'project_token',
                name: 'User app token',
                icon: 'key'
            },
            {
                uniqueName: 'uid',
                name: 'User ID',
                icon: 'number'
            },
            {
                uniqueName: 'language',
                name: 'Language',
                icon: 'earth_planet'
            }
        ]);
    };
    GlobalContext.prototype.updateContextUserProperties = function (properties) {
        var outputs = properties.map(function (item) {
            return __assign({ uniqueName: item.uid, name: item.name, icon: item.fieldDescription.icon }, (item.field
                ? {
                    fieldType: item.field.field,
                    fieldParams: item.field.params
                }
                : {}));
        });
        this.viewContextElementUserProperty.setOutputs(outputs);
    };
    GlobalContext.prototype.updateContextTeam = function () {
        this.viewContextElementTeam.setOutputs([
            {
                uniqueName: 'uid',
                name: 'Team ID',
                icon: 'number'
            },
            {
                uniqueName: 'name',
                name: 'Team name',
                icon: 'users_teams'
            }
        ]);
    };
    GlobalContext.prototype.updateContextTeamProperties = function (properties) {
        var outputs = properties.map(function (item) {
            return __assign({ uniqueName: item.uid, name: item.name, icon: item.fieldDescription.icon }, (item.field
                ? {
                    fieldType: item.field.field,
                    fieldParams: item.field.params
                }
                : {}));
        });
        this.viewContextElementTeamProperty.setOutputs(outputs);
    };
    return GlobalContext;
}(ViewContext));
export { GlobalContext };
