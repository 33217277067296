import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';

import { DialogButtonHotkey, DialogButtonType, DialogService } from '@common/dialogs';
import { NotificationService } from '@common/notifications';
import { ActionService, WorkflowStepInfo } from '@modules/action-queries';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ServerRequestError } from '@modules/api';
import { WorkflowEditController } from '@modules/customize-bar';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { Automation, AutomationService, AutomationTriggerType, WorkflowRunStatus } from '@modules/workflow';
import { errorToString, TypedChanges } from '@shared';

@Component({
  selector: 'app-automations-item, [app-automations-item]',
  templateUrl: './automations-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AutomationsItemComponent implements OnInit, OnDestroy, OnChanges {
  @Input() item: Automation;
  @Output() edit = new EventEmitter<void>();
  @Output() duplicate = new EventEmitter<void>();
  @Output() deleted = new EventEmitter<void>();
  @Output() updated = new EventEmitter<Automation>();

  loadingActive = false;
  loadingRun = false;
  triggerTypes = AutomationTriggerType;
  stepsInfo: WorkflowStepInfo[] = [];
  stepsMore = 0;
  statuses = WorkflowRunStatus;

  @HostListener('click') onClick() {
    this.edit.emit();
  }

  constructor(
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private automationService: AutomationService,
    private actionService: ActionService,
    private workflowEditController: WorkflowEditController,
    private dialogService: DialogService,
    private notificationService: NotificationService,
    private analyticsService: UniversalAnalyticsService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.automationService
      .subscribeAutomation(this.currentProjectStore.instance, this.currentEnvironmentStore.instance, this.item)
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        result.workflow = this.item.workflow;

        this.item = result;
        this.cd.markForCheck();
        this.updated.emit(result);
      });
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<AutomationsItemComponent>): void {
    if (changes.item) {
      this.updateStepsInfo();
    }
  }

  updateStepsInfo() {
    const maxSteps = 3;
    const steps = this.item.workflow ? this.item.workflow.flattenSteps() : [];

    this.actionService
      .getStepsInfo(steps.slice(0, maxSteps))
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          this.stepsInfo = result;
          this.stepsMore = steps.length - maxSteps;
          this.cd.markForCheck();
        },
        () => {
          this.stepsInfo = [];
          this.stepsMore = 0;
          this.cd.markForCheck();
        }
      );
  }

  toggleItemActive() {
    if (this.loadingActive) {
      return;
    }

    const automation: Automation = cloneDeep(this.item);

    automation.active = !automation.active;

    this.loadingActive = true;
    this.cd.markForCheck();

    return this.automationService
      .update(this.currentProjectStore.instance, this.currentEnvironmentStore.instance, automation, ['active'])
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          this.item = result;
          this.loadingActive = false;
          this.cd.markForCheck();
          this.updated.emit(result);

          if (automation.active) {
            this.notificationService.success(
              'Enabled',
              `Automation <strong>${this.item.name}</strong> was successfully enabled`
            );
          } else {
            this.notificationService.success(
              'Disabled',
              `Automation <strong>${this.item.name}</strong> was successfully disabled`
            );
          }

          this.analyticsService.sendSimpleEvent(AnalyticsEvent.Project.BuilderChange, {
            Type: 'automation',
            AutomationUid: result.uid
          });
        },
        error => {
          this.loadingActive = false;
          this.cd.markForCheck();

          this.notificationService.error('Error', errorToString(error));
        }
      );
  }

  run() {
    this.loadingRun = true;
    this.cd.markForCheck();

    this.automationService
      .run(this.currentProjectStore.instance, this.currentEnvironmentStore.instance, this.item.uid, true)
      .pipe(
        switchMap(() =>
          this.automationService.getDetail(
            this.currentProjectStore.instance,
            this.currentEnvironmentStore.instance,
            this.item.uid
          )
        ),
        untilDestroyed(this)
      )
      .subscribe(
        result => {
          this.item = result;
          this.loadingRun = false;
          this.cd.markForCheck();
          this.updated.emit(result);
        },
        error => {
          this.loadingRun = false;
          this.cd.markForCheck();

          if (error instanceof ServerRequestError && error.errors.length) {
            this.notificationService.error('Error', error.errors[0]);
          } else {
            this.notificationService.error('Error', errorToString(error));
          }
        }
      );
  }

  delete() {
    this.dialogService
      .warning({
        title: 'Deleting',
        description: `Are you sure want to delete automation <strong>${this.item.name}</strong>?`,
        buttons: [
          {
            name: 'cancel',
            label: 'Cancel',
            type: DialogButtonType.Default,
            hotkey: DialogButtonHotkey.Cancel
          },
          {
            name: 'ok',
            label: 'Delete automation',
            type: DialogButtonType.Danger,
            hotkey: DialogButtonHotkey.Submit,
            executor: () => this.deleteProcess()
          }
        ]
      })
      .pipe(
        filter(result => result == true),
        untilDestroyed(this)
      )
      .subscribe(
        () => {
          this.deleted.emit();
        },
        error => {
          if (error instanceof ServerRequestError && error.errors.length) {
            this.notificationService.error('Error', error.errors[0]);
          } else {
            this.notificationService.error('Error', errorToString(error));
          }
        }
      );
  }

  deleteProcess(): Observable<boolean> {
    return this.automationService
      .delete(this.currentProjectStore.instance, this.currentEnvironmentStore.instance, this.item)
      .pipe(
        tap(() => {
          this.analyticsService.sendSimpleEvent(AnalyticsEvent.Project.BuilderChange, {
            Type: 'automation',
            AutomationUid: this.item.uid
          });
        })
      );
  }
}
