import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment-timezone';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, fromEvent, Observable, timer } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

import { localize } from '@common/localize';
import { NotificationService } from '@common/notifications';
import { ApiService } from '@modules/api';
import { Domain } from '@modules/domain';
import { Option } from '@modules/field-components';
import { createFormFieldFactory } from '@modules/fields';
import { MetaService } from '@modules/meta';
import { RoutingService } from '@modules/routing';
import { CurrentUserStore, User, UserService } from '@modules/users';
import { addClass, controlValue, getOffset, getSize, getWindowScrollTop, isSet, removeClass } from '@shared';

import { ChangeEmailForm } from './change-email.form';
import { ChangePasswordForm } from './change-password.form';
import { UserEditForm } from './user-edit.form';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  providers: [UserEditForm, ChangeEmailForm, ChangePasswordForm],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserEditComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('container_footer') containerFooter: ElementRef;
  @ViewChild('footer') footer: ElementRef;

  loading = false;
  submitting = false;
  changingEmail = false;
  changingPassword = false;
  domain: Domain;
  user: User;
  photo: string;
  createField = createFormFieldFactory();
  isWhiteLabel: boolean;
  currentTimezoneOption: Option;
  currentTimezoneTime$: Observable<moment.Moment>;
  externalAuth = false;

  constructor(
    public userEditForm: UserEditForm,
    public changePasswordForm: ChangePasswordForm,
    public changeEmailForm: ChangeEmailForm,
    private userService: UserService,
    private notificationService: NotificationService,
    private currentUserStore: CurrentUserStore,
    private apiService: ApiService,
    private routing: RoutingService,
    private metaService: MetaService,
    private cd: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.metaService.set({ title: localize('Profile Settings') });

    this.activatedRoute.data.pipe(untilDestroyed(this)).subscribe(data => {
      this.domain = data.domain;
      this.isWhiteLabel = data.domain && data.domain.whiteLabel;
      this.cd.markForCheck();
    });

    this.loading = true;
    this.cd.markForCheck();
    this.enableTheme();

    this.currentUserStore
      .getFirst(true)
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          if (!result) {
            this.routing.navigateUnauthenticated(this.activatedRoute.snapshot);
            return;
          }

          this.user = result;
          this.photo = this.user.photo;
          this.loading = false;
          this.cd.markForCheck();
          this.userEditForm.init(this.user);
        },
        () => {
          this.loading = false;
          this.cd.markForCheck();
        }
      );

    combineLatest(
      this.changePasswordForm.controls.new_password.valueChanges,
      this.changePasswordForm.controls.repeat_password.valueChanges
    )
      .pipe(untilDestroyed(this))
      .pipe(debounceTime(100))
      .subscribe(v => {
        this.setErrorPassword();
      });

    controlValue(this.userEditForm.controls.timezone)
      .pipe(untilDestroyed(this))
      .subscribe(timezone => {
        const option = isSet(timezone)
          ? this.userEditForm.timezoneOptions.find(item => item.value == timezone)
          : this.userEditForm.timezoneDefaultOption;

        this.currentTimezoneOption = option;
        this.currentTimezoneTime$ = option
          ? timer(0, 1000).pipe(
              map(() => {
                if (!option.data || !isSet(option.data['tz'])) {
                  return;
                }

                return moment().tz(option.data['tz']);
              })
            )
          : undefined;
        this.cd.markForCheck();
      });

    this.externalAuth = isSet(this.apiService.getSSOUid()) || isSet(this.apiService.getSocialBackend());
    this.cd.markForCheck();
  }

  ngAfterViewInit(): void {
    this.updatePositionFooter();
    fromEvent(window, 'scroll')
      .pipe(untilDestroyed(this))
      .subscribe(event => {
        this.updatePositionFooter();
      });
  }

  ngOnDestroy(): void {
    this.disableTheme();
  }

  enableTheme() {
    addClass(document.body, 'theme_dark');
  }

  disableTheme() {
    removeClass(document.body, 'theme_dark');
  }

  onFileChange(el: HTMLInputElement) {
    if (!el.files.length) {
      return;
    }

    const file = el.files[0];

    this.userService
      .updatePhoto(file)
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          this.photo = result.photo;
          this.cd.markForCheck();
          this.currentUserStore.getFirst(true);

          this.notificationService.success(localize('Saved'), localize('Photo was successfully updated'));
        },
        error => {
          this.notificationService.error(localize('Error'), error.errors[0]);
        }
      );
  }

  deletePhoto() {
    this.photo = undefined;
    this.cd.markForCheck();

    this.userService
      .updatePhoto(undefined)
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          this.currentUserStore.getFirst(true);

          this.notificationService.success(localize('Removed'), localize('Photo was successfully deleted'));
        },
        error => {
          this.notificationService.error(localize('Error'), error.errors[0]);
        }
      );
  }

  submit() {
    this.submitting = true;
    this.cd.markForCheck();

    this.userEditForm
      .submit()
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          this.submitting = false;
          this.cd.markForCheck();

          this.currentUserStore.getFirst(true);

          this.notificationService.success(localize('Saved'), localize('Current user was successfully updated'));

          if (result.languageChanged) {
            setTimeout(() => window.location.reload(), 1000);
          }
        },
        () => {
          this.submitting = false;
          this.cd.markForCheck();

          this.notificationService.error(localize('Error'), localize('Saving current user failed'));
        }
      );
  }

  back() {
    window.history.back();
  }

  get validPassword(): boolean {
    return (
      this.changePasswordForm.controls.new_password.value == this.changePasswordForm.controls.repeat_password.value &&
      this.changePasswordForm.controls.new_password.value &&
      this.changePasswordForm.controls.new_password.value.length > 5
    );
  }

  setErrorPassword() {
    if (
      this.changePasswordForm.controls.new_password.value &&
      this.changePasswordForm.controls.new_password.value.length < 6
    ) {
      this.changePasswordForm.controls.new_password.setErrors({ server: localize('Short password') });
    } else if (
      this.changePasswordForm.controls.repeat_password.value &&
      this.changePasswordForm.controls.new_password.value &&
      this.changePasswordForm.controls.new_password.value !== this.changePasswordForm.controls.repeat_password.value
    ) {
      this.changePasswordForm.controls.new_password.setErrors(null);
      this.changePasswordForm.controls.repeat_password.setErrors({ server: localize("Passwords don't match") });
    } else {
      this.changePasswordForm.controls.new_password.setErrors(null);
      this.changePasswordForm.controls.repeat_password.setErrors(null);
    }
  }

  changeEmail() {
    if (this.changingEmail) {
      return;
    }

    this.changingEmail = true;
    this.cd.markForCheck();

    this.changeEmailForm
      .submit()
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          this.changingEmail = false;
          this.cd.markForCheck();

          this.notificationService.success(
            localize('Check your inbox'),
            localize('Confirmation Email was sent to {0}', [result.sentConfirmationTo])
          );
        },
        () => {
          this.changingEmail = false;
          this.cd.markForCheck();

          this.notificationService.error(localize('Error'), localize('Changing Email failed'));
        }
      );
  }

  changePassword() {
    if (this.changingPassword) {
      return;
    }

    this.changingPassword = true;
    this.cd.markForCheck();

    this.changePasswordForm
      .changePassword()
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          this.user = result;
          this.changingPassword = false;
          this.cd.markForCheck();

          this.notificationService.success(
            localize('Saved'),
            localize('Current password was successfully updated, all other sessions will be logged out shortly')
          );
        },
        () => {
          this.changingPassword = false;
          this.cd.markForCheck();

          this.notificationService.error(localize('Error'), localize('Saving current password failed'));
        }
      );
  }

  updatePositionFooter() {
    if (!this.containerFooter || !this.footer) {
      return;
    }

    const scroll = getWindowScrollTop();
    const position = getOffset(this.containerFooter.nativeElement);
    const size = getSize(this.containerFooter.nativeElement);
    if (scroll + window.innerHeight < position.top + size.height) {
      addClass(this.footer.nativeElement, 'workspace__footer_fixed');
    } else {
      removeClass(this.footer.nativeElement, 'workspace__footer_fixed');
    }
  }
}
