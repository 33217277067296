<div
  *ngIf="!customView"
  class="kanban-board-item"
  [class.kanban-board-item_draggable]="draggable"
  [class.kanban-board-item_clickable]="true"
  [class.kanban-board-item_selected]="selected"
  [class.kanban-board-item_theme]="theme"
  (click)="open($event, root_element)"
  #root_element
>
  <app-model-card
    [item]="item"
    [modelDescription]="modelDescription"
    [vertical]="true"
    [visibleColumns]="visibleColumns"
    [columns]="settings.dataSource?.columns || []"
    [columnActions]="settings.columnActions"
    [context]="context"
    [contextElement]="contextElement"
    [theme]="theme"
    (modelUpdated)="modelUpdated.emit($event)"
  ></app-model-card>

  <div *ngIf="settings.modelActions.length" class="kanban-board-item__buttons">
    <ng-container *ngFor="let action of settings.modelActions">
      <app-model-card-action
        class="kanban-board-item__buttons-item"
        [model]="item.model"
        [action]="action"
        [context]="context"
        [contextElement]="contextElement"
        [accentColor]="accentColor"
        [theme]="theme"
        (click)="$event.stopPropagation()"
      >
      </app-model-card-action>
    </ng-container>
  </div>

  <div *ngIf="settings.commentsEnabled" class="kanban-board-item__widget">
    <a
      href="javascript:void(0)"
      class="kanban-board-item-widget"
      [class.kanban-board-item-widget_disabled]="!commentsConfigured"
      (click)="$event.stopPropagation(); openTimeline()"
    >
      <div class="kanban-board-item-widget__icon icon-comments"></div>
      <div *ngIf="commentsCount" class="kanban-board-item-widget__label">{{ commentsCount }}</div>
    </a>
  </div>
</div>

<div
  *ngIf="customView"
  class="kanban-board-item-view"
  [class.kanban-board-item-view_draggable]="draggable"
  [class.kanban-board-item-view_clickable]="true"
  (click)="open($event, root_element)"
  #root_element
>
  <app-custom-element-auto
    [source]="customView.source"
    [customView]="customView"
    [actions]="settings.customActions"
    [params]="viewParams$ | async"
    [context]="context"
    [contextElement]="contextElement"
    [localContext]="localContext"
    [stateSelectedEnabled]="true"
    [stateSelected]="selected"
  ></app-custom-element-auto>
</div>
