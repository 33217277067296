var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { AfterViewInit, ChangeDetectorRef, OnChanges, OnDestroy, OnInit, QueryList, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import fromPairs from 'lodash/fromPairs';
import isEqual from 'lodash/isEqual';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { debounceTime, filter, map } from 'rxjs/operators';
import { DropListOrientation } from '@common/drag-drop2';
import { DocumentService } from '@core';
import { TintStyle } from '@modules/actions';
import { UniversalAnalyticsService } from '@modules/analytics';
import { CustomizeService, ElementItem, ElementType, registerElementComponent, TabsAppearance, TabsLayoutElementItem, TabsLayoutItem, TabsOrientation, TabsStyle, traverseElementItems } from '@modules/customize';
import { AutoElementComponent, BaseElementComponent, ElementContainerService } from '@modules/customize-elements';
import { applyBooleanInput$, Input as FieldInput } from '@modules/fields';
import { RoutingService } from '@modules/routing';
import { isSet } from '@shared';
import { ElementGroupsContainerDirective } from '../../directives/element-groups-container/element-groups-container.directive';
import { FormElementComponent } from '../form-element/form-element.component';
import { RootLayoutComponent } from '../root-layout/root-layout.component';
var TabsLayoutComponent = /** @class */ (function (_super) {
    __extends(TabsLayoutComponent, _super);
    function TabsLayoutComponent(customizeService, elementContainerService, routing, analyticsService, rootLayoutComponent, formElementComponent, documentService, cd, routingService, activatedRoute) {
        var _this = _super.call(this) || this;
        _this.customizeService = customizeService;
        _this.elementContainerService = elementContainerService;
        _this.routing = routing;
        _this.analyticsService = analyticsService;
        _this.rootLayoutComponent = rootLayoutComponent;
        _this.formElementComponent = formElementComponent;
        _this.documentService = documentService;
        _this.cd = cd;
        _this.routingService = routingService;
        _this.activatedRoute = activatedRoute;
        _this.elementGroupsContainer = new QueryList();
        _this.elementComponents = new QueryList();
        _this.itemsOpened = {};
        _this.isVisible = false;
        _this.tabsVisible$ = new BehaviorSubject([]);
        _this.tabsVisibleById$ = new BehaviorSubject({});
        _this.dropListOrientation = DropListOrientation;
        _this.tabsOrientations = TabsOrientation;
        _this.tabsStyles = TabsStyle;
        _this.tabsAppearances = TabsAppearance;
        _this.tintStyles = TintStyle;
        _this.canEnter = (function () {
            return function (drag, drop) {
                if (_this.rootLayoutComponent && !_this.rootLayoutComponent.active) {
                    return false;
                }
                if (drag.data instanceof ElementItem && drag.data.type == ElementType.FormSubmit) {
                    var isInsideCurrentForm_1 = false;
                    if (_this.formElementComponent) {
                        traverseElementItems(_this.formElementComponent.element, function (item) {
                            if (item === drag.data) {
                                isInsideCurrentForm_1 = true;
                            }
                        });
                    }
                    return isInsideCurrentForm_1;
                }
                else {
                    return true;
                }
            };
        })();
        _this.trackTab = (function () {
            return function (i, item) {
                var pageUid = _this.context && _this.context.viewSettings ? _this.context.viewSettings.uid : undefined;
                return [pageUid, item.uid].join('_');
            };
        })();
        _this.trackElement = (function () {
            return function (i, item) {
                var pageUid = _this.context && _this.context.viewSettings && !_this.context.viewSettings.newlyCreated
                    ? _this.context.viewSettings.uid
                    : undefined;
                return [pageUid, item.uid].join('_');
            };
        })();
        return _this;
    }
    TabsLayoutComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.initVisibleObserver();
        this.customizeEnabled$ = this.customizeService.enabled$.pipe(map(function (item) { return !!item; }));
        this.isMobile$ = this.documentService.isMobile$();
        this.actionClicked
            .pipe(filter(function (item) { return item == 'add'; }), untilDestroyed(this))
            .subscribe(function () { return _this.addTab(); });
        var initialUrl = this.activatedRoute.snapshot.url.map(function (item) { return item.path; });
        combineLatest(this.activatedRoute.url.pipe(map(function (url) { return url.map(function (item) { return item.path; }); })), this.activatedRoute.queryParams.pipe(map(function (params) { return params[_this.elementParam]; })), this.tabsVisibleById$, this.customizeService.enabled$)
            .pipe(debounceTime(0), filter(function (_a) {
            var url = _a[0], urlUid = _a[1], visibleTabsById = _a[2], customize = _a[3];
            return isEqual(url, initialUrl);
        }), map(function (_a) {
            var url = _a[0], urlUid = _a[1], visibleTabsById = _a[2], customize = _a[3];
            var tabs = _this.getVisibleTabs(!!customize);
            var activeUrlTab = urlUid ? tabs.find(function (item) { return item.uid == urlUid; }) : undefined;
            return activeUrlTab || tabs[0];
        }), untilDestroyed(this))
            .subscribe(function (tab) {
            _this.setActiveItemUid(tab ? tab.uid : undefined);
        });
    };
    TabsLayoutComponent.prototype.ngOnDestroy = function () { };
    TabsLayoutComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if (changes['element'] && !changes['element'].firstChange) {
            this.initVisibleObserver();
            setTimeout(function () { return _this.updateAllElementStatesOnStable(); }, 0);
        }
    };
    TabsLayoutComponent.prototype.ngAfterViewInit = function () {
        this.updateAllElementStates();
    };
    Object.defineProperty(TabsLayoutComponent.prototype, "activeItem", {
        get: function () {
            var _this = this;
            if (!isSet(this.activeItemUid)) {
                return;
            }
            return this.element.items.find(function (item) { return item.uid == _this.activeItemUid; });
        },
        enumerable: true,
        configurable: true
    });
    TabsLayoutComponent.prototype.getVisibleTabs = function (customize) {
        return this.tabsVisible$.value
            .filter(function (item) {
            if (customize) {
                return true;
            }
            return item.visible;
        })
            .map(function (item) { return item.tab; });
    };
    TabsLayoutComponent.prototype.initVisibleObserver = function () {
        var _this = this;
        if (this.isVisibleSubscription) {
            this.isVisibleSubscription.unsubscribe();
        }
        var obs$ = this.element.items.map(function (tab) {
            if (!tab.visibleInput) {
                return of({ tab: tab, visible: true });
            }
            else {
                return applyBooleanInput$(tab.visibleInput, { context: _this.context }).pipe(map(function (visible) {
                    return { tab: tab, visible: visible };
                }));
            }
        });
        if (!obs$.length) {
            this.tabsVisible$.next([]);
            this.tabsVisibleById$.next({});
            return;
        }
        combineLatest(obs$)
            .pipe(untilDestroyed(this))
            .subscribe(function (value) {
            _this.tabsVisible$.next(value);
            _this.tabsVisibleById$.next(fromPairs(value.map(function (item) { return [item.tab.uid, item.visible]; })));
            _this.cd.markForCheck();
        });
    };
    // tabDragDrop(event: AppDragDrop<TabsLayoutItem[]>) {
    //   const prevActiveItem = this.activeItem;
    //
    //   moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    //
    //   const activeItemIndex = this.element.items.indexOf(prevActiveItem);
    //
    //   if (activeItemIndex != -1) {
    //     this.setActiveItemIndex(activeItemIndex, false);
    //   }
    //
    //   this.customizeService.markChanged();
    // }
    TabsLayoutComponent.prototype.dragDrop = function (tabIndex, event) {
        var item = event.previousContainer.data[event.previousIndex];
        var barItem = item instanceof ElementItem ? undefined : item;
        if (barItem && barItem.popup) {
            if (this.customizeService.handler && this.customizeService.handler.createPopup) {
                this.customizeService.handler.createPopup(true, __assign({}, (barItem.defaultParams && {
                    width: barItem.defaultParams['width'],
                    style: barItem.defaultParams['style'],
                    position: barItem.defaultParams['position']
                }), { analyticsSource: 'components_library' }));
                this.elementContainerService.sendAddPopupAnalytics();
            }
            return;
        }
        var siblingLeftEntered = event.data ? !!event.data['siblingLeftEntered'] : false;
        var siblingRightEntered = event.data ? !!event.data['siblingRightEntered'] : false;
        var siblingSelf = event.data ? !!event.data['siblingSelf'] : false;
        var siblingAnchor = event.data ? event.data['siblingAnchor'] : undefined;
        var siblingAnchorContainer = event.data ? event.data['siblingAnchorContainer'] : undefined;
        if (siblingLeftEntered || siblingRightEntered) {
            var anchorContainer = siblingSelf ? event.container.data : siblingAnchorContainer.data;
            var anchorIndex = siblingSelf ? event.currentIndex : anchorContainer.indexOf(siblingAnchor);
            this.elementContainerService.dragDropIntoSiblingColumn({
                sourceContainer: event.previousContainer.data,
                sourceIndex: event.previousIndex,
                sourceCloneItem: event.previousContainer.cloneItems,
                anchorContainer: anchorContainer,
                anchorIndex: anchorIndex,
                anchorSelf: siblingSelf,
                left: siblingLeftEntered,
                context: this.context,
                parent: this.element
            });
            // TODO: Implement onAdd
            // this.cd.detectChanges();
            // const component = this.elementComponents.find(i => i.element === elementItem);
            // this.onAdd(elementItem, item, component);
        }
        else {
            if (event.previousContainer === event.container) {
                moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
            }
            else if (event.previousContainer.cloneItems) {
                var elementItem = this.elementContainerService.copyElementItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex, this.context);
                this.customizeService.registerCreatedElement(elementItem, barItem);
            }
            else {
                transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
            }
        }
        this.customizeService.markChanged();
        this.updateElementStatesOnStable(tabIndex);
    };
    TabsLayoutComponent.prototype.replaceItem = function (tabIndex, index, elements) {
        this.elementContainerService.replaceElementItem(this.element.items[tabIndex].children, index, elements);
        this.cd.markForCheck();
        this.customizeService.markChanged();
        this.updateElementStatesOnStable(tabIndex);
    };
    TabsLayoutComponent.prototype.duplicateItem = function (tabIndex, index) {
        var _this = this;
        this.elementContainerService
            .duplicateElementItem(this.element.items[tabIndex].children, this.context, index)
            .pipe(untilDestroyed(this))
            .subscribe(function (elementItem) {
            _this.cd.detectChanges();
            _this.customizeService.markChanged();
            _this.updateElementStatesOnStable(tabIndex);
            var component = _this.elementComponents.find(function (i) { return i.element === elementItem; });
            if (component) {
                component.customize();
            }
        });
    };
    TabsLayoutComponent.prototype.deleteItem = function (element) {
        var _this = this;
        this.element.items.forEach(function (tab, tabIndex) {
            var index = tab.children.findIndex(function (item) { return item === element; });
            if (index == -1) {
                return;
            }
            _this.elementContainerService.deleteElementItem(_this.element.items[tabIndex].children, index);
            _this.cd.markForCheck();
            _this.customizeService.markChanged();
            _this.updateElementStatesOnStable(tabIndex);
        });
    };
    TabsLayoutComponent.prototype.moveItemTo = function (element, link) {
        var _this = this;
        this.deleteItem(element);
        this.customizeService.stopTrackChanges();
        this.customizeService
            .saveActualChanges()
            .pipe(untilDestroyed(this))
            .subscribe(function () { return _this.routing.navigateApp(link); });
    };
    TabsLayoutComponent.prototype.updateAllElementStatesOnStable = function () {
        var _this = this;
        this.element.items.forEach(function (_, i) { return _this.updateElementStatesOnStable(i); });
    };
    TabsLayoutComponent.prototype.updateAllElementStates = function () {
        var _this = this;
        this.element.items.forEach(function (_, i) { return _this.updateElementStates(i); });
    };
    TabsLayoutComponent.prototype.updateElementStatesOnStable = function (tabIndex) {
        var container = this.elementGroupsContainer.toArray()[tabIndex];
        if (container) {
            container.updateElementStatesOnStable();
        }
    };
    TabsLayoutComponent.prototype.updateElementStates = function (tabIndex) {
        var container = this.elementGroupsContainer.toArray()[tabIndex];
        if (container) {
            container.updateElementStates();
        }
    };
    Object.defineProperty(TabsLayoutComponent.prototype, "elementParam", {
        get: function () {
            return "_tab_" + this.element.uid.substring(0, 4);
        },
        enumerable: true,
        configurable: true
    });
    TabsLayoutComponent.prototype.setActiveItemUid = function (uid, navigate) {
        if (navigate === void 0) { navigate = true; }
        var _a;
        if (this.activeItemUid === uid) {
            return;
        }
        this.activeItemUid = uid;
        if (this.activeItem && !this.itemsOpened[this.activeItem.uid]) {
            this.itemsOpened[this.activeItem.uid] = true;
        }
        this.cd.markForCheck();
        if (navigate) {
            this.routingService.navigateCurrent({
                queryParams: (_a = {}, _a[this.elementParam] = uid, _a),
                queryParamsHandling: 'merge'
            });
        }
    };
    TabsLayoutComponent.prototype.deleteTab = function (tabIndex) {
        this.element.items.splice(tabIndex, 1);
        var newActiveItem = this.getVisibleTabs(!!this.customizeService.enabled).splice(0, tabIndex).reverse()[0];
        this.setActiveItemUid(newActiveItem ? newActiveItem.uid : undefined);
        this.customizeService.markChanged();
    };
    TabsLayoutComponent.prototype.addTab = function () {
        var tab = new TabsLayoutItem();
        tab.titleInput = new FieldInput().deserializeFromStatic('value', 'New Tab');
        tab.generateUid();
        this.element.items.push(tab);
        this.setActiveItemUid(tab.uid);
        this.cd.markForCheck();
        this.customizeService.markChanged();
        this.initVisibleObserver();
    };
    return TabsLayoutComponent;
}(BaseElementComponent));
export { TabsLayoutComponent };
registerElementComponent({
    type: ElementType.Tabs,
    component: TabsLayoutComponent,
    label: 'Tabs',
    alwaysActive: false,
    actions: [{ name: 'add', label: 'Add Tab' }]
});
