import { HttpClient, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import extend from 'lodash/extend';
import fromPairs from 'lodash/fromPairs';
import isArray from 'lodash/isArray';
import isPlainObject from 'lodash/isPlainObject';
import { Observable, of, throwError } from 'rxjs';
import { filter, map, publishLast, refCount, switchMap } from 'rxjs/operators';

import { ActionDescription, ActionResponse, ActionType } from '@modules/actions';
import { ServerRequestError } from '@modules/api';
import { AggregateFunc, DataGroup, DatasetGroupLookup } from '@modules/charts';
import { RawListViewSettingsColumn } from '@modules/customize';
import { getFieldDescriptionByType, InputValueType, ParameterField } from '@modules/fields';
import { Model, modelDbFieldToParameterField, ModelDescription, ModelFieldType, PER_PAGE_PARAM } from '@modules/models';
import { ProjectApiService } from '@modules/project-api';
import { Resource, ResourceName, ResourceTypeItem } from '@modules/projects';
import {
  ActionQuery,
  HttpQuery,
  HttpQueryService,
  HttpResponseType,
  ListModelDescriptionQuery,
  ModelDescriptionQuery,
  QueryPagination,
  QueryService,
  QueryType,
  StorageQuery
} from '@modules/queries';
import { QueryTokensService } from '@modules/queries-tokens';
import { Storage, StorageObject, StorageObjectsResponse } from '@modules/storages';
import { defaultComparator, getHttpHeadersAsArray, getHttpHeadersAsObject, isSet, objectsSortPredicate } from '@shared';

// TODO: Refactor import
import { modelFieldToRawListViewSettingsColumn } from '../../../customize/utils/common';

import {
  FIREBASE_CREATED_TIME,
  FIREBASE_ITEM_PRIMARY_KEY,
  FIREBASE_PARENT,
  FIREBASE_PRIMARY_KEY,
  FIREBASE_UPDATED_TIME,
  FirebaseDatabaseOption,
  FirebaseDatabaseType
} from '../../data/firebase';
import { ModelResponse } from '../../data/model-response';
import { ActionExecuteParams, GetObjectUrlResponse, ResourceController } from '../../data/resource-controller';
import { RestAPIResourceParams } from '../../data/rest-api-resource-params';
import { aggregateGetResponse } from '../../utils/aggregate';
import { isResourceQueryCustom } from '../../utils/common';
import { applyFrontendFiltering, applyFrontendPagination, applyFrontendSorting } from '../../utils/filters';
import { groupGetResponse } from '../../utils/group';

@Injectable()
export class RestApiResourceControllerService extends ResourceController {
  protected http: HttpClient;
  protected queryService: QueryService;
  protected queryTokensService: QueryTokensService;
  protected httpQueryService: HttpQueryService;
  protected apiService: ProjectApiService;

  init() {
    this.http = this.initService<HttpClient>(HttpClient);
    this.queryService = this.initService<QueryService>(QueryService);
    this.queryTokensService = this.initService<QueryTokensService>(QueryTokensService);
    this.httpQueryService = this.initService<HttpQueryService>(HttpQueryService);
    this.apiService = this.initService<ProjectApiService>(ProjectApiService);
  }

  supportedQueryTypes(resource: ResourceTypeItem, queryClass: any): QueryType[] {
    return [QueryType.Http];
  }

  checkResource(typeItem: ResourceTypeItem, params: Object): Observable<boolean> {
    // if (typeItem.name == 'stripe') {
    //   const restApiParams = new RestAPIResourceParams().deserialize(params);
    //   const query = restApiParams.modelDescriptions[0].getQuery;
    //   const tokens = this.queryTokensService.mergeTokens(
    //     this.queryTokensService.generalTokens()
    //   );
    //
    //   return this.httpQueryService.request<any>(undefined, query, undefined, tokens).pipe(
    //     catchError(error => {
    //       return throwError(new ServerRequestError({
    //         non_field_errors: undefined,
    //         secret_key: ['Failed to get data from Stripe API. Please check if Secret Token is correct']
    //       }));
    //     }),
    //     publishLast(),
    //     refCount()
    //   );
    // } else {
    return of(true);
    // }
  }

  getStorageObjects(
    resource: Resource,
    storage: Storage,
    query: StorageQuery,
    path: string
  ): Observable<StorageObjectsResponse> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const resourceParams = resource.parseParams<RestAPIResourceParams>(RestAPIResourceParams);
        if (!query || !query.httpQuery) {
          return of(undefined);
        }

        const tokens = this.queryTokensService.mergeTokens(
          this.queryTokensService.generalTokens(),
          this.queryTokensService.storageGetObjectsTokens(path)
        );

        return this.httpQueryService.requestBody<Object[]>(query.httpQuery, {
          resource: resource.uniqueName,
          baseQuery: resourceParams.baseHttpQuery,
          tokens: tokens,
          customProxy: resourceParams ? resourceParams.customProxy : undefined
        });
      }),
      map(result => {
        const response = new StorageObjectsResponse();
        response.objects = result.map(item => new StorageObject().deserialize(item));
        return response;
      }),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  deleteStorageObject(resource: Resource, storage: Storage, query: StorageQuery, path: string): Observable<Object> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const resourceParams = resource.parseParams<RestAPIResourceParams>(RestAPIResourceParams);
        if (!query || !query.httpQuery) {
          return of(undefined);
        }

        const tokens = this.queryTokensService.mergeTokens(
          this.queryTokensService.generalTokens(),
          this.queryTokensService.storageDeleteObjectTokens(path)
        );

        return this.httpQueryService.requestBody<Object>(query.httpQuery, {
          resource: resource.uniqueName,
          baseQuery: resourceParams.baseHttpQuery,
          tokens: tokens,
          customProxy: resourceParams ? resourceParams.customProxy : undefined
        });
      }),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  getObjectUrl(
    resource: Resource,
    storage: Storage,
    query: StorageQuery,
    path: string,
    expiresInSec?: number
  ): Observable<GetObjectUrlResponse> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const resourceParams = resource.parseParams<RestAPIResourceParams>(RestAPIResourceParams);
        if (!query || !query.httpQuery) {
          return of(undefined);
        }

        const tokens = this.queryTokensService.mergeTokens(
          this.queryTokensService.generalTokens(),
          this.queryTokensService.storageGetObjectUrlTokens(path, expiresInSec)
        );

        return this.httpQueryService.requestBody(query.httpQuery, {
          resource: resource.uniqueName,
          baseQuery: resourceParams.baseHttpQuery,
          tokens: tokens,
          customProxy: resourceParams ? resourceParams.customProxy : undefined
        });
      }),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  createStorageFolder(resource: Resource, storage: Storage, query: StorageQuery, path: string): Observable<Object> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const resourceParams = resource.parseParams<RestAPIResourceParams>(RestAPIResourceParams);
        if (!query || !query.httpQuery) {
          return of(undefined);
        }

        const tokens = this.queryTokensService.mergeTokens(
          this.queryTokensService.generalTokens(),
          this.queryTokensService.storageCreateFolderTokens(path)
        );

        return this.httpQueryService.requestBody<Object>(query.httpQuery, {
          resource: resource.uniqueName,
          baseQuery: resourceParams.baseHttpQuery,
          tokens: tokens,
          customProxy: resourceParams ? resourceParams.customProxy : undefined
        });
      }),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  // TODO: Remove deprecated model descriptions
  modelDescriptionGet(resource: Resource, draft = false): Observable<ModelDescription[]> {
    if (!resource.params['model_descriptions']) {
      return of([]);
    }

    const result = resource.params['model_descriptions']
      .map(item =>
        new ModelDescription().deserialize({
          ...item['model_description'],
          get_query: item['get_query']
            ? {
                query_type: QueryType.Http,
                http_query: item['get_query'],
                pagination: item['get_query']['pagination'],
                pagination_has_more_function: item['get_query']['pagination_has_more_function'],
                pagination_total_function: item['get_query']['pagination_total_function'],
                pagination_cursor_field: item['get_query']['pagination_cursor_field']
              }
            : undefined,
          get_parameters: item['model_description']['fields']
            .filter(field => field['filterable'])
            .map(field => {
              return {
                name: field['name']
              };
            }),
          get_inputs: item['model_description']['fields']
            .filter(field => field['filterable'])
            .map(field => {
              return {
                name: field['name'],
                value_type: InputValueType.Filter,
                filter_field: field['name']
              };
            }),
          get_detail_query: item['get_detail_query']
            ? {
                query_type: QueryType.Http,
                http_query: item['get_detail_query']
              }
            : undefined,
          create_query: item['create_query']
            ? {
                query_type: QueryType.Http,
                http_query: item['create_query']
              }
            : undefined,
          update_query: item['update_query']
            ? {
                query_type: QueryType.Http,
                http_query: item['update_query']
              }
            : undefined,
          delete_query: item['delete_query']
            ? {
                query_type: QueryType.Http,
                http_query: item['delete_query']
              }
            : undefined
        })
      )
      .map(item => {
        item.resource = resource.uniqueName;
        return item;
      });

    return of(result);
  }

  getDefaultCreateParameters(resource: Resource, modelDescription: ModelDescription): ParameterField[] {
    const database = resource.params['database_option'] as FirebaseDatabaseOption;
    const isFirebaseRealtime = database && database.type == FirebaseDatabaseType.Realtime;

    if (resource.typeItem.name == ResourceName.Firebase && !isFirebaseRealtime) {
      const fields = modelDescription.dbDefaultFields || modelDescription.dbFields;
      const parentParameter: ParameterField = fields
        .filter(item => item.name == FIREBASE_PARENT)
        .map(item => {
          const parameter = modelDbFieldToParameterField(item);
          parameter.required = true;
          return parameter;
        })[0];

      return [
        ...(isSet(parentParameter) ? [parentParameter] : []),
        ...fields
          .filter(
            item =>
              ![
                FIREBASE_PARENT,
                FIREBASE_PRIMARY_KEY,
                FIREBASE_ITEM_PRIMARY_KEY,
                FIREBASE_CREATED_TIME,
                FIREBASE_UPDATED_TIME
              ].includes(item.name)
          )
          .filter(item => item.editable)
          .map(item => modelDbFieldToParameterField(item))
          .sort(
            objectsSortPredicate(
              (lhs, rhs) =>
                defaultComparator(
                  lhs.name == modelDescription.primaryKeyField,
                  rhs.name == modelDescription.primaryKeyField
                ) * -1,
              '-required'
            )
          )
      ];
    } else {
      return super.getDefaultCreateParameters(resource, modelDescription);
    }
  }

  getUpdateParametersOrDefaults(resource: Resource, modelDescription: ModelDescription): ParameterField[] {
    const database = resource.params['database_option'] as FirebaseDatabaseOption;
    const isFirebaseRealtime = database && database.type == FirebaseDatabaseType.Realtime;

    if (resource.typeItem.name == ResourceName.Firebase && !isFirebaseRealtime) {
      const fields = modelDescription.dbDefaultFields || modelDescription.dbFields;
      const parentParameter: ParameterField = fields
        .filter(item => item.name == FIREBASE_PARENT)
        .map(item => {
          const parameter = modelDbFieldToParameterField(item);
          parameter.required = true;
          return parameter;
        })[0];
      const documentIdRequiredParameter: ParameterField = modelDescription.dbFields
        .filter(item => item.name == FIREBASE_ITEM_PRIMARY_KEY)
        .map(item => {
          const parameter = modelDbFieldToParameterField(item);
          parameter.required = true;
          return parameter;
        })[0];

      return [
        ...(isSet(parentParameter) ? [parentParameter] : []),
        documentIdRequiredParameter,
        ...fields
          .filter(
            item =>
              ![
                FIREBASE_PARENT,
                FIREBASE_PRIMARY_KEY,
                FIREBASE_ITEM_PRIMARY_KEY,
                FIREBASE_CREATED_TIME,
                FIREBASE_UPDATED_TIME
              ].includes(item.name)
          )
          .filter(item => item.editable)
          .map(item => modelDbFieldToParameterField(item))
          .sort(objectsSortPredicate('-required'))
      ];
    } else {
      return super.getUpdateParametersOrDefaults(resource, modelDescription);
    }
  }

  getDefaultDeleteParameters(resource: Resource, modelDescription: ModelDescription): ParameterField[] {
    const database = resource.params['database_option'] as FirebaseDatabaseOption;
    const isFirebaseRealtime = database && database.type == FirebaseDatabaseType.Realtime;

    if (resource.typeItem.name == ResourceName.Firebase && !isFirebaseRealtime) {
      const fields = modelDescription.dbDefaultFields || modelDescription.dbFields;
      const parentParameter: ParameterField = fields
        .filter(item => item.name == FIREBASE_PARENT)
        .map(item => {
          const parameter = modelDbFieldToParameterField(item);
          parameter.required = true;
          return parameter;
        })[0];
      const documentIdRequiredParameter: ParameterField = modelDescription.dbFields
        .filter(item => item.name == FIREBASE_ITEM_PRIMARY_KEY)
        .map(item => {
          const parameter = modelDbFieldToParameterField(item);
          parameter.required = true;
          return parameter;
        })[0];

      return [...(isSet(parentParameter) ? [parentParameter] : []), documentIdRequiredParameter];
    } else {
      return super.getDefaultDeleteParameters(resource, modelDescription);
    }
  }

  getHttp(
    resource: Resource,
    query: ListModelDescriptionQuery,
    parameters: ParameterField[] = [],
    params?: {},
    body?: {},
    columns: RawListViewSettingsColumn[] = [],
    paginate = true
  ): Observable<ModelResponse.GetResponse> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const resourceParams = resource.parseParams<RestAPIResourceParams>(RestAPIResourceParams);
        if (!query.httpQuery) {
          return of(undefined);
        }

        const userQuery = isResourceQueryCustom(resource, query.queryType);
        const tokens = this.queryTokensService.mergeTokens(
          this.queryTokensService.generalTokens(),
          this.queryTokensService.modelGetTokens(params, parameters, userQuery),
          this.queryTokensService.paginationTokens(query.pagination, params)
        );

        return this.httpQueryService
          .request<any>(query.httpQuery, {
            resource: resource.uniqueName,
            baseQuery: resourceParams.baseHttpQuery,
            tokens: tokens,
            customProxy: resourceParams ? resourceParams.customProxy : undefined
          })
          .pipe(
            map(result => {
              let resultBody = result.body;

              const tokensAfterRequest = {
                ...tokens,
                headers: getHttpHeadersAsObject(result.headers),
                headers_array: getHttpHeadersAsArray(result.headers)
              };

              resultBody = this.queryService.applyTransformer(
                resultBody,
                query.httpQuery.responseTransformer,
                query.httpQuery.url,
                false,
                tokensAfterRequest
              ) as Object[];
              resultBody = this.queryService.getPath(resultBody, query.httpQuery.responsePath);

              if (!isArray(resultBody)) {
                resultBody = [resultBody];
              }

              // TODO: Move filtering after deserialization
              if (query.frontendFiltering && resultBody && isArray(resultBody)) {
                resultBody = applyFrontendFiltering(resultBody, params, columns);
              }

              const data = {
                results: resultBody
              };
              const response = this.createGetResponse().deserialize(data, undefined, undefined);
              const tokensWithResponse = {
                ...tokensAfterRequest,
                results: resultBody
              };

              response.results.forEach(item => {
                item.deserializeAttributes(columns);
              });

              if (query.frontendFiltering) {
                applyFrontendSorting(response, params);
                applyFrontendPagination(response, params, paginate);
              } else if (
                query.pagination == QueryPagination.Page ||
                query.pagination == QueryPagination.Offset ||
                query.pagination == QueryPagination.Cursor
              ) {
                if (query.paginationHasMoreFunction) {
                  response.hasMore = this.queryService.applyTransformer(
                    result.body,
                    query.paginationHasMoreFunction,
                    query.httpQuery.url,
                    false,
                    tokensWithResponse
                  );
                } else {
                  if (query.paginationHasMorePagesPath.length) {
                    response.hasMore = this.queryService.getPath(
                      result.body,
                      query.paginationHasMorePagesPath.join('.')
                    );
                  } else if (query.paginationHasMoreTotalPagesPath.length) {
                    const totalPages = this.queryService.getPath(
                      result.body,
                      query.paginationHasMoreTotalPagesPath.join('.')
                    );
                    response.hasMore = tokens['paging']['page'] < totalPages;
                  } else if (query.paginationHasMoreTotalRecordsPath.length) {
                    const totalRecords = this.queryService.getPath(
                      result.body,
                      query.paginationHasMoreTotalRecordsPath.join('.')
                    );
                    const limit = query.paginationPerPage;
                    let totalPages: number;

                    if (limit && totalRecords) {
                      totalPages = Math.ceil(totalRecords / limit);
                      response.hasMore = tokens['paging']['page'] < totalPages;
                    }
                  }
                }

                if (query.paginationTotalFunction) {
                  response.count = this.queryService.applyTransformer(
                    result.body,
                    query.paginationTotalFunction,
                    query.httpQuery.url,
                    false,
                    tokensWithResponse
                  );
                } else if (query.paginationTotalPath && query.paginationTotalPath.length) {
                  response.count = this.queryService.getPath(result.body, query.paginationTotalPath.join('.'));
                }

                if (query.pagination == QueryPagination.Cursor) {
                  if (query.paginationCursorPrevFunction) {
                    response.cursorPrev = this.queryService.applyTransformer(
                      result.body,
                      query.paginationCursorPrevFunction,
                      query.httpQuery.url,
                      false,
                      tokensWithResponse
                    );
                  } else if (query.paginationCursorPrevPath) {
                    response.cursorPrev = this.queryService.getPath(
                      result.body,
                      query.paginationCursorPrevPath.join('.')
                    );
                  }

                  if (query.paginationCursorNextFunction) {
                    response.cursorNext = this.queryService.applyTransformer(
                      result.body,
                      query.paginationCursorNextFunction,
                      query.httpQuery.url,
                      false,
                      tokensWithResponse
                    );
                  } else if (query.paginationCursorNextPath) {
                    response.cursorNext = this.queryService.getPath(
                      result.body,
                      query.paginationCursorNextPath.join('.')
                    );
                  }

                  response.hasMore = !!response.cursorNext;
                }
              }

              return response;
            })
          );
      }),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  modelGet(
    resource: Resource,
    modelDescription: ModelDescription,
    params?: {},
    body?: {}
  ): Observable<ModelResponse.GetResponse> {
    const query = modelDescription.getQuery;

    if (!query) {
      return throwError(
        new ServerRequestError(`No get query specified for collection ${modelDescription.verboseNamePlural}`)
      );
    }

    return this.getHttp(resource, query, modelDescription.getParameters, params, body, modelDescription.dbFields).pipe(
      map(response => {
        if (!response) {
          return;
        }

        response.results.forEach(item => {
          item.setUp(modelDescription);
        });

        return response;
      })
    );
  }

  getDetail(
    resource: Resource,
    query: ModelDescriptionQuery,
    parameters: ParameterField[] = [],
    params?: {},
    columns: RawListViewSettingsColumn[] = []
  ): Observable<Model> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const resourceParams = resource.parseParams<RestAPIResourceParams>(RestAPIResourceParams);

        const userQuery = isResourceQueryCustom(resource, query.queryType);
        const tokens = this.queryTokensService.mergeTokens(
          this.queryTokensService.generalTokens(),
          this.queryTokensService.modelGetDetailTokens(params, parameters, userQuery)
        );

        return this.httpQueryService.requestBody<Object>(query.httpQuery, {
          resource: resource.uniqueName,
          baseQuery: resourceParams.baseHttpQuery,
          tokens: tokens,
          customProxy: resourceParams ? resourceParams.customProxy : undefined
        });
      }),
      map(result => {
        if (!result) {
          return result;
        }

        const object = isArray(result) ? result[0] : result;
        const model = this.createModel().deserialize(undefined, object);
        model.deserializeAttributes(columns);
        return model;
      }),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  modelGetDetail(
    resource: Resource,
    modelDescription: ModelDescription,
    idField: string,
    id: number,
    params?: {}
  ): Observable<Model> {
    if (modelDescription.getDetailQuery) {
      params = {
        ...params,
        PK: id // TODO: Remove deprecated tokens
      };

      if (isSet(modelDescription.primaryKeyField)) {
        params[idField] = id;
      }

      const detailQueryParameters = this.getDetailParametersOrDefaults(resource, modelDescription);

      return this.getDetail(
        resource,
        modelDescription.getDetailQuery,
        detailQueryParameters,
        params,
        modelDescription.dbFields
      ).pipe(
        map(result => {
          if (!result) {
            return result;
          }

          result.setUp(modelDescription);
          return result;
        })
      );
    } else if (modelDescription.getQuery) {
      params = {
        ...params,
        PK: id // TODO: Remove deprecated tokens
      };

      if (isSet(modelDescription.primaryKeyField)) {
        params[idField] = id;
      }

      return this.getHttp(
        resource,
        modelDescription.getQuery,
        modelDescription.getParameters,
        params,
        undefined,
        modelDescription.dbFields
      ).pipe(
        map(result => {
          if (!result || !result.results.length) {
            return;
          }

          const model = result.results[0];
          model.setUp(modelDescription);
          return model;
        })
      );
    }
  }

  serializeModelField(modelInstance: Model, name: string) {
    if (!isSet(name) || !modelInstance.modelDescription) {
      return;
    }

    const field = modelInstance.modelDescription.dbFields.find(item => item.name == name);

    if (!field) {
      return;
    }

    let value = modelInstance.getAttribute(name);
    const fieldDescription = getFieldDescriptionByType(field.field);

    if (fieldDescription.serializeValue) {
      value = fieldDescription.serializeValue(value, field);
    }

    return value;
  }

  serializeModelParameters(modelInstance: Model, parameters: ParameterField[]) {
    return fromPairs(
      parameters.map(parameter => {
        let value = modelInstance.getAttribute(parameter.name);
        const fieldDescription = getFieldDescriptionByType(parameter.field);

        if (fieldDescription.serializeValue) {
          value = fieldDescription.serializeValue(value, parameter);
        }

        return [parameter.name, value];
      })
    );
  }

  modelCreate(
    resource: Resource,
    modelDescription: ModelDescription,
    modelInstance: Model,
    fields?: string[]
  ): Observable<Model> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const resourceParams = resource.parseParams<RestAPIResourceParams>(RestAPIResourceParams);
        const query = cloneDeep(modelDescription.createQuery.httpQuery);

        if (!query) {
          throw new ServerRequestError(
            `No create query specified for collection ${modelDescription.verboseNamePlural}`
          );
        }

        const modelParameters = this.getCreateParametersOrDefaults(resource, modelDescription);
        const data = this.serializeModelParameters(modelInstance, modelParameters);

        // TODO: Legacy default body
        if (!query.body) {
          query.body = data;
        }

        const tokens = this.queryTokensService.mergeTokens(
          this.queryTokensService.generalTokens(),
          this.queryTokensService.modelCreateTokens(data, modelParameters)
        );

        return this.httpQueryService.requestBody<Object>(query, {
          resource: resource.uniqueName,
          baseQuery: resourceParams.baseHttpQuery,
          tokens: tokens,
          customProxy: resourceParams ? resourceParams.customProxy : undefined
        });
      }),
      map(result => {
        const instanceData = extend(modelInstance.serialize(), result);
        const instance = this.createModel().deserialize(modelDescription.model, instanceData);
        instance.setUp(modelDescription);
        instance.deserializeAttributes(modelDescription.dbFields);
        return instance;
      }),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  modelUpdate(
    resource: Resource,
    modelDescription: ModelDescription,
    modelInstance: Model,
    fields?: string[]
  ): Observable<Model> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const resourceParams = resource.parseParams<RestAPIResourceParams>(RestAPIResourceParams);
        const query = cloneDeep(modelDescription.updateQuery.httpQuery);

        if (!query) {
          throw new ServerRequestError(
            `No update query specified for collection ${modelDescription.verboseNamePlural}`
          );
        }

        const modelParameters = this.getUpdateParametersOrDefaults(resource, modelDescription);
        const primaryKeyValue = isSet(modelDescription.primaryKeyField)
          ? this.serializeModelField(modelInstance, modelDescription.primaryKeyField)
          : undefined;
        const data = {
          ...this.serializeModelParameters(modelInstance, modelParameters),
          // TODO: Remove deprecated tokens
          ...(isSet(primaryKeyValue) ? { [modelDescription.primaryKeyField]: primaryKeyValue } : undefined)
        };

        // TODO: Legacy default body
        if (!query.body) {
          query.body = data;
        }

        const tokens = this.queryTokensService.mergeTokens(
          this.queryTokensService.generalTokens(),
          this.queryTokensService.modelUpdateTokens(data, modelParameters, primaryKeyValue, fields)
        );

        return this.httpQueryService.requestBody<Object>(query, {
          resource: resource.uniqueName,
          baseQuery: resourceParams.baseHttpQuery,
          tokens: tokens,
          customProxy: resourceParams ? resourceParams.customProxy : undefined
        });
      }),
      map(result => {
        const instanceData = extend(modelInstance.serialize(), result);
        const instance = this.createModel().deserialize(modelDescription.model, instanceData);
        instance.setUp(modelDescription);
        instance.deserializeAttributes(modelDescription.dbFields);
        return instance;
      }),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  modelDelete(resource: Resource, modelDescription: ModelDescription, modelInstance: Model): Observable<Object> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const resourceParams = resource.parseParams<RestAPIResourceParams>(RestAPIResourceParams);
        const query = modelDescription.deleteQuery.httpQuery;

        if (!query) {
          throw new ServerRequestError(
            `No delete query specified for collection ${modelDescription.verboseNamePlural}`
          );
        }

        const modelParameters = this.getDeleteParametersOrDefaults(resource, modelDescription);
        const primaryKeyValue = isSet(modelDescription.primaryKeyField)
          ? this.serializeModelField(modelInstance, modelDescription.primaryKeyField)
          : undefined;
        const data = {
          ...this.serializeModelParameters(modelInstance, modelParameters),
          // TODO: Remove deprecated tokens
          ...(isSet(primaryKeyValue) ? { [modelDescription.primaryKeyField]: primaryKeyValue } : undefined)
        };

        const tokens = this.queryTokensService.mergeTokens(
          this.queryTokensService.generalTokens(),
          this.queryTokensService.modelDeleteTokens(data, primaryKeyValue)
        );

        return this.httpQueryService.requestBody<Object>(query, {
          resource: resource.uniqueName,
          baseQuery: resourceParams.baseHttpQuery,
          tokens: tokens,
          customProxy: resourceParams ? resourceParams.customProxy : undefined
        });
      }),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  query(resource: Resource, query: HttpQuery, params?: {}): Observable<any> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const resourceParams = resource.parseParams<RestAPIResourceParams>(RestAPIResourceParams);

        if (!query) {
          return of(undefined);
        }

        const tokens = this.queryTokensService.mergeTokens(this.queryTokensService.generalTokens());

        return this.httpQueryService
          .request<any>(query, {
            resource: resource.uniqueName,
            baseQuery: resourceParams.baseHttpQuery,
            tokens: tokens,
            customProxy: resourceParams ? resourceParams.customProxy : undefined
          })
          .pipe(
            map(response => {
              let resultBody = response.body;

              resultBody = this.queryService.applyTransformer(
                resultBody,
                query.responseTransformer,
                query.url,
                false,
                tokens
              ) as Object[];
              resultBody = this.queryService.getPath(resultBody, query.responsePath);

              return resultBody;
            })
          );
      }),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  aggregate(
    resource: Resource,
    query: HttpQuery,
    yFunc: AggregateFunc,
    yColumn: string,
    parameters: ParameterField[] = [],
    params?: {}
  ): Observable<any> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const resourceParams = resource.parseParams<RestAPIResourceParams>(RestAPIResourceParams);

        if (!query) {
          return of(undefined);
        }

        const userQuery = isResourceQueryCustom(resource, QueryType.Http);
        const tokens = this.queryTokensService.mergeTokens(
          this.queryTokensService.generalTokens(),
          this.queryTokensService.modelGetTokens(params, parameters, userQuery),
          this.queryTokensService.modelAggregateTokens(yFunc, yColumn, parameters, params, userQuery),
          this.queryTokensService.paginationTokens(QueryPagination.Page, { [PER_PAGE_PARAM]: 1000 })
        );

        return this.httpQueryService
          .request<any>(query, {
            resource: resource.uniqueName,
            baseQuery: resourceParams.baseHttpQuery,
            tokens: tokens,
            customProxy: resourceParams ? resourceParams.customProxy : undefined
          })
          .pipe(
            map(response => {
              let resultBody = response.body;

              resultBody = this.queryService.applyTransformer(
                resultBody,
                query.responseTransformer,
                query.url,
                false,
                tokens
              );
              resultBody = this.queryService.getPath(resultBody, query.responsePath);

              return resultBody as any;
            })
          );
      }),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  getHttpAggregate(
    resource: Resource,
    query: ListModelDescriptionQuery,
    yFunc: AggregateFunc,
    yColumn: string,
    parameters: ParameterField[] = [],
    params?: {},
    columns: RawListViewSettingsColumn[] = []
  ): Observable<any> {
    return this.getHttp(resource, query, parameters, params, undefined, columns, false).pipe(
      map(response => aggregateGetResponse(response, yFunc, yColumn))
    );
  }

  modelAggregate(
    resource: Resource,
    modelDescription: ModelDescription,
    yFunc: AggregateFunc,
    yColumn: string,
    params?: {}
  ): Observable<any> {
    if (modelDescription.aggregateQuery && modelDescription.aggregateQuery.httpQuery) {
      const query = modelDescription.aggregateQuery.httpQuery;
      return this.aggregate(resource, query, yFunc, yColumn, modelDescription.aggregateParameters, params);
    } else if (modelDescription.getQuery && modelDescription.getQuery.httpQuery) {
      const columns = modelDescription.fields
        .filter(item => item.type == ModelFieldType.Db)
        .map(item => modelFieldToRawListViewSettingsColumn(item));

      return this.getHttpAggregate(
        resource,
        modelDescription.getQuery,
        yFunc,
        yColumn,
        modelDescription.getParameters,
        params,
        columns
      );
    } else {
      return of(undefined);
    }
  }

  group(
    resource: Resource,
    query: HttpQuery,
    xColumns: { xColumn: string; xLookup?: DatasetGroupLookup }[],
    yFunc: AggregateFunc,
    yColumn: string,
    parameters: ParameterField[] = [],
    params?: {}
  ): Observable<DataGroup[]> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const resourceParams = resource.parseParams<RestAPIResourceParams>(RestAPIResourceParams);

        if (!query) {
          return of(undefined);
        }

        const userQuery = isResourceQueryCustom(resource, QueryType.Http);
        const tokens = this.queryTokensService.mergeTokens(
          this.queryTokensService.generalTokens(),
          this.queryTokensService.modelGetTokens(params, parameters, userQuery),
          this.queryTokensService.modelGroupTokens(xColumns, yFunc, yColumn, parameters, params, userQuery),
          this.queryTokensService.paginationTokens(QueryPagination.Page, { [PER_PAGE_PARAM]: 1000 })
        );

        return this.httpQueryService
          .request<any>(query, {
            resource: resource.uniqueName,
            baseQuery: resourceParams.baseHttpQuery,
            tokens: tokens,
            customProxy: resourceParams ? resourceParams.customProxy : undefined
          })
          .pipe(
            map(response => {
              let resultBody = response.body;

              resultBody = this.queryService.applyTransformer(
                resultBody,
                query.responseTransformer,
                query.url,
                false,
                tokens
              ) as any;
              resultBody = this.queryService.getPath(resultBody, query.responsePath);

              return resultBody as Object[];
            }),
            map(response => {
              if (!response.length) {
                return [];
              }

              return response.map(item => {
                const result = new DataGroup();

                if (xColumns[0]) {
                  result.group = item[xColumns[0].xColumn];
                }

                if (xColumns[1]) {
                  result.group = item[xColumns[1].xLookup];
                }

                result.value = item[yColumn];
                return result;
              });
            })
          );
      }),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  getGroup(
    resource: Resource,
    query: ListModelDescriptionQuery,
    xColumns: { xColumn: string; xLookup?: DatasetGroupLookup }[],
    yFunc: AggregateFunc,
    yColumn: string,
    parameters: ParameterField[] = [],
    params?: {},
    columns: RawListViewSettingsColumn[] = []
  ): Observable<DataGroup[]> {
    return this.getHttp(resource, query, parameters, params, undefined, columns, false).pipe(
      map(response => groupGetResponse(response, xColumns, yFunc, yColumn))
    );
  }

  modelGroup(
    resource: Resource,
    modelDescription: ModelDescription,
    xColumns: { xColumn: string; xLookup?: DatasetGroupLookup }[],
    yFunc: AggregateFunc,
    yColumn: string,
    params?: {}
  ): Observable<any> {
    if (modelDescription.groupQuery && modelDescription.groupQuery.httpQuery) {
      const query = modelDescription.groupQuery.httpQuery;
      return this.group(resource, query, xColumns, yFunc, yColumn, modelDescription.groupParameters, params);
    } else if (modelDescription.getQuery && modelDescription.getQuery.httpQuery) {
      const columns = modelDescription.fields
        .filter(item => item.type == ModelFieldType.Db)
        .map(item => modelFieldToRawListViewSettingsColumn(item));

      return this.getGroup(
        resource,
        modelDescription.getQuery,
        xColumns,
        yFunc,
        yColumn,
        modelDescription.getParameters,
        params,
        columns
      );
    } else {
      return of(undefined);
    }
  }

  uploadFile(
    resource: Resource,
    storage: Storage,
    query: StorageQuery,
    file: File,
    path?: string,
    fileName?: string
  ): Observable<ModelResponse.UploadFileResponse> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const resourceParams = resource.parseParams<RestAPIResourceParams>(RestAPIResourceParams);

        if (!query || !query.httpQuery) {
          return of(undefined);
        }

        const tokens = this.queryTokensService.mergeTokens(
          this.queryTokensService.generalTokens(),
          this.queryTokensService.uploadFileTokens(file, path, fileName)
        );

        return this.httpQueryService
          .executeRequest<any>(query.httpQuery, {
            resource: resource.uniqueName,
            baseQuery: resourceParams.baseHttpQuery,
            tokens: tokens,
            customProxy: resourceParams ? resourceParams.customProxy : undefined
          })
          .pipe(
            map(event => {
              if (event.type == HttpEventType.Response) {
                let resultBody = event.body;

                resultBody = this.queryService.applyTransformer(
                  resultBody,
                  query.httpQuery.responseTransformer,
                  query.httpQuery.url,
                  false,
                  tokens
                ) as Object[];
                resultBody = this.queryService.getPath(resultBody, query.httpQuery.responsePath);

                return {
                  result: {
                    uploadedPath: resultBody,
                    uploadedUrl: resultBody,
                    response: event
                  },
                  state: {
                    downloadProgress: 1,
                    uploadProgress: 1
                  }
                };
              } else if (event.type == HttpEventType.UploadProgress) {
                return {
                  state: {
                    uploadProgress: event.loaded / event.total,
                    downloadProgress: 0,
                    uploadLoaded: event.loaded,
                    uploadTotal: event.total
                  }
                };
              } else if (event.type == HttpEventType.DownloadProgress) {
                return {
                  state: {
                    uploadProgress: 1,
                    downloadProgress: event.loaded / event.total,
                    downloadLoaded: event.loaded,
                    downloadTotal: event.total
                  }
                };
              }
            }),
            filter(item => item !== undefined)
          );
      }),
      this.apiService.catchApiError() as any
    );
  }

  // TODO: Remove deprecated action descriptions
  actionDescriptionGet(resource: Resource): Observable<ActionDescription[]> {
    if (!resource.params['action_descriptions']) {
      return of([]);
    }

    const result = resource.params['action_descriptions']
      .map(item => {
        const data = item['action_description'];

        data['params']['type'] = ActionType.Query;
        data['params']['query_action'] = item['query']
          ? {
              query: {
                query_type: QueryType.Http,
                http_query: item['query']
              }
            }
          : undefined;

        return new ActionDescription().deserialize(data);
      })
      .map(item => {
        item.resource = resource.uniqueName;
        return item;
      });

    return of(result);
  }

  actionExecute(
    resource: Resource,
    query: ActionQuery,
    parameters: ParameterField[] = [],
    params?: ActionExecuteParams,
    rawErrors?: boolean
  ): Observable<ActionResponse> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const resourceParams = resource.parseParams<RestAPIResourceParams>(RestAPIResourceParams);

        if (!query || !query.httpQuery) {
          return of(undefined);
        }

        const userQuery = isResourceQueryCustom(resource, query.queryType);
        const tokens = this.queryTokensService.mergeTokens(
          this.queryTokensService.generalTokens(),
          this.queryTokensService.actionExecuteTokens(params, parameters, userQuery)
        );

        return this.httpQueryService
          .request<Object>(query.httpQuery, {
            resource: resource.uniqueName,
            baseQuery: resourceParams.baseHttpQuery,
            tokens: tokens,
            customProxy: resourceParams ? resourceParams.customProxy : undefined
          })
          .pipe(
            map(result => {
              if (query.httpQuery.responseType == HttpResponseType.Blob) {
                return {
                  response: result,
                  blob: result.body
                };
              } else {
                let resultBody = result.body;

                resultBody = this.queryService.applyTransformer(
                  resultBody,
                  query.httpQuery.responseTransformer,
                  query.httpQuery.url,
                  false,
                  tokens
                ) as Object[];
                resultBody = this.queryService.getPath(resultBody, query.httpQuery.responsePath);

                if (isPlainObject(resultBody) || isArray(resultBody)) {
                  return {
                    response: result,
                    json: resultBody
                  };
                } else {
                  return {
                    response: result,
                    text: resultBody
                  };
                }
              }
            })
          );
      }),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }
}
