import { ChangeDetectionStrategy, Component, Injector, Input, OnDestroy, OnInit } from '@angular/core';

import { ActionControllerService } from '@modules/action-queries';
import { ActionItem } from '@modules/actions';
import { CustomView, CustomViewSource } from '@modules/custom-views';
import { ViewContext, ViewContextElement } from '@modules/customize';

@Component({
  selector: 'app-custom-element-auto',
  templateUrl: './custom-element-auto.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomElementAutoComponent implements OnInit, OnDestroy {
  @Input() source: CustomViewSource;
  @Input() customView: CustomView;
  @Input() actions: { name: string; action: ActionItem }[] = [];
  @Input() params: Object = {};
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() localContext: Object;
  @Input() stateSelectedEnabled = false;
  @Input() stateSelected = true;

  sources = CustomViewSource;

  constructor(private actionControllerService: ActionControllerService, private injector: Injector) {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  onActionEmitted(name: string, data: any) {
    const action = this.actions.find(item => item.name == name);
    if (!action) {
      return;
    }

    this.actionControllerService
      .execute(action.action, {
        context: this.context,
        contextElement: this.contextElement,
        localContext: {
          params: data
        },
        injector: this.injector
      })
      .subscribe();
  }
}
