var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import isArray from 'lodash/isArray';
import isPlainObject from 'lodash/isPlainObject';
import keys from 'lodash/keys';
import toPairs from 'lodash/toPairs';
import values from 'lodash/values';
import { aggregateObjects, interpolate, isSet } from '@shared';
import { defaultFieldType } from '../../../fields/data/field-type';
import { detectFieldByValue } from '../../../fields/utils/detect-field-by-value';
import { QueryType } from '../../data/query-type';
import * as i0 from "@angular/core";
export var NO_KEY_ATTRIBUTE = 'result';
var QueryService = /** @class */ (function () {
    function QueryService() {
    }
    QueryService.prototype.applyTokens = function (template, tokens, raiseErrors, disableInterpolate) {
        if (tokens === void 0) { tokens = {}; }
        if (raiseErrors === void 0) { raiseErrors = false; }
        if (disableInterpolate === void 0) { disableInterpolate = false; }
        if (typeof template == 'string') {
            var ctx = tokens ? tokens : {};
            try {
                return interpolate(template, ctx);
            }
            catch (e) {
                console.error(e);
                if (raiseErrors) {
                    throw e;
                }
                else {
                    return template;
                }
            }
        }
        else {
            return template;
        }
    };
    QueryService.prototype.applyTransformer = function (value, transformer, url, raiseErrors, tokens) {
        if (raiseErrors === void 0) { raiseErrors = false; }
        tokens = tokens || {};
        if (transformer) {
            try {
                var x = new (Function.bind.apply(Function, [void 0, 'data', 'url'].concat(keys(tokens), [transformer])))();
                return x.apply(void 0, [value, url].concat(values(tokens)));
            }
            catch (e) {
                console.error(e, { transformer: transformer, value: value, tokens: tokens });
                if (raiseErrors) {
                    throw e;
                }
                else {
                    return value;
                }
            }
        }
        else {
            return value;
        }
    };
    QueryService.prototype.getPath = function (value, pathStr) {
        if (!isSet(value) || !isSet(pathStr)) {
            return value;
        }
        var result = value;
        var path = pathStr.split('.').filter(function (item) { return isSet(item); });
        for (var _i = 0, path_1 = path; _i < path_1.length; _i++) {
            var item = path_1[_i];
            if (!isSet(result) || !result.hasOwnProperty(item)) {
                return;
            }
            result = result[item];
        }
        return result;
    };
    QueryService.prototype.getResponseColumns = function (query) {
        if (query.queryType == QueryType.SQL && query.sqlQuery) {
            return query.sqlQuery.requestResponseColumns;
        }
    };
    QueryService.prototype.columnDescriptionToFields = function (columnsDescription) {
        if (!columnsDescription) {
            return;
        }
        return toPairs(columnsDescription).map(function (_a) {
            var name = _a[0], description = _a[1];
            return {
                name: name,
                verboseName: name,
                field: description.field || defaultFieldType,
                params: {}
            };
        });
    };
    QueryService.prototype.getResponseProcessed = function (query) {
        if (query.queryType == QueryType.Http && query.httpQuery) {
            return this.getHttpResponseProcessed(query.httpQuery);
        }
        else if (query.queryType == QueryType.SQL && query.sqlQuery) {
            return query.sqlQuery.requestResponse;
        }
        else if (query.queryType == QueryType.Object && query.objectQuery) {
            return query.objectQuery.requestResponse;
        }
    };
    QueryService.prototype.getHttpResponseProcessed = function (query) {
        var response = query.requestResponse;
        response = this.applyTransformer(response, query.responseTransformer, query.url, false, __assign({}, query.requestTokens, { headers: query.getRequestResponseHeadersAsObject(), headers_array: query.requestResponseHeaders }));
        response = this.getPath(response, query.responsePath);
        return response;
    };
    QueryService.prototype.autoDetectObjectFields = function (obj) {
        if (!obj) {
            return;
        }
        return toPairs(obj).map(function (_a) {
            var k = _a[0], v = _a[1];
            var valueField = detectFieldByValue(v);
            return {
                name: k,
                verboseName: k,
                field: valueField.field,
                params: valueField.params || {}
            };
        });
    };
    QueryService.prototype.autoDetectGetFields = function (data) {
        if (data && isArray(data)) {
            if (!data.length) {
                return [];
            }
            if (!data.every(function (item) { return isPlainObject(item); })) {
                var firstData = data.find(function (item) { return !isPlainObject(item); });
                var valueField = detectFieldByValue(firstData);
                return [
                    {
                        name: NO_KEY_ATTRIBUTE,
                        verboseName: 'data',
                        field: valueField.field,
                        params: valueField.params || {}
                    }
                ];
            }
            var aggregatedData = aggregateObjects(data);
            return this.autoDetectObjectFields(aggregatedData);
        }
        else if (data && isPlainObject(data)) {
            return this.autoDetectObjectFields(data);
        }
        else if (data && !isPlainObject(data)) {
            var valueField = detectFieldByValue(data);
            return [
                {
                    name: NO_KEY_ATTRIBUTE,
                    verboseName: 'data',
                    field: valueField.field,
                    params: valueField.params || {}
                }
            ];
        }
    };
    QueryService.prototype.autoDetectGetDetailFields = function (data) {
        if (data && isArray(data)) {
            if (!data.length) {
                return [];
            }
            if (!data.every(function (item) { return isPlainObject(item); })) {
                var firstData = data.find(function (item) { return !isPlainObject(item); });
                var valueField = detectFieldByValue(firstData);
                return [
                    {
                        name: NO_KEY_ATTRIBUTE,
                        verboseName: 'data',
                        field: valueField.field,
                        params: valueField.params || {}
                    }
                ];
            }
            var aggregatedData = aggregateObjects(data);
            return this.autoDetectGetDetailFields(aggregatedData);
        }
        else if (data && isPlainObject(data)) {
            return this.autoDetectObjectFields(data);
        }
        else if (data && !isPlainObject(data)) {
            var valueField = detectFieldByValue(data);
            return [
                {
                    name: NO_KEY_ATTRIBUTE,
                    verboseName: 'data',
                    field: valueField.field,
                    params: valueField.params || {}
                }
            ];
        }
    };
    QueryService.prototype.autoDetectColumns = function (response, isDataArray) {
        var result = isDataArray ? this.autoDetectGetFields(response) : this.autoDetectGetDetailFields(response);
        if (!result) {
            return;
        }
        return result;
    };
    QueryService.prototype.getAutoDetectColumns = function (query, isDataArray) {
        var columnDescription = this.getResponseColumns(query);
        if (columnDescription) {
            return this.columnDescriptionToFields(columnDescription);
        }
        var response = this.getResponseProcessed(query);
        return this.autoDetectColumns(response, isDataArray);
    };
    QueryService.prototype.isQueryArrayResponse = function (query) {
        if (query.queryType == QueryType.Http && query.httpQuery) {
            return isArray(this.getHttpResponseProcessed(query.httpQuery));
        }
        else if (query.queryType == QueryType.SQL && query.sqlQuery) {
            return true;
        }
        else if (query.queryType == QueryType.Object && query.objectQuery) {
            return isArray(query.objectQuery.requestResponse);
        }
    };
    QueryService.ngInjectableDef = i0.defineInjectable({ factory: function QueryService_Factory() { return new QueryService(); }, token: QueryService, providedIn: "root" });
    return QueryService;
}());
export { QueryService };
