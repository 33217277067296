var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import toPairs from 'lodash/toPairs';
import moment from 'moment';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { getCurrentLanguage } from '@common/localize';
import { AdminMode } from '@modules/admin-mode';
import { AnalyticsEvent, AnalyticsEventAction } from '@modules/analytics';
import { HomeTriggerOutput } from '@modules/projects';
import { ROUTE_LOADED_PROJECTS_STORE } from '@modules/projects-components';
import { JetBridgeSSOSettings, SSOType } from '@modules/sso';
import { isAbsoluteUrl, isProductionLocation, isSet, openUrl, parseLocationFragment } from '@shared';
var LoginBaseComponent = /** @class */ (function () {
    function LoginBaseComponent(loginForm, routing, currentUserStore, activatedRoute, analyticsService, googleAnalyticsService, appConfigService, authService, notificationService, metaService, projectsStore, apiService, projectInviteService, projectPublicInviteService, cd) {
        this.loginForm = loginForm;
        this.routing = routing;
        this.currentUserStore = currentUserStore;
        this.activatedRoute = activatedRoute;
        this.analyticsService = analyticsService;
        this.googleAnalyticsService = googleAnalyticsService;
        this.appConfigService = appConfigService;
        this.authService = authService;
        this.notificationService = notificationService;
        this.metaService = metaService;
        this.projectsStore = projectsStore;
        this.apiService = apiService;
        this.projectInviteService = projectInviteService;
        this.projectPublicInviteService = projectPublicInviteService;
        this.cd = cd;
        this.submitLoading = false;
        this.screenLoader = false;
        this._domain = new BehaviorSubject(undefined);
        this.inviteLoading = false;
        this.publicInviteLoading = false;
        this.analyticsEvents = AnalyticsEvent;
    }
    LoginBaseComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.activatedRoute.fragment.pipe(untilDestroyed(this)).subscribe(function (fragment) {
            var params = parseLocationFragment(fragment);
            var token = params.find(function (item) { return item.key == 'token'; });
            var accessToken = params.find(function (item) { return item.key == 'access_token'; });
            var accessTokenExpires = params.find(function (item) { return item.key == 'access_token_expires'; });
            var refreshToken = params.find(function (item) { return item.key == 'refresh_token'; });
            var refreshTokenExpires = params.find(function (item) { return item.key == 'refresh_token_expires'; });
            var serverTime = params.find(function (item) { return item.key == 'server_time'; });
            var sso = params.find(function (item) { return item.key == 'sso'; });
            var incognito = params.find(function (item) { return item.key == 'incognito'; });
            var message = params.find(function (item) { return item.key == 'message'; });
            var created = params.find(function (item) { return item.key == 'created'; });
            var socialBackend = params.find(function (item) { return item.key == 'social_backend'; });
            if (token || message) {
                _this.googleAnalyticsService.setLocationReferrer();
            }
            if (token) {
                var tokenOptions = {
                    token: token.value
                };
                if (accessToken) {
                    tokenOptions.accessToken = accessToken.value;
                }
                if (accessTokenExpires) {
                    tokenOptions.accessTokenExpires = moment(accessTokenExpires.value);
                }
                if (refreshToken) {
                    tokenOptions.refreshToken = refreshToken.value;
                }
                if (refreshTokenExpires) {
                    tokenOptions.refreshTokenExpires = moment(refreshTokenExpires.value);
                }
                if (serverTime) {
                    tokenOptions.serverTime = moment(serverTime.value);
                }
                if (sso) {
                    tokenOptions.sso = sso.value;
                }
                if (socialBackend) {
                    tokenOptions.social = socialBackend.value;
                }
                if (incognito) {
                    tokenOptions.incognito = true;
                }
                _this.tokenLogin(tokenOptions, created && created.value == 'True', socialBackend && socialBackend.value ? socialBackend.value : undefined);
            }
            if (message) {
                _this.notificationService.error('Error', message.value);
            }
        });
        combineLatest(this.activatedRoute.data.pipe(map(function (data) { return data.domain; })), this.activatedRoute.queryParams.pipe(map(function (params) { return params['sso']; })))
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var domain = _a[0], ssoUid = _a[1];
            _this.domain = domain;
            _this.currentSSO = domain && isSet(ssoUid) ? domain.ssoSettings.find(function (item) { return item.uid == ssoUid; }) : undefined;
            _this.cd.markForCheck();
        });
        this.activatedRoute.queryParams
            .pipe(map(function (queryParams) {
            var redirectParams = queryParams['redirect']
                ? JSON.parse(decodeURIComponent(queryParams['redirect']))
                : undefined;
            if (!redirectParams ||
                !redirectParams['url'] ||
                !redirectParams['url'].join('/').startsWith('projects/invite')) {
                return;
            }
            return redirectParams['url'][2];
        }), switchMap(function (code) {
            if (!code) {
                return of(undefined);
            }
            _this.inviteLoading = true;
            _this.cd.markForCheck();
            return _this.projectInviteService.getByCode(code);
        }), untilDestroyed(this))
            .subscribe(function (invite) {
            _this.invite = invite;
            _this.inviteLoading = false;
            _this.cd.markForCheck();
            _this.onInviteLoaded();
        }, function () {
            _this.inviteLoading = false;
            _this.cd.markForCheck();
        });
        this.activatedRoute.queryParams
            .pipe(map(function (queryParams) {
            var redirectParams = queryParams['redirect']
                ? JSON.parse(decodeURIComponent(queryParams['redirect']))
                : undefined;
            if (!redirectParams ||
                !redirectParams['url'] ||
                !redirectParams['url'].join('/').startsWith('projects/join')) {
                return;
            }
            return redirectParams['url'][2];
        }), switchMap(function (code) {
            if (!code) {
                return of(undefined);
            }
            _this.publicInviteLoading = true;
            _this.cd.markForCheck();
            return _this.projectPublicInviteService.getByCode(code);
        }), untilDestroyed(this))
            .subscribe(function (invite) {
            _this.publicInvite = invite;
            _this.publicInviteLoading = false;
            _this.cd.markForCheck();
            _this.onInviteLoaded();
        }, function () {
            _this.publicInviteLoading = false;
            _this.cd.markForCheck();
        });
    };
    LoginBaseComponent.prototype.ngOnDestroy = function () { };
    Object.defineProperty(LoginBaseComponent.prototype, "domain", {
        get: function () {
            return this._domain.value;
        },
        set: function (value) {
            this._domain.next(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoginBaseComponent.prototype, "domain$", {
        get: function () {
            return this._domain.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    LoginBaseComponent.prototype.socialLogin = function (social) {
        this.submitLoading = true;
        this.cd.markForCheck();
        openUrl(this.socialLoginUrl(social));
    };
    LoginBaseComponent.prototype.ssoLogin = function (sso) {
        if (sso.type == SSOType.Custom) {
            this.routing.navigate([], { queryParams: __assign({}, (sso && { sso: sso.uid })), queryParamsHandling: 'merge' });
            return {};
        }
        else {
            this.submitLoading = true;
            this.cd.markForCheck();
            var url = this.ssoLoginOpenUrl(sso);
            if (isSet(url)) {
                openUrl(url);
                return { externalRedirect: true };
            }
        }
        return {};
    };
    LoginBaseComponent.prototype.ssoReset = function () {
        this.routing.navigate([], { queryParams: { sso: undefined }, queryParamsHandling: 'merge' });
    };
    LoginBaseComponent.prototype.tokenLogin = function (tokenOptions, userCreated, socialBackend) {
        var _this = this;
        if (userCreated === void 0) { userCreated = false; }
        this.submitLoading = true;
        this.screenLoader = true;
        this.cd.markForCheck();
        this.authService
            .tokenLogin(tokenOptions)
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            _this.onLogin({ userCreated: userCreated, socialBackend: socialBackend });
        }, function () {
            _this.submitLoading = false;
            _this.screenLoader = false;
            _this.cd.markForCheck();
        });
    };
    Object.defineProperty(LoginBaseComponent.prototype, "webBaseUrl", {
        get: function () {
            return this.domain && isProductionLocation()
                ? "https://" + this.domain.actualDomain
                : this.appConfigService.webBaseUrl;
        },
        enumerable: true,
        configurable: true
    });
    LoginBaseComponent.prototype.serializeQueryParams = function (params) {
        return toPairs(params)
            .map(function (item) { return item[0] + "=" + encodeURIComponent(item[1]); })
            .join('&');
    };
    LoginBaseComponent.prototype.loginRedirectParams = function () {
        var currentQueryParams = this.serializeQueryParams(this.activatedRoute.snapshot.queryParams);
        var projectName = this.activatedRoute.snapshot.params['project'];
        var transferProjects = this.activatedRoute.snapshot.queryParams['transfer_projects'];
        var projectToken = this.apiService.getProjectToken();
        var redirect = projectName
            ? [this.webBaseUrl, 'login', projectName].join('/')
            : [this.webBaseUrl, 'login'].join('/');
        if (currentQueryParams) {
            redirect = redirect + "?" + currentQueryParams;
        }
        var params = {
            redirect_uri: redirect,
            language: getCurrentLanguage()
        };
        if (projectName) {
            params['project'] = projectName;
        }
        if (projectToken) {
            params['project_token'] = projectToken;
        }
        if (transferProjects) {
            params['transfer_projects'] = transferProjects;
        }
        return params;
    };
    LoginBaseComponent.prototype.socialLoginUrl = function (social) {
        var params = this.loginRedirectParams();
        params['language'] = getCurrentLanguage();
        var queryParams = this.serializeQueryParams(params);
        return this.appConfigService.serverBaseUrl + "/api/social_login/" + social + "/?" + queryParams;
    };
    LoginBaseComponent.prototype.ssoLoginOpenUrl = function (sso) {
        var params = this.loginRedirectParams();
        params['sso'] = sso.uid;
        if (sso.type == SSOType.JetBridge) {
            params['auth_uri'] = this.appConfigService.serverBaseUrl + "/api/jet_bridge_login/";
        }
        params['language'] = getCurrentLanguage();
        var queryParams = this.serializeQueryParams(params);
        if (sso.type == SSOType.SAML2) {
            return this.appConfigService.serverBaseUrl + "/api/saml2_login/" + sso.uid + "/?" + queryParams;
        }
        else if (sso.type == SSOType.OAuth2) {
            return this.appConfigService.serverBaseUrl + "/api/social_login/" + sso.publicParams['backend'] + "/?" + queryParams;
        }
        else if (sso.type == SSOType.JetBridge && sso instanceof JetBridgeSSOSettings) {
            return sso.url + "external_auth/login/" + sso.uid + "/?" + queryParams;
        }
    };
    LoginBaseComponent.prototype.onInviteLoaded = function () { };
    LoginBaseComponent.prototype.onLogin = function (options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        this.projectsStore
            .getFirst()
            .pipe(untilDestroyed(this))
            .subscribe(function (projects) {
            var projectsCreated = projects ? projects.filter(function (item) { return item.isCreated; }).length : 0;
            var redirect = _this.activatedRoute.snapshot.queryParams['redirect'];
            var redirectParams = redirect ? JSON.parse(decodeURIComponent(redirect)) : undefined;
            var invited = !!(redirectParams &&
                redirectParams['url'] &&
                redirectParams['url'].join('/').startsWith('projects/invite'));
            var redirectQueryParams = redirectParams && redirectParams['params'] ? redirectParams['params'] : {};
            var onboardingEventParams = __assign({}, (redirectQueryParams['apply_template_admin_panel'] && {
                OnboardingTemplateAdminPanel: true
            }), (redirectQueryParams['apply_template_admin_panel_resource'] && {
                OnboardingTemplateAdminPanelResource: redirectQueryParams['apply_template_admin_panel_resource']
            }));
            if (options.socialBackend == 'google-oauth2') {
                options.socialBackend = 'google';
            }
            if (options.userCreated) {
                var user = _this.currentUserStore.instance;
                _this.analyticsService.sendEvent(AnalyticsEvent.GA.UserRegistration, AnalyticsEventAction.Success, '', {
                    userEmail: user ? user.email : undefined,
                    userFirstName: user ? user.firstName : undefined,
                    userLastName: user ? user.lastName : undefined,
                    socialSignIn: options.socialBackend,
                    projectsCreated: projectsCreated
                });
                _this.analyticsService.sendSimpleEvent(AnalyticsEvent.SignUp.AccountCreated, __assign({ Email: user ? user.email : undefined, FirstName: user ? user.firstName : undefined, LastName: user ? user.lastName : undefined, Provider: options.socialBackend, Invited: invited, ReferralUrl: document.referrer }, onboardingEventParams));
                if (options.socialBackend) {
                    _this.analyticsService.sendSimpleEvent(AnalyticsEvent.SignUp.AccountVerified, {
                        Email: user ? user.email : undefined
                    });
                }
            }
            else {
                _this.analyticsService.sendEvent(AnalyticsEvent.GA.UserLogin, AnalyticsEventAction.Success, '', {
                    projectsCreated: projectsCreated
                });
            }
            _this.analyticsService.sendSimpleEvent(AnalyticsEvent.SignIn.UserSignedIn, __assign({ Invited: invited, UserCreated: options.userCreated, ReferralUrl: document.referrer }, onboardingEventParams));
            _this.redirect({ projects: projects || [], userCreated: options.userCreated, selfSignOn: options.selfSignOn });
        });
    };
    Object.defineProperty(LoginBaseComponent.prototype, "isWhiteLabel", {
        get: function () {
            return this.domain && this.domain.whiteLabel;
        },
        enumerable: true,
        configurable: true
    });
    LoginBaseComponent.prototype.redirect = function (options) {
        var redirect = this.activatedRoute.snapshot.queryParams['redirect'];
        var redirectParams = redirect ? JSON.parse(decodeURIComponent(redirect)) : undefined;
        var projectSignUp = this.activatedRoute.snapshot.params['project'];
        var transferProjects = this.activatedRoute.snapshot.queryParams['transfer_projects'];
        var selfSignOn = options.selfSignOn || this.activatedRoute.snapshot.queryParams['self_sign_on'];
        var project = projectSignUp || transferProjects
            ? options.projects.find(function (item) { return item.uniqueName == projectSignUp || transferProjects; })
            : undefined;
        if (redirectParams && redirectParams['url']) {
            var queryParams = redirectParams['params'] || {};
            var referrer = this.activatedRoute.snapshot.queryParams['referrer'];
            if (referrer) {
                queryParams['referrer'] = referrer;
            }
            this.routing.navigate(redirectParams['url'], { queryParams: queryParams });
        }
        else if (project) {
            var mode = transferProjects ? AdminMode.Builder : AdminMode.App;
            if (project.params['sign_up_link'] && options.userCreated) {
                var link = isAbsoluteUrl(project.params['sign_up_link'])
                    ? { href: project.params['sign_up_link'] }
                    : project.linkWithProtocol(project.params['sign_up_link'].split('/').filter(function (item) { return isSet(item); }), { mode: mode });
                this.routing.navigateLink(link);
            }
            else {
                this.routing.navigateLink(project.getHomeLinkWithProtocol({ mode: mode }), {
                    queryParams: __assign({}, (selfSignOn && {
                        src: HomeTriggerOutput.Register
                    }))
                });
            }
        }
        else {
            this.routing.setRouterAttr(ROUTE_LOADED_PROJECTS_STORE, true);
            this.routing.navigate(['/projects']);
        }
    };
    return LoginBaseComponent;
}());
export { LoginBaseComponent };
