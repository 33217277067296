/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../customize-bar-custom-html-edit/customize-bar-custom-html-edit.component.ngfactory";
import * as i2 from "../customize-bar-custom-html-edit/customize-bar-custom-html-edit.form";
import * as i3 from "../../../projects/stores/current-project.store";
import * as i4 from "../../../projects/stores/current-environment.store";
import * as i5 from "../../../custom-views/services/custom-view/custom-view.service";
import * as i6 from "../../../custom-views/stores/custom-views.store";
import * as i7 from "../customize-bar-custom-html-edit/customize-bar-custom-html-edit.component";
import * as i8 from "../../services/customize-bar-context/customize-bar.context";
import * as i9 from "../customize-bar-custom-view-edit/customize-bar-custom-view-edit.component.ngfactory";
import * as i10 from "../customize-bar-custom-view-edit/customize-bar-custom-view-edit.form";
import * as i11 from "../../../customize-configuration/services/element-configuration/element-configuration.service";
import * as i12 from "../customize-bar-custom-view-edit/customize-bar-custom-view-edit.component";
import * as i13 from "../customize-bar-custom-element-edit/customize-bar-custom-element-edit.component.ngfactory";
import * as i14 from "../customize-bar-custom-element-edit/customize-bar-custom-element-edit.form";
import * as i15 from "../customize-bar-custom-element-edit/customize-bar-custom-element-edit.component";
import * as i16 from "@angular/common";
import * as i17 from "./customize-bar-custom-edit.component";
var styles_CustomizeBarCustomEditComponent = [];
var RenderType_CustomizeBarCustomEditComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CustomizeBarCustomEditComponent, data: {} });
export { RenderType_CustomizeBarCustomEditComponent as RenderType_CustomizeBarCustomEditComponent };
function View_CustomizeBarCustomEditComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-customize-bar-custom-html-edit", [], null, [[null, "event"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("event" === en)) {
        var pd_0 = (_co.event.emit($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_CustomizeBarCustomHTMLEditComponent_0, i1.RenderType_CustomizeBarCustomHTMLEditComponent)), i0.ɵprd(131584, null, i2.CustomizeBarCustomHTMLEditForm, i2.CustomizeBarCustomHTMLEditForm, [i3.CurrentProjectStore, i4.CurrentEnvironmentStore, i5.CustomViewService, i6.CustomViewsStore]), i0.ɵdid(2, 245760, null, 0, i7.CustomizeBarCustomHTMLEditComponent, [i2.CustomizeBarCustomHTMLEditForm, i8.CustomizeBarContext, i0.ChangeDetectorRef], { element: [0, "element"], context: [1, "context"], contextElement: [2, "contextElement"], titleEnabled: [3, "titleEnabled"], titleEditable: [4, "titleEditable"], deleteEnabled: [5, "deleteEnabled"], parentElement: [6, "parentElement"], parentPopup: [7, "parentPopup"] }, { event: "event" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.element; var currVal_1 = _co.context; var currVal_2 = _co.contextElement; var currVal_3 = _co.titleEnabled; var currVal_4 = _co.titleEditable; var currVal_5 = _co.deleteEnabled; var currVal_6 = _co.parentElement; var currVal_7 = _co.parentPopup; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, null); }
function View_CustomizeBarCustomEditComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-customize-bar-custom-view-edit", [], null, [[null, "event"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("event" === en)) {
        var pd_0 = (_co.event.emit($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_CustomizeBarCustomViewEditComponent_0, i9.RenderType_CustomizeBarCustomViewEditComponent)), i0.ɵprd(131584, null, i10.CustomizeBarCustomViewEditForm, i10.CustomizeBarCustomViewEditForm, [i3.CurrentProjectStore, i4.CurrentEnvironmentStore, i5.CustomViewService, i6.CustomViewsStore, i11.ElementConfigurationService]), i0.ɵdid(2, 245760, null, 0, i12.CustomizeBarCustomViewEditComponent, [i10.CustomizeBarCustomViewEditForm, i8.CustomizeBarContext, i0.ChangeDetectorRef], { element: [0, "element"], context: [1, "context"], contextElement: [2, "contextElement"], titleEnabled: [3, "titleEnabled"], titleEditable: [4, "titleEditable"], deleteEnabled: [5, "deleteEnabled"], parentElement: [6, "parentElement"], parentPopup: [7, "parentPopup"], firstInit: [8, "firstInit"] }, { event: "event" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.element; var currVal_1 = _co.context; var currVal_2 = _co.contextElement; var currVal_3 = _co.titleEnabled; var currVal_4 = _co.titleEditable; var currVal_5 = _co.deleteEnabled; var currVal_6 = _co.parentElement; var currVal_7 = _co.parentPopup; var currVal_8 = _co.firstInit; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }, null); }
function View_CustomizeBarCustomEditComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-customize-bar-custom-element-edit", [], null, [[null, "event"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("event" === en)) {
        var pd_0 = (_co.event.emit($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i13.View_CustomizeBarCustomElementEditComponent_0, i13.RenderType_CustomizeBarCustomElementEditComponent)), i0.ɵprd(131584, null, i14.CustomizeBarCustomElementEditForm, i14.CustomizeBarCustomElementEditForm, [i3.CurrentProjectStore, i4.CurrentEnvironmentStore, i5.CustomViewService, i6.CustomViewsStore, i11.ElementConfigurationService]), i0.ɵdid(2, 245760, null, 0, i15.CustomizeBarCustomElementEditComponent, [i14.CustomizeBarCustomElementEditForm, i3.CurrentProjectStore, i4.CurrentEnvironmentStore, i8.CustomizeBarContext, i5.CustomViewService, i0.ChangeDetectorRef], { element: [0, "element"], context: [1, "context"], contextElement: [2, "contextElement"], titleEnabled: [3, "titleEnabled"], titleEditable: [4, "titleEditable"], deleteEnabled: [5, "deleteEnabled"], parentElement: [6, "parentElement"], parentPopup: [7, "parentPopup"], firstInit: [8, "firstInit"] }, { event: "event" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.element; var currVal_1 = _co.context; var currVal_2 = _co.contextElement; var currVal_3 = _co.titleEnabled; var currVal_4 = _co.titleEditable; var currVal_5 = _co.deleteEnabled; var currVal_6 = _co.parentElement; var currVal_7 = _co.parentPopup; var currVal_8 = _co.firstInit; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }, null); }
export function View_CustomizeBarCustomEditComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomizeBarCustomEditComponent_1)), i0.ɵdid(1, 16384, null, 0, i16.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomizeBarCustomEditComponent_2)), i0.ɵdid(3, 16384, null, 0, i16.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomizeBarCustomEditComponent_3)), i0.ɵdid(5, 16384, null, 0, i16.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.element.source == _co.sources.HTML); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.element.source == _co.sources.View); _ck(_v, 3, 0, currVal_1); var currVal_2 = (_co.element.source == _co.sources.CustomElement); _ck(_v, 5, 0, currVal_2); }, null); }
export function View_CustomizeBarCustomEditComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-customize-bar-custom-edit", [], null, null, null, View_CustomizeBarCustomEditComponent_0, RenderType_CustomizeBarCustomEditComponent)), i0.ɵdid(1, 49152, null, 0, i17.CustomizeBarCustomEditComponent, [], null, null)], null, null); }
var CustomizeBarCustomEditComponentNgFactory = i0.ɵccf("app-customize-bar-custom-edit", i17.CustomizeBarCustomEditComponent, View_CustomizeBarCustomEditComponent_Host_0, { title: "title", titleEnabled: "titleEnabled", element: "element", context: "context", contextElement: "contextElement", deleteEnabled: "deleteEnabled", titleEditable: "titleEditable", parentElement: "parentElement", parentPopup: "parentPopup", firstInit: "firstInit" }, { event: "event" }, []);
export { CustomizeBarCustomEditComponentNgFactory as CustomizeBarCustomEditComponentNgFactory };
