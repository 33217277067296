/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../list-components/components/model-card-action/model-card-action.component.ngfactory";
import * as i2 from "../../../list-components/components/model-card-action/model-card-action.component";
import * as i3 from "../../../customize/services/customize/customize.service";
import * as i4 from "../../../action-queries/services/action/action.service";
import * as i5 from "../../../action-queries/services/action-controller/action-controller.service";
import * as i6 from "../../../../common/notifications/services/notification/notification.service";
import * as i7 from "@angular/common";
import * as i8 from "../../../list-components/components/model-card/model-card.component.ngfactory";
import * as i9 from "../../../list-components/components/model-card/model-card.component";
import * as i10 from "../../../custom-elements-components/components/custom-element-auto/custom-element-auto.component.ngfactory";
import * as i11 from "../../../custom-elements-components/components/custom-element-auto/custom-element-auto.component";
import * as i12 from "./calendar-item.component";
import * as i13 from "../../../custom-views/stores/custom-views.store";
var styles_CalendarItemComponent = [];
var RenderType_CalendarItemComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CalendarItemComponent, data: {} });
export { RenderType_CalendarItemComponent as RenderType_CalendarItemComponent };
function View_CalendarItemComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-model-card-action", [["class", "calendar-card__buttons-item"]], [[2, "element__indicator-wrapper", null], [2, "hidden", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = ($event.stopPropagation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_ModelCardActionComponent_0, i1.RenderType_ModelCardActionComponent)), i0.ɵdid(2, 770048, null, 0, i2.ModelCardActionComponent, [i3.CustomizeService, i4.ActionService, i5.ActionControllerService, i6.NotificationService, i0.Injector, i0.ChangeDetectorRef], { model: [0, "model"], action: [1, "action"], context: [2, "context"], contextElement: [3, "contextElement"], theme: [4, "theme"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.item.model; var currVal_3 = _v.context.$implicit; var currVal_4 = _co.context; var currVal_5 = _co.contextElement; var currVal_6 = _co.theme; _ck(_v, 2, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2).indicatorWrapper; var currVal_1 = i0.ɵnov(_v, 2).hidden; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_CalendarItemComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "calendar-card__buttons"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CalendarItemComponent_3)), i0.ɵdid(2, 278528, null, 0, i7.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.settings.modelActions; _ck(_v, 2, 0, currVal_0); }, null); }
function View_CalendarItemComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, [["root_element", 1]], null, 4, "div", [["class", "calendar-card calendar-card--accent"]], [[2, "calendar-card_clickable", null], [2, "calendar-card_selected", null], [2, "calendar-card_theme", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick($event, i0.ɵnov(_v, 0)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-model-card", [], null, [[null, "modelUpdated"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("modelUpdated" === en)) {
        var pd_0 = (_co.modelUpdated.emit($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_ModelCardComponent_0, i8.RenderType_ModelCardComponent)), i0.ɵdid(2, 245760, null, 0, i9.ModelCardComponent, [i4.ActionService, i0.ChangeDetectorRef], { item: [0, "item"], modelDescription: [1, "modelDescription"], visibleColumns: [2, "visibleColumns"], columns: [3, "columns"], labels: [4, "labels"], compact: [5, "compact"], context: [6, "context"], contextElement: [7, "contextElement"], theme: [8, "theme"] }, { modelUpdated: "modelUpdated" }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CalendarItemComponent_2)), i0.ɵdid(4, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.item; var currVal_4 = _co.modelDescription; var currVal_5 = _co.visibleColumns; var currVal_6 = (((_co.settings.dataSource == null) ? null : _co.settings.dataSource.columns) || i0.ɵEMPTY_ARRAY); var currVal_7 = false; var currVal_8 = true; var currVal_9 = _co.context; var currVal_10 = _co.contextElement; var currVal_11 = _co.theme; _ck(_v, 2, 0, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11); var currVal_12 = _co.settings.modelActions.length; _ck(_v, 4, 0, currVal_12); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = true; var currVal_1 = _co.selected; var currVal_2 = _co.theme; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_CalendarItemComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, [["root_element", 1]], null, 3, "div", [["class", "calendar-card-view"]], [[2, "calendar-card-view_clickable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick($event, i0.ɵnov(_v, 0)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "app-custom-element-auto", [], null, null, null, i10.View_CustomElementAutoComponent_0, i10.RenderType_CustomElementAutoComponent)), i0.ɵdid(2, 245760, null, 0, i11.CustomElementAutoComponent, [i5.ActionControllerService, i0.Injector], { source: [0, "source"], customView: [1, "customView"], actions: [2, "actions"], params: [3, "params"], context: [4, "context"], contextElement: [5, "contextElement"], localContext: [6, "localContext"], stateSelectedEnabled: [7, "stateSelectedEnabled"], stateSelected: [8, "stateSelected"] }, null), i0.ɵpid(131072, i7.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.customView.source; var currVal_2 = _co.customView; var currVal_3 = _co.settings.customActions; var currVal_4 = i0.ɵunv(_v, 2, 3, i0.ɵnov(_v, 3).transform(_co.viewParams$)); var currVal_5 = _co.context; var currVal_6 = _co.contextElement; var currVal_7 = _co.localContext; var currVal_8 = true; var currVal_9 = _co.selected; _ck(_v, 2, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }, function (_ck, _v) { var currVal_0 = true; _ck(_v, 0, 0, currVal_0); }); }
export function View_CalendarItemComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_CalendarItemComponent_1)), i0.ɵdid(1, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CalendarItemComponent_4)), i0.ɵdid(3, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.customView; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.customView; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_CalendarItemComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-calendar-item", [], null, null, null, View_CalendarItemComponent_0, RenderType_CalendarItemComponent)), i0.ɵdid(1, 770048, null, 0, i12.CalendarItemComponent, [i13.CustomViewsStore, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CalendarItemComponentNgFactory = i0.ɵccf("app-calendar-item", i12.CalendarItemComponent, View_CalendarItemComponent_Host_0, { item: "item", listState: "listState", modelDescription: "modelDescription", settings: "settings", visibleColumns: "visibleColumns", context: "context", contextElement: "contextElement", selected: "selected", theme: "theme" }, { select: "select", modelUpdated: "modelUpdated" }, []);
export { CalendarItemComponentNgFactory as CalendarItemComponentNgFactory };
