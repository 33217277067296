var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Injector } from '@angular/core';
import { FormControl } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, of } from 'rxjs';
import { catchError, debounceTime, map, switchMap, tap } from 'rxjs/operators';
import { NotificationService } from '@common/notifications';
import { ActionService } from '@modules/action-queries';
import { AggregateFunc, DatasetGroupLookup } from '@modules/charts';
import { CustomView, CustomViewService, CustomViewsStore } from '@modules/custom-views';
import { ListDefaultSelection } from '@modules/customize';
import { ElementConfigurationService } from '@modules/customize-configuration';
import { WidgetQueryService } from '@modules/dashboard-queries';
import { DataSourceType } from '@modules/data-sources';
import { applyParamInputs, FieldType, Input, Input as FieldInput } from '@modules/fields';
import { ModelOptionsSource } from '@modules/filters-components';
import { ModelDescriptionStore } from '@modules/model-queries';
import { FieldInputControl, modelDescriptionHasAutoParameters } from '@modules/parameters';
import { CurrentEnvironmentStore, CurrentProjectStore, ResourceType } from '@modules/projects';
import { ChartWidgetQuery, QueryType } from '@modules/queries';
import { ResourceControllerService } from '@modules/resources';
import { controlValue, isSet } from '@shared';
import { ActionOutputFormGroup } from '../../../forms/action-output.form-group';
import { FieldActionsArray } from '../../display-fields-edit/field-actions.array';
import { ListGroupArray } from '../../list-groups-edit/list-group.array';
import { ListModelDescriptionDataSourceControl } from '../../model-description-data-source-edit/list-model-description-data-source';
import { OptionEnabledArray } from '../../option-enabled-edit/option-enabled.array';
import { GridListElementStylesControl } from '../../styles-grid-list-element-edit/grid-list-element-styles.control';
import { CustomizeBarListLayoutSettingsForm, validateAction, validateActions } from '../customize-bar-list-layout-settings/customize-bar-list-layout-settings.form';
export var GridCardsPerRow;
(function (GridCardsPerRow) {
    GridCardsPerRow["Auto"] = "auto";
    GridCardsPerRow["Custom"] = "custom";
})(GridCardsPerRow || (GridCardsPerRow = {}));
var CustomizeBarGridSettingsForm = /** @class */ (function (_super) {
    __extends(CustomizeBarGridSettingsForm, _super);
    function CustomizeBarGridSettingsForm(widgetQueryService, modelOptionsSource, customViewService, customViewsStore, notificationService, injector, currentProjectStore, currentEnvironmentStore, resourceControllerService, modelDescriptionStore, actionService, elementConfigurationService, dataSourceControl) {
        var _this = _super.call(this, currentProjectStore, currentEnvironmentStore, resourceControllerService, modelDescriptionStore, actionService, elementConfigurationService, {
            header: new FormControl(true),
            title: new FieldInputControl({ path: ['value'] }),
            data_source: dataSourceControl,
            card_view_unique_name: new FormControl(null),
            card_view_mappings: new FormControl([]),
            search_enabled: new FormControl(true),
            search_live: new FormControl(true),
            group_field: new FormControl(''),
            group_collapse: new FormControl(true),
            group_counter: new FormControl(true),
            groups: new ListGroupArray([]),
            card_click_action: new FormControl(undefined, undefined, validateAction),
            actions: new FormControl([], undefined, validateActions),
            model_actions: new FormControl([], undefined, validateActions),
            columns_actions: new FieldActionsArray([]),
            custom_actions: new ActionOutputFormGroup(elementConfigurationService),
            sorting_field: new FormControl(undefined),
            sorting_asc: new FormControl(true),
            per_page: new FormControl(undefined),
            cards_per_row: new FormControl(GridCardsPerRow.Auto),
            cards_per_row_custom: new FormControl(null),
            multiple_selection: new FormControl(false),
            first_item_default_selection: new FormControl(false),
            display_filters: new FormControl(true),
            filter_fields: new OptionEnabledArray([]),
            display_footer: new FormControl(true),
            visible_input: new FieldInputControl({ path: ['value'] }),
            name: new FormControl(''),
            tooltip: new FormControl(''),
            gap_horizontal: new FormControl(null),
            gap_vertical: new FormControl(null),
            card_wrap: new FormControl(true),
            element_styles: new GridListElementStylesControl(injector)
        }) || this;
        _this.widgetQueryService = widgetQueryService;
        _this.modelOptionsSource = modelOptionsSource;
        _this.customViewService = customViewService;
        _this.customViewsStore = customViewsStore;
        _this.notificationService = notificationService;
        _this.injector = injector;
        _this.cardsPerRowOptions = [
            {
                name: 'Auto',
                value: GridCardsPerRow.Auto
            },
            {
                name: 'Specify number of cards',
                value: GridCardsPerRow.Custom
            }
        ];
        dataSourceControl.setRequired(true);
        return _this;
    }
    CustomizeBarGridSettingsForm.prototype.init = function (options) {
        var _this = this;
        this.settings = options.settings;
        this.pageUid = options.pageUid;
        this.elementUid = options.elementUid;
        this.options = options;
        var customView$ = isSet(options.settings.cardCustomView)
            ? this.customViewsStore.getDetailFirst(options.settings.cardCustomView)
            : of(undefined);
        return customView$.pipe(tap(function (customView) {
            var value = __assign({ header: options.settings.header, title: options.settings.titleInput ? options.settings.titleInput.serializeWithoutPath() : {}, card_view_unique_name: options.settings.cardCustomView, card_view_mappings: options.settings.cardCustomViewMappings, search_enabled: options.settings.searchEnabled, search_live: options.settings.searchLive, group_field: options.settings.groupField, group_collapse: options.settings.groupCollapse, group_counter: options.settings.groupCounter, card_click_action: options.settings.cardClickAction, actions: options.settings.actions, model_actions: options.settings.modelActions, columns_actions: options.settings.columnActions, sorting_field: options.settings.sortingField || null, sorting_asc: options.settings.sortingAsc, per_page: options.settings.perPage }, (isSet(options.settings.cardsPerRow)
                ? {
                    cards_per_row: GridCardsPerRow.Custom,
                    cards_per_row_custom: options.settings.cardsPerRow
                }
                : {
                    cards_per_row: GridCardsPerRow.Auto
                }), { multiple_selection: options.settings.multipleSelection, first_item_default_selection: options.settings.defaultSelection == ListDefaultSelection.First, display_filters: options.settings.displayFilters, filter_fields: options.settings.filterFields.map(function (item) {
                    return {
                        name: item.name,
                        enabled: true
                    };
                }), display_footer: options.settings.displayFooter, visible_input: options.visibleInput ? options.visibleInput.serializeWithoutPath() : {}, tooltip: options.settings.tooltip, gap_horizontal: options.settings.gapHorizontal, gap_vertical: options.settings.gapVertical });
            if (options.nameEditable) {
                value['name'] = options.name;
            }
            _this.patchValue(value, { emitEvent: false });
            _this.controls.data_source.deserialize(options.settings.dataSource);
            _this.controls.groups.deserialize(options.settings.groups);
            _this.controls.custom_actions.deserialize(customView ? customView.actions : [], options.settings.customActions);
            if (options.cardWrapEditable) {
                _this.controls.card_wrap.patchValue(options.cardWrap);
            }
            if (options.elementStylesEditable && options.elementStyles) {
                _this.controls.element_styles.deserialize(options.elementStyles);
            }
            _this.trackChanges(options);
            _this.initPerPageClean(_this.controls.per_page);
            if (!options.firstInit) {
                _this.markAsDirty();
            }
        }));
    };
    CustomizeBarGridSettingsForm.prototype.trackChanges = function (options) {
        var _this = this;
        _super.prototype.trackChanges.call(this, options);
        combineLatest(controlValue(this.controls.data_source.controls.columns), this.controls.data_source.getModelDescription$(), this.controls.data_source.getModelDescription$().pipe(switchMap(function (modelDescription) {
            if (!modelDescription) {
                return of([]);
            }
            return _this.modelOptionsSource.getOptions$(modelDescription, {
                relationsEnabled: true
            });
        })))
            .pipe(debounceTime(60), untilDestroyed(this))
            .subscribe(function (_a) {
            var columns = _a[0], modelDescription = _a[1], modelOptions = _a[2];
            var columnNames = modelDescription ? modelOptions.map(function (item) { return item.name; }) : columns.map(function (item) { return item.name; });
            var modelId = modelDescription ? modelDescription.modelId : null;
            var filterFieldsSourceChanged = _this.controls.filter_fields.isSourceSet()
                ? !_this.controls.filter_fields.isSource(modelId)
                : false;
            _this.controls.filter_fields.syncControls(columnNames, { source: modelId });
            if (_this.controls.display_filters.value && _this.controls.filter_fields.isAllDisabled()) {
                _this.controls.filter_fields.enableDefault();
            }
            else if (_this.controls.display_filters.value && filterFieldsSourceChanged) {
                _this.controls.filter_fields.enableDefault();
            }
        });
        this.controls.group_field.valueChanges.subscribe(function () { return _this.controls.groups.removeControls(); });
    };
    CustomizeBarGridSettingsForm.prototype.isGetMissingEnabled = function () {
        if (this.controls.data_source.controls.type.value != DataSourceType.Query) {
            return false;
        }
        var query = this.controls.data_source.controls.query.value;
        if (!query) {
            return false;
        }
        else if (query.queryType == QueryType.Simple && query.simpleQuery) {
            var resourceName_1 = this.controls.data_source.controls.query_resource.value;
            var resource = this.currentEnvironmentStore.resources.find(function (item) { return item.uniqueName == resourceName_1; });
            var modelDescription = this.modelDescriptionStore.instance
                ? this.modelDescriptionStore.instance.find(function (item) { return item.model == query.simpleQuery.model; })
                : undefined;
            if (!resource || !modelDescription) {
                return false;
            }
            if (resource.type == ResourceType.Airtable) {
                return resource.isSynced(modelDescription.model) || modelDescription.isSynced();
            }
            else {
                return modelDescriptionHasAutoParameters(resource, modelDescription);
            }
        }
        else if (query.queryType == QueryType.Http) {
            return query.frontendFiltering;
        }
        else if (query.queryType == QueryType.SQL) {
            return true;
        }
        else if (query.queryType == QueryType.Object) {
            return true;
        }
        else {
            return false;
        }
    };
    CustomizeBarGridSettingsForm.prototype.getMissing = function () {
        if (!this.controls.group_field.value) {
            return of([]);
        }
        var existingValues = this.controls.groups.controls.map(function (item) { return item.controls.value.value; });
        var resourceName = this.controls.data_source.controls.query_resource.value;
        var resource = this.currentEnvironmentStore.resources.find(function (item) { return item.uniqueName == resourceName; });
        var modelQuery = this.controls.data_source.controls.query.value;
        var modelDescription = modelQuery.queryType == QueryType.Simple && modelQuery.simpleQuery && this.modelDescriptionStore.instance
            ? this.modelDescriptionStore.instance.find(function (item) { return item.model == modelQuery.simpleQuery.model; })
            : undefined;
        var modelField = modelDescription ? modelDescription.field(this.controls.group_field.value) : undefined;
        if (modelField &&
            [FieldType.Select, FieldType.MultipleSelect, FieldType.RadioButton].includes(modelField.item.field) &&
            modelField.item.params['options'] &&
            modelField.item.params['options'].length) {
            var values = modelField.item.params['options'].map(function (item) { return item['value']; });
            return of(values.filter(function (item) { return existingValues.find(function (i) { return i == item; }) == undefined; }));
        }
        if (!this.isGetMissingEnabled()) {
            return of([]);
        }
        var query = new ChartWidgetQuery();
        var parameters = this.controls.data_source.controls.query_parameters.value;
        var columns = this.controls.data_source.controls.columns.value;
        var xColumns = [{ xColumn: this.controls.group_field.value, xLookup: DatasetGroupLookup.Plain }];
        query.queryType = modelQuery.queryType;
        query.simpleQuery = modelQuery.simpleQuery;
        query.httpQuery = modelQuery.httpQuery;
        query.sqlQuery = modelQuery.sqlQuery;
        var params = applyParamInputs({}, this.controls.data_source.controls.query_inputs.value, {
            context: this.context,
            contextElement: this.contextElement,
            parameters: parameters
        });
        return this.widgetQueryService
            .groupQuery(resource, query, xColumns, AggregateFunc.Count, undefined, parameters, params, columns)
            .pipe(map(function (result) {
            if (!result) {
                return;
            }
            var values = result.map(function (item) { return item.group; });
            return values.filter(function (item) { return existingValues.find(function (i) { return i == item; }) == undefined; }).slice(0, 10);
        }), catchError(function () { return of(undefined); }));
    };
    CustomizeBarGridSettingsForm.prototype.onModelChange = function (modelDescription) {
        _super.prototype.onModelChange.call(this, modelDescription);
        this.controls.group_field.patchValue('');
        this.controls.groups.removeControls();
    };
    CustomizeBarGridSettingsForm.prototype.getCardCustomView$ = function () {
        var _this = this;
        return controlValue(this.controls.card_view_unique_name).pipe(switchMap(function (customView) {
            return isSet(customView) ? _this.customViewsStore.getDetail(customView) : of(undefined);
        }));
    };
    CustomizeBarGridSettingsForm.prototype.getCustomViewDefaults = function () {
        return {
            uniqueName: CustomView.generateUniqueName(),
            pageUid: this.pageUid,
            elementUid: this.elementUid
        };
    };
    CustomizeBarGridSettingsForm.prototype.isConfigured = function (instance) {
        return this.elementConfigurationService.isListGridConfigured(instance, { restrictDemo: true });
    };
    CustomizeBarGridSettingsForm.prototype.submit = function () {
        var instance = cloneDeep(this.settings);
        instance.titleInput = this.controls.title ? new FieldInput().deserialize(this.controls.title.value) : undefined;
        instance.dataSource = this.controls.data_source.serialize();
        instance.cardCustomView = this.controls.card_view_unique_name.value;
        instance.cardCustomViewMappings = this.controls.card_view_mappings.value;
        instance.searchEnabled = this.controls.search_enabled.value;
        instance.searchLive = this.controls.search_live.value;
        instance.groupField = this.controls.group_field.value;
        instance.groupCollapse = this.controls.group_collapse.value;
        instance.groupCounter = this.controls.group_counter.value;
        instance.groups = this.controls.groups.serialize();
        if (this.controls.card_click_action.value) {
            instance.cardClickAction = this.controls.card_click_action.value;
        }
        else {
            instance.cardClickAction = undefined;
        }
        instance.actions = this.controls.actions.value;
        instance.modelActions = this.controls.model_actions.value;
        instance.columnActions = this.controls.columns_actions.value;
        instance.customActions = this.controls.custom_actions.serialize();
        if (isSet(this.controls.sorting_field.value)) {
            instance.sortingField = this.controls.sorting_field.value;
        }
        else {
            instance.sortingField = undefined;
        }
        instance.sortingAsc = this.controls.sorting_asc.value;
        instance.perPage = this.controls.per_page.value;
        if (this.controls.cards_per_row.value == GridCardsPerRow.Custom &&
            isSet(this.controls.cards_per_row_custom.value)) {
            instance.cardsPerRow = this.controls.cards_per_row_custom.value;
        }
        else {
            instance.cardsPerRow = null;
        }
        instance.multipleSelection = this.controls.multiple_selection.value;
        instance.defaultSelection = this.controls.first_item_default_selection.value
            ? ListDefaultSelection.First
            : undefined;
        instance.filterFields = this.controls.filter_fields.value
            .filter(function (item) { return item.enabled; })
            .map(function (item) {
            return {
                name: item.name
            };
        });
        instance.displayFilters = instance.filterFields.length && this.controls.display_filters.value;
        instance.displayFooter = this.controls.display_footer.value;
        instance.header =
            (instance.titleInput && instance.titleInput.isSet()) ||
                !!instance.searchEnabled ||
                !!instance.displayFilters ||
                (instance.actions && instance.actions.length > 0);
        instance.tooltip = isSet(this.controls.tooltip.value) ? this.controls.tooltip.value.trim() : undefined;
        instance.gapHorizontal = this.controls.gap_horizontal.value;
        instance.gapVertical = this.controls.gap_vertical.value;
        return __assign({ settings: instance, visibleInput: this.controls.visible_input.value
                ? new Input().deserialize(this.controls.visible_input.value)
                : undefined, name: this.controls.name.value }, (this.options.cardWrapEditable && {
            cardWrap: this.controls.card_wrap.value
        }), (this.options.elementStylesEditable && {
            elementStyles: this.controls.element_styles.serialize()
        }));
    };
    return CustomizeBarGridSettingsForm;
}(CustomizeBarListLayoutSettingsForm));
export { CustomizeBarGridSettingsForm };
