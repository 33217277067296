import { Injectable } from '@angular/core';
import saveAs from 'file-saver';
import fromPairs from 'lodash/fromPairs';
import * as moment from 'moment';
import { from, Observable, of, Subject } from 'rxjs';
import { switchMap, takeWhile } from 'rxjs/operators';
import { slugify } from 'transliteration';

import { RawListViewSettingsColumn, ViewContext, ViewContextElement } from '@modules/customize';
import { ListModelDescriptionDataSource } from '@modules/data-sources';
import { Domain, DomainStore } from '@modules/domain';
import { applyParamInput, getFieldDescriptionByType } from '@modules/fields';
import { FilterItem2, Sort } from '@modules/filters';
import { ColumnsModelListStore, ListItem } from '@modules/list';
import { PER_PAGE_PARAM } from '@modules/models';
import { CurrentProjectStore } from '@modules/projects';
import { GetQueryOptions } from '@modules/resources';
import { isSet } from '@shared';

import { ExportFormat, exportFormatBookTypes, exportFormats, ExportFormatType } from '../../data/export-formats';

@Injectable()
export class ExportService {
  cancel = false; // TODO: fix concurrent exports

  constructor(
    private modelListStore: ColumnsModelListStore,
    private currentProjectStore: CurrentProjectStore,
    private domainStore: DomainStore
  ) {}

  exportModels(
    title: string | undefined,
    dataSource: ListModelDescriptionDataSource,
    queryOptions: GetQueryOptions,
    formatType: ExportFormatType,
    context?: ViewContext,
    contextElement?: ViewContextElement,
    localContext?: Object
  ): Observable<number> {
    const progressObs = new Subject<number>();

    let staticData = [];

    try {
      staticData = applyParamInput(dataSource.input, {
        context: context,
        contextElement: contextElement,
        localContext: localContext,
        defaultValue: []
      });
    } catch (e) {}

    this.modelListStore.dataSource = dataSource;
    this.modelListStore.queryOptions = queryOptions;
    this.modelListStore.staticData = staticData;
    this.modelListStore.context = context;
    this.modelListStore.contextElement = contextElement;
    this.modelListStore.localContext = localContext;
    this.modelListStore.perPage = 100;
    this.modelListStore.reset();

    if (queryOptions.paging && isSet(queryOptions.paging.limit)) {
      this.modelListStore.perPage = queryOptions.paging.limit;
    } else {
      this.modelListStore.perPage = 100;
    }

    this.cancel = false;

    progressObs.next(0);

    this.domainStore
      .getFirst()
      .pipe(
        switchMap(domain => {
          return this.processPage(progressObs).pipe(
            switchMap(result => {
              const columns = this.modelListStore.dataSource.columns.filter(item => item.visible);
              const data = result.map(item => {
                return columns.map(column => {
                  const value = item.model.getAttribute(column.name);
                  return this.serializeItemColumn(column, value, { domain });
                });
              });

              return this.downloadData(title, columns, data, formatType);
            })
          );
        })
      )
      .subscribe(
        () => progressObs.next(1),
        e => progressObs.error(e)
      );

    return progressObs;
  }

  processPage(progressObs: Subject<number>, items: ListItem[] = []): Observable<ListItem[]> {
    return this.modelListStore.getNext().pipe(
      takeWhile(() => !this.cancel),
      switchMap(results => {
        const progress =
          isSet(this.modelListStore.currentPage) && isSet(this.modelListStore.totalPages)
            ? this.modelListStore.currentPage / (this.modelListStore.totalPages + 1)
            : undefined;
        progressObs.next(progress);

        const result = items.concat(results);

        if (!this.modelListStore.hasMore) {
          return of(result);
        }

        return this.processPage(progressObs, result);
      })
    );
  }

  serializeItemColumn(column: RawListViewSettingsColumn, value: any, options: { domain?: Domain } = {}) {
    const field = column ? column.field : undefined;
    const fieldDescription = getFieldDescriptionByType(field);

    if (fieldDescription.valueToStr) {
      return fieldDescription.valueToStr(value, { field: column, noTruncate: true, domain: options.domain });
    } else {
      return value;
    }
  }

  downloadData(
    title: string | undefined,
    columns: RawListViewSettingsColumn[],
    data: Object[][],
    formatType: ExportFormatType
  ): Observable<boolean> {
    const exportFormat = exportFormats.find(item => item.type == formatType);

    if (formatType == ExportFormatType.JSON) {
      const jsonData = data.map(row => {
        return fromPairs(
          columns.map((column, i) => {
            return [isSet(column.verboseName) ? column.verboseName : column.name, row[i]];
          })
        );
      });

      return this.downloadDataAsJSON(title, jsonData, exportFormat);
    } else {
      return from(this.downloadDataAsWorkbook(title, columns, data, exportFormat));
    }
  }

  downloadDataAsJSON(title: string | undefined, data: Object[], format: ExportFormat): Observable<boolean> {
    if (!data.length) {
      return of(false);
    }

    const blob = new Blob([JSON.stringify(data)], { type: 'application/json; charset=utf-8' });
    const titleSlug = isSet(title) ? slugify(title, { trim: true, separator: '_' }).replace(/_+/g, '_') : undefined;
    const filename = [
      this.currentProjectStore.instance.uniqueName,
      ...(isSet(titleSlug) ? [titleSlug] : []),
      moment().format('YYYY-MM-DD'),
      moment().format('HH-mm-ss')
    ].join('_');

    saveAs(blob, `${filename}.${format.extension}`);

    return of(true);
  }

  async downloadDataAsWorkbook(
    title: string | undefined,
    columns: RawListViewSettingsColumn[],
    data: Object[][],
    format: ExportFormat
  ): Promise<boolean> {
    if (!data.length) {
      return false;
    }

    const XLSX = import(/* webpackChunkName: "xlsx" */ 'xlsx');
    const xlsx = await XLSX;

    const book = xlsx.utils.book_new();

    const header = columns
      ? columns.map(item => {
          return isSet(item.verboseName) ? item.verboseName : item.name;
        })
      : undefined;
    const titleSlug = isSet(title) ? slugify(title, { trim: true, separator: '_' }).replace(/_+/g, '_') : undefined;
    const sheetTitle = isSet(title)
      ? slugify(title, { trim: true, separator: '-' }).replace(/-+/g, '-').substring(0, 31)
      : 'Sheet';
    let sheet = xlsx.utils.json_to_sheet([], { header: header });
    sheet = xlsx.utils.sheet_add_aoa(sheet, data, { origin: 1 });
    xlsx.utils.book_append_sheet(book, sheet, sheetTitle);

    const bookType = exportFormatBookTypes[format.type];
    const out = (<any>xlsx.write)(book, { bookType: bookType, bookSST: false, type: 'array' });
    const blob = new Blob([out], { type: 'application/octet-stream' });
    const filename = [
      this.currentProjectStore.instance.uniqueName,
      ...(isSet(titleSlug) ? [titleSlug] : []),
      moment().format('YYYY-MM-DD'),
      moment().format('HH-mm-ss')
    ].join('_');

    saveAs(blob, `${filename}.${format.extension}`);

    return true;
  }
}
