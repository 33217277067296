var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injector } from '@angular/core';
import { of } from 'rxjs';
import { map, publishLast, refCount, switchMap, tap } from 'rxjs/operators';
import { AppConfigService } from '@core';
import { ApiService, ServerRequestError } from '@modules/api';
import { MenuGeneratorService } from '@modules/menu';
import { ModelDescriptionService } from '@modules/model-queries';
import { CryptService, databaseResourcesEngines, Resource, ResourceDeploy, ResourceName } from '@modules/projects';
import { ResourceGeneratorService } from '../resource-generator/resource-generator.service';
var DatabaseGeneratorService = /** @class */ (function (_super) {
    __extends(DatabaseGeneratorService, _super);
    function DatabaseGeneratorService(menuGeneratorService, cryptService, modelDescriptionService, appConfigService, apiService, http, injector) {
        var _this = _super.call(this) || this;
        _this.menuGeneratorService = menuGeneratorService;
        _this.cryptService = cryptService;
        _this.modelDescriptionService = modelDescriptionService;
        _this.appConfigService = appConfigService;
        _this.apiService = apiService;
        _this.http = http;
        _this.injector = injector;
        _this.tokenRequired = true;
        return _this;
    }
    DatabaseGeneratorService.prototype.getParamsOptions = function (project, environment, resource) {
        if (!resource.params['bridge_settings']) {
            return of({
                deploy: resource.params['deploy'],
                url: resource.params['url'],
                region: resource.params['region']
            });
        }
        // TODO: Add JetBridge params class
        return this.cryptService.decrypt(project, environment, undefined, resource.params['bridge_settings']).pipe(map(function (result) {
            var dbSettings = JSON.parse(result);
            return {
                deploy: resource.params['deploy'],
                url: resource.params['url'],
                token: dbSettings['token'],
                project: dbSettings['project'],
                region: resource.params['region'],
                database_url: dbSettings['database_url'],
                database_host: dbSettings['database_host'],
                database_port: dbSettings['database_port'],
                database_name: dbSettings['database_name'],
                database_user: dbSettings['database_user'],
                database_password: dbSettings['database_password'],
                database_schema: dbSettings['database_schema'],
                database_timezone: dbSettings['database_timezone'],
                database_extra: dbSettings['database_extra'],
                database_only: dbSettings['database_only'],
                database_ssl_ca: dbSettings['database_ssl_ca'],
                database_ssl_cert: dbSettings['database_ssl_cert'],
                database_ssl_key: dbSettings['database_ssl_key'],
                database_ssh_host: dbSettings['database_ssh_host'],
                database_ssh_port: dbSettings['database_ssh_port'],
                database_ssh_user: dbSettings['database_ssh_user'],
                database_ssh_public_key: resource.params['database_ssh_public_key'],
                database_ssh_private_key: dbSettings['database_ssh_private_key']
            };
        }));
    };
    DatabaseGeneratorService.prototype.generateGeneralParams = function (project, environment, typeItem, options) {
        if (options.deploy == ResourceDeploy.Direct) {
            var engine = databaseResourcesEngines.find(function (item) { return item.name == typeItem.name; });
            var bridgeSettings = JSON.stringify({
                token: options.token,
                project: project.uniqueName,
                database_engine: engine ? engine.engines[0] : undefined,
                database_url: options.database_url,
                database_host: options.database_host,
                database_port: options.database_port,
                database_name: options.database_name,
                database_user: options.database_user,
                database_password: options.database_password,
                database_schema: options.database_schema,
                database_timezone: options.database_timezone,
                database_extra: options.database_extra,
                database_only: options.database_only,
                database_ssl_ca: options.database_ssl_ca,
                database_ssl_cert: options.database_ssl_cert,
                database_ssl_key: options.database_ssl_key,
                database_ssh_host: options.database_ssh_host,
                database_ssh_port: options.database_ssh_port,
                database_ssh_user: options.database_ssh_user,
                database_ssh_private_key: options.database_ssh_private_key
            });
            return this.cryptService.encrypt(project, environment, undefined, bridgeSettings).pipe(map(function (result) {
                return {
                    resourceToken: options.token,
                    resourceParams: {
                        deploy: options.deploy,
                        url: options.url,
                        region: options.region,
                        bridge_settings: result,
                        schema: options.database_schema,
                        database_ssh_public_key: options.database_ssh_public_key
                    }
                };
            }));
        }
        else {
            return of({
                resourceToken: options.token,
                resourceParams: {
                    deploy: options.deploy,
                    url: options.url
                }
            });
        }
    };
    DatabaseGeneratorService.prototype.generateParams = function (project, environment, typeItem, options) {
        var _this = this;
        return this.generateGeneralParams(project, environment, typeItem, options).pipe(switchMap(function (result) {
            var instance = new Resource();
            instance.type = typeItem.resourceType;
            instance.typeItem = typeItem;
            instance.deploy = options.deploy;
            instance.token = result.resourceToken;
            instance.params = result.resourceParams || {};
            return _this.modelDescriptionService.getFromResource(instance).pipe(tap(function (modelDescriptions) {
                if (!modelDescriptions) {
                    throw new ServerRequestError('Database structured failed to load');
                }
                if (typeItem.name != ResourceName.JetDatabase && !modelDescriptions.length) {
                    throw new ServerRequestError('Database does not have any valid Tables');
                }
            }), map(function (modelDescriptions) {
                return __assign({}, result, { modelDescriptions: modelDescriptions.map(function (item) {
                        item.resource = '{{resource}}';
                        return item.serialize();
                    }), resourceModelDescriptions: true });
            }));
        }));
    };
    DatabaseGeneratorService.prototype.createJetDatabase = function (project, environment, resource) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.nodeMethodURL('project_databases/create');
            var headers = new HttpHeaders();
            var data = {
                projectUniqueName: project.uniqueName,
                environmentUniqueName: environment.uniqueName,
                resourceUniqueName: resource.uniqueName
            };
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.post(url, data, { headers: headers });
        }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    DatabaseGeneratorService.prototype.getJetDatabaseSize = function (project, environment) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.nodeEnvironmentMethodURL(project.uniqueName, environment.uniqueName, 'project_databases/size');
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.get(url, { headers: headers });
        }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    return DatabaseGeneratorService;
}(ResourceGeneratorService));
export { DatabaseGeneratorService };
