var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Injector } from '@angular/core';
import { combineLatest, of, ReplaySubject } from 'rxjs';
import { delayWhen, map, switchMap, tap } from 'rxjs/operators';
import { ThinDialogPopupComponent } from '@common/dialog-popup';
import { PopupService } from '@common/popups';
import { ActionStore } from '@modules/action-queries';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { MenuSettingsStore } from '@modules/menu';
import { ModelDescriptionStore } from '@modules/model-queries';
import { ProjectSettingsService } from '@modules/project-settings';
import { isResourceTypeItemCompatible, isResourceTypeItemReplicable, JET_APP_RESOURCE, ProjectToken, ProjectTokenService, Resource, ResourceDeploy, ResourceService } from '@modules/projects';
import { ResourceEditController } from '@modules/projects-components';
import { RegionService } from '@modules/regions';
import { ResourceGeneratorResolver } from '@modules/resource-generators';
import { generateUUID, isSet } from '@shared';
import { ChooseResourcesComponent } from '../../components/choose-resources/choose-resources.component';
var SelectResourcesController = /** @class */ (function () {
    function SelectResourcesController(popupService, resourceEditController, modelDescriptionStore, actionStore, menuSettingsStore, resourceGeneratorResolver, projectTokenService, resourceService, regionService, projectSettingsService, analyticsService, injector) {
        this.popupService = popupService;
        this.resourceEditController = resourceEditController;
        this.modelDescriptionStore = modelDescriptionStore;
        this.actionStore = actionStore;
        this.menuSettingsStore = menuSettingsStore;
        this.resourceGeneratorResolver = resourceGeneratorResolver;
        this.projectTokenService = projectTokenService;
        this.resourceService = resourceService;
        this.regionService = regionService;
        this.projectSettingsService = projectSettingsService;
        this.analyticsService = analyticsService;
        this.injector = injector;
    }
    SelectResourcesController.prototype.selectTemplateResourcesIfNeeded = function (project, environment, template, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        var selectResources = template.usedResources.map(function (item) {
            return {
                type: item.type,
                typeItem: item.typeItem,
                token: item.token
            };
        });
        var autoCreated = template.usedResources.filter(function (item) { return isResourceTypeItemReplicable(item.typeItem) || isSet(item.options); }).length;
        var demoAvailable = autoCreated == template.usedResources.length;
        if (demoAvailable && options.useDemoResources) {
            return of({ resources: [], useDemo: true });
        }
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.Template.StartedToAddMultipleResource, {
            TemplateID: template.name
        });
        return this.selectResourcesIfNeeded(project, environment, selectResources, {
            demoAvailable: demoAvailable,
            analyticsSource: options.analyticsSource
        }).pipe(tap(function (result) {
            if (result.resources) {
                _this.analyticsService.sendSimpleEvent(AnalyticsEvent.Template.MultipleResourceSuccessfullyAdded, {
                    TemplateID: template.name
                });
            }
        }));
    };
    SelectResourcesController.prototype.selectResourcesIfNeeded = function (project, environment, selectResources, options) {
        if (options === void 0) { options = {}; }
        if (!selectResources.length) {
            return of({ resources: [] });
        }
        var selectedObs = new ReplaySubject();
        var resources = project
            .getEnvironmentResources(environment.uniqueName)
            .filter(function (item) { return !item.demo && item.uniqueName != JET_APP_RESOURCE; });
        // const selectResources = template.usedResources.filter(item => !item.options);
        // Ask user to create or select used resources
        if (selectResources.length == 1 &&
            !resources.find(function (item) { return isResourceTypeItemCompatible(item.typeItem, selectResources[0].typeItem); })) {
            // If only 1 resource is required to select and there is no such resource type in the project yet
            // Then open "Resource create" popup without "Select resource" popup
            var selectResource_1 = selectResources[0];
            this.analyticsService.sendSimpleEvent(AnalyticsEvent.Resource.ClickedCreateResource, {
                Source: options.analyticsSource,
                ResourceID: selectResource_1.typeItem.name
            });
            this.resourceEditController
                .createResource(selectResource_1.typeItem, {
                resourceNameEditing: true,
                analyticsSource: options.analyticsSource
            })
                .subscribe(function (result) {
                if (result.cancelled) {
                    selectedObs.next({ cancelled: true });
                }
                else {
                    selectedObs.next({
                        resources: [
                            __assign({}, selectResource_1, { resource: result.resource, created: true })
                        ]
                    });
                }
            });
        }
        else if (selectResources.length == 0) {
            // If only no resources are required to select
            selectedObs.next({ resources: [] });
        }
        else {
            // Otherwise open "Select resource" popup
            this.popupService.push({
                component: ChooseResourcesComponent,
                popupComponent: ThinDialogPopupComponent,
                inputs: {
                    project: project,
                    selectResources: selectResources,
                    demoAvailable: options.demoAvailable,
                    analyticsSource: options.analyticsSource
                },
                outputs: {
                    selected: [
                        function (result) {
                            selectedObs.next({ resources: result });
                        }
                    ],
                    demoSelected: [
                        function () {
                            selectedObs.next({ resources: [], useDemo: true });
                        }
                    ],
                    cancelled: [function () { return selectedObs.next({ cancelled: true }); }]
                },
                injector: this.injector
            });
        }
        return selectedObs;
    };
    SelectResourcesController.prototype.createTemplateReplicatedResource = function (project, environment, template, item, useResource) {
        var _this = this;
        if (useResource) {
            return this.projectSettingsService
                .saveResource(project, environment, useResource, false, {
                modelDescriptions: item.models
                    ? item.models.map(function (model) {
                        model.modelDescription.resource = useResource.uniqueName;
                        return model.modelDescription.serialize();
                    })
                    : undefined,
                replicateModelDescriptions: true,
                models: item.models
                    ? item.models.map(function (model) {
                        return {
                            model: model.modelDescription.model,
                            items: model.instances
                        };
                    })
                    : undefined
            }, false)
                .pipe(map(function (result) {
                return __assign({}, item, { resource: result });
            }));
        }
        else {
            var generator_1 = this.resourceGeneratorResolver.get(item.typeItem.name);
            var instance = new Resource();
            instance.uniqueName = Resource.generateUniqueName(item.typeItem);
            instance.name = item.typeItem.label;
            instance.type = item.typeItem.resourceType;
            instance.typeItem = item.typeItem;
            instance.deploy = ResourceDeploy.Direct;
            instance.token = generateUUID();
            return this.resourceService.create(project.uniqueName, environment.uniqueName, instance).pipe(switchMap(function (resource) {
                return generator_1.createJetDatabase(project, environment, resource).pipe(switchMap(function (jetDatabase) {
                    var address = jetDatabase.url.split(':', 2);
                    return _this.regionService.getDefaultJetBridge().pipe(map(function (jetBridge) {
                        return {
                            deploy: ResourceDeploy.Direct,
                            region: jetBridge.region ? jetBridge.region.uid : undefined,
                            url: jetBridge.url,
                            database_host: address[0],
                            database_port: address[1],
                            database_name: jetDatabase.databaseName,
                            database_user: jetDatabase.userName,
                            database_password: jetDatabase.password,
                            database_schema: resource.uniqueName,
                            token: resource.token
                        };
                    }), switchMap(function (options) {
                        return generator_1.generateParams(project, environment, resource.typeItem, options);
                    }), switchMap(function (resourceParams) {
                        return _this.projectSettingsService
                            .saveResource(project, environment, resource, true, __assign({}, resourceParams, { resourceParams: __assign({}, resourceParams.resourceParams, { template_stub_data: template.id }), modelDescriptions: item.models
                                ? item.models.map(function (model) {
                                    model.modelDescription.resource = resource.uniqueName;
                                    return model.modelDescription.serialize();
                                })
                                : undefined, replicateModelDescriptions: true, models: item.models
                                ? item.models.map(function (model) {
                                    return {
                                        model: model.modelDescription.model,
                                        items: model.instances
                                    };
                                })
                                : undefined }), false)
                            .pipe(map(function (result) {
                            return __assign({}, item, { resource: result });
                        }));
                    }));
                }));
            }));
        }
    };
    SelectResourcesController.prototype.createTemplateResourceWithOptions = function (project, environment, template, item) {
        var _this = this;
        var generator = this.resourceGeneratorResolver.get(item.typeItem.name);
        return of(generator.tokenRequired).pipe(switchMap(function (tokenRequired) {
            if (!tokenRequired) {
                return of(undefined);
            }
            var token = new ProjectToken();
            token.resourceType = item.typeItem.resourceType;
            token.resourceTypeItem = item.typeItem.name;
            token.activated = true;
            return _this.projectTokenService.create(project, token);
        }), switchMap(function (projectToken) {
            var options = __assign({}, item.options);
            if (projectToken) {
                options['token'] = projectToken.token;
            }
            return generator.generateParams(project, environment, item.typeItem, options);
        }), switchMap(function (result) {
            var resource = new Resource();
            resource.name = item.typeItem.label;
            resource.type = item.type;
            resource.typeItem = item.typeItem;
            resource.deploy = item.options['deploy'];
            return _this.projectSettingsService
                .saveResource(project, environment, resource, true, {
                resourceParams: __assign({}, (result.resourceParams || {}), { template_stub_data: template.id }),
                modelDescriptions: result.modelDescriptions,
                resourceModelDescriptions: result.resourceModelDescriptions,
                actionDescriptions: result.actionDescriptions,
                secretTokens: result.secretTokens,
                storages: result.storages,
                extraTokens: result.extraTokens,
                mergeExisting: true
            }, false)
                .pipe(map(function (instance) {
                return __assign({}, item, { resource: instance });
            }));
        }));
    };
    SelectResourcesController.prototype.createTemplateResources = function (project, environment, template, useExistingUsedResource) {
        var _this = this;
        return combineLatest(template.usedResources
            .map(function (item) {
            if (isResourceTypeItemReplicable(item.typeItem)) {
                return _this.createTemplateReplicatedResource(project, environment, template, item, useExistingUsedResource);
            }
            else if (item.options && _this.resourceGeneratorResolver.get(item.typeItem.name)) {
                return _this.createTemplateResourceWithOptions(project, environment, template, item);
            }
        })
            .filter(function (item) { return item != undefined; })).pipe(delayWhen(function () {
            return combineLatest(_this.modelDescriptionStore.getFirst(true), _this.actionStore.getFirst(true), _this.menuSettingsStore.getFirst(true));
        }));
    };
    return SelectResourcesController;
}());
export { SelectResourcesController };
