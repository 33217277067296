import { HttpHeaders } from '@angular/common/http';

export const HTTP_STATUS_CODES = {
  '0': 'Connection Failed',
  '200': 'OK',
  '201': 'Created',
  '202': 'Accepted',
  '203': 'Non-Authoritative Information',
  '204': 'No Content',
  '205': 'Reset Content',
  '206': 'Partial Content',
  '300': 'Multiple Choices',
  '301': 'Moved Permanently',
  '302': 'Found',
  '303': 'See Other',
  '304': 'Not Modified',
  '305': 'Use Proxy',
  '307': 'Temporary Redirect',
  '400': 'Bad Request',
  '401': 'Unauthorized',
  '402': 'Payment Required',
  '403': 'Forbidden',
  '404': 'Not Found',
  '405': 'Method Not Allowed',
  '406': 'Not Acceptable',
  '407': 'Proxy Authentication Required',
  '408': 'Request Timeout',
  '409': 'Conflict',
  '410': 'Gone',
  '411': 'Length Required',
  '412': 'Precondition Failed',
  '413': 'Request Entity Too Large',
  '414': 'Request-URI Too Long',
  '415': 'Unsupported Media Type',
  '416': 'Requested Range Not Satisfiable',
  '417': 'Expectation Failed',
  '500': 'Internal Server Error',
  '501': 'Not Implemented',
  '502': 'Bad Gateway',
  '503': 'Service Unavailable',
  '504': 'Gateway Timeout',
  '505': 'HTTP Version Not Supported'
};

export function getHttpHeadersAsObject(headers: HttpHeaders): Record<string, string> {
  if (!headers) {
    return {};
  }

  return headers.keys().reduce((acc, key) => {
    const value = headers.get(key);
    if (value !== null) {
      acc[key] = value;
    }
    return acc;
  }, {});
}

export function getHttpHeadersAsArray(headers: HttpHeaders): { name: string; value: string }[] {
  if (!headers) {
    return [];
  }

  return headers.keys().reduce((acc, key) => {
    const values = headers.getAll(key);
    if (values !== null) {
      acc.push(
        ...values.map(value => {
          return { name: key, value: value };
        })
      );
    }
    return acc;
  }, []);
}
