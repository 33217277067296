import { ChangeDetectorRef, EventEmitter, Injector, OnChanges, OnDestroy, OnInit } from '@angular/core';
import fromPairs from 'lodash/fromPairs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { NotificationService } from '@common/notifications';
import { ActionControllerService } from '@modules/action-queries';
import { UserActivityCountService, UserActivityType } from '@modules/activities';
import { TimelineController } from '@modules/activities-components';
import { CustomViewsStore } from '@modules/custom-views';
import { getModelAttributesByColumns, KanbanBoardSettings, ListItemViewContext, ViewContext, ViewContextElement } from '@modules/customize';
import { ListModelDescriptionDataSource } from '@modules/data-sources';
import { applyParamInput, applyParamInput$, FieldType, ParameterField } from '@modules/fields';
import { ITEM_OUTPUT } from '@modules/list';
import { ModelDescription } from '@modules/models';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { EMPTY, isSet } from '@shared';
var KanbanBoardItemComponent = /** @class */ (function () {
    function KanbanBoardItemComponent(currentProjectStore, currentEnvironmentStore, listItemViewContext, actionControllerService, customViewsStore, timelineController, userActivityCountService, notificationService, injector, cd) {
        this.currentProjectStore = currentProjectStore;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.listItemViewContext = listItemViewContext;
        this.actionControllerService = actionControllerService;
        this.customViewsStore = customViewsStore;
        this.timelineController = timelineController;
        this.userActivityCountService = userActivityCountService;
        this.notificationService = notificationService;
        this.injector = injector;
        this.cd = cd;
        this.visibleColumns = [];
        this.draggable = false;
        this.selected = false;
        this.theme = false;
        this.select = new EventEmitter();
        this.modelUpdated = new EventEmitter();
        this.settings$ = new BehaviorSubject(undefined);
        this.viewParameters = [];
        this.viewParams$ = of({});
        this.localContext = {};
        this.commentsConfigured = false;
    }
    KanbanBoardItemComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.settings$
            .pipe(filter(function (settings) { return !!settings; }), switchMap(function (settings) {
            if (!isSet(settings.cardCustomView)) {
                return of(undefined);
            }
            return _this.customViewsStore.getDetail(settings.cardCustomView);
        }), untilDestroyed(this))
            .subscribe(function (customView) {
            _this.customView = customView;
            _this.cd.markForCheck();
            _this.updateViewParameters();
            _this.updateViewParams();
        });
    };
    KanbanBoardItemComponent.prototype.ngOnDestroy = function () { };
    KanbanBoardItemComponent.prototype.ngOnChanges = function (changes) {
        var _a;
        if (changes.item) {
            // this.context.model = this.item.model;
            this.localContext = (_a = {},
                _a[ITEM_OUTPUT] = this.item.model.getAttributes(),
                _a);
            this.initComments();
        }
        if (changes.settings) {
            this.settings$.next(this.settings);
            this.updateViewParameters();
            this.initComments();
        }
        if (changes.settings || changes.item) {
            this.updateViewParams();
        }
    };
    KanbanBoardItemComponent.prototype.updateViewParameters = function () {
        if (!this.customView) {
            this.viewParameters = [];
            this.cd.markForCheck();
            return;
        }
        var columns = this.settings.dataSource ? this.settings.dataSource.columns : [];
        this.viewParameters = columns.map(function (item) {
            var parameter = new ParameterField();
            parameter.name = item.name;
            parameter.verboseName = item.verboseName;
            parameter.field = item.field;
            parameter.params = item.params;
            return parameter;
        });
        this.cd.markForCheck();
    };
    KanbanBoardItemComponent.prototype.updateViewParams = function () {
        var _this = this;
        if (!this.customView) {
            this.viewParams$ = of({});
            this.cd.markForCheck();
            return;
        }
        var columns = this.settings.dataSource ? this.settings.dataSource.columns : [];
        var modelParams = getModelAttributesByColumns(this.item.model, columns);
        this.viewParams$ = combineLatest(this.settings.cardCustomViewMappings.map(function (mapping) {
            var _a;
            if (mapping.sourceParameterInput) {
                return applyParamInput$(mapping.sourceParameterInput, {
                    context: _this.context,
                    contextElement: _this.contextElement,
                    localContext: (_a = {},
                        _a[ITEM_OUTPUT] = modelParams,
                        _a)
                }).pipe(map(function (value) {
                    return {
                        name: mapping.targetParameter,
                        value: value !== EMPTY ? value : undefined
                    };
                }));
            }
            else {
                var value = modelParams[mapping.sourceParameter];
                return of({
                    name: mapping.targetParameter,
                    value: value
                });
            }
        })).pipe(map(function (values) {
            return fromPairs(values.map(function (item) { return [item.name, item.value]; }));
        }));
        this.cd.markForCheck();
    };
    KanbanBoardItemComponent.prototype.open = function (e, element) {
        var _a;
        var select = !this.selected;
        this.contextElement.context.clickEvent = e;
        this.select.emit(this.item);
        if (this.settings.cardClickAction && select) {
            this.actionControllerService
                .execute(this.settings.cardClickAction, {
                context: this.contextElement.context,
                contextElement: this.contextElement,
                localContext: (_a = {},
                    _a[ITEM_OUTPUT] = this.item.model.getAttributes(),
                    _a),
                injector: this.injector,
                origin: element
            })
                .subscribe();
        }
    };
    KanbanBoardItemComponent.prototype.initComments = function () {
        var _a, _b;
        if (this.commentsSubscription) {
            this.commentsSubscription.unsubscribe();
        }
        if (!this.settings.commentsEnabled || !this.settings.commentsObjectType) {
            this.commentsConfigured = false;
            this.cd.markForCheck();
            this.updateCount();
            return;
        }
        var objectTypeField = { name: 'value', field: FieldType.Text };
        var objectTypeId = { name: 'value' };
        this.commentsObjectType = applyParamInput(this.settings.commentsObjectType, {
            context: this.context,
            contextElement: this.contextElement,
            localContext: (_a = {},
                _a[ITEM_OUTPUT] = this.item.model.getAttributes(),
                _a),
            // field: objectTypeField,
            defaultValue: undefined
        });
        if (!isSet(this.commentsObjectType)) {
            this.commentsConfigured = false;
            this.cd.markForCheck();
            return;
        }
        this.commentsObjectId = this.settings.commentsObjectId
            ? applyParamInput(this.settings.commentsObjectId, {
                context: this.context,
                contextElement: this.contextElement,
                localContext: (_b = {},
                    _b[ITEM_OUTPUT] = this.item.model.getAttributes(),
                    _b),
                // field: objectTypeId,
                defaultValue: undefined
            })
            : undefined;
        this.commentsConfigured = true;
        this.cd.markForCheck();
        this.updateCount();
        // this.commentsSubscription = this.context.outputs$
        //   .pipe(debounceTime(10), distinctUntilChanged(), untilDestroyed(this))
        //   .subscribe(() => {
        //     this.commentsObjectType = applyParamInput(
        //       this.settings.commentsObjectType,
        //       undefined,
        //       this.context,
        //       this.contextElement,
        //       undefined
        //     );
        //     this.commentsObjectId = this.settings.commentsObjectId
        //       ? applyParamInput(this.settings.commentsObjectId, undefined, this.context, this.contextElement, undefined)
        //       : undefined;
        //     this.cd.markForCheck();
        //   });
    };
    KanbanBoardItemComponent.prototype.updateCount = function () {
        var _this = this;
        if (this.commentsCountSubscription) {
            this.commentsCountSubscription.unsubscribe();
        }
        if (!this.commentsConfigured) {
            this.commentsCount = undefined;
            this.cd.markForCheck();
            return;
        }
        this.commentsCountSubscription = this.userActivityCountService
            .subscribeCountObjectId(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, UserActivityType.Message, this.commentsObjectType, this.commentsObjectId)
            .pipe(untilDestroyed(this))
            .subscribe(function (count) {
            _this.commentsCount = count;
            _this.cd.markForCheck();
        }, function () {
            _this.commentsCount = undefined;
            _this.cd.markForCheck();
        });
    };
    KanbanBoardItemComponent.prototype.openTimeline = function () {
        this.timelineController.open(this.commentsObjectType, this.commentsObjectId);
    };
    return KanbanBoardItemComponent;
}());
export { KanbanBoardItemComponent };
