var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import pickBy from 'lodash/pickBy';
import { ActionItem } from '@modules/actions';
import { ChartWidgetDataSource, DataSourceType } from '@modules/data-sources';
import { Input, InputValueType, parseFilterPath } from '@modules/fields';
import { fromLegacyModel } from '@modules/models';
import { ChartWidgetQuery, HttpQuery, QueryType, SqlQuery } from '@modules/queries';
// TODO: Refactor import
import { TextStyle } from '../../../../customize/data/text-style';
import { migrateModelDescriptionDataSource } from '../../../../customize/utils/migration';
import { WidgetType } from '../widget-type';
import { registerWidgetForType } from '../widgets';
import { Widget } from './widget';
// TODO: Remove
var ChartColumnFunc = /** @class */ (function () {
    function ChartColumnFunc() {
    }
    ChartColumnFunc.prototype.deserialize = function (data) {
        this.column = data['column'];
        this.func = data['func'];
        this.color = data['color'];
        this.name = data['name'];
        this.format = data['format'];
        return this;
    };
    ChartColumnFunc.prototype.serialize = function () {
        return {
            column: this.column,
            func: this.func,
            color: this.color,
            name: this.name,
            format: this.format
        };
    };
    return ChartColumnFunc;
}());
export { ChartColumnFunc };
export var ChartType;
(function (ChartType) {
    ChartType["Line"] = "line";
    ChartType["Bar"] = "bar";
    ChartType["BarHorizontal"] = "bar_horizontal";
    ChartType["StackedBar"] = "stacked_bar";
    ChartType["StackedBarHorizontal"] = "stacked_bar_horizontal";
    ChartType["Pie"] = "pie";
    ChartType["Doughnut"] = "doughnut";
    ChartType["Radar"] = "radar";
    ChartType["PolarArea"] = "polar_area";
    ChartType["Scatter"] = "scatter";
    ChartType["Bubble"] = "bubble";
})(ChartType || (ChartType = {}));
export var singleColorDatasetChartTypes = [
    ChartType.Bar,
    ChartType.BarHorizontal,
    ChartType.StackedBar,
    ChartType.StackedBarHorizontal,
    ChartType.Line,
    ChartType.Radar,
    ChartType.PolarArea,
    ChartType.Scatter,
    ChartType.Bubble
];
var ChartWidgetDataset = /** @class */ (function () {
    function ChartWidgetDataset() {
        this.color = '';
    }
    ChartWidgetDataset.prototype.deserialize = function (data) {
        this.color = data['color'];
        this.name = data['name'];
        this.format = data['format'];
        if (data['data_source']) {
            this.dataSource = new ChartWidgetDataSource().deserialize(data['data_source']);
        }
        else if (data['resource']) {
            // Backward compatibility
            this.dataSource = migrateModelDescriptionDataSource(ChartWidgetDataSource, ChartWidgetQuery, {
                resource: data['resource'],
                query: data['query'],
                parameters: data['parameters'],
                inputs: data['inputs'],
                columns: data['columns']
            });
            if (data['query']) {
                this.dataSource.xColumn = data['query']['x_column'];
                this.dataSource.xLookup = data['query']['x_lookup'];
                this.dataSource.xColumn2 = data['query']['x_column_2'];
                this.dataSource.xLookup2 = data['query']['x_lookup_2'];
                if (data['query']['y_func']) {
                    this.dataSource.yFunc = data['query']['y_func'];
                }
                else if (data['query']['simple_query'] && data['query']['simple_query']['y_func']) {
                    // Backward compatibility
                    this.dataSource.yFunc = data['query']['simple_query']['y_func'];
                }
                if (data['query']['y_column']) {
                    this.dataSource.yColumn = data['query']['y_column'];
                }
                else if (data['query']['simple_query'] && data['query']['simple_query']['y_column']) {
                    // Backward compatibility
                    this.dataSource.yColumn = data['query']['simple_query']['y_column'];
                }
            }
            // Backward compatibility
            if (data['x_column'] && this.dataSource.xColumn === undefined) {
                this.dataSource.xColumn = data['x_column'];
            }
            // Backward compatibility
            if (data['x_lookup'] && this.dataSource.xLookup === undefined) {
                this.dataSource.xLookup = data['x_lookup'];
            }
            // Backward compatibility
            if (data['y_column'] && this.dataSource.yColumn === undefined) {
                this.dataSource.yColumn = data['y_column'];
            }
        }
        return this;
    };
    ChartWidgetDataset.prototype.serialize = function () {
        return {
            data_source: this.dataSource ? this.dataSource.serialize() : undefined,
            color: this.color,
            name: this.name,
            format: this.format
        };
    };
    ChartWidgetDataset.prototype.isConfigured = function () {
        return this.dataSource && this.dataSource.isConfigured();
    };
    return ChartWidgetDataset;
}());
export { ChartWidgetDataset };
var ChartWidgetGroup = /** @class */ (function () {
    function ChartWidgetGroup() {
    }
    ChartWidgetGroup.prototype.deserialize = function (data) {
        this.value = data['value'];
        this.name = data['name'];
        this.color = data['color'];
        return this;
    };
    ChartWidgetGroup.prototype.serialize = function (fields) {
        var data = {
            value: this.value,
            name: this.name,
            color: this.color
        };
        if (fields) {
            data = pickBy(data, function (v, k) { return fields.includes(k); });
        }
        return data;
    };
    return ChartWidgetGroup;
}());
export { ChartWidgetGroup };
var ChartWidget = /** @class */ (function (_super) {
    __extends(ChartWidget, _super);
    function ChartWidget() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.type = WidgetType.Chart;
        _this.datasets = [];
        _this.groups = [];
        _this.chartType = ChartType.Bar;
        _this.datasetColumnEnabled = false;
        _this.customQueryXColumn = true;
        _this.customDateRange = true;
        _this.showReload = true;
        _this.legend = true;
        _this.percentage = false;
        _this.smooth = false;
        return _this;
    }
    ChartWidget.prototype.deserialize = function (data) {
        var _this = this;
        _super.prototype.deserialize.call(this, data);
        this.chartType = this.params['chart_type'];
        this.datasetColumnEnabled = this.params['dataset_column_enabled'];
        this.datasetColumn = this.params['dataset_column'];
        this.yFormat = this.params['y_format'];
        this.min = this.params['min'];
        this.max = this.params['max'];
        if (this.params['title_style']) {
            this.titleStyle = new TextStyle().deserialize(this.params['title_style']);
        }
        else {
            this.titleStyle = undefined;
        }
        if (this.params.hasOwnProperty('custom_query_x_column')) {
            this.customQueryXColumn = this.params['custom_query_x_column'];
        }
        if (this.params.hasOwnProperty('custom_date_range')) {
            this.customDateRange = this.params['custom_date_range'];
        }
        if (this.params.hasOwnProperty('show_reload')) {
            this.showReload = this.params['show_reload'];
        }
        if (this.params.hasOwnProperty('legend')) {
            this.legend = this.params['legend'];
        }
        if (this.params.hasOwnProperty('percentage')) {
            this.percentage = this.params['percentage'];
        }
        if (this.params.hasOwnProperty('smooth')) {
            this.smooth = this.params['smooth'];
        }
        if (this.params['datasets']) {
            this.datasets = this.params['datasets'].map(function (item) { return new ChartWidgetDataset().deserialize(item); });
        }
        else if (this.params['y_columns']) {
            // Backward compatibility
            this.datasets = this.params['y_columns']
                .map(function (item) { return new ChartColumnFunc().deserialize(item); })
                .map(function (item) {
                var query = new ChartWidgetQuery();
                var dataset = new ChartWidgetDataset();
                if (_this.params['http_query']) {
                    query.queryType = QueryType.Http;
                    query.httpQuery = new HttpQuery().deserialize(_this.params['http_query']);
                }
                else if (_this.params['custom_sql']) {
                    query.queryType = QueryType.SQL;
                    query.sqlQuery = new SqlQuery();
                    query.sqlQuery.query = _this.params['custom_sql'];
                }
                else {
                    query.queryType = QueryType.Simple;
                    query.simpleQuery = new query.simpleQueryClass();
                    query.simpleQuery.model = fromLegacyModel(_this.params['model']);
                }
                dataset.dataSource = new ChartWidgetDataSource();
                dataset.dataSource.type = DataSourceType.Query;
                dataset.dataSource.queryResource = 'jet_bridge';
                dataset.dataSource.query = query;
                if (_this.params['x_lookup']) {
                    dataset.dataSource.xLookup = _this.params['x_lookup'];
                }
                dataset.dataSource.yFunc = item.func;
                dataset.color = item.color;
                dataset.name = item.name;
                dataset.format = item.format;
                return dataset;
            });
        }
        // Backward compatibility
        if (this.params['filter_items']) {
            this.datasets
                .filter(function (dataset) { return dataset.dataSource; })
                .forEach(function (dataset) {
                dataset.dataSource.queryInputs = _this.params['filter_items'].map(function (item) {
                    var result = new Input();
                    result.path = parseFilterPath(item['field']);
                    result.lookup = item['lookup'];
                    result.exclude = item['exclude'];
                    result.valueType = InputValueType.StaticValue;
                    result.staticValue = item['value'];
                    return result;
                });
            });
        }
        if (this.params['groups']) {
            this.groups = this.params['groups'].map(function (item) { return new ChartWidgetGroup().deserialize(item); });
        }
        if (this.params['click_action']) {
            this.clickAction = new ActionItem().deserialize(this.params['click_action']);
        }
        else {
            this.clickAction = undefined;
        }
        return this;
    };
    ChartWidget.prototype.serialize = function (fields) {
        var data = _super.prototype.serialize.call(this, fields);
        data['params'] = JSON.stringify({
            name_input: this.nameInput ? this.nameInput.serialize() : null,
            title_style: this.titleStyle ? this.titleStyle.serialize() : undefined,
            chart_type: this.chartType,
            dataset_column_enabled: this.datasetColumnEnabled,
            dataset_column: this.datasetColumn,
            datasets: this.datasets.map(function (item) { return item.serialize(); }),
            groups: this.groups.map(function (item) { return item.serialize(); }),
            y_format: this.yFormat,
            min: this.min,
            max: this.max,
            custom_query_x_column: this.customQueryXColumn,
            custom_date_range: this.customDateRange,
            show_reload: this.showReload,
            legend: this.legend,
            smooth: this.smooth,
            percentage: this.percentage,
            click_action: this.clickAction ? this.clickAction.serialize() : undefined
        });
        if (fields) {
            data = pickBy(data, function (v, k) { return fields.includes(k); });
        }
        return data;
    };
    Object.defineProperty(ChartWidget.prototype, "analyticsName", {
        get: function () {
            if (this.chartType == ChartType.Line) {
                return 'chart_line';
            }
            else if (this.chartType == ChartType.Bar) {
                return 'chart_bar';
            }
            else if (this.chartType == ChartType.Pie) {
                return 'chart_pie';
            }
            else if (this.chartType == ChartType.Doughnut) {
                return 'chart_doughnut';
            }
            else if (this.chartType == ChartType.Radar) {
                return 'chart_radar';
            }
            else if (this.chartType == ChartType.PolarArea) {
                return 'chart_polar_area';
            }
            else if (this.chartType == ChartType.Scatter) {
                return 'chart_scatter';
            }
            else {
                return 'chart';
            }
        },
        enumerable: true,
        configurable: true
    });
    return ChartWidget;
}(Widget));
export { ChartWidget };
registerWidgetForType(WidgetType.Chart, ChartWidget);
