import { InjectionToken } from '@angular/core';

import { ActionOutput, ParameterField } from '@modules/fields';
import { View } from '@modules/views';

export interface CustomViewComponentInputs {
  view?: View;
  parameters?: ParameterField[];
  actionOutputs?: ActionOutput[];
  params?: Record<string, unknown>;
}

export const CUSTOM_VIEW_COMPONENT = new InjectionToken<any>('CUSTOM_VIEW_COMPONENT');
export const CUSTOM_VIEW_COMPONENT_INPUTS = new InjectionToken<CustomViewComponentInputs>(
  'CUSTOM_VIEW_COMPONENT_INPUT'
);
