var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { AdminMode } from '@modules/admin-mode';
import { CurrentEnvironmentStore } from '@modules/projects';
import { isSet, SingletonStore } from '@shared';
import { CurrentProjectStore } from '../../projects/stores/current-project.store';
import { CustomViewType } from '../data/custom-view';
import { CustomViewService } from '../services/custom-view/custom-view.service';
var CustomViewsStore = /** @class */ (function (_super) {
    __extends(CustomViewsStore, _super);
    function CustomViewsStore(mode$, customViewService, currentProjectStore, currentEnvironmentStore) {
        var _this = _super.call(this) || this;
        _this.mode$ = mode$;
        _this.customViewService = customViewService;
        _this.currentProjectStore = currentProjectStore;
        _this.currentEnvironmentStore = currentEnvironmentStore;
        return _this;
    }
    CustomViewsStore.prototype.fetch = function () {
        var _this = this;
        return this.mode$.pipe(switchMap(function (mode) {
            return _this.currentProjectStore.getFirst().pipe(filter(function (project) { return project != undefined; }), switchMap(function (project) {
                return _this.customViewService.get(_this.currentProjectStore.instance.uniqueName, _this.currentEnvironmentStore.instance.uniqueName, {}, mode == AdminMode.Builder);
            }), map(function (result) {
                if (!result) {
                    return [];
                }
                return result;
            }));
        }));
    };
    CustomViewsStore.prototype.get = function (forceUpdate, options) {
        if (forceUpdate === void 0) { forceUpdate = false; }
        if (options === void 0) { options = {}; }
        return _super.prototype.get.call(this, forceUpdate, options).pipe(map(function (result) {
            if (!result) {
                return result;
            }
            return result.filter(function (item) { return options.includeDeleted || !item.deleted; });
        }));
    };
    CustomViewsStore.prototype.getFirst = function (forceUpdate, options) {
        if (forceUpdate === void 0) { forceUpdate = false; }
        if (options === void 0) { options = {}; }
        return _super.prototype.getFirst.call(this, forceUpdate, options).pipe(map(function (result) {
            if (!result) {
                return result;
            }
            return result.filter(function (item) { return options.includeDeleted || !item.deleted; });
        }));
    };
    CustomViewsStore.prototype.getDetail = function (uniqueName, forceUpdate, options) {
        if (forceUpdate === void 0) { forceUpdate = false; }
        if (options === void 0) { options = {}; }
        return this.get(forceUpdate).pipe(map(function (result) {
            if (!result) {
                return;
            }
            return result.find(function (item) { return item.uniqueName == uniqueName; });
        }));
    };
    CustomViewsStore.prototype.getCommon = function (forceUpdate, options) {
        if (forceUpdate === void 0) { forceUpdate = false; }
        if (options === void 0) { options = {}; }
        return this.get(forceUpdate, options).pipe(map(function (result) {
            if (!result) {
                return [];
            }
            return result.filter(function (item) { return item.viewType == CustomViewType.Common; });
        }));
    };
    CustomViewsStore.prototype.getModelDescription = function (modelDescription, forceUpdate, options) {
        if (forceUpdate === void 0) { forceUpdate = false; }
        if (options === void 0) { options = {}; }
        return this.get(forceUpdate, options).pipe(map(function (result) {
            if (!result) {
                return [];
            }
            return result.filter(function (item) {
                return item.viewType == CustomViewType.ModelDescription &&
                    (!item.params['model_description'] || item.params['model_description'] == modelDescription);
            });
        }));
    };
    CustomViewsStore.prototype.getModel = function (modelDescription, forceUpdate, options) {
        if (forceUpdate === void 0) { forceUpdate = false; }
        if (options === void 0) { options = {}; }
        return this.get(forceUpdate, options).pipe(map(function (result) {
            if (!result) {
                return [];
            }
            return result.filter(function (item) {
                return item.viewType == CustomViewType.Model &&
                    (!item.params['model_description'] || item.params['model_description'] == modelDescription);
            });
        }));
    };
    CustomViewsStore.prototype.getDetailFirst = function (uniqueName, forceUpdate, options) {
        if (forceUpdate === void 0) { forceUpdate = false; }
        if (options === void 0) { options = {}; }
        return this.getFirst(forceUpdate, options).pipe(map(function (result) {
            if (!result) {
                return;
            }
            return result.find(function (item) { return item.uniqueName == uniqueName; });
        }));
    };
    CustomViewsStore.prototype.addItem = function (customView) {
        this.instance = this.instance.concat([customView]);
    };
    CustomViewsStore.prototype.updateItem = function (customView) {
        var index = this.instance.findIndex(function (item) { return item.uniqueName == customView.uniqueName; });
        if (index !== -1) {
            this.instance = this.instance.slice(0, index).concat([customView], this.instance.slice(index + 1));
        }
    };
    CustomViewsStore.prototype.updateOrAddItem = function (customView) {
        var index = this.instance.findIndex(function (item) { return item.uniqueName == customView.uniqueName; });
        if (index !== -1) {
            this.instance = this.instance.slice(0, index).concat([customView], this.instance.slice(index + 1));
        }
        else {
            this.addItem(customView);
        }
    };
    CustomViewsStore.prototype.deleteItem = function (customView) {
        var index = this.instance.findIndex(function (item) { return item.uniqueName == customView.uniqueName; });
        if (index !== -1) {
            this.instance = this.instance.filter(function (item, i) { return i !== index; });
        }
    };
    CustomViewsStore.prototype.generateName = function (defaultName) {
        if (defaultName === void 0) { defaultName = 'New Component'; }
        return this.getFirst().pipe(map(function (customViews) {
            var names = (customViews || []).reduce(function (acc, item) {
                if (isSet(item.name)) {
                    acc[item.name.toLowerCase()] = true;
                }
                return acc;
            }, {});
            var i = 1;
            var newName;
            do {
                newName = i > 1 ? [defaultName, i].join(' ') : defaultName;
                ++i;
            } while (names.hasOwnProperty(newName.toLowerCase()));
            return newName;
        }));
    };
    return CustomViewsStore;
}(SingletonStore));
export { CustomViewsStore };
