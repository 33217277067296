import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { TweenMax } from 'gsap';
import { CameraDevice, Html5Qrcode } from 'html5-qrcode';
import clamp from 'lodash/clamp';
import * as moment from 'moment';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { from, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, delay, filter, switchMap, tap } from 'rxjs/operators';

import { localize } from '@common/localize';
import { NotificationService } from '@common/notifications';
import { UniqueIdToken } from '@common/unique-id';
import { elementResize$, errorToString, TypedChanges } from '@shared';

export interface ScanResult {
  text?: string;
  error?: string;
}

@Component({
  selector: 'app-scanner',
  templateUrl: './scanner.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScannerComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() scanningInitial = false;
  @Input() scanConfirm = false;
  @Input() theme = false;
  @Output() scan = new EventEmitter<ScanResult>();

  @ViewChild('scanner') scannerElement: ElementRef;

  idToken = new UniqueIdToken();
  scanner: Html5Qrcode;
  scanning = false;
  scanningInitialLoading = false;
  scanningImage: File;
  scanningImageError: string;
  scanningCamera: string;
  scanningLoading = false;
  scanOngoing$ = new Subject<string>();
  scanConfirmValue: string;
  scanConfirmImage: string;
  cameraDropdownOpened = false;
  cameras: CameraDevice[] = [];
  camerasLoading = false;
  draggingOver = false;

  constructor(
    private el: ElementRef,
    private notificationService: NotificationService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    if (this.scanningInitial) {
      this.scanningInitialLoading = true;
      this.cd.markForCheck();
    }
  }

  ngOnDestroy(): void {
    if (this.scanning) {
      this.stopScanning$().subscribe();
    }
  }

  ngOnChanges(changes: TypedChanges<ScannerComponent>): void {
    if (changes.scanningInitial && !changes.scanningInitial.firstChange) {
      this.initScanningInitial();
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.initScanning();
    }, 0);
  }

  getCameras(): Observable<CameraDevice[]> {
    this.camerasLoading = true;
    this.cd.markForCheck();

    return from(Html5Qrcode.getCameras()).pipe(
      tap(devices => {
        this.camerasLoading = false;
        this.cd.markForCheck();

        if (!devices.length) {
          this.notificationService.error('No cameras', 'Make sure you have correctly configured camera on your device');
        }
      }),
      catchError(error => {
        this.camerasLoading = false;
        this.cd.markForCheck();

        this.notificationService.error('Loading cameras failed', errorToString(error));

        return throwError(error);
      })
    );
  }

  openCameraDropdown() {
    this.getCameras()
      .pipe(untilDestroyed(this))
      .subscribe(
        devices => {
          this.cameras = devices;
          this.cd.markForCheck();

          if (devices.length) {
            this.cameraDropdownOpened = true;
            this.cd.markForCheck();
          }
        },
        error => {
          console.error(error);
        }
      );
  }

  setCameraDropdownOpened(value: boolean) {
    this.cameraDropdownOpened = value;
    this.cd.markForCheck();
  }

  initScanning() {
    const element = this.scannerElement.nativeElement;
    const elementId = element.id;

    this.scanner = new Html5Qrcode(elementId);

    this.initValueEmit();
    this.initResize();
    this.initScanningInitial();
  }

  selectCamera(cameraId: string) {
    if (this.scanningCamera == cameraId) {
      return;
    }

    this.startScanning$(cameraId).pipe(untilDestroyed(this)).subscribe();
  }

  initScanningInitial() {
    if (this.scanningInitial && !this.scanning) {
      this.scanningInitialLoading = true;
      this.cd.markForCheck();

      this.getCameras()
        .pipe(untilDestroyed(this))
        .subscribe(
          result => {
            if (result.length) {
              this.selectCamera(result[0].id);
            }

            this.scanningInitialLoading = false;
            this.cd.markForCheck();
          },
          () => {
            this.scanningInitialLoading = false;
            this.cd.markForCheck();
          }
        );
    }
  }

  initValueEmit() {
    let lastScanValue: string;
    let lastScanDate: moment.Moment;

    this.scanOngoing$
      .pipe(
        filter(value => {
          if (value !== lastScanValue) {
            return true;
          } else {
            return !lastScanDate || moment().diff(lastScanDate, 'seconds') >= 3;
          }
        }),
        untilDestroyed(this)
      )
      .subscribe(value => {
        lastScanValue = value;
        lastScanDate = moment();

        this.scan.emit({ text: value });
      });
  }

  initResize() {
    const element = this.scannerElement.nativeElement;

    elementResize$(this.scannerElement.nativeElement, false)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        const video = element.querySelector('video');
        const canvas = element.querySelector('canvas');
        const qrBox = element.querySelector('#qr-shaded-region');

        if (video) {
          TweenMax.set(video, {
            width: element.offsetWidth,
            height: element.offsetHeight
          });
        }

        if (canvas) {
          canvas.setAttribute('width', element.offsetWidth);
          canvas.setAttribute('height', element.offsetHeight);
          TweenMax.set(canvas, {
            width: element.offsetWidth,
            height: element.offsetHeight
          });
        }

        if (qrBox) {
          const qrBoxSize = this.getQrBoxSize(element.offsetWidth, element.offsetHeight);
          const borderWidthHorizontal = (element.offsetWidth - qrBoxSize.width) * 0.5;
          const borderWidthVertical = (element.offsetHeight - qrBoxSize.height) * 0.5;

          TweenMax.set(qrBox, {
            borderWidth: `${borderWidthVertical}px ${borderWidthHorizontal}px`
          });
        }

        try {
          this.scanner.applyVideoConstraints({ width: element.offsetWidth, height: element.offsetHeight });
        } catch (e) {}
      });
  }

  getQrBoxSize(viewportWidth: number, viewportHeight: number): { width: number; height: number } {
    const width = clamp(viewportWidth - (50 + 5) * 2, 40, 400);
    const height = clamp(viewportHeight - 30 * 2, 40, 400);
    const size = Math.min(width, height);

    return { width: size, height: size };
  }

  startScanning$(cameraId: string): Observable<void> {
    const stop$ = this.scanning
      ? this.stopScanning$().pipe(
          // Workaround for start after stop error
          delay(1000)
        )
      : of(undefined);

    this.scanningImage = undefined;
    this.scanningImageError = undefined;
    this.scanningLoading = true;
    this.cd.markForCheck();

    return stop$.pipe(
      switchMap(() => {
        const element = this.scannerElement.nativeElement;

        this.scanning = true;
        this.scanningCamera = cameraId;
        this.cd.markForCheck();

        return from(
          this.scanner.start(
            cameraId,
            {
              fps: 10,
              videoConstraints: {
                deviceId: cameraId,
                width: element.offsetWidth,
                height: element.offsetHeight
              },
              qrbox: (viewportWidth, viewportHeight) => {
                return this.getQrBoxSize(viewportWidth, viewportHeight);
              }
            },
            (decodedText, decodedResult) => {
              if (this.scanConfirm && !this.scanConfirmImage) {
                const video = element.querySelector('video');
                const canvas = element.querySelector('canvas');
                if (video && canvas) {
                  canvas.width = video.videoWidth;
                  canvas.height = video.videoHeight;

                  canvas.getContext('2d').drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

                  this.scanConfirmValue = decodedText;
                  this.scanConfirmImage = canvas.toDataURL('image/png');
                  this.cd.markForCheck();
                }
              } else if (!this.scanConfirm) {
                this.scanOngoing$.next(decodedText);
              }
            },
            () => {
              // parse error, ignore it.
            }
          )
        );
      }),
      // Workaround for stop after start error
      delay(1000),
      tap(() => {
        this.scanningLoading = false;
        this.cd.markForCheck();
      }),
      catchError(error => {
        this.scanning = false;
        this.scanningCamera = undefined;
        this.scanningLoading = false;
        this.cd.markForCheck();
        this.cd.markForCheck();

        return throwError(error);
      })
    );
  }

  startScanning(cameraId: string) {
    this.startScanning$(cameraId).pipe(untilDestroyed(this)).subscribe();
  }

  stopScanning$(): Observable<void> {
    if (!this.scanning) {
      return of(undefined);
    }

    return from(this.scanner.stop()).pipe(
      tap(() => {
        this.scanning = false;
        this.scanningCamera = undefined;
        this.cd.markForCheck();
      })
    );
  }

  stopScanning() {
    this.stopScanning$().pipe(untilDestroyed(this)).subscribe();
  }

  onFileChange(el: HTMLInputElement) {
    if (!el.files.length) {
      return;
    }

    const file = el.files[0];
    el.value = null;
    this.scanImage(file);
  }

  scanImage(file: File) {
    this.stopScanning$()
      .pipe(
        switchMap(() => {
          this.scanningImage = file;
          this.scanningImageError = undefined;
          this.cd.markForCheck();

          return from(this.scanner.scanFileV2(file, true));
        }),
        untilDestroyed(this)
      )
      .subscribe(
        result => {
          this.scan.emit({ text: result.decodedText });
        },
        error => {
          const errorMessage = error.message || String(error);

          this.scanningImageError = localize('Code failed to scan, please try another image');
          this.cd.markForCheck();

          this.scan.emit({ error: errorMessage });

          this.notificationService.error(
            localize('Failed to scan'),
            localize('Code failed to scan, please try another image')
          );
        }
      );
  }

  onDragOver(e: DragEvent) {
    e.stopPropagation();
    e.preventDefault();

    this.draggingOver = true;
    this.cd.markForCheck();
  }

  onDragLeave(e: DragEvent) {
    e.stopPropagation();
    e.preventDefault();

    this.draggingOver = false;
    this.cd.markForCheck();
  }

  onDrop(e: DragEvent) {
    e.stopPropagation();
    e.preventDefault();

    if (!e.dataTransfer.files.length) {
      return;
    }

    const file = e.dataTransfer.files[0];

    this.draggingOver = false;
    this.cd.markForCheck();

    if (file) {
      this.scanImage(file);
    }
  }

  scanConfirmSubmit() {
    this.scan.emit({ text: this.scanConfirmValue });

    this.scanConfirmValue = undefined;
    this.scanConfirmImage = undefined;
    this.cd.markForCheck();
  }

  scanConfirmCancel() {
    this.scanConfirmValue = undefined;
    this.scanConfirmImage = undefined;
    this.cd.markForCheck();
  }
}
