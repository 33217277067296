import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import toPairs from 'lodash/toPairs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable, of } from 'rxjs';
import { delayWhen, filter, map, switchMap, tap } from 'rxjs/operators';

import { AppDrag } from '@common/drag-drop2';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { CustomizeBarItem } from '@modules/change-components';
import {
  CustomView,
  CustomViewData,
  customViewDataToInstance,
  CustomViewService,
  CustomViewSource,
  CustomViewsStore,
  CustomViewType
} from '@modules/custom-views';
import { CustomElementItem, CustomizeService, ElementType, ViewContext } from '@modules/customize';
import { Input as FieldInput, InputValueType } from '@modules/fields';
import { IDEController } from '@modules/ide-components';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { CustomViewTemplate, CustomViewTemplateCounterStore, Frame, View } from '@modules/views';
import {
  CustomViewTemplatesController,
  ImportFigmaNodeController,
  ImportSketchFileController,
  ViewEditorController
} from '@modules/views-components';

import { CustomViewDefaults } from '../../../../customize-bar/data/custom-view-defaults';

@Component({
  selector: 'app-change-customize-bar-custom-views',
  templateUrl: './change-customize-bar-custom-views.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangeCustomizeBarCustomViewsComponent implements OnInit, OnDestroy {
  @Input() context: ViewContext;
  @Input() analyticsSource: string;

  templatesApprox: number;
  customViewsShared: CustomView[] = [];
  createLoading = false;

  constructor(
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private viewEditorController: ViewEditorController,
    private ideController: IDEController,
    private customViewService: CustomViewService,
    private customViewsStore: CustomViewsStore,
    private customViewTemplateCounterStore: CustomViewTemplateCounterStore,
    private customViewTemplatesController: CustomViewTemplatesController,
    private importFigmaNodeController: ImportFigmaNodeController,
    private importSketchFileController: ImportSketchFileController,
    private customizeService: CustomizeService,
    private cd: ChangeDetectorRef,
    private analyticsService: UniversalAnalyticsService
  ) {}

  ngOnInit() {
    this.customViewTemplateCounterStore
      .getApproxFirst$()
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        this.templatesApprox = value;
        this.cd.markForCheck();
      });

    this.customViewsStore
      .get()
      .pipe(untilDestroyed(this))
      .subscribe(customViews => {
        this.customViewsShared = customViews.filter(item => item.shared);
        this.cd.markForCheck();
      });
  }

  ngOnDestroy(): void {}

  isDroppable(item: AppDrag<CustomizeBarItem>): boolean {
    return false;
  }

  getCustomViewDefaults(element?: CustomElementItem): CustomViewDefaults {
    return {
      uniqueName: CustomView.generateUniqueName(),
      pageUid: this.context && this.context.viewSettings ? this.context.viewSettings.uid : undefined,
      elementUid: element ? element.uid : undefined
    };
  }

  createCustomView(data: CustomViewData): Observable<CustomView> {
    const instance = customViewDataToInstance(data);
    const defaults = this.getCustomViewDefaults();

    instance.uniqueName = defaults.uniqueName;
    instance.viewType = CustomViewType.Component;
    instance.source = data.source;
    instance.pageUid = defaults.pageUid;
    instance.elementUid = defaults.elementUid;

    if (data.source == CustomViewSource.CustomElement) {
      instance.filesIde = true;
    }

    const fields = ['unique_name', 'name', 'view_type', 'source', 'view', 'html', 'params'];

    return this.customViewService
      .create(
        this.currentProjectStore.instance.uniqueName,
        this.currentEnvironmentStore.instance.uniqueName,
        instance,
        { draft: true, fields: fields }
      )
      .pipe(tap(customView => this.customViewsStore.updateOrAddItem(customView)));
  }

  createdViewItem(data: CustomViewData, template?: CustomViewTemplate): Observable<CustomizeBarItem> {
    return this.createCustomView(data).pipe(map(customView => this.createBarItem(customView, template)));
  }

  getCustomViewTestParameters(testParameters: Record<string, unknown>): FieldInput[] {
    return toPairs(testParameters).map(([name, value]) => {
      const result = new FieldInput();

      result.path = [name];
      result.valueType = InputValueType.StaticValue;
      result.staticValue = value;

      return result;
    });
  }

  createBarItem(customView: CustomView, template?: CustomViewTemplate): CustomizeBarItem {
    const inputs =
      template && template.view
        ? this.getCustomViewTestParameters(template.testParameters)
        : this.getCustomViewTestParameters(customView.testParameters);

    return {
      title: customView.name,
      image: 'canvas',
      purpleGradient: true,
      action: 'Add Custom',
      type: ElementType.Custom,
      defaultParams: {
        ...(customView.view && {
          width: customView.view.frame.width + 20 * 2,
          height: customView.view.frame.height + 15 * 2
        }),
        source: customView.source,
        custom_view: customView.uniqueName,
        parameters: customView.parameters.map(item => item.serialize()),
        inputs: inputs.map(item => item.serialize())
      }
    };
  }

  importFigmaNode() {
    this.analyticsService.sendSimpleEvent(AnalyticsEvent.ViewEditor.ImportFigmaOpened, {
      Source: this.analyticsSource
    });

    this.importFigmaNodeController
      .importNode()
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        if (result.view) {
          this.createCustomViewWithViewEditor(result.view);

          this.analyticsService.sendSimpleEvent(AnalyticsEvent.ViewEditor.ImportFigmaApplied, {
            Source: this.analyticsSource
          });
        } else if (result.cancelled) {
          this.analyticsService.sendSimpleEvent(AnalyticsEvent.ViewEditor.ImportFigmaCancelled, {
            Source: this.analyticsSource
          });
        }
      });
  }

  importSketchFile() {
    this.analyticsService.sendSimpleEvent(AnalyticsEvent.ViewEditor.ImportSketchOpened, {
      Source: this.analyticsSource
    });

    this.importSketchFileController
      .importFile()
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        if (result.view) {
          this.createCustomViewWithViewEditor(result.view);

          this.analyticsService.sendSimpleEvent(AnalyticsEvent.ViewEditor.ImportSketchApplied, {
            Source: this.analyticsSource
          });
        } else if (result.cancelled) {
          this.analyticsService.sendSimpleEvent(AnalyticsEvent.ViewEditor.ImportSketchCancelled, {
            Source: this.analyticsSource
          });
        }
      });
  }

  createSharedView(customView: CustomView) {
    const item = this.createBarItem(customView);
    this.customizeService.createElement(item);
  }

  createCustomViewWithViewEditor(view?: View) {
    if (!view) {
      view = new View();

      view.generateId();
      view.frame = new Frame({ width: 300, height: 240 });
    }

    return this.customViewsStore
      .generateName()
      .pipe(
        switchMap(name => {
          return this.viewEditorController.open({
            create: true,
            data: {
              source: CustomViewSource.View,
              view: view,
              name: name
            },
            submitLabel: 'Create component',
            analyticsSource: this.analyticsSource
          });
        }),
        filter(result => !result.cancelled),
        switchMap(result => {
          if (result.data) {
            this.createLoading = true;
            this.cd.markForCheck();

            return this.createdViewItem(result.data);
          } else if (result.useShared) {
            return of(this.createBarItem(result.useShared));
          } else {
            return of(undefined);
          }
        }),
        untilDestroyed(this)
      )
      .subscribe(
        item => {
          this.createLoading = false;
          this.cd.markForCheck();

          if (item) {
            this.customizeService.createElement(item);
          }
        },
        () => {
          this.createLoading = false;
          this.cd.markForCheck();
        }
      );
  }

  createCustomViewWithIDE() {
    this.createLoading = true;
    this.cd.markForCheck();

    return this.customViewsStore
      .generateName()
      .pipe(
        switchMap(name => this.createdViewItem({ source: CustomViewSource.CustomElement, name: name })),
        delayWhen(item => {
          return this.ideController.open({
            projectName: this.currentProjectStore.instance.uniqueName,
            environmentName: this.currentEnvironmentStore.instance.uniqueName,
            customViewName: item.defaultParams['custom_view']
          });
        }),
        untilDestroyed(this)
      )
      .subscribe(
        item => {
          this.createLoading = false;
          this.cd.markForCheck();

          if (item) {
            this.customizeService.createElement(item);
          }
        },
        () => {
          this.createLoading = false;
          this.cd.markForCheck();
        }
      );
  }

  openCustomViewTemplates() {
    this.customViewTemplatesController
      .chooseTemplate({
        viewCreateEnabled: true,
        analyticsSource: this.analyticsSource
      })
      .pipe(
        switchMap(result => {
          if (result.data) {
            return this.createdViewItem(result.data);
          } else if (result.useShared) {
            return of(this.createBarItem(result.useShared));
          } else {
            return of(undefined);
          }
        }),
        untilDestroyed(this)
      )
      .subscribe(item => {
        if (item) {
          this.customizeService.createElement(item);
        }
      });
  }
}
