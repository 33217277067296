var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { AggregateFunc, DatasetGroupLookup } from '@modules/charts';
import { ChartWidgetQuery, QueryType } from '@modules/queries';
import { isSet } from '@shared';
import { DataSource, DataSourceType } from './data-source';
var ChartWidgetDataSource = /** @class */ (function (_super) {
    __extends(ChartWidgetDataSource, _super);
    function ChartWidgetDataSource(options) {
        if (options === void 0) { options = {}; }
        var _this = _super.call(this, options) || this;
        _this.xLookup = DatasetGroupLookup.Auto;
        _this.xLookup2 = DatasetGroupLookup.Auto;
        _this.yCumulative = false;
        if (options.hasOwnProperty('xColumn')) {
            _this.xColumn = options.xColumn;
        }
        if (options.hasOwnProperty('xLookup')) {
            _this.xLookup = options.xLookup;
        }
        if (options.hasOwnProperty('xColumn2')) {
            _this.xColumn2 = options.xColumn2;
        }
        if (options.hasOwnProperty('xLookup2')) {
            _this.xLookup2 = options.xLookup2;
        }
        if (options.hasOwnProperty('yFunc')) {
            _this.yFunc = options.yFunc;
        }
        if (options.hasOwnProperty('yColumn')) {
            _this.yColumn = options.yColumn;
        }
        if (options.hasOwnProperty('yCumulative')) {
            _this.yCumulative = options.yCumulative;
        }
        return _this;
    }
    ChartWidgetDataSource.prototype.deserialize = function (data) {
        _super.prototype.deserialize.call(this, data);
        this.xColumn = data['x_column'];
        this.xLookup = data['x_lookup'];
        this.xColumn2 = data['x_column_2'];
        this.xLookup2 = data['x_lookup_2'];
        this.yFunc = data['y_func'];
        this.yColumn = data['y_column'];
        this.yCumulative = data['y_cumulative'];
        return this;
    };
    ChartWidgetDataSource.prototype.serialize = function () {
        return __assign({}, _super.prototype.serialize.call(this), { x_column: this.xColumn, x_lookup: this.xLookup, x_column_2: this.xColumn2, x_lookup_2: this.xLookup2, y_func: this.yFunc, y_column: this.yColumn, y_cumulative: this.yCumulative });
    };
    ChartWidgetDataSource.prototype.isConfigured = function () {
        var isCount = this.yFunc == AggregateFunc.Count;
        return (_super.prototype.isConfigured.call(this) &&
            (isCount ||
                (isSet(this.yFunc) && !isCount && isSet(this.yColumn)) ||
                (!isSet(this.yFunc) && isSet(this.yColumn))) &&
            isSet(this.xColumn) &&
            isSet(this.xLookup));
    };
    ChartWidgetDataSource.prototype.getModelId = function () {
        return this.type == DataSourceType.Query &&
            this.query &&
            this.query.queryType == QueryType.Simple &&
            this.query.simpleQuery
            ? [this.queryResource, this.query.simpleQuery.model].join('.')
            : undefined;
    };
    ChartWidgetDataSource.queryCls = ChartWidgetQuery;
    return ChartWidgetDataSource;
}(DataSource));
export { ChartWidgetDataSource };
