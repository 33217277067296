import { isSet } from '../common/common';

export class AppError extends Error {
  constructor(message?: string) {
    super(message);

    Object.setPrototypeOf(this, AppError.prototype);
  }
}

export class UnexpectedError extends Error {
  constructor(message?: string) {
    super(message);

    Object.setPrototypeOf(this, UnexpectedError.prototype);
  }
}

export function errorToString(error: any): string {
  if (!isSet(error)) {
    return error;
  }

  try {
    const message = error.message;
    if (isSet(message)) {
      return message;
    }
  } catch (e) {}

  return error.toString();
}
