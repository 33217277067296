import { Injectable, InjectionToken, Injector, StaticProvider } from '@angular/core';
import { ActivationEnd, Router } from '@angular/router';
import fromPairs from 'lodash/fromPairs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, merge, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { getCurrentLanguage } from '@common/localize';
import { DocumentService } from '@core';
import { ApiService } from '@modules/api';
import { ViewContext, ViewContextElement, ViewContextOutput } from '@modules/customize';
import { ProjectPropertyEditController } from '@modules/customize-bar';
import { FieldType } from '@modules/fields';
import { ProjectApiService } from '@modules/project-api';
import {
  CurrentEnvironmentStore,
  CurrentProjectStore,
  ProjectProperty,
  ProjectPropertyStore,
  ProjectPropertyType
} from '@modules/projects';
import { ThemeService } from '@modules/theme';
import { CurrentUserStore } from '@modules/users';
import { ascComparator, getLocationAppPath, getLocationQueryParams } from '@shared';

export const teamToken = new InjectionToken<ViewContextElement>('teamToken');
export const teamPropertiesToken = new InjectionToken<ViewContextElement>('teamPropertiesToken');
export const userToken = new InjectionToken<ViewContextElement>('userToken');
export const userPropertiesToken = new InjectionToken<ViewContextElement>('userPropertiesToken');
export const globalPropertiesToken = new InjectionToken<ViewContextElement>('globalPropertiesToken');
export const appPropertiesToken = new InjectionToken<ViewContextElement>('appPropertiesToken');
export const devicePropertiesToken = new InjectionToken<ViewContextElement>('appDeviceToken');

@Injectable()
export class MenuContext extends ViewContext {
  private providers: StaticProvider[] = [
    { provide: teamToken, useFactory: () => this.createContextElement(), deps: [] },
    { provide: teamPropertiesToken, useFactory: () => this.createContextElement(), deps: [] },
    { provide: userToken, useFactory: () => this.createContextElement(), deps: [] },
    { provide: userPropertiesToken, useFactory: () => this.createContextElement(), deps: [] },
    { provide: globalPropertiesToken, useFactory: () => this.createContextElement(), deps: [] },
    { provide: appPropertiesToken, useFactory: () => this.createContextElement(), deps: [] },
    { provide: devicePropertiesToken, useFactory: () => this.createContextElement(), deps: [] }
  ];
  private injector = Injector.create({
    providers: this.providers,
    parent: this.parentInjector
  });

  private viewContextElementTeam = this.injector.get<ViewContextElement>(teamToken);
  private viewContextElementTeamProperty = this.injector.get<ViewContextElement>(teamPropertiesToken);
  private viewContextElementUser = this.injector.get<ViewContextElement>(userToken);
  private viewContextElementUserProperty = this.injector.get<ViewContextElement>(userPropertiesToken);
  private viewContextElementGlobalProperty = this.injector.get<ViewContextElement>(globalPropertiesToken);
  private viewContextElementAppProperty = this.injector.get<ViewContextElement>(appPropertiesToken);
  private viewContextElementDeviceProperty = this.injector.get<ViewContextElement>(devicePropertiesToken);

  constructor(
    private router: Router,
    private parentInjector: Injector,
    private currentUserStore: CurrentUserStore,
    private projectPropertyStore: ProjectPropertyStore,
    private projectPropertyEditController: ProjectPropertyEditController,
    private apiService: ApiService,
    private projectApiService: ProjectApiService,
    private currentProjectStore: CurrentProjectStore,
    private documentService: DocumentService,
    private themeService: ThemeService,
    currentEnvironmentStore: CurrentEnvironmentStore
  ) {
    super(currentEnvironmentStore);
    this.init();
  }

  createContextElement() {
    return new ViewContextElement(this);
  }

  onBeforeDestroy(): void {
    this.pauseElements();
    this.clear();
  }

  init() {
    this.initViewContextElements();

    this.projectPropertyStore
      .getGlobal()
      .pipe(untilDestroyed(this))
      .subscribe(properties => {
        properties = properties || [];

        const outputs: ViewContextOutput[] = properties
          .map(item => {
            return {
              uniqueName: item.uid,
              name: item.name,
              icon: item.fieldDescription.icon,
              ...(item.field
                ? {
                    fieldType: item.field.field,
                    fieldParams: item.field.params
                  }
                : {})
            };
          })
          .sort((lhs, rhs) => ascComparator(lhs.name, rhs.name));
        this.viewContextElementGlobalProperty.setOutputs(outputs);
      });

    this.currentEnvironmentStore.globalStorage
      .getValues$()
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        this.viewContextElementGlobalProperty.setOutputValues(value);
      });

    combineLatest(
      this.currentUserStore.instance$,
      this.currentProjectStore.instance$,
      this.currentEnvironmentStore.instance$,
      this.projectPropertyStore.get(),
      this.currentEnvironmentStore.globalStorage.getValues$()
    )
      .pipe(untilDestroyed(this))
      .subscribe(([currentUser, currentProject, currentEnvironment, projectProperties, globalProjectProperties]) => {
        const userProperties = projectProperties
          ? projectProperties.filter(item => item.type == ProjectPropertyType.User)
          : [];
        const groupProperties = projectProperties
          ? projectProperties.filter(item => item.type == ProjectPropertyType.Group)
          : [];

        this.updateContextUser();
        this.updateContextUserProperties(userProperties);
        this.updateContextTeam();
        this.updateContextTeamProperties(groupProperties);

        if (currentEnvironment && currentEnvironment.user) {
          const value = fromPairs(
            userProperties.map(property => [property.uid, currentEnvironment.user.getPropertyValue(property)])
          );

          this.viewContextElementUserProperty.setOutputValues(value);
        }

        if (currentEnvironment && currentEnvironment.group) {
          const value = fromPairs(
            groupProperties.map(property => [property.uid, currentEnvironment.group.getPropertyValue(property)])
          );
          this.viewContextElementTeamProperty.setOutputValues(value);
        }

        this.viewContextElementTeam.setOutputValues({
          ...(currentEnvironment && currentEnvironment.group
            ? {
                uid: currentEnvironment.group.uid,
                name: currentEnvironment.group.name
              }
            : {})
        });

        this.viewContextElementUser.setOutputValues({
          email: currentUser ? currentUser.email : undefined,
          first_name: currentUser ? currentUser.firstName : undefined,
          last_name: currentUser ? currentUser.lastName : undefined,
          token: this.apiService.getAccessToken() ? this.apiService.getAccessToken() : undefined,
          project_token: this.projectApiService.getAccessToken() ? this.projectApiService.getAccessToken() : undefined,
          uid: currentEnvironment && currentEnvironment.user ? currentEnvironment.user.uid : undefined,
          language: getCurrentLanguage()
        });

        this.viewContextElementAppProperty.patchOutputValues({
          name: currentProject.uniqueName,
          env_name: currentEnvironment ? currentEnvironment.uniqueName : undefined
        });
      });

    merge(of({}), this.router.events.pipe(filter(item => item instanceof ActivationEnd)))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.viewContextElementAppProperty.patchOutputValues({
          app_path: getLocationAppPath(),
          query_params: getLocationQueryParams()
        });
      });

    this.documentService.viewportSize$.pipe(untilDestroyed(this)).subscribe(value => {
      const type = this.documentService.getViewportType(value.width);

      this.viewContextElementDeviceProperty.patchOutputValues({
        is_desktop: this.documentService.isDesktopViewportType(type),
        is_mobile: this.documentService.isMobileViewportType(type),
        is_phone: this.documentService.isPhoneViewportType(type),
        is_tablet: this.documentService.isTabletViewportType(type),
        screen_width: value.width,
        screen_height: value.height
      });
    });

    this.themeService.isDarkTheme$.pipe(untilDestroyed(this)).subscribe(value => {
      this.viewContextElementDeviceProperty.patchOutputValues({
        dark_theme: value
      });
    });
  }

  initViewContextElements() {
    this.viewContextElementTeam.unregister();
    this.viewContextElementTeam.initGlobal({ uniqueName: 'group', name: 'Team' });

    this.viewContextElementTeamProperty.unregister();
    this.viewContextElementTeamProperty.initGlobal({
      uniqueName: 'team_properties',
      name: 'Team Properties',
      action: {
        label: 'Add Property',
        icon: 'plus',
        handler: () => {
          return this.projectPropertyEditController
            .create({
              type: ProjectPropertyType.Group,
              context: this,
              analyticsSource: 'component_input'
            })
            .pipe(
              map(item => {
                return {
                  insertToken: ['team_properties', item.property.uid]
                };
              })
            );
        }
      },
      documentation: 'conditional-visibility/properties'
    });

    this.viewContextElementUser.unregister();
    this.viewContextElementUser.initGlobal({ uniqueName: 'user', name: 'User' });

    this.viewContextElementUserProperty.unregister();
    this.viewContextElementUserProperty.initGlobal({
      uniqueName: 'user_properties',
      name: 'User Properties',
      action: {
        label: 'Add Property',
        icon: 'plus',
        handler: () => {
          return this.projectPropertyEditController
            .create({
              type: ProjectPropertyType.User,
              context: this,
              analyticsSource: 'component_input'
            })
            .pipe(
              map(item => {
                return {
                  insertToken: ['user_properties', item.property.uid]
                };
              })
            );
        }
      },
      documentation: 'conditional-visibility/properties'
    });

    this.viewContextElementGlobalProperty.unregister();
    this.viewContextElementGlobalProperty.initGlobal({
      uniqueName: 'global_variables',
      name: 'Global variables',
      action: {
        label: 'Add Variable',
        icon: 'plus',
        handler: () => {
          return this.projectPropertyEditController
            .create({
              type: ProjectPropertyType.Global,
              defaultName: 'variable',
              defaultValueEnabled: true,
              context: this,
              analyticsSource: 'component_input'
            })
            .pipe(
              map(item => {
                return {
                  insertToken: ['global_variables', item.property.uid]
                };
              })
            );
        }
      },
      documentation: 'conditional-visibility/properties'
    });

    this.viewContextElementAppProperty.unregister();
    this.viewContextElementAppProperty.initGlobal({ uniqueName: 'app', name: 'Application' });
    this.viewContextElementAppProperty.setOutputs([
      {
        uniqueName: 'name',
        name: 'App name',
        icon: 'home'
      },
      {
        uniqueName: 'env_name',
        name: 'Environment name',
        icon: 'tag'
      },
      {
        uniqueName: 'app_path',
        name: 'App path',
        icon: 'console'
      },
      {
        uniqueName: 'query_params',
        name: 'URL query parameters',
        icon: 'components',
        fieldType: FieldType.JSON
      }
    ]);

    this.viewContextElementDeviceProperty.unregister();
    this.viewContextElementDeviceProperty.initGlobal({ uniqueName: 'device', name: 'Device' });
    this.viewContextElementDeviceProperty.setOutputs([
      {
        uniqueName: 'is_desktop',
        name: 'Is Desktop',
        icon: 'pages'
      },
      {
        uniqueName: 'is_mobile',
        name: 'Is Mobile',
        icon: 'pages'
      },
      {
        uniqueName: 'is_phone',
        name: 'Is Phone',
        icon: 'pages'
      },
      {
        uniqueName: 'is_tablet',
        name: 'Is Tablet',
        icon: 'pages'
      },
      {
        uniqueName: 'screen_width',
        name: 'Screen width',
        icon: 'align_horizontal_fill'
      },
      {
        uniqueName: 'screen_height',
        name: 'Screen height',
        icon: 'align_vertical_fill'
      },
      {
        uniqueName: 'dark_theme',
        name: 'Dark theme',
        icon: 'toggle_theme'
      }
    ]);
  }

  updateContextUser() {
    this.viewContextElementUser.setOutputs([
      {
        uniqueName: 'email',
        name: 'Email',
        icon: 'email'
      },
      {
        uniqueName: 'first_name',
        name: 'First name',
        icon: 'user'
      },
      {
        uniqueName: 'last_name',
        name: 'Last name',
        icon: 'user'
      },
      {
        uniqueName: 'token',
        name: 'User token',
        icon: 'key'
      },
      {
        uniqueName: 'project_token',
        name: 'User app token',
        icon: 'key'
      },
      {
        uniqueName: 'uid',
        name: 'User ID',
        icon: 'number'
      },
      {
        uniqueName: 'language',
        name: 'Language',
        icon: 'earth_planet'
      }
    ]);
  }

  updateContextUserProperties(properties: ProjectProperty[]) {
    const outputs: ViewContextOutput[] = properties.map(item => {
      return {
        uniqueName: item.uid,
        name: item.name,
        icon: item.fieldDescription.icon,
        ...(item.field
          ? {
              fieldType: item.field.field,
              fieldParams: item.field.params
            }
          : {})
      };
    });
    this.viewContextElementUserProperty.setOutputs(outputs);
  }

  updateContextTeam() {
    this.viewContextElementTeam.setOutputs([
      {
        uniqueName: 'uid',
        name: 'Team ID',
        icon: 'number'
      },
      {
        uniqueName: 'name',
        name: 'Team name',
        icon: 'users_teams'
      }
    ]);
  }

  updateContextTeamProperties(properties: ProjectProperty[]) {
    const outputs: ViewContextOutput[] = properties.map(item => {
      return {
        uniqueName: item.uid,
        name: item.name,
        icon: item.fieldDescription.icon,
        ...(item.field
          ? {
              fieldType: item.field.field,
              fieldParams: item.field.params
            }
          : {})
      };
    });

    this.viewContextElementTeamProperty.setOutputs(outputs);
  }
}
