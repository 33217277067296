import { ComponentPortal } from '@angular/cdk/portal';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, Observable, timer } from 'rxjs';
import { tap } from 'rxjs/operators';

import { DialogService } from '@common/dialogs';
import { NotificationService } from '@common/notifications';
import { ServerRequestError } from '@modules/api';
import { CustomView, CustomViewService, CustomViewSource, CustomViewsStore } from '@modules/custom-views';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { errorToString, isSet, KeyboardEventKeyCode, TypedChanges } from '@shared';

import { CUSTOM_VIEW_COMPONENT, CUSTOM_VIEW_COMPONENT_INPUTS } from '../../data/injection-tokens';

@Component({
  selector: 'app-custom-view-dropdown-item',
  templateUrl: './custom-view-dropdown-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomViewDropdownItemComponent implements OnInit, OnDestroy, OnChanges {
  @Input() item: CustomView;
  @Input() active = false;
  @Input() thumbWidth = 100;
  @Input() thumbHeight = 83;
  @Input() thumbPadding = 10;
  @Output() selectItem = new EventEmitter<void>();

  @ViewChild('input') inputElement: ElementRef;

  firstVisible$ = new BehaviorSubject<boolean>(false);
  loadingSave = false;
  nameValue: string;
  nameValueInitial: string;
  nameEditing = false;
  viewLoading = true;
  viewScale = 1;
  viewPortal: ComponentPortal<any>;
  sources = CustomViewSource;

  constructor(
    @Inject(CUSTOM_VIEW_COMPONENT) private customElementViewComponent: any,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private customViewService: CustomViewService,
    private customViewsStore: CustomViewsStore,
    private dialogService: DialogService,
    private notificationService: NotificationService,
    private injector: Injector,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    combineLatest(this.firstVisible$, timer(400))
      .pipe(untilDestroyed(this))
      .subscribe(([firstVisible]) => {
        this.viewLoading = !firstVisible;
        this.cd.markForCheck();
      });

    if (this.customElementViewComponent && this.item.view) {
      const injector = Injector.create({
        providers: [
          {
            provide: CUSTOM_VIEW_COMPONENT_INPUTS,
            useValue: {
              view: this.item.view,
              parameters: this.item.parameters,
              params: this.item.testParameters
            }
          }
        ],
        parent: this.injector
      });

      this.viewPortal = new ComponentPortal(this.customElementViewComponent, null, injector);
    }
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<CustomViewDropdownItemComponent>): void {
    if (changes.item || changes.thumbWidth || changes.thumbHeight) {
      this.viewScale = this.getViewScale();
    }
  }

  getViewScale(): number {
    if (!this.item.view) {
      return;
    }

    const frame = this.item.view.frame;
    const widthScale = frame && frame.width > this.thumbWidth ? this.thumbWidth / frame.width : undefined;
    const heightScale = frame && frame.height > this.thumbHeight ? this.thumbHeight / frame.height : undefined;
    const scales = [widthScale, heightScale].filter(item => item);

    if (!scales.length) {
      return;
    }

    return Math.min(...scales);
  }

  startEditing() {
    this.nameValue = this.item.name;
    this.nameValueInitial = this.nameValue;
    this.nameEditing = true;
    this.cd.detectChanges();

    this.inputElement.nativeElement.focus();
    setTimeout(() => this.inputElement.nativeElement.select(), 0);
  }

  finishEditing(save = true) {
    this.nameEditing = false;
    this.cd.markForCheck();

    if (save && isSet(this.nameValue) && this.nameValue != this.nameValueInitial) {
      this.loadingSave = true;
      this.cd.markForCheck();

      const instance = cloneDeep(this.item);

      instance.name = this.nameValue;

      this.customViewService
        .update(
          this.currentProjectStore.instance.uniqueName,
          this.currentEnvironmentStore.instance.uniqueName,
          instance,
          { draft: true, fields: ['unique_name', 'name'] }
        )
        .pipe(
          tap(result => this.customViewsStore.updateItem(result)),
          untilDestroyed(this)
        )
        .subscribe(
          result => {
            this.item = result;
            this.loadingSave = false;
            this.cd.markForCheck();
          },
          error => {
            if (error instanceof ServerRequestError && error.errors.length) {
              this.notificationService.error('Rename failed', error.errors[0]);
            } else {
              this.notificationService.error('Rename failed', errorToString(error));
            }

            this.loadingSave = false;
            this.cd.markForCheck();
          }
        );
    }

    this.nameValue = undefined;
    this.nameValueInitial = undefined;
  }

  delete() {
    this.loadingSave = true;
    this.cd.markForCheck();

    this.deleteProcess()
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {},
        error => {
          this.loadingSave = false;
          this.cd.markForCheck();

          if (error instanceof ServerRequestError && error.errors.length) {
            this.notificationService.error('Delete failed', error.errors[0]);
          } else {
            this.notificationService.error('Delete failed', errorToString(error));
          }
        }
      );

    // this.dialogService
    //   .dialog({
    //     title: 'Deleting',
    //     description: `Are you sure want to delete custom component <strong>${this.item.name}</strong>?`,
    //     style: 'orange',
    //     buttons: [
    //       {
    //         name: 'cancel',
    //         label: 'Cancel',
    //         type: DialogButtonType.Default,
    //         hotkey: DialogButtonHotkey.Cancel
    //       },
    //       {
    //         name: 'ok',
    //         label: 'Delete custom component',
    //         type: DialogButtonType.Danger,
    //         hotkey: DialogButtonHotkey.Submit,
    //         executor: () => {
    //           this.loadingSave = true;
    //           this.cd.markForCheck();
    //
    //           return this.deleteProcess();
    //         }
    //       }
    //     ]
    //   })
    //   .pipe(
    //     map(result => {
    //       if (result.executorError) {
    //         throw result.executorError;
    //       }
    //
    //       return result.executorResult;
    //     }),
    //     untilDestroyed(this)
    //   )
    //   .subscribe(
    //     () => {},
    //     error => {
    //       this.loadingSave = false;
    //       this.cd.markForCheck();
    //
    //       if (error instanceof ServerRequestError && error.errors.length) {
    //         this.notificationService.error('Delete failed', error.errors[0]);
    //       } else {
    //         this.notificationService.error('Delete failed', errorToString(error));
    //       }
    //     }
    //   );
  }

  deleteProcess(): Observable<boolean> {
    const instance = cloneDeep(this.item);

    instance.deleted = true;

    return this.customViewService
      .delete(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, instance)
      .pipe(tap(() => this.customViewsStore.updateItem(instance)));
  }

  onKeyUp(e: KeyboardEvent) {
    if (e.keyCode == KeyboardEventKeyCode.Enter) {
      this.finishEditing();
    } else if (e.keyCode == KeyboardEventKeyCode.Escape) {
      this.finishEditing(false);
    }
  }

  onClick() {
    if (this.nameEditing) {
      return;
    }

    this.selectItem.emit();
  }
}
