<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayPanelClass]="['overlay', 'overlay_horizontal_center', 'overlay_position_left-center']"
  [cdkConnectedOverlayPositions]="[
    { originX: 'start', originY: 'center', overlayX: 'end', overlayY: 'center', offsetX: 0, offsetY: 0 }
  ]"
  [cdkConnectedOverlayOrigin]="root_trigger"
  [cdkConnectedOverlayOpen]="editPopoverOpened"
  [cdkConnectedOverlayPush]="true"
  [cdkConnectedOverlayGrowAfterOpen]="true"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayBackdropClass]="'popover2-backdrop'"
  (backdropClick)="openEditPopover(false)"
  (detach)="openEditPopover(false)"
>
  <app-custom-menu-item-popover [control]="control" (close)="openEditPopover(false)"></app-custom-menu-item-popover>
</ng-template>

<mat-menu #item_dropdown="matMenu">
  <button
    *ngIf="!customView || customView?.source == sources.View"
    mat-menu-item
    class="mat-menu-item-odd"
    [disableRipple]="true"
    (click)="openViewEditor(customView)"
  >
    <span class="choose-items-item__dropdown-icon icon-canvas"></span>
    <ng-container *ngIf="customView">Customize with Designer</ng-container>
    <ng-container *ngIf="!customView">Create with Designer</ng-container>
  </button>

  <button
    *ngIf="!customView || customView?.source == sources.CustomElement"
    mat-menu-item
    class="mat-menu-item-odd"
    [disableRipple]="true"
    (click)="openIDE(customView)"
  >
    <span class="choose-items-item__dropdown-icon icon-console"></span>
    <ng-container *ngIf="customView">Customize with JS Editor</ng-container>
    <ng-container *ngIf="!customView">Create with JS Editor</ng-container>
    <span class="mat-menu-item-tag mat-menu-item-tag_color_purple">AI</span>
  </button>

  <button mat-menu-item class="mat-menu-item-odd" [disableRipple]="true" (click)="openCustomViewTemplates()">
    <span class="choose-items-item__dropdown-icon icon-blocks"></span>
    Use Marketplace component
  </button>

  <button
    mat-menu-item
    class="mat-menu-item-odd"
    [disabled]="customViewsShared.length == 0"
    [disableRipple]="true"
    (click)="custom_view_dropdown.open()"
  >
    <span class="choose-items-item__dropdown-icon icon-open_folder"></span>
    Use saved components ({{ customViewsShared.length }})
  </button>

  <button
    *ngIf="(currentUserStore.instance$ | async)?.isStaff"
    mat-menu-item
    class="mat-menu-item-odd staff-block staff-block_background"
    [disabled]="!customView"
    [disableRipple]="true"
    (click)="updateCustomViewTemplate(customView)"
  >
    <span class="choose-items-item__dropdown-icon icon-redo"></span>
    Save to marketplace
  </button>

  <button
    *ngIf="!valueShared && customView"
    mat-menu-item
    class="mat-menu-item-odd mat-menu-item-orange"
    [disableRipple]="true"
    (click)="createCustomViewShared(customView)"
  >
    <span class="choose-items-item__dropdown-icon choose-items-item__dropdown-icon_orange icon-save"></span>
    Save to reuse
  </button>

  <button
    *ngIf="valueShared && customView"
    mat-menu-item
    class="mat-menu-item-odd mat-menu-item-orange"
    [disableRipple]="true"
    (click)="detachCustomViewShared(customView)"
  >
    <span class="choose-items-item__dropdown-icon choose-items-item__dropdown-icon_orange icon-copy"></span>
    Detach from saved
  </button>

  <button
    mat-menu-item
    class="mat-menu-item-odd"
    [disabled]="!customView"
    [disableRipple]="true"
    (click)="openEditPopover(true)"
  >
    <span class="choose-items-item__dropdown-icon icon-gear"></span>
    Customize settings
  </button>

  <button
    mat-menu-item
    class="mat-menu-item-odd mat-menu-item-red"
    [disableRipple]="true"
    (click)="deleteRequested.emit()"
  >
    <span class="choose-items-item__dropdown-icon choose-items-item__dropdown-icon_red icon-bin"></span>
    Delete item
  </button>
</mat-menu>

<app-custom-view-dropdown
  [value]="customView?.uniqueName"
  [origin]="root_trigger"
  (selectItem)="selectCustomView($event)"
  #custom_view_dropdown
></app-custom-view-dropdown>

<div class="sidebar-container__element">
  <div class="sidebar-list-item2 sidebar-list-item2_draggable" #root_trigger="cdkOverlayOrigin" cdkOverlayOrigin>
    <div
      class="sidebar-list-item2__row"
      [class.sidebar-list-item2__row_disabled]="menuTrigger.menuOpen || editPopoverOpened || loading"
    >
      <div class="sidebar-list-item2__row-left sidebar-list-item2__row-left_margin_l">
        <div class="sidebar-list-item2__drag sidebar-list-item2__drag_margin icon-dots_small"></div>
        <div
          *ngIf="!loading"
          class="sidebar-list-item2__icon"
          [class.icon-canvas]="!customView?.source || customView?.source == sources.View"
          [class.icon-console]="customView?.source == sources.CustomElement"
          [class.sidebar-list-item2__icon_purple-gradient]="true"
          [class.sidebar-list-item2__icon_size_l]="true"
        ></div>

        <app-loader-small
          *ngIf="loading"
          class="sidebar-list-item2__icon"
          [class.sidebar-list-item2__icon_size_l]="true"
        ></app-loader-small>
      </div>
      <div class="sidebar-list-item2__row-main sidebar-list-item2__row-main_multiline">
        <div class="sidebar-list-item2__title">
          <ng-container *ngIf="customView">
            {{ (customView.name | appIsSet) ? customView.name : 'No name' }}
          </ng-container>

          <ng-container *ngIf="!customView">
            Not specified
          </ng-container>
        </div>
        <div class="sidebar-list-item2__subtitle">
          <span *ngIf="valueShared" class="sidebar-list-item2__tag">SAVED</span> Custom component
        </div>
      </div>
      <div
        class="sidebar-list-item2__row-right"
        [matMenuTriggerFor]="item_dropdown"
        [matMenuTriggerData]="{ prepend: true }"
        #item_dropdown_trigger="matMenuTrigger"
      >
        <div
          class="sidebar-list-item2__action sidebar-list-item2__action_hover sidebar-list-item2__item icon-more"
          [class.sidebar-list-item2__action_active]="item_dropdown_trigger.menuOpen"
          (mousedown)="$event.stopPropagation()"
        ></div>
      </div>
    </div>
  </div>
</div>
