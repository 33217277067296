import { ChangeDetectorRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';
import { NotificationService } from '@common/notifications';
import { BasePopupComponent } from '@common/popups';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ServerRequestError } from '@modules/api';
import { createFormFieldFactory } from '@modules/fields';
import { CurrentProjectStore, Environment } from '@modules/projects';
import { RoutingService } from '@modules/routing';
import { controlValue, errorToString } from '@shared';
import { ProjectEnvironmentMergePopupForm } from './project-environment-merge-popup.form';
var ProjectEnvironmentMergePopupComponent = /** @class */ (function () {
    function ProjectEnvironmentMergePopupComponent(form, currentProjectStore, popupComponent, cd, routing, notificationService, analyticsService) {
        this.form = form;
        this.currentProjectStore = currentProjectStore;
        this.popupComponent = popupComponent;
        this.cd = cd;
        this.routing = routing;
        this.notificationService = notificationService;
        this.analyticsService = analyticsService;
        this.merged = new EventEmitter();
        this.createField = createFormFieldFactory();
        this.loading = false;
        this.resources = [];
    }
    ProjectEnvironmentMergePopupComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.form.init({ environmentFrom: this.environmentFrom, environmentTo: this.environmentTo });
        combineLatest(controlValue(this.form.controls.from_environment), controlValue(this.form.controls.to_environment))
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var fromEnvironment = _a[0], toEnvironment = _a[1];
            if (!fromEnvironment || !toEnvironment) {
                _this.resources = undefined;
                _this.cd.markForCheck();
                return;
            }
            var fromResources = _this.currentProjectStore.instance
                .getEnvironmentResources(fromEnvironment.uniqueName)
                .filter(function (item) { return !item.demo; });
            var toResources = _this.currentProjectStore.instance
                .getEnvironmentResources(toEnvironment.uniqueName)
                .filter(function (item) { return !item.demo; });
            _this.resources = fromResources.map(function (fromResource) {
                var toResource = toResources.find(function (item) { return item.uniqueName == fromResource.uniqueName; });
                return {
                    from: fromResource,
                    to: toResource
                };
            });
            _this.cd.markForCheck();
        });
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.Environment.StartedToMerge, {
            EnvironmentFromName: this.environmentFrom ? this.environmentFrom.name : undefined,
            EnvironmentToName: this.environmentTo ? this.environmentTo.name : undefined,
            Source: this.source
        });
    };
    ProjectEnvironmentMergePopupComponent.prototype.ngOnDestroy = function () { };
    ProjectEnvironmentMergePopupComponent.prototype.submit = function () {
        var _this = this;
        if (this.form.invalid) {
            return;
        }
        this.loading = true;
        this.cd.markForCheck();
        this.form
            .submit()
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            _this.close();
            _this.merged.emit();
            var copyFrom = _this.form.controls.from_environment.value.name;
            var copyTo = _this.form.controls.to_environment.value.name;
            _this.notificationService.success('Copied', "Environment " + copyFrom + " was copied to " + copyTo + " successfully");
            _this.analyticsService.sendSimpleEvent(AnalyticsEvent.Environment.MergedSuccessfully, {
                EnvironmentFromName: copyFrom,
                EnvironmentToName: copyTo,
                Source: _this.source
            });
        }, function (error) {
            var errorMessage = error instanceof ServerRequestError && error.errors.length ? error.errors[0] : errorToString(error);
            _this.notificationService.error('Copy failed', errorMessage);
            _this.loading = false;
            _this.cd.markForCheck();
            _this.analyticsService.sendSimpleEvent(AnalyticsEvent.Environment.MergeFailed, {
                EnvironmentFromName: _this.form.controls.from_environment.value.name,
                EnvironmentToName: _this.form.controls.to_environment.value.name,
                Error: errorMessage,
                Source: _this.source
            });
        });
    };
    ProjectEnvironmentMergePopupComponent.prototype.close = function () {
        this.popupComponent.close();
    };
    return ProjectEnvironmentMergePopupComponent;
}());
export { ProjectEnvironmentMergePopupComponent };
