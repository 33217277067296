<form [formGroup]="form" (ngSubmit)="submit()">
  <app-customize-bar-header
    [title]="'Bar code'"
    [titleEnabled]="titleEnabled"
    [backLabel]="'All components'"
    [deleteEnabled]="deleteEnabled"
    [parentLabel]="parentElement?.displayName || parentPopup?.displayName"
    (parentClick)="parentElement?.customize({ event: $event }) || parentPopup?.customize({ event: $event })"
    (deleteClick)="delete()"
  >
  </app-customize-bar-header>

  <div class="sidebar__content">
    <app-sidebar-tabs>
      <app-sidebar-tab [label]="'General'">
        <div class="sidebar__element">
          <app-sidebar-field [label]="'Format'">
            <app-auto-field
              [form]="form"
              [label]="false"
              [field]="
                createField({
                  name: 'format',
                  field: 'SelectField',
                  params: { options: form.formatOptions, classes: ['select_fill'] }
                })
              "
            ></app-auto-field>
          </app-sidebar-field>
        </div>

        <div class="sidebar__element">
          <app-customize-bar-input-edit
            [label]="'Value'"
            [itemForm]="form.controls.value"
            [context]="context"
            [placeholder]="valuePlaceholder"
            [fill]="true"
            [analyticsSource]="element.analyticsGenericName + '_value'"
          ></app-customize-bar-input-edit>
        </div>

        <div class="sidebar__element">
          <app-sidebar-field [label]="'display code text'" [layoutLeft]="true">
            <app-auto-field
              [form]="form"
              [label]="false"
              [field]="
                createField({
                  name: 'display_text',
                  label: 'Display text',
                  field: 'BooleanField',
                  params: { style: booleanFieldStyle.Toggle, orange: true }
                })
              "
            ></app-auto-field>
          </app-sidebar-field>
        </div>

        <div class="sidebar__element">
          <app-sidebar-field [label]="'fill color'">
            <app-auto-field
              [form]="form"
              [label]="false"
              [field]="
                createField({
                  name: 'fill_color',
                  field: 'ColorField',
                  required: false,
                  params: { classes: ['select_fill'], allow_empty: true, custom_colors: true }
                })
              "
            ></app-auto-field>
          </app-sidebar-field>
        </div>

        <div class="sidebar__element">
          <app-sidebar-field [label]="'background color'">
            <app-auto-field
              [form]="form"
              [label]="false"
              [field]="
                createField({
                  name: 'background_color',
                  field: 'ColorField',
                  required: false,
                  params: { classes: ['select_fill'], allow_empty: true, custom_colors: true }
                })
              "
            ></app-auto-field>
          </app-sidebar-field>
        </div>
      </app-sidebar-tab>

      <app-sidebar-tab [label]="'Display'">
        <div class="sidebar__element">
          <app-sidebar-field
            [label]="'Conditional Visibility'"
            [documentation]="'conditional-visibility'"
            [documentationLabel]="'See how it works'"
            [documentationSource]="element.analyticsGenericName + '_visible'"
          >
            <app-input-edit
              [itemForm]="form.controls.visible_input"
              [context]="context"
              [staticValueDisabled]="true"
              [placeholder]="'Value'"
              [fill]="true"
              [analyticsSource]="element.analyticsGenericName + '_visible'"
            ></app-input-edit>

            <ng-container description>
              Example: elements.field.value >= 10
            </ng-container>
          </app-sidebar-field>
        </div>

        <div class="sidebar__element">
          <app-element-wrap-edit [control]="form.controls.card_wrap"></app-element-wrap-edit>
        </div>

        <div class="sidebar__element">
          <app-sidebar-field [label]="'Spacing'">
            <app-margin-control [control]="form.controls.margin"></app-margin-control>
          </app-sidebar-field>
        </div>
      </app-sidebar-tab>
    </app-sidebar-tabs>
  </div>
</form>
