import { Type } from '@angular/core';
import pickBy from 'lodash/pickBy';

import { ApiInfo } from '@modules/api';
import { ModelDescription } from '@modules/models';
import { QueryType, StorageQuery } from '@modules/queries';
import { Storage } from '@modules/storages';
import { deployUrl, generateAlphanumeric, isSet, Mappable } from '@shared';

import { ResourceDeploy } from './resource-deploy';
import { ResourceName } from './resource-name';
import { ResourceType } from './resource-type';
import { ResourceTypeItem } from './resource-type-item';
import { ResourceTypeItemCategory } from './resource-type-item-category';
import { resourceTypeItems } from './resource-type-items';
import { SecretToken } from './secret-token';

export class Resource {
  public environment: string;
  public uniqueName: string;
  public name: string;
  public type: ResourceType;
  public typeItem: ResourceTypeItem;
  public deploy: string;
  public mediaUrlTemplate: string;
  public secretTokens: SecretToken[] = [];
  public secretTokensWithDeleted: SecretToken[] = [];
  public storages: Storage[] = [];
  public storagesWithDeleted: Storage[] = [];
  public params = {};
  public parsedParams: Mappable<any>;
  public demo = false;
  public featured = false;
  public templateStubData: number;
  public token: string;
  public apiInfo: ApiInfo;
  public draft = false;
  public deleted = false;

  static generateUniqueName(typeItem: ResourceTypeItem) {
    return [typeItem.name, generateAlphanumeric(4)].join('_');
  }

  deserialize(data: Object): Resource {
    this.environment = data['environment'];
    this.uniqueName = data['unique_name'];
    this.name = data['name'];
    this.type = data['type'];
    this.typeItem = resourceTypeItems.find(item => item.name == data['type_item']);
    this.deploy = data['deploy'];
    this.mediaUrlTemplate = data['media_url_template'];
    this.token = data['token'];

    if (data['secret_tokens']) {
      this.secretTokensWithDeleted = data['secret_tokens'].map(item => {
        const result = new SecretToken().deserialize(item);
        result.resource = this.uniqueName;
        return result;
      });
      this.secretTokens = this.secretTokensWithDeleted.filter(item => !item.deleted);
    }

    if (data['storages']) {
      this.storagesWithDeleted = data['storages'].map(item => new Storage().deserialize(item));
      this.storages = this.storagesWithDeleted.filter(item => !item.deleted);
    }

    if (typeof data['params'] == 'string') {
      try {
        this.params = JSON.parse(data['params']);
      } catch (e) {}
    } else {
      this.params = data['params'];
    }

    if (
      this.type == ResourceType.JetBridge &&
      ([ResourceDeploy.Docker, ResourceDeploy.Python].includes(this.deploy as ResourceDeploy) ||
        (this.typeItem && this.typeItem.name == ResourceName.Django))
    ) {
      const storage = new Storage();

      storage.uniqueName = 'default';
      storage.name = 'Storage';

      storage.uploadQuery = new StorageQuery();
      storage.uploadQuery.queryType = QueryType.Simple;
      storage.uploadQuery.simpleQuery = new storage.uploadQuery.simpleQueryClass();

      this.storages = [storage];
    }

    this.demo = !!this.params['demo'];
    this.featured = !!this.params['featured'];
    this.templateStubData = this.params['template_stub_data'];

    if (data['draft'] !== undefined) {
      this.draft = data['draft'];
    }

    if (data['deleted'] !== undefined) {
      this.deleted = data['deleted'];
    }

    return this;
  }

  serialize(fields?: string[]): Object {
    this.params = this.params || {};
    this.params['featured'] = this.featured;

    // TODO: Refactor
    if (this.parsedParams) {
      this.parsedParams['params'] = this.params;
    }

    let data: Object = {
      unique_name: this.uniqueName,
      name: this.name,
      type: this.type,
      type_item: this.typeItem ? this.typeItem.name : undefined,
      deploy: this.deploy,
      media_url_template: this.mediaUrlTemplate,
      params: this.parsedParams ? this.parsedParams.serialize() : this.params,
      token: this.token,
      draft: this.draft,
      deleted: this.deleted
    };
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }

  parseParams<T extends Mappable<any>>(cls: Type<T>, force = false): T {
    if (this.parsedParams instanceof cls && !force) {
      return this.parsedParams as T;
    }

    this.parsedParams = new cls().deserialize(this.params);

    return this.parsedParams as T;
  }

  get url() {
    return this.params['url'];
  }

  mediaUrl(path: string) {
    if (!isSet(this.mediaUrlTemplate)) {
      return path;
    }
    return this.mediaUrlTemplate.replace('{}', path);
  }

  get link() {
    return ['resources', this.uniqueName];
  }

  get linkCreateCollection() {
    return ['resources', this.uniqueName, 'models', 'create'];
  }

  get linkCreateAction() {
    return ['resources', this.uniqueName, 'actions', 'create'];
  }

  get icon() {
    if (!this.typeItem || !this.typeItem.icon) {
      return;
    }

    return deployUrl(`/assets/images/resources/icons/${this.typeItem.icon}.svg`);
  }

  isSimpleIntegration() {
    return (
      !this.typeItem.categories.includes(ResourceTypeItemCategory.Databases) &&
      this.typeItem.name != ResourceName.RestApi
    );
  }

  isStub() {
    return this.demo || !!this.templateStubData;
  }

  isSynced(modelDescription?: string) {
    if (modelDescription) {
      return (
        !!this.params['sync'] &&
        (!this.params['sync_model_descriptions'] || this.params['sync_model_descriptions'].includes(modelDescription))
      );
    } else {
      return !!this.params['sync'];
    }
  }

  isSyncedFinished() {
    return !!this.params['sync_finished'];
  }

  isSyncRunning() {
    return this.isSynced() && !this.isSyncedFinished();
  }

  isUsingProjectDatabase(): boolean {
    return this.typeItem.name == ResourceName.JetDatabase || this.isSynced() || this.hasCollectionSync();
  }

  isSameDatabase(localModel: ModelDescription, relatedResource: Resource, relatedModel: ModelDescription): boolean {
    if (!relatedResource || !relatedModel) {
      return false;
    }

    if (relatedResource.uniqueName == this.uniqueName && this.type == ResourceType.JetBridge) {
      return true;
    } else {
      return (
        (this.typeItem.name == ResourceName.JetDatabase || this.isSynced(localModel.model) || localModel.isSynced()) &&
        (relatedResource.typeItem.name == ResourceName.JetDatabase ||
          relatedResource.isSynced(relatedModel.model) ||
          relatedModel.isSynced())
      );
    }
  }

  isResourceSyncSupported(): boolean {
    return this.typeItem && this.typeItem.syncEnabled;
  }

  isCollectionSyncSupported(modelDescription: ModelDescription): boolean {
    return this.typeItem.name == ResourceName.RestApi && modelDescription.queryType != QueryType.SQL;
  }

  hasCollectionSync() {
    return !!this.params['sync_collection'];
  }

  resourceContents(): string[] {
    const contents = [];

    if (this.typeItem.modelsEnabled) {
      contents.push('collections');
    }

    if (this.typeItem.modelsEnabled) {
      contents.push('actions');
    }

    if (this.typeItem.modelsEnabled) {
      contents.push('storages');
    }

    return contents;
  }
}
