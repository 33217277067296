import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { NotificationService } from '@common/notifications';
import { RuntimeEnvironment } from '@core';
import { ActionType } from '@modules/actions';
import { ServerRequestError } from '@modules/api';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import {
  Automation,
  Workflow,
  WorkflowBackendRunListStore,
  WorkflowBackendRunService,
  WorkflowBackendRunWithRelations,
  WorkflowRunStatus
} from '@modules/workflow';
import { AppError, errorToString, isSet } from '@shared';

import { CustomizeBarContext } from '../../../services/customize-bar-context/customize-bar.context';
import { WorkflowEditController } from '../../../services/workflow-edit-controller/workflow-edit.controller';

@Component({
  selector: 'app-workflow-runs',
  templateUrl: './workflow-runs.component.html',
  providers: [WorkflowBackendRunListStore],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkflowRunsComponent implements OnInit, OnDestroy {
  @Input() runtime: RuntimeEnvironment;
  @Input() automation: Automation;
  @Input() workflow: Workflow;
  @Input() triggerLabel: string;
  @Input() triggerIcon: string;

  items$: Observable<WorkflowBackendRunWithRelations[]>;
  loading$: Observable<boolean>;
  hasMore = false;
  error: string;
  statuses = WorkflowRunStatus;
  analyticsSource = 'workflow_runs';

  trackItemFn(i, item: WorkflowBackendRunWithRelations) {
    return item.id;
  }

  constructor(
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private store: WorkflowBackendRunListStore,
    private workflowBackendRunService: WorkflowBackendRunService,
    private workflowEditController: WorkflowEditController,
    private customizeBarContext: CustomizeBarContext,
    private notificationService: NotificationService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    if (this.automation) {
      this.store.automationUid = this.automation.uid;
    } else if (this.workflow) {
      this.store.workflowUid = this.workflow.uid;
    }

    this.loading$ = this.store.loading$;
    this.items$ = this.store.items$;
    this.store
      .getNext()
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          this.hasMore = result && result.length == this.store.perPage;
          this.cd.markForCheck();
        },
        error => {
          if (error instanceof ServerRequestError && error.errors.length) {
            this.error = error.errors[0];
          } else {
            this.error = error;
          }

          this.cd.markForCheck();
        }
      );
  }

  ngOnDestroy(): void {}

  getNext() {
    this.store
      .getNext()
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        this.hasMore = result && result.length == this.store.perPage;
        this.cd.markForCheck();
      });
  }

  close() {
    this.customizeBarContext.popSettingsComponent();
  }

  openItem(item: WorkflowBackendRunWithRelations) {
    this.workflowBackendRunService
      .getDetail(this.currentProjectStore.instance, this.currentEnvironmentStore.instance, item.id)
      .pipe(
        switchMap(result => {
          if (!result || !result.log) {
            throw new AppError('No information about this run');
          }

          return this.workflowEditController.open({
            runtime: this.runtime,
            name: result.getName(),
            workflow: result.log.workflow,
            workflowRun: result.log.workflowRun,
            parameters: [],
            trigger: result.log.trigger,
            triggerEditable: !!item.automation,
            triggerLabel: this.triggerLabel,
            triggerIcon: this.triggerIcon,
            triggerData: result.log.data,
            actionTypesEnabled: [ActionType.Query, ActionType.RunJavaScript],
            resultEnabled: true,
            analyticsSource: this.analyticsSource
          });
        }),
        untilDestroyed(this)
      )
      .subscribe(
        () => {},
        error => {
          this.notificationService.error('Failed to open', errorToString(error));
        }
      );
  }
}
