import { AfterViewInit, ChangeDetectorRef, ElementRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { Power2, TimelineMax } from 'gsap';
import defaults from 'lodash/defaults';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { fromEvent, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { localize } from '@common/localize';
import { BasePopupComponent } from '@common/popups';
import { TintStyle } from '@modules/actions';
import { KeyboardEventKeyCode } from '@shared';
import { DialogButtonHotkey, DialogButtonType, DialogOptions } from '../../data/options';
import { DialogForm } from './dialog.form';
export var DefaultDialogOptions = {
    title: '',
    description: '',
    subtitle: undefined,
    buttons: [
        {
            name: 'cancel',
            label: localize('Cancel'),
            type: DialogButtonType.Default,
            hotkey: DialogButtonHotkey.Cancel
        },
        {
            name: 'ok',
            label: 'OK',
            type: DialogButtonType.Primary,
            hotkey: DialogButtonHotkey.Submit
        }
    ],
    style: 'default'
};
var DialogComponent = /** @class */ (function () {
    function DialogComponent(form, popupComponent, cd) {
        this.form = form;
        this.popupComponent = popupComponent;
        this.cd = cd;
        this.closed = new EventEmitter();
        this.tl = new TimelineMax();
        this.animating = false;
        this.dialogButtonTypes = DialogButtonType;
        this.tintStyles = TintStyle;
    }
    Object.defineProperty(DialogComponent.prototype, "currentOptions", {
        get: function () {
            return defaults(this.options, DefaultDialogOptions);
        },
        enumerable: true,
        configurable: true
    });
    DialogComponent.prototype.ngOnInit = function () {
        this.form.init(this.currentOptions);
    };
    DialogComponent.prototype.ngOnDestroy = function () { };
    DialogComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        fromEvent(document, 'keydown')
            .pipe(untilDestroyed(this))
            .subscribe(function (e) {
            if (e.keyCode == KeyboardEventKeyCode.Enter) {
                e.preventDefault();
                _this.clickSubmitButton();
            }
            else if (e.keyCode == KeyboardEventKeyCode.Escape) {
                e.preventDefault();
                _this.clickCancelButton();
            }
        });
        this.show();
    };
    DialogComponent.prototype.show = function () {
        var _this = this;
        if (this.animating) {
            return;
        }
        this.animating = true;
        this.tl
            .clear()
            .fromTo(this.background.nativeElement, 0, {
            opacity: 0
        }, {
            opacity: 1,
            ease: Power2.easeOut
        })
            .fromTo(this.root.nativeElement, 0, {
            scale: 0.9,
            opacity: 0
        }, {
            scale: 1,
            opacity: 1,
            ease: Power2.easeOut
        }, 0)
            .add(function () {
            _this.animating = false;
        });
    };
    DialogComponent.prototype.clickSubmitButton = function () {
        var button = this.currentOptions.buttons.find(function (item) { return item.hotkey == DialogButtonHotkey.Submit; });
        if (button) {
            this.submit(button.name);
        }
    };
    DialogComponent.prototype.clickCancelButton = function () {
        var button = this.currentOptions.buttons.find(function (item) { return item.hotkey == DialogButtonHotkey.Cancel; });
        if (button) {
            this.submit(button.name);
        }
    };
    DialogComponent.prototype.submit = function (buttonName) {
        var _this = this;
        if (this.animating) {
            return;
        }
        var button = this.currentOptions.buttons.find(function (item) { return item.name == buttonName; });
        if (button && button.type == DialogButtonType.Submit && this.form.form.invalid) {
            return;
        }
        var obs;
        var result = {
            button: buttonName,
            form: this.form.form.value
        };
        if (button.executor) {
            var executorObs = button.executor(result);
            if (executorObs instanceof Observable) {
                obs = executorObs;
            }
            else {
                obs = of(executorObs);
            }
        }
        if (!obs) {
            obs = of(undefined);
        }
        this.buttonLoading = buttonName;
        this.cd.markForCheck();
        obs
            .pipe(map(function (executorResult) {
            return {
                executorResult: executorResult
            };
        }), catchError(function (executorError) {
            return of({
                executorResult: executorError,
                executorError: executorError
            });
        }), untilDestroyed(this))
            .subscribe(function (value) {
            _this.hide({
                button: buttonName,
                form: _this.form.form.value,
                executorResult: value.executorResult,
                executorError: value.executorError
            });
        });
    };
    DialogComponent.prototype.hide = function (result) {
        var _this = this;
        if (this.animating) {
            return;
        }
        this.animating = true;
        this.tl
            .clear()
            .to(this.root.nativeElement, 0.2, {
            scale: 0.9,
            opacity: 0,
            ease: Power2.easeIn
        }, 0)
            .to(this.background.nativeElement, 0.2, {
            opacity: 0,
            ease: Power2.easeIn
        })
            .add(function () {
            _this.animating = false;
            _this.popupComponent.close();
            _this.closed.next(result);
        });
    };
    DialogComponent.prototype.miss = function () {
        var _this = this;
        if (this.animating) {
            return;
        }
        this.animating = true;
        this.tl
            .clear()
            .fromTo(this.root.nativeElement, 0.1, {
            scale: 1
        }, {
            scale: 1.025,
            ease: Power2.easeOut
        })
            .fromTo(this.root.nativeElement, 0.1, {
            scale: 1.025,
            immediateRender: false
        }, {
            scale: 1,
            ease: Power2.easeIn
        })
            .add(function () {
            _this.animating = false;
        });
    };
    return DialogComponent;
}());
export { DialogComponent };
