<app-customize-bar-custom-html-edit
  *ngIf="element.source == sources.HTML"
  [element]="element"
  [context]="context"
  [contextElement]="contextElement"
  [titleEnabled]="titleEnabled"
  [titleEditable]="titleEditable"
  [deleteEnabled]="deleteEnabled"
  [parentElement]="parentElement"
  [parentPopup]="parentPopup"
  (event)="event.emit($event)"
></app-customize-bar-custom-html-edit>

<app-customize-bar-custom-view-edit
  *ngIf="element.source == sources.View"
  [element]="element"
  [context]="context"
  [contextElement]="contextElement"
  [titleEnabled]="titleEnabled"
  [titleEditable]="titleEditable"
  [deleteEnabled]="deleteEnabled"
  [parentElement]="parentElement"
  [parentPopup]="parentPopup"
  [firstInit]="firstInit"
  (event)="event.emit($event)"
></app-customize-bar-custom-view-edit>

<app-customize-bar-custom-element-edit
  *ngIf="element.source == sources.CustomElement"
  [element]="element"
  [context]="context"
  [contextElement]="contextElement"
  [titleEnabled]="titleEnabled"
  [titleEditable]="titleEditable"
  [deleteEnabled]="deleteEnabled"
  [parentElement]="parentElement"
  [parentPopup]="parentPopup"
  [firstInit]="firstInit"
  (event)="event.emit($event)"
></app-customize-bar-custom-element-edit>
