<form (ngSubmit)="submit()" [formGroup]="form">
  <app-customize-bar-header
    [title]="form.controls.name.value || 'Action dropdown'"
    [titleEnabled]="titleEnabled"
    [titleEditable]="titleEditable"
    [titleCleanValue]="titleCleanValue"
    [deleteEnabled]="deleteEnabled"
    [backLabel]="'All components'"
    [parentLabel]="parentElement?.displayName || parentPopup?.displayName"
    (deleteClick)="delete()"
    (titleChanged)="onTitleChanged($event)"
    (parentClick)="parentElement?.customize({ event: $event }) || parentPopup?.customize({ event: $event })"
  >
  </app-customize-bar-header>

  <div class="sidebar__content">
    <app-sidebar-tabs>
      <app-sidebar-tab [label]="'General'">
        <app-sidebar-section>
          <div class="sidebar__element">
            <app-sidebar-field [label]="'actions'" [required]="true">
              <app-actions-edit
                [options]="{
                  actionItemClass: viewSettingsActionClass,
                  nameEditable: true,
                  iconEditable: true,
                  approveEnabled: true,
                  confirmationEnabled: true,
                  completionEditable: true,
                  disabledEditable: true,
                  visibleEditable: true
                }"
                [context]="context"
                [contextElement]="contextElement"
                [contextElementPath]="contextElementPath"
                [contextElementPaths]="contextElementPaths"
                [control]="form.controls.actions"
                [emptyActionValue]="'Not specified'"
                [emptyAction]="'Dropdown action'"
                [emptyIcon]="'fileds'"
                [actionLabel]="'Dropdown action click'"
                [object]="element.analyticsGenericName"
                [classes]="'component-action-button'"
                [firstInit]="firstInit"
              >
              </app-actions-edit>
            </app-sidebar-field>
          </div>
        </app-sidebar-section>

        <div class="sidebar__element">
          <app-customize-bar-input-edit
            [label]="'button text'"
            [required]="true"
            [itemForm]="form.controls.verbose_name"
            [context]="context"
            [contextElement]="contextElement"
            [contextElementPath]="contextElementPath"
            [contextElementPaths]="contextElementPaths"
            [fill]="true"
            [analyticsSource]="element.analyticsGenericName + '_verbose_name'"
          ></app-customize-bar-input-edit>
        </div>
      </app-sidebar-tab>

      <app-sidebar-tab [label]="'Display'">
        <app-sidebar-section>
          <div class="sidebar__element">
            <app-sidebar-field [label]="'style'">
              <div class="sidebar-image-toggle sidebar-image-toggle_cols_3">
                <div *ngFor="let item of form.styleOptions" class="sidebar-image-toggle__col">
                  <div
                    class="sidebar-image-toggle__item"
                    [class.sidebar-image-toggle__item_active]="item.value === form.controls.style.value"
                    (click)="form.controls.style.patchValue(item.value)"
                  >
                    <div
                      class="sidebar-image-toggle__item-inner"
                      [style.background-image]="'/assets/images/buttons/' + item.image + '.svg' | appDeployCssUrl"
                    ></div>
                  </div>
                </div>
              </div>
            </app-sidebar-field>
          </div>

          <div class="sidebar__element">
            <app-sidebar-field [label]="'color'" [required]="false">
              <app-auto-field
                [field]="
                  createField({
                    name: 'tint',
                    field: 'ColorField',
                    params: {
                      classes: ['select_fill'],
                      allow_empty: true,
                      empty_accent_color: true,
                      custom_colors: true
                    }
                  })
                "
                [form]="form"
                [label]="false"
              ></app-auto-field>
            </app-sidebar-field>
          </div>

          <div class="sidebar__element">
            <app-sidebar-field [label]="'icon'">
              <app-auto-field
                [field]="
                  createField({
                    name: 'icon',
                    label: 'Icon',
                    field: 'IconField',
                    params: { classes: ['select_fill'], allow_empty: true }
                  })
                "
                [form]="form"
                [label]="false"
              ></app-auto-field>
            </app-sidebar-field>
          </div>

          <div class="sidebar__element" *ngIf="visibleEditable">
            <app-sidebar-field
              [label]="'Conditional Visibility'"
              [documentation]="'conditional-visibility'"
              [documentationLabel]="'See how it works'"
              [documentationSource]="element.analyticsGenericName + '_visible'"
            >
              <app-input-edit
                [itemForm]="form.controls.visible_input"
                [context]="context"
                [staticValueDisabled]="true"
                [placeholder]="'Value'"
                [fill]="true"
                [analyticsSource]="element.analyticsGenericName + '_visible'"
              ></app-input-edit>

              <ng-container description>
                Example: elements.field.value >= 10
              </ng-container>
            </app-sidebar-field>
          </div>

          <div class="sidebar__element">
            <app-sidebar-field
              [label]="'Conditional Disable'"
              [documentationSource]="element.analyticsGenericName + '_disable'"
            >
              <app-input-edit
                [itemForm]="form.controls.disabled_input"
                [context]="context"
                [contextElement]="contextElement"
                [contextElementPath]="contextElementPath"
                [contextElementPaths]="contextElementPaths"
                [staticValueDisabled]="true"
                [placeholder]="'Value'"
                [fill]="true"
                [analyticsSource]="element.analyticsGenericName + '_disable'"
              ></app-input-edit>

              <ng-container description>
                Example: elements.type.value == 'Refund'
              </ng-container>
            </app-sidebar-field>
          </div>

          <div class="sidebar__element">
            <app-sidebar-field [label]="'tooltip'">
              <app-auto-field
                [form]="form"
                [label]="false"
                [field]="
                  createField({
                    name: 'tooltip',
                    field: 'CharField',
                    placeholder: 'Show information for users',
                    reset_enabled: true,
                    params: { classes: ['input_fill'] }
                  })
                "
              ></app-auto-field>
            </app-sidebar-field>
          </div>

          <div class="sidebar__element">
            <app-element-wrap-edit [control]="form.controls.card_wrap"></app-element-wrap-edit>
          </div>

          <div class="sidebar__element">
            <app-sidebar-field [label]="'Spacing'">
              <app-margin-control [control]="form.controls.margin"></app-margin-control>
            </app-sidebar-field>
          </div>
        </app-sidebar-section>
      </app-sidebar-tab>
    </app-sidebar-tabs>
  </div>
</form>
