import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { DatasetGroupLookup } from '@modules/charts';
import { ViewContext, ViewContextElement } from '@modules/customize';
import { ChartType } from '@modules/dashboard';
import { DataSourceType } from '@modules/data-sources';
import { HttpResultsSection, SqlResultsSection } from '@modules/queries-components';
import { controlValue, isSet } from '@shared';
import { ChartWidgetDataSourceControl } from '../model-description-data-source-edit/chart-widget-data-source';
import { ModelDescriptionDataSourceEditComponent } from '../model-description-data-source-edit/model-description-data-source-edit.component';
var ChartWidgetDataSourceEditComponent = /** @class */ (function () {
    function ChartWidgetDataSourceEditComponent(cd) {
        this.cd = cd;
        this.datasetColumnEnabled = false;
        this.datasetColumnAllowed = false;
        this.typeOrange = false;
        this.search = false;
        this.dataSourceTypes = DataSourceType;
        this.datasetGroupLookup = DatasetGroupLookup;
    }
    ChartWidgetDataSourceEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.updateControlLabels();
        this.columnOptions$ = this.control.getChartColumnOptions$();
        // TODO: Temp fix
        this.columnOptions$.pipe(untilDestroyed(this)).subscribe(function () { return _this.cd.detectChanges(); });
        this.xColumnLabel$ = this.getXColumnLabel$();
        this.x2ColumnLabel$ = this.getX2ColumnLabel$();
        this.yFuncLabel$ = this.getYFuncLabel$();
        this.datasetColumnLabel$ = this.getDatasetColumnLabel$();
    };
    ChartWidgetDataSourceEditComponent.prototype.ngOnDestroy = function () { };
    ChartWidgetDataSourceEditComponent.prototype.updateControlLabels = function () {
        var yColumn;
        var xColumn;
        var xColumn2;
        if (this.control.xColumn2ChartTypes.includes(this.chartType)) {
            yColumn = {
                label: 'show',
                additional: 'bubble size'
            };
            xColumn = {
                label: 'X axis'
            };
            xColumn2 = {
                label: 'Y axis'
            };
        }
        else if (this.control.axisChartTypes.includes(this.chartType)) {
            yColumn = {
                label: 'show',
                additional: 'Y axis'
            };
            xColumn = {
                label: 'group by',
                additional: 'X axis'
            };
        }
        else if (this.control.axisInverseChartTypes.includes(this.chartType)) {
            yColumn = {
                label: 'show',
                additional: 'X axis'
            };
            xColumn = {
                label: 'group by',
                additional: 'Y axis'
            };
        }
        else {
            yColumn = {
                label: 'show'
            };
            xColumn = {
                label: 'group by'
            };
        }
        this.controlLabels = {
            yColumn: yColumn,
            xColumn: xColumn,
            xColumn2: xColumn2
        };
        this.cd.markForCheck();
    };
    ChartWidgetDataSourceEditComponent.prototype.getXColumnLabel$ = function () {
        var _this = this;
        return combineLatest(controlValue(this.control.controls.x_column), controlValue(this.control.controls.x_column_lookup)).pipe(map(function (_a) {
            var column = _a[0], lookup = _a[1];
            var xColumnLookupOption = _this.control.xColumnLookupAllOptions.find(function (item) { return item.value == lookup; });
            if (isSet(column) && xColumnLookupOption) {
                return xColumnLookupOption.valueDisplay(column);
            }
        }));
    };
    ChartWidgetDataSourceEditComponent.prototype.getX2ColumnLabel$ = function () {
        var _this = this;
        return combineLatest(controlValue(this.control.controls.x_column_2), controlValue(this.control.controls.x_column_2_lookup)).pipe(map(function (_a) {
            var column = _a[0], lookup = _a[1];
            var xColumnLookupOption = _this.control.xColumnLookupAllOptions.find(function (item) { return item.value == lookup; });
            if (isSet(column) && xColumnLookupOption) {
                return xColumnLookupOption.valueDisplay(column);
            }
        }));
    };
    ChartWidgetDataSourceEditComponent.prototype.getYFuncLabel$ = function () {
        var _this = this;
        return combineLatest(controlValue(this.control.controls.y_func), controlValue(this.control.controls.y_column)).pipe(map(function (_a) {
            var func = _a[0], column = _a[1];
            var yFuncOption = _this.control.yColumnFuncOptions.find(function (item) { return item.value == func; });
            if (!isSet(func) && isSet(column)) {
                return "Pre-aggregated Field - " + column;
            }
            else if (yFuncOption) {
                return yFuncOption.valueDisplay(column);
            }
        }));
    };
    ChartWidgetDataSourceEditComponent.prototype.getDatasetColumnLabel$ = function () {
        if (!this.datasetColumnControl) {
            return;
        }
        return combineLatest(controlValue(this.datasetColumnControl)).pipe(map(function (_a) {
            var column = _a[0];
            return column;
        }));
    };
    ChartWidgetDataSourceEditComponent.prototype.addQueryInput = function () {
        if (this.dataSourceEditComponent) {
            this.dataSourceEditComponent.editQuery({
                initialHttpResultsSection: HttpResultsSection.Parameters,
                initialSqlResultsSection: SqlResultsSection.Parameters
            });
        }
    };
    return ChartWidgetDataSourceEditComponent;
}());
export { ChartWidgetDataSourceEditComponent };
