import { ChangeDetectorRef, ElementRef, EventEmitter, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';
import { CustomViewService, CustomViewSource, CustomViewsStore } from '@modules/custom-views';
import { DisplayFieldType } from '@modules/fields';
import { ModelDescription } from '@modules/models';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { CurrentUserStore } from '@modules/users';
import { controlValue, isSet, KeyboardEventKeyCode } from '@shared';
import { DisplayFieldArray } from '../display-fields-edit/display-field.array';
import { DisplayFieldControl } from '../display-fields-edit/display-field.control';
var DisplayFieldsEditItemComponent = /** @class */ (function () {
    function DisplayFieldsEditItemComponent(currentProjectStore, currentEnvironmentStore, currentUserStore, customViewService, customViewsStore, cd) {
        this.currentProjectStore = currentProjectStore;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.currentUserStore = currentUserStore;
        this.customViewService = customViewService;
        this.customViewsStore = customViewsStore;
        this.cd = cd;
        this.loading = false;
        this.visibleEditable = true;
        this.customize = new EventEmitter();
        this.moveToTop = new EventEmitter();
        this.removeControl = new EventEmitter();
        this.openViewEditor = new EventEmitter();
        this.openIDE = new EventEmitter();
        this.openCustomViewTemplates = new EventEmitter();
        this.changeMapping = new EventEmitter();
        this.updateCustomViewTemplate = new EventEmitter();
        this.rename = new EventEmitter();
        this.createShared = new EventEmitter();
        this.detachShared = new EventEmitter();
        this.selectCustomView = new EventEmitter();
        this.queryField = false;
        this.nameEditing = false;
        this.customViewsShared = [];
        this.valueShared = false;
        this.displayFieldTypes = DisplayFieldType;
        this.sources = CustomViewSource;
    }
    DisplayFieldsEditItemComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.control.valueChanges.pipe(untilDestroyed(this)).subscribe(function () { return _this.cd.markForCheck(); });
        if (this.control.type == DisplayFieldType.CustomView) {
            combineLatest(this.customViewsStore.get(), controlValue(this.control.controls.customViewUniqueName))
                .pipe(untilDestroyed(this))
                .subscribe(function (_a) {
                var customViews = _a[0], customView = _a[1];
                _this.customView = isSet(customView) ? customViews.find(function (item) { return item.uniqueName == customView; }) : undefined;
                _this.customViewsShared = customViews.filter(function (item) { return item.shared; });
                _this.valueShared = isSet(customView)
                    ? _this.customViewsShared.some(function (item) { return item.uniqueName == customView; })
                    : undefined;
                _this.cd.markForCheck();
            });
        }
    };
    DisplayFieldsEditItemComponent.prototype.ngOnDestroy = function () { };
    DisplayFieldsEditItemComponent.prototype.ngOnChanges = function (changes) {
        if (changes.control || changes.modelDescription) {
            this.queryField = this.isQueryField(this.control, this.modelDescription);
        }
    };
    DisplayFieldsEditItemComponent.prototype.isQueryField = function (control, modelDescription) {
        if (modelDescription) {
            return modelDescription.field(control.controls.name.value) !== undefined;
        }
        else {
            return control.type == DisplayFieldType.Base;
        }
    };
    DisplayFieldsEditItemComponent.prototype.startEditing = function () {
        var _this = this;
        this.nameValue = isSet(this.control.controls.verboseName.value) ? this.control.controls.verboseName.value : '';
        this.nameEditing = true;
        this.cd.detectChanges();
        this.inputElement.nativeElement.focus();
        setTimeout(function () { return _this.inputElement.nativeElement.select(); }, 0);
    };
    DisplayFieldsEditItemComponent.prototype.finishEditing = function (save) {
        if (save === void 0) { save = true; }
        var currentValue = isSet(this.control.controls.verboseName.value) ? this.control.controls.verboseName.value : '';
        this.nameEditing = false;
        this.cd.markForCheck();
        if (save && this.nameValue != currentValue) {
            this.rename.emit(this.nameValue);
        }
        this.nameValue = undefined;
    };
    DisplayFieldsEditItemComponent.prototype.createCustomViewShared = function (customView) {
        this.createShared.emit(customView);
    };
    DisplayFieldsEditItemComponent.prototype.detachCustomViewShared = function (customView) {
        this.detachShared.emit(customView);
    };
    DisplayFieldsEditItemComponent.prototype.onKeyDown = function (e) {
        if (e.keyCode == KeyboardEventKeyCode.Enter) {
            e.preventDefault();
        }
    };
    DisplayFieldsEditItemComponent.prototype.onKeyUp = function (e) {
        if (e.keyCode == KeyboardEventKeyCode.Enter) {
            this.finishEditing();
        }
        else if (e.keyCode == KeyboardEventKeyCode.Escape) {
            this.finishEditing(false);
        }
    };
    return DisplayFieldsEditItemComponent;
}());
export { DisplayFieldsEditItemComponent };
