/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../custom-elements-components/components/custom-element-auto/custom-element-auto.component.ngfactory";
import * as i2 from "../../../../custom-elements-components/components/custom-element-auto/custom-element-auto.component";
import * as i3 from "../../../../action-queries/services/action-controller/action-controller.service";
import * as i4 from "@angular/common";
import * as i5 from "./custom-menu-item.component";
import * as i6 from "../../../../menu/services/menu-item-action/menu-item-action.service";
import * as i7 from "../../../../custom-views/stores/custom-views.store";
var styles_CustomMenuItemComponent = [];
var RenderType_CustomMenuItemComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CustomMenuItemComponent, data: {} });
export { RenderType_CustomMenuItemComponent as RenderType_CustomMenuItemComponent };
function View_CustomMenuItemComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-custom-element-auto", [], null, null, null, i1.View_CustomElementAutoComponent_0, i1.RenderType_CustomElementAutoComponent)), i0.ɵdid(1, 245760, null, 0, i2.CustomElementAutoComponent, [i3.ActionControllerService, i0.Injector], { source: [0, "source"], customView: [1, "customView"], params: [2, "params"], context: [3, "context"] }, null), i0.ɵpid(131072, i4.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.customView.source; var currVal_1 = _co.customView; var currVal_2 = i0.ɵunv(_v, 1, 2, i0.ɵnov(_v, 2).transform(_co.viewParams$)); var currVal_3 = _co.context; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_CustomMenuItemComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomMenuItemComponent_1)), i0.ɵdid(1, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.customView; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_CustomMenuItemComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-custom-menu-item", [], null, null, null, View_CustomMenuItemComponent_0, RenderType_CustomMenuItemComponent)), i0.ɵdid(1, 770048, null, 0, i5.CustomMenuItemComponent, [i6.MenuItemActionService, i7.CustomViewsStore, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CustomMenuItemComponentNgFactory = i0.ɵccf("app-custom-menu-item", i5.CustomMenuItemComponent, View_CustomMenuItemComponent_Host_0, { menuItem: "menuItem", menuBlock: "menuBlock", childrenVertical: "childrenVertical", primary: "primary", dropdown: "dropdown", horizontal: "horizontal", forceActive: "forceActive", forceOpened: "forceOpened", context: "context" }, { dropdownOpen: "dropdownOpen", dropdownClose: "dropdownClose" }, []);
export { CustomMenuItemComponentNgFactory as CustomMenuItemComponentNgFactory };
