import { Injectable } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import pickBy from 'lodash/pickBy';
import { combineLatest, concat, Observable, of } from 'rxjs';
import { delayWhen, map, publishLast, refCount, switchMap, tap, toArray } from 'rxjs/operators';

import { ActionDescriptionService, ActionStore } from '@modules/action-queries';
import { ActionDescription } from '@modules/actions';
import { DEMO_RESOURCES_PROJECT } from '@modules/api';
import {
  modelFieldItemToRawListViewSettingsColumn,
  ViewSettings,
  ViewSettingsService,
  ViewSettingsStore
} from '@modules/customize';
import { DataSyncJob, DataSyncService } from '@modules/data-sync';
import { MenuSettings, MenuSettingsService, MenuSettingsStore } from '@modules/menu';
import { ModelDescriptionService, ModelDescriptionStore } from '@modules/model-queries';
import { Model, ModelDbField, ModelDescription, ModelFieldType } from '@modules/models';
import {
  CurrentEnvironmentStore,
  CurrentProjectStore,
  Environment,
  isResourceTypeItemReplicable,
  Project,
  ProjectToken,
  ProjectTokenService,
  Resource,
  ResourceDeploy,
  ResourceName,
  ResourceService,
  ResourceTypeItem,
  resourceTypeItems,
  SecretToken,
  SecretTokenService
} from '@modules/projects';
import { RegionService } from '@modules/regions';
import { DatabaseGeneratorService } from '@modules/resource-generators/services/database-generator/database-generator.service';
import { ResourceGeneratorService } from '@modules/resource-generators/services/resource-generator/resource-generator.service';
import {
  ResourceControllerService,
  ResourceParamsResult,
  RestAPIResourceParams,
  SOURCE_FIELD_TYPE
} from '@modules/resources';
import { Storage } from '@modules/storages';
import { StorageService } from '@modules/storages-queries';
import { generateUUID, interpolateSimple, isSet, mapValuesDeep } from '@shared';

@Injectable()
export class ProjectSettingsService {
  constructor(
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private viewSettingsService: ViewSettingsService,
    private modelDescriptionService: ModelDescriptionService,
    private modelDescriptionStore: ModelDescriptionStore,
    private actionDescriptionService: ActionDescriptionService,
    private actionStore: ActionStore,
    private menuSettingsService: MenuSettingsService,
    private menuSettingsStore: MenuSettingsStore,
    private viewSettingsStore: ViewSettingsStore,
    private resourceService: ResourceService,
    private projectTokenService: ProjectTokenService,
    private resourceControllerService: ResourceControllerService,
    private secretTokenService: SecretTokenService,
    private storageService: StorageService,
    private databaseGeneratorService: DatabaseGeneratorService,
    private regionService: RegionService,
    private dataSyncService: DataSyncService
  ) {}

  saveResource(
    project: Project,
    environment: Environment,
    instance: Resource,
    create: boolean,
    params: {
      token?: ProjectToken;
      resourceParams?: Object;
      modelDescriptions?: Object[];
      resourceModelDescriptions?: boolean;
      replicateModelDescriptions?: boolean;
      models?: { model: string; items: Object[] }[];
      actionDescriptions?: Object[];
      viewSettings?: Object[];
      menuSettings?: Object;
      secretTokens?: Object[];
      storages?: Object[];
      extraTokens?: Object;
      mergeExisting?: boolean;
      mergeExistingRename?: boolean;
      deleteNonExisting?: boolean;
      sync?: boolean;
    } = {},
    reloadStores = true
  ): Observable<Resource> {
    let savedResource: Resource;
    let check: Observable<boolean>;

    if (this.resourceControllerService.get(instance.type).checkResource) {
      check = this.resourceControllerService
        .get(instance.type)
        .checkResource(instance.typeItem, params.resourceParams || instance.params || {});
    } else {
      check = of(true);
    }

    return check.pipe(
      switchMap(() => {
        if (!params.token) {
          return of(undefined);
        }

        const fields = ['resource', 'resource_type', 'resource_type_item', 'resource_deploy', 'activated'];
        return this.projectTokenService.update(project, params.token, fields);
      }),
      switchMap(() => {
        const tokens = {
          project: project.uniqueName,
          ...(params.extraTokens || {})
        };

        if (params.resourceParams) {
          params.resourceParams = mapValuesDeep(params.resourceParams, value => interpolateSimple(value, tokens));

          instance.params = { ...instance.params, ...params.resourceParams };
          instance.parseParams<RestAPIResourceParams>(RestAPIResourceParams, true);
        }

        if (!create) {
          return this.resourceService.update(project.uniqueName, environment.uniqueName, instance, [
            'unique_name',
            'name',
            'type',
            'type_item',
            'deploy',
            'token',
            'params'
          ]);
        } else {
          return this.resourceService.create(project.uniqueName, environment.uniqueName, instance);
        }
      }),
      tap(result => {
        savedResource = result;

        const tokens = {
          project: project.uniqueName,
          resource: savedResource.uniqueName,
          resource_name: savedResource.name,
          ...(params.extraTokens || {})
        };

        params.modelDescriptions = mapValuesDeep(params.modelDescriptions, value => interpolateSimple(value, tokens));
        params.actionDescriptions = mapValuesDeep(params.actionDescriptions, value => interpolateSimple(value, tokens));
        params.viewSettings = mapValuesDeep(params.viewSettings, value => interpolateSimple(value, tokens));
        params.menuSettings = mapValuesDeep(params.menuSettings, value => interpolateSimple(value, tokens));
        params.secretTokens = mapValuesDeep(params.secretTokens, value => interpolateSimple(value, tokens));
        params.storages = mapValuesDeep(params.storages, value => interpolateSimple(value, tokens));
      }),
      switchMap(() => {
        if (
          !params.modelDescriptions ||
          !params.modelDescriptions.length ||
          (params.resourceModelDescriptions && !isResourceTypeItemReplicable(savedResource.typeItem))
        ) {
          return of([]);
        }

        let createItems$: Observable<any>;
        const newItems = params.modelDescriptions.map(item => new ModelDescription().deserialize(item));

        if (params.replicateModelDescriptions && isResourceTypeItemReplicable(savedResource.typeItem)) {
          const controller = this.resourceControllerService.get(savedResource.type);

          createItems$ = combineLatest(
            newItems.map(newItem => {
              return controller.modelDescriptionCreate(savedResource, newItem);
            })
          ).pipe(
            switchMap(() => {
              if (controller.reload) {
                return controller.reload(savedResource);
              } else {
                return of(true);
              }
            }),
            switchMap(() => this.modelDescriptionStore.getFirst(true))
          );
        } else {
          createItems$ = of([]);
        }

        const existingItems$ = create ? of<ModelDescription[]>([]) : this.modelDescriptionStore.getFirst();

        return combineLatest(createItems$, existingItems$).pipe(
          switchMap(([_createResult, modelDescriptions]) => {
            const existingItems = modelDescriptions.filter(item => item.resource == instance.uniqueName);

            if (params.mergeExisting) {
              newItems.forEach(newModelDescription => {
                const sync = savedResource.isSynced(newModelDescription.model);
                const existingModelDescription = existingItems.find(item => item.isSame(newModelDescription));

                if (existingModelDescription) {
                  if (!params.mergeExistingRename) {
                    newModelDescription.verboseName = existingModelDescription.verboseName;
                    newModelDescription.verboseNamePlural = existingModelDescription.verboseNamePlural;
                  }

                  newModelDescription.orderingField = existingModelDescription.orderingField;
                  newModelDescription.defaultOrderBy = existingModelDescription.defaultOrderBy;
                  newModelDescription.displayField = existingModelDescription.displayField;
                  newModelDescription.description = existingModelDescription.description;
                  newModelDescription.featured = existingModelDescription.featured;
                  newModelDescription.hidden = existingModelDescription.hidden;
                  newModelDescription.orderAfter = existingModelDescription.orderAfter;
                  // newModelDescription.deleted = existingModelDescription.deleted;

                  if (sync) {
                    newModelDescription.fields = newModelDescription.fields.filter(newField => {
                      return existingModelDescription.field(newField.name);
                    });
                    newModelDescription.getParameters = [];
                    newModelDescription.getDetailQuery = undefined;
                    newModelDescription.getDetailParameters = [];
                    newModelDescription.getDetailParametersUseDefaults = true;
                  }

                  newModelDescription.fields.forEach(newField => {
                    const existingField = existingModelDescription.field(newField.name);

                    if (existingField) {
                      newField.orderAfter = existingField.orderAfter;
                      newField.visible = existingField.visible;
                    }
                  });

                  newModelDescription.dbFields.forEach(newField => {
                    const existingField = existingModelDescription.dbField(newField.name);

                    if (existingField) {
                      newField.verboseName = existingField.verboseName;
                      newField.description = existingField.description;
                      newField.field = existingField.field;
                      newField.required = existingField.required;
                      newField.null = existingField.null;
                      newField.editable = existingField.editable;
                      // newField.filterable = existingField.filterable;
                      // newField.sortable = existingField.sortable;
                      newField.defaultType = existingField.defaultType;
                      newField.defaultValue = existingField.defaultValue;

                      const forceUpdateParamsKeys = [SOURCE_FIELD_TYPE];

                      newField.params = {
                        ...pickBy(existingField.params || {}, (v, k) => {
                          return !forceUpdateParamsKeys.includes(k);
                        }),
                        ...pickBy(newField.params || {}, (v, k) => {
                          return forceUpdateParamsKeys.includes(k);
                        })
                      };
                      newField.resetFormField();
                      newField.updateFieldDescription();
                    }
                  });

                  newModelDescription.fields.push(
                    ...existingModelDescription.fields.filter(item => item.type == ModelFieldType.Custom)
                  );

                  [
                    {
                      newParameters: newModelDescription.createParameters,
                      existingParameters: existingModelDescription.createParameters
                    },
                    {
                      newParameters: newModelDescription.updateParameters,
                      existingParameters: existingModelDescription.updateParameters
                    }
                  ].forEach(options => {
                    options.newParameters.forEach(newParameter => {
                      const existingParameter = options.existingParameters.find(item => item.name == newParameter.name);

                      if (existingParameter && existingParameter.params) {
                        newParameter.params = {
                          ...newParameter.params,
                          ...(isSet(existingParameter.params['options']) && {
                            options: existingParameter.params['options']
                          }),
                          ...(isSet(existingParameter.params['structure']) && {
                            structure: existingParameter.params['structure']
                          }),
                          ...(isSet(existingParameter.params['display_fields']) && {
                            display_fields: existingParameter.params['display_fields']
                          })
                        };
                      }
                    });
                  });
                } else {
                  if (sync) {
                    newModelDescription.fields = [];
                    newModelDescription.getParameters = [];
                    newModelDescription.getDetailQuery = undefined;
                    newModelDescription.getDetailParameters = [];
                    newModelDescription.getDetailParametersUseDefaults = true;
                  }
                }
              });
            } else {
              newItems.forEach(newModelDescription => {
                const sync = savedResource.isSynced(newModelDescription.model);
                if (sync) {
                  newModelDescription.fields = [];
                  newModelDescription.getParameters = [];
                  newModelDescription.getDetailQuery = undefined;
                  newModelDescription.getDetailParameters = [];
                  newModelDescription.getDetailParametersUseDefaults = true;
                }
              });
            }

            if (params.deleteNonExisting) {
              existingItems
                .filter(existingItem => !existingItem.virtual)
                .filter(existingItem => !newItems.find(item => item.isSame(existingItem)))
                .forEach(existingItem => {
                  const deleteItem = cloneDeep(existingItem);
                  deleteItem.deleted = true;
                  newItems.push(deleteItem);
                });
            }

            return this.modelDescriptionService.createBulk(project.uniqueName, environment.uniqueName, newItems);
          })
        );
      }),
      delayWhen(newItems => {
        if (create) {
          return of(undefined);
        }

        return this.modelDescriptionStore.getFirst().pipe(
          switchMap(modelDescriptions => {
            const existingItems = modelDescriptions.filter(item => item.resource == savedResource.uniqueName);
            const newSyncItem = newItems.find(newItem => {
              return !existingItems.find(item => item.isSame(newItem)) && savedResource.isSynced(newItem.model);
            });

            if (!newSyncItem) {
              return of(undefined);
            }

            return this.dataSyncService
              .getJobs(this.currentProjectStore.instance, this.currentEnvironmentStore.instance, {
                sourceResourceUniqueName: savedResource.uniqueName
              })
              .pipe(
                map(jobs => jobs[0]),
                switchMap(job => {
                  if (!job) {
                    return of(undefined);
                  }

                  return this.dataSyncService.runJob(
                    this.currentProjectStore.instance,
                    this.currentEnvironmentStore.instance,
                    job.id
                  );
                })
              );
          })
        );
      }),
      switchMap(modelDescriptions => {
        if (!params.models || !params.models.length) {
          return of([]);
        }

        const controller = this.resourceControllerService.get(savedResource.type);
        const models$ = params.models.reduce<Observable<any>[]>((acc, data) => {
          const modelDescription = modelDescriptions.find(item => item.model == data.model);

          if (modelDescription) {
            const models = data.items.map(item => {
              const model = controller.createModel().deserialize(modelDescription.modelId, item);

              model.setUp(modelDescription);
              model.deserializeAttributes(modelDescription.dbFields);

              return model;
            });

            if (models.length) {
              acc.push(controller.modelCreateBulk(savedResource, modelDescription, models));
            }
          }

          return acc;
        }, []);

        return concat(...models$).pipe(toArray());
      }),
      switchMap(() => {
        if (!params.actionDescriptions || !params.actionDescriptions.length) {
          return of([]);
        }

        return (create ? of([]) : this.actionStore.getFirst()).pipe(
          switchMap(actionDescriptions => {
            const existingItems = actionDescriptions.filter(item => item.resource == instance.uniqueName);
            const newItems = params.actionDescriptions.map(item => new ActionDescription().deserialize(item));

            if (params.mergeExisting) {
              newItems.forEach(newActionDescription => {
                const existingActionDescription = existingItems.find(item => item.isSame(newActionDescription));

                if (existingActionDescription) {
                  newActionDescription.verboseName = existingActionDescription.verboseName;
                  // newActionDescription.icon = existingActionDescription.icon;
                  newActionDescription.description = existingActionDescription.description;
                  newActionDescription.featured = existingActionDescription.featured;
                  // newModelDescription.deleted = existingModelDescription.deleted;
                }
              });
            }

            if (params.deleteNonExisting) {
              existingItems
                .filter(existingItem => !existingItem.virtual)
                .filter(existingItem => !newItems.find(item => item.isSame(existingItem)))
                .forEach(existingItem => {
                  const deleteItem = cloneDeep(existingItem);
                  deleteItem.deleted = true;
                  newItems.push(deleteItem);
                });
            }

            return this.actionDescriptionService.createBulk(project.uniqueName, environment.uniqueName, newItems);
          })
        );
      }),
      switchMap(() => {
        if (!params.viewSettings || !params.viewSettings.length) {
          return of([]);
        }

        return this.viewSettingsService.createBulk(
          project.uniqueName,
          environment.uniqueName,
          params.viewSettings.map(item => new ViewSettings().deserialize(item))
        );
      }),
      switchMap(() => {
        if (!params.secretTokens || !params.secretTokens.length) {
          return of(undefined);
        }

        return combineLatest(
          ...params.secretTokens.map(item => {
            const secretToken = new SecretToken().deserialize(item);
            return this.secretTokenService.create(
              project.uniqueName,
              environment.uniqueName,
              savedResource.uniqueName,
              secretToken
            );
          })
        );
      }),
      switchMap(() => {
        if (!params.storages || !params.storages.length) {
          return of(undefined);
        }

        const existingItems = this.currentProjectStore.instance
          .getStorages(this.currentEnvironmentStore.instance.uniqueName)
          .filter(item => {
            return item.resource.uniqueName == instance.uniqueName;
          })
          .map(item => item.storage);
        const newItems = params.storages.map(storage => {
          const newStorage = new Storage().deserialize(storage);

          if (params.mergeExisting) {
            const existingStorage = !create
              ? existingItems.find(item => item.uniqueName == newStorage.uniqueName)
              : undefined;

            if (existingStorage) {
              newStorage.name = existingStorage.name;
            }
          }

          return newStorage;
        });

        if (params.deleteNonExisting) {
          existingItems
            .filter(existingItem => !newItems.find(item => item.isSame(existingItem)))
            .forEach(existingItem => {
              const deleteItem = cloneDeep(existingItem);
              deleteItem.deleted = true;
              newItems.push(deleteItem);
            });
        }

        return combineLatest(
          ...newItems.map(storage => {
            return this.storageService.create(
              project.uniqueName,
              environment.uniqueName,
              savedResource.uniqueName,
              storage
            );
          })
        );
      }),
      delayWhen(() => this.currentProjectStore.getFirst(true)),
      // switchMap(() => {
      //   if (!params.menuSettings) {
      //     return of(undefined);
      //   }
      //
      //   return this.menuSettingsStore.getFirst().pipe(
      //     switchMap(original => {
      //       const override = new MenuSettings().deserialize(params.menuSettings);
      //
      //       original.primaryCenterItems = [...original.primaryCenterItems, ...override.primaryCenterItems];
      //       original.secondaryStartItems = [...original.secondaryStartItems, ...override.secondaryStartItems];
      //
      //       return this.menuSettingsService.create(project.uniqueName, environment.uniqueName, original);
      //     })
      //   );
      // }),
      delayWhen(() => {
        const stores = [];

        if (reloadStores) {
          stores.push(this.modelDescriptionStore.getFirst(true));
          stores.push(this.actionStore.getFirst(true));

          if (params.viewSettings) {
            stores.push(this.viewSettingsStore.getFirst(true));
          }

          // if (params.menuSettings) {
          //   stores.push(this.menuSettingsStore.getFirst(true));
          // }
        }

        if (!stores.length) {
          return of(undefined);
        }

        return combineLatest(...stores);
      }),
      map<any, Resource>(() => savedResource),
      publishLast(),
      refCount()
    ) as Observable<Resource>;
  }

  reloadAllowed(resource: Resource): boolean {
    const supported =
      resource &&
      resource.apiInfo &&
      resource.apiInfo.isCompatibleJetBridge({ jetBridge: '0.6.9', jetDjango: '0.9.6' });

    if (resource && resource.isSynced()) {
      return true;
    } else {
      const resourceController = this.resourceControllerService.get(resource.type);
      return supported && resourceController && resourceController.reload != undefined;
    }
  }

  reloadResource(resource: Resource): Observable<boolean> {
    const resourceController = this.resourceControllerService.getForResource(resource, true);
    return resourceController.reload ? resourceController.reload(resource) : of(undefined);
  }

  syncResource(
    resource: Resource,
    generator: ResourceGeneratorService<any>,
    options: { mergeExisting?: boolean; deleteNonExisting?: boolean } = {}
  ): Observable<Resource> {
    const resourceController = this.resourceControllerService.getForResource(resource, true);
    const project = this.currentProjectStore.instance;
    const environment = this.currentEnvironmentStore.instance;
    const resourceReload = resourceController.reload ? resourceController.reload(resource) : of(undefined);

    return resourceReload.pipe(
      switchMap(() => generator.getParamsOptions(project, environment, resource)),
      switchMap(result => generator.generateParams(project, environment, resource.typeItem, result)),
      switchMap(params => {
        return this.saveResource(project, environment, resource, false, {
          resourceParams: {
            ...resource.params,
            ...params.resourceParams
          },
          modelDescriptions: params.modelDescriptions,
          actionDescriptions: params.actionDescriptions,
          secretTokens: params.secretTokens,
          storages: params.storages,
          extraTokens: params.extraTokens,
          mergeExisting: options.mergeExisting,
          deleteNonExisting: options.deleteNonExisting,
          sync: resource.isSynced()
        });
      })
    );
  }

  syncAllowed(resource: Resource, generator: ResourceGeneratorService<any>): boolean {
    if (generator) {
      return true;
    } else {
      const supported =
        resource &&
        resource.apiInfo &&
        resource.apiInfo.isCompatibleJetBridge({ jetBridge: '0.6.9', jetDjango: '0.9.6' });

      const resourceController = this.resourceControllerService.get(resource.type);
      return supported && resourceController && resourceController.reload != undefined;
    }
  }

  createResourceJetDatabase(options: {
    project: Project;
    environment: Environment;
    resource: Resource;
  }): Observable<ResourceParamsResult> {
    return this.databaseGeneratorService.createJetDatabase(options.project, options.environment, options.resource).pipe(
      switchMap(result => {
        return this.regionService.getDefaultJetBridge().pipe(
          switchMap(jetBridge => {
            const address = result.url.split(':', 2);
            const databaseOptions = {
              deploy: ResourceDeploy.Direct,
              region: jetBridge.region ? jetBridge.region.uid : undefined,
              url: jetBridge.url,
              database_host: address[0],
              database_port: address[1],
              database_name: result.databaseName,
              database_user: result.userName,
              database_password: result.password,
              database_schema: options.resource.uniqueName,
              token: options.resource.token || generateUUID()
            };

            const typeItem = resourceTypeItems.find(item => item.name == ResourceName.JetDatabase);
            return this.databaseGeneratorService.generateGeneralParams(
              options.project,
              options.environment,
              typeItem,
              databaseOptions
            );
          })
        );
      })
    );
  }

  applySyncParams(options: {
    project: Project;
    environment: Environment;
    params: ResourceParamsResult;
    existingResource: Resource;
    typeItem: ResourceTypeItem;
  }): Observable<{ params: ResourceParamsResult; createdResource?: Resource }> {
    if (!options.params.sync) {
      const params = {
        ...options.params,
        resourceParams: {
          ...options.params.resourceParams,
          sync: false
        }
      };
      return of({ params: params });
    }

    let createdResource: Resource;
    let resource$: Observable<Resource>;

    if (options.existingResource) {
      resource$ = of(options.existingResource);
    } else {
      const instance = new Resource();

      instance.name = options.params.resourceName;
      instance.type = options.typeItem.resourceType;
      instance.typeItem = options.typeItem;

      if (options.project.uniqueName == DEMO_RESOURCES_PROJECT) {
        instance.uniqueName = ['demo', options.typeItem.name].join('_');
      } else if (!options.existingResource) {
        instance.uniqueName = Resource.generateUniqueName(options.typeItem);
      }

      resource$ = this.resourceService
        .create(options.project.uniqueName, options.environment.uniqueName, instance)
        .pipe(
          tap(result => {
            createdResource = result;
          })
        );
    }

    return resource$.pipe(
      switchMap(resource => {
        return this.createResourceJetDatabase({
          project: options.project,
          environment: options.environment,
          resource: resource
        });
      }),
      map(databaseParams => {
        const params = {
          ...options.params,
          resourceToken: databaseParams.resourceToken,
          resourceParams: {
            ...options.params.resourceParams,
            sync: true,
            sync_model_descriptions: options.params.syncModelDescriptions,
            sync_finished: options.existingResource ? options.existingResource.isSyncedFinished() : false,
            ...databaseParams.resourceParams
          }
        };
        return { params: params, createdResource: createdResource };
      })
    );
  }

  applySyncJob(options: {
    project: Project;
    environment: Environment;
    resource: Resource;
    modelDescriptions?: string[];
    runInterval?: number;
  }): Observable<DataSyncJob> {
    if (!options.resource.isSynced()) {
      return of(undefined);
    }

    return this.dataSyncService
      .getJobs(options.project, options.environment, {
        sourceResourceUniqueName: options.resource.uniqueName
      })
      .pipe(
        map(result => result[0]),
        switchMap(existingJob => {
          if (existingJob) {
            return of(existingJob);
          } else {
            const job = new DataSyncJob();

            job.sourceResource = options.resource;
            job.runInterval = options.runInterval || 60;

            // if (options.modelDescriptions) {
            //   job.useAllModelDescriptions = false;
            //   job.modelDescriptions = options.modelDescriptions.map(item => {
            //     return {
            //       name: item
            //     };
            //   });
            // } else {
            job.useAllModelDescriptions = true;
            // }

            return this.dataSyncService.createJob(options.project, options.environment, job);
          }
        })
      );
  }
}
