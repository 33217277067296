import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable } from 'rxjs';

import { DialogService } from '@common/dialogs';
import { UniversalAnalyticsService } from '@modules/analytics';
import { CustomView, CustomViewType } from '@modules/custom-views';
import { MapSettings } from '@modules/customize';
import { ActionOutput, FieldType, ParameterField } from '@modules/fields';
import { ITEM_OUTPUT, SELECTED_ITEM_OUTPUT } from '@modules/list';
import { SidebarCollapseContext } from '@modules/sidebar';
import { CustomViewTemplateType } from '@modules/views';

import { CustomViewDefaults } from '../../../data/custom-view-defaults';
import { CustomizeBarContext } from '../../../services/customize-bar-context/customize-bar.context';
import { ListModelDescriptionDataSourceControl } from '../../model-description-data-source-edit/list-model-description-data-source';
import { CustomizeBarListLayoutSettingsComponent } from '../customize-bar-list-layout-settings/customize-bar-list-layout-settings.component';
import { CustomizeBarMapSettingsDataSourceComponent } from './customize-bar-map-settings-data-source/customize-bar-map-settings-data-source.component';
import { CustomizeBarMapSettingsForm } from './customize-bar-map-settings.form';

@Component({
  selector: 'app-customize-bar-map-settings',
  templateUrl: './customize-bar-map-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ListModelDescriptionDataSourceControl, CustomizeBarMapSettingsForm]
})
export class CustomizeBarMapSettingsComponent extends CustomizeBarListLayoutSettingsComponent<MapSettings>
  implements OnInit, OnDestroy {
  itemContextElementPath = [ITEM_OUTPUT];
  selectedItemContextElementPath = [SELECTED_ITEM_OUTPUT];
  dataSourceEditComponent = CustomizeBarMapSettingsDataSourceComponent;
  collapseContext = new SidebarCollapseContext();
  columnParameters$: Observable<ParameterField[]>;
  actionsRequiredFields = ['card_click_action', 'actions', 'model_actions'];
  cardCustomView: CustomView;
  customViewDefaults: CustomViewDefaults;
  customViewTypes = CustomViewType;
  customViewTemplateTypes = CustomViewTemplateType;
  fieldTypes = FieldType;

  trackActionOutput(i, item: ActionOutput) {
    return item.name;
  }

  constructor(
    public form: CustomizeBarMapSettingsForm,
    customizeBarContext: CustomizeBarContext,
    dialogService: DialogService,
    analyticsService: UniversalAnalyticsService,
    cd: ChangeDetectorRef
  ) {
    super(form, customizeBarContext, dialogService, analyticsService, cd);
  }

  ngOnInit() {
    super.ngOnInit();
    this.columnParameters$ = this.form.controls.data_source.controls.columns.getParameters$();
    this.customViewDefaults = this.form.getCustomViewDefaults();

    this.form
      .getCardCustomView$()
      .pipe(untilDestroyed(this))
      .subscribe(customView => {
        this.cardCustomView = customView;
        this.cd.markForCheck();
      });
  }

  onCustomViewSelected(customView?: CustomView) {
    this.form.controls.card_view_unique_name.patchValue(customView ? customView.uniqueName : undefined);
  }
}
