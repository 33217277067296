<app-error *ngIf="deprecatedTypes.includes(type)" [title]="'Deprecated page type'" [fill]="true">
  This page type is not supported in the current version.<br />
  Please downgrade to a version <strong>< 2.10.30</strong> or build a new page.
</app-error>

<!--<app-list-->
<!--  *ngIf="type == types.List"-->
<!--  [uniqueName]="uniqueName"-->
<!--  [params]="params"-->
<!--  (paramsChanged)="onParamsChanged($event)"-->
<!--&gt;</app-list>-->

<!--<app-change-->
<!--  *ngIf="type == types.Change"-->
<!--  [uniqueName]="uniqueName"-->
<!--  [params]="params"-->
<!--  [modelId]="modelId"-->
<!--  [id]="id"-->
<!--&gt;</app-change>-->

<app-custom-page *ngIf="type == types.Custom" [uniqueName]="uniqueName" [params]="params"></app-custom-page>

<ng-container *ngIf="type == types.Action">
  action page
</ng-container>

<ng-container *ngIf="type == types.Dashboard">
  dashboard page
</ng-container>

<app-error *ngIf="notAllowed" [title]="'Not allowed' | localize" [fill]="true">
  {{ "You don't have permissions to access this page" | localize }}
</app-error>

<app-error *ngIf="notFound" [title]="'Page not found' | localize" [fill]="true">
  {{ 'The specified address is incorrect, or the page does not exist' | localize }}
</app-error>
