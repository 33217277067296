import { HttpErrorResponse, HttpResponse, HttpResponseBase } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import isArray from 'lodash/isArray';
import values from 'lodash/values';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, merge, timer } from 'rxjs';
import { debounce, debounceTime, filter, map } from 'rxjs/operators';

import { DialogService } from '@common/dialogs';
import { DynamicComponentArguments } from '@common/dynamic-component';
import { NotificationService } from '@common/notifications';
import { LocalStorage } from '@core';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ServerRequestError } from '@modules/api';
import { fieldsEditItemFromParameterField, fieldsEditItemToParameterField } from '@modules/field-components';
// TODO: Fix import
import { BooleanFieldStyle } from '@modules/field-components/components/boolean-field/boolean-field.component';
import { createFormFieldFactory, ParameterArray, ParameterField } from '@modules/fields';
import { Resource, ResourceName } from '@modules/projects';
import {
  HttpContentType,
  HttpQuery,
  HttpQueryParameters,
  HttpQueryService,
  HttpResponseType,
  QueryService
} from '@modules/queries';
import { RestAPIResourceParams } from '@modules/resources';
import {
  controlValue,
  errorToString,
  filterObject,
  getHttpHeadersAsArray,
  getHttpHeadersAsObject,
  isSet,
  JsFunctionError,
  limitObjectLength
} from '@shared';

import { QueryBuilderContext } from '../../data/query-builder-context';
import {
  InputTokensEvent,
  InputTokensEventSetPaginationData,
  InputTokensEventSetSortingData,
  InputTokensEventType
} from '../input-tokens/input-tokens.component';
import { ResponsePathSelectEvent } from '../query-builder-response-transform/query-builder-response-transform.component';
import { QueryBuilderCustomComponent } from '../query-builder/query-builder.component';
import { QueryBuilderForm } from '../query-builder/query-builder.form';
import { QueryBuilderHttpForm } from './query-builder-http.form';

export enum HttpResultsSection {
  Parameters = 'parameters',
  Http = 'http',
  Result = 'result'
}

enum SideBarSection {
  Sorting = 'sorting',
  Pagination = 'pagination',
  ErrorTransformer = 'error_transformer'
}

export interface QueryBuilderHttpOptions {
  baseQuery?: HttpQuery;
  defaults?: Object;
  defaultTab?: string;
  enabledBodyTypes?: HttpContentType[];
  validator?: (body: any) => boolean;
  initialResultsSection?: HttpResultsSection;
}

const queryBuilderHttpOptionsDefaults: QueryBuilderHttpOptions = {
  enabledBodyTypes: [
    HttpContentType.JSON,
    HttpContentType.FormUrlEncoded,
    HttpContentType.FormData,
    HttpContentType.Raw
  ]
};

enum QueryBuilderSection {
  QueryParameters,
  Result,
  SideBar
}

interface HttpBuilderRequestBuffer {
  formValue: Object;
  parametersValue?: Object[];
}

export const HTTP_BUILDER_REQUEST_BUFFER_KEY = 'http_builder_request_buffer';

@Component({
  selector: 'app-query-builder-http',
  templateUrl: './query-builder-http.component.html',
  providers: [QueryBuilderHttpForm],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QueryBuilderHttpComponent implements OnInit, OnDestroy, OnChanges {
  @Input() resource: Resource;
  @Input() context: QueryBuilderContext;
  @Input() control: AbstractControl;
  @Input() queryForm: QueryBuilderForm;
  @Input() requireResponse = false;
  @Input() arrayResponse = false;
  @Input() options: QueryBuilderHttpOptions;
  @Input() customSections: QueryBuilderCustomComponent[] = [];
  @Input() parametersControl: ParameterArray;
  @Input() source: string;
  @Output() executed = new EventEmitter<HttpQuery>();
  @Output() saved = new EventEmitter<boolean>();
  @Output() canceled = new EventEmitter<boolean>();

  createField = createFormFieldFactory();
  loading = false;
  query: HttpQuery;
  requestCreate = false;
  requestParameters: HttpQueryParameters;
  response: HttpResponseBase;
  responseActual = false;
  responseBody: any;
  responseBodyTransformed: any;
  responseBodyProcessed: any;
  resultsSection = HttpResultsSection.Parameters;
  sideBarSection: SideBarSection;
  resultsSections = HttpResultsSection;
  sideBarSections = SideBarSection;
  customSectionComponentsTop: DynamicComponentArguments[] = [];
  customSectionComponentsBottom: DynamicComponentArguments[] = [];
  customSectionComponentsPreview: DynamicComponentArguments[] = [];
  booleanFieldStyle = BooleanFieldStyle;
  fieldsEditItemToParameterField = fieldsEditItemToParameterField;
  fieldsEditItemFromParameterField = fieldsEditItemFromParameterField;
  httpResponseTypes = HttpResponseType;
  resourceNames = ResourceName;
  queryBuilderSections = QueryBuilderSection;

  saveHovered = new BehaviorSubject<boolean>(false);
  requireResponseMessageHovered = new BehaviorSubject<boolean>(false);
  requireResponseMessageVisible$ = combineLatest(
    this.saveHovered.pipe(
      filter(value => (value && this.isResponseMissing()) || !value),
      debounce(value => timer(value ? 0 : 200))
    ),
    this.requireResponseMessageHovered
  ).pipe(
    map(([saveHovered, requireResponseMessageHovered]) => {
      return saveHovered || requireResponseMessageHovered;
    })
  );

  constructor(
    public form: QueryBuilderHttpForm,
    private queryService: QueryService,
    private httpQueryService: HttpQueryService,
    private localStorage: LocalStorage,
    private notificationService: NotificationService,
    private dialogService: DialogService,
    private analyticsService: UniversalAnalyticsService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    const query = this.control.value as HttpQuery;

    this.form.init(this.resource, query, this.currentOptions, this.parametersControl, this.context.useFileObjects);
    this.context.httpForm = this.form;
    this.context.paginationTokens = this.queryForm.listQuery;
    this.context.sortingTokens = this.queryForm.listQuery;

    if (query) {
      this.requestCreate = false;
      this.context.tokenValues = query.requestTokens;

      if (query.requestResponse !== undefined) {
        this.responseBody = query.requestResponse;
        this.updateResponseBody(query, {
          ...query.requestTokens,
          headers: query.getRequestResponseHeadersAsObject(),
          headers_array: query.requestResponseHeaders
        });
        this.responseActual = true;
        this.cd.markForCheck();
      }
    } else {
      this.requestCreate = true;
      this.context.tokenValues = {};
    }

    this.context.tokenValues$.pipe(untilDestroyed(this)).subscribe(() => this.updateRequestParameters());

    if (isSet(this.currentOptions.initialResultsSection)) {
      this.resultsSection = this.currentOptions.initialResultsSection;
    } else {
      this.resultsSection = this.responseBodyProcessed ? HttpResultsSection.Result : HttpResultsSection.Parameters;
    }

    this.cd.markForCheck();

    merge(
      ...values<AbstractControl>(this.form.controls)
        .filter(control => {
          return [
            this.form.controls.request_response,
            this.form.controls.request_response_headers,
            this.form.controls.request_tokens
          ].every(item => item !== control);
        })
        .map(control => control.valueChanges),
      ...(this.parametersControl ? [this.parametersControl.valueChanges] : [])
    )
      .pipe(debounceTime(60), untilDestroyed(this))
      .subscribe(() => {
        this.updateRequestParameters();
        this.responseActual = false;
        this.cd.markForCheck();
      });

    controlValue(this.form)
      .pipe(debounceTime(60), untilDestroyed(this))
      .subscribe(() => {
        this.query = this.form.getInstance();
        this.cd.markForCheck();
      });

    this.form.controls.response_path.valueChanges.pipe(debounceTime(60), untilDestroyed(this)).subscribe(() => {
      const currentQuery = this.form.getInstance();
      this.updateResponseBodyProcessed(currentQuery);
    });
    this.updateRequestParameters();
    this.updateCustomSections();

    this.analyticsService.sendSimpleEvent(AnalyticsEvent.HTTPBuilder.StartToSetUp, {
      ResourceType: this.resource.typeItem.name,
      HTTPQueryType: this.httpQueryType,
      Source: this.source
    });
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {}

  get currentOptions(): QueryBuilderHttpOptions {
    return {
      ...queryBuilderHttpOptionsDefaults,
      ...this.options
    };
  }

  setPerPage(length: number) {
    if (isSet(this.queryForm.controls.pagination_per_page.value) || length === 0) {
      return;
    }
    this.queryForm.controls.pagination_per_page.patchValue(length);
    this.cd.markForCheck();
  }

  updateRequestParameters() {
    const query = this.form.getInstance();
    try {
      this.requestParameters = this.httpQueryService.prepareParameters(query, {
        baseQuery: this.currentOptions.baseQuery,
        tokens: this.context.serializeTokenValues()
      });
      this.cd.markForCheck();
    } catch (e) {
      this.requestParameters = undefined;
      this.cd.markForCheck();
    }
  }

  setResultsSection(section: HttpResultsSection) {
    this.resultsSection = section;
    this.cd.markForCheck();
  }

  updateCustomSections() {
    this.customSectionComponentsTop = this.customSections
      .filter(item => item.position == 'top')
      .map(item => item.component);
    this.customSectionComponentsBottom = this.customSections
      .filter(item => item.position == 'bottom')
      .map(item => item.component);
    this.customSectionComponentsPreview = this.customSections
      .filter(item => item.position == 'preview')
      .map(item => item.component);
    this.cd.markForCheck();
  }

  applyTransformer(query: HttpQuery, tokens: Object, body: any) {
    if (query.responseTransformer) {
      try {
        return this.queryService.applyTransformer(body, query.responseTransformer, query.url, true, tokens);
      } catch (e) {
        const error = new JsFunctionError(e);
        this.notificationService.error(
          'Transformation Failed',
          `
                    Response transformer failed:<br>
                    [${error.line - 2}:${error.column}] ${error.message}
                `
        );
      }
    } else {
      return body;
    }
  }

  updateResponseBodyTransformed(query: HttpQuery, tokens: Object) {
    this.responseBodyTransformed = this.applyTransformer(query, tokens, this.responseBody);
    this.cd.markForCheck();
  }

  updateResponseBodyProcessed(query: HttpQuery) {
    this.responseBodyProcessed = this.queryService.getPath(this.responseBodyTransformed, query.responsePath);
    this.cd.markForCheck();
  }

  updateResponseBody(query: HttpQuery, tokens: Object) {
    this.updateResponseBodyTransformed(query, tokens);
    this.updateResponseBodyProcessed(query);
  }

  execute() {
    const query = this.form.getInstance();
    const requestTokens = this.context.serializeTokenValues();
    const saveTokens = this.context.serializeTokenValues(true);
    const resourceParams = this.resource
      ? this.resource.parseParams<RestAPIResourceParams>(RestAPIResourceParams)
      : undefined;

    this.loading = true;
    this.response = undefined;
    this.responseBody = undefined;
    this.responseBodyTransformed = undefined;
    this.responseBodyProcessed = undefined;
    this.resultsSection = HttpResultsSection.Result;
    this.cd.markForCheck();

    try {
      this.httpQueryService
        .request<any>(query, {
          resource: this.resource ? this.resource.uniqueName : undefined,
          baseQuery: this.currentOptions.baseQuery,
          tokens: requestTokens,
          raiseErrors: true,
          customProxy: resourceParams ? resourceParams.customProxy : undefined
        })
        .pipe(untilDestroyed(this))
        .subscribe(
          response => {
            this.response = response;
            this.responseActual = true;
            this.loading = false;
            this.cd.markForCheck();

            const tokensAfterResponse = {
              ...requestTokens,
              headers: getHttpHeadersAsObject(response.headers),
              headers_array: getHttpHeadersAsArray(response.headers)
            };

            this.responseBody = response.body;

            if (isArray(this.responseBodyProcessed)) {
              this.setPerPage(this.responseBodyProcessed.length);
            }
            this.cd.markForCheck();
            this.updateResponseBody(query, tokensAfterResponse);

            const responseHeaders = getHttpHeadersAsArray(response.headers);

            this.context.lastExecutedResponse = this.responseBodyProcessed;
            this.form.controls.request_response.patchValue(limitObjectLength(this.responseBody, 20));
            this.form.controls.request_response_headers.patchValue(limitObjectLength(responseHeaders, 20));
            this.form.controls.request_tokens.patchValue(filterObject(saveTokens, item => !(item instanceof Blob)));
            this.executed.emit(query);

            this.analyticsService.sendSimpleEvent(AnalyticsEvent.HTTPBuilder.SuccessfullyPerformed, {
              ResourceType: this.resource.typeItem.name,
              HTTPQueryType: this.httpQueryType
            });
          },
          e => {
            if (e.response && e.response instanceof HttpErrorResponse) {
              this.response = e.response;
              this.responseBody = e.response.error;
              this.responseBodyTransformed = e.response.error;
              this.responseBodyProcessed = e.response.error;
            } else if (e.response && e.response instanceof HttpResponse) {
              this.response = e.response;
              this.responseBody = e.response.body;
              this.responseBodyTransformed = e.response.body;
              this.responseBodyProcessed = e.response.body;
            } else {
              this.resultsSection = HttpResultsSection.Http;

              const serverError = new ServerRequestError(e);

              if (serverError.nonFieldErrors.length) {
                this.notificationService.error('Request failed', serverError.nonFieldErrors[0]);
              } else {
                this.notificationService.error('Request failed', `Unknown error`);
              }
            }

            this.responseActual = true;
            this.loading = false;
            this.cd.markForCheck();

            const responseHeaders = getHttpHeadersAsArray(e.response.headers);

            this.form.controls.request_response.patchValue(limitObjectLength(this.responseBodyProcessed, 20));
            this.form.controls.request_response_headers.patchValue(limitObjectLength(responseHeaders, 20));
            this.form.controls.request_tokens.patchValue(filterObject(saveTokens, item => !(item instanceof Blob)));

            this.analyticsService.sendSimpleEvent(AnalyticsEvent.HTTPBuilder.UnsuccessfullyPerformed, {
              ResourceType: this.resource.typeItem.name,
              HTTPQueryType: this.httpQueryType
            });
          }
        );
    } catch (e) {
      if (e instanceof JsFunctionError) {
        this.notificationService.error(
          'Transformation Failed',
          `
          Body transformer failed:<br>
          [${e.line - 2}:${e.column}] ${e.message}
        `
        );
      } else if (e instanceof ReferenceError) {
        this.notificationService.error('Execute Failed', 'Token parsing failed:<br>' + e.message);
      } else {
        this.notificationService.error('Execute Failed', errorToString(e));
      }

      this.loading = false;
      this.cd.markForCheck();
    }
  }

  onHasMorePathSelected(e: ResponsePathSelectEvent) {
    this.queryForm.controls.pagination_has_more_path.patchValue(e.pathName);

    if (e.pathName === 'pagination_has_more_pages_path') {
      this.queryForm.controls.pagination_has_more_pages_path.patchValue(e.value);
    }

    if (e.pathName === 'pagination_has_more_total_pages_path') {
      this.queryForm.controls.pagination_has_more_total_pages_path.patchValue(e.value);
    }

    if (e.pathName === 'pagination_has_more_total_records_path') {
      this.queryForm.controls.pagination_has_more_total_records_path.patchValue(e.value);
    }
  }

  onHasMorePathJsSelected() {
    this.queryForm.controls.pagination_has_more_path.patchValue(undefined);
  }

  onTotalTransformPathSelected(e: ResponsePathSelectEvent) {
    this.queryForm.controls.pagination_total_transformer_enabled.patchValue(false);
    this.queryForm.controls.pagination_total_path.patchValue(e.value);
  }

  onTotalTransformJsSelected() {
    this.queryForm.controls.pagination_total_transformer_enabled.patchValue(true);
  }

  onCursorPrevPathSelected(e: ResponsePathSelectEvent) {
    this.queryForm.controls.pagination_cursor_prev_transformer_enabled.patchValue(false);
    this.queryForm.controls.pagination_cursor_prev_path.patchValue(e.value);
  }

  onCursorPrevTransformJsSelected() {
    this.queryForm.controls.pagination_cursor_prev_transformer_enabled.patchValue(true);
  }

  onCursorNextPathSelected(e: ResponsePathSelectEvent) {
    this.queryForm.controls.pagination_cursor_next_transformer_enabled.patchValue(false);
    this.queryForm.controls.pagination_cursor_next_path.patchValue(e.value);
  }

  onCursorNextTransformJsSelected() {
    this.queryForm.controls.pagination_cursor_next_transformer_enabled.patchValue(true);
  }

  onResponseTransformPathSelected(e: ResponsePathSelectEvent) {
    this.form.controls.response_transformer_enabled.patchValue(false);
    this.form.controls.response_path.patchValue(e.value);
    this.execute();
  }

  onResponseTransformJsSelected() {
    this.form.controls.response_transformer_enabled.patchValue(true);
    this.execute();
  }

  setSideBarSection(value: SideBarSection) {
    this.sideBarSection = value;
    this.cd.markForCheck();
  }

  cancel() {
    this.canceled.emit();
  }

  saveProcess() {
    const query = this.form.getInstance();
    this.control.patchValue(query);
    this.saved.emit();

    this.analyticsService.sendSimpleEvent(AnalyticsEvent.HTTPBuilder.SuccessfullySetUp, {
      ResourceType: this.resource.typeItem.name,
      HTTPQueryType: this.httpQueryType
    });
  }

  isResponseMissing() {
    return this.requireResponse && !this.responseActual;
  }

  save() {
    if (this.isResponseMissing()) {
      return;
    }

    this.saveProcess();
  }

  submit() {
    this.save();
  }

  get httpQueryType() {
    if (this.resource.typeItem.name == ResourceName.GraphQL) {
      return ResourceName.GraphQL;
    } else {
      return ResourceName.RestApi;
    }
  }

  onInputTokensEvent(event: InputTokensEvent, source?: QueryBuilderSection) {
    if (event.type == InputTokensEventType.SetSorting) {
      const setSortingEvent = event as InputTokensEvent<InputTokensEventSetSortingData>;

      if (setSortingEvent.data.value) {
        this.setSideBarSection(SideBarSection.Sorting);
      } else if (!setSortingEvent.data.value && this.sideBarSection == SideBarSection.Sorting) {
        this.setSideBarSection(undefined);
      }
    } else if (event.type == InputTokensEventType.SetPagination) {
      const setPaginationEvent = event as InputTokensEvent<InputTokensEventSetPaginationData>;

      if (setPaginationEvent.data.pagination) {
        this.setSideBarSection(SideBarSection.Pagination);
      } else if (!setPaginationEvent.data.pagination && this.sideBarSection == SideBarSection.Pagination) {
        this.setSideBarSection(undefined);
      }
    } else if (event.type == InputTokensEventType.AddParameter) {
      if (this.parametersControl && source == QueryBuilderSection.QueryParameters) {
        this.setResultsSection(HttpResultsSection.Parameters);
      }
    }
  }

  copyRequest() {
    const formValue = this.form.value;

    if (this.form.baseUrlAllowed) {
      formValue['url'] = this.form.urlPathToUrl();
    }

    const data: HttpBuilderRequestBuffer = {
      formValue: formValue,
      parametersValue: this.parametersControl
        ? this.parametersControl.serialize().map(item => item.serialize())
        : undefined
    };

    this.localStorage.set(HTTP_BUILDER_REQUEST_BUFFER_KEY, JSON.stringify(data));
  }

  isPasteRequestAvailable() {
    return !!this.localStorage.get(HTTP_BUILDER_REQUEST_BUFFER_KEY);
  }

  pasteRequest() {
    const dataStr = this.localStorage.get(HTTP_BUILDER_REQUEST_BUFFER_KEY);
    if (!dataStr) {
      return;
    }

    try {
      const data = JSON.parse(dataStr) as HttpBuilderRequestBuffer;

      if (this.parametersControl && data.parametersValue) {
        this.parametersControl.deserialize(data.parametersValue.map(item => new ParameterField().deserialize(item)));
      }

      this.form.patchValue(data.formValue);

      this.notificationService.info('Pasted', 'Request was pasted from clipboard');
    } catch (e) {
      this.notificationService.info('Paste failed', `Failed to load request from clipboard: ${e}`);
    }
  }
}
