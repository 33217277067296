import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { NotificationService } from '@common/notifications';
import { ServerRequestError } from '@modules/api';
import { CustomViewTemplate, CustomViewTemplateService } from '@modules/views';
import { errorToString, TypedChanges } from '@shared';

@Component({
  selector: 'app-custom-view-template-rating',
  templateUrl: './custom-view-template-rating.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomViewTemplateRatingComponent implements OnInit, OnDestroy, OnChanges {
  @Input() template: CustomViewTemplate;

  currentValue = 3;
  hoverValue: number;
  displayValue: number;
  allowHalf = false;
  loading = false;

  constructor(
    private customViewTemplateService: CustomViewTemplateService,
    private notificationService: NotificationService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<CustomViewTemplateRatingComponent>): void {
    if (changes.template) {
      this.currentValue = this.template.userRating;
      this.displayValue = this.template.rating;
    }
  }

  setCurrentValue(value: number) {
    if (this.loading) {
      return;
    }

    const prevCurrentValue = this.currentValue;

    this.currentValue = value;
    this.loading = true;
    this.cd.markForCheck();

    this.customViewTemplateService
      .rate(this.template.id, value)
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          this.displayValue = result.newRating;
          this.loading = false;
          this.cd.markForCheck();
        },
        error => {
          if (error instanceof ServerRequestError && error.errors.length) {
            this.notificationService.error('Error', error.errors[0]);
          } else {
            this.notificationService.error('Error', errorToString(error));
          }

          this.currentValue = prevCurrentValue;
          this.loading = false;
          this.cd.markForCheck();
        }
      );
  }

  isRatingValueGte(value: number, i: number, left: boolean) {
    const itemValue = left && this.allowHalf ? 0.5 : 1;
    return value >= i + itemValue;
  }

  getRatingValue(i: number, left: boolean) {
    const itemValue = left && this.allowHalf ? 0.5 : 1;
    return i + itemValue;
  }

  setHoverValue(i: number, left: boolean) {
    this.hoverValue = this.getRatingValue(i, left);
    this.displayValue = this.hoverValue;
    this.cd.markForCheck();
  }

  setDisplayValue(value: number) {
    this.displayValue = value;
    this.cd.markForCheck();
  }

  clearHoverValue() {
    this.hoverValue = undefined;
    this.displayValue = this.hoverValue;
    this.cd.markForCheck();
  }
}
