import {
  CdkConnectedOverlay,
  CdkOverlayOrigin,
  ConnectedOverlayPositionChange,
  ConnectedPosition
} from '@angular/cdk/overlay';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { asyncScheduler, combineLatest, Subscription } from 'rxjs';
import { distinctUntilChanged, map, throttleTime } from 'rxjs/operators';

import { CustomView, CustomViewSource, CustomViewsStore } from '@modules/custom-views';
import { ascComparator, controlValue, isSet, KeyboardEventKeyCode, scrollTo } from '@shared';

@Component({
  selector: 'app-custom-view-dropdown',
  templateUrl: './custom-view-dropdown.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomViewDropdownComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() source: CustomViewSource;
  @Input() value: string;
  @Input() origin: CdkOverlayOrigin;
  @Output() selectItem = new EventEmitter<CustomView>();

  @ViewChild(CdkConnectedOverlay) cdkConnectedOverlay: CdkConnectedOverlay;
  @ViewChild('scrollable_element') scrollableElement: ElementRef;

  customViews: CustomView[] = [];
  customViewsFiltered: CustomView[] = [];
  thumbWidth = 100;
  thumbHeight = 83;
  thumbPadding = 10;
  thumbMargin = 4;
  thumbsPerRow = 4;
  thumbsPerPage = this.thumbsPerRow * 4;
  searchControl = new FormControl('');
  dropdownOpened = false;
  popoverPositions: ConnectedPosition[] = [
    {
      panelClass: ['overlay_position_left-top'],
      originX: 'start',
      overlayX: 'end',
      originY: 'top',
      overlayY: 'top',
      offsetY: -8,
      weight: 6
    },
    {
      panelClass: ['overlay_position_left-center'],
      originX: 'start',
      overlayX: 'end',
      originY: 'center',
      overlayY: 'center',
      weight: 5
    },
    {
      panelClass: ['overlay_position_left-bottom'],
      originX: 'start',
      overlayX: 'end',
      originY: 'bottom',
      overlayY: 'bottom',
      offsetY: 8,
      weight: 4
    },
    {
      panelClass: ['overlay_position_right-top'],
      originX: 'end',
      overlayX: 'start',
      originY: 'top',
      overlayY: 'top',
      offsetY: -8,
      weight: 3
    },
    {
      panelClass: ['overlay_position_right-center'],
      originX: 'end',
      overlayX: 'start',
      originY: 'center',
      overlayY: 'center',
      weight: 2
    },
    {
      panelClass: ['overlay_position_right-bottom'],
      originX: 'end',
      overlayX: 'start',
      originY: 'bottom',
      overlayY: 'bottom',
      offsetY: 8,
      weight: 1
    }
  ];
  popoverPositionsSubscription: Subscription;

  trackCustomViewFn(i, item: CustomView) {
    return item.uniqueName;
  }

  constructor(private customViewsStore: CustomViewsStore, private cd: ChangeDetectorRef) {}

  ngOnInit() {
    combineLatest(
      this.customViewsStore.get(),
      controlValue<string>(this.searchControl).pipe(
        map(search => search.toLowerCase().trim()),
        distinctUntilChanged(),
        throttleTime(100, asyncScheduler, { leading: true, trailing: true })
      )
    )
      .pipe(untilDestroyed(this))
      .subscribe(([customViews, search]) => {
        this.customViews = customViews
          .filter(item => item.shared && (!isSet(this.source) || item.source == this.source))
          .sort((lhs, rhs) => ascComparator(lhs.name.toLowerCase(), rhs.name.toLowerCase()));
        this.customViewsFiltered = isSet(search)
          ? this.customViews.filter(item => item.name.toLowerCase().includes(search))
          : this.customViews;
        this.cd.markForCheck();
      });
  }

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {
    this.setPositionObserver();
  }

  public isOpened(): boolean {
    return this.dropdownOpened;
  }

  public open() {
    this.setDropdownOpened(true);
  }

  public close() {
    this.setDropdownOpened(false);
  }

  setDropdownOpened(value: boolean) {
    this.dropdownOpened = value;
    this.cd.markForCheck();
  }

  setPositionObserver() {
    if (this.popoverPositionsSubscription) {
      this.popoverPositionsSubscription.unsubscribe();
    }

    if (!this.cdkConnectedOverlay) {
      return;
    }

    this.popoverPositionsSubscription = this.cdkConnectedOverlay.positionChange
      .pipe(untilDestroyed(this))
      .subscribe((e: ConnectedOverlayPositionChange) => {
        const propsEqual = ['offsetX', 'offsetY', 'originX', 'originY', 'overlayX', 'overlayY'];
        const position = this.popoverPositions.find(item =>
          propsEqual.every(prop => (item[prop] || undefined) == e.connectionPair[prop])
        );
        const otherPosition = this.popoverPositions.filter(item => item !== position);

        if (position) {
          this.cdkConnectedOverlay.overlayRef.addPanelClass(position.panelClass);
        }

        otherPosition.forEach(item => this.cdkConnectedOverlay.overlayRef.removePanelClass(item.panelClass));
      });
  }

  clearSearch() {
    this.searchControl.patchValue('');
    this.onSearch();
  }

  onSearch() {
    scrollTo(this.scrollableElement.nativeElement, 0);
  }

  onSearchKey(e: KeyboardEvent) {
    if (e.keyCode == KeyboardEventKeyCode.Escape) {
      this.clearSearch();
    }
  }
}
