import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { ActionItem } from '@modules/actions';
import { ViewContext, ViewContextElement } from '@modules/customize';
import { ActionOutput, ParameterField } from '@modules/fields';
import { View } from '@modules/views';

@Component({
  selector: 'app-custom-element-view',
  templateUrl: './custom-element-view.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomElementViewComponent implements OnInit {
  @Input() view: View;
  @Input() parameters: ParameterField[] = [];
  @Input() actionOutputs: ActionOutput[] = [];
  @Input() actions: { name: string; action: ActionItem }[] = [];
  @Input() params: Object = {};
  @Input() parentContext: ViewContext;
  @Input() parentContextElement: ViewContextElement;
  @Input() localContext: Object;
  @Input() stateSelectedEnabled = false;
  @Input() stateSelected = true;

  constructor() {}

  ngOnInit() {}
}
