/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/cdk/overlay";
import * as i2 from "../view-editor-menu-root/view-editor-menu-root.component.ngfactory";
import * as i3 from "../view-editor-menu-root/view-editor-menu-root.component";
import * as i4 from "../../services/view-editor-context/view-editor.context";
import * as i5 from "../../services/custom-view-templates-controller/custom-view-templates.controller";
import * as i6 from "../../../custom-views/stores/custom-views.store";
import * as i7 from "../../../customize-ui/components/custom-view-dropdown/custom-view-dropdown.component.ngfactory";
import * as i8 from "../../../customize-ui/components/custom-view-dropdown/custom-view-dropdown.component";
import * as i9 from "./view-editor-menu-button.component";
var styles_ViewEditorMenuButtonComponent = [];
var RenderType_ViewEditorMenuButtonComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ViewEditorMenuButtonComponent, data: {} });
export { RenderType_ViewEditorMenuButtonComponent as RenderType_ViewEditorMenuButtonComponent };
export function View_ViewEditorMenuButtonComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["cdkOverlayOrigin", ""], ["class", "view-editor-header-main-button icon-canvas"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 4).setDropdownOpened(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 16384, [["origin", 4]], 0, i1.CdkOverlayOrigin, [i0.ElementRef], null, null), (_l()(), i0.ɵeld(2, 0, null, null, 0, "span", [["class", "view-editor-header-main-button__arrow icon-arrow_down_2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "app-view-editor-menu-root", [], null, [[null, "saveEditor"], [null, "closeEditor"], [null, "rename"], [null, "importFigmaNode"], [null, "importSketchFile"], [null, "useShared"], [null, "selectShared"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("saveEditor" === en)) {
        var pd_0 = (_co.saveEditor.emit() !== false);
        ad = (pd_0 && ad);
    } if (("closeEditor" === en)) {
        var pd_1 = (_co.closeEditor.emit() !== false);
        ad = (pd_1 && ad);
    } if (("rename" === en)) {
        var pd_2 = (_co.rename.emit() !== false);
        ad = (pd_2 && ad);
    } if (("importFigmaNode" === en)) {
        var pd_3 = (_co.importFigmaNode.emit() !== false);
        ad = (pd_3 && ad);
    } if (("importSketchFile" === en)) {
        var pd_4 = (_co.importSketchFile.emit() !== false);
        ad = (pd_4 && ad);
    } if (("useShared" === en)) {
        var pd_5 = (_co.useShared.emit($event) !== false);
        ad = (pd_5 && ad);
    } if (("selectShared" === en)) {
        var pd_6 = (i0.ɵnov(_v, 6).open() !== false);
        ad = (pd_6 && ad);
    } return ad; }, i2.View_ViewEditorMenuRootComponent_0, i2.RenderType_ViewEditorMenuRootComponent)), i0.ɵdid(4, 245760, [["menu_root", 4]], 0, i3.ViewEditorMenuRootComponent, [i4.ViewEditorContext, i5.CustomViewTemplatesController, i6.CustomViewsStore, i0.ChangeDetectorRef], { trigger: [0, "trigger"], componentLabel: [1, "componentLabel"], submitLabel: [2, "submitLabel"], stateSelectedEnabled: [3, "stateSelectedEnabled"], templatesEnabled: [4, "templatesEnabled"], analyticsSource: [5, "analyticsSource"] }, { saveEditor: "saveEditor", closeEditor: "closeEditor", rename: "rename", importFigmaNode: "importFigmaNode", importSketchFile: "importSketchFile", useShared: "useShared", selectShared: "selectShared" }), (_l()(), i0.ɵeld(5, 0, null, null, 1, "app-custom-view-dropdown", [], null, [[null, "selectItem"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectItem" === en)) {
        var pd_0 = (_co.useShared.emit($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_CustomViewDropdownComponent_0, i7.RenderType_CustomViewDropdownComponent)), i0.ɵdid(6, 4440064, [["custom_view_dropdown", 4]], 0, i8.CustomViewDropdownComponent, [i6.CustomViewsStore, i0.ChangeDetectorRef], { origin: [0, "origin"] }, { selectItem: "selectItem" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 1); var currVal_1 = _co.componentLabel; var currVal_2 = _co.submitLabel; var currVal_3 = _co.stateSelectedEnabled; var currVal_4 = _co.templatesEnabled; var currVal_5 = _co.analyticsSource; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_6 = i0.ɵnov(_v, 1); _ck(_v, 6, 0, currVal_6); }, null); }
export function View_ViewEditorMenuButtonComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-view-editor-menu-button", [], null, null, null, View_ViewEditorMenuButtonComponent_0, RenderType_ViewEditorMenuButtonComponent)), i0.ɵdid(1, 114688, null, 0, i9.ViewEditorMenuButtonComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ViewEditorMenuButtonComponentNgFactory = i0.ɵccf("app-view-editor-menu-button", i9.ViewEditorMenuButtonComponent, View_ViewEditorMenuButtonComponent_Host_0, { componentLabel: "componentLabel", submitLabel: "submitLabel", stateSelectedEnabled: "stateSelectedEnabled", templatesEnabled: "templatesEnabled", analyticsSource: "analyticsSource" }, { saveEditor: "saveEditor", closeEditor: "closeEditor", rename: "rename", importFigmaNode: "importFigmaNode", importSketchFile: "importSketchFile", useShared: "useShared" }, []);
export { ViewEditorMenuButtonComponentNgFactory as ViewEditorMenuButtonComponentNgFactory };
