import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { NotificationService } from '@common/notifications';
import { BasePopupComponent } from '@common/popups';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ServerRequestError } from '@modules/api';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { Snapshot, SnapshotService, SnapshotStore } from '@modules/snapshots';
import { errorToString, isSet } from '@shared';

@Component({
  selector: 'app-snapshot-popup',
  templateUrl: './snapshot-popup.component.html',
  providers: [SnapshotStore],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SnapshotPopupComponent implements OnInit, OnDestroy {
  loading = false;
  createLoading = false;
  createName = '';
  error: string;
  items: Snapshot[];
  createAllowed = false;

  constructor(
    private popupComponent: BasePopupComponent,
    private currentProjectStore: CurrentProjectStore,
    public currentEnvironmentStore: CurrentEnvironmentStore,
    private snapshotService: SnapshotService,
    public snapshotStore: SnapshotStore,
    private notificationService: NotificationService,
    private analyticsService: UniversalAnalyticsService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.load();
  }

  ngOnDestroy(): void {}

  load() {
    this.snapshotStore.perPage = 10;
    this.loadPage(1);
  }

  loadPage(page: number) {
    this.loading = true;
    this.error = undefined;
    this.cd.markForCheck();

    this.snapshotStore.reset(page);
    this.snapshotStore
      .getNext()
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          this.items = result;
          this.createAllowed = this.snapshotStore.createAllowed;
          this.loading = false;
          this.cd.markForCheck();
        },
        error => {
          this.loading = false;

          if (error instanceof ServerRequestError && error.errors.length) {
            this.error = error.errors[0];
          } else {
            this.error = error;
          }

          this.cd.markForCheck();
        }
      );
  }

  setCreateName(value: string) {
    this.createName = value;
    this.cd.markForCheck();
  }

  createSnapshot() {
    this.createLoading = true;
    this.cd.markForCheck();

    const name = this.createName;

    this.snapshotService
      .create(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, name)
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          this.createLoading = false;
          this.createName = '';
          this.cd.markForCheck();

          this.loadPage(1);
        },
        error => {
          this.createLoading = false;
          this.cd.markForCheck();

          if (error instanceof ServerRequestError && error.errors.length) {
            this.notificationService.error('Snapshot failed', error.errors[0]);
          } else {
            this.notificationService.error('Snapshot failed', errorToString(error));
          }
        }
      );

    this.analyticsService.sendSimpleEvent(AnalyticsEvent.Snapshots.ClickCreateSnapshot, {
      Name: isSet(name) ? name : null
    });
  }

  onItemUpdated(currentItem: Snapshot, newItem: Snapshot) {
    if (this.items) {
      this.items = this.items.map(item => {
        if (item === currentItem) {
          return newItem;
        } else {
          return item;
        }
      });
      this.cd.markForCheck();
    } else {
      this.loadPage(1);
    }
  }

  onItemApplied() {
    window.location.reload();
  }

  close() {
    this.popupComponent.close();
  }
}
