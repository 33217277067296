/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../shared/pipes/safe-html/safe-html.pipe";
import * as i2 from "@angular/platform-browser";
import * as i3 from "./custom-element-html.component";
import * as i4 from "../../../queries/services/query/query.service";
var styles_CustomElementHtmlComponent = [];
var RenderType_CustomElementHtmlComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CustomElementHtmlComponent, data: {} });
export { RenderType_CustomElementHtmlComponent as RenderType_CustomElementHtmlComponent };
export function View_CustomElementHtmlComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i1.SafeHtmlPipe, [i2.DomSanitizer]), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [], [[8, "innerHTML", 1]], null, null, null, null)), i0.ɵppd(2, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i0.ɵnov(_v, 0), _co.htmlEffective)); _ck(_v, 1, 0, currVal_0); }); }
export function View_CustomElementHtmlComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-custom-element-html", [], null, null, null, View_CustomElementHtmlComponent_0, RenderType_CustomElementHtmlComponent)), i0.ɵdid(1, 638976, null, 0, i3.CustomElementHtmlComponent, [i4.QueryService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CustomElementHtmlComponentNgFactory = i0.ɵccf("app-custom-element-html", i3.CustomElementHtmlComponent, View_CustomElementHtmlComponent_Host_0, { html: "html", params: "params" }, {}, []);
export { CustomElementHtmlComponentNgFactory as CustomElementHtmlComponentNgFactory };
