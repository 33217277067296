var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { InjectionToken, OnDestroy, OnInit } from '@angular/core';
import isEqual from 'lodash/isEqual';
import values from 'lodash/values';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { ActionService, isModelUpdateEventMatch, patchModel } from '@modules/action-queries';
import { displayFieldsToViewContextOutputs, getModelAttributesByColumns, ModelData, ModelDataType, ViewContext, ViewContextElement } from '@modules/customize';
import { DataSourceGeneratorService } from '@modules/customize-generators';
import { DataSourceType, ListModelDescriptionDataSource, ModelDescriptionDataSource } from '@modules/data-sources';
import { ModelDescriptionDataSourceService } from '@modules/data-sources-queries';
import { applyParamInput$, applyParamInputs$, DisplayFieldType, LOADING_VALUE, NOT_SET_VALUE } from '@modules/fields';
import { ModelDescriptionStore } from '@modules/model-queries';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { QueryType } from '@modules/queries';
import { paramsToGetQueryOptions } from '@modules/resources';
import { ascComparator, isSet } from '@shared';
export function serializeDataSourceColumns(columns) {
    return columns
        .filter(function (item) { return !item.flex; })
        .map(function (item) {
        return {
            name: item.name
        };
    })
        .sort(function (lhs, rhs) { return ascComparator(lhs.name, rhs.name); });
}
function getElementStateFetch(state) {
    return {
        detailDataSource: state.detailDataSource
            ? __assign({}, state.detailDataSource.serialize(), { columns: serializeDataSourceColumns(state.detailDataSource.columns) }) : undefined,
        detailStaticData: state.detailStaticData,
        listDataSource: state.listDataSource
            ? __assign({}, state.listDataSource.serialize(), { columns: serializeDataSourceColumns(state.listDataSource.columns) }) : undefined,
        listStaticData: state.listStaticData,
        params: state.params,
        inputsLoading: state.inputsLoading,
        inputsNotSet: state.inputsNotSet,
        perPage: state.perPage,
        sortingField: state.sortingField,
        sortingAsc: state.sortingAsc
    };
}
function getElementStateColumns(state) {
    return {
        columns: state.detailDataSource ? state.detailDataSource.columns : undefined
    };
}
function getElementStateName(state) {
    return {
        name: state.query ? state.query.name : undefined
    };
}
export var queryControllerQueryToken = new InjectionToken('queryToken');
export var queryControllerContextElementToken = new InjectionToken('contextElementToken');
export var queryControllerParentElementToken = new InjectionToken('parentElementToken');
var CustomPageQueryController = /** @class */ (function () {
    function CustomPageQueryController(query, contextElement, parentElement, context, currentProjectStore, currentEnvironmentStore, modelDescriptionStore, actionService, modelDescriptionDataSourceService, dataSourceGeneratorService) {
        this.query = query;
        this.contextElement = contextElement;
        this.parentElement = parentElement;
        this.context = context;
        this.currentProjectStore = currentProjectStore;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.modelDescriptionStore = modelDescriptionStore;
        this.actionService = actionService;
        this.modelDescriptionDataSourceService = modelDescriptionDataSourceService;
        this.dataSourceGeneratorService = dataSourceGeneratorService;
        this.query$ = new BehaviorSubject(undefined);
        this.state = {};
    }
    CustomPageQueryController.prototype.ngOnInit = function () {
        this.initContext();
        this.trackModelUpdates();
        this.queryOnChange(this.query);
        this.trackChanges();
    };
    CustomPageQueryController.prototype.ngOnDestroy = function () {
        this.destroy();
    };
    CustomPageQueryController.prototype.destroy = function () {
        this.contextElement.unregister();
    };
    CustomPageQueryController.prototype.setQuery = function (query) {
        this.query = query;
        this.queryOnChange(this.query);
        this.initContext();
    };
    CustomPageQueryController.prototype.queryOnChange = function (value) {
        this.query$.next(value);
    };
    CustomPageQueryController.prototype.trackChanges = function () {
        var _this = this;
        this.query$
            .pipe(switchMap(function (query) {
            if (query.type == ModelDataType.Detail) {
                return _this.dataSourceGeneratorService
                    .applyDataSourceDefaults(ModelDescriptionDataSource, query.detailDataSource)
                    .pipe(map(function (dataSource) {
                    query.detailDataSource = dataSource;
                    return query;
                }));
            }
            else if (query.type == ModelDataType.List) {
                return _this.dataSourceGeneratorService
                    .applyDataSourceDefaults(ListModelDescriptionDataSource, query.listDataSource)
                    .pipe(map(function (dataSource) {
                    query.listDataSource = dataSource;
                    return query;
                }));
            }
            else {
                return of(query);
            }
        }), switchMap(function (query) { return _this.getState(query); }), untilDestroyed(this))
            .subscribe(function (state) {
            _this.onStateUpdated(state);
            _this.state = state;
        });
    };
    CustomPageQueryController.prototype.getState = function (query) {
        if (query.type == ModelDataType.Detail) {
            var staticData$ = query.detailDataSource && query.detailDataSource.type == DataSourceType.Input && query.detailDataSource.input
                ? applyParamInput$(query.detailDataSource.input, {
                    context: this.context,
                    defaultValue: {},
                    handleLoading: true,
                    ignoreEmpty: true
                }).pipe(distinctUntilChanged(function (lhs, rhs) { return isEqual(lhs, rhs); }))
                : of({});
            var dataSourceParams$ = query.detailDataSource
                ? applyParamInputs$({}, query.detailDataSource.queryInputs, {
                    context: this.context,
                    parameters: query.detailDataSource.queryParameters,
                    handleLoading: true,
                    ignoreEmpty: true
                }).pipe(distinctUntilChanged(function (lhs, rhs) { return isEqual(lhs, rhs); }))
                : of({});
            return combineLatest(staticData$, dataSourceParams$, this.getQueryModelDescription(query.detailDataSource)).pipe(map(function (_a) {
                var staticData = _a[0], dataSourceParams = _a[1], modelDescription = _a[2];
                return {
                    query: query,
                    detailDataSource: query.detailDataSource,
                    detailStaticData: staticData,
                    params: dataSourceParams,
                    modelDescription: modelDescription,
                    inputsLoading: [dataSourceParams, staticData].some(function (obj) {
                        return obj == LOADING_VALUE || values(obj).some(function (item) { return item === LOADING_VALUE; });
                    }),
                    inputsNotSet: [dataSourceParams, staticData].some(function (obj) {
                        return obj == NOT_SET_VALUE || values(obj).some(function (item) { return item === NOT_SET_VALUE; });
                    })
                };
            }));
        }
        else if (query.type == ModelDataType.List) {
            var staticData$ = query.listDataSource && query.listDataSource.type == DataSourceType.Input && query.listDataSource.input
                ? applyParamInput$(query.listDataSource.input, {
                    context: this.context,
                    defaultValue: [],
                    handleLoading: true,
                    ignoreEmpty: true
                }).pipe(distinctUntilChanged(function (lhs, rhs) { return isEqual(lhs, rhs); }))
                : of([]);
            var dataSourceParams$ = query.listDataSource
                ? applyParamInputs$({}, query.listDataSource.queryInputs, {
                    context: this.context,
                    parameters: query.listDataSource.queryParameters,
                    handleLoading: true,
                    ignoreEmpty: true
                }).pipe(distinctUntilChanged(function (lhs, rhs) { return isEqual(lhs, rhs); }))
                : of({});
            return combineLatest(staticData$, dataSourceParams$, this.getQueryModelDescription(query.listDataSource)).pipe(map(function (_a) {
                var staticData = _a[0], dataSourceParams = _a[1], modelDescription = _a[2];
                return {
                    query: query,
                    listDataSource: query.listDataSource,
                    listStaticData: staticData,
                    params: dataSourceParams,
                    modelDescription: modelDescription,
                    inputsLoading: [dataSourceParams, staticData].some(function (obj) {
                        return obj == LOADING_VALUE || values(obj).some(function (item) { return item === LOADING_VALUE; });
                    }),
                    inputsNotSet: [dataSourceParams, staticData].some(function (obj) {
                        return obj == NOT_SET_VALUE || values(obj).some(function (item) { return item === NOT_SET_VALUE; });
                    }),
                    perPage: query.perPage,
                    sortingField: query.sortingField,
                    sortingAsc: query.sortingAsc
                };
            }));
        }
    };
    CustomPageQueryController.prototype.onStateUpdated = function (state) {
        if (!isEqual(getElementStateColumns(state), getElementStateColumns(this.state))) {
            this.updateContextOutputs(state);
        }
        if (!isEqual(getElementStateName(state), getElementStateName(this.state))) {
            this.updateContextInfo(state);
        }
        if (!isEqual(getElementStateFetch(state), getElementStateFetch(this.state))) {
            this.fetch(state);
        }
    };
    CustomPageQueryController.prototype.fetch = function (state) {
        var _this = this;
        if (this.loadingSubscription) {
            this.loadingSubscription.unsubscribe();
            this.loadingSubscription = undefined;
        }
        if (state.detailDataSource) {
            this.parentElement.patchOutputValueMeta(this.contextElement.uniqueName, { loading: true });
            var configured = state.detailDataSource && state.detailDataSource.isConfigured();
            if (!configured || state.inputsLoading || state.inputsNotSet) {
                return;
            }
            var queryOptions = paramsToGetQueryOptions(state.params);
            queryOptions.columns = state.detailDataSource ? state.detailDataSource.columns : undefined;
            this.loadingSubscription = this.modelDescriptionDataSourceService
                .getDetailAdv({
                project: this.currentProjectStore.instance,
                environment: this.currentEnvironmentStore.instance,
                dataSource: state.detailDataSource,
                queryOptions: queryOptions,
                staticData: state.detailStaticData,
                context: this.context
            })
                .pipe(untilDestroyed(this))
                .subscribe(function (model) {
                _this.detailResult = model;
                _this.updateContextValue();
                _this.parentElement.patchOutputValueMeta(_this.contextElement.uniqueName, { loading: false });
            }, function () {
                _this.detailResult = undefined;
                _this.updateContextValue();
                _this.parentElement.patchOutputValueMeta(_this.contextElement.uniqueName, { loading: false });
            });
        }
        else if (state.listDataSource) {
            this.parentElement.patchOutputValueMeta(this.contextElement.uniqueName, { loading: true });
            var configured = state.listDataSource && state.listDataSource.isConfigured();
            if (!configured || state.inputsLoading || state.inputsNotSet) {
                return;
            }
            var queryOptions = paramsToGetQueryOptions(state.params);
            queryOptions.columns = state.listDataSource ? state.listDataSource.columns : undefined;
            queryOptions.paging = queryOptions.paging || {};
            if (state.perPage) {
                queryOptions.paging.limit = state.perPage;
            }
            if (isSet(state.sortingField)) {
                queryOptions.sort = [{ field: state.sortingField, desc: !state.sortingAsc }];
            }
            this.loadingSubscription = this.modelDescriptionDataSourceService
                .getAdv({
                project: this.currentProjectStore.instance,
                environment: this.currentEnvironmentStore.instance,
                dataSource: state.listDataSource,
                queryOptions: queryOptions,
                staticData: state.listStaticData,
                context: this.context
            })
                .pipe(untilDestroyed(this))
                .subscribe(function (result) {
                _this.listResult = result ? result.results : undefined;
                _this.updateContextOutputs(state);
                _this.updateContextValue();
                _this.parentElement.patchOutputValueMeta(_this.contextElement.uniqueName, { loading: false });
            }, function () {
                _this.listResult = undefined;
                _this.updateContextOutputs(state);
                _this.updateContextValue();
                _this.parentElement.patchOutputValueMeta(_this.contextElement.uniqueName, { loading: false });
            });
        }
        else {
            this.detailResult = undefined;
            this.listResult = undefined;
            this.updateContextValue();
        }
    };
    CustomPageQueryController.prototype.getQueryModelDescription = function (dataSource) {
        if (!dataSource ||
            !dataSource.query ||
            dataSource.query.queryType != QueryType.Simple ||
            !dataSource.query.simpleQuery) {
            return of(undefined);
        }
        var modelId = [dataSource.queryResource, dataSource.query.simpleQuery.model].join('.');
        return this.modelDescriptionStore.getDetailFirst(modelId);
    };
    CustomPageQueryController.prototype.initContext = function () {
        var _this = this;
        this.contextElement.initGlobal({
            uniqueName: this.query.uid,
            name: this.query.name,
            icon: 'pages',
            insert: true
        }, this.parentElement);
        this.contextElement.setActions([
            {
                uniqueName: 'update_data',
                name: 'Update Data',
                icon: 'repeat',
                parameters: [],
                handler: function () { return _this.reloadData(); }
            }
        ]);
    };
    CustomPageQueryController.prototype.updateContextInfo = function (state) {
        this.contextElement.initInfo({
            name: state.query.name,
            getFieldValue: function (field, outputs) {
                return outputs[field];
            }
        }, true);
    };
    CustomPageQueryController.prototype.updateContextOutputs = function (state) {
        if (state.detailDataSource) {
            var children = state.detailDataSource
                ? displayFieldsToViewContextOutputs(state.detailDataSource.columns, state.modelDescription)
                : [];
            this.contextElement.setOutputs(children);
        }
        else if (state.listDataSource) {
            var children_1 = state.listDataSource
                ? displayFieldsToViewContextOutputs(state.listDataSource.columns.filter(function (item) { return item.type != DisplayFieldType.Computed; }), state.modelDescription)
                : [];
            this.contextElement.setOutputs((this.listResult || []).map(function (item, i) {
                return {
                    uniqueName: String(i),
                    name: "Item #" + (i + 1),
                    children: children_1
                };
            }));
        }
        else {
            this.contextElement.setOutputs([]);
        }
    };
    CustomPageQueryController.prototype.updateContextValue = function () {
        var _this = this;
        if (this.state.detailDataSource) {
            var output = this.detailResult && this.state.detailDataSource
                ? getModelAttributesByColumns(this.detailResult, this.state.detailDataSource.columns)
                : undefined;
            this.contextElement.setOutputValues(output);
        }
        else if (this.state.listDataSource) {
            var output = this.listResult && this.state.listDataSource
                ? this.listResult.map(function (item) {
                    return getModelAttributesByColumns(item, _this.state.listDataSource.columns);
                })
                : undefined;
            this.contextElement.setOutputValues(output);
        }
        else {
            this.contextElement.setOutputValues({});
        }
    };
    CustomPageQueryController.prototype.trackModelUpdates = function () {
        var _this = this;
        this.actionService.modelUpdated$.pipe(untilDestroyed(this)).subscribe(function (e) {
            if (_this.detailResult && isModelUpdateEventMatch(e, _this.state.modelDescription, _this.detailResult)) {
                _this.detailResult = patchModel(_this.detailResult, e.model);
                _this.updateContextValue();
            }
            else if (_this.listResult && isModelUpdateEventMatch(e, _this.state.modelDescription, _this.detailResult)) {
                var updated_1 = false;
                _this.listResult.map(function (item) {
                    if (isModelUpdateEventMatch(e, _this.state.modelDescription, item)) {
                        updated_1 = true;
                        return patchModel(item, e.model);
                    }
                    else {
                        return item;
                    }
                });
                if (updated_1) {
                    _this.updateContextValue();
                }
            }
        });
    };
    CustomPageQueryController.prototype.reloadData = function () {
        this.fetch(this.state);
    };
    return CustomPageQueryController;
}());
export { CustomPageQueryController };
