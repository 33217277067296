import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable } from 'rxjs';

import { DynamicComponentArguments } from '@common/dynamic-component';
import { localize } from '@common/localize';

import { PopupService } from '../../services/popup/popup.service';

export const CLOSE_BY_DEACTIVATE = 'CLOSE_BY_DEACTIVATE';

@Component({
  selector: 'app-base-popup',
  templateUrl: './base-popup.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BasePopupComponent implements OnInit, OnDestroy {
  @Input() data: DynamicComponentArguments;
  @Input() disableClose = false;
  @Input() closeWithoutConfirm = false;
  @Input() closeTitle = localize('Close');
  @Input() closeDescription = localize('Are you sure want to close?');
  @Input() requestClose: Observable<void>;
  @Input() orange = false;
  @Input() @HostBinding('class.theme_dark') dark = false;
  @Input() theme = false;
  @Output() closed = new EventEmitter<any>();

  constructor(public readonly el: ElementRef, protected popupService: PopupService) {}

  ngOnInit() {
    this.initProviders();
    this.initRequestClose();
  }

  ngOnDestroy(): void {}

  initProviders() {
    this.data.providers = (this.data.providers || []).concat([{ provide: BasePopupComponent, useValue: this }]);
  }

  initRequestClose() {
    if (!this.requestClose) {
      return;
    }

    this.requestClose.pipe(untilDestroyed(this)).subscribe(() => {
      this.close(CLOSE_BY_DEACTIVATE);
    });
  }

  close(data?: any) {
    this.popupService.remove(this.data);
    this.closed.emit(data);
  }

  confirmClose(data?: any) {
    this.close(data);
  }
}
