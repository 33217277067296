import { ElementRef, Inject, Injectable, InjectionToken, Injector } from '@angular/core';
import defaults from 'lodash/defaults';
import { combineLatest, Observable, of, ReplaySubject, throwError } from 'rxjs';
import { catchError, delayWhen, switchMap, tap } from 'rxjs/operators';

import { CLOSE_BY_BACKGROUND_CLICK, ThinDialogPopupComponent } from '@common/dialog-popup';
import { DialogButtonHotkey, DialogButtonType, DialogService } from '@common/dialogs';
import { localize } from '@common/localize';
import { NotificationService } from '@common/notifications';
import { CLOSE_BY_DEACTIVATE, PopupService } from '@common/popups';
import {
  ActionDescription,
  ActionItem,
  ActionType,
  confirmationDefaultCancelLabel,
  confirmationDefaultSubmitLabel,
  confirmationDefaultTitle,
  DownloadActionType
} from '@modules/actions';
import { ActionExecuteFinishedEvent } from '@modules/actions-components';
import { ServerRequestError } from '@modules/api';
import { ViewContext, ViewContextElement } from '@modules/customize';
import { applyParamInput, applyParamInputs, InputValueType } from '@modules/fields';
import { ThemeContext } from '@modules/theme-components';
import { AppError, errorToString, isSet } from '@shared';

import { CancelledError } from '../../data/cancelled.error';
import { WorkflowExecuteStepError } from '../../data/workflow-execute';
import { ActionOrigin, ActionService } from '../action/action.service';

export const ACTION_CONTROLLER_PROMPT_COMPONENT = new InjectionToken<any>('ACTION_CONTROLLER_PROMPT_COMPONENT');

@Injectable()
export class ActionControllerService {
  constructor(
    @Inject(ACTION_CONTROLLER_PROMPT_COMPONENT) private promptComponent: any,
    private actionService: ActionService,
    private themeContext: ThemeContext,
    private popupService: PopupService,
    private dialogService: DialogService,
    private notificationService: NotificationService,
    private injector: Injector
  ) {}

  execute(
    action: ActionItem,
    options: {
      context?: ViewContext;
      contextElement?: ViewContextElement;
      localContext?: Object;
      saveResultTo?: string;
      showSuccess?: boolean;
      showError?: boolean;
      bulk?: boolean;
      disablePopups?: boolean;
      disableRouting?: boolean;
      confirmRouting?: boolean;
      theme?: boolean;
      injector?: Injector;
      analyticsSource?: string;
      origin?: ActionOrigin;
    } = {}
  ): Observable<any> {
    options = defaults(options, {
      showSuccess: action.onSuccessNotification,
      showError: action.onErrorNotification
    });

    return this.actionService.getActionDescription(action, { context: options.context }).pipe(
      delayWhen(() => {
        if (!action.confirmation) {
          return of(undefined);
        }

        const title = applyParamInput(action.confirmation.title, {
          context: options.context,
          contextElement: options.contextElement,
          localContext: options.localContext
        });
        const description = applyParamInput(action.confirmation.description, {
          context: options.context,
          contextElement: options.contextElement,
          localContext: options.localContext
        });
        const accentColor = this.themeContext.getColorHex('accentColor');

        return this.dialogService
          .confirm({
            title: isSet(title) ? title : confirmationDefaultTitle,
            description: description,
            buttons: [
              {
                name: 'cancel',
                label: isSet(action.confirmation.cancelLabel)
                  ? action.confirmation.cancelLabel
                  : confirmationDefaultCancelLabel,
                tint: action.confirmation.cancelTint || accentColor,
                icon: action.confirmation.cancelIcon,
                type: DialogButtonType.Default,
                hotkey: DialogButtonHotkey.Cancel
              },
              {
                name: 'ok',
                label: isSet(action.confirmation.submitLabel)
                  ? action.confirmation.submitLabel
                  : confirmationDefaultSubmitLabel,
                tint: action.confirmation.submitTint || accentColor,
                icon: action.confirmation.submitIcon,
                type: DialogButtonType.Submit,
                hotkey: DialogButtonHotkey.Submit
              }
            ],
            theme: true
          })
          .pipe(
            tap(result => {
              if (!result) {
                throw new CancelledError();
              }
            })
          );
      }),
      switchMap(actionDescription => {
        if (!actionDescription) {
          return of(undefined);
        }

        let inputs = [...action.inputs];

        if (
          actionDescription.type == ActionType.Export &&
          actionDescription.exportAction &&
          actionDescription.exportAction.dataSource
        ) {
          inputs.push(...actionDescription.exportAction.dataSource.queryInputs);
        } else if (
          actionDescription.type == ActionType.Download &&
          actionDescription.downloadAction &&
          actionDescription.downloadAction.type == DownloadActionType.Input
        ) {
          inputs = [];
        }

        const hasPromptParameters = !!inputs.filter(item => item.valueType == InputValueType.Prompt).length;
        const params = applyParamInputs({}, inputs, {
          context: options.context,
          contextElement: options.contextElement,
          localContext: options.localContext,
          parameters: actionDescription.actionParams
        });
        // const customControllers = [SegueType.ModelMassEdit, SegueType.ModelDelete, SegueType.ModelExport];

        if (
          hasPromptParameters ||
          (options.bulk &&
            [ActionType.Query, ActionType.Download, ActionType.Workflow].includes(actionDescription.type))
        ) {
          return this.executeActionWithPrompt(action, actionDescription, params, {
            context: options.context,
            contextElement: options.contextElement,
            localContext: options.localContext,
            saveResultTo: options.saveResultTo,
            showSuccess: options.showSuccess,
            showError: options.showError,
            injector: options.injector,
            analyticsSource: options.analyticsSource
          });
        } else if (action.approve) {
          return this.actionService
            .requestApproval(action, params, {
              context: options.context,
              contextElement: options.contextElement,
              localContext: options.localContext
            })
            .pipe(
              delayWhen(() => {
                if (action.approve.onTaskCreateActions.length) {
                  return combineLatest(
                    action.approve.onTaskCreateActions.map(item =>
                      this.execute(item, {
                        context: options.context,
                        contextElement: options.contextElement,
                        localContext: options.localContext,
                        showSuccess: false,
                        theme: options.theme,
                        injector: options.injector,
                        origin: options.origin
                      })
                    )
                  );
                } else {
                  return of(undefined);
                }
              })
            );
        } else {
          return this.executeAction(action, params, {
            context: options.context,
            contextElement: options.contextElement,
            localContext: options.localContext,
            saveResultTo: options.saveResultTo,
            disablePopups: options.disablePopups,
            disableRouting: options.disableRouting,
            confirmRouting: options.confirmRouting,
            theme: options.theme,
            injector: options.injector,
            analyticsSource: options.analyticsSource,
            origin: options.origin
          }).pipe(
            tap(() => {
              if (options.showSuccess) {
                this.notificationService.success(localize('Action executed'), localize('Action executed successfully'));
              }
            }),
            catchError(error => {
              console.error(error);

              if (options.showError) {
                if (error instanceof CancelledError) {
                  // Do nothing
                } else if (error instanceof WorkflowExecuteStepError && error.silent) {
                  // Do nothing
                } else if (error instanceof ServerRequestError && error.errors.length) {
                  this.notificationService.error(localize('Action failed'), error.errors[0]);
                } else {
                  this.notificationService.error(localize('Action failed'), errorToString(error));
                }
              }

              return throwError(error);
            })
          );
        }
      })
    );
  }

  executeAction(
    action: ActionItem,
    params: Object = {},
    options: {
      context?: ViewContext;
      contextElement?: ViewContextElement;
      localContext?: Object;
      saveResultTo?: string;
      disablePopups?: boolean;
      disableRouting?: boolean;
      confirmRouting?: boolean;
      theme?: boolean;
      injector?: Injector;
      analyticsSource?: string;
      origin?: ActionOrigin;
    } = {}
  ): Observable<any> {
    return this.actionService.getActionDescription(action).pipe(
      switchMap(actionDescription =>
        this.actionService.executeActionDescription(actionDescription, params, {
          context: options.context,
          contextElement: options.contextElement,
          localContext: options.localContext,
          disablePopups: options.disablePopups,
          disableRouting: options.disableRouting,
          confirmRouting: options.confirmRouting,
          injector: options.injector,
          analyticsSource: options.analyticsSource,
          origin: options.origin
        })
      ),
      tap(result => {
        if (options.saveResultTo && options.contextElement) {
          options.contextElement.setOutputValue(options.saveResultTo, result);
        }
      }),
      delayWhen(result => {
        if (action.onSuccessActions.length) {
          return combineLatest(
            action.onSuccessActions.map(item =>
              this.execute(item, {
                context: options.context,
                contextElement: options.contextElement,
                localContext: options.localContext,
                showSuccess: false,
                theme: options.theme,
                injector: options.injector,
                origin: options.origin
              })
            )
          );
        } else {
          return of(undefined);
        }
      }),
      catchError(error => {
        let obs: Observable<any>;

        if (options.saveResultTo && options.contextElement) {
          options.contextElement.setOutputValue(options.saveResultTo, undefined);
        }

        if (action.onErrorActions.length) {
          obs = combineLatest(
            action.onErrorActions.map(item =>
              this.execute(item, {
                context: options.context,
                contextElement: options.contextElement,
                localContext: options.localContext,
                theme: options.theme,
                injector: options.injector,
                origin: options.origin
              })
            )
          );
        } else {
          obs = of(undefined);
        }

        return obs.pipe(
          catchError(() => of(undefined)),
          switchMap(() => throwError(error))
        );
      })
    );
  }

  executeActionWithPrompt(
    action: ActionItem,
    actionDescription: ActionDescription,
    params: Object = {},
    options: {
      context?: ViewContext;
      contextElement?: ViewContextElement;
      localContext?: Object;
      saveResultTo?: string;
      showSuccess?: boolean;
      showError?: boolean;
      injector?: Injector;
      analyticsSource?: string;
    } = {}
  ): Observable<any> {
    options = defaults(options, {
      showSuccess: action.onSuccessNotification,
      showError: action.onErrorNotification
    });

    const result = new ReplaySubject<ActionExecuteFinishedEvent>();

    this.popupService.push({
      component: this.promptComponent,
      popupComponent: ThinDialogPopupComponent,
      popupComponentTheme: true,
      inputs: {
        action: action,
        actionDescription: actionDescription,
        params: params,
        options: options,
        fill: true,
        theme: true,
        analyticsSource: options.analyticsSource
      },
      outputs: {
        finished: [
          (event: ActionExecuteFinishedEvent) => {
            result.next(event);
          }
        ],
        cancelled: [
          () => {
            result.error(new CancelledError());
          }
        ]
      },
      popupClosed: data => {
        if (data == CLOSE_BY_BACKGROUND_CLICK || data == CLOSE_BY_DEACTIVATE) {
          result.error(new CancelledError());
        }
      },
      injector: options.injector || this.injector
    });

    return result.asObservable();
  }
}
