import { ModelDescriptionQuery, QueryType } from '@modules/queries';

import { DataSource, DataSourceType } from './data-source';

export class ModelDescriptionDataSource extends DataSource<ModelDescriptionQuery> {
  public static queryCls = ModelDescriptionQuery;

  getModelId(): string {
    return this.type == DataSourceType.Query &&
      this.query &&
      this.query.queryType == QueryType.Simple &&
      this.query.simpleQuery
      ? [this.queryResource, this.query.simpleQuery.model].join('.')
      : undefined;
  }
}
