import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Subscription, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { DialogButtonHotkey, DialogButtonType, DialogService } from '@common/dialogs';
import { NotificationService } from '@common/notifications';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ServerRequestError } from '@modules/api';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { Snapshot, SnapshotService, SnapshotStatus } from '@modules/snapshots';
import { errorToString, isSet, TypedChanges } from '@shared';

@Component({
  selector: 'app-snapshot-popup-item, [app-snapshot-popup-item]',
  templateUrl: './snapshot-popup-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SnapshotPopupItemComponent implements OnInit, OnDestroy, OnChanges {
  @Input() item: Snapshot;
  @Output() updated = new EventEmitter<Snapshot>();
  @Output() applied = new EventEmitter<void>();

  updateLoading = false;
  applyLoading = false;
  statuses = SnapshotStatus;
  snapshotSubscription: Subscription;

  constructor(
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private snapshotService: SnapshotService,
    private dialogService: DialogService,
    private notificationService: NotificationService,
    private analyticsService: UniversalAnalyticsService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<SnapshotPopupItemComponent>): void {
    if (changes.item) {
      this.subscribeSnapshot();
    }
  }

  renameSnapshot(name: string) {
    this.updateLoading = true;
    this.cd.markForCheck();

    const instance = cloneDeep(this.item) as Snapshot;

    instance.name = name;

    this.snapshotService
      .update(
        this.currentProjectStore.instance.uniqueName,
        this.currentEnvironmentStore.instance.uniqueName,
        instance,
        ['name']
      )
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          this.updateLoading = false;
          this.cd.markForCheck();

          this.updated.emit(result);
        },
        error => {
          this.updateLoading = false;
          this.cd.markForCheck();

          if (error instanceof ServerRequestError && error.errors.length) {
            this.notificationService.error('Rename failed', error.errors[0]);
          } else {
            this.notificationService.error('Rename failed', errorToString(error));
          }
        }
      );

    this.analyticsService.sendSimpleEvent(AnalyticsEvent.Snapshots.RenameSnapshot, {
      Name: isSet(name) ? name : null
    });
  }

  applySnapshot() {
    const name = this.item.createdAt ? this.item.createdAt.format('LLL') : this.item.hash;

    this.dialogService
      .dialog({
        title: `Are you sure want to revert to ${name}?`,
        description:
          'All changes will be restored to the selected version.<br>Other environments will not be affected.',
        style: 'orange',
        buttons: [
          {
            name: 'cancel',
            label: 'Cancel',
            type: DialogButtonType.Default,
            hotkey: DialogButtonHotkey.Cancel
          },
          {
            name: 'ok',
            label: 'Revert',
            type: DialogButtonType.Danger,
            hotkey: DialogButtonHotkey.Submit,
            executor: () => {
              this.applyLoading = true;
              this.cd.markForCheck();

              return this.snapshotService
                .apply(
                  this.currentProjectStore.instance.uniqueName,
                  this.currentEnvironmentStore.instance.uniqueName,
                  this.item
                )
                .pipe(
                  tap(() => {
                    this.applyLoading = true;
                    this.cd.markForCheck();
                  }),
                  catchError(error => {
                    this.applyLoading = false;
                    this.cd.markForCheck();

                    if (error instanceof ServerRequestError && error.errors.length) {
                      this.notificationService.error('Apply failed', error.errors[0]);
                    } else {
                      this.notificationService.error('Apply failed', errorToString(error));
                    }

                    return throwError(error);
                  })
                );
            }
          }
        ]
      })
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        if (result.button == 'ok' && !result.executorError && result.executorResult) {
          this.applied.emit();
        }
      });

    this.analyticsService.sendSimpleEvent(AnalyticsEvent.Snapshots.ClickApplySnapshot);
  }

  subscribeSnapshot() {
    if (this.snapshotSubscription) {
      this.snapshotSubscription.unsubscribe();
      this.snapshotSubscription = undefined;
    }

    if (this.item.isProcessing()) {
      this.snapshotSubscription = this.snapshotService
        .subscribeSnapshot(
          this.currentProjectStore.instance.uniqueName,
          this.currentEnvironmentStore.instance.uniqueName,
          this.item
        )
        .pipe(untilDestroyed(this))
        .subscribe(item => {
          this.item = item;
          this.cd.markForCheck();

          if (!item.isProcessing()) {
            this.snapshotSubscription.unsubscribe();
            this.snapshotSubscription = undefined;
          }
        });
    }
  }
}
