/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../localize/pipes/localize/localize.pipe";
import * as i2 from "@angular/common";
import * as i3 from "./image-editor.component";
import * as i4 from "../../../popups/data/popup-ref";
var styles_ImageEditorComponent = [];
var RenderType_ImageEditorComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ImageEditorComponent, data: {} });
export { RenderType_ImageEditorComponent as RenderType_ImageEditorComponent };
function View_ImageEditorComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 24, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, [[6, 0], ["image", 1]], null, 2, "div", [["class", "photo-editor__image"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "div", [["class", "photo-editor__image-inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, [[2, 0], ["canvas", 1]], null, 0, "canvas", [], [[8, "width", 0], [8, "height", 0]], null, null, null, null)), (_l()(), i0.ɵeld(4, 0, [[7, 0], ["bounds", 1]], null, 5, "div", [["class", "photo-editor__bounds"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 4, "div", [["class", "photo-editor__bounds-inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, [[8, 0], ["corner", 1]], null, 0, "span", [["class", "photo-editor__bounds-corner photo-editor__bounds-corner_position_top-left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, [[8, 0], ["corner", 1]], null, 0, "span", [["class", "photo-editor__bounds-corner photo-editor__bounds-corner_position_top-right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, [[8, 0], ["corner", 1]], null, 0, "span", [["class", "photo-editor__bounds-corner photo-editor__bounds-corner_position_bottom-left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, [[8, 0], ["corner", 1]], null, 0, "span", [["class", "photo-editor__bounds-corner photo-editor__bounds-corner_position_bottom-right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 11, "div", [["class", "photo-editor__toolbar"]], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 0, "a", [["class", "photo-editor__toolbar-item photo-editor__toolbar-item_type_zoom-out"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.scaleDown() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 0, "a", [["class", "photo-editor__toolbar-item photo-editor__toolbar-item_type_zoom-in"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.scaleUp() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 0, "a", [["class", "photo-editor__toolbar-item photo-editor__toolbar-item_type_rotate-left"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.rotate(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(14, 0, null, null, 0, "a", [["class", "photo-editor__toolbar-item photo-editor__toolbar-item_type_rotate-right"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.rotate(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(15, 0, null, null, 0, "a", [["class", "photo-editor__toolbar-item photo-editor__toolbar-item_type_fullscreen-exit"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.scaleFit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(16, 0, null, null, 2, "a", [["class", "photo-editor__toolbar-button"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(17, null, [" ", " "])), i0.ɵppd(18, 1), (_l()(), i0.ɵeld(19, 0, null, null, 2, "a", [["class", "photo-editor__toolbar-button photo-editor__toolbar-button_primary"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.save() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(20, null, [" ", " "])), i0.ɵppd(21, 1), (_l()(), i0.ɵeld(22, 0, [[3, 0], ["preview", 1]], null, 2, "div", [["class", "photo-editor__preview"]], null, null, null, null, null)), (_l()(), i0.ɵeld(23, 0, [[4, 0], ["preview_inner", 1]], null, 1, "div", [["class", "photo-editor__preview-inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(24, 0, [[5, 0], ["preview_canvas", 1]], null, 0, "canvas", [["class", "photo-editor__preview-canvas"], ["height", "100%"], ["width", "100%"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.width; var currVal_1 = _co.height; _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = i0.ɵunv(_v, 17, 0, _ck(_v, 18, 0, i0.ɵnov(_v.parent, 0), "Cancel")); _ck(_v, 17, 0, currVal_2); var currVal_3 = i0.ɵunv(_v, 20, 0, _ck(_v, 21, 0, i0.ɵnov(_v.parent, 0), "Save")); _ck(_v, 20, 0, currVal_3); }); }
export function View_ImageEditorComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i1.LocalizePipe, []), i0.ɵqud(402653184, 1, { viewport: 0 }), i0.ɵqud(671088640, 2, { canvas: 0 }), i0.ɵqud(671088640, 3, { preview: 0 }), i0.ɵqud(671088640, 4, { previewInner: 0 }), i0.ɵqud(671088640, 5, { previewCanvas: 0 }), i0.ɵqud(671088640, 6, { image: 0 }), i0.ɵqud(671088640, 7, { bounds: 0 }), i0.ɵqud(671088640, 8, { corners: 1 }), (_l()(), i0.ɵeld(9, 0, [[1, 0], ["viewport", 1]], null, 2, "div", [["class", "photo-editor"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ImageEditorComponent_1)), i0.ɵdid(11, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.loading; _ck(_v, 11, 0, currVal_0); }, null); }
export function View_ImageEditorComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-image-editor", [], null, null, null, View_ImageEditorComponent_0, RenderType_ImageEditorComponent)), i0.ɵdid(1, 4440064, null, 0, i3.ImageEditorComponent, [[2, i4.PopupRef], i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ImageEditorComponentNgFactory = i0.ɵccf("app-image-editor", i3.ImageEditorComponent, View_ImageEditorComponent_Host_0, { file: "file", cropFormat: "cropFormat", cropFormatCustom: "cropFormatCustom" }, { changed: "changed" }, []);
export { ImageEditorComponentNgFactory as ImageEditorComponentNgFactory };
