<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayBackdropClass]="'popover2-backdrop'"
  [cdkConnectedOverlayOpen]="opened"
  [cdkConnectedOverlayOrigin]="origin"
  [cdkConnectedOverlayPanelClass]="['overlay', 'overlay_horizontal_center']"
  [cdkConnectedOverlayPositions]="popoverPositions"
  [cdkConnectedOverlayPush]="true"
  [cdkConnectedOverlayGrowAfterOpen]="true"
  [cdkConnectedOverlayHasBackdrop]="true"
  (backdropClick)="close.emit()"
  (detach)="close.emit()"
>
  <div
    class="popover2 overlay-content overlay-content_animated"
    [class.popover2_theme]="theme"
    [class.token-popup_theme]="theme"
  >
    <div class="popover2__inner">
      <div class="popover2__content popover2__content_padding_sm menu2-dropdown menu2-block menu2-block_unset">
        <div class="token-popup-section">
          <div class="token-popup-section__title">
            <div class="token-popup-section__title-main">{{ 'Choose camera' | localize }}</div>
          </div>
          <div class="token-popup-section__content">
            <div
              *ngFor="let item of cameras"
              class="token-popup-list-item token-popup-list-item_hoverable token-popup-list-item_clickable"
              (click)="selectCamera.emit(item)"
            >
              <div class="token-popup-list-item__inner">
                <div class="token-popup-list-item__line">
                  <span class="token-popup-list-item__left">
                    <span class="token-popup-list-item__icon icon-camera"></span>
                  </span>

                  <span class="token-popup-list-item__main">
                    {{ item.label }}
                  </span>

                  <span class="token-popup-list-item__right">
                    <span *ngIf="selectedCamera == item.id">{{ 'current' | localize }}</span>
                    <span
                      *ngIf="selectedCamera != item.id"
                      class="token-popup-list-item__icon icon-arrow_forward_2"
                    ></span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
