<div class="model-card__item">
  <label *ngIf="label" class="model-card__item-label">
    {{ item.verboseName }}

    <app-model-card-item-action
      *ngFor="let action of actions"
      [model]="model"
      [action]="action"
      [context]="context"
      [contextElement]="contextElement"
      (click)="$event.stopPropagation()"
    >
    </app-model-card-item-action>
  </label>

  <div class="model-card__item-value">
    <ng-container *ngIf="!customView">
      <app-auto-field
        *ngIf="!(valueStr | appIsSet)"
        [field]="createField(item)"
        [readonly]="true"
        [value]="model.getAttribute(item.name)"
        [label]="false"
        [context]="context"
      >
      </app-auto-field>

      <ng-container *ngIf="valueStr | appIsSet">
        {{ valueStr | appSafeAsync }}
      </ng-container>
    </ng-container>

    <app-custom-element-auto
      *ngIf="customView"
      [source]="customView.source"
      [customView]="customView"
      [params]="viewParams$ | async"
      [context]="context"
      [contextElement]="contextElement"
    ></app-custom-element-auto>
  </div>
</div>
