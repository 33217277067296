var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { AggregateFunc } from '@modules/charts';
import { QueryType, ValueWidgetQuery } from '@modules/queries';
import { isSet } from '@shared';
import { DataSource, DataSourceType } from './data-source';
var ValueWidgetDataSource = /** @class */ (function (_super) {
    __extends(ValueWidgetDataSource, _super);
    function ValueWidgetDataSource(options) {
        if (options === void 0) { options = {}; }
        var _this = _super.call(this, options) || this;
        if (options.hasOwnProperty('column')) {
            _this.column = options.column;
        }
        if (options.hasOwnProperty('func')) {
            _this.func = options.func;
        }
        return _this;
    }
    ValueWidgetDataSource.prototype.deserialize = function (data) {
        _super.prototype.deserialize.call(this, data);
        this.column = data['column'];
        this.func = data['func'];
        return this;
    };
    ValueWidgetDataSource.prototype.serialize = function () {
        return __assign({}, _super.prototype.serialize.call(this), { column: this.column, func: this.func });
    };
    ValueWidgetDataSource.prototype.isConfigured = function (options) {
        if (options === void 0) { options = {}; }
        var isCount = this.func == AggregateFunc.Count;
        return (_super.prototype.isConfigured.call(this) &&
            (isCount || (isSet(this.func) && !isCount && isSet(this.column)) || (!isSet(this.func) && isSet(this.column))));
    };
    ValueWidgetDataSource.prototype.getModelId = function () {
        return this.type == DataSourceType.Query &&
            this.query &&
            this.query.queryType == QueryType.Simple &&
            this.query.simpleQuery
            ? [this.queryResource, this.query.simpleQuery.model].join('.')
            : undefined;
    };
    ValueWidgetDataSource.queryCls = ValueWidgetQuery;
    return ValueWidgetDataSource;
}(DataSource));
export { ValueWidgetDataSource };
