import pickBy from 'lodash/pickBy';
import { isSet } from '@shared';
import { UserParams } from './user-params';
var User = /** @class */ (function () {
    function User() {
        this.isStaff = false;
        this.emailSubscription = false;
        this.emailVerified = true;
        this.hasPassword = true;
        this.params = new UserParams();
    }
    User.prototype.deserialize = function (data) {
        this.id = data['id'];
        this.uid = data['uid'];
        this.username = data['username'];
        this.email = data['email'];
        this.firstName = data['first_name'];
        this.lastName = data['last_name'];
        this.password = data['password'];
        this.photo = data['photo'];
        this.isStaff = data['is_staff'];
        this.emailSubscription = data['email_subscription'];
        this.emailVerified = data['email_verified'];
        this.language = data['language'];
        this.timezone = data['timezone'];
        if (data.hasOwnProperty('has_password')) {
            this.hasPassword = data['has_password'];
        }
        if (data['params']) {
            try {
                this.params = new UserParams().deserialize(JSON.parse(data['params']));
            }
            catch (e) { }
        }
        return this;
    };
    User.prototype.serialize = function (fields) {
        var data = {
            id: this.id,
            username: this.username,
            email: this.email,
            first_name: this.firstName,
            last_name: this.lastName,
            password: this.password,
            email_subscription: this.emailSubscription,
            language: this.language,
            timezone: this.timezone,
            current_password: this.currentPassword,
            params: JSON.stringify(this.params.serialize())
        };
        if (fields) {
            data = pickBy(data, function (v, k) { return fields.includes(k); });
        }
        return data;
    };
    Object.defineProperty(User.prototype, "strShort", {
        get: function () {
            return this.firstName || this.username;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(User.prototype, "strFull", {
        get: function () {
            return this.firstName || this.lastName ? this.firstName + " " + this.lastName : this.username;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(User.prototype, "strFullWithEmail", {
        get: function () {
            return this.strFull + " (" + this.email + ")";
        },
        enumerable: true,
        configurable: true
    });
    User.prototype.isAnonymous = function () {
        return !isSet(this.uid);
    };
    return User;
}());
export { User };
