import { Inject, Injectable, InjectionToken, Injector } from '@angular/core';
import { AsyncValidatorFn, FormControl } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { ValueWidgetStyles } from '@modules/customize';
import { ElementConfigurationService } from '@modules/customize-configuration';
import { ValueWidget } from '@modules/dashboard';
import { Input } from '@modules/fields';
import { FieldInputControl } from '@modules/parameters';
import { isSet } from '@shared';

import { ValueWidgetDataSourceControl } from '../../model-description-data-source-edit/value-widget-data-source';
import { ValueWidgetStylesControl } from '../../styles-value-widget-edit/value-widget-styles.control';
import {
  CustomizeBarBaseWidgetEditForm,
  CustomizeBarWidgetEditFormOptions,
  CustomizeBarWidgetEditFormResult
} from '../customize-bar-base-widget-edit/customize-bar-base-widget-edit.form';
import { ChartWidgetEditFormDatasetControl } from '../customize-bar-chart-widget-edit/chart-widget-edit-dataset.control';

export const VALUE_WIDGET_DATA_SOURCE = new InjectionToken<ValueWidgetDataSourceControl>('VALUE_WIDGET_DATA_SOURCE');
export const VALUE_WIDGET_COMPARE_DATA_SOURCE = new InjectionToken<ValueWidgetDataSourceControl>(
  'VALUE_WIDGET_COMPARE_DATA_SOURCE'
);
export const VALUE_WIDGET_CHART_DATASET = new InjectionToken<ChartWidgetEditFormDatasetControl>(
  'VALUE_WIDGET_CHART_DATASET'
);

export const validateAction: AsyncValidatorFn = control => {
  const parent = control.parent as CustomizeBarValueWidgetEditForm;

  if (!parent) {
    return of(null);
  }

  if (!control.value) {
    return of(null);
  }

  return parent.elementConfigurationService.isActionConfigured(control.value).pipe(
    map(configured => {
      if (!configured) {
        return { required: true };
      }
    })
  );
};

@Injectable()
export class CustomizeBarValueWidgetEditForm extends CustomizeBarBaseWidgetEditForm<ValueWidget, ValueWidgetStyles> {
  controls: {
    title: FieldInputControl;
    data_source: ValueWidgetDataSourceControl;
    compare_data_source_enabled: FormControl;
    compare_name: FormControl;
    compare_growth_negative: FormControl;
    compare_data_source: ValueWidgetDataSourceControl;
    chart_dataset_enabled: FormControl;
    chart_dataset: ChartWidgetEditFormDatasetControl;
    prefix: FormControl;
    postfix: FormControl;
    format: FormControl;
    icon: FormControl;
    show_reload: FormControl;
    name: FormControl;
    click_action: FormControl;
    visible_input: FieldInputControl;
    tooltip: FormControl;
    card_wrap: FormControl;

    element_styles: ValueWidgetStylesControl;
  };

  widget: ValueWidget;

  constructor(
    public elementConfigurationService: ElementConfigurationService,
    @Inject(VALUE_WIDGET_DATA_SOURCE) dataSourceControl: ValueWidgetDataSourceControl,
    @Inject(VALUE_WIDGET_COMPARE_DATA_SOURCE) compareDataSourceControl: ValueWidgetDataSourceControl,
    @Inject(VALUE_WIDGET_CHART_DATASET) chartDatasetControl: ChartWidgetEditFormDatasetControl,
    private injector: Injector
  ) {
    super({
      title: new FieldInputControl({ path: ['value'] }),
      data_source: dataSourceControl,
      compare_data_source_enabled: new FormControl(false),
      compare_name: new FormControl(''),
      compare_growth_negative: new FormControl(false),
      compare_data_source: compareDataSourceControl,
      chart_dataset_enabled: new FormControl(false),
      chart_dataset: chartDatasetControl,
      prefix: new FormControl(''),
      postfix: new FormControl(''),
      format: new FormControl(''),
      icon: new FormControl(''),
      show_reload: new FormControl(true),
      name: new FormControl(''),
      click_action: new FormControl(undefined, undefined, validateAction),
      visible_input: new FieldInputControl({ path: ['value'] }),
      tooltip: new FormControl(''),
      card_wrap: new FormControl(true),

      element_styles: new ValueWidgetStylesControl(injector)
    });
  }

  init(widget?: ValueWidget, options: CustomizeBarWidgetEditFormOptions<ValueWidgetStyles> = {}) {
    this.widget = widget;
    this.options = options;

    if (widget) {
      this.controls.title.patchValue(widget.nameInput ? widget.nameInput.serializeWithoutPath() : {});
      this.controls.compare_data_source_enabled.patchValue(!!widget.compareDataSource);
      this.controls.chart_dataset_enabled.patchValue(!!widget.chartDataset);
      this.controls.compare_name.patchValue(widget.compareName);
      this.controls.compare_growth_negative.patchValue(widget.compareGrowthNegative);
      this.controls.prefix.patchValue(widget.prefix);
      this.controls.postfix.patchValue(widget.postfix);
      this.controls.format.patchValue(widget.format);
      this.controls.icon.patchValue(widget.icon);
      this.controls.show_reload.patchValue(widget.showReload);
      this.controls.click_action.patchValue(widget.clickAction);
      this.controls.visible_input.patchValue(options.visibleInput ? options.visibleInput.serializeWithoutPath() : {});
      this.controls.tooltip.patchValue(widget.tooltip);
    }

    if (options.name) {
      this.controls.name.patchValue(options.name);
    }

    this.controls.data_source.deserialize(widget ? widget.dataSource : undefined);
    this.controls.compare_data_source.deserialize(widget ? widget.compareDataSource : undefined);
    this.controls.chart_dataset.deserialize(widget ? widget.chartDataset : undefined);

    if (options.cardWrapEditable) {
      this.controls.card_wrap.patchValue(options.cardWrap);
    }

    if (options.elementStylesEditable && options.elementStyles) {
      this.controls.element_styles.deserialize(options.elementStyles);
    }

    if (!options.firstInit) {
      this.markAsDirty();
    }
  }

  isConfigured(instance: ValueWidget): Observable<boolean> {
    return this.elementConfigurationService.isWidgetValueConfigured(instance, { restrictDemo: true });
  }

  setCompareDataSourceEnabled(value: boolean) {
    if (this.controls.compare_data_source_enabled.value === value) {
      return;
    }
    this.controls.compare_data_source_enabled.patchValue(value);
  }

  setChartDatasetEnabled(value: boolean) {
    if (this.controls.chart_dataset_enabled.value === value) {
      return;
    }
    this.controls.chart_dataset_enabled.patchValue(value);
  }

  swapMainAndCompareDataSources() {
    const dataSourceValue = this.controls.data_source.serialize();
    const compareDataSourceValue = this.controls.compare_data_source.serialize();

    this.controls.compare_data_source.deserialize(dataSourceValue, false);
    this.controls.data_source.deserialize(compareDataSourceValue, false);
  }

  actionsValid$(): Observable<boolean> {
    return this.controlsValid$([this.controls.click_action]);
  }

  submit(): CustomizeBarWidgetEditFormResult<ValueWidget, ValueWidgetStyles> {
    const instance: ValueWidget = this.widget ? cloneDeep(this.widget) : new ValueWidget();

    instance.nameInput = this.controls.title.value ? new Input().deserialize(this.controls.title.value) : undefined;
    instance.dataSource = this.controls.data_source.serialize();

    if (this.controls.compare_data_source_enabled.value) {
      instance.compareName = this.controls.compare_name.value;
      instance.compareGrowthNegative = this.controls.compare_growth_negative.value;
      instance.compareDataSource = this.controls.compare_data_source.serialize();
    } else {
      instance.compareName = undefined;
      instance.compareGrowthNegative = undefined;
      instance.compareDataSource = undefined;
    }

    if (this.controls.chart_dataset_enabled.value) {
      instance.chartDataset = this.controls.chart_dataset.serialize();
    } else {
      instance.chartDataset = undefined;
    }

    instance.prefix = this.controls.prefix.value;
    instance.postfix = this.controls.postfix.value;
    instance.format = this.controls.format.value;
    instance.icon = this.controls.icon.value;
    instance.showReload = this.controls.show_reload.value;

    if (this.controls.click_action.value) {
      instance.clickAction = this.controls.click_action.value;
    } else {
      instance.clickAction = undefined;
    }

    instance.tooltip = isSet(this.controls.tooltip.value) ? this.controls.tooltip.value.trim() : undefined;

    return {
      widget: instance,
      name: this.controls.name.value,
      visibleInput: this.controls.visible_input.value
        ? new Input().deserialize(this.controls.visible_input.value)
        : undefined,
      ...(this.options.cardWrapEditable && {
        cardWrap: this.controls.card_wrap.value
      }),
      ...(this.options.elementStylesEditable && {
        elementStyles: this.controls.element_styles.serialize()
      })
    };
  }
}
