var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { SelectSource } from 'ng-gxselect';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { fromLegacyModel } from '@modules/models';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { isSet } from '@shared';
import { ModelDescriptionStore } from './model-description.store';
var ModelDescriptionSelectSource = /** @class */ (function (_super) {
    __extends(ModelDescriptionSelectSource, _super);
    function ModelDescriptionSelectSource(modelDescriptionStore, currentProjectStore, currentEnvironmentStore) {
        var _this = _super.call(this) || this;
        _this.modelDescriptionStore = modelDescriptionStore;
        _this.currentProjectStore = currentProjectStore;
        _this.currentEnvironmentStore = currentEnvironmentStore;
        _this.allowEmpty = false;
        _this.emptyName = '---';
        _this.page = 1;
        _this.totalPages = 1;
        return _this;
    }
    Object.defineProperty(ModelDescriptionSelectSource.prototype, "emptyOption", {
        get: function () {
            return {
                value: null,
                name: this.emptyName
            };
        },
        enumerable: true,
        configurable: true
    });
    ModelDescriptionSelectSource.prototype.resourceName = function (resource) {
        if (!resource) {
            return;
        }
        return resource.name;
    };
    ModelDescriptionSelectSource.prototype.resourceImage = function (resource) {
        if (!resource) {
            return;
        }
        return resource.icon;
    };
    ModelDescriptionSelectSource.prototype.fetch = function (searchQuery) {
        var _this = this;
        searchQuery = (searchQuery || '').trim();
        return this.modelDescriptionStore.getFirst().pipe(map(function (result) {
            if (!_this.models || !result) {
                return result;
            }
            return result.filter(function (item) { return _this.models.includes(item.modelId) || _this.models.includes(item.model); });
        }), map(function (result) {
            if (!_this.resource || !result) {
                return result;
            }
            return result.filter(function (item) { return item.resource == _this.resource; });
        }), map(function (result) {
            _this.page += 1;
            var results = result
                .filter(function (item) {
                return isSet(searchQuery)
                    ? [item.verboseName, item.verboseNamePlural, item.model, item.resource]
                        .filter(function (str) { return typeof str == 'string'; })
                        .some(function (str) { return str.toLowerCase().includes(searchQuery.toLowerCase()); })
                    : true;
            })
                .reduce(function (acc, item) {
                var resource = _this.currentEnvironmentStore.resources.find(function (i) { return i.uniqueName == item.resource; });
                if (resource && !resource.demo) {
                    acc.push({
                        value: { model: item.modelId },
                        name: item.verboseNamePlural,
                        image: _this.resourceImage(resource),
                        data: {
                            resource: _this.resourceName(resource)
                        }
                    });
                }
                return acc;
            }, []);
            if (!_this.allowEmpty) {
                return results;
            }
            return [_this.emptyOption].concat(results);
        }));
    };
    ModelDescriptionSelectSource.prototype.fetchByValue = function (value) {
        var _this = this;
        if (this.allowEmpty && value === null) {
            return of(this.emptyOption);
        }
        if (!value || !value['model']) {
            return of(undefined);
        }
        return this.modelDescriptionStore.getDetailFirst(fromLegacyModel(value['model'])).pipe(map(function (item) {
            if (!item) {
                return;
            }
            var resource = _this.currentEnvironmentStore.resources.find(function (i) { return i.uniqueName == item.resource; });
            if (!resource || resource.demo) {
                return;
            }
            return {
                value: { model: item.modelId },
                name: item.verboseNamePlural,
                image: _this.resourceImage(resource),
                data: {
                    resource: _this.resourceName(resource)
                }
            };
        }));
    };
    ModelDescriptionSelectSource.prototype.isFetchAvailable = function () {
        return this.page <= this.totalPages;
    };
    ModelDescriptionSelectSource.prototype.resetPagination = function () {
        this.page = 1;
        this.totalPages = 1;
    };
    ModelDescriptionSelectSource.prototype.setStaticOptions = function (options) { };
    return ModelDescriptionSelectSource;
}(SelectSource));
export { ModelDescriptionSelectSource };
