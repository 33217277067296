import { Injectable } from '@angular/core';
import { combineLatest, Observable, of, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { CustomView } from '../../data/custom-view';

@Injectable({
  providedIn: 'root'
})
export class CustomViewLoaderService {
  private states: { tagName: string; buildId?: string }[] = [];

  load(view: CustomView): Observable<{ loaded: boolean; buildId?: string }> {
    const state = this.isLoaded(view);
    if (state.loading || state.loaded) {
      return of(state);
    }

    const obs = [];

    if (view.filesJs) {
      view.filesJs.forEach(item => {
        obs.push(this.loadScript(`${view.distBaseAbsoluteUrl}${item}`));
      });
    }

    if (view.filesCss) {
      view.filesCss.forEach(item => {
        obs.push(this.loadStyle(`${view.distBaseAbsoluteUrl}${item}`));
      });
    }

    if (!obs.length) {
      return of({ loaded: true, buildId: view.buildId });
    }

    this.states.push({ tagName: view.tagNameEffective, buildId: view.buildId });

    return combineLatest(obs).pipe(
      map(result => {
        return {
          loaded: result.every(item => item),
          buildId: view.buildId
        };
      })
    );
  }

  isLoaded(view: CustomView): { loading: boolean; loaded: boolean; buildId?: string } {
    const tagName = view.tagNameEffective;
    const state = this.states.find(item => item.tagName == tagName);

    return {
      loading: state && !customElements.get(tagName),
      loaded: !!customElements.get(tagName),
      buildId: state ? state.buildId : undefined
    };
  }

  loadScript(url: string): Observable<boolean> {
    const result = new ReplaySubject<boolean>(1);
    const node = document.createElement('script');

    node.src = url;
    node.type = 'text/javascript';
    node.async = true;
    node.charset = 'utf-8';
    node.onload = () => result.next(true);
    node.onerror = () => result.next(false);
    document.getElementsByTagName('body')[0].appendChild(node);

    return result.asObservable();
  }

  loadStyle(url: string): Observable<boolean> {
    const result = new ReplaySubject<boolean>(1);
    const node = document.createElement('link');

    node.href = url;
    node.type = 'text/css';
    node.rel = 'stylesheet';
    node.onload = () => result.next(true);
    node.onerror = () => result.next(false);
    document.getElementsByTagName('body')[0].appendChild(node);

    return result.asObservable();
  }
}
