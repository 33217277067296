<ng-container *ngIf="!loadingSave">
  <mat-menu #dropdown="matMenu">
    <ng-template matMenuContent>
      <button mat-menu-item class="mat-menu-item-odd" [disableRipple]="true" (click)="startEditing()">
        <span class="choose-items-item__dropdown-icon icon-edit"></span>
        Rename component
      </button>

      <button mat-menu-item class="mat-menu-item-odd mat-menu-item-red" [disableRipple]="true" (click)="delete()">
        <span class="choose-items-item__dropdown-icon choose-items-item__dropdown-icon_red icon-bin"></span>
        Remove component
      </button>
    </ng-template>
  </mat-menu>

  <div
    class="custom-view-dropdown-item"
    [class.custom-view-dropdown-item_active]="active"
    [class.custom-view-dropdown-item_interactive]="!nameEditing"
    appElement
    [appElementDetectVisible]="{ intervalCheck: 600 }"
    (appElementFirstVisible)="firstVisible$.next(true)"
    (click)="onClick()"
  >
    <div
      class="custom-view-dropdown-item__image"
      [class.custom-view-dropdown-item__image_disabled]="nameEditing"
      [style.width.px]="thumbWidth + thumbPadding * 2"
      [style.height.px]="thumbHeight + thumbPadding * 2"
    >
      <div *ngIf="!item.source" class="custom-view-dropdown-item__image-inner">
        <div class="custom-view-dropdown-item__image-stub"></div>
      </div>

      <div
        *ngIf="item.source == sources.View"
        class="custom-view-dropdown-item__image-inner custom-view-dropdown-item__image-inner_absolute"
        [style.width.px]="item.view.frame.width"
        [style.height.px]="item.view.frame.height"
        [style.transform]="viewScale ? ('translate(-50%, -50%) scale(' + viewScale + ')' | appSafeStyle) : null"
      >
        <ng-template *ngIf="!viewLoading && viewPortal" [cdkPortalOutlet]="viewPortal"></ng-template>

        <div *ngIf="viewLoading" class="custom-view-dropdown-item__image-stub"></div>
      </div>

      <div *ngIf="item.source == sources.HTML" class="custom-view-dropdown-item__image-inner">
        <div class="custom-view-dropdown-item__image-tag">
          <div class="custom-view-dropdown-item__image-tag-name">{{ '<' }}div{{ '>' }}</div>
          <div class="custom-view-dropdown-item__image-tag-subtitle">HTML</div>
        </div>
      </div>

      <div *ngIf="item.source == sources.CustomElement" class="custom-view-dropdown-item__image-inner">
        <div class="custom-view-dropdown-item__image-tag">
          <div class="custom-view-dropdown-item__image-tag-name">{{ '<' }}jet-custom{{ '>' }}</div>
          <div class="custom-view-dropdown-item__image-tag-subtitle">JavaScript</div>
        </div>
      </div>

      <div *ngIf="active" class="custom-view-dropdown-item__image-label">
        Selected
      </div>

      <div
        class="custom-view-dropdown-item__image-button icon-more"
        [class.custom-view-dropdown-item__image-button_active]="dropdown_trigger.menuOpen"
        [matMenuTriggerFor]="dropdown"
        #dropdown_trigger="matMenuTrigger"
        (click)="$event.stopPropagation()"
      ></div>
    </div>

    <div class="custom-view-dropdown-item__info">
      <div *ngIf="!nameEditing" class="custom-view-dropdown-item__title">
        <ng-container *ngIf="item.name | appIsSet">{{ item.name }}</ng-container>
        <ng-container *ngIf="!(item.name | appIsSet)">No name</ng-container>
      </div>

      <input
        *ngIf="nameEditing"
        class="custom-view-dropdown-item__title-input"
        [(ngModel)]="nameValue"
        (blur)="finishEditing()"
        (keydown)="$event.stopPropagation()"
        (keyup)="onKeyUp($event)"
        #input
      />
    </div>
  </div>
</ng-container>

<ng-container *ngIf="loadingSave">
  <div class="custom-view-dropdown-item">
    <div
      class="custom-view-dropdown-item__image"
      [style.width.px]="thumbWidth + thumbPadding * 2"
      [style.height.px]="thumbHeight + thumbPadding * 2"
    >
      <div
        class="custom-view-dropdown-item__image-inner custom-view-dropdown-item__image-inner_absolute"
        [style.width.px]="item.view ? item.view.frame.width : null"
        [style.height.px]="item.view ? item.view.frame.height : null"
        [style.transform]="viewScale ? ('translate(-50%, -50%) scale(' + viewScale + ')' | appSafeStyle) : null"
      >
        <div class="custom-view-dropdown-item__image-stub" [class.loading-animation]="true"></div>
      </div>
    </div>

    <div class="custom-view-dropdown-item__info">
      <div *ngIf="!nameEditing" class="custom-view-dropdown-item__title">
        <span [class.loading-animation]="true">
          <span class="stub-text">Name</span>
        </span>
      </div>
    </div>
  </div>
</ng-container>
